import { workitem } from '../../apis/apiSlice';
import Series from "./Series";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import { getProtocolStepNumberFromSeries } from "../ImageDisplay/ImageDisplaySlice";
import "../Preview/PreviewPanel.scss";
import { Constants } from '../../Constants';
import { useORTranslation } from '../Localization/ORLocalization';

interface RejectedSeriesProps {
	orderId: string;
	workitemId: string;
	workitemData: workitem;
	seriesIds: string[];
	rawSeriesIds: string[];
	className: string;
	getRawSeries: boolean;
	includeImageDesc: boolean;
	showRecover: boolean;
	onImageSelect: (workitem: string, artifactId: string | undefined, artifactId_raw: string | undefined, workitemData?: workitem | undefined, protocolCorrelationId?: string | undefined) => void;
}

const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
const useAppDispatch = () => useDispatch<AppDispatch>();

const RejectedSeries = (props: RejectedSeriesProps) => {

	const { t } = useORTranslation(['PreviewPanel']);

	const dispatch = useAppDispatch();
	const rawProtocolStepNumber: number = useAppSelector((state) => getProtocolStepNumberFromSeries(state, (props.rawSeriesIds && Array.isArray(props.rawSeriesIds) && props.rawSeriesIds.length > 0) ? props.rawSeriesIds[0] : '', props.workitemData));
	const protocolCorrelationId: string | undefined = props.workitemData.protocol.steps[rawProtocolStepNumber]?.correlationId;

	const onImageSelect = (workitem: string, artifactId: string | undefined, artifactId_raw: string | undefined, workitemData?: workitem | undefined, protocolCorrelationId?: string | undefined) => {
		dispatch({ type: "ImageDisplay/setSelectedWorkitem", payload: props.workitemId });
		dispatch({ type: 'ImageDisplay/setProtocolStepSelectionProps', payload: { workitemId: props.workitemId, stepType: Constants.ACQUISITION_STEP_TYPE, requiredAttribute: 'rejected', excludedAttribute: undefined, index: -1, seriesIndex: -1, correlationId: '' } });
		props.onImageSelect(workitem, artifactId, artifactId_raw, workitemData, protocolCorrelationId);
	}

	// note well: we must put the acquisitionDetails for the InfoPanel in an array to enable JSONPath filtering (masWithAEC vs. masWithoutAEC)

	// TODO check: is the reconsider button always there? Does it always do the same?

	return (
		<>
			<div className='protocolStepHr' />
			<div className='protocolStep'>
				<Series seriesIds={props.seriesIds} rawSeriesIds={props.rawSeriesIds} onImageSelect={onImageSelect}
					workitemId={props.workitemId} workitemData={props.workitemData} orderId={props.orderId}
					getRawSeries={props.getRawSeries} className={props.className}
					includeImageDesc={props.includeImageDesc} protocolCorrelationId={protocolCorrelationId}
					showRecover={props.showRecover} isRejectedSeries={true} />
			</div>
		</>
	);

}

export default RejectedSeries;
