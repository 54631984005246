import {createSlice} from '@reduxjs/toolkit'

export interface IDicomTagBrowser { 
    isDicomTagBrowserVisible: boolean;   
}

const initialState: IDicomTagBrowser = {
    isDicomTagBrowserVisible: false,   
}

const DicomTagBrowserSlice = createSlice({
    name: 'DicomTagBrowser',
    initialState,
    reducers: {
        changeDicomTagBrowserVisible(state, action) {
            const isDicomTagBrowserVisible: boolean = action.payload;
            state.isDicomTagBrowserVisible = isDicomTagBrowserVisible;
        },       
    }
});

export const {changeDicomTagBrowserVisible} = DicomTagBrowserSlice.actions;
export default DicomTagBrowserSlice.reducer;
