import cornerstoneWADOImageLoader from 'cornerstone-wado-image-loader';
import dicomParser from 'dicom-parser';
import store from './../../store';
import { selectModalityFromSeries, selectPixelSpacingFromSeries, selectPixelSpacingFromInstance } from './../OrderList/OrdersSlice';


const specificCharacterSetMap = new Map([["ISO_IR 192", "utf8"]]);

function readFixedString(dataSet, tag) {
  const elem = dataSet?.elements[tag];
  const stringAsByteArray = dataSet?.byteArray.slice(elem?.dataOffset, elem?.dataOffset + elem?.length);
  const specificCharacterSet = dataSet.string('x00080005');
  if (specificCharacterSet) {
    return (new TextDecoder(specificCharacterSetMap.get(specificCharacterSet)).decode(stringAsByteArray));
  } else {
    return (new TextDecoder('iso-ir-100').decode(stringAsByteArray));
  }
}

export function wadoRsMetaDataProvider(type, imageId) {
  const { getNumberValue, getValue } = cornerstoneWADOImageLoader.wadors.metaData;

  const metaData = cornerstoneWADOImageLoader.wadors.metaDataManager.get(
    imageId
  );

  if (!metaData) {
    return;
  }

  if (
    metaData[type] !== undefined &&
    metaData[type].Value !== undefined &&
    metaData[type].Value.length
  ) {
    return metaData[type].Value[0];
  }

  const typeCleaned = type.replace('x', '');
  if (
    metaData[typeCleaned] !== undefined &&
    metaData[typeCleaned].Value !== undefined &&
    metaData[typeCleaned].Value.length
  ) {
    return metaData[typeCleaned].Value[0];
  }

  if (type === 'generalImageModule') {
    return {
      sopInstanceUid: getValue(metaData['00080018']),
      modality: getValue(metaData['00080060']),
      instanceNumber: getNumberValue(metaData['00200013']),
      lossyImageCompression: getValue(metaData['00282110']),
      lossyImageCompressionRatio: getValue(metaData['00282112']),
      lossyImageCompressionMethod: getValue(metaData['00282114']),

    };
  }

  if (type === 'patientModule') {
    return {
      patientName: getValue(metaData['00100010']),
      patientId: getValue(metaData['00100020']),
      patientSex: getValue(metaData['00100040']),
      patientBirthDate: getValue(metaData['00100030']),
    };
  }

  if (type === 'spacingBetweenSlices') {
    return getValue(metaData['00180088']);
  }

  if (type === 'generalStudyModule') {
    return {
      studyDescription: getValue(metaData['00081030']),
      studyDate: getValue(metaData['00080020']),
      studyTime: getValue(metaData['00080030']),
      accessionNumber: getValue(metaData['00080050']),
    };
  }

  if (type === 'cineModule') {
    return {
      frameTime: getNumberValue(metaData['00181063']),
    };
  }
}

export function wadoUriMetaDataProvider(type, imageId) {
  const { getNumberValues } = cornerstoneWADOImageLoader.wadouri.metaData;
  const {
    parseImageId,
    dataSetCacheManager,
  } = cornerstoneWADOImageLoader.wadouri;
  const parsedImageId = parseImageId(imageId);
  const dataSet = dataSetCacheManager.get(parsedImageId.url);
  const state = store.getState();
  // const currentRawSeries = selectRawSeriesDataForWorkitem(state, state.ImageDisplay.selectedWorkitem);
  // const currentRawInstance = selectRawInstanceForWorkitem(state, state.ImageDisplay.selectedWorkitem);
  const modalityFromSeries = selectModalityFromSeries(state, state.ImageDisplay.selectedWorkitem);
  const pixelSpacingFromSeries = selectPixelSpacingFromSeries(state, state.ImageDisplay.selectedWorkitem);
  const pixelSpacingFromInstance = selectPixelSpacingFromInstance(state, state.ImageDisplay.selectedWorkitem);

  if (!dataSet) {
    return;
  }

  if (type === 'generalImageModule') {

    let modality = dataSet?.string('x00080060');

    // try to get modality from series meta data
    if (!modality) {
      modality = modalityFromSeries;
    }


    return {
      sopInstanceUid: readFixedString(dataSet, 'x00080018'),
      modality: modality,
      instanceNumber: dataSet.intString('x00200013'),
      lossyImageCompression: readFixedString(dataSet, 'x00282110'),
      lossyImageCompressionRatio: readFixedString(dataSet, 'x00282112'),
      lossyImageCompressionMethod: readFixedString(dataSet, 'x00282114'),
    };
  }

  if (type === 'patientModule') {
    return {
      patientName: readFixedString(dataSet, 'x00100010'),
      patientId: readFixedString(dataSet, 'x00100020'),
    };
  }

  if (type === 'generalStudyModule') {
    return {
      studyDescription: readFixedString(dataSet, 'x00081030'),
      studyDate: readFixedString(dataSet, 'x00080020'),
      studyTime: readFixedString(dataSet, 'x00080030'),
    };
  }

  if (type === 'cineModule') {
    return {
      frameTime: dataSet.float('x00181063'),
    };
  }



  if (type === 'imagePlaneModule') {
    const imageOrientationPatient = getNumberValues(dataSet, 'x00200037', 6);
    const imagePositionPatient = getNumberValues(dataSet, 'x00200032', 3);
    const photometricInterpretation = readFixedString(dataSet, 'x00280004');
    let pixelSpacing = getNumberValues(dataSet, 'x00280030', 2);

    if (!pixelSpacing) {
      pixelSpacing = getNumberValues(dataSet, 'x00181164', 2);
    }

    let columnPixelSpacing = null;

    let rowPixelSpacing = null;

    if (pixelSpacing) {
      rowPixelSpacing = pixelSpacing[0];
      columnPixelSpacing = pixelSpacing[1];
    }

    // try to get pixelSpacing from series meta data
    if (!pixelSpacing) {
      if (pixelSpacingFromSeries) {
        pixelSpacing = pixelSpacingFromSeries;
        if (pixelSpacing.split("\\") && pixelSpacing.split("\\").length > 1) {
          rowPixelSpacing = pixelSpacing.split("\\")[0];
          columnPixelSpacing = pixelSpacing.split("\\")[1];
        }
      } else if (pixelSpacingFromInstance) {
        pixelSpacing = pixelSpacingFromInstance;
        if (pixelSpacing.split("\\") && pixelSpacing.split("\\").length > 1) {
          rowPixelSpacing = pixelSpacing.split("\\")[0];
          columnPixelSpacing = pixelSpacing.split("\\")[1];
        }
      }
    }

    let rowCosines = null;

    let columnCosines = null;

    if (imageOrientationPatient) {
      rowCosines = [
        parseFloat(imageOrientationPatient[0]),
        parseFloat(imageOrientationPatient[1]),
        parseFloat(imageOrientationPatient[2]),
      ];
      columnCosines = [
        parseFloat(imageOrientationPatient[3]),
        parseFloat(imageOrientationPatient[4]),
        parseFloat(imageOrientationPatient[5]),
      ];
    }

    return {
      frameOfReferenceUID: readFixedString(dataSet, 'x00200052'),
      rows: dataSet.uint16('x00280010'),
      columns: dataSet.uint16('x00280011'),
      imageOrientationPatient,
      rowCosines,
      columnCosines,
      imagePositionPatient,
      sliceThickness: dataSet.floatString('x00180050'),
      sliceLocation: dataSet.floatString('x00201041'),
      pixelSpacing,
      rowPixelSpacing,
      columnPixelSpacing,
      photometricInterpretation,
    };
  }


  if (dataSet.elements[type] !== undefined) {
    const element = dataSet.elements[type];
    if (!element.vr) {
      return;
    }

    return dicomParser.explicitElementToString(dataSet, element);
  }
}
