/* eslint no-alert: 0 */
import csTools from 'cornerstone-tools';

const Cursors = csTools.importInternal('tools/cursors');
const lengthCursor = Cursors.lengthCursor;
const getPixelSpacing = csTools.importInternal('util/getPixelSpacing');
const getToolState = csTools.getToolState;
const getNewContext = csTools.import("drawing/getNewContext");
const setShadow = csTools.import("drawing/setShadow");
const draw = csTools.import("drawing/draw");
const getModule = csTools.getModule;
const toolColors = csTools.toolColors;
const drawLine = csTools.importInternal("drawing/drawLine");
const drawHandles = csTools.import("drawing/drawHandles");
const drawLinkedTextBox = csTools.import("drawing/drawLinkedTextBox");

/**
 * @public
 * @class RestorableLengthTool
 * @memberof Tools.Annotation
 * @classdesc Create and position an arrow and label
 * @extends Tools.Annotation.LengthTool
 */
export default class RestorableLengthTool extends csTools.LengthTool {
	constructor(props = {}) {
		const defaultProps = {
			name: 'RestorableLength',
			supportedInteractionTypes: ['Mouse', 'Touch'],
			configuration: {
				drawHandles: true,
				drawHandlesOnHover: props.configuration.drawHandlesOnHover ?? false,
				hideHandlesIfMoving: false,
				renderDashed: false,
				digits: 2,
				shadow: true,
			},
			svgCursor: lengthCursor,
		};

		super(defaultProps);
		this.pixelToLengthFactor = undefined;
		this.scaleFactor = undefined;
	}

	updateCachedStats(image, element, data) {
		if (data?.toolName !== this.name) return;
		const { rowPixelSpacing, colPixelSpacing } = getPixelSpacing(image);

		// Set rowPixelSpacing and columnPixelSpacing to 1 if they are undefined (or zero)
		let dx, dy;
		if (this.pixelToLengthFactor !== undefined) {
			dx = (data.handles.end.x - data.handles.start.x) * (this.pixelToLengthFactor.col);
			dy = (data.handles.end.y - data.handles.start.y) * (this.pixelToLengthFactor.row);
		} else {
			dx = (data.handles.end.x - data.handles.start.x) * (colPixelSpacing || 1);
			dy = (data.handles.end.y - data.handles.start.y) * (rowPixelSpacing || 1);
		}

		// Calculate the length, and create the text variable with the millimeters or pixels suffix
		const length = Math.sqrt(dx * dx * (this.scaleFactor ?? 1) * (this.scaleFactor ?? 1)
			+ dy * dy * (this.scaleFactor ?? 1) * (this.scaleFactor ?? 1));


		// Store the length inside the tool for outside access
		data.length = length;
		data.invalidated = false;
	}

	renderToolData(evt) {
		const eventData = evt.detail;
		const {
			handleRadius,
			drawHandlesOnHover,
			hideHandlesIfMoving,
			renderDashed,
			digits,
		} = this.configuration;
		const toolData = getToolState(evt.currentTarget, this.name);

		if (!toolData) {
			return;
		}

		// We have tool data for this element - iterate over each one and draw it
		const context = getNewContext(eventData.canvasContext.canvas);
		const { image, element } = eventData;
		const { rowPixelSpacing, colPixelSpacing } = getPixelSpacing(image);
		const pixelToLengthFactor = this.pixelToLengthFactor;
		const scaleFactor = this.scaleFactor;

		const lineWidth = csTools.toolStyle.getToolWidth();
		const lineDash = getModule('globalConfiguration').configuration.lineDash;

		for (let i = 0; i < toolData.data.length; i++) {
			const data = toolData.data[i];

			if (data.visible === false) {
				continue;
			}

			draw(context, context => {
				// Configurable shadow
				setShadow(context, this.configuration);

				const color = toolColors.getColorIfActive(data);

				const lineOptions = { color };

				if (renderDashed) {
					lineOptions.lineDash = lineDash;
				}

				// Draw the measurement line
				drawLine(
					context,
					element,
					data.handles.start,
					data.handles.end,
					lineOptions
				);

				// Draw the handles
				const handleOptions = {
					color,
					handleRadius,
					drawHandlesIfActive: drawHandlesOnHover,
					hideHandlesIfMoving,
				};

				if (this.configuration.drawHandles) {
					drawHandles(context, eventData, data.handles, handleOptions);
				}

				if (!data.handles.textBox.hasMoved) {
					const coords = {
						x: Math.max(data.handles.start.x, data.handles.end.x),
					};

					// Depending on which handle has the largest x-value,
					// Set the y-value for the text box
					if (coords.x === data.handles.start.x) {
						coords.y = data.handles.start.y;
					} else {
						coords.y = data.handles.end.y;
					}

					data.handles.textBox.x = coords.x;
					data.handles.textBox.y = coords.y;
				}

				// Move the textbox slightly to the right and upwards
				// So that it sits beside the length tool handle
				const xOffset = 10;

				// Update textbox stats
				if (data.invalidated === true) {
					if (data.length) {
						this.throttledUpdateCachedStats(image, element, data);
					} else {
						this.updateCachedStats(image, element, data);
					}
				}

				const text = textBoxText(data, rowPixelSpacing, colPixelSpacing, pixelToLengthFactor, scaleFactor);

				drawLinkedTextBox(
					context,
					element,
					data.handles.textBox,
					text,
					data.handles,
					textBoxAnchorPoints,
					color,
					lineWidth,
					xOffset,
					true
				);
			});
		}

		// - SideEffect: Updates annotation 'suffix'
		function textBoxText(annotation, rowPixelSpacing, colPixelSpacing, pixelToLengthFactor) {
			//const measuredValue = sanitizeMeasuredValue(annotation.length);

			let dx, dy;
			if (pixelToLengthFactor !== undefined) {
				dx = (annotation.handles.end.x - annotation.handles.start.x) * (pixelToLengthFactor.col);
				dy = (annotation.handles.end.y - annotation.handles.start.y) * (pixelToLengthFactor.row);
			} else {
				dx = (annotation.handles.end.x - annotation.handles.start.x) * (colPixelSpacing || 1);
				dy = (annotation.handles.end.y - annotation.handles.start.y) * (rowPixelSpacing || 1);
			}

			const measuredValue = Math.sqrt(dx * dx * (scaleFactor ?? 1) * (scaleFactor ?? 1)
			+ dy * dy * (scaleFactor ?? 1) * (scaleFactor ?? 1));

			// Measured value is not defined, return empty string
			if (!measuredValue) {
				return '';
			}

			// Set the length text suffix depending on whether or not pixelSpacing is available
			let suffix = 'pixels';

			if ((rowPixelSpacing !== undefined && colPixelSpacing !== undefined) || pixelToLengthFactor !== undefined) {
				suffix = 'mm';
			}

			annotation.unit = suffix;

			return `${measuredValue.toFixed(digits)} ${suffix}`;
		}

		function textBoxAnchorPoints(handles) {
			const midpoint = {
				x: (handles.start.x + handles.end.x) / 2,
				y: (handles.start.y + handles.end.y) / 2,
			};

			return [handles.start, midpoint, handles.end];
		}
	}

	restore(element, data) {
		var xs = data.handles.start.x;
		var ys = data.handles.start.y;

		let measurement = super.createNewMeasurement(
			{
				currentPoints: { image: { x: xs, y: ys } },
				viewport: { rotation: undefined },
				element: element
			});

		measurement.handles.end.x = data.handles.end.x;
		measurement.handles.end.y = data.handles.end.y;
		measurement.handles.textBox = JSON.parse(JSON.stringify(data.handles.textBox));

		return measurement;
	}

	store(toolData) {
		toolData.storeToolData = {
			"handles": toolData.handles,
		}
	}

	setLenghtCalibration(element, pixelToLengthFactor) {
		const toolData = getToolState(element?.element, this.name);
		this.pixelToLengthFactor = global.structuredClone(pixelToLengthFactor);

		if (!toolData) {
			return;
		}

		for (let i = 0; i < toolData.data.length; i++) {
			this.updateCachedStats(element?.image, element?.element, toolData.data[i]);
		}
	}

	setScaleCalibration(element, scaleFactor) {
		const toolData = getToolState(element?.element, this.name);
		this.scaleFactor = scaleFactor;

		if (!toolData) {
			return;
		}

		for (let i = 0; i < toolData.data.length; i++) {
			this.updateCachedStats(element?.image, element?.element, toolData.data[i]);
		}
	}

} // RestorableLengthTool
