import { useEffect, useState } from 'react';
import { TypedUseSelectorHook, useSelector } from 'react-redux';
import { RootState } from '../../store';
import { selectSoftwareTriggerState } from './AcquisitionSlice';
import { useORTranslation } from '../Localization/ORLocalization';

interface AkquisitionProgressProps { };

const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

const AkquisitionProgress = (props: AkquisitionProgressProps) => {

	const { t } = useORTranslation(['Acquisition', 'common']);

	const isAkquisitionActive: boolean = useAppSelector((state) => state.Acquisition.isAcquistionButtonActive);
	const currentSoftwareTriggerState: string | undefined = useAppSelector((state) => selectSoftwareTriggerState(state));

	//const [progress, setpPogress] = useState<number>(0);
	const [acquisitionEXECUTED, setAcquisitionEXECUTED] = useState<boolean>(false);

	/* useEffect(() => {
		if (isAkquisitionActive && progress < 100) {
			setTimeout(() => {
				setpPogress(progress + 1);
			}, 20);
		}
		if (!isAkquisitionActive) setpPogress(0);
	}, [progress, isAkquisitionActive]); */

	useEffect(() => {
		if (currentSoftwareTriggerState === 'EXECUTED') {
			setAcquisitionEXECUTED(true)
		}
	}, [currentSoftwareTriggerState]);

	return (

		<div id="akquisition-button" className={isAkquisitionActive ? "akquisition-button-active" : "akquisition-button-inactive"}>
			{!(currentSoftwareTriggerState === 'IDLE' || currentSoftwareTriggerState === 'CANCELED' || currentSoftwareTriggerState === 'CANCEL_REQUESTED' || currentSoftwareTriggerState === 'TRIGGER_REQUESTED') || acquisitionEXECUTED ?
				<div className="akquisition-button-active-content">
					{/* <p>{`Bild-Akquirierung ${progress.toString()}%`}</p> */}
					<p>{
						acquisitionEXECUTED ? t('acquisition') : t('waitingForDetector')
					}</p>
					<div className="progress">
						{/* <div className="progress-bar" style={{ width: progress.toString() + '%' }}> */}
						<div className="progress-bar">
							<div className="progress-stripes"></div>
						</div>
					</div>
					{/* <img src="/images/STOP-Button.svg" alt="STOP-Button"></img> */}
				</div>
				: null}
		</div>
	);
}

export default AkquisitionProgress;
