import React, { useEffect } from "react";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { useGetArtifactMimeTypeQuery, workitem } from '../../apis/apiSlice';
import { AppDispatch, RootState } from "../../store";
import { getActivatePlanningMode, selectedWorkitem, selectImageDescVisible } from "../ImageDisplay/ImageDisplaySlice";
import { selectImagePolarity } from "../OrderList/MainOrderListSlice";
import ImageDesc from "./ImageDesc";
import { getImagePathForCurrentTheme, getLayout, selectModalityFromSeries } from "../OrderList/OrdersSlice";
import { serviceEndpoints } from "../..";
import { selectAcquistionButtonActive } from "../Akquisition/AcquisitionSlice";
import { useLocation } from "react-router-dom";
import LoadImage from "../OrderList/LoadImage";
import { Constants } from "../../Constants";
import { selectProcedureSelectionVisibilityState } from "../Procedures/ProcedureSlice";

interface PreviewImageProps {
    orderId: string;
    workitemId: string;
    artifactId: string;
    workitemData: workitem | undefined;
    artifactId_Raw: string | undefined;
    className: string;
    includeImageDesc: boolean;
    protocolCorrelationId: string | undefined;
    onImageSelect: (workitem: string, artifactId: string | undefined, artifactId_raw: string | undefined, workitemData?: workitem | undefined, protocolCorrelationId?: string | undefined) => void;
    isRejectedSeries?: boolean;
    resumeWorkitem?: (evt: any) => void;
    currentProtocolStep?: any;
    sources?: any;
    rawProtocolStepNumber?: number;
    showRecover?: boolean;
    recoverImage?: (evt: any) => void;
}


const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
const useAppDispatch = () => useDispatch<AppDispatch>();

const PreviewImage = (props: PreviewImageProps) => {
    const dispatch = useAppDispatch();
    const {
        data,
        isSuccess,
    } = useGetArtifactMimeTypeQuery(props.artifactId, {
        refetchOnMountOrArgChange: Constants.SYNCHRONOUS_BACKEND,
        skip: props.artifactId === undefined,
    })

    /* const {
        data,
        isSuccess,
    } = useGetArtifactQuery(props.artifactId, {
        refetchOnMountOrArgChange: false,
        skip: props.artifactId === undefined,
    }) */

    const currentWorkitemId: string = useAppSelector((state) => selectedWorkitem(state));
    const isAcquisitionButtonActive: boolean = useAppSelector((state) => selectAcquistionButtonActive(state)) ?? false;
    const isImageDescVisible: boolean = useAppSelector((state) => selectImageDescVisible(state));
    const currentImagePolarity: number = useAppSelector((state) => selectImagePolarity(state));
    const modalityFromSeries: string = useAppSelector((state) => selectModalityFromSeries(state, props.workitemId));
    const imagePathForCurrentTheme: string = useAppSelector((state) => getImagePathForCurrentTheme(state));
    const layout: any = useAppSelector((state) => getLayout(state));
    const procedureSelectionVisibilityState: number = useAppSelector((state) => selectProcedureSelectionVisibilityState(state));
    const activatePlanningMode = useAppSelector((state) => getActivatePlanningMode(state));

    const location = useLocation();

    const onDragStart = (e: any) => {
        e.dataTransfer.setData("text/plain", JSON.stringify({ workitemId: props.workitemId, artifactId: props.artifactId, artifactId_Raw: props.artifactId_Raw, correlationId: props.protocolCorrelationId }));
    };

    useEffect(() => {
        if (location?.state?.isFromOrderList && currentWorkitemId === props.workitemId && !isAcquisitionButtonActive) {
            if (!props.isRejectedSeries) {
                props.onImageSelect(props.workitemId, props.artifactId, props.artifactId_Raw, props.workitemData, props.protocolCorrelationId);
            }
        }
    }, []);

    const onWorkitemClick = (evt: any) => {
        if (evt.detail === 1) {
            props.onImageSelect(props.workitemId, props.artifactId, props.artifactId_Raw, props.workitemData, props.protocolCorrelationId);
            if (!activatePlanningMode && props.currentProtocolStep?.type === Constants.ACQUISITION_STEP_TYPE &&
                props.currentProtocolStep?.performed === undefined && props.currentProtocolStep?.rejected === undefined) {
                dispatch({ type: "Procedure/setProcedureSelectionVisibilityState", payload: 2 });
            }

        } else if (evt.detail === 2) {
            if (procedureSelectionVisibilityState === 1) {
                dispatch({ type: "Procedure/setProcedureSelectionVisibilityState", payload: 2 });
            }
        }

    };

    return (
        <>
            <>
                {props.artifactId ?
                    <span id={props.workitemId} className={`${props.className}${currentImagePolarity === 1 && (modalityFromSeries === 'DX' || modalityFromSeries === 'CR' || modalityFromSeries === 'CT' || data === 'image/tiff') ? '-inverted' : ''}`}>
                        {props.isRejectedSeries ?
                            <img src="/images/overlay-x.svg" className="previewImage-list-svg-on" alt="" /> : null}
                        <img
                            //src={`data:image/jpeg;base64, ${data?.icon}`}
                            src={`${serviceEndpoints.ARTIFACT_STORE_URL}/artifacts/${props.artifactId}/icon-as-image`}
                            id={props.artifactId}
                            alt={"previewImage"}
                            className={`previewImgInner${currentImagePolarity === 1 && (modalityFromSeries === 'DX' || modalityFromSeries === 'CR' || modalityFromSeries === 'CT' || data === 'image/tiff') ? '-inverted' : ''}`}
                            onClick={(evt: any) => onWorkitemClick(evt)}
                            draggable="true" onDragStart={onDragStart}
                        />
                        <LoadImage protocolCorrelationId={props.protocolCorrelationId}
                            orderId={props.orderId} workitemId={props.workitemId} artifactId_Raw={props.artifactId_Raw}
                            artifactId={props.artifactId} currentProtocolStep={props.currentProtocolStep} />
                    </span>
                    : <div className={props.className}>
                        <img className='emptyPreviewIcon' title='missing icon'
                            src={`${imagePathForCurrentTheme}box-svgrepo-image-com.svg`}
                            onError={(event: any) => { event.target.src = "/images/box-svgrepo-image-com.svg"; event.onerror = null }}
                            alt="Empty preview icon"
                            onClick={(evt: any) => onWorkitemClick(evt)} />
                    </div>}
            </>
            {(function () {
                if (props.includeImageDesc && (isImageDescVisible || layout?.showItemDescInPreviewPanel)) {
                    return <ImageDesc className={props.className} onImageSelect={props.onImageSelect}
                        workitem={props.workitemId} orderId={props.orderId} artifactId={props.artifactId}
                        artifactId_Raw={props.artifactId_Raw} data={props.workitemData} resumeWorkitem={props.resumeWorkitem}
                        currentProtocolStep={props.currentProtocolStep} protocolCorrelationId={props.protocolCorrelationId}
                        sources={props?.sources} rawProtocolStepNumber={props.rawProtocolStepNumber}
                        isRejectedSeries={props.isRejectedSeries} showRecover={props.showRecover} recoverImage={props.recoverImage}/>
                } else {
                    return null;
                }
            })()}
        </>
    );
}

export default PreviewImage;
