import React from 'react';
import { TypedUseSelectorHook, useSelector } from 'react-redux';
import { RootState } from '../../store';
import { getImageIdAtMatrixIndex, selectMatrixIndex} from './ImageDisplaySlice';
import { useORTranslation } from '../Localization/ORLocalization';
import "./RejectedOverlay.scss";

const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

interface RejectedOverlayProps {
    imageLoadStatus: string | undefined;
    matrixIndex: number;
    orderId: string;
}

export const RejectedOverlay = (props: RejectedOverlayProps) => {

    const { t } = useORTranslation(['ViewportOverlay']);

    const selectedMatrixIndex: number | undefined = useAppSelector((state) => selectMatrixIndex(state));

    const normal = (
        <React.Fragment>
            <div className="rejectedOverlay">
                Rejected!
            </div>            
        </React.Fragment>
    );

    return <div className={props.imageLoadStatus === "loading" && selectedMatrixIndex === props.matrixIndex ? "ViewportOverlay_loading" : "ViewportOverlay"}> {normal}</div>;
}

export default RejectedOverlay;
