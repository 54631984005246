import csTools from 'cornerstone-tools';
import cornerstone from "cornerstone-core";
import { apiSlice } from "../../../apis/apiSlice";

const getToolState = csTools.getToolState;
const addToolState = csTools.addToolState;
const BaseAnnotationTool = csTools.importInternal('base/BaseAnnotationTool');
const getLogger = csTools.importInternal('util/getLogger');
const logger = getLogger('tools:Restorable');

export const toolNameCalibrationResult = "LenghtCalibrationResult";
export const toolNameScaleResult = "ScaleCalibrationResult";
export const toolNameResolutionMeasurementResult = "ResolutionMeasurementResult";


/**
* Communicate with the backend server to create a new annotation for a given target.
* toolData must contain an element "storeToolData" which contains the data to be saved.
* toolData.annotationId will be set to -1 during the backend server communication.
* toolData.annotationId will be set to the annotationId when the backend server communication succeeded.
* target is the cornerstone imageId.
 */
export async function storeData(toolData, target, dispatch, sourceEnabledElement = undefined) {
	// TODO: check target? check toolData for completeness?
	if (toolData.storeToolData) {
		toolData.annotationId = -1;
		const annotationId = await dispatch(apiSlice.endpoints.annotateMultipleTargets.initiate({ targets: target, type: toolData.toolName, value: toolData.storeToolData }));
		if (annotationId?.data?.id) toolData.annotationId = annotationId?.data?.id;

		updateRTKQueryCache(toolData, 'add', target, dispatch);
	}
	return;
} // storeData

/**
* Communicate with the backend server to store a new version of an annotation.
* toolData must contain an element "storeToolData" which contains the data to be saved.
* toolData.annotationId must be set to the id previously received via store() above.
 */
export function edit(toolData, targetId, dispatch, sourceEnabledElement = undefined) {
	if (toolData.storeToolData) {
		if (toolData.annotationId) {
			let body = {
				"body": {
					"type": toolData.toolName,
					"format": "application/cornerstonetoolv1",
					"value": toolData.storeToolData,
				}
			}
			dispatch(apiSlice.endpoints.editAnnotation.initiate({ annotationId: toolData.annotationId, body: body, targetId: targetId }));

			updateRTKQueryCache(toolData, 'edit', targetId, dispatch);
		}
	}
	return;
} // edit


/**
* Communicate with the backend server to revoke an annotation.
* toolData.annotationId must be set to the id previously received via store() above.
 */
export function revoke(element, toolData, targetIds, dispatch) {
	if (toolData.annotationId) {
		dispatch(apiSlice.endpoints.revokeAnnotation.initiate({ annotationId: toolData.annotationId, targetId: targetIds }));

		updateRTKQueryCache(toolData, 'delete', targetIds, dispatch);

	}
	return;
} // revoke


export function updateRTKQueryCache(toolData, action, targetIds, dispatch) {
	switch (action) {
		case 'edit':
			targetIds?.forEach((targetId) => {
				if (toolData.storeToolData && toolData.annotationId) {
					dispatch(apiSlice.util.updateQueryData('getAnnotation', targetId, (newData) => {
						if (newData) {
							const cachedData = newData.find((data) => data.id === toolData.annotationId);
							if (cachedData) {
								if (cachedData.version) {
									cachedData.version = cachedData.version + 1;
								}
								let body = {
									"type": toolData.toolName,
									"format": "application/cornerstonetoolv1",
									"value": global.structuredClone(toolData.storeToolData),
								}
								cachedData.body = body;
							}
						}
						return newData;
					})
					)
				}
			})
			break;
		case 'delete':
			targetIds?.forEach((targetId) => {
				// @ts-ignore
				dispatch(apiSlice.util.updateQueryData('getAnnotation', targetId, (newData) => {
					if (newData) {
						const index = newData.findIndex((data) => data.id === toolData.annotationId);
						if (index !== undefined && index >= 0) {
							newData.splice(index, 1);
						}
					}
					return newData;
				})
				)
			})
			break;
		case 'add':
			targetIds?.forEach((targetId) => {
				// @ts-ignore
				dispatch(apiSlice.util.updateQueryData('getAnnotation', targetId, (newData) => {
					if (newData) {
						let body = {
							"type": toolData.toolName,
							"format": "application/cornerstonetoolv1",
							"value": global.structuredClone(toolData.storeToolData),
						}
						newData.push({
							id: toolData.annotationId, target: targetIds, revoked: false, version: 0,
							body: body
						});
					}
					return newData;
				})
				)
			})
			break;
		default:
			console.error("action: " + action + " not found");
			return undefined;
	}
}

/**
* Communicate with the backend server to retrieve all stored annotations for a given
* target/imageId;
* Restore these annotations.
*/
export function restore(element, response, targetId, matrixIndex, isOverviewAndAnnotationsVisible, dispatch) {

	if (element && element.image && element.image.imageId) {

		if (response) {
			let pixelToLengthFactor = undefined;
			let scaleFactor = undefined;
			let resultAnnotationId = undefined;
			let scaleFactorAnnotationId = undefined;
			let resolution = undefined;
			const annotationIds = new Set();

			for (let i = 0; i < response.length; i++) {
				if (response[i].body && response[i].body.format === 'application/cornerstonetoolv1' &&
					response[i].body.type && response[i].target.includes(targetId)) {
					let toolName = response[i].body.type;
					annotationIds.add(response[i]?.id);
					let tool = csTools.getToolForElement(element?.element, toolName);

					if (toolName === toolNameCalibrationResult) {
						const calibrationFactorType = typeof response[i]?.body?.value?.pixelToLengthFactor;
						if (calibrationFactorType === 'object') {
							pixelToLengthFactor = global.structuredClone(response[i]?.body?.value?.pixelToLengthFactor);
						} else {
							pixelToLengthFactor = { row: response[i]?.body?.value?.pixelToLengthFactor, col: response[i]?.body?.value?.pixelToLengthFactor };
						}
						resultAnnotationId = response[i]?.id;
					}

					if (toolName === toolNameScaleResult) {
						scaleFactor = response[i]?.body?.value?.scaleFactor;
						scaleFactorAnnotationId = response[i]?.id;
					}

					if (toolName === toolNameResolutionMeasurementResult) {
						resolution = response[i]?.body?.value?.resolution;
						dispatch({ type: "ImageDisplay/setMeasurement", payload: { matrixIndex: matrixIndex, key: "spacialResolution", value: resolution } });
						//resolutionAnnotationId = response[i]?.id;
					}

					if (tool && tool.restore) {
						const toolData = getToolState(element?.element, toolName);

						let measurement = tool.restore(element?.element, global.structuredClone(response[i].body.value));
						if (measurement) {
							if (toolData) {
								let haveId = false;
								for (let j = 0; j < toolData.data.length; j++) {
									if (toolData.data[j].annotationId === response[i].id) {
										haveId = true;
										measurement.annotationId = response[i].id;
										measurement.active = false;
										measurement.toolName = toolName;

										measurement.currentToolstateIndex = toolData.data[j]?.currentToolstateIndex;
										measurement.isNew = false;
										measurement.complete = true;
										toolData.data[j] = global.structuredClone(measurement);
										toolData.data[j].complete = true;
										toolData.data[j].visible = isOverviewAndAnnotationsVisible;
										tool.updateCachedStats(element?.image, element?.element, toolData.data[j]);
										if (tool?.selectedToolstateIndex !== undefined && tool?.selectedToolstateIndex === toolData.data[j]?.currentToolstateIndex) {
											cornerstone.triggerEvent(element?.element, "currentToolstateIndex", { data:  toolData.data[j], forceRedisplay: true });
										}
										break;
									}
								}

								if (haveId) {
									//console.log("have id!");
									continue;
								}
							}

							// no annotation id -> new annotation. And the annotatiuon to toolstate

							let currentToolstateIndex = 0;
							if (toolData?.data) {
								currentToolstateIndex = toolData.data.length;
							}

							measurement.annotationId = response[i].id;
							measurement.active = false;
							if (toolName === "PipeWallThickness" || toolName === "LineProfile" || toolName === "LengthCalibration") {
								measurement.currentToolstateIndex = currentToolstateIndex;
								measurement.isNew = false;
							}

							if (tool.store) {
								tool.store(measurement)
								measurement.toolName = toolName;
							}

							addToolState(element?.element, toolName, measurement);

							measurement.complete = true;
							measurement.visible = isOverviewAndAnnotationsVisible;

							tool.updateCachedStats(element?.image, element?.element, measurement);

						}
					}
				}
			}

			const toolList = csTools.store?.state?.tools?.filter(
				(tool) =>
					tool.element === element?.element &&
					(tool.mode === 'active' ||
						tool.mode === 'passive' ||
						tool.mode === 'enabled') &&
					tool instanceof BaseAnnotationTool
			);

			const tooldataToDelete = [];

			for (const tool of toolList) {
				const toolStateData = getToolState(element?.element, tool?.name);
				if (toolStateData?.data && toolStateData.data.length > 0) {
					for (let j = 0; j < toolStateData.data.length; j++) {
						if (toolStateData.data[j]?.annotationId !== undefined && !annotationIds.has(toolStateData.data[j]?.annotationId)) {
							tooldataToDelete.push({ name: tool?.name, data: toolStateData.data[j] });
						}
					}
				}
			}

			tooldataToDelete.forEach(tooldata => {
				csTools.removeToolState(element?.element, tooldata?.name, tooldata?.data);
			})

			const toolsToUpdate = csTools.store?.state?.tools?.filter(
				(tool) =>
					tool.element === element.element &&
					tool instanceof BaseAnnotationTool
			);

			for (const tool of toolsToUpdate) {
				if (tool.setLenghtCalibration) {
					tool?.setLenghtCalibration(element, pixelToLengthFactor);
				}
				tool.resultAnnotationId = resultAnnotationId;

				if (tool.setScaleCalibration) {
					tool?.setScaleCalibration(element, scaleFactor);
				}
				tool.scaleFactorAnnotationId = scaleFactorAnnotationId;
			}

			cornerstone.updateImage(element?.element);
		}
	} // if element

} // restore
