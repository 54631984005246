import csTools from 'cornerstone-tools';
import cornerstone from "cornerstone-core";
import { setGetToolStateItems, updateAnnnotationOnInputChange } from "./utils";
import store from './../../../store';

const BaseTool = csTools.importInternal('base/BaseTool');
const getToolState = csTools.getToolState;

/**
 * @public
 * @class AcceptBlackMaskTool
 * @memberof Tools
 *
 * @classdesc AcceptBlackMaskTool
 * @extends Tools.Base.BaseTool
 */
export default class AcceptBlackMaskTool extends BaseTool {
    /** @inheritdoc */
    constructor(props = {}) {
        const defaultProps = {
            name: 'AcceptBlackMask',
        };
        super(props, defaultProps);
    }

    activeCallback(element) {
        const state = store.getState();
        const matrixIndex = state.ImageDisplay.selectedIndex;
        let bmname = 'BlackMask';
        let toolData = getToolState(element, bmname);
        if (toolData !== undefined && toolData.data) {
            for (let i = 0; i < toolData.data.length; i++) {
                let data = toolData.data[i];
                if (!data.locked) {
                    setGetToolStateItems(i, [{ name: "locked", value: true }], [], matrixIndex, bmname);
                    updateAnnnotationOnInputChange(i, matrixIndex, bmname);
                } else {
                    setGetToolStateItems(i, [{ name: "locked", value: false }], [], matrixIndex, bmname);                    
                    updateAnnnotationOnInputChange(i, matrixIndex, bmname);
                }
                cornerstone.updateImage(element, false);
            }
            //cornerstone.triggerEvent(element, "acceptblackmask");
        }
    }
}
