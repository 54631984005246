import React, { useEffect, useRef, useState } from "react";
import { formatISO, parseISO } from "date-fns";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { JSONPath } from "jsonpath-plus";
import pointer from "json-pointer";
import Select, { components } from "react-select";
import { AppDispatch, RootState } from "../../../store";
import "./InfoPanel.scss";

import {
    apiSlice,
    instance, study, useCompleteWorkitemMutation, useConfigQuery, useUpdateArtifactMetaMutation,
    useUpdateStudyDetailsMutation, useUpdateWorkitemDetails1Mutation, workitem
} from "../../../apis/apiSlice";
import ItemActionButtonGroup from "../ItemActionButtonGroup";
import { submit } from "../ConfirmDialog";
import { deleteImage, getThemes } from "../../OrderList/OrdersSlice";
import { useORTranslation } from "../../Localization/ORLocalization";
import { selectTheme } from "../../OrderList/MainOrderListSlice";
import { concatStringFromJsonPathArray, getItemFromJsonPath } from "../../OrderList/OrderListEntry";
import JsxParser from 'react-jsx-parser';
import PregnancyStatus from "../../Subject/PregnancyStatus";
import DatePicker from "../../Subject/DatePicker";
import GenderSelection from "../../Subject/GenderSelection";
import GenderSelection2 from "../../Subject/GenderSelection2";
import { getAge } from "../../Subject/SubjectPanel";
import { selectedWorkitem } from "../../ImageDisplay/ImageDisplaySlice";
import GenderIcon from "../../Subject/GenderIcon";
import DateOfBirthWithAge from "../../Subject/DateOfBirthWithAge";

type InfoPanelProps = {
    configFile: string;
    configFileSection?: string;
    sources?: {
        currentStudy: study | undefined, currentWorkitem: workitem | undefined, currentSeries: any,
        currentInstance: instance | undefined, currentRawInstance?: instance | undefined, currentArtifact: any,
        currentRawSeries?: any, subject?: any, acquisitionDetails?: any | undefined, protocolStep?: any | undefined
    }
    workitemId: string | undefined;
    artifactId: string | undefined;
    orderId: string;
    editMode: boolean;
    editEnabled?: boolean;
    className: string;
    confirmationTextlines?: {
        downloadButtonClick: string[] | undefined, editButtonClick: string[] | undefined,
        closeButtonClick: string[] | undefined, cancelButtonClick: string[] | undefined,
        resumeButtonClick: string[] | undefined, recoverButtonClick: string[] | undefined
    };
    setUpdateObject?: (arg: any, item?: any) => void;
    updateOnComponentShutdown?: boolean;
    refresh?: number;
    resetRefresh?: () => void;
    onActionDone?: () => void;
    recoverImage?: (evt: any) => void;
    resumeWorkitem?: (evt: any) => void;
    rawProtocolStepNumber?: number;
};

const useAppDispatch = () => useDispatch<AppDispatch>();
const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

const Input = (props: any) => <components.Input {...props} isHidden={false} />;

const InfoPanel = (props: InfoPanelProps) => {
    const dispatch = useAppDispatch();

    const { t } = useORTranslation(['InfoPanel']);


    const [updateBody, setUpdateBody] = useState<Map<number, { item: any, value: string }>>(new Map<number, { item: any, value: string }>());
    const [values, setValues] = useState<Map<number, any>>(new Map<number, any>());
    const [configData, setConfigData] = useState<any>({ content: [] });
    const [isChanged, setIsChanged] = useState<boolean>(false);

    const [rawProtocolStepNumber, setRawProtocolStepNumber] = useState<number>(0);

    const [UpdateWorkitemDetails] = useUpdateWorkitemDetails1Mutation();
    const [UpdateStudyDetails] = useUpdateStudyDetailsMutation();
    const [UpdateArtifactMeta] = useUpdateArtifactMetaMutation();
    const [completeWorkitemDetails] = useCompleteWorkitemMutation();

    const currentTheme: string = useAppSelector((state) => selectTheme(state));
    const themes: string[] = useSelector(getThemes);
    const currentWorkitemId: string = useAppSelector((state) => selectedWorkitem(state));

    const updateBodylRef = useRef<Map<number, { item: any, value: string }>>();
    updateBodylRef.current = updateBody;

    const rawProtocolStepNumberRef = useRef<number>();
    rawProtocolStepNumberRef.current = rawProtocolStepNumber;

    const getSourceOject = (source: string) => {
        switch (source) {
            case 'study':
                return props.sources?.currentStudy;
            case 'workitem':
                return props.sources?.currentWorkitem;
            case 'series':
                return props.sources?.currentSeries;
            case 'rawSeries':
                return props.sources?.currentRawSeries;
            case 'instance':
                return props.sources?.currentInstance;
            case 'rawInstance':
                return props.sources?.currentRawInstance;
            case 'artifact':
                return props.sources?.currentArtifact;
            case 'subject':
                return props.sources?.subject;
            case 'acquisition':
                return props.sources?.acquisitionDetails;
            case 'protocolStep':
                return props.sources?.protocolStep;
            default:
                return undefined;
        }
    }

    const getDefaultSourceOject = (source: string) => {
        switch (source) {
            case 'study':
                return { id: '', state: '', workItems: [], details: { desc: '', opened: formatISO(new Date()), accessionNumber: '', comment: '', title: '' } };
            case 'workitem':
                return { id: '', state: '', protocol: props.sources?.currentWorkitem?.protocol, details: { desc: '', procedureCode: '', created: formatISO(new Date()), procedureName: '', document_mime_typ: '', projection: '', title: '', ref_study: '', laterality: '' } };
            case 'series':
                return props.sources?.currentSeries;
            case 'rawSeries':
                return props.sources?.currentRawSeries;
            case 'instance':
                return props.sources?.currentInstance;
            case 'rawInstance':
                return props.sources?.currentRawInstance;
            case 'artifact':
                return props.sources?.currentArtifact;
            case 'subject':
                return { registrationId: '', state: '', acceptedMerges: [], subjectName: { familyName: '', givenName: '', middleName: '', prefix: '', suffix: '' }, subjectId: { id: '', issuer: '' }, details: { comment: '', type: '', birthDate: '', sex: '', age: '', pregnancyStatus: '' } }
            case 'acquisition':
                return props.sources?.acquisitionDetails;
            case 'protocolStep':
                return props.sources?.protocolStep;
            default:
                return undefined;
        }
    }

    const onInputChange = (inputValue: any, obj: any, item: any) => {
        // onInputChange => update inputValue
        if (obj?.action === "input-change") {
            //setInputValue(inputValue);
            //console.log(inputValue);
            handelInputChange({ target: { value: inputValue } }, item)
        }
    };

    const handelInputChange = (evt: any, item: any) => {
        const index: number = configData?.content?.flatMap((item: any) => item?.content && Array.isArray(item?.content) ? item?.content : item)?.indexOf(item);
        const valuesTmp: Map<number, any> = new Map<number, any>(values);
        const updateBodyTmp: Map<number, { item: any, value: string }> = new Map<number, { item: any, value: string }>(updateBody);
        updateBodyTmp.set(index, { item, value: evt?.target?.value });
        valuesTmp.set(index, evt?.target?.value);

        let value = evt?.target?.value;

        if (item?.type && item?.type === 'number' && typeof value === 'string') {
            value = Number(value.trim());
        }
        setUpdateBody(updateBodyTmp);
        setValues(new Map<number, any>(valuesTmp));
        setIsChanged(true);
        if (props.setUpdateObject) {
            props.setUpdateObject(getUpdateObject({ item, value: value }), item);
        }
    };

    const mouseDown = (evt: any, id: string) => {
        //evt.stopPropagation();
        evt.target.focus();
    }

    const {
        data,
        isSuccess,
    }: { data: any, isSuccess: boolean } = useConfigQuery({ file: props.configFile, path: themes?.length > 0 ? `Themes/${currentTheme}` : './' }, {
        refetchOnMountOrArgChange: true,
    })

    const getUpdateObject = (value: { item: any, value: string }) => {
        let updateObject = undefined;
        let result = undefined;
        if (value.item.path !== undefined) {
            result = JSONPath({ path: value.item.path, json: getDefaultSourceOject(value.item.source) ?? '', resultType: 'pointer' });
        }
        if (value?.item?.tag) {
            updateObject = { [value.item?.tag]: value.value };
        } else if (result && Array.isArray(result) && result.length > 0) {
            var obj = {};
            pointer.set(obj, result[0], value.value);
            updateObject = obj;
        }
        return updateObject;
    }

    const okAction = () => {
        updateBodylRef?.current?.forEach((value: { item: any, value: string }, key: number) => {
            const obj = getUpdateObject(value);
            if (obj) {
                switch (value.item.source) {
                    case 'study':
                        UpdateStudyDetails({ orderId: props.orderId, body: obj });
                        break;
                    case 'workitem':
                        UpdateWorkitemDetails({ workitemId: props.workitemId, body: obj });
                        dispatch(
                            apiSlice.util.updateQueryData('getStudyWithWorkitems', props.orderId, (data: any) => {
                                const workitemList = data?.workitems?.map((workitem: any, i: number) => workitem?.data?.id);
                                if (props.workitemId && workitemList && workitemList.includes(props.workitemId)) {
                                    const tmpWorkitem = data?.workitems?.find((workitem: any) => workitem.data?.id === props.workitemId);
                                    if (tmpWorkitem?.data) {
                                        tmpWorkitem.data.details = obj;
                                    }
                                }
                                return data;
                            })
                        );
                        break;
                    case 'series':
                        break;
                    case 'instance':
                        break;
                    case 'artifact':
                        UpdateArtifactMeta({ artefactId: props.artifactId, body: obj });
                        break;
                    default:
                        console.log("source " + value.item.source + " not found");
                }
            }
        });
    }

    useEffect(() => {
        if (props.refresh && props.refresh > 0) {
            okAction();
        }
        return () => {
            if (props.resetRefresh) {
                props.resetRefresh();
            }
            if (props.onActionDone) {
                props.onActionDone();
            }
        };
    }, [props.refresh]);

    useEffect(() => {
        return () => {
            if (isChanged && props?.updateOnComponentShutdown) {
                okAction();
            }
        };
    }, [isChanged]);




    useEffect(() => {
        if (configData?.content) {
            const valuesTmp: Map<number, any> = new Map<number, any>(values);
            for (const data of configData?.content?.flatMap((item: any) => item?.content && Array.isArray(item?.content) ? item?.content : item)) {
                const index: number = configData?.content?.flatMap((item: any) => item?.content && Array.isArray(item?.content) ? item?.content : item)?.indexOf(data);
                //valuesTmp.set(index, JSONPath({ path: data?.path, json: getSourceOject(data?.source) ?? '' })?.pop());
                if (data?.path !== undefined) {
                    valuesTmp.set(index, getItemFromJsonPath(data?.path, getSourceOject(data?.source), data));
                }
            }
            setValues(new Map<number, any>(valuesTmp));
        }
    }, [props.orderId, JSON.stringify(configData), JSON.stringify(props.sources?.currentWorkitem), JSON.stringify(props.sources?.currentSeries),
    JSON.stringify(props.sources?.currentStudy)]);

    useEffect(() => {
        if (isSuccess && data) {
            if (props.configFileSection && data[props.configFileSection]) {
                setConfigData(data[props.configFileSection]);
            } else {
                setConfigData(data);
            }
        }
    }, [isSuccess, data]);

    useEffect(() => {
        if (props.rawProtocolStepNumber !== undefined) {
            setRawProtocolStepNumber(props.rawProtocolStepNumber);
        }
    }, [props.rawProtocolStepNumber, currentWorkitemId]);


    const dotsButtonClick = (e: React.MouseEvent | React.TouchEvent) => {
        dispatch({ type: "ImageDisplay/setExportDialogVisible", payload: 1 });
    };

    const finalizeButtonClick = (e: React.MouseEvent | React.TouchEvent) => {
        dispatch({ type: "ImageDisplay/setFinalizeStudyDialogVisible", payload: 1 });
    };

    const editButtonClick = (e: React.MouseEvent | React.TouchEvent) => {
        e.stopPropagation();
        dispatch({ type: "ImageDisplay/setProcedureEditVisible", payload: rawProtocolStepNumberRef.current ?? -1 });
        //okAction();
        //setIsChanged(false);
    };

    const completeButtonClick = async (evt: any) => {
        completeWorkitemDetails({ workitemId: props.workitemId });
        dispatch(
            apiSlice.util.updateQueryData('getStudyWithWorkitems', props.orderId, (data: any) => {
                const workitemList = data?.workitems?.map((workitem: any, i: number) => workitem?.data?.id);
                if (props.workitemId && workitemList && workitemList.includes(props.workitemId)) {
                    let currentWorkitem = data?.workitems?.find((workitem: any) => workitem.data?.id === props.workitemId);
                    if (currentWorkitem)
                        currentWorkitem.data.state = "COMPLETED";
                }
                return data;
            })
        );
    };




    const cancelButtonClick = (e: React.MouseEvent | React.TouchEvent) => {
        e.stopPropagation();
        dispatch(deleteImage(props.workitemId ?? ''));
        const r = document.querySelector(':root');
        // @ts-ignore
        r?.style.setProperty('--img_desc_left_margin', `${0}px`);
    };

    const formatStringToCamelCase = (str: string) => {
        const splitted = str.split("-");
        if (splitted.length === 1) return splitted[0];
        return (
            splitted[0] +
            splitted
                .slice(1)
                .map(word => word[0].toUpperCase() + word.slice(1))
                .join("")
        );
    };

    const getStyleObjectFromString = (str: string) => {
        const style = {};
        str.split(";").forEach(el => {
            const [property, value] = el.split(":");
            if (!property) return;

            const formattedProperty: string = formatStringToCamelCase(property.trim());
            //@ts-ignore
            style[formattedProperty] = value.trim();
        });

        return style;
    };



    const getJsx = (item: any, depth: number) => {
        return (
            //@ts-ignore
            <JsxParser
                className="innerHtml"
                bindings={{
                    getItemFromJsonPath: (path: string) => getItemFromJsonPath(path, getSourceOject(item.source), item),
                    concatStringFromJsonPathArray: (pathArray: string[]) => concatStringFromJsonPathArray(pathArray, getSourceOject(item.source), item?.type),
                    t: (text: string) => t(text, {ns: 'Subject'}),
                    getAge: (date: string) => parseISO(date).toLocaleDateString() !== 'Invalid Date' ? getAge(parseISO(date)) : '',
                    imagePathForCurrentTheme: "/images/",

                    getDateFromString: (datestring: string) => {
                        const dateAsString: string = getItemFromJsonPath(datestring, getSourceOject(item.source), item);
                        const dataAsDate = parseISO(dateAsString);

                        if (dataAsDate.toLocaleDateString() === 'Invalid Date') {
                            return '';
                        }
                        return t('date', { date: dataAsDate });
                    },
                    id: depth,
                }}
                //@ts-ignore
                components={{ PregnancyStatus, GenderIcon, DatePicker, GenderSelection, GenderSelection2, DateOfBirthWithAge }}
                jsx={item?.html?.join('') ?? ''}
            />
        )
    }

    const getJsxWrapper = (item: any, depth: number) => {
        return (
            <div className={item?.displayOptions?.twolines ? "item_twolines" : "item"} id={depth.toString()}>
                {item.displayOptions && item.displayOptions.fieldset ?
                    <fieldset className="fieldsetInfoPanel">
                        {item?.displayOptions?.hideLegend ?
                            null
                            :
                            <legend className="fieldsetInfoPanelLegend"
                                style={item?.displayOptions?.styleLabel ? getStyleObjectFromString(item?.displayOptions?.styleLabel) : {}}>
                                {t(item.title)}</legend>}
                        {getJsx(item, depth)}
                    </fieldset>
                    :
                    <>
                        {getJsx(item, depth)}
                    </>}
            </div>
        )
    }

    const getStringContent = (item: any, i: number) => {
        return (
            <>
                {item.readonly ?
                    <>
                        <div className={props.editMode && i === 0 ? "itemContent_edit" : "itemContent"}
                            style={item?.displayOptions?.styleContent ? getStyleObjectFromString(item?.displayOptions?.styleContent) : {}}
                            title={item.path ?
                                t(getItemFromJsonPath(item.path, getSourceOject(item.source), item, `${item?.displayOptions?.fontWeight ? item?.displayOptions?.fontWeight : undefined}`)) as string
                                : ''}>
                            {item.path ?
                                t(getItemFromJsonPath(item.path, getSourceOject(item.source), item, `${item?.displayOptions?.fontWeight ? item?.displayOptions?.fontWeight : undefined}`))
                                : ''
                            }
                        </div>
                    </>
                    :
                    <>
                        {item?.options ?
                            <>
                                <div className={props.editEnabled ? "itemContentEditable_edit" : "itemContentEditable"}
                                    style={item?.displayOptions?.styleContent ? getStyleObjectFromString(item?.displayOptions?.styleContent) : {}}></div>
                                <Select className="infoPanelDropDown" classNames={{ control: (state) => "infoPanelDropDownControl", menu: (state) => "infoPanelDropDownMenu", option: (state) => "infoPanelDropDownOption" }}
                                    unstyled
                                    onInputChange={(inputValue: any, obj: any) => onInputChange(inputValue, obj, item)}
                                    components={item?.editable ? {
                                        Input
                                    } : undefined}
                                    isClearable={item?.editable ? true : false}
                                    isSearchable={item?.editable ? true : false}
                                    hideSelectedOptions={true}
                                    options={item?.options} onChange={(evt) => handelInputChange({ target: evt }, item)}
                                    inputValue={item?.editable ? (values?.get(configData?.content?.flatMap((item: any) => item?.content && Array.isArray(item?.content) ? item?.content : item)?.indexOf(item)) ?? '') : undefined}
                                    value={item?.options?.find((option: any) => option?.value === (values?.get(configData?.content?.flatMap((item: any) => item?.content && Array.isArray(item?.content) ? item?.content : item)?.indexOf(item)) ?? '')) ?? ''}
                                />
                            </>
                            :
                            <div className={props.editEnabled ? "itemContentEditable_edit" : "itemContentEditable"}
                                style={item?.displayOptions?.styleContent ? getStyleObjectFromString(item?.displayOptions?.styleContent) : {}}>
                                <input type={item?.type === 'number' ? "number" : "text"} className="input" value={(values?.get(configData?.content?.flatMap((item: any) => item?.content && Array.isArray(item?.content) ? item?.content : item)?.indexOf(item)) ?? '')}
                                    onChange={(evt) => handelInputChange(evt, item)} name="title"
                                    onMouseDown={(evt) => mouseDown(evt, i.toString())}
                                    placeholder={item.displayOptions?.hideTitle && (props?.editMode || item.displayOptions.fieldset) ? `${item.title}` : ''}
                                    style={item?.displayOptions?.styleContent ? getStyleObjectFromString(item?.displayOptions?.styleContent) : {}}
                                    readOnly={!(props.editEnabled)} />
                            </div>}
                    </>
                }
            </>
        )
    }

    const getTextAreaContent = (item: any, i: number) => {
        return (
            <>
                {item.readonly ?
                    <div className={props.editMode && i === 0 ? "itemContent_edit" : "itemContent"}
                        style={item?.displayOptions?.styleContent ? getStyleObjectFromString(item?.displayOptions?.styleContent) : {}}>
                        {item.path ? t(getItemFromJsonPath(item.path, getSourceOject(item.path), item)) : ''}
                    </div>
                    :
                    <div className={(props.editEnabled || item?.displayOptions?.editEnabled) ? "itemContentEditable_edit" : "itemContentEditable"}
                        style={item?.displayOptions?.styleContent ? getStyleObjectFromString(item?.displayOptions?.styleContent) : {}}>
                        <textarea value={values?.get(configData?.content?.flatMap((item: any) => item?.content && Array.isArray(item?.content) ? item?.content : item)?.indexOf(item)) ?? ''}
                            placeholder={item.displayOptions?.hideTitle && props?.editMode ? t(`${item.title}`) as string : ''}
                            onChange={(evt) => handelInputChange(evt, item)}
                            rows={1}
                            style={item?.displayOptions?.styleContent ? getStyleObjectFromString(item?.displayOptions?.styleContent) : {}}
                            readOnly={!(props.editEnabled || item?.displayOptions?.editEnabled)}
                        />
                    </div>
                }
            </>
        )
    }

    const getItemJsx = (item: any, i: number, depth: number) => {
        return (
            <span key={i.toString()}>
                {(() => {
                    switch (item.type) {
                        case 'string':
                        case 'number':
                            return <div className={item?.displayOptions?.twolines ? "item_twolines" : "item"} id={depth.toString()}>
                                {item.displayOptions && item.displayOptions.fieldset ?
                                    <fieldset className="fieldsetInfoPanel">
                                        {item?.displayOptions?.hideLegend ?
                                            null
                                            :
                                            <legend className="fieldsetInfoPanelLegend"
                                                style={item?.displayOptions?.styleLabel ? getStyleObjectFromString(item?.displayOptions?.styleLabel) : {}}>
                                                <span style={item?.displayOptions?.styleLabel ? getStyleObjectFromString(item?.displayOptions?.styleLabel) : {}}>{t(item.title)}</span>
                                                <span className="required" style={item?.displayOptions?.styleLabel ? getStyleObjectFromString(item?.displayOptions?.styleLabel) : {}}>
                                                    &nbsp;{`${!item?.readonly && item?.required ? '*' : ''}`}
                                                </span>
                                            </legend>}

                                        {getStringContent(item, i)}
                                    </fieldset> :
                                    <>
                                        {item.displayOptions && item.displayOptions.hideTitle ?
                                            null :
                                            <span className="itemLabelWrapper">
                                                <label className="itemLabel"
                                                    style={item?.displayOptions?.styleLabel ? getStyleObjectFromString(item?.displayOptions?.styleLabel) : {}}>
                                                    {`${t(item.title)}`}
                                                </label>
                                                <span className="required" style={item?.displayOptions?.styleLabel ? getStyleObjectFromString(item?.displayOptions?.styleLabel) : {}}>
                                                    &nbsp;{`${!item?.readonly && item?.required ? '*' : ''}`}
                                                </span>
                                                <span style={item?.displayOptions?.styleLabel ? getStyleObjectFromString(item?.displayOptions?.styleLabel) : {}}>
                                                    {`${t(item.title) !== '' && !item?.displayOptions?.hideColon ? ':' : ''}`}
                                                </span>
                                            </span>
                                        }
                                        {getStringContent(item, i)}
                                    </>
                                }
                            </div>

                        case 'textarea':
                            return <div className={item?.displayOptions?.twolines ? "item_twolines" : "item textarea"} id={depth.toString()}>
                                {item.displayOptions && item.displayOptions.fieldset ?
                                    <fieldset className="fieldsetInfoPanel">
                                        {item?.displayOptions?.hideLegend ?
                                            null
                                            :
                                            <legend className="fieldsetInfoPanelLegend"
                                                style={item?.displayOptions?.styleLabel ? getStyleObjectFromString(item?.displayOptions?.styleLabel) : {}}>
                                                {t(item.title)}</legend>
                                        }
                                        {getTextAreaContent(item, i)}
                                    </fieldset>
                                    :
                                    <>
                                        {item.displayOptions && item.displayOptions.hideTitle ?
                                            null :
                                            <span className="itemLabelWrapper">
                                                <label className="itemLabel"
                                                    style={item?.displayOptions?.styleLabel ? getStyleObjectFromString(item?.displayOptions?.styleLabel) : {}}>
                                                    {`${t(item.title)}`}
                                                </label>
                                                <span className="required" style={item?.displayOptions?.styleLabel ? getStyleObjectFromString(item?.displayOptions?.styleLabel) : {}}>
                                                    &nbsp;{`${!item?.readonly && item?.required ? '*' : ''}`}
                                                </span>
                                                <span style={item?.displayOptions?.styleLabel ? getStyleObjectFromString(item?.displayOptions?.styleLabel) : {}}>
                                                    &nbsp;{`${t(item.title) !== '' && !item?.displayOptions?.hideColon ? ':' : ''}`}
                                                </span>
                                            </span>}
                                        {getTextAreaContent(item, i)}
                                    </>
                                }
                            </div>
                        case 'date':
                            return <div className={item?.displayOptions?.twolines ? "item_twolines textarea" : "item textarea"} id={depth.toString()}>
                                {item.displayOptions && item.displayOptions.hideTitle ? null :
                                    <span className="itemLabelWrapper">
                                        <label className="itemLabel"
                                            style={item?.displayOptions?.styleLabel ? getStyleObjectFromString(item?.displayOptions?.styleLabel) : {}}>
                                            {`${t(item.title)}`}
                                        </label>
                                        <span className="required" style={item?.displayOptions?.styleLabel ? getStyleObjectFromString(item?.displayOptions?.styleLabel) : {}}>
                                            &nbsp;{`${!item?.readonly && item?.required ? '*' : ''}`}
                                        </span>
                                        <span style={item?.displayOptions?.styleLabel ? getStyleObjectFromString(item?.displayOptions?.styleLabel) : {}}>
                                            &nbsp;{`${t(item.title) !== '' && !item?.displayOptions?.hideColon ? ':' : ''}`}
                                        </span>
                                    </span>}
                                <div className="itemContent"
                                    style={item?.displayOptions?.styleContent ? getStyleObjectFromString(item?.displayOptions?.styleContent) : {}}>
                                    <span className='date'>
                                        {item.path ? (getItemFromJsonPath(item?.path, getSourceOject(item.source), item) ? t('date', { date: parseISO(getItemFromJsonPath(item?.path, getSourceOject(item.source), item)) }) : '') : ''}
                                    </span>
                                    <span>&nbsp;</span>
                                    <span className='time'>
                                        {item.path ? (getItemFromJsonPath(item?.path, getSourceOject(item.source), item) ? t('time', { date: parseISO(getItemFromJsonPath(item?.path, getSourceOject(item.source), item)) }) : '') : ''}
                                    </span>
                                </div>
                            </div>
                        case 'dateOnly':
                            return <div className={item?.displayOptions?.twolines ? "item_twolines textarea" : "item textarea"} id={depth.toString()}>
                                {item.displayOptions && item.displayOptions.fieldset ?
                                    <fieldset className="fieldsetInfoPanel">
                                        {item?.displayOptions?.hideLegend ?
                                            null
                                            :
                                            <legend className="fieldsetInfoPanelLegend"
                                                style={item?.displayOptions?.styleLabel ? getStyleObjectFromString(item?.displayOptions?.styleLabel) : {}}>
                                                <span style={item?.displayOptions?.styleLabel ? getStyleObjectFromString(item?.displayOptions?.styleLabel) : {}}>{t(item.title)}</span>
                                                <span className="required" style={item?.displayOptions?.styleLabel ? getStyleObjectFromString(item?.displayOptions?.styleLabel) : {}}>
                                                    &nbsp;{`${!item?.readonly && item?.required ? '*' : ''}`}
                                                </span>
                                            </legend>
                                        }
                                        {item.displayOptions && item.displayOptions.hideTitle ? null :
                                            <span className="itemLabelWrapper">
                                                <label className="itemLabel"
                                                    style={item?.displayOptions?.styleLabel ? getStyleObjectFromString(item?.displayOptions?.styleLabel) : {}}>
                                                    {`${t(item.title)}`}
                                                </label>
                                                <span className="required" style={item?.displayOptions?.styleLabel ? getStyleObjectFromString(item?.displayOptions?.styleLabel) : {}}>
                                                    &nbsp;{`${!item?.readonly && item?.required ? '*' : ''}`}
                                                </span>
                                                <span style={item?.displayOptions?.styleLabel ? getStyleObjectFromString(item?.displayOptions?.styleLabel) : {}}>
                                                    &nbsp;{`${t(item.title) !== '' && !item?.displayOptions?.hideColon ? ':' : ''}`}
                                                </span>
                                            </span>}
                                        <div className="itemContent"
                                            style={item?.displayOptions?.styleContent ? getStyleObjectFromString(item?.displayOptions?.styleContent) : {}}>
                                            <span className='date'>
                                                {item.path ? (getItemFromJsonPath(item?.path, getSourceOject(item.source), item?.type) ? t('date', { date: parseISO(getItemFromJsonPath(item?.path, getSourceOject(item.source), item)) }) : '') : ''}
                                            </span>
                                        </div>
                                    </fieldset>
                                    :
                                    <>
                                        {item.displayOptions && item.displayOptions.hideTitle ? null :
                                            <label className="itemLabel"
                                                style={item?.displayOptions?.styleLabel ? getStyleObjectFromString(item?.displayOptions?.styleLabel) : {}}>
                                                {`${t(item.title)} ${(t(item.title) !== '') && !item?.displayOptions?.hideColon ? ':' : ''}`}
                                            </label>}
                                        <div className="itemContent"
                                            style={item?.displayOptions?.styleContent ? getStyleObjectFromString(item?.displayOptions?.styleContent) : {}}>
                                            <span className='date'>
                                                {item.path ? (getItemFromJsonPath(item?.path, getSourceOject(item.source), item?.type) ? t('date', { date: parseISO(getItemFromJsonPath(item?.path, getSourceOject(item.source), item)) }) : '') : ''}
                                            </span>
                                        </div>
                                    </>}
                            </div>
                        case 'jsx':
                            return getJsxWrapper(item, depth);
                        default:
                            return null
                    }
                })()}
            </span>
        )
    };

    return (
        <div className={props.className} onContextMenu={(e) => e.preventDefault()}
            style={configData?.displayOptions?.style ? getStyleObjectFromString(configData?.displayOptions?.style) : {}}>
            <div className={props.editMode ? "itemActionButtonGroup_visible" : "itemActionButtonGroup_invisible"}>
                <ItemActionButtonGroup
                    callbacks={{
                        downloadButtonClick: props.editMode && !(currentTheme === "ORmed") ? dotsButtonClick : undefined,
                        editButtonClick: isChanged || (currentTheme === "ORmed") ? editButtonClick : undefined,
                        finalizeStudyButtonClick: isChanged ? finalizeButtonClick : undefined,
                        closeButtonClick: props.editMode ? (props.confirmationTextlines && props.confirmationTextlines.closeButtonClick ? (evt: any) => { evt?.stopPropagation(); submit(props.confirmationTextlines!.closeButtonClick!, completeButtonClick) } : completeButtonClick) : undefined,
                        cancelButtonClick: props.editMode ? (props.confirmationTextlines && props.confirmationTextlines.cancelButtonClick ? (evt: any) => { evt?.stopPropagation(); submit(props.confirmationTextlines!.cancelButtonClick!, cancelButtonClick) } : undefined) : undefined,
                        resumeButtonClick: props.editMode ? (props.confirmationTextlines && props.confirmationTextlines.resumeButtonClick ? (evt: any) => { evt?.stopPropagation(); submit(props.confirmationTextlines!.resumeButtonClick!, props?.resumeWorkitem) } : props?.resumeWorkitem) : undefined,
                        recoverButtonClick: props.editMode ? (props.confirmationTextlines && props.confirmationTextlines.recoverButtonClick ? props.recoverImage : undefined) : undefined,

                    }}
                    titles={{
                        downloadButtonClick: t("exportImages") ?? '',
                        editButtonClick: t("editImage Info") ?? '',
                        closeButtonClick: t("completeWorkitem") ?? '',
                        cancelButtonClick: t("cancelWorkitem") ?? '',
                        finalizeStudyButtonClick: t("finalizeImages") ?? '',
                        resumeButtonClick: t("resumeWorkitem") ?? '',
                        recoverButtonClick: t("recoverWorkitem") ?? ''
                    }}
                    icons={{ editButtonIcon: "save-svgrepo-com.svg" }}
                />
            </div>
            {
                configData?.content?.map((item: any, i: number) =>
                    <React.Fragment key={i.toString()}>
                        {item?.content && Array.isArray(item?.content) ?
                            <span className="row"
                                style={item?.displayOptions?.style ? getStyleObjectFromString(item?.displayOptions?.style) : {}}>
                                {item?.content?.map((subItem: any, j: number) => getItemJsx(subItem, j, 2))}
                            </span>
                            :
                            getItemJsx(item, i, 1)
                        }
                    </React.Fragment>

                )
            }
        </div >
    );
};

export default InfoPanel;
