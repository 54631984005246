
import csTools from 'cornerstone-tools';
const external = csTools.external;
const getToolState = csTools.getToolState;
const removeToolState = csTools.removeToolState;
const BaseTool = csTools.importInternal('base/BaseTool');
const Cursors = csTools.importInternal('tools/cursors');


/**
 * @public
 * @class EraserExtendedTool
 * @memberof Tools
 *
 * @classdesc Tool for deleting the data of other Annotation Tools.
 * @extends Tools.Base.BaseTool
 */
export default class EraserExtendedTool extends BaseTool {
  constructor(props = {}) {
    const defaultProps = {
      name: 'EraserExtended',
      supportedInteractionTypes: ['Mouse', 'Touch'],
      svgCursor: Cursors.eraserCursor,
    };

    super(props, defaultProps);

    this.preMouseDownCallback = this._deleteAllNearbyTools.bind(this);
    this.preTouchStartCallback = this._deleteAllNearbyTools.bind(this);
  }

  _deleteAllNearbyTools(evt) {
    const coords = evt.detail.currentPoints.canvas;
    const element = evt.detail.element;

    csTools.store?.state.tools.forEach(function(tool) {
      const toolState = getToolState(element, tool.name);

      if (toolState) {
        // Modifying in a foreach? Probably not ideal
        toolState.data.forEach(function(data) {
          if (
            typeof tool.pointNearTool === 'function' &&
            tool.pointNearTool(element, data, coords) &&
              !(tool.name === 'LengthCalibration' && data?.measurementaccepted || tool.name === 'ResolutionMeasurement' && data?.locked)
          ) {
            removeToolState(element, tool.name, data);
            external.cornerstone.updateImage(element);
          }
        });
      }
    });

    const consumeEvent = true;

    return consumeEvent;
  }
}
