import './ErrorMessage.scss';

interface ErrorMessageProps {
    message: string;
}

const ErrorMessage = (props: ErrorMessageProps) => {

    return (
        <div>
			<p className="errorMessage">{props.message}</p>
		</div>
    );
}

export default ErrorMessage;
