import React, { useState } from "react";

import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";


import "./DatePicker.scss";
import { getImagePathForCurrentTheme } from "../OrderList/OrdersSlice";
import CommonDialog from "../Utils/CommonDialog";
import DateSelector from "./DateSelector";
import { format, parseISO } from "date-fns";
import { getSelectedSubject } from "./SubjectSlice";
import { useORTranslation } from "../Localization/ORLocalization";

type DatePickerProps = {
    displayOptionsString?: string;
    required?: boolean;
};

const useAppDispatch = () => useDispatch<AppDispatch>();
const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

const DatePicker = (props: DatePickerProps) => {

    const dispatch = useAppDispatch();

    const { t } = useORTranslation(['Subject', 'common']);

    const subject: any = useAppSelector((state) => getSelectedSubject(state));
    const imagePathForCurrentTheme: string = useAppSelector((state) => getImagePathForCurrentTheme(state));


    const [datePickerActive, setDatePickerActive] = useState<boolean>(false);
    const [selectedDate, setSelectedDate] = useState<Date>(new Date(Date.now()));

    const handleDatePickerClose = () => {
        setDatePickerActive(false);
    };

    const handleDateSelection = async (date: any) => {
        setSelectedDate(date);
        setDatePickerActive(false);
        const newSubject: any = structuredClone(subject);
        const { details } = newSubject;
        newSubject.details = { ...details, birthDate: format(date, "yyyy-MM-dd") };
        dispatch({ type: "Subject/setSelectedSubject", payload: newSubject });
    }

    const Image = React.memo(function Image() {
        return <img className="birthDateImg"
            src={`${imagePathForCurrentTheme}calender-svgrepo-com.svg`}
            onError={(event: any) => { event.target.src = "/images/calender-svgrepo-com.svg"; event.onerror = null }}
            alt="tag browser" />;
    });

    const displayOptions = props?.displayOptionsString !== undefined ? JSON.parse(props?.displayOptionsString) : undefined;

    return (
        <div className="birthDate">
            {displayOptions && displayOptions.fieldset ?
                <fieldset className="fieldsetDatePicker">
                    {displayOptions?.hideLegend ?
                        null
                        :
                        <legend className="fieldsetLegendDatePicker">
                            <span >{t('Birth date', {ns: 'InfoPanel'})}</span>
                            <span className="required" >
                                &nbsp;{`${props.required ? '*' : ''}`}
                            </span>
                        </legend>
                    }
                    {/* <label>{t('date', { date: parseISO(subject?.details?.birthDate), ns: 'InfoPanel' })}</label> */}
                    <div className="datePickerContentEditable_edit">
                        <input type="text" className="input" value={(parseISO(subject?.details?.birthDate)).toLocaleDateString() !== 'Invalid Date' ? t('date', { date: parseISO(subject?.details?.birthDate), ns: 'InfoPanel' }).toString() : ''}
                            placeholder={`Birth date:`}
                            readOnly={true} />
                    </div>

                    <button className="birthDateButton venaDefaultButton" onClick={() => setDatePickerActive(true)} >
                        <Image />
                    </button>
                </fieldset> :
                <>
                    {displayOptions && displayOptions.twolines ?
                        <div className="divDatePicker_twolines">
                            <label  className="itemLabel">{t('Birth date', {ns: 'InfoPanel'})}</label>
                            <span className="datePicker_input">
                                <button className="birthDateButton venaDefaultButton" onClick={() => setDatePickerActive(true)} >
                                    <Image />
                                </button>
                                <div className="datePickerContentEditable_edit">
                                    <input type="text" className="input" value={(parseISO(subject?.details?.birthDate)).toLocaleDateString() !== 'Invalid Date' ? t('date', { date: parseISO(subject?.details?.birthDate), ns: 'InfoPanel' }).toString() : ''}
                                        readOnly={true} />
                                </div>
                            </span>
                        </div> :
                        <div className="divDatePicker">
                            {displayOptions && displayOptions.hideTitle ?
                                null
                                :
                                <label>{t('Birth date', {ns: 'InfoPanel'})}</label>
                            }

                            {/* <label>{t('date', { date: parseISO(subject?.details?.birthDate), ns: 'InfoPanel' })}</label> */}
                            <div className="datePickerContentEditable_edit">
                                <input type="text" className="input" value={(parseISO(subject?.details?.birthDate)).toLocaleDateString() !== 'Invalid Date' ? t('date', { date: parseISO(subject?.details?.birthDate), ns: 'InfoPanel' }).toString() : ''}
                                    readOnly={true} />
                            </div>
                            <button className="birthDateButton venaDefaultButton" onClick={() => setDatePickerActive(true)} >
                                <Image />
                            </button>
                        </div>}
                </>
            }
            <CommonDialog zIndex={101} onClose={handleDatePickerClose} title={t('Birth Date')} visible={datePickerActive} okText={''} cancelText={t("cancel", { ns: 'common' })}
                default={{ x: (window.innerWidth - 450) / 20, y: (window.innerHeight - 450) / 2, width: 355, height: undefined }} childProps={{}}>
                <DateSelector date={selectedDate}
                    handleDateSelection={(ranges: any) => handleDateSelection(ranges)}
                />
            </CommonDialog>
        </div>
    );
};

export default DatePicker;
