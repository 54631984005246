import {createSlice} from '@reduxjs/toolkit'

export interface IoverviewVisibility { 
    isOverviewVisible: boolean;   
}

const initialState: IoverviewVisibility = {
    isOverviewVisible: true,   
}

const OverviewSlice = createSlice({
    name: 'Overview',
    initialState,
    reducers: {
        changeOverviewVisible(state, action) {
            const isOverviewVisible: boolean = action.payload;
            state.isOverviewVisible = isOverviewVisible;
        },       
    }
});

export const {changeOverviewVisible} = OverviewSlice.actions;
export default OverviewSlice.reducer;
