import csTools from 'cornerstone-tools';
import { getPixels, calculateFreehandStatistics, freehandArea } from "./utils";
import store from '../../../store';
import { selectedAnnotations } from '../../OrderList/OrdersSlice';
import { t } from "../../Localization/ORLocalization";
const getNewContext = csTools.import("drawing/getNewContext");
const getPixelSpacing = csTools.importInternal('util/getPixelSpacing');
const calculateSUV = csTools.importInternal('util/calculateSUV');
const draw = csTools.import("drawing/draw");
const drawJoinedLines = csTools.import("drawing/drawJoinedLines");
const drawHandles = csTools.import("drawing/drawHandles");
const drawLinkedTextBox = csTools.import("drawing/drawLinkedTextBox");
const numbersWithCommas = csTools.import("util/numbersWithCommas");
const getToolState = csTools.getToolState;
const getLogger = csTools.importInternal('util/getLogger');
const throttle = csTools.importInternal('util/throttle');
const setShadow = csTools.import("drawing/setShadow");

const logger = getLogger('tools:annotation:FreehandRoiExtendedTool');


/**
 * @public
 * @class RectangleRoiTool
 * @memberof Tools
 *
 * @classdesc Tool for annotating/measuring in a polygonal region.
 * @extends Tools.Annotation.FreehandRoiTool
 */
export default class FreehandRoiExtendedTool extends csTools.FreehandRoiTool {
  /** @inheritdoc */
  constructor(props = {}) {
    const defaultProps = {
      name: 'FreehandRoiExtended',
      supportedInteractionTypes: ['Mouse', 'Touch'],
      configuration: {
        showMinMax: true,
        // showHounsfieldUnits: true,
        drawHandlesOnHover: props.configuration.drawHandlesOnHover ?? false,
        hideHandlesIfMoving: false,
        renderDashed: false,
        shadow: true,
      },
    };
    super(defaultProps);

    // Create bound callback functions for private event loops
    this._drawingMouseDownCallback = this._drawingMouseDownCallback.bind(this);
    this._drawingMouseMoveCallback = this._drawingMouseMoveCallback.bind(this);
    this._drawingMouseDragCallback = this._drawingMouseDragCallback.bind(this);
    this._drawingMouseUpCallback = this._drawingMouseUpCallback.bind(this);
    this._drawingMouseDoubleClickCallback = this._drawingMouseDoubleClickCallback.bind(
      this
    );
    this._editMouseUpCallback = this._editMouseUpCallback.bind(this);
    this._editMouseDragCallback = this._editMouseDragCallback.bind(this);

    this._drawingTouchStartCallback = this._drawingTouchStartCallback.bind(
      this
    );
    this._drawingTouchDragCallback = this._drawingTouchDragCallback.bind(this);
    this._drawingDoubleTapClickCallback = this._drawingDoubleTapClickCallback.bind(
      this
    );
    this._editTouchDragCallback = this._editTouchDragCallback.bind(this);

    this.throttledUpdateCachedStats = throttle(this.updateCachedStats, 110);



    this.pixelToLengthFactor = undefined;
    const scaleFactor = undefined;
  }

  renderToolData(evt) {
    const eventData = evt.detail;

    // If we have no toolState for this element, return immediately as there is nothing to do
    const toolState = csTools.getToolState(evt.currentTarget, this.name);
    const pixelToLengthFactor = this.pixelToLengthFactor;
    const scaleFactor = this.scaleFactor;

    if (!toolState) {
      return;
    }

    const { image, element } = eventData;
    const config = this.configuration;
    const seriesModule = csTools.external.cornerstone.metaData.get(
      'generalSeriesModule',
      image.imageId
    );
    const modality = seriesModule ? seriesModule.modality : null;

    // We have tool data for this element - iterate over each one and draw it
    const context = getNewContext(eventData.canvasContext.canvas);
    const lineWidth = csTools.toolStyle.getToolWidth();
    const { renderDashed } = config;
    const lineDash = csTools.getModule('globalConfiguration').configuration.lineDash;

    for (let i = 0; i < toolState.data.length; i++) {
      const data = toolState.data[i];

      if (data.visible === false) {
        continue;
      }

      draw(context, context => {
        let color = csTools.toolColors.getColorIfActive(data);
        let fillColor;

        if (data.active) {
          if (data.handles.invalidHandlePlacement) {
            color = config.invalidColor;
            fillColor = config.invalidColor;
          } else {
            color = csTools.toolColors.getColorIfActive(data);
            fillColor = csTools.toolColors.getFillColor();
          }
        } else {
          fillColor = csTools.toolColors.getToolColor();
        }

        let options = { color };

        if (renderDashed) {
          options.lineDash = lineDash;
        }

        setShadow(context, this.configuration);

        if (data.handles.points.length) {
          const points = data.handles.points;

          drawJoinedLines(context, element, points[0], points, options);

          if (data.polyBoundingBox) {
            drawJoinedLines(
              context,
              element,
              points[points.length - 1],
              [points[0]],
              options
            );
          } else {
            drawJoinedLines(
              context,
              element,
              points[points.length - 1],
              [config.mouseLocation.handles.start],
              options
            );
          }
        }

        // Draw handles

        options = {
          color,
          fill: fillColor,
        };

        if (config.alwaysShowHandles || (data.active && data.polyBoundingBox)) {
          // Render all handles
          options.handleRadius = config.activeHandleRadius;

          if (this.configuration.drawHandles) {
            drawHandles(context, eventData, data.handles.points, options);
          }
        }

        if (data.canComplete) {
          // Draw large handle at the origin if can complete drawing
          options.handleRadius = config.completeHandleRadius;
          const handle = data.handles.points[0];

          if (this.configuration.drawHandles) {
            drawHandles(context, eventData, [handle], options);
          }
        }

        if (data.active && !data.polyBoundingBox) {
          // Draw handle at origin and at mouse if actively drawing
          options.handleRadius = config.activeHandleRadius;

          if (this.configuration.drawHandles) {
            drawHandles(
              context,
              eventData,
              config.mouseLocation.handles,
              options
            );
          }

          const firstHandle = data.handles.points[0];

          if (this.configuration.drawHandles) {
            drawHandles(context, eventData, [firstHandle], options);
          }
        }

        // Update textbox stats
        if (data.invalidated === true && !data.active) {
          if (data.meanStdDev && data.meanStdDevSUV && data.area) {
            this.throttledUpdateCachedStats(image, element, data);
          } else {
            this.updateCachedStats(image, element, data);
          }
        }

        // Only render text if polygon ROI has been completed and freehand 'shiftKey' mode was not used:
        if (data.polyBoundingBox && !data.handles.textBox.freehand) {
          // If the textbox has not been moved by the user, it should be displayed on the right-most
          // Side of the tool.
          if (!data.handles.textBox.hasMoved) {
            // Find the rightmost side of the polyBoundingBox at its vertical center, and place the textbox here
            // Note that this calculates it in image coordinates
            data.handles.textBox.x =
              data.polyBoundingBox.left + data.polyBoundingBox.width;
            data.handles.textBox.y =
              data.polyBoundingBox.top + data.polyBoundingBox.height / 2;
          }

          const text = textBoxText.call(this, data);

          drawLinkedTextBox(
            context,
            element,
            data.handles.textBox,
            text,
            data.handles.points,
            textBoxAnchorPoints,
            color,
            lineWidth,
            0,
            true
          );
        }
      });
    }

    function textBoxText(data) {
      const { meanStdDev, meanStdDevSUV, area } = data;
      // Define an array to store the rows of text for the textbox
      let textLines = [];

      // If the mean and standard deviation values are present, display them
      if (meanStdDev && meanStdDev.mean !== undefined) {
        // If the modality is CT, add HU to denote Hounsfield Units
        let moSuffix = '';

        if (modality === 'CT') {
          moSuffix = 'HU';
        }
        data.unit = moSuffix;

        // Create a line of text to display the mean and any units that were specified (i.e. HU)
        let meanText = `${t('mean', {ns: 'Tools'})}: ${numbersWithCommas(
          meanStdDev.mean.toFixed(2)
        )} ${moSuffix}`;
        // Create a line of text to display the standard deviation and any units that were specified (i.e. HU)
        let stdDevText = `${t('stdDev', {ns: 'Tools'})}: ${numbersWithCommas(
          meanStdDev.stdDev.toFixed(2)
        )} ${moSuffix}`;

        let SNRText = `SNR: ${numbersWithCommas(
          (meanStdDev.mean / meanStdDev.stdDev)?.toFixed(2)
        )} `;

        // If this image has SUV values to display, concatenate them to the text line
        if (meanStdDevSUV && meanStdDevSUV.mean !== undefined) {
          const SUVtext = ' SUV: ';

          meanText +=
            SUVtext + numbersWithCommas(meanStdDevSUV.mean.toFixed(2));
          stdDevText +=
            SUVtext + numbersWithCommas(meanStdDevSUV.stdDev.toFixed(2));
        }

        // (((mean / stdDev) * 0.886) / (resolution * 10.0))?.toFixed(2)
        const SNRnString = data?.resolution ? `SNRn: ${numbersWithCommas(
          (((meanStdDev.mean / meanStdDev.stdDev) * 0.886) / (data?.resolution * 10.0))?.toFixed(2)
        )}` : '';

        // Add these text lines to the array to be displayed in the textbox
        textLines.push(meanText);
        textLines.push(stdDevText);
        textLines.push(SNRText);
        if (data?.resolution && data?.resolution !== '') textLines.push(`${SNRnString}`);
      }

      // If the area is a sane value, display it
      if (area) {
        // Determine the area suffix based on the pixel spacing in the image.
        // If pixel spacing is present, use millimeters. Otherwise, use pixels.
        // This uses Char code 178 for a superscript 2
        let suffix = ` mm${String.fromCharCode(178)}`;

        const { rowPixelSpacing, colPixelSpacing } = getPixelSpacing(image);

        if ((!rowPixelSpacing || !colPixelSpacing) && pixelToLengthFactor === undefined) {
          suffix = ` pixels${String.fromCharCode(178)}`;
        }

        // Create a line of text to display the area and its units
        const areaText = `${t('area', {ns: 'Tools'})}: ${numbersWithCommas(area.toFixed(2))}${suffix}`;

        // Add this text line to the array to be displayed in the textbox
        textLines = [areaText, ...textLines];
      }

      return textLines;
    }

    function textBoxAnchorPoints(handles) {
      return handles;
    }
  }

  updateCachedStats(image, element, data) {
    if (data?.toolName !== this.name) return;
    // Define variables for the area and mean/standard deviation
    let meanStdDev, meanStdDevSUV;

    const seriesModule = csTools.external.cornerstone.metaData.get(
      'generalSeriesModule',
      image.imageId
    );
    const modality = seriesModule ? seriesModule.modality : null;

    const imageId = image?.targetId;
    const annotations = selectedAnnotations(store.getState(), imageId);
    const resolution = annotations?.find((annotation) => annotation?.body?.type === "ResolutionMeasurementResult")?.body?.value?.resolution;

    data.resolution = parseFloat(resolution);

    const points = data.handles.points;
    // If the data has been invalidated, and the tool is not currently active,
    // We need to calculate it again.

    // Retrieve the bounds of the ROI in image coordinates
    const bounds = {
      left: points[0].x,
      right: points[0].x,
      bottom: points[0].y,
      top: points[0].x,
    };

    for (let i = 0; i < points.length; i++) {
      bounds.left = Math.min(bounds.left, points[i].x);
      bounds.right = Math.max(bounds.right, points[i].x);
      bounds.bottom = Math.min(bounds.bottom, points[i].y);
      bounds.top = Math.max(bounds.top, points[i].y);
    }

    const polyBoundingBox = {
      left: bounds.left,
      top: bounds.bottom,
      width: Math.abs(bounds.right - bounds.left),
      height: Math.abs(bounds.top - bounds.bottom),
    };

    // Store the bounding box information for the text box
    data.polyBoundingBox = polyBoundingBox;

    // First, make sure this is not a color image, since no mean / standard
    // Deviation will be calculated for color images.
    if (!image.color) {
      // Retrieve the array of pixels that the ROI bounds cover
      const pixels = getPixels(
        element, image?.image_raw ? image.image_raw : image,
        polyBoundingBox.left,
        polyBoundingBox.top,
        polyBoundingBox.width,
        polyBoundingBox.height
      );

      // Calculate the mean & standard deviation from the pixels and the object shape
      meanStdDev = calculateFreehandStatistics.call(
        this,
        pixels,
        polyBoundingBox,
        data.handles.points
      );

      if (modality === 'PT') {
        // If the image is from a PET scan, use the DICOM tags to
        // Calculate the SUV from the mean and standard deviation.

        // Note that because we are using modality pixel values from getPixels, and
        // The calculateSUV routine also rescales to modality pixel values, we are first
        // Returning the values to storedPixel values before calcuating SUV with them.
        // TODO: Clean this up? Should we add an option to not scale in calculateSUV?
        meanStdDevSUV = {
          mean: calculateSUV(
            image,
            (meanStdDev.mean - image.intercept) / image.slope
          ),
          stdDev: calculateSUV(
            image,
            (meanStdDev.stdDev - image.intercept) / image.slope
          ),
        };
      }

      // If the mean and standard deviation values are sane, store them for later retrieval
      if (meanStdDev && !isNaN(meanStdDev.mean)) {
        data.meanStdDev = meanStdDev;
        data.meanStdDevSUV = meanStdDevSUV;
      }
    }

    // Retrieve the pixel spacing values, and if they are not
    // Real non-zero values, set them to 1
    const { colPixelSpacing, rowPixelSpacing } = getPixelSpacing(image);
    let scaling = (colPixelSpacing || 1) * (rowPixelSpacing || 1) * (this.scaleFactor ?? 1) * (this.scaleFactor ?? 1);
    if (this.pixelToLengthFactor !== undefined) {
      scaling = this.pixelToLengthFactor.col * this.pixelToLengthFactor.row * (this.scaleFactor ?? 1) * (this.scaleFactor ?? 1);
    }

    const area = freehandArea(data.handles.points, scaling);

    // If the area value is sane, store it for later retrieval
    if (!isNaN(area)) {
      data.area = area;
    }

    // Set the invalidated flag to false so that this data won't automatically be recalculated
    data.invalidated = false;
  }

  restore(element, data) {
    var xs = data.handles.points[0].x;
    var ys = data.handles.points[0].y;

    let measurement = super.createNewMeasurement(
      {
        currentPoints: { image: { x: xs, y: ys } },
        viewport: { rotation: undefined },
        element: element
      });

    measurement.handles.points = JSON.parse(JSON.stringify(data.handles.points));
    measurement.handles.textBox = JSON.parse(JSON.stringify(data.handles.textBox));

    return measurement;
  }

  store(toolData) {
    toolData.storeToolData = {
      "handles": toolData.handles,
    }
  }

  setLenghtCalibration(element, pixelToLengthFactor) {
    const toolData = getToolState(element?.element, this.name);
    this.pixelToLengthFactor = global.structuredClone(pixelToLengthFactor);

    if (!toolData) {
      return;
    }

    for (let i = 0; i < toolData.data.length; i++) {
      this.updateCachedStats(element?.image, element?.element, toolData.data[i]);
    }
  }

  setScaleCalibration(element, scaleFactor) {
		const toolData = getToolState(element?.element, this.name);
		this.scaleFactor = scaleFactor;

		if (!toolData) {
			return;
		}

		for (let i = 0; i < toolData.data.length; i++) {
			this.updateCachedStats(element?.image, element?.element, toolData.data[i]);
		}
	}
}
