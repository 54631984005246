import csTools from 'cornerstone-tools';
import cornerstone from "cornerstone-core";

const BaseTool = csTools.importInternal('base/BaseTool');


/**
 * @public
 * @class DarkerTool
 * @memberof Tools
 *
 * @classdesc Tool for modifiying the window level so that the image appears darker
 * @extends Tools.Base.BaseTool
 */
export default class DarkerTool extends BaseTool {
    /** @inheritdoc */
	constructor(props = {}) {
		const defaultProps = {
			name: 'Darker',
		};
		super(props, defaultProps);
	}

	activeCallback(element) {
		let viewport = cornerstone.getViewport(element);		

		let d = Math.round(viewport.voi.windowWidth * 0.1);

		if (false) {
			viewport.voi.windowCenter += (d / 2);
			viewport.voi.windowWidth += d;
		} else {
			viewport.voi.windowCenter += d;
		}
		// Unset any existing VOI LUT
		viewport.voiLUT = undefined;

		cornerstone.setViewport(element, viewport);

		const eventData = {
			toolName: "StoreViewport",
			toolType: "StoreViewport",
			element: element,
			measurementData: undefined,
		};
		// @ts-ignore
		cornerstone.triggerEvent(element, csTools.EVENTS.MEASUREMENT_COMPLETED, eventData);
	}
}
