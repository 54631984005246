import csTools from 'cornerstone-tools';
import cornerstone from "cornerstone-core";
import { getPixels } from "./utils";
import { selectedAnnotations } from '../../OrderList/OrdersSlice';

import store from '../../../store';

const BaseAnnotationTool = csTools.importInternal('base/BaseAnnotationTool');
const getNewContext = csTools.import("drawing/getNewContext");
const path = csTools.import("drawing/path");
const getPixelSpacing = csTools.importInternal('util/getPixelSpacing');
const calculateSUV = csTools.importInternal('util/calculateSUV');
const draw = csTools.import("drawing/draw");
const setShadow = csTools.import("drawing/setShadow");
const drawRect = csTools.import("drawing/drawRect");
const drawHandles = csTools.import("drawing/drawHandles");
const drawLinkedTextBox = csTools.import("drawing/drawLinkedTextBox");
const getROITextBoxCoords = csTools.import("util/getROITextBoxCoords");
const numbersWithCommas = csTools.import("util/numbersWithCommas");
const getToolState = csTools.getToolState;
const Cursors = csTools.importInternal('tools/cursors');
const rectangleRoiCursor = Cursors.rectangleRoiCursor;
const throttle = csTools.importInternal('util/throttle');
const getLogger = csTools.importInternal('util/getLogger');
const external = csTools.external;
const getHandleNearImagePoint = csTools.import("manipulators/getHandleNearImagePoint");

const logger = getLogger('tools:annotation:SNRTool');

/**
 * @public
 * @class SNRTool
 * @memberof Tools
 *
 * @classdesc Tool for setting wwwc based on a rectangular region.
 * @extends Tools.Annotation.EllipticalRoiTool
 */
export default class SNRTool extends BaseAnnotationTool {
  /** @inheritdoc */
  constructor(props = {}) {
    const defaultProps = {
      name: 'SNR',
      supportedInteractionTypes: ['Mouse', 'Touch'],
      configuration: {
        drawHandles: true,
        //drawHandlesOnHover: true,
        hideHandlesIfMoving: true,
        renderDashed: false,
        // showMinMax: false,
        // showHounsfieldUnits: true,
        shadow: true,
      },
      svgCursor: rectangleRoiCursor,
    };

    super(props, defaultProps);
    this.throttledUpdateCachedStats = throttle(this.updateCachedStats, 110);

  }

  getStartHandle(endHandle, rotated) {
    if (rotated) {
      return { x: endHandle.x + 55, y: endHandle.y + 20 }
    } else {
      return { x: endHandle.x + 20, y: endHandle.y + 55 }
    }
  }

  createNewMeasurement(eventData) {

    /* const toolData = getToolState(eventData?.element, this.name);
    if(toolData  && (toolData?.data && toolData?.data.length > 0)) {
      return;
    } */

    const goodEventData =
      eventData && eventData.currentPoints && eventData.currentPoints.image;

    if (!goodEventData) {
      logger.error(
        `required eventData not supplied to tool ${this.name}'s createNewMeasurement`
      );

      return;
    }

    return {
      visible: true,
      active: true,
      color: undefined,
      invalidated: true,
      rotated: false,
      handles: {
        /*  start: {
           x: eventData.currentPoints.image.x,
           y: eventData.currentPoints.image.y,
           highlight: true,
           active: false,
         }, */
        end: {
          x: eventData.currentPoints.image.x,
          y: eventData.currentPoints.image.y,
          highlight: true,
          active: true,
        },
        /* initialRotation: eventData.viewport.rotation,
        textBox: {
          active: false,
          hasMoved: false,
          movesIndependently: false,
          drawnIndependently: true,
          allowedOutsideImage: true,
          hasBoundingBox: true,
        }, */
      },
    };
  }


  handleSelectedCallback(evt, toolData, handle, interactionType = 'mouse') {
    evt.stopImmediatePropagation();
    evt.stopPropagation();
    evt.preventDefault();
    toolData.rotated = !toolData.rotated;
    cornerstone.updateImage(evt.target);
    const modifiedEventData = {
      toolName: this.name,
      toolType: this.name,
      element: evt.target,
      measurementData: toolData,
    };
    cornerstone.triggerEvent(evt.target, csTools.EVENTS.MEASUREMENT_COMPLETED, modifiedEventData);
    //moveHandleNearImagePoint(evt, this, toolData, handle, interactionType);

  }

  pointNearTool(element, data, coords, interactionType) {
    const hasEndHandles =
      data && data.handles && data.handles.end;
    const validParameters = hasEndHandles;

    if (!validParameters) {
      logger.warn(
        `invalid parameters supplied to tool ${this.name}'s pointNearTool`
      );
    }

    if (!validParameters || data.visible === false) {
      return false;
    }

    const handleNearImagePoint = getHandleNearImagePoint(
      element,
      data.handles,
      coords,
      6
    );

    if (handleNearImagePoint) {
      //return false;
    }

    const distance = interactionType === 'mouse' ? 15 : 25;
    const startCanvas = cornerstone.pixelToCanvas(
      element,
      this.getStartHandle(data.handles.end, data?.rotated)
    );
    const endCanvas = cornerstone.pixelToCanvas(
      element,
      data.handles.end
    );

    const rect = {
      left: Math.min(startCanvas.x, endCanvas.x),
      top: Math.min(startCanvas.y, endCanvas.y),
      width: Math.abs(startCanvas.x - endCanvas.x),
      height: Math.abs(startCanvas.y - endCanvas.y),
    };

    const distanceToPoint = external.cornerstoneMath.rect.distanceToPoint(
      rect,
      coords
    );

    return distanceToPoint < distance;
  }

  renderToolData(evt) {
    const toolData = csTools.getToolState(evt.currentTarget, this.name);

    if (!toolData) {
      return;
    }

    const eventData = evt.detail;
    const { image, element } = eventData;
    const lineWidth = csTools.toolStyle.getToolWidth();
    const lineDash = csTools.getModule('globalConfiguration').configuration.lineDash;
    const {
      handleRadius,
      drawHandlesOnHover,
      hideHandlesIfMoving,
      renderDashed,
    } = this.configuration;
    const context = getNewContext(eventData.canvasContext.canvas);
    const { rowPixelSpacing, colPixelSpacing } = getPixelSpacing(image);

    // Meta
    const seriesModule =
      csTools.external.cornerstone.metaData.get('generalSeriesModule', image.imageId) ||
      {};

    // Pixel Spacing
    const modality = seriesModule.modality;
    const hasPixelSpacing = rowPixelSpacing && colPixelSpacing;
    const pixelToLengthFactor = this.pixelToLengthFactor;


    draw(context, context => {
      // If we have tool data for this element - iterate over each set and draw it
      for (let i = 0; i < toolData.data.length; i++) {
        const data = toolData.data[i];

        if (data.visible === false) {
          continue;
        }

        // Configure
        const color = csTools.toolColors.getColorIfActive(data);
        const handleOptions = {
          color,
          handleRadius,
          drawHandlesIfActive: drawHandlesOnHover,
          hideHandlesIfMoving,
        };

        setShadow(context, this.configuration);

        const rectOptions = { color };

        if (renderDashed) {
          rectOptions.lineDash = lineDash;
        }



        // Draw
        drawRect(
          context,
          element,
          data.handles.end,
          this.getStartHandle(data.handles.end, data?.rotated),
          rectOptions,
          'pixel',
          0
        );

        if (this.configuration.drawHandles && data.active) {
          this.drawHandles(context, eventData, data.handles, handleOptions);
        }

        // Update textbox stats
        if (data.invalidated === true) {
          if (data.cachedStats) {
            this.throttledUpdateCachedStats(image, element, data);
          } else {
            this.updateCachedStats(image, element, data);
          }
        }

        const textBox = {
          active: false,
          hasMoved: false,
          movesIndependently: false,
          drawnIndependently: true,
          allowedOutsideImage: true,
          hasBoundingBox: true,
        }

        // Default to textbox on right side of ROI
        if (!textBox.hasMoved) {
          const defaultCoords = getROITextBoxCoords(
            eventData.viewport,
            { start: this.getStartHandle(data.handles.end, data?.rotated), end: data.handles.end }
          );

          Object.assign(textBox, defaultCoords);
        }

        const textBoxAnchorPoints = handles =>
          _findTextBoxAnchorPoints(handles.start, handles.end, data.rotated);
        if (data.cachedStats) {
          const textBoxContent = _createTextBoxContent(
            context,
            image.color,
            data.cachedStats,
            modality,
            hasPixelSpacing,
            pixelToLengthFactor,
            this.configuration
          );

          data.unit = _getUnit(modality, this.configuration.showHounsfieldUnits);

          drawLinkedTextBox(
            context,
            element,
            textBox,
            textBoxContent,
            data.handles,
            textBoxAnchorPoints,
            color,
            lineWidth,
            10,
            true
          );
        }
      }
    });
  }

  drawHandles(context, evtDetail, handles, options = {}) {
    const element = evtDetail.element;
    const defaultColor = csTools.toolColors.getToolColor();

    context.strokeStyle = options.color || defaultColor;

    const handleKeys = Object.keys(handles);

    for (let i = 0; i < handleKeys.length; i++) {
      const handleKey = handleKeys[i];
      const handle = handles[handleKey];

      if (handle.drawnIndependently === true) {
        continue;
      }

      if (options.drawHandlesIfActive === true && !handle.active) {
        continue;
      }
      if (options.hideHandlesIfMoving && handle.moving) {
        continue;
      }

      const lineWidth = handle.active
        ? csTools.toolStyle.getActiveWidth()
        : csTools.toolStyle.getToolWidth();
      const fillStyle = options.fill;

      const pathOptions = { lineWidth, fillStyle };

      if (options.lineDash) {
        pathOptions.lineDash = options.lineDash;
      }

      path(context, pathOptions, context => {
        const handleCanvasCoords = external.cornerstone.pixelToCanvas(
          element,
          handle
        );

        // Handle's radisu, then tool's radius, then default radius
        const handleRadius =
          handle.radius || options.handleRadius || csTools.store?.state?.handleRadius;

        context.arc(
          handleCanvasCoords.x - 5,
          handleCanvasCoords.y - 5,
          handleRadius,
          0.5 * Math.PI,
          2.0 * Math.PI
        );
        context.moveTo(handleCanvasCoords.x - 4 + csTools.store?.state?.handleRadius, handleCanvasCoords.y - 5);
        context.lineTo(handleCanvasCoords.x - 5, handleCanvasCoords.y - 5);
        context.moveTo(handleCanvasCoords.x - 4 + csTools.store?.state?.handleRadius, handleCanvasCoords.y - 5);
        context.lineTo(handleCanvasCoords.x - 4 + csTools.store?.state?.handleRadius, handleCanvasCoords.y - 13);


      });
    }


  }

  updateCachedStats(image, element, data) {
    if (data?.toolName !== this.name) return;
    const seriesModule =
      csTools.external.cornerstone.metaData.get('generalSeriesModule', image.imageId) ||
      {};
    const modality = seriesModule.modality;
    const pixelSpacing = getPixelSpacing(image);

    _calculateStats = _calculateStats.bind(this);

    const stats = _calculateStats(
      image?.image_raw ? image.image_raw : image,
      image,
      element,
      data.handles,
      modality,
      pixelSpacing,
      this.pixelToLengthFactor,
      data.rotated,
    );



    data.cachedStats = stats;
    data.invalidated = false;
  }

  restore(element, data) {
    var xs = data.handles.end.x + this.getStartHandle(data.handles.end, data?.rotated).x;
    var ys = data.handles.end.y + this.getStartHandle(data.handles.end, data?.rotated).y;

    let measurement = this.createNewMeasurement(
      {
        currentPoints: { image: { x: xs, y: ys } },
        viewport: { rotation: undefined },
        element: element
      });

    measurement.handles.end.x = data.handles.end.x;
    measurement.handles.end.y = data.handles.end.y;
    measurement.rotated = data?.rotated;

    return measurement;
  }

  store(toolData) {
    toolData.storeToolData = {
      "handles": toolData.handles,
      "rotated": toolData?.rotated,
    }
  }

  setLenghtCalibration(element, pixelToLengthFactor) {
    const toolData = getToolState(element?.element, this.name);
    this.pixelToLengthFactor = global.structuredClone(pixelToLengthFactor);

    if (!toolData) {
      return;
    }

    for (let i = 0; i < toolData.data.length; i++) {
      this.updateCachedStats(element?.image, element?.element, toolData.data[i]);
    }
  }

}

function _getRectangleImageCoordinates(startHandle, endHandle) {
  return {
    left: Math.min(startHandle.x, endHandle.x),
    top: Math.min(startHandle.y, endHandle.y),
    width: Math.abs(startHandle.x - endHandle.x),
    height: Math.abs(startHandle.y - endHandle.y),
  };
}

function _formatArea(area, hasPixelSpacing, pixelToLengthFactor) {
  // This uses Char code 178 for a superscript 2
  const suffix = hasPixelSpacing || pixelToLengthFactor !== undefined
    ? ` mm${String.fromCharCode(178)}`
    : ` px${String.fromCharCode(178)}`;

  return `Area: ${numbersWithCommas(area.toFixed(2))}${suffix}`;
}

function _getUnit(modality, showHounsfieldUnits) {
  return modality === 'CT' && showHounsfieldUnits !== false ? 'HU' : '';
}


function _findTextBoxAnchorPoints(startHandle, endHandle, rotated) {
  const { left, top, width, height } = _getRectangleImageCoordinates(
    this.getStartHandle(endHandle, rotated),
    endHandle
  );

  return [
    {
      // Top middle point of rectangle
      x: left + width / 2,
      y: top,
    },
    {
      // Left middle point of rectangle
      x: left,
      y: top + height / 2,
    },
    {
      // Bottom middle point of rectangle
      x: left + width / 2,
      y: top + height,
    },
    {
      // Right middle point of rectangle
      x: left + width,
      y: top + height / 2,
    },
  ];
}

function _createTextBoxContent(
  context,
  isColorImage,
  { area, mean, stdDev, min, max, meanStdDevSUV, resolution },
  modality,
  hasPixelSpacing,
  pixelToLengthFactor,
  options = {}
) {
  const showMinMax = options.showMinMax || false;
  const textLines = [];

  const otherLines = [];

  if (!isColorImage) {
    const hasStandardUptakeValues = meanStdDevSUV && meanStdDevSUV.mean !== 0;
    const unit = _getUnit(modality, options.showHounsfieldUnits);

    let meanString = `Mean: ${numbersWithCommas(mean.toFixed(2))} ${unit}`;
    const stdDevString = `Std Dev: ${numbersWithCommas(
      stdDev.toFixed(2)
    )} ${unit}`;

    const SNRString = `SNR: ${numbersWithCommas(
      (mean / stdDev)?.toFixed(2)
    )}`;

    const SNRnString = `SNRn: ${numbersWithCommas(
      (((mean / stdDev) * 0.886) / (resolution * 10.0))?.toFixed(2)
    )}`;

    // If this image has SUV values to display, concatenate them to the text line
    if (hasStandardUptakeValues) {
      const SUVtext = ' SUV: ';

      const meanSuvString = `${SUVtext}${numbersWithCommas(
        meanStdDevSUV.mean.toFixed(2)
      )}`;
      const stdDevSuvString = `${SUVtext}${numbersWithCommas(
        meanStdDevSUV.stdDev.toFixed(2)
      )}`;

      const targetStringLength = Math.floor(
        context.measureText(`${stdDevString}     `).width
      );

      while (context.measureText(meanString).width < targetStringLength) {
        meanString += ' ';
      }

      otherLines.push(`${meanString}${meanSuvString}`);
      otherLines.push(`${stdDevString}     ${stdDevSuvString}`);
    } else {
      otherLines.push(`${meanString}`);
      otherLines.push(`${stdDevString}`);
      otherLines.push(`${SNRString}`);
    }
    if (resolution && resolution !== '') otherLines.push(`${SNRnString}`);

    if (showMinMax) {
      let minString = `Min: ${min} ${unit}`;
      const maxString = `Max: ${max} ${unit}`;
      const targetStringLength = hasStandardUptakeValues
        ? Math.floor(context.measureText(`${stdDevString} `).width)
        : Math.floor(context.measureText(`${meanString} `).width);

      /* while (context.measureText(minString).width < targetStringLength) {
        minString += ' ';
      } */
      minString += ' ';

      otherLines.push(`${minString}${maxString}`);
    }
  }

  //textLines.push(_formatArea(area, hasPixelSpacing, pixelToLengthFactor));
  otherLines.forEach(x => textLines.push(x));

  return textLines;
}

function _calculateStats(image, image_org, element, handles, modality, pixelSpacing, pixelToLengthFactor, rotated) {
  // Retrieve the bounds of the rectangle in image coordinates
  const roiCoordinates = _getRectangleImageCoordinates(
    this.getStartHandle(handles.end, rotated),
    handles.end
  );

  // Retrieve the array of pixels that the rectangle bounds cover
  const pixels = getPixels(
    element, image,
    roiCoordinates.left,
    roiCoordinates.top,
    roiCoordinates.width,
    roiCoordinates.height
  );

  // Calculate the mean & standard deviation from the pixels and the rectangle details
  const roiMeanStdDev = _calculateRectangleStats(pixels, roiCoordinates);

  // Fetch resolution from annotation
  const imageId = image_org?.targetId;
  const annotations = selectedAnnotations(store.getState(), imageId);
  const resolution = annotations?.find((annotation) => annotation?.body?.type === "ResolutionMeasurementResult")?.body?.value?.resolution;

  let meanStdDevSUV;

  if (modality === 'PT') {
    meanStdDevSUV = {
      mean: calculateSUV(image, roiMeanStdDev.mean, true) || 0,
      stdDev: calculateSUV(image, roiMeanStdDev.stdDev, true) || 0,
    };
  }

  // Calculate the image area from the rectangle dimensions and pixel spacing
  const area =
    roiCoordinates.width *
    (pixelToLengthFactor?.col || pixelSpacing.colPixelSpacing || 1) *
    (roiCoordinates.height * (pixelToLengthFactor?.row || pixelSpacing.rowPixelSpacing || 1));

  const perimeter =
    roiCoordinates.width * 2 * (pixelToLengthFactor?.col || pixelSpacing.colPixelSpacing || 1) +
    roiCoordinates.height * 2 * (pixelToLengthFactor?.row || pixelSpacing.rowPixelSpacing || 1);

  return {
    area: area || 0,
    perimeter,
    count: roiMeanStdDev.count || 0,
    mean: roiMeanStdDev.mean || 0,
    variance: roiMeanStdDev.variance || 0,
    stdDev: roiMeanStdDev.stdDev || 0,
    min: roiMeanStdDev.min || 0,
    max: roiMeanStdDev.max || 0,
    meanStdDevSUV,
    resolution: parseFloat(resolution)
  };
}

/**
 *
 *
 * @param {*} sp
 * @param {*} rectangle
 * @returns {{ count, number, mean: number,  variance: number,  stdDev: number,  min: number,  max: number }}
 */
function _calculateRectangleStats(sp, rectangle) {
  let sum = 0;
  let sumSquared = 0;
  let count = 0;
  let index = 0;
  let min = sp ? sp[0] : null;
  let max = sp ? sp[0] : null;

  for (let y = rectangle.top; y < rectangle.top + rectangle.height; y++) {
    for (let x = rectangle.left; x < rectangle.left + rectangle.width; x++) {
      sum += sp[index];
      sumSquared += sp[index] * sp[index];
      min = Math.min(min, sp[index]);
      max = Math.max(max, sp[index]);
      count++; // TODO: Wouldn't this just be sp.length?
      index++;
    }
  }

  if (count === 0) {
    return {
      count,
      mean: 0.0,
      variance: 0.0,
      stdDev: 0.0,
      min: 0.0,
      max: 0.0,
    };
  }

  const mean = sum / count;
  const variance = sumSquared / count - mean * mean;

  return {
    count,
    mean,
    variance,
    stdDev: Math.sqrt(variance),
    min,
    max,
  };
}
