function pad(num: string, size: number): string {
    var s = "0000" + num;
    return s.substring(s.length - size);
}

export function GetImageType(array: Uint8Array): {fileType: string | undefined , mimeType: string | undefined}  {
    let fileType: string | undefined = undefined;
    let mimeType: string | undefined = undefined;
    let s = "";
    let start = 128, end = 132;

    for (let i = start; i < end; ++i) {
        s += String.fromCharCode(array[i]);
    }

    let header: string = "";
    for (let j = 0; j < 4; j++) {
        header += pad(array[j].toString(16), 2);
    }

    if (header === '89504e47' ) {
        fileType = 'IMG';
        mimeType = 'image/png';
    } else if ( header === 'ffd8ffe0' || header === 'ffd8ffe1' || header === 'ffd8ffdb' || header === 'ffd8ffe2') {
        fileType = 'IMG';
        mimeType = 'image/jpg';
    } else if (header === '49492a00' || header === '4d4d002a') {
        fileType = 'TIF';
        mimeType = 'image/tiff';
    } else if (s === "DICM") {
        fileType = 'DICM';
        mimeType = 'application/dicom';
    }

    return {fileType, mimeType};
}
