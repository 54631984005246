import csTools from 'cornerstone-tools';
import cornerstone from "cornerstone-core";
import { moveAnnotationWithCompletedTrigger } from "./utils";
import { serviceEndpoints } from "../../..";

const BaseAnnotationTool = csTools.importInternal('base/BaseAnnotationTool');

const external = csTools.external;

const toolColors = csTools.toolColors;
const getToolState = csTools.getToolState;
const clearToolState = csTools.clearToolState;

const getNewContext = csTools.import("drawing/getNewContext");
const drawLine = csTools.importInternal("drawing/drawLine");
const drawRect = csTools.importInternal("drawing/drawRect");
const draw = csTools.import("drawing/draw");
const throttle = csTools.importInternal('util/throttle');
const drawHandles = csTools.importInternal("drawing/drawHandles");
const drawLinkedTextBox = csTools.importInternal("drawing/drawLinkedTextBox");
const setShadow = csTools.importInternal("drawing/setShadow");

const getLogger = csTools.importInternal('util/getLogger');
const Cursors = csTools.importInternal('tools/cursors');
const EVENTS = csTools.EVENTS;

const rectangleRoiCursor = Cursors.rectangleRoiCursor;
const getModule = csTools.getModule;
const moveHandleNearImagePoint = csTools.importInternal('manipulators/moveHandleNearImagePoint');

var rmactive = false;
var rmmeasurementImage = undefined;
var rmmeasurementElement = undefined;
var rmmeasurementStart = undefined;
var rmmeasurementEnd = undefined;
var rmmeasurementMatrixIndex = undefined;
var lastrmLineWidth = undefined;
var rmId = 0;
var lastrmId = -1;
var rmunmounted = false;
const logger = getLogger('tools:annotation:ResolutionMeasurementTool');

/**
 * @public
 * @class /ResolutionMeasurementTool
 * @memberof Tools.Annotation
 * @classdesc Tool for measuring the resolution (IQI Duplex) according to ISO 19232-5
 * @extends Tools.Base.BaseAnnotationTool
 */
export default class ResolutionMeasurementTool extends BaseAnnotationTool {
    constructor(props = {}) {
        const defaultProps = {
            name: 'ResolutionMeasurement',
            supportedInteractionTypes: ['Mouse', 'Touch'],
            configuration: {
                drawHandles: true,
                drawHandlesOnHover: props.configuration.drawHandlesOnHover ?? false,
                hideHandlesIfMoving: false,
                renderDashed: false,
                shadow: true,
            },
            svgCursor: rectangleRoiCursor,
        };

        super(props, defaultProps);


        this.toolMeasurementAcceptedColor = 'hsla(30, 100%, 60%, 1)';
        const r = document.querySelector(':root');
        if (r) {
            // @ts-ignore
            const measurementAcceptedColor = getComputedStyle(r)?.getPropertyValue('--tool-measurement-accepted');
            if(measurementAcceptedColor) {
                this.toolMeasurementAcceptedColor = measurementAcceptedColor;
            }
        }

        this.throttledApplyMeasurement = throttle(_applyResolutionMeasurement, 200);
    } // constructor

    passiveCallback(element) {
        //console.log("passive");
        /*
        if (this.options.reset) {
            this.delete(element);
            const image = cornerstone.getImage(element);
            if (image) {
                cornerstone.updateImage(element);
            }
        }
        */
        rmactive = false;
    }

    activeCallback(element) {
        //console.log("activeCallback");
        if (rmactive === false) {
            rmmeasurementElement = element;
            if (rmmeasurementElement !== undefined) {
                cornerstone.triggerEvent(rmmeasurementElement, "enableresolutionmeasurement");
                let toolData = getToolState(rmmeasurementElement, 'ResolutionMeasurement');
                if (toolData !== undefined) {
                    let sdata = undefined;
                    for (let i = 0; i < toolData.data.length; i++) {
                        let data = toolData.data[i];
                        if (data && data.id === lastrmId /*&& data.matrixIndex === rmmeasurementMatrixIndex*/) {
                            data.activate = true;
                            sdata = data;
                            break;
                        }
                    }
                    if (sdata) {
                        setTimeout(() => {
                            //cornerstone.triggerEvent(rmmeasurementElement, "rmmeasurementid", lastrmId);
                            _applyResolutionMeasurement(rmmeasurementImage, /*viewport*/null, rmmeasurementElement, sdata.handles.start, sdata.handles.end,
                                    /*config*/null, sdata);
                        }, 500);
                    }
                }
            }
        }
        rmactive = true;
    }

    delete(element) {
        //console.log("DELETE RESOLUTION MEASUREMENT");
        const toolData = getToolState(element, this.name);
        if (!toolData) {
            return;
        }
        var haveother = false;
        for (let i = 0; i < toolData.data.length; i++) {
            const data = toolData.data[i];
            if (data)
                if (data.matrixIndex === this.initialConfiguration.matrixIndex)
                    csTools.removeToolState(element, this.name, data);
                else
                    haveother = true;
        }
        if (!haveother) {
            cornerstone.triggerEvent(element, "disableresolutionmeasurement");
        }
    }

    createNewMeasurement(eventData) {
        const goodEventData = eventData && eventData.currentPoints && eventData.currentPoints.image;
        if (!goodEventData) {
            logger.error(
                `required eventData not supplied to tool ${this.name}'s createNewMeasurement`
            );
            return;
        }

        if (eventData.event) {
            if (false) {
            // TODO check if we really want this (remove all other grayscale line profiles when drawing a new one)
                this.delete(eventData.event.currentTarget);
                clearToolState(eventData.event.currentTarget, this.name);
            } else {
                // bail out, if there is already a resolution measurement
                let toolData = getToolState(eventData.event.currentTarget, 'ResolutionMeasurement');
                if (toolData !== undefined && toolData.data.length !== 0) { return; }
            }
        }

        const element = eventData.element;
        let openDialog = true;
        if (eventData.locked !== undefined && eventData.locked === true)
            openDialog = false;
        if (openDialog) {
            cornerstone.triggerEvent(element, "enableresolutionmeasurement");
            let detail = { profile: [] };
            cornerstone.triggerEvent(element, "rmprofile", detail);
        }

        var toolData = {
            visible: true,
            active: true,
            color: undefined,
            invalidated: true,
            handles: {
                start: {
                    x: eventData.currentPoints.image.x,
                    y: eventData.currentPoints.image.y,
                    highlight: true,
                    active: false,
                },
                end: {
                    x: eventData.currentPoints.image.x,
                    y: eventData.currentPoints.image.y,
                    highlight: true,
                    active: true,
                },
                //initialRotation: eventData.viewport.rotation,
                textBox: {
                    active: false,
                    hasMoved: false,
                    movesIndependently: false,
                    drawnIndependently: true,
                    allowedOutsideImage: true,
                    hasBoundingBox: true,
                },
            },
            matrixIndex: this.initialConfiguration.matrixIndex,
            lineWidth: (lastrmLineWidth !== undefined) ? lastrmLineWidth : 22,
            profile: [],
            angle: "n/a",
            resolution: "n/a",
            toolTip: undefined,
            id: rmId++,
            locked: false,
        };

        function resolutionmeasurementlocklistener(evt) {
            //console.log("RMLC event received for id: " + evt.detail.id);
            if (evt.detail !== undefined && evt.detail !== "") {
                if (rmmeasurementElement !== undefined) {
                    // can not use this.name here!
                    var toolData = getToolState(rmmeasurementElement, 'ResolutionMeasurement');
                    if (toolData !== undefined) {
                        for (let i = 0; i < toolData.data.length; i++) {
                            let data = toolData.data[i];
                            if (data /*&& (data.matrixIndex === rmmeasurementMatrixIndex)*/) {
                                if (evt.detail.value === true) {
                                    // lock this, unlock others
                                    if (evt.detail.id === data.id) {
                                        data.locked = true;
                                        data.active = false;
                                        data.handles.start.active = false;
                                        data.handles.end.active = false;
                                        data.handles.start.highlight = false;
                                        data.handles.end.highlight = false;
                                    } else {
                                        // unlock others
                                        data.locked = false;
                                        data.active = true;
                                        data.handles.start.active = true;
                                        data.handles.end.active = true;
                                        data.handles.start.highlight = true;
                                        data.handles.end.highlight = true;
                                    }
                                } else {
                                    // unlock this, do nothing to others
                                    if (evt.detail.id === data.id) {
                                        data.locked = false;
                                        data.active = true;
                                        data.handles.start.active = true;
                                        data.handles.end.active = true;
                                        data.handles.start.highlight = true;
                                        data.handles.end.highlight = true;
                                    }
                                }
                                // trigger store
                                cornerstone.triggerEvent(rmmeasurementElement, EVENTS.MEASUREMENT_COMPLETED, { element: rmmeasurementElement, measurementData: data, toolName: 'ResolutionMeasurement' });
                            }
                        } // for
                        cornerstone.updateImage(rmmeasurementElement, true);
                    } // if have toolData
                }
            }
            return;
        } // resolutionmeasurementlocklistener

        element.removeEventListener("RMLC", resolutionmeasurementlocklistener);
        element.addEventListener("RMLC", resolutionmeasurementlocklistener);


        function linewidthlistener(evt) {
            //console.log("RMLW event received");
            if (evt.detail && evt.detail !== "") {
                if (rmmeasurementElement !== undefined) {
                    // can not use this.name here!
                    var toolData = getToolState(rmmeasurementElement, 'ResolutionMeasurement');
                    if (toolData !== undefined) {
                        for (let i = 0; i < toolData.data.length; i++) {
                            let data = toolData.data[i];
                            if (data /*&& (data.matrixIndex === rmmeasurementMatrixIndex)*/ &&
                                evt.detail.id === data.id && (data.linewidth !== evt.detail.value)) {
                                data.lineWidth = parseInt(evt.detail.value);
                                lastrmLineWidth = data.lineWidth;
                                _applyResolutionMeasurement(rmmeasurementImage, /*viewport*/null, rmmeasurementElement, data.handles.start, data.handles.end,
			 						/*config*/null, data);
                            }
                        }
                    }
                }
            }
            return;
        } // linewidthlistener

        element.removeEventListener("RMLW", linewidthlistener);
        element.addEventListener("RMLW", linewidthlistener);


        function rmtooltiplistener(evt) {
            //console.log("TT event received");
            if (rmmeasurementElement !== undefined) {
                // can not use this.name here!
                var toolData = getToolState(rmmeasurementElement, 'ResolutionMeasurement');
                if (toolData !== undefined) {
                    for (let i = 0; i < toolData.data.length; i++) {
                        let data = toolData.data[i];
                        if (data /*&& (data.matrixIndex === rmmeasurementMatrixIndex)*/) {
                            if (evt.detail && evt.detail !== "" && evt.detail.id === data.id) {
                                data.toolTip = parseInt(evt.detail.value);
                            } else {
                                data.toolTip = undefined;
                            }
                            cornerstone.updateImage(rmmeasurementElement, true);
                            //renderToolData({image: measurementImage, element: measurementElement});
                        }
                    }
                }
            }
            return;
        } // tooltiplistener

        element.removeEventListener("RMTT", rmtooltiplistener);
        element.addEventListener("RMTT", rmtooltiplistener);

        function unmountlistener(evt) {
            //console.log("RMUM event received");
            lastrmId = -1;
            rmactive = false;
            rmunmounted = true;
            return;
        } // unmountlistener

        element.removeEventListener("RMUM", unmountlistener);
        element.addEventListener("RMUM", unmountlistener);

        rmmeasurementElement = element;
        var image = cornerstone.getImage(element);

        rmmeasurementStart = toolData.handles.start;

        if (eventData && eventData.currentPoints && eventData.currentPoints.end) {
            rmmeasurementEnd = eventData.currentPoints.end;
        } else {
            rmmeasurementEnd = {};
            rmmeasurementEnd.x = rmmeasurementStart.x;
            rmmeasurementEnd.y = rmmeasurementStart.y;
            // avoid 404 from raw processor
            rmmeasurementEnd.x++;
            rmmeasurementEnd.y++;

            // warm up rawprocessor (load raw image) on initial click
            _applyResolutionMeasurement(image, /*viewport*/null, element, rmmeasurementStart, rmmeasurementEnd, /*config*/null, toolData);
        }

        function mouseDown(evt) {
            //console.log("RESMES MOUSEDOWN");
            if (rmmeasurementElement !== undefined) {
                let toolData = getToolState(rmmeasurementElement, 'ResolutionMeasurement');
                if (toolData !== undefined) {
                    for (let i = 0; i < toolData.data.length; i++) {
                        const data = toolData.data[i];

                        // check for length == 0 to make this fire only once
                        if (data && data.profile.length === 0 /*&& data.matrixIndex === rmmeasurementMatrixIndex*/) {

                            _applyResolutionMeasurement(image/*measurementJobImage*/, /*viewport*/null, rmmeasurementElement, rmmeasurementStart, rmmeasurementEnd,
			 						/*config*/null, data);
                        }
                    }
                }
            }
            return;
        }; // mouseDown

        element.removeEventListener(EVENTS.MOUSE_DOWN, mouseDown);
        element.addEventListener(EVENTS.MOUSE_DOWN, mouseDown);

        if(true || !eventData.restore) {
            if(toolData.id != -1)
            cornerstone.triggerEvent(element, "rmmeasurementid", toolData.id);
        }

        return toolData;
    } // createNewMeasurement

    handleSelectedCallback(evt, toolData, handle, interactionType = 'mouse') {
        if (!toolData?.locked) {
            moveHandleNearImagePoint(evt, this, toolData, handle, interactionType);
        }
    }


    pointNearTool(element, data, coords, interactionType) {
        const hasStartAndEndHandles =
            data && data.handles && data.handles.start && data.handles.end;
        const validParameters = hasStartAndEndHandles;

        if (!validParameters) {
            logger.warn(
                `invalid parameters supplied to tool ${this.name}'s pointNearTool`
            );
        }

        if (!validParameters || data.visible === false) {
            return false;
        }

        const distance = interactionType === 'mouse' ? 15 : 25;
        const startCanvas = external.cornerstone.pixelToCanvas(
            element,
            data.handles.start
        );
        const endCanvas = external.cornerstone.pixelToCanvas(
            element,
            data.handles.end
        );

        const line = {
            start: startCanvas,
            end: endCanvas
        };

        const distanceToPoint = external.cornerstoneMath.lineSegment.distanceToPoint(
            line,
            coords
        );

        if (distanceToPoint < distance) {
            //console.log("In point near tool data.id is:"+data.id);
            cornerstone.triggerEvent(element, "rmmeasurementid", data.id);
            if (lastrmId !== data.id && data.profile) {
                let detail = { profile: data.profile, lineWidth: data.lineWidth, id: data.id };
                cornerstone.triggerEvent(element, "rmprofile", detail);
                if (data.points) {
                    cornerstone.triggerEvent(element, "rmpoints", data.points);
                }
            }
            if (false && lastrmId !== data.id) {
                if (data.profile) {
                    let detail = { profile: data.profile, lineWidth: data.lineWidth, id: data.id };

                    if (rmactive) {
                        cornerstone.triggerEvent(element, "enableresolutionmeasurement");
                    }

                    cornerstone.triggerEvent(element, "rmprofile", detail);
                    // should we get here after "lock" operation, we must update the points lock state (without calling _applyResolutionMeasurement)
                    if (data.points) {
                        data.points[7] = data.locked;
                        data.points[8] = data.id;
                        cornerstone.triggerEvent(element, "rmpoints", data.points);
                    }

                    lastrmId = data.id;
                }
            }

            if (data.locked) {
                data.handles.start.active = false;
                data.handles.end.active = false;
            }

        }
        return data && data.matrixIndex === this.initialConfiguration.matrixIndex && distanceToPoint < distance /*&& !data.locked*/;
    } // pointNearTool

    toolSelectedCallback(evt, annotation, interactionType = 'mouse') {
        console.log("toolSelected anno is:"+annotation.locked)

        if (!annotation?.locked) {
            //moveAnnotation(evt, this, annotation, interactionType);
            moveAnnotationWithCompletedTrigger(evt, this.name, annotation, this.options, interactionType)
        } else {
            evt.stopImmediatePropagation();
            evt.stopPropagation();
            evt.preventDefault();
        }
    }


    updateCachedStats(image, element, data) {
        if (rmactive === false && rmunmounted === true) {
            //console.log("UPDATECACHE with rmactive false");
            setTimeout(() => {
                //cornerstone.triggerEvent(rmmeasurementElement, "rmmeasurementid", lastrmId);
                if (data !== undefined)
                    _applyResolutionMeasurement(rmmeasurementImage, /*viewport*/null, rmmeasurementElement, data.handles.start, data.handles.end,
                                            /*config*/null, data);
            }, 500);
        }
        const viewport = cornerstone.getViewport(element);
        this.throttledApplyMeasurement(image, viewport, element, data.handles.start, data.handles.end, this.configuration, data);
    }

    renderToolData(evt) {

        const toolData = getToolState(evt.currentTarget, this.name);

        if (!toolData) {
            return;
        }

        const eventData = evt.detail;
        const { image, element } = eventData;

        if (element === undefined) {
            return;
        }

        //console.log(image.imageId);
        const lineDash = getModule('globalConfiguration').configuration.lineDash;
        const {
            handleRadius,
            drawHandlesOnHover,
            hideHandlesIfMoving,
            renderDashed,
        } = this.configuration;
        const context = getNewContext(eventData.canvasContext.canvas);

        draw(context, context => {
            // If we have tool data for this element - iterate over each set and draw it
            for (let i = 0; i < toolData.data.length; i++) {
                const data = toolData.data[i];

                if (data && (data.matrixIndex === undefined || data.matrixIndex === this.initialConfiguration.matrixIndex)) {

                    if (data.visible === false) {
                        continue;
                    }

                    if (data.handles.start !== undefined)
                        rmmeasurementStart = data.handles.start;
                    if (data.handles.end !== undefined)
                        rmmeasurementEnd = data.handles.end;
                    rmmeasurementElement = element;
                    rmmeasurementMatrixIndex = this.initialConfiguration.matrixIndex;

                    // Configure
                    let color = toolColors.getColorIfActive(data);
                    if (data.locked)
                        color = this.toolMeasurementAcceptedColor;
                    const handleOptions = {
                        color,
                        handleRadius,
                        drawHandlesIfActive: drawHandlesOnHover,
                        hideHandlesIfMoving,
                    };

                    setShadow(context, this.configuration);

                    const lineOptions = { color };

                    if (renderDashed) {
                        lineOptions.lineDash = lineDash;
                    }
                    // Draw
                    drawLine(
                        context,
                        element,
                        data.handles.start,
                        data.handles.end,
                        lineOptions,
                        'pixel',
                        data.handles.initialRotation
                    );
                    if (this.configuration.drawHandles && !data.locked) {
                        drawHandles(context, eventData, data.handles, handleOptions);
                    }

                    if (!data.handles.textBox.hasMoved) {
                        var coords = {
                            x: Math.max(data.handles.start.x, data.handles.end.x),
                            y: undefined
                        }; // Depending on which handle has the largest x-value,
                        // Set the y-value for the text box

                        if (coords.x === data.handles.start.x) {
                            coords.y = data.handles.start.y;
                        } else {
                            coords.y = data.handles.end.y;
                        }

                        data.handles.textBox.x = coords.x;
                        data.handles.textBox.y = coords.y;
                    }
                    drawLinkedTextBox(context, element, data.handles.textBox, data.resolution, data.handles, textBoxAnchorPoints, color, 1, 10, true);

                    // draw oriented rectangle (line with height)
                    if (data.lineWidth > 1) {
                        var lh = data.lineWidth;
                        const ls = data.handles.start, le = data.handles.end;
                        var dx = (le.x - ls.x);
                        var dy = (le.y - ls.y);

                        var l = Math.sqrt(dx * dx + dy * dy);
                        if (l > 0.05) {
                            var angle = dx / l;
                            if (angle <= -1.0)
                                angle = -Math.PI;
                            else
                                if (angle > 1.0)
                                    angle = Math.PI;
                                else
                                    angle = Math.acos(angle);
                            angle *= 180.0 / Math.PI;
                            if (dy > 0)
                                angle = -angle;
                            var cp = { x: ls.x + (dx * 0.5), y: ls.y + (dy * 0.5) };
                            var p1 = { x: cp.x - l * 0.5, y: cp.y - lh * 0.5 };
                            var p2 = { x: cp.x + l * 0.5, y: cp.y + lh * 0.5 };
                            lineOptions.lineDash = lineDash;
                            drawRect(context, element, rotatePoint(p1, cp, -angle), rotatePoint(p2, cp, -angle), lineOptions, 'pixel', angle);
                        }
                    }

                    // draw marker
                    if (data.toolTip) {
                        let lh = data.lineWidth;
                        const ls = data.handles.start, le = data.handles.end;
                        let dx = (le.x - ls.x);
                        let dy = (le.y - ls.y);
                        let l = Math.sqrt(dx * dx + dy * dy);

                        const markerLineOptions = { color: "red" };

                        drawMarkerLine(data.toolTip, ls, l, dx, dy, context, element, lh, markerLineOptions);
                    }
                    if (data.invalidated === true && !data.active) {
                        console.log("invalid data in redraw for id:" + data.id);
                        this.updateCachedStats(image, element, data);
                        data.invalidated = false;
                    }
                }
            }
        }); // draw
    } // renderToolData

    restore(element, data) {
        var xs = data.handles.start.x;
        var ys = data.handles.start.y;

        let measurement = this.createNewMeasurement(
            {
                currentPoints: { image: { x: xs, y: ys }, end: data.handles.end },
                viewport: { rotation: undefined },
                element: element,
                locked: data.locked,
                restore: true
            });

        measurement.handles.end.x = data.handles.end.x;
        measurement.handles.end.y = data.handles.end.y;
        measurement.lineWidth = data.lineWidth;
        measurement.resolution = data.resolution;
        measurement.locked = data.locked;
        measurement.angle = data.angle;

        rmmeasurementImage = cornerstone.getImage(element);
        _applyResolutionMeasurement(rmmeasurementImage, /*viewport*/null, element, measurement.handles.start, measurement.handles.end,
			 							/*config*/null, measurement);

        return measurement;
    }

    store(toolData) {
        toolData.storeToolData = {
            "handles": toolData.handles,
            "lineWidth": toolData.lineWidth,
            "resolution": toolData.resolution,
            "locked": toolData.locked,
            "angle": toolData.angle
        }
    }

    measurementRemoved(element, removedata) {
        //console.log("measurementRemoved " + element + "/" + removedata);
        const toolData = getToolState(element, this.name);
        if (!toolData) {
            return;
        } else {
            //console.log("clear toolstate");
            clearToolState(element, this.name);
        }
        var haveother = false;
        for (let i = 0; i < toolData.data.length; i++) {
            const data = toolData.data[i];
            if (data && (data.matrixIndex === this.initialConfiguration.matrixIndex)) {
                if (data.id !== removedata.id) {
                    haveother = true;
                    break;
                }
            }
        }
        if (!haveother) {
            cornerstone.triggerEvent(element, "disableresolutionmeasurement");
        }

        return;
    }
} // ResolutionMeasurementTool


function distance(p1, p2) {
    const dx = p1.x - p2.x;
    const dy = p1.y - p2.y;
    return Math.sqrt(dx * dx + dy * dy);
}

function drawMarkerLine(position, ls, l, dx, dy, context, element, linewidth, lineOptions) {
    if (position > l)
        position = l;
    const x1 = ls.x + dx * position / l;
    const y1 = ls.y + dy * position / l;
    const w = distance(ls, { x: x1, y: y1 });
    const linevec = { x: (x1 - ls.x) / w, y: (y1 - ls.y) / w };
    const ortho = { x: -linevec.y, y: linevec.x }

    // TODO rather use screen size for "zoomed in" views
    if (linewidth < 20)
        linewidth = 20;

    const pa = { x: x1 + ((linewidth / 2.0) * ortho.x), y: y1 + ((linewidth / 2.0) * ortho.y) };
    const pd = { x: x1 - ((linewidth / 2.0) * ortho.x), y: y1 - ((linewidth / 2.0) * ortho.y) };
    drawLine(
        context,
        element,
        pa,
        pd,
        lineOptions
    );
}

function textBoxAnchorPoints(handles) {
    var midpoint = {
        x: (handles.start.x + handles.end.x) / 2,
        y: (handles.start.y + handles.end.y) / 2
    };
    return [handles.start, midpoint, handles.end];
}

const _applyResolutionMeasurement = function(image, viewport, element, startPoint, endPoint, config, data) {

    if (!image)
        return;

    let imageId = image.image_raw ? image.image_raw.targetId : image.targetId;
    if (imageId === undefined) {
        console.log("could not get profile, imageId is undefined");
        return;
    }

    if (imageId.startsWith("wadouri"))
        imageId = imageId.slice(8);

    if (data.lineWidth > 20) {
        var drreq = new XMLHttpRequest();
        function plistener() {
            if (this.status === 200) {
                var response = JSON.parse(this.responseText);
                data.resolution = response.result;
                //logger.error(response.result);
                //console.log("got resolution for: "+data.id);
                data.points = [response.peaks, response.dips, response.base, response.results, response.width, response.angle, response.result, data.locked, data.id, response.profile];
                cornerstone.triggerEvent(element, "rmpoints", data.points);
                //console.log("sending new points to grayscale line display for id:"+data.id+" locked: "+data.locked);
                rmmeasurementImage = image;
                //cornerstone.invalidate(element);
            } else {
                data.resolution = "n/a";
                console.error("Received HTTP status " + this.status + " while getting the resolution data.");
                //logger.error("Received HTTP status " + this.status + " while getting the resolution data.");
            }
            cornerstone.updateImage(element, true);
        }
        drreq.addEventListener("load", plistener);
        //console.log("getting resolution from rawprocessor");

        drreq.open("GET", serviceEndpoints.RAW_PROCESSOR_URL + "/resolution/direct" +
            "?rawfile=" + imageId + "&p1x=" + startPoint.x + "&p1y=" + startPoint.y +
            "&p2x=" + endPoint.x + "&p2y=" + endPoint.y + "&width=" + data.lineWidth + "&wait=3000");

        drreq.send();
    } else {
        data.resolution = "n/a";
        data.points = [[], [], [], [], data.lineWidth, "n/a", "n/a", data.locked, data.id, []];
        cornerstone.triggerEvent(element, "rmpoints", data.points);
    } // if lineWidth > 20

    //cornerstone.invalidate(element);
    //cornerstone.updateImage(element, true);

    return;
}; // _applyResolutionMeasurement


function rotatePoint(point, center, angle) {
    var angleRadians = angle * (Math.PI / 180); // Convert to radians

    var rotatedX = Math.cos(angleRadians) * (point.x - center.x) - Math.sin(angleRadians) * (point.y - center.y) + center.x;
    var rotatedY = Math.sin(angleRadians) * (point.x - center.x) + Math.cos(angleRadians) * (point.y - center.y) + center.y;
    return {
        x: rotatedX,
        y: rotatedY
    };
}
