
import React, { useRef } from "react";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { parseISO } from 'date-fns';
import { JSONPath } from "jsonpath-plus";
import { AppDispatch, RootState } from "../../store";
import JsxParser from 'react-jsx-parser';
import BurgerMenu from "./BurgerMenu";
import Workitem from "./Workitem";
import { study, useGetStudyWithWorkitemsQuery, workitem } from "../../apis/apiSlice";
import { setAcquisitionActive } from "../Akquisition/AcquisitionSlice";
import { MainOrderListColumn, getOrderlistCols, selectAllowComplete, selectFormatString, selectOrderlistIndex } from "./MainOrderListSlice";
import { t, useORTranslation } from "../Localization/ORLocalization";
import { Constants } from "../../Constants";
import { getAge } from "../Subject/SubjectPanel";
import { getImagePathForCurrentTheme } from "./OrdersSlice";
import GenderIcon from "../Subject/GenderIcon";


const useAppDispatch = () => useDispatch<AppDispatch>();
const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const getItemFromJsonPath = (path: string | string[], dataSource: any, item: any, className?: string, fontweight?: string) => {
	//const { t } = useORTranslation(['Procedures']);
	let ret: any = undefined;
	if (Array.isArray(path)) {
		ret = '';
		for (let i = 0; i < path.length; i++) {
			const JSONPath_item = JSONPath({ path: path[i], json: dataSource })?.pop();
			if (JSONPath_item !== undefined && JSONPath_item !== null && JSONPath_item !== '') {
				ret += JSONPath_item;
				if (i < path.length - 1) {
					ret += item?.stringSeperator ? item.stringSeperator : ' ';
				}
			}
		}
	} else {
		ret = JSONPath({ path: path, json: dataSource })?.pop();
	}

	if(item?.translate){
		ret = t(ret, {ns: 'Procedures'});
	}

	/* console.log(ret);
	console.log(type);
	console.log(typeof ret);
 */
	if (item?.type && item?.type === 'number' && typeof ret === 'string') {
		ret = Number(ret.trim());
	}

	if (item?.toFixed !== undefined && ret !== undefined) {
		if (typeof ret !== 'number') {
			ret = Number(ret.trim());
		}
		ret = Number.parseFloat(ret.toFixed(item?.toFixed));
		if (typeof ret !== 'number') {
			ret = ret.toString();
		}
	}


	return ret;
}

export const concatStringFromJsonPathArray = (path: string[], dataSource: any, item: any, className?: string, fontweight?: string) => {
	let ret: string = '';
	if (Array.isArray(path)) {
		for (let i = 0; i < path.length; i++) {
			const item = JSONPath({ path: path[i], json: dataSource })?.pop();
			if (item !== undefined && item !== null && item !== '') {
				ret += getItemFromJsonPath(path[i], dataSource, item);
				ret += ' ';
			}
		}
	}
	return ret;
}

export const getConcatStringFromJsonPath = (path: string | string[], dataSource: any, className?: string) => {
	let ret: any = undefined;
	if (Array.isArray(path)) {
		ret = '';
		for (let i = 0; i < path.length; i++) {
			const item = JSONPath({ path: path[i], json: dataSource })?.pop();
			if (item !== undefined && item !== null && item !== '') {
				ret += item;
				if (i < path.length - 1) {
					ret += ' ';
				}
			}
		}
	} else {
		ret = JSONPath({ path: path, json: dataSource })?.pop();
	}
	return ret;
}

const OrderListEntry = (props: { study: study }) => {

	const { t } = useORTranslation(['OrderList', 'InfoPanel']);

	const previewImages = useRef<HTMLDivElement>(null);

	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const overflow = Math.max((props.study.workItems?.length || 0) - 7, 0);

	const orderlistCols: any = useAppSelector((state) => getOrderlistCols(state));
	const formatString: string = useAppSelector((state) => selectFormatString(state));
	const allowComplete: boolean = useAppSelector((state) => selectAllowComplete(state));
	const orderlistIndex: number = useAppSelector((state) => selectOrderlistIndex(state));
	const imagePathForCurrentTheme: string = useAppSelector((state) => getImagePathForCurrentTheme(state));

	/* const {
		data,
		isSuccess,
	} = useGetWorkitemListQuery({studyId: props.study.id, workitems: props.study.workItems}, {
		skip: props.study.workItems === undefined,
	}) */


	const {
		data,
		isSuccess,
	} = useGetStudyWithWorkitemsQuery(props.study.id, {
		skip: props?.study?.id === undefined,
	})

	const selectStartWorkitem = () => {
		let startWorkitemId: string | undefined = undefined;
		for (const workitem of data?.workitems) {
			if (workitem?.data?.protocol?.steps) {
				let found: boolean = false;
				for (const step of workitem?.data?.protocol?.steps) {
					if (step.type === Constants.ACQUISITION_STEP_TYPE && step.performed === undefined &&
						step.rejected === undefined && step.scheduled !== undefined) {

						startWorkitemId = workitem?.data?.id;
						found = true;
						break;
					}
				}
				if (found) {
					break;
				}
			}
		}
		if (startWorkitemId === undefined) {
			if (data?.workitems && Array.isArray(data?.workitems) && data?.workitems?.length > 0) {
				startWorkitemId = data?.workitems[0]?.data?.id;
			}
		}
		return startWorkitemId;
	}


	const handleItemClick = (e: React.MouseEvent) => {
		//console.log("OrderListEntry handleItemClick: ");
		dispatch({ type: "ImageDisplay/setExportDialogVisible", payload: 0 });
		dispatch({ type: "ImageDisplay/setFinalizeStudyDialogVisible", payload: 0 });

		const startWorkitemId = selectStartWorkitem();

		if (startWorkitemId) {
			dispatch({ type: 'ImageDisplay/setSelectedWorkitem', payload: startWorkitemId });
			dispatch({ type: "Procedure/setProcedureSelectionVisibilityState", payload: 2 })
			navigate(`/orders/${props.study.id}/${location?.search ?? ''}`, { state: { isFromOrderList: true } });
		} else {
			dispatch(setAcquisitionActive({ setAcquistionButtonActive: false, studyId: undefined, workitemId: undefined }));
			dispatch({ type: "Procedure/setProcedureSelectionVisibilityState", payload: 1 });
			dispatch({ type: 'ImageDisplay/setSelectedWorkitem', payload: "" });
			navigate(`/orders/${props.study.id}/${location?.search ?? ''}`);
		}
	}

	const handleWorkitemClick = (e: React.MouseEvent, workitem: string) => {
		e.stopPropagation();
		dispatch({ type: "ImageDisplay/setExportDialogVisible", payload: 0 });
		dispatch({ type: "ImageDisplay/setFinalizeStudyDialogVisible", payload: 0 });
		//dispatch(setAcquisitionActive({ setAcquistionButtonActive: false, studyId: undefined, workitemId: undefined }));
		dispatch({ type: 'ImageDisplay/setSelectedWorkitem', payload: workitem });
		navigate(`/orders/${props.study.id}/${location?.search ?? ''}`, { state: { isFromOrderList: true } });
	}

	const location = useLocation();

	const onImageSelect = (workitem: string, artifactId: string | undefined, artifactId_raw: string | undefined, workitemData?: workitem | undefined) => {
	}

	const getJsx = (item: any, depth: number) => {
		return (
			//@ts-ignore
			<JsxParser
				className="innerHtml"
				bindings={{
					getItemFromJsonPath: (path: string) => getItemFromJsonPath(path, props.study, item),
					concatStringFromJsonPathArray: (pathArray: string[]) => concatStringFromJsonPathArray(pathArray, props.study, item),
					t: (text: string) => t(text),
					getAge: (date: string) => parseISO(date).toLocaleDateString() !== 'Invalid Date' ? getAge(parseISO(date)) : '',
					imagePathForCurrentTheme: "/images/",

					getDateFromString: (datestring: string) => {
						const dateAsString: string = getItemFromJsonPath(datestring, props.study, item);
						const dataAsDate = parseISO(dateAsString);

						if (dataAsDate.toLocaleDateString() === 'Invalid Date') {
							return '';
						}
						return t('date', { date: dataAsDate });
					},
					id: depth,
				}}
				//@ts-ignore
				components={{ GenderIcon }}
				jsx={item?.html?.join('') ?? ''}
			/>
		)
	}

	return (
		<>
			{orderlistCols ?
				<li className="order-item" onClick={handleItemClick} style={{ gridTemplateColumns: formatString, height: orderlistCols[orderlistIndex]?.displayOptions?.rowHeight ?? "4.3em" }}>
					{orderlistCols[orderlistIndex]?.cols?.filter((column: MainOrderListColumn) => column.type !== 'openStatus' || allowComplete).map((column: MainOrderListColumn, i: number) =>
						<span key={i}>
							{(() => {
								switch (column.type) {
									case 'openStatus':
										return <>
											<div className="open-status">
												<span className={`dot open-status-${getItemFromJsonPath(column.path, props.study, column) === "OPENED" ? 'open' : 'closed'}`} />
												<img className="openStatusImg" src={getItemFromJsonPath(column.path, props.study, column) === "OPENED" ?
													`${imagePathForCurrentTheme}StudyStateOpen.svg` : (getItemFromJsonPath(column.path, props.study, column) === "COMPLETED" ?
														`${imagePathForCurrentTheme}StudyStateCompleted.svg` : `${imagePathForCurrentTheme}StudyStateClosed.svg`)}
													onError={(event: any) => {
														event.target.src = getItemFromJsonPath(column.path, props.study, column) === "OPENED" ?
															"/images/StudyStateOpen.svg" : (getItemFromJsonPath(column.path, props.study, column) === "COMPLETED" ?
																"/images/StudyStateCompleted.svg" : "/images/StudyStateClosed.svg"); event.onerror = null
													}}
													alt="Anzeige Bilduebersicht aktiv.svg" />
											</div>
										</>
									case 'date':
										return <>
											<div className="date-Wrapper">
												<div className="content">
													<span className='date'>
														{JSONPath({ path: column.path, json: props.study })?.pop() ?
															t('date', { date: parseISO(getItemFromJsonPath(column.path, props.study, column)), ns: 'InfoPanel' }) :
															''}
													</span>
													<span className='time'>
														{JSONPath({ path: column.path, json: props.study })?.pop() ?
															t('time', { date: parseISO(getItemFromJsonPath(column.path, props.study, column)), ns: 'InfoPanel' }) :
															''}
													</span>
												</div>
											</div>
										</>
									case 'dateOnly':
										return <>
											<div className="date-Wrapper">
												<div className="content">
													<span className='dateOnly'>
														{JSONPath({ path: column.path, json: props.study })?.pop() ?
															t('date', { date: parseISO(getItemFromJsonPath(column.path, props.study, column)), ns: 'InfoPanel' }) :
															''}
													</span>
												</div>
											</div>
										</>
									case 'string':
										return <>
											<div className="title" title={getConcatStringFromJsonPath(column.path, props.study, "nameColumn")}>
												<div className="content">{getItemFromJsonPath(column.path, props.study, column)}</div>
											</div>
										</>
									case 'previewImages':
										return <div className="preview-images-wrapper">
											<div className="preview-images" ref={previewImages}>
												{getItemFromJsonPath(column.path, data?.workitems, column)?.slice(0, Constants.MAX_WORKITEMS_IN_LISTDISPLAY).map((workitem: any, i: number) => <div key={i.toString()} onClick={(evt) => handleWorkitemClick(evt, workitem?.data?.id)}>
													<Workitem onImageSelect={onImageSelect}
														orderId={props.study.id} workitemId={workitem?.data?.id} workitem={workitem?.data} getRawSeries={false}
														includeImageDesc={false} showPlannings={true} showDocuments={true} className={undefined} showRejected={false} /></div>)}
											</div>
											<span className="preview-images-more">
												{overflow ? `+ ${overflow} ${t('more', { count: overflow })}` : ''}
											</span>
										</div>
									case 'BurgerMenu':
										return <>
											<BurgerMenu orderId={props.study.id} />
										</>
									case 'jsx':
										return getJsx(column, 1);
									default:
										return null
								}
							})()}
						</span>
					)}
				</li>
				: null}
		</>

	);
}

export default OrderListEntry;
