import { configureStore} from '@reduxjs/toolkit'
import ObjectSelectionReducer from './components/Akquisition/ObjectSelectionSlice';
import AcquisitionReducer from './components/Akquisition/AcquisitionSlice';
import GeneratorTechniqueParametersReducer from './components/Akquisition/GeneratorTechniqueParametersSlice';
import CatalogueReducer from './components/Akquisition/CatalogueSlice';
import SubjectReducer from './components/Subject/SubjectSlice';
import ProcedureReducer from './components/Procedures/ProcedureSlice';
import OrderSliceReducer from './components/OrderList/OrdersSlice';
import OverviewSliceReducer from './components/ImageDisplay/Tools/OverviewSlice';
import DicomTagBrowserReducer from './components/DicomTagBrowser/DicomTagBrowserSlice';
import ImageDisplayReducer from './components/ImageDisplay/ImageDisplaySlice';
import MessageReducer from './components/common/MessageSlice';
import MainOrderListReducer from './components/OrderList/MainOrderListSlice';
import authReducer from './components/Auth/authSlice';
import { MqttMiddleware } from './apis/Mqtt/MqttMiddleware';
import reduxCookiesMiddleware from 'redux-cookies-middleware';
import { apiSlice } from './apis/apiSlice';

const paths = {
  'auth.toCookie': { name: 'token' },
};

// set cookie with token to avoid login after page reload
const setCookie = (name: string, value: any) => {
  document.cookie = name +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  const d = new Date();
  const expiresIn: number = JSON.parse(value)?.expiresIn;
  d.setTime(d.getTime() + (expiresIn*1000));
  const expires =  d.toUTCString();
  document.cookie = `${name}=${value}; expires=${expires}; path=/`;
};


const customOptions = {
  setCookie,
};


const store: any = configureStore({
  reducer: {
    ObjectSelection: ObjectSelectionReducer,
    Acquisition: AcquisitionReducer,
    GeneratorTechniqueParameters: GeneratorTechniqueParametersReducer,
    Catalogue: CatalogueReducer,
    Subject: SubjectReducer,
    Procedure: ProcedureReducer,
    Orders: OrderSliceReducer,
    Overview: OverviewSliceReducer,
    DicomTagBrowser: DicomTagBrowserReducer,
    ImageDisplay: ImageDisplayReducer,
    Message: MessageReducer,
    auth: authReducer,
    MainOrderListColumns: MainOrderListReducer,
    [apiSlice.reducerPath]: apiSlice.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({serializableCheck: false, serialize: {options: {map: true}}}).concat(MqttMiddleware).concat(reduxCookiesMiddleware(paths, customOptions)).concat(apiSlice.middleware),
  devTools: false,
  /* devTools: {
   // @ts-ignore
    stateSanitizer: (state) => state.data ? { ...state, data: '<<LONG_BLOB>>' } : state
  } */
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
export default store;
