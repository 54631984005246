import csTools from 'cornerstone-tools';
import cornerstone from "cornerstone-core";
import { fitToWindow } from "./utils";

const BaseTool = csTools.importInternal('base/BaseTool');

/**
 * @public
 * @class FlipVerticalTool
 * @memberof Tools
 *
 * @classdesc Tool for switching the vertical image orientation.
 * @extends Tools.Base.BaseTool
 */
export default class FlipVerticalTool extends BaseTool {
    /** @inheritdoc */
    constructor(props = {}) {
        const defaultProps = {
            name: 'FlipVertical',
        };
        super(props, defaultProps);
    }

    activeCallback(element: HTMLElement) {
        let viewport: cornerstone.Viewport | undefined = cornerstone.getViewport(element);
        if (viewport) {
            const canvas: HTMLCanvasElement | undefined = cornerstone.getEnabledElement(element).canvas;
            if (canvas) {
                const image: cornerstone.Image | undefined = cornerstone.getEnabledElement(element).image;
                if (image) {
                    const canvasToPixelStart = cornerstone.canvasToPixel(element, { x: 0, y: 0, _canvasCoordinateBrand: "" });
                    const canvasToPixelEnd = cornerstone.canvasToPixel(element, { x: canvas?.width ?? 0, y: canvas?.height ?? 0, _canvasCoordinateBrand: "" });
                    viewport.translation!.y -= (viewport.vflip ? -1 : 1) * (image?.height - (canvasToPixelEnd.y + canvasToPixelStart.y)) ;
                    viewport.vflip = !viewport.vflip;
                    cornerstone.setViewport(element, viewport);
                    // @ts-ignore
					if (image.cutwidth !== undefined) {
						viewport = cornerstone.getViewport(element);
						if (viewport !== undefined) {
							let scale = viewport.scale;
							fitToWindow(element);
							viewport.scale = scale;
							cornerstone.setViewport(element, viewport);
						}
					}
					
					let eventData = {
						toolName: "StoreViewport",
						toolType: "StoreViewport",
						element: element,
						measurementData: undefined,
					};
					// @ts-ignore
					cornerstone.triggerEvent(element, csTools.EVENTS.MEASUREMENT_COMPLETED, eventData);
					
					eventData = {
						toolName: "FlipVertical",
						toolType: "FlipVertical",
						element: element,
						measurementData: undefined,
					};
					// @ts-ignore
					cornerstone.triggerEvent(element, csTools.EVENTS.MEASUREMENT_COMPLETED, eventData);
                }
            }
        }
    }
}
