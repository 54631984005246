import React, { useEffect, useRef } from "react";

import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import { useORTranslation } from "../Localization/ORLocalization";
import { useGetStudyWithWorkitemsQuery, workitem } from "../../apis/apiSlice";
import { useLocation, useNavigate } from "react-router-dom";
import "./RelatedStudies.scss";
import { parseISO } from "date-fns";
import Workitem from "../OrderList/Workitem";
import { setAcquisitionActive } from "../Akquisition/AcquisitionSlice";

type RelatedStudiesRowProps = {
    study: any;
};


const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
const useAppDispatch = () => useDispatch<AppDispatch>();

const RelatedStudiesRow = (props: RelatedStudiesRowProps) => {


    const { t } = useORTranslation(['common']);

    const navigate = useNavigate();

    const location = useLocation();

    const dispatch = useAppDispatch();
    const overflow = Math.max((props.study.workItems?.length || 0) - 6, 0);

    /* const {
        data,
        isSuccess,
    } = useGetWorkitemListQuery({studyId: props.study.id, workitems: props.study.workItems}, {
        skip: props.study.workItems === undefined,
    }) */

    const {
        data,
        isSuccess,
    } = useGetStudyWithWorkitemsQuery(props.study.id, {
        skip: props?.study?.id === undefined,
    })

    const onImageSelect = (workitem: string, artifactId: string | undefined, artifactId_raw: string | undefined, workitemData?: workitem | undefined) => {
    }

    const handleStudyClick = (e: React.MouseEvent) => {
        e.stopPropagation();
        dispatch({ type: "ImageDisplay/setExportDialogVisible", payload: 0 });
        dispatch({ type: "ImageDisplay/setFinalizeStudyDialogVisible", payload: 0 });
        dispatch({ type: "Procedure/setSelectedComponentSubtree", payload: ['human', 'Adult'] });
        dispatch(setAcquisitionActive({ setAcquistionButtonActive: false, studyId: undefined, workitemId: undefined }));
        dispatch({ type: "Procedure/setProcedureSelectionVisibilityState", payload: 0 });
        dispatch({ type: 'ImageDisplay/setSelectedWorkitem', payload: "" });

        navigate(`/orders/${props.study.id}/${location?.search ?? ''}`);
    }

    const handleWorkitemClick = (e: React.MouseEvent, workitem: workitem) => {
        e.stopPropagation();
        let isPlanning: boolean = false;
        if (workitem?.state === 'SCHEDULED') {
            isPlanning = true;
        }
        dispatch({ type: "ImageDisplay/setExportDialogVisible", payload: 0 });
        dispatch({ type: "ImageDisplay/setFinalizeStudyDialogVisible", payload: 0 });
        dispatch({ type: "Procedure/setSelectedComponentSubtree", payload: ['human', 'Adult'] });
        dispatch({ type: 'ImageDisplay/setSelectedWorkitem', payload: workitem?.id });
        if (!isPlanning) {
            dispatch(setAcquisitionActive({ setAcquistionButtonActive: false, studyId: undefined, workitemId: undefined }));
            dispatch({ type: "Procedure/setProcedureSelectionVisibilityState", payload: 0 });
        }
        navigate(`/orders/${props.study.id}/${location?.search ?? ''}`, { state: { isFromOrderList: true } });
    }

    return (
        <>
            {isSuccess ?
                <>
                    <div className="relatedStudiesRow" id={props.study.id} onClick={(evt) => handleStudyClick(evt)} title={props.study?.details?.desc}>
                        <label>
                            {t('date', { date: parseISO(props.study?.details?.opened), ns: 'InfoPanel' })}
                        </label>
                        <div className="preview-images-wrapper">
                            <div className="preview-images">
                                {data?.workitems?.slice(0, 6)?.map((workitem: any, i: number) =>
                                    <div key={i.toString()} onClick={(evt) => handleWorkitemClick(evt, workitem?.data)}>
                                        <Workitem onImageSelect={onImageSelect}
                                            orderId={props.study.id} workitemId={workitem?.data?.id} workitem={workitem?.data} getRawSeries={true}
                                            includeImageDesc={false} showPlannings={true} showDocuments={true} className={undefined} showRejected={false} />
                                    </div>)}
                            </div>
                            <span className="preview-images-more">
                                {overflow ? `+ ${overflow} ${t('more', { count: overflow })}` : ''}
                            </span>
                        </div>
                    </div>
                    <hr className="hrule" />
                </> : null}
        </>
    );
};

export default RelatedStudiesRow;
