import React, { useEffect, useRef, useState } from "react";
import * as cornerstoneTools from "cornerstone-tools";
import cornerstone, { EnabledElement, webGL } from "cornerstone-core";
//import BlackMaskTool from "./BlackMaskTool";
import OverviewBlackMaskTool from "./OverviewBlackMaskTool";
import OverviewTool from "./OverviewTool";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../store";
import { getImageDisplayInvalidated } from "../../OrderList/OrdersSlice";
import { Constants } from "../../../Constants";
import { getDrawHandlesOnHover, selectMatrixIndex } from "../ImageDisplaySlice";
import { render, initRenderer, getRenderCanvas, isWebGLAvailable } from '../../FromCornerstoneCore/renderer.js';
import { useORTranslation } from "../../Localization/ORLocalization";
import './Overview.scss';

type OverviewProps = {
  orderId: string;
};

const useAppDispatch = () => useDispatch<AppDispatch>();
const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

const Overview = (props: OverviewProps) => {
  const dispatch = useAppDispatch();

  const { t } = useORTranslation(['Tools']);

  const viewportElement = useRef<HTMLDivElement>(null);
  const overviewVisible: boolean = useAppSelector((state) => state.Overview.isOverviewVisible);
  const imageDisplayInvalidated: boolean = useAppSelector((state) => getImageDisplayInvalidated(state));
  const selectedMatrixIndex: number | undefined = useAppSelector((state) => selectMatrixIndex(state));
  const drawHandlesOnHover: boolean = useAppSelector((state) => getDrawHandlesOnHover(state));

  const [zoomLevel, setZoomLevel] = useState<number>(0);

  const cornerstoneElements: EnabledElement[] = cornerstone.getEnabledElements();
  const imageDisplayElement: EnabledElement | undefined = cornerstoneElements?.find(
    (element: EnabledElement) => element.element.id === `${Constants.IMAGE_DISPLAY_GENERIC_ELEMENT_NAME}_${selectedMatrixIndex}`
  );


  const onToolsHideButtonClicked = (evt: React.MouseEvent<HTMLButtonElement>) => {
    dispatch({ type: "Overview/changeOverviewVisible", payload: !overviewVisible });
  };

  const onImageRendered = (evt: any) => {
    if (evt && evt.detail && evt.detail.viewport) {
      setZoomLevel(evt.detail.viewport.scale);

      const cornerstoneElements: EnabledElement[] = cornerstone.getEnabledElements();
      const display_element: EnabledElement | undefined = cornerstoneElements.find(
      (element: EnabledElement) =>
        element.element.id === `${Constants.IMAGE_DISPLAY_GENERIC_ELEMENT_NAME}_${selectedMatrixIndex}`
      );
		if (display_element) {
			const imageStateManager = cornerstoneTools.getElementToolStateManager(display_element?.element);
			const imageToolstate = imageStateManager.get(display_element?.element, "BlackMask");
			if (imageToolstate) {
				const stateManager = cornerstoneTools.getElementToolStateManager(display_element?.element);
				let toolstate = stateManager.get(display_element?.element, "OverviewBlackMask");
				if (toolstate) {
					if (imageToolstate.data[0])
						toolstate.data[0] = structuredClone(imageToolstate.data[0]);
				} else {
					stateManager.set(display_element?.element, "OverviewBlackMask", structuredClone(imageToolstate));
				}
			} else {
			    cornerstoneTools.clearToolState(display_element?.element, "OverviewBlackMask");
			}
		}
	}
  };

  async function onNeedOverviewResize(evt: any) {
      if (viewportElement.current) {
          cornerstone.resize(viewportElement.current, true);
      }
      return;
  };

  useEffect(() => {
    if (viewportElement.current) {

      const options = {
        renderer: "webgl",
        desynchronized: true,
        preserveDrawingBuffer: true,
      };

      // @ts-ignore
      webGL.renderer.initRenderer = initRenderer;
      // @ts-ignore
      webGL.renderer.render = render;
      // @ts-ignore
      webGL.renderer.getRenderCanvas = getRenderCanvas;
      // @ts-ignore
      webGL.renderer.isWebGLAvailable = isWebGLAvailable;

      // @ts-ignore
      cornerstone.enable(viewportElement.current, options);

      //cornerstone.enable(viewportElement.current);
    }
    cornerstoneTools.addToolForElement(viewportElement.current, OverviewBlackMaskTool, { configuration: {drawHandlesOnHover: drawHandlesOnHover }});
    cornerstoneTools.addToolForElement(viewportElement.current, OverviewTool, { configuration: {drawHandlesOnHover: drawHandlesOnHover }});

    const cornerstoneElements: EnabledElement[] = cornerstone.getEnabledElements();
    const display_element: EnabledElement | undefined = cornerstoneElements.find(
      (element: EnabledElement) =>
        element.element.id === `${Constants.IMAGE_DISPLAY_GENERIC_ELEMENT_NAME}_${selectedMatrixIndex}`
    );
    if (display_element && viewportElement.current) {
        /*
        const imageStateManager = cornerstoneTools.getElementToolStateManager(imageDisplayElement?.element);
        const imageToolstate = imageStateManager.get(imageDisplayElement?.element, "BlackMask");
        if (imageToolstate) {
            const stateManager = cornerstoneTools.getElementToolStateManager(display_element?.element);
            let toolstate = stateManager.get(display_element?.element, "OverviewBlackMask");
            if (toolstate) {
                toolstate.data[0] = imageToolstate.data[0];
            } else {
                stateManager.set(display_element?.element, "OverviewBlackMask", imageToolstate);
            }
        }
        */

        cornerstoneTools.setToolActiveForElement(viewportElement.current, "OverviewBlackMask", {
        mouseButtonMask: 0,
        element: display_element,
        preventHandleOutsideImage: false,
        deleteIfHandleOutsideImage: false,
        });

      cornerstoneTools.setToolActiveForElement(viewportElement.current, "Overview", {
        mouseButtonMask: 1,
        element: display_element,
        preventHandleOutsideImage: false,
        deleteIfHandleOutsideImage: false,
      });
      display_element.element.addEventListener("cornerstoneimagerendered", onImageRendered);

      viewportElement.current.addEventListener("needoverviewresize", onNeedOverviewResize);

      if (viewportElement.current && display_element.image) {
        cornerstone.displayImage(viewportElement.current, display_element.image);
        display_element.needsRedraw = true;
        display_element.invalid = true;
      }
    }

    return () => {
      const cornerstoneElements: EnabledElement[] = cornerstone.getEnabledElements();
      const element: EnabledElement | undefined = cornerstoneElements.find(
        (element: EnabledElement) => element.element.id === Constants.OVERVIEW_ELEMENT_NAME
      );
      if (element) {
        if (cornerstoneTools.getToolForElement(element.element, "Overview")) {
          cornerstoneTools.setToolPassiveForElement(element.element, "Overview");
        }
        if (cornerstoneTools.getToolForElement(element.element, "OverviewBlackMask")) {
          cornerstoneTools.setToolPassiveForElement(element.element, "OverviewBlackMask");
        }
        cornerstone.disable(element.element);
      }

      const display_element: EnabledElement | undefined = cornerstoneElements.find(
        (element: EnabledElement) =>
          element.element.id === `${Constants.IMAGE_DISPLAY_GENERIC_ELEMENT_NAME}_${selectedMatrixIndex}`
      );
      if (display_element) {
        display_element.element.removeEventListener("cornerstoneimagerendered", onImageRendered);
      }
      if (element) {
        element.element.removeEventListener("needoverviewresize", onNeedOverviewResize);
      }
    };
  }, [props.orderId, imageDisplayInvalidated, selectedMatrixIndex]);

  useEffect(() => {
    if (overviewVisible && viewportElement && viewportElement.current) {
      cornerstone.updateImage(viewportElement.current!);
      cornerstone.resize(viewportElement.current, true);
    }
  }, [overviewVisible]);

  return (
    <div className={overviewVisible ? "Overview" : "Overview_Overview_hidden"}>
      <button
        className="Overview_hide_button"
        onClick={(evt) => onToolsHideButtonClicked(evt)}
      >
        <img
          src={overviewVisible ? "/images/Pfeil_nach_oben.svg" : "/images/Pfeil_nach_unten.svg"}
          alt={"Pfeil nach oben.svg"}
          className="overview_hide_button_img"
        />
        {overviewVisible ? t("overview") : t("overview")}
      </button>
      <br />
      <div className={overviewVisible ? "OverviewContent_show" : "OverviewContent_hide"}>
        <div className="Zoomlevel">
          <label className="zoom_label">{`${Math.round(zoomLevel * 100)}%`}</label>
        </div>
        <div id={Constants.OVERVIEW_ELEMENT_NAME} className={Constants.OVERVIEW_ELEMENT_NAME} ref={viewportElement}>
          <canvas className="cornerstone-canvas" style={{ background: "rgba(0, 0, 0, 1.0)" }} />
        </div>
      </div>
    </div>
  );
};

export default Overview;
