import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import "./ConfirmDialog.scss";
import { t } from "../Localization/ORLocalization";

export const submit = (textlines: string[], onOkAction: ((e: React.MouseEvent | React.TouchEvent) => void) | undefined, onCancelAction?: (e: React.MouseEvent | React.TouchEvent) => void) => {

  confirmAlert({
    customUI: ({ onClose }) => {
      return (
        <div className="custom-ui">
          <div className='header'>
            <div> {t('confirmationNeeded', { ns: 'common' })}</div>
          </div>
          <div className="content">
            {
              textlines?.map((textline: string, i) =>
                <p key={i.toString()}>{textline}</p>
              )
            }
          </div>
          <div className='footer'>
            <button
              onClick={(evt) => {
                if (onOkAction !== undefined) {
                  onOkAction(evt);
                }
                onClose();
              }}
            >
              {t('yes', { ns: 'common' })}
            </button>
            <button onClick={(evt: any) => { if (onCancelAction) onCancelAction(evt); onClose(); }}>{t('no', { ns: 'common' })}</button>
          </div>
        </div>
      );
    }
  });
};
