import csTools from 'cornerstone-tools';
import cornerstone from "cornerstone-core";
import { fitToWindow } from "./utils";

const BaseAnnotationTool = csTools.importInternal('base/BaseAnnotationTool');
const external = csTools.external;
const toolColors = csTools.toolColors;
const getToolState = csTools.getToolState;
const addToolState = csTools.addToolState;
const getNewContext = csTools.import("drawing/getNewContext");
const drawJoinedLines = csTools.import("drawing/drawJoinedLines");
const draw = csTools.import("drawing/draw");
const drawHandles = csTools.importInternal("drawing/drawHandles");
const setShadow = csTools.importInternal("drawing/setShadow");
const getLogger = csTools.importInternal('util/getLogger');
const Cursors = csTools.importInternal('tools/cursors');
const rectangleRoiCursor = Cursors.rectangleRoiCursor;
const getModule = csTools.getModule;
const logger = getLogger('tools:annotation:BlackMaskTool');
const moveHandleNearImagePoint = csTools.importInternal('manipulators/moveHandleNearImagePoint');

/**
 * @public
 * @class BlackMaskTool
 * @memberof Tools.Annotation
 * @classdesc Tool for displaying/editing the current black mask
 * @extends Tools.Base.BaseAnnotationTool
 */
export default class BlackMaskTool extends BaseAnnotationTool {
    constructor(props = {}) {
        const defaultProps = {
            name: 'BlackMask',
            supportedInteractionTypes: ['Mouse', 'Touch'],
            configuration: {
                drawHandles: true,
                drawHandlesOnHover: false,
                hideHandlesIfMoving: false,
                renderDashed: false,
                shadow: true,
            },
            svgCursor: rectangleRoiCursor,
        };
        super(props, defaultProps);
        
        console.log("this.options.opacity: "+this.options.opacity);
    } // constructor

    passiveCallback(element) {
        //console.log("bm passive");
        /*
        if (this.options.reset) {
            this.delete(element);
            const image = cornerstone.getImage(element);
            if (image) {
                cornerstone.updateImage(element);
            }
        }
        */
    }

    activeCallback(element) {
        //console.log("bm active");

        const toolData = getToolState(element, this.name);

        if (!toolData) {
            return;
        }
        for (let i = 0; i < toolData.data.length; i++) {
            const data = toolData.data[i];
            if (data && data.matrixIndex === this.initialConfiguration.matrixIndex) {
                if (data.locked || data.cut) {
                    const modifiedEventData = {
                        toolName: this.name,
                        toolType: this.name,
                        element: element,
                        measurementData: data,
                    };
                    cornerstone.triggerEvent(element, csTools.EVENTS.MEASUREMENT_COMPLETED, modifiedEventData);
                }
            }
        }
    }

    delete(element) {
        const toolData = getToolState(element, this.name);
        if (!toolData) {
            return;
        }
        for (let i = 0; i < toolData.data.length; i++) {
            const data = toolData.data[i];
            if (data && data.matrixIndex === this.initialConfiguration.matrixIndex) {
                csTools.removeToolState(element, this.name, data);
            }
        }

    }

    createNewMeasurement(eventData) {
        if (eventData && eventData.event !== undefined) {
            let td = getToolState(eventData.event.currentTarget, this.name);
            if (td !== undefined && td.data.length !== 0) { return; }
        }
        const goodEventData = eventData && eventData.currentPoints && eventData.currentPoints.image;
        if (!goodEventData) {
            logger.error(
                `required eventData not supplied to tool ${this.name}'s createNewMeasurement`
            );
            return;
        }

        //const element = eventData.element;

        if (eventData.event) {
            // TODO check if we really want this (remove all other blackmasks drawing a new one)

            //this.delete(eventData.event.currentTarget);
            //clearToolState(eventData.event.currentTarget, this.name);
        }
        let x = eventData.currentPoints.image.x;
        let y = eventData.currentPoints.image.y;
        const image = cornerstone.getImage(eventData.element);
        let d = Math.min(image.width, image.height) * 0.2;
        if (d === undefined || isNaN(d))
            d = 100;
        var toolData = {
            visible: true,
            active: true,
            color: undefined,
            invalidated: true,
            locked: false,
            handles: {
                "1": {
                    x: x - d,
                    y: y + d,
                    highlight: false,
                    active: false,
                    allowedOutsideImage: true,
                },
                "2": {
                    x: x + d,
                    y: y + d,
                    highlight: false,
                    active: false,
                    allowedOutsideImage: true,
                },
                "3": {
                    x: x + d,
                    y: y - d,
                    highlight: false,
                    active: false,
                    allowedOutsideImage: true,
                },
                "4": {
                    x: x - d,
                    y: y - d,
                    highlight: false,
                    active: false,
                    allowedOutsideImage: true,
                },
                end: {
                    x: x,
                    y: y,
                    highlight: false,
                    active: false,
                    allowedOutsideImage: true,
                },

                //initialRotation: eventData.viewport.rotation,

            },
            matrixIndex: this.initialConfiguration.matrixIndex,
        };

        return toolData;
    } // createNewMeasurement


    // providing an own addNewMeasurement to improve initial interaction
    addNewMeasurement(evt, interactionType) {
        evt.preventDefault();
        evt.stopPropagation();
        const eventData = evt.detail;
        const element = eventData.element;
        const measurementData = this.createNewMeasurement(eventData);

        if (!measurementData) {
            return;
        }

        addToolState(element, this.name, measurementData);

        external.cornerstone.updateImage(element);

        const modifiedEventData = {
            toolName: this.name,
            toolType: this.name,
            element: evt.target,
            measurementData: measurementData,
        };
        cornerstone.triggerEvent(evt.target, csTools.EVENTS.MEASUREMENT_COMPLETED, modifiedEventData);

        return;
    }

    pointNearTool(element, data, coords, interactionType) {
        const hasHandles = data && data.handles;
        const validParameters = hasHandles;

        if (!validParameters) {
            logger.warn(
                `invalid parameters supplied to tool ${this.name}'s pointNearTool`
            );
        }

        if (!validParameters || data.visible === false || data.matrixIndex !== this.initialConfiguration.matrixIndex) {
            return false;
        }

        const distance = interactionType === 'mouse' ? 15 : 25;

        if (data.locked || data.cut) {
            return false;
        }

        const h = data.handles;
        let points = [];
        for (var j = 1; j <= 4; j++) {
            points.push({ x: h[j].x, y: h[j].y });
        }

        for (let i = 0; i < points.length; i++) {
            let j = i < points.length - 1 ? i + 1 : 0;
            const distanceToPoint = external.cornerstoneMath.lineSegment.distanceToPoint(
                {
                    start: external.cornerstone.pixelToCanvas(element, points[i]),
                    end: external.cornerstone.pixelToCanvas(element, points[j])
                },
                coords
            );
            if (distanceToPoint < distance) {
                return true;
            }
        }

        return false;
    } // pointNearTool

    renderToolData(evt) {

        var eventData = evt.detail;
        let toolData = getToolState(evt.currentTarget, this.name);

        if (!toolData) {
            return;
        }

        const { image, element, viewport, canvasContext } = eventData;

        if (element === undefined) {
            return;
        }

        //console.log(image.imageId);
        const lineDash = getModule('globalConfiguration').configuration.lineDash;
        const {
            handleRadius,
            drawHandlesOnHover,
            hideHandlesIfMoving,
            renderDashed,
        } = this.configuration;

        if (true) {
            var imageWidth = image.columns;
            var imageHeight = image.rows;
            var layerCanvas = document.createElement('canvas');
            layerCanvas.width = imageWidth;
            layerCanvas.height = imageHeight;
            var layerContext = layerCanvas.getContext('2d');

            if (toolData && toolData.data[0]) {
                const h = toolData.data[0].handles;
                let points = [];
                for (var j = 1; j <= 4; j++) {
                    points.push({ x: h[j].x, y: h[j].y })
                }
                let path = new Path2D();
                path.moveTo(0, 0);
                path.lineTo(imageWidth, 0);
                path.lineTo(imageWidth, imageHeight);
                path.lineTo(0, imageHeight);
                path.lineTo(0, 0);
                path.moveTo(points[0].x, points[0].y);
                for (let i = 0; i < points.length; i++)
                    path.lineTo(points[i].x, points[i].y);
                path.lineTo(points[points.length - 1].x, points[points.length - 1].y);

                if (toolData.data[0].cut) {
                    let minx = imageWidth, maxx = 0, miny = imageHeight, maxy = 0;
                    for (let i = 0; i < points.length; i++) {
                        if (points[i].x < minx)
                            minx = points[i].x;
                        if (points[i].x > maxx)
                            maxx = points[i].x;
                        if (points[i].y < miny)
                            miny = points[i].y;
                        if (points[i].y > maxy)
                            maxy = points[i].y;
                    }

                    image.cutwidth = maxx - minx;
                    image.cutheight = maxy - miny;
                    image.cutx = minx + (image.cutwidth / 2);
                    image.cuty = miny + (image.cutheight / 2);

					if (viewport.rotation !== undefined && viewport.rotation !== 0) {
						const rotationRad = -viewport.rotation * Math.PI / 180.0;
						let nw = imageWidth * Math.abs(Math.cos(rotationRad)) + imageHeight * Math.abs(Math.sin(rotationRad));
						let nh = imageHeight * Math.abs(Math.cos(rotationRad)) + imageWidth * Math.abs(Math.sin(rotationRad));
						
						let rpoints = [];
						let dx = imageWidth / 2;
						let dy = imageHeight / 2;
						let sx = nw - imageWidth;
						let sy = nh - imageHeight;

						for (let i = 0; i < points.length; i++) {
							let rpoint = { x: points[i].x - dx, y: points[i].y - dy };
							if (viewport.hflip)
								rpoint.x = (imageWidth - points[i].x) - dx;
							if (viewport.vflip)
								rpoint.y = (imageHeight - points[i].y) - dy;
							let rx = rpoint.x * Math.cos(rotationRad) + rpoint.y * Math.sin(rotationRad);
							let ry = rpoint.y * Math.cos(rotationRad) + rpoint.x * Math.sin(rotationRad);
							rpoint.x = (rx + dx) + sx / 2;
							rpoint.y = (ry + dy) + sy / 2;
							rpoints[i] = rpoint;
							//console.log(points[i].x+"/"+points[i].y+" => "+rpoints[i].x+"/"+rpoints[i].y);
						}
						minx = nw;
						maxx = 0;
						miny = nh;
						maxy = 0;
						for (let i = 0; i < rpoints.length; i++) {
							if (rpoints[i].x < minx)
								minx = rpoints[i].x;
							if (rpoints[i].x > maxx)
								maxx = rpoints[i].x;
							if (rpoints[i].y < miny)
								miny = rpoints[i].y;
							if (rpoints[i].y > maxy)
								maxy = rpoints[i].y;
						}
						
						if (viewport.hflip) {
							image.cutright = minx;
							image.cutleft = nw - maxx;
						} else {
							image.cutleft = minx;
							image.cutright = nw - maxx;
						}
						if (viewport.vflip) {
							image.cutbottom = miny;
							image.cuttop = nh - maxy;
						} else {
							image.cuttop = miny;
							image.cutbottom = nh - maxy;
						}
					} else {						
						if (viewport.hflip) {
							image.cutright = minx;
							image.cutleft = image.width - maxx;
						} else {
							image.cutleft = minx;
							image.cutright = image.width - maxx;
						}
						if (viewport.vflip) {
							image.cutbottom = miny;
							image.cuttop = image.height - maxy;
						} else {
							image.cuttop = miny;
							image.cutbottom = image.height - maxy;
						}
					}
                    layerContext.filter = "opacity(100%)";
                } else {
					image.cutwidth = undefined;
					image.cutheight = undefined;
					image.cutx = undefined;
					image.cuty = undefined;
					if (toolData.data[0].darkening !== undefined) {
						console.log("using opacity from annotation data: " + toolData.data[0].darkening);
						layerContext.filter = "opacity(" + toolData.data[0].darkening + "%)";
					} else {
						if (this.options.opacity !== undefined) {
							console.log("using opacity from tooloptions: " + this.options.opacity);
							layerContext.filter = "opacity(" + this.options.opacity + "%)";
						} else {
							console.log("using fallback opacity: 50%");
							layerContext.filter = "opacity(50%)";
						}
					}
                }

                if (toolData.data[0].needRotate) {
                    if (viewport) {
                        if (toolData.data[0].angle !== undefined)
                            viewport.rotation = toolData.data[0].angle;

                    }
                    toolData.data[0].needRotate = false;
                    cornerstone.updateImage(element);
                }

                if (toolData.data[0].needFit) {
                    fitToWindow(element);
                    toolData.data[0].needFit = false;
                }

                // correct angle for storing
                if ((toolData.data[0].angle !== undefined) && (viewport.rotation !== undefined) && (toolData.data[0].angle !== viewport.rotation)) {
                    // we get here, when the user first rotated the image to the black mask and afterwards corrected the rotation angle
                    // using the cursor keys or with the rotate-90 tools; for the purpose of completely storing this state, we must take
                    // over the rotation state from the viewport to the annotation now                    
                    toolData.data[0].angle = viewport.rotation;
                    const modifiedEventData = {
                        toolName: this.name,
                        toolType: this.name,
                        element: evt.target,
                        measurementData: toolData.data[0],
                    };
                    cornerstone.triggerEvent(evt.target, csTools.EVENTS.MEASUREMENT_COMPLETED, modifiedEventData);
                }

                layerContext.fill(path, "evenodd");

                canvasContext.drawImage(layerCanvas, 0, 0);

                canvasContext.lineWidth = 9;
                canvasContext.beginPath();
                canvasContext.moveTo(0, 0);
                canvasContext.lineTo(imageWidth, 0);
                canvasContext.lineTo(imageWidth, imageHeight);
                canvasContext.lineTo(0, imageHeight);
                canvasContext.lineTo(0, 0);

                canvasContext.strokeStyle = "black";
                canvasContext.stroke();
            }

        }


        const context = getNewContext(eventData.canvasContext.canvas);

        draw(context, context => {
            // If we have tool data for this element - iterate over each set and draw it
            for (let i = 0; i < toolData.data.length; i++) {
                const data = toolData.data[i];

                if (data && (data.matrixIndex === undefined || data.matrixIndex === this.initialConfiguration.matrixIndex)) {

                    if (data.visible === false || data.cut === true) {
                        continue;
                    }

                    // Configure
                    let color = toolColors.getColorIfActive(data);
                    const handleOptions = {
                        color,
                        handleRadius,
                        drawHandlesIfActive: drawHandlesOnHover,
                        hideHandlesIfMoving,
                    };

                    setShadow(context, this.configuration);

                    const lineOptions = { color };

                    if (renderDashed) {
                        lineOptions.lineDash = lineDash;
                    }

                    // Draw
                    const h = data.handles;
                    let points = [];
                    for (var j = 1; j <= 4; j++) {
                        points.push({ x: h[j].x, y: h[j].y });
                    }

                    if (points.length) {
                        let dpoints = points;
                        dpoints.push(points[0]);
                        drawJoinedLines(context, element, dpoints[0], dpoints, lineOptions);
                        dpoints.pop();
                    }

                    if (this.configuration.drawHandles && !data.locked) {
                        drawHandles(context, eventData, data.handles, handleOptions);
                    }
                } // if
            } // for

			if (image.storevp) {				
				cornerstone.triggerEvent(element, csTools.EVENTS.MEASUREMENT_COMPLETED, {
					toolName: "StoreViewport",
					toolType: "StoreViewport",
					element: element,
					measurementData: undefined,
				});
				image.storevp = undefined;
			}
        }); // draw
    } // renderToolData

    restore(element, data) {

        var xs = data.points[0].x;
        var ys = data.points[0].y;

        let measurement = this.createNewMeasurement(
            {
                currentPoints: { image: { x: xs, y: ys } },
                viewport: { rotation: undefined },
                element: element,
            });

        let points = JSON.parse(JSON.stringify(data.points));

        let activateHandles = true;
        if (data.accepted !== undefined && data.accepted === true)
            activateHandles = false;

        for (var i = 1; i <= points.length; i++)
            measurement.handles[i] = { x: points[i - 1].x, y: points[i - 1].y, active: activateHandles, highlight: false, allowedOutsideImage: true, }

        measurement.handles.end = { x: points[0].x, y: points[0].y, active: false, highlight: false, allowedOutsideImage: true, }

        if (data.accepted !== undefined) {
            measurement.locked = data.accepted;            
        }
        if (data.cut !== undefined) {
            measurement.cut = data.cut;
        }
        if (data.needFit !== undefined) {
            measurement.needFit = data.needFit;
        }        
		if (measurement.cut === true && false) {
			measurement.needFit = true;
		}
        if (data.angle !== undefined) {
            measurement.angle = data.angle;
            measurement.needRotate = true;
        }
		if (data.darkening !== undefined) {
			console.log("reading darkening:" +data.darkening);
			measurement.darkening = data.darkening;
		} else {
			if (this.options && this.options.opacity !== undefined) {
				console.log("getting darkening from options:" +this.options.opacity);
				measurement.darkening = this.options.opacity;
			} else {
				console.log("fallback darkening 50");
				this.darkening = 50;
			}
		}
        const eventData = {
            toolName: this.name,
            toolType: this.name,
            element: element,
            measurementData: measurement,
        };
        cornerstone.triggerEvent(element, csTools.EVENTS.MEASUREMENT_ADDED, eventData);
        
        //csTools.setToolPassiveForElement(element.element, this.name, { mouseButtonMask: 1, reset: true });
        
        return measurement;
    } // restore

    store(toolData) {
        const h = toolData.handles;
        let points = [];
        for (var i = 1; i <= 4; i++) {
            points.push({ x: h[i].x, y: h[i].y })
        }
        toolData.storeToolData = {
            "points": points,
            "accepted": toolData.locked,
            "cut": toolData.cut,
            "needFit": toolData.needFit,
            "angle": toolData.angle,
            "darkening": toolData.darkening
        }
    }

    handleSelectedCallback(evt, toolData, handle, interactionType = 'mouse') {
        if (!toolData?.locked && !toolData?.cut) {
            moveHandleNearImagePoint(evt, this, toolData, handle, interactionType);
        }
    }

    updateCachedStats(image, element, data) {
        return;
    }
} // BlackMaskTool
