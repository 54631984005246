import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { getStateFromCookies } from 'redux-cookies-middleware';
import { apiSlice } from "../../apis/apiSlice";
import { Constants } from "../../Constants";
import { RootState } from "../../store"
import { messageSeverity } from "../common/MessageSlice";
import { checkLicense, revokeToken } from "./RequireAuth";

export function parseJwt(token: string) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
};

export const refreshToken = createAsyncThunk('auth/refreshToken',
    async (args: { refresh_token: string | undefined, user: string | undefined }, thunkApi) => {
        try {
            const loginResponse: any = await thunkApi.dispatch(apiSlice.endpoints.Login.initiate({ grant_type: 'refresh_token', refresh_token: args.refresh_token, scope: [] })).unwrap();
            if (loginResponse?.access_token) {
                const jwtData = parseJwt(loginResponse.access_token);
                const license = jwtData[Constants.TOKEN_LICENSE_TAG];
                const isLicenseValid = checkLicense(license)?.valid;
                if(!isLicenseValid) {
                    revokeToken(loginResponse?.refresh_token, thunkApi.dispatch);
                        thunkApi.dispatch(logOut());
                        thunkApi.dispatch({ type: "Message/setMessage", payload: { timestamp: new Date().toISOString(), severity: messageSeverity.error, text: "no valid license" } });
                        return thunkApi.rejectWithValue(new Error('no valid license'));
                }
            }
            thunkApi.dispatch(setCredentials({ user: args.user, access_token: loginResponse?.access_token, refresh_token: loginResponse?.refresh_token, expires_in: loginResponse?.expires_in }));
            return loginResponse;
        } catch (error) {
            thunkApi.dispatch(logOut());
            thunkApi.dispatch({ type: "Message/setMessage", payload: { timestamp: new Date().toISOString(), severity: messageSeverity.error, text: "error refreshing token: " + JSON.stringify(error) } });
            return thunkApi.rejectWithValue(error);
        }
    })

const paths = {
    'toCookie': { name: 'token' },
};


let _initialState = { user: null, token: null, refreshToken: null, expiresIn: null, creationDate: 0, refreshStatus: 'idle', jwtData: null, license: null };
const initialState = getStateFromCookies(_initialState, paths);

const authSlice = createSlice({
    name: 'auth',
    initialState: initialState,
    reducers: {
        setCredentials: (state, action) => {
            const { user, access_token, refresh_token, expires_in } = action.payload;
            state.jwtData = parseJwt(access_token);
            state.license = state.jwtData['https://orhro.com/jwt/license'];
            const toCookie: any = { user: user, refreshToken: refresh_token, license: state.license, expiresIn: expires_in };
            state.user = user;
            state.token = access_token;
            state.refreshToken = refresh_token;
            state.expiresIn = expires_in;
            state.creationDate = Date.now();
            state.toCookie = toCookie;
        },
        logOut: (state) => {
            state.user = null;
            state.token = null;
            state.refreshToken = null;
            state.expiresIn = null;
            state.creationDate = 0;
            state.toCookie = null;
            state.jwtData = null;
            state.license = null;
        },
    },

    extraReducers(builder) {
        builder
            .addCase(refreshToken.pending, (state, action) => {
                state.refreshStatus = 'loading'
            })
            .addCase(refreshToken.fulfilled, (state, action) => {
                state.refreshStatus = 'succeeded'
            })
            .addCase(refreshToken.rejected, (state, action) => {
                state.refreshStatus = 'failed'
            })

    }
})

export const { setCredentials, logOut } = authSlice.actions;

export default authSlice.reducer;

export const selectCurrentUser = (state: RootState) => state.auth.user;
export const selectCurrentToken = (state: RootState) => state.auth.token;
export const selectCurrentRefreshToken = (state: RootState) => state.auth.refreshToken;
export const selectCurrentExpiresIn = (state: RootState) => state.auth.expiresIn;
export const selectCurrentCreationDate = (state: RootState) => state.auth.creationDate;
export const selectCurrentToCookie = (state: RootState) => state.auth.toCookie;
export const selectRefreshStatus = (state: RootState) => state.auth.refreshStatus;
export const selectJwtData = (state: RootState) => state.auth.jwtData;
export const selectLicense = (state: RootState) => state.auth.license;
