import csTools from 'cornerstone-tools';
import cornerstone from "cornerstone-core";
import { setGetToolStateItems, updateAnnnotationOnInputChange } from "./utils";
import store from './../../../store';

const BaseTool = csTools.importInternal('base/BaseTool');
const getToolState = csTools.getToolState;

/**
 * @public
 * @class ReprocessWithBlackMaskTool
 * @memberof Tools
 *
 * @classdesc ReprocessWithBlackMaskTool
 * @extends Tools.Base.BaseTool
 */
export default class ReprocessWithBlackMaskTool extends BaseTool {
    /** @inheritdoc */
    constructor(props = {}) {
        const defaultProps = {
            name: 'ReprocessWithBlackMask',
        };
        super(props, defaultProps);
    }

    activeCallback(element) {

        const state = store.getState();
        const matrixIndex = state.ImageDisplay.selectedIndex;
        let bmname = 'BlackMask';
        let toolData = getToolState(element, bmname);
        if (toolData !== undefined && toolData.data) {
            for (let i = 0; i < toolData.data.length; i++) {
                let data = toolData.data[i];
                if (data) {
                    //cornerstone.triggerEvent(element, "reprocesswithblackmask");
                    const eventData = {
                        toolName: this.name,
                        toolType: this.name,
                        element: element,
                        measurementData: data,
                    };
                    cornerstone.triggerEvent(element, csTools.EVENTS.MEASUREMENT_COMPLETED, eventData);
                }
            }
        } else {
            // no blackmask data found, assume an automatically found blackmask was declined
            const eventData = {
                toolName: this.name,
                toolType: this.name,
                element: element,
                measurementData: undefined,
            };
            cornerstone.triggerEvent(element, csTools.EVENTS.MEASUREMENT_COMPLETED, eventData);
        }
    } // activeCallback
} // ReprocessWithBlackMaskTool
