import csTools from 'cornerstone-tools';
import cornerstone from "cornerstone-core";

const BaseTool = csTools.importInternal('base/BaseTool');


/**
 * @public
 * @class FinalizeTool
 * @memberof Tools
 *
 * @classdesc Tool for finalizing the current image
 * @extends Tools.Base.BaseTool
 */
export default class FinalizeTool extends BaseTool {
    /** @inheritdoc */
	constructor(props = {}) {
		const defaultProps = {
			name: 'Finalize',
		};
		super(props, defaultProps);
	}

	activeCallback(element) {
		const eventData = {
			toolName: this.name,
			toolType: this.name,
			element: element,
			measurementData: undefined,
		};
		cornerstone.triggerEvent(element, csTools.EVENTS.MEASUREMENT_COMPLETED, eventData);
	}
}
