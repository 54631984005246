import "./ReloadApplicationDialog.scss";
import { useORTranslation } from "../Localization/ORLocalization";
import { useEffect } from "react";

type ReloadApplicationDialogProps = {

    refresh?: number;
    resetRefresh?: () => void;
    onActionDone?: () => void;

};

const ReloadApplicationDialog = (props: ReloadApplicationDialogProps) => {

    const { t } = useORTranslation(['common', 'ReloadDialog']);


    useEffect(() => {
        if (props.refresh && props.refresh > 0) {
            console.log("Reloading application.");
            window.location.reload();
        }
        return () => {
            if (props.resetRefresh) {
                props.resetRefresh();
            }
            if (props.onActionDone) {
                props.onActionDone();
            }
        };
    }, [props.refresh]);

    return (
        <div className='reloadApplicationDialog_visible'>
            <div className='contentText'>{t(`text`, { ns: 'ReloadDialog' })}</div>
        </div>
    );
}

export default ReloadApplicationDialog;
