import csTools from 'cornerstone-tools';
import cornerstone from "cornerstone-core";
import {applyWWWCRegion} from "./utils";

const BaseAnnotationTool = csTools.importInternal('base/BaseAnnotationTool');
const external = csTools.external;

const toolColors = csTools.toolColors;
const getToolState = csTools.getToolState;
const getNewContext = csTools.import("drawing/getNewContext");
const drawRect = csTools.importInternal("drawing/drawRect");
const draw = csTools.import("drawing/draw");
const throttle = csTools.importInternal('util/throttle');
const drawHandles = csTools.importInternal("drawing/drawHandles");
const setShadow = csTools.importInternal("drawing/setShadow");

const getLogger = csTools.importInternal('util/getLogger');
const Cursors = csTools.importInternal('tools/cursors');
const rectangleRoiCursor = Cursors.rectangleRoiCursor;
const getModule = csTools.getModule;

const logger = getLogger('tools:annotation:RectangleRoiTool');

/**
 * @public
 * @class /NewWwwcRegionTool
 * @memberof Tools.Annotation
 * @classdesc Tool for drawing rectangular regions of interest, and measuring
 * the statistics of the enclosed pixels.
 * @extends Tools.Base.BaseAnnotationTool
 */
export default class NewWwwcRegionTool extends BaseAnnotationTool {
  constructor(props = {}) {
    const defaultProps = {
      name: 'NewWwwcRegion',
      supportedInteractionTypes: ['Mouse', 'Touch'],
      configuration: {
        drawHandles: true,
        drawHandlesOnHover: props.configuration.drawHandlesOnHover ?? false,
        hideHandlesIfMoving: false,
        renderDashed: false,
        shadow: true,
        // showMinMax: false,
        // showHounsfieldUnits: true
      },
      svgCursor: rectangleRoiCursor,
    };

    super(props, defaultProps);

    this.throttledApplyWWWCRegion = throttle(applyWWWCRegion, 200);

    this.disabledCallback = (element) => {this.delete(element)};
  }


  passiveCallback(element) {
    if (this.options.reset) {
      this.delete(element);
      const image = cornerstone.getImage(element);
      if (image) {
        cornerstone.updateImage(element);
      }
    }
  }

  delete(element) {
    if(!element) return;
    const toolData = getToolState(element, this.name);
    if (!toolData) {
      return;
    }
    for (let i = 0; i < toolData.data.length; i++) {
      const data = toolData.data[i];
        if (data && data.matrixIndex === this.initialConfiguration.matrixIndex) {
          csTools.removeToolState(element, this.name, data);
        }
    }
  }



  createNewMeasurement(eventData) {
    const goodEventData =
      eventData && eventData.currentPoints && eventData.currentPoints.image;

    if (!goodEventData) {
      logger.error(
        `required eventData not supplied to tool ${this.name}'s createNewMeasurement`
      );
      return;
    }

    this.delete(eventData?.element);
    //clearToolState(eventData.event.currentTarget, this.name);

    return {
      visible: true,
      active: true,
      color: undefined,
      invalidated: true,
      toolName: "NewWwwcRegion",
      handles: {
        start: {
          x: eventData.currentPoints.image.x,
          y: eventData.currentPoints.image.y,
          highlight: true,
          active: false,
        },
        end: {
          x: eventData.currentPoints.image.x,
          y: eventData.currentPoints.image.y,
          highlight: true,
          active: true,
        },
        initialRotation: eventData.viewport.rotation,
        textBox: {
          active: false,
          hasMoved: false,
          movesIndependently: false,
          drawnIndependently: true,
          allowedOutsideImage: true,
          hasBoundingBox: true,
        },
      },
      matrixIndex: this.initialConfiguration.matrixIndex,
    };
  }

  pointNearTool(element, data, coords, interactionType) {
    const hasStartAndEndHandles =
      data && data.handles && data.handles.start && data.handles.end;
    const validParameters = hasStartAndEndHandles;

    if (!validParameters) {
      logger.warn(
        `invalid parameters supplied to tool ${this.name}'s pointNearTool`
      );
    }

    if (!validParameters || data.visible === false) {
      return false;
    }

    const distance = interactionType === 'mouse' ? 15 : 25;
    const startCanvas = external.cornerstone.pixelToCanvas(
      element,
      data.handles.start
    );
    const endCanvas = external.cornerstone.pixelToCanvas(
      element,
      data.handles.end
    );

    const rect = {
      left: Math.min(startCanvas.x, endCanvas.x),
      top: Math.min(startCanvas.y, endCanvas.y),
      width: Math.abs(startCanvas.x - endCanvas.x),
      height: Math.abs(startCanvas.y - endCanvas.y),
    };

    const distanceToPoint = external.cornerstoneMath.rect.distanceToPoint(
      rect,
      coords
    );

    return data && data.matrixIndex === this.initialConfiguration.matrixIndex && distanceToPoint < distance;
  }

  updateCachedStats(image, element, data) {
    if(data?.toolName !== this.name) return;
    const viewport = cornerstone.getViewport(element);
    //_applyWWWCRegion(image, viewport, element, data.handles.start, data.handles.end, this.configuration);
    this.throttledApplyWWWCRegion(image, viewport, element, data.handles.start, data.handles.end, this.configuration);
    data.invalidated = false;
  }

  renderToolData(evt) {
    const toolData = getToolState(evt.currentTarget, this.name);

    if (!toolData) {
      return;
    }

    const eventData = evt.detail;
    const { image, element } = eventData
    const lineDash = getModule('globalConfiguration').configuration.lineDash;
    const {
      handleRadius,
      drawHandlesOnHover,
      hideHandlesIfMoving,
      renderDashed,
    } = this.configuration;
    const context = getNewContext(eventData.canvasContext.canvas);

    draw(context, context => {
      // If we have tool data for this element - iterate over each set and draw it
      for (let i = 0; i < toolData.data.length; i++) {
        const data = toolData.data[i];
        if (data && data.matrixIndex === this.initialConfiguration.matrixIndex) {

          if (data.visible === false) {
            continue;
          }

          // Configure
          const color = toolColors.getColorIfActive(data);
          const handleOptions = {
            color,
            handleRadius,
            drawHandlesIfActive: drawHandlesOnHover,
            hideHandlesIfMoving,
          };

          setShadow(context, this.configuration);

          const rectOptions = { color };

          if (renderDashed) {
            rectOptions.lineDash = lineDash;
          }

          // Draw
          drawRect(
            context,
            element,
            data.handles.start,
            data.handles.end,
            rectOptions,
            'pixel',
            data.handles.initialRotation
          );

          if (this.configuration.drawHandles) {
            drawHandles(context, eventData, data.handles, handleOptions);
          }

          if (data.invalidated === true) {
              this.updateCachedStats(image, element, data);
          }
        }
      }
    });
  }
}
