import React from "react";

import { TypedUseSelectorHook, useDispatch, useSelector, } from "react-redux";
import { AppDispatch, RootState } from "../../store";


import "./GenderSelection2.scss";
import { getSelectedSubject } from "./SubjectSlice";
import { useORTranslation } from "../Localization/ORLocalization";

type GenderSelection2Props = {
    required?: boolean
};

const useAppDispatch = () => useDispatch<AppDispatch>();
const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

const GenderSelection2 = (props: GenderSelection2Props) => {

    const { t } = useORTranslation(['Subject', 'common']);

    const dispatch = useAppDispatch();

    const subject: any = useAppSelector((state) => getSelectedSubject(state));

    const onSexClicked = (value: string) => {
        const newSubject: any = structuredClone(subject);
        let { details } = newSubject;

        if (value === 'male') {
            details = { ...details, pregnancyStatus: 'not_pregnant' };
        } else {
            details = { ...details, pregnancyStatus: undefined };
        }

        newSubject.details = { ...details, sex: value };
        dispatch({ type: "Subject/setSelectedSubject", payload: newSubject });
    }

    const female = () =>
        <React.Fragment>
            <path d="M18.5 8.5C18.5 12.0899 15.5899 15 12 15C8.41015 15 5.5 12.0899 5.5 8.5C5.5 4.91015 8.41015 2 12 2C15.5899 2 18.5 4.91015 18.5 8.5Z" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M7.5 19H16.5" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M12 22L12 15" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </React.Fragment>;

    const male = () =>
        <React.Fragment>
            <path d="M15 15.5C15 19.0899 12.0899 22 8.5 22C4.91015 22 2 19.0899 2 15.5C2 11.9101 4.91015 9 8.5 9C12.0899 9 15 11.9101 15 15.5Z" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M15 2H22V9" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M13.5 10.5L22 2" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </React.Fragment>;

    const other = () =>
        <React.Fragment>
            <path d="m4.039 11.622v4.9776" fill="#000" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.2" />
            <path d="m8.1703 0.6h3.0963v3.1999" fill="#000" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.2" />
            <path d="m1.975 14.465h4.1283" fill="#000" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.2" />
            <path d="m0.6 8.0636c0 0.94295 0.36246 1.8473 1.0076 2.5141 0.64518 0.6667 1.5202 1.0413 2.4326 1.0413s1.7875-0.3746 2.4326-1.0413c0.64517-0.6668 1.0076-1.5711 1.0076-2.5141 0-0.94296-0.36246-1.8473-1.0076-2.5141-0.64518-0.66677-1.5202-1.0414-2.4326-1.0414s-1.7875 0.37459-2.4326 1.0414c-0.64517 0.66677-1.0076 1.5711-1.0076 2.5141z" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.2" />
            <path d="m11.268 0.6-4.7957 4.9562" fill="#000" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.2" />
        </React.Fragment>;

    const getIcon = (sex: string) => {
        switch (sex) {
            case 'female':
                return female();
            case 'male':
                return male();
            default:
                return other();
        } // switch
    }

    return (
        <div className="GenderSelection2">
            <label>
                <span>{t('Sex', {ns: 'InfoPanel'})}</span>
                <span className="required">&nbsp;{`${props.required ? '*' : ''}`}</span>
            </label>
            <div className="GenderSelectionButtons2">
                <div className={`${subject?.details?.sex === 'female' ? 'GenderSelectionButtonWrapper_selected' : 'GenderSelectionButtonWrapper'}`} onClick={() => onSexClicked('female')}>
                    <svg className="GenderSelectionButton2" fill="none" viewBox="0 0 25 25" id="Flat">
                        {getIcon('female')}
                    </svg>
                </div>
                <div className={`${subject?.details?.sex === 'male' ? 'GenderSelectionButtonWrapper_selected' : 'GenderSelectionButtonWrapper'}`} onClick={() => onSexClicked('male')}>
                    <svg className="GenderSelectionButton2" fill="none" viewBox="0 0 25 25" id="Flat">
                        {getIcon('male')}
                    </svg>
                </div>
                <div className={`${subject?.details?.sex === 'other' ? 'GenderSelectionButtonWrapper_selected' : 'GenderSelectionButtonWrapper'}`} onClick={() => onSexClicked('other')} >
                    <svg className="GenderSelectionButton2" fill="none" viewBox="0 0 11.868 17.199" id="Flat">
                        {getIcon('')}
                    </svg>
                </div>

            </div>
        </div >
    );
};

export default GenderSelection2;
