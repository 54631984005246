import cornerstone, { EnabledElement } from "cornerstone-core";
import * as cornerstoneTools from "cornerstone-tools";
import { throttle } from 'throttle-debounce';
import React, { useCallback, useEffect, useRef, useState } from "react";
import { TypedUseSelectorHook, useSelector } from "react-redux";
import { Constants } from "../../Constants";
import { RootState } from "../../store";
import { useGetCollectionsQuery, useGetProcessingsQuery, useSetNewBlackMaskMutation, useSetNewProcessingMutation } from '../../apis/apiSlice';
import { getBlackMaskActive, selectToolInsetVisible, selectedWorkitem } from "./ImageDisplaySlice";
import { selectSeriesDataForWorkitem, selectInstanceForWorkitem } from '../OrderList/OrdersSlice';
import csTools from 'cornerstone-tools';

import "./BlackMask.scss";
import { setGetToolStateItems, updateAnnnotationOnInputChange } from "./Tools/utils";
import { useORTranslation } from "../Localization/ORLocalization";
import { selectTheme } from "../OrderList/MainOrderListSlice";

type BlackMaskToolsComponentProps = {
    orderId: string;
    matrixIndex: number;
};

type BlackMaskState = {
    locked: false
}

const toolname: string = 'BlackMask';

const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;


const BlackMask = (props: BlackMaskToolsComponentProps) => {

    const { t } = useORTranslation(['Tools', 'common']);

    const [windowSize, setWindowSize] = useState<{ width: number, height: number }>({ width: 440, height: 440 });
    const [selectedToolstateIndex, setSelectedToolstateIndex] = useState<number>(0);

    const [blackMaskLocked, setBlackMaskLocked] = useState<boolean>(false);
    const [blackMaskCut, setBlackMaskCut] = useState<boolean>(false);

    const selectedToolstateIndexRef = useRef<number>();
    selectedToolstateIndexRef.current = selectedToolstateIndex;

    const cornerstoneElements: EnabledElement[] = cornerstone.getEnabledElements();
    const isBlackMaskActive: boolean | undefined = useAppSelector((state) => getBlackMaskActive(state, props.matrixIndex ?? 0));
    const isToolInsetVisible: boolean | undefined = useAppSelector((state) => selectToolInsetVisible(state));

    const currentWorkitemId: string = useAppSelector((state) => selectedWorkitem(state));

    const currentSeriesAsString: string | undefined = useAppSelector((state) => selectSeriesDataForWorkitem(state, currentWorkitemId));
    const currentSeries = currentSeriesAsString ? JSON.parse(currentSeriesAsString) : undefined;
    const currentInstanceAsString: string | undefined = useAppSelector((state) => selectInstanceForWorkitem(state, currentWorkitemId));
    const currentInstance = currentInstanceAsString ? JSON.parse(currentInstanceAsString) : undefined;
    const [SetNewBlackMask] = useSetNewBlackMaskMutation();

    const displayElement: EnabledElement | undefined = cornerstoneElements?.find(
        (element: EnabledElement) => element.element.id === `${Constants.IMAGE_DISPLAY_GENERIC_ELEMENT_NAME}_${props.matrixIndex}`
    );

    const overviewElement: EnabledElement | undefined = cornerstoneElements?.find(
        (element: EnabledElement) => element.element.id === `${Constants.OVERVIEW_ELEMENT_NAME}`
    );

    const [selectedCollection, setSelectedCollection] = useState<string>("");
    const [selectedProcessing, setSelectedProcessing] = useState<string>("");
    const [SetNewProcessing] = useSetNewProcessingMutation();

    const throttleOnValueChange = useCallback(throttle(200, (measurementData) => {

        setBlackMaskLocked(measurementData.locked);
        setBlackMaskCut(measurementData.cut);
    }), [isBlackMaskActive]);

    useEffect(() => {
        const onMeasureModified = (ev: any) => {
            if (ev?.detail?.toolName === toolname) {
                throttleOnValueChange(ev?.detail?.measurementData);
            }
        };

        const onMeasureCompleted = (ev: any) => {
            if (ev?.detail?.toolName === toolname) {
                throttleOnValueChange(ev?.detail?.measurementData);
            }
        };

        const onMeasureAdded = (ev: any) => {
            if (ev?.detail?.toolName === toolname) {
                const cornerstoneElements: EnabledElement[] = cornerstone.getEnabledElements();
                const displayElement: EnabledElement | undefined = cornerstoneElements?.find(
                    (element: EnabledElement) => element.element.id === `${Constants.IMAGE_DISPLAY_GENERIC_ELEMENT_NAME}_${props.matrixIndex}`
                );
                const stateManager = cornerstoneTools.getElementToolStateManager(displayElement?.element);
                const toolstate = stateManager.get(displayElement?.element, toolname);

                if (toolstate?.data) {
                    setBlackMaskLocked(toolstate?.data.locked);
                    setBlackMaskCut(toolstate?.data.cut);
                }
            }
        };

        const onCurrentToolstateIndex = (ev: any) => {
            if (ev?.detail?.data?.toolName === toolname && (ev?.detail?.data?.currentToolstateIndex !== selectedToolstateIndexRef.current || ev?.detail?.forceRedisplay)) {
                if (ev?.detail?.data?.currentToolstateIndex === -1) {
                    setSelectedToolstateIndex(0);
                } else {
                    setSelectedToolstateIndex(ev?.detail?.data?.currentToolstateIndex);
                }
            }
        };

        setWindowSize({ width: windowSize.width, height: 220 });

        if (displayElement) {
            if (displayElement.image) {

                displayElement.element?.addEventListener(
                    cornerstoneTools.EVENTS.MEASUREMENT_MODIFIED,
                    onMeasureModified
                );
                displayElement.element?.addEventListener(
                    cornerstoneTools.EVENTS.MEASUREMENT_ADDED,
                    onMeasureAdded
                );
                displayElement.element?.addEventListener(
                    cornerstoneTools.EVENTS.MEASUREMENT_COMPLETED,
                    onMeasureCompleted
                );
                displayElement.element?.addEventListener(
                    "currentToolstateIndex",
                    onCurrentToolstateIndex
                );

                displayElement.element?.addEventListener("reprocesswithblackmask", reprocessWithBlackMask);

            }
        }
        return () => {
            if (displayElement) {

                displayElement.element?.removeEventListener(
                    cornerstoneTools.EVENTS.MEASUREMENT_MODIFIED,
                    onMeasureModified
                );
                displayElement.element?.removeEventListener(
                    cornerstoneTools.EVENTS.MEASUREMENT_ADDED,
                    onMeasureAdded
                );
                displayElement.element?.removeEventListener(
                    cornerstoneTools.EVENTS.MEASUREMENT_COMPLETED,
                    onMeasureCompleted
                );
                displayElement.element?.removeEventListener(
                    "currentToolstateIndex",
                    onCurrentToolstateIndex
                );

                displayElement.element?.removeEventListener( "reprocesswithblackmask", reprocessWithBlackMask);
            }
        };
    }, [displayElement, displayElement?.image?.imageId, isBlackMaskActive]);

    useEffect(() => {
        if (selectedToolstateIndex !== undefined) {
            const retl = setGetToolStateItems(selectedToolstateIndex, [], ["locked"], props.matrixIndex, toolname);
            throttleOnValueChange({ locked: retl[0] });
            const retc = setGetToolStateItems(selectedToolstateIndex, [], ["cut"], props.matrixIndex, toolname);
            throttleOnValueChange({ cut: retc[0] });
        }
    }, [selectedToolstateIndex]);
/*
    useEffect(() => {
        if (displayElement) {
            if (displayElement.image) {
                displayElement.element?.removeEventListener("cuttoblackmask", cutBlackMask);
                displayElement.element?.addEventListener("cuttoblackmask", cutBlackMask);
            }
        }
    }, [displayElement]);
*/
    useEffect(() => {
        let toolSelectedToolstateIndex: number = 0;
        const cornerstoneElements: EnabledElement[] = cornerstone.getEnabledElements();
        const displayElement: EnabledElement | undefined = cornerstoneElements?.find(
            (element: EnabledElement) => element.element.id === `${Constants.IMAGE_DISPLAY_GENERIC_ELEMENT_NAME}_${props.matrixIndex}`
        );
        if (displayElement) {
            const tool = cornerstoneTools.getToolForElement(displayElement.element, toolname);
            if (tool?.selectedToolstateIndex !== undefined) {
                toolSelectedToolstateIndex = tool?.selectedToolstateIndex;
            }
        }

        setGetToolStateItems(toolSelectedToolstateIndex,
            [{ name: "dragActive", value: false }], [], props.matrixIndex, toolname);

    }, [isToolInsetVisible, props.matrixIndex]);

    async function reprocessWithBlackMask(evt: any) {
        evt.stopPropagation();
        evt.preventDefault();
        console.log("receiving reprocessWithBlackMask");
        const enabledElement = displayElement;
        // @ts-ignore
        if (enabledElement?.image?.image_raw?.targetId) {
            // @ts-ignore
            const artifactId = enabledElement.image?.targetId;
            // @ts-ignore
            const rawId = enabledElement.image?.image_raw?.targetId;
            const stateManager = cornerstoneTools.getElementToolStateManager(displayElement?.element);
            const toolstate = stateManager.get(displayElement?.element, toolname);
            if (toolstate?.data && displayElement) {
                for (let i = 0; i < toolstate?.data.length; i++) {

                    let processing = currentInstance.details.processing;

                    if (processing === undefined)
                        processing = {rpid: "cop3:Dog:toes"};

                    const h = toolstate?.data[i].handles;
                    let roi = [];
                    for (var j = 1; j < 5; j++) {
                        roi.push(h[j].x);
                        roi.push(h[j].y);
                    }

                    SetNewBlackMask({ artifactId: artifactId, raw_artifactId: rawId, seriesId: currentSeries.id, instanceId: currentInstance.id, workitemId: currentWorkitemId, processing: processing, roi: roi });
                }
            }
        }
        return;
    } // reprocessWithBlackMask

    const getReprocessButton = () => {
        return (<span><button className="toolContextButton reprocessButton" onClick={reprocessWithBlackMask} >
            <img className="reprocessButtonImg" src="/images/Tools/rotate-cw-svgrepo-com.svg" alt="Button Reprocess BlackMask" />{t('reprocess with new blackmask', { ns: 'common' })}</button></span>);
    }

    const external = csTools.external;

    async function rotateToBlackMask(evt: any) {
        evt.stopPropagation();
        evt.preventDefault();
        const enabledElement = displayElement;
        // @ts-ignore
        if (enabledElement?.image?.image_raw?.targetId) {
            const stateManager = cornerstoneTools.getElementToolStateManager(displayElement?.element);
            const toolstate = stateManager.get(displayElement?.element, toolname);
            if (toolstate?.data && displayElement) {
                for (let i = 0; i < toolstate?.data.length; i++) {
                    const h = toolstate?.data[i].handles;
                    let roi = [];
                    for (let j = 1; j < 5; j++) {
                        roi.push(h[j]);
                    }
                    roi.push(h[1]);
                    let rangle = 360.0;
                    for (let j = 1; j < 4; j++) {
                        var dx = (roi[j + 1].x - roi[j].x);
                        var dy = (roi[j + 1].y - roi[j].y);

                        var l = Math.sqrt(dx * dx + dy * dy);
                        if (l > 0.05) {
                            var angle = dy / l;
                            if (angle <= -1.0)
                                angle = -Math.PI;
                            else
                                if (angle > 1.0)
                                    angle = Math.PI;
                                else
                                    angle = Math.acos(angle);
                            angle *= 180.0 / Math.PI;
                            if (dy < 0)
                                angle = -angle;
                        } else {
                            angle = 0.0;
                        }
                        if (Math.abs(angle) < Math.abs(rangle))
                            rangle = angle;
                    } // for
                    console.log("rangle: " + rangle)
                    var viewport = external.cornerstone.getViewport(enabledElement.element);
                    if (viewport) {
                        // @ts-ignore
                        viewport.rotation = rangle;
                        external.cornerstone.setViewport(enabledElement.element, viewport);
                    }
                }
            }
        }
        return;
    } // rotateToBlackMask

    const getRotateButton = () => {
        return (<span><button className="toolContextButton rotateButton" onClick={rotateToBlackMask} >
            <img className="rotateButtonImg" src="/images/Tools/rotate-cw-svgrepo-com.svg" alt="Button Rotate To BlackMask" />{t('rotate image to blackmask', { ns: 'common' })}</button></span>);
    }

    async function unlockBlackMask(evt: any) {
        evt.stopPropagation();
        evt.preventDefault();
        setBlackMaskLocked(false);
        const stateManager = cornerstoneTools.getElementToolStateManager(displayElement?.element);
        const toolstate = stateManager.get(displayElement?.element, toolname);
        if (toolstate?.data && displayElement) {
            for (let i = 0; i < toolstate?.data.length; i++) {
                setGetToolStateItems(i, [{ name: "locked", value: false }], [], props.matrixIndex, toolname);
                updateAnnnotationOnInputChange(i, props.matrixIndex, toolname);
            }
        }
    }

    async function lockBlackMask(evt: any) {
        evt.stopPropagation();
        evt.preventDefault();
        setBlackMaskLocked(true);

        const stateManager = cornerstoneTools.getElementToolStateManager(displayElement?.element);
        const toolstate = stateManager.get(displayElement?.element, toolname);
        if (toolstate?.data && displayElement) {
            for (let i = 0; i < toolstate?.data.length; i++) {
                setGetToolStateItems(i, [{ name: "locked", value: true }], [], props.matrixIndex, toolname);
                updateAnnnotationOnInputChange(i, props.matrixIndex, toolname);
            }
        }
    }

    const getAcceptButton = () => {
        if (blackMaskLocked)
            return (<span><button className="toolContextButton revokeResultButton" onClick={unlockBlackMask} >
                <img className="revokeResultButtonImg" src="/images/delete-svgrepo-com.svg" alt="Button Revoke Accepted BlackMask" />{t('revoke', { ns: 'common' })}</button></span>);
        else
            return (<span><button className="toolContextButton acceptResultButton" onClick={lockBlackMask} >
                <img className="acceptResultButtonImg" src="/images/check.svg" alt="Button Accept BlackMask" />{t('accept', { ns: 'common' })}</button></span>);
    }

    async function uncutBlackMask(evt: any) {
        evt.stopPropagation();
        evt.preventDefault();
        setBlackMaskCut(false);
        const stateManager = cornerstoneTools.getElementToolStateManager(displayElement?.element);
        const toolstate = stateManager.get(displayElement?.element, toolname);
        if (toolstate?.data && displayElement) {
            for (let i = 0; i < toolstate?.data.length; i++) {
                setGetToolStateItems(i, [{ name: "cut", value: false }], [], props.matrixIndex, toolname);
                setGetToolStateItems(i, [{ name: "needFit", value: true }], [], props.matrixIndex, toolname);
                updateAnnnotationOnInputChange(i, props.matrixIndex, toolname);
                overviewElement?.element.dispatchEvent(new CustomEvent("needoverviewresize", { detail: { } }));
            }
        }
    }

    async function cutBlackMask(evt: any) {
        evt.stopPropagation();
        evt.preventDefault();
        setBlackMaskCut(true);
        const stateManager = cornerstoneTools.getElementToolStateManager(displayElement?.element);
        const toolstate = stateManager.get(displayElement?.element, toolname);
        if (toolstate?.data && displayElement) {
            for (let i = 0; i < toolstate?.data.length; i++) {
                setGetToolStateItems(i, [{ name: "cut", value: true }], [], props.matrixIndex, toolname);
                setGetToolStateItems(i, [{ name: "needFit", value: true }], [], props.matrixIndex, toolname);
                updateAnnnotationOnInputChange(i, props.matrixIndex, toolname);
            }
        }
    }

    const getCutButton = () => {
        if (blackMaskCut)
            return (<span><button className="toolContextButton uncutButton" onClick={uncutBlackMask} >
                <img className="uncutButtonImg" src="/images/delete-svgrepo-com.svg" alt="Button Restore BlackMask Cut" />{t('uncut', { ns: 'common' })}</button></span>);
        else
            return (<span><button className="toolContextButton cutButton" onClick={cutBlackMask} >
                <img className="cutButtonImg" src="/images/check.svg" alt="Button Cut Image To BlackMask" />{t('cut', { ns: 'common' })}</button></span>);
    }

    async function reprocessImg(evt: any) {
        evt.stopPropagation();
        evt.preventDefault();
        const enabledElement = displayElement;
        // @ts-ignore
        if (enabledElement?.image?.image_raw?.targetId) {
            // @ts-ignore
            const artifactId = enabledElement.image?.targetId;
            // @ts-ignore
            const rawId = enabledElement.image?.image_raw?.targetId;

            SetNewProcessing({ artifactId: artifactId, raw_artifactId: rawId, seriesId: currentSeries.id, instanceId: currentInstance.id, workitemId: currentWorkitemId, processing: "cop3:" + selectedCollection + ":" + selectedProcessing });
        }
    }

    const fetchedcolls = useGetCollectionsQuery({ renderer: "cop3" }, {
        refetchOnMountOrArgChange: true,
        skip: false,
    });

    const fetchedprocs = useGetProcessingsQuery({ renderer: "cop3", collection: selectedCollection }, {
        refetchOnMountOrArgChange: true,
        skip: false,
    });

    const getProcMenu = () => {

        let colls = ["Select Collection"];

        if (fetchedcolls && fetchedcolls.data) {
            colls.pop();
            const fcolls = fetchedcolls.data;
            for (let i = 0; i < fcolls.length; i++)
                colls.push(fcolls[i]);
        }

        let procs = ["Select Processing"];

        if (fetchedprocs && fetchedprocs.data) {
            procs.pop();
            const fprocs = fetchedprocs.data;
            for (let i = 0; i < fprocs.length; i++)
                procs.push(fprocs[i]);
        }
        return (<span className="reprocSpan"><span className="reprocText">Reprocess as: </span>&nbsp;<select className="toolContextButton reprocMenu" id="collsel" onChange={(e) => setSelectedCollection(e.target.value)}>{colls.map((item, index) => <React.Fragment key={index.toString()}><option>{item}</option></React.Fragment>)}</select><span className="reprocText"> with processing: </span><select className="toolContextButton reprocMenu" name="procselect" onChange={(e) => setSelectedProcessing(e.target.value)}>
            {procs.map((item, index) => <React.Fragment key={index.toString()}><option>{item}</option></React.Fragment>)}
        </select><span className="reprocText">.</span>&nbsp;<button className="toolContextButton reprocButton" onClick={reprocessImg}>{t('Go!', { ns: 'common' })}</button></span>);
    }
    /*
    return (
        <div className="BlackMaskTools">
            <div className="toolContextHeader">
                {t("BlackMask Management")}
            </div>
            <div className="bmWrapper" onContextMenu={(e) => e.preventDefault()} >
                {getAcceptButton()}{getReprocessButton()}{getRotateButton()}{getCutButton()}{getProcMenu()}
            </div>
        </div>
    );
    */
    return null;
};

export default BlackMask;
