
import { useEffect, useState } from 'react';
import './BodyPartSelection.scss';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store';
import { selectIsFavoritesSelected, selectProcedureSelectionVisibilityState, selectXrayHelper, selectedComponentSubtree, selectedComponentTreeVisible, selectedXrayHelperVisible } from './ProcedureSlice';
import XrayHelper from './XrayHelper';
import ComponentTree from './ComponentTree';
import { getImagePathForCurrentTheme, getSelectedWorkitem } from '../OrderList/OrdersSlice';
import { serviceEndpoints } from '../..';
import { Actions } from 'flexlayout-react';

import { Model } from "flexlayout-react";
import Adult from './Adult';
import Child from './Child';
import Toddler from './Toddler';
import { useORTranslation } from '../Localization/ORLocalization';

interface BodyPartSelectionProps {
    orderId: string;
    model: Model;
}

const useAppDispatch = () => useDispatch<AppDispatch>();
const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

const BodyPartSelection = (props: BodyPartSelectionProps) => {

    const { t } = useORTranslation(['Procedures']);

    const dispatch = useAppDispatch();

    const currentComponentSubtree: string[] = useAppSelector((state) => selectedComponentSubtree(state));
    const procedureSelectionVisibilityState: number = useAppSelector((state) => selectProcedureSelectionVisibilityState(state));
    const componentTreeVisible: boolean = useAppSelector((state) => selectedComponentTreeVisible(state));
    const isXrayhelperVisible: any = useAppSelector((state) => selectedXrayHelperVisible(state));
    const currentWorkitemAsString: string | undefined = useAppSelector((state) => getSelectedWorkitem(state));
    const currentWorkitem = currentWorkitemAsString ? JSON.parse(currentWorkitemAsString) : undefined;

    const xrayHelper: any = useAppSelector((state) => selectXrayHelper(state, currentWorkitem?.details?.procedureCode));
    const imagePathForCurrentTheme: string = useAppSelector((state) => getImagePathForCurrentTheme(state));

    const isFavoritesSelected = useAppSelector((state) => selectIsFavoritesSelected(state));

    /* useEffect(() => {
        let resizeImg: any = undefined;
        if (currentComponentSubtree !== undefined && currentComponentSubtree.length >= 2) {
            switch (currentComponentSubtree[1]) {
                case 'Adult':
                    resizeImg = new ImageResize({
                        width: 764,
                        height: 926,
                        element: '#bodypart-image-map'
                    })
                    break;
                case 'Child':
                    resizeImg = new ImageResize({
                        width: 764,
                        height: 926,
                        element: '#bodypart-image-map-kind'
                    })
                    break;
                case 'premature infant':
                    resizeImg = new ImageResize({
                        width: 764,
                        height: 926,
                        element: '#bodypart-image-map-fruehchen'
                    })
                    break;
            }
        }
        return () => {
            if (currentComponentSubtree !== undefined && currentComponentSubtree.length >= 2) {
                resizeImg?.removeListener();
            }
        }
    }, [currentComponentSubtree, procedureSelectionVisibilityState]); */

    useEffect(() => {
        dispatch({ type: "Procedure/setXrayHelperVisible", payload: false });
    }, [props.orderId, dispatch]);


    const setSelectComponentSubtree = ((subTreeNamesPath: string[]) => {
        dispatch({ type: "Procedure/setSelectedComponentSubtree", payload: subTreeNamesPath });
        dispatch({ type: "Procedure/setIsFavoritesSelected", payload: false });
    })

    const selectfavorites = (() => {
        if (isFavoritesSelected) {
            props.model.doAction(Actions.selectTab("ProcedureSelection"));
        } else {
            props.model.doAction(Actions.selectTab("Favourites"));
        }
        dispatch({ type: "Procedure/setIsFavoritesSelected", payload: !isFavoritesSelected });
    })

    const setXrayHelperState = (isVisible: boolean) => {
        dispatch({ type: "Procedure/setXrayHelperVisible", payload: isVisible });
    }

    function getBodyParts(currentComponentSubtree: string[]) {
        if (currentComponentSubtree !== undefined && currentComponentSubtree.length >= 2) {
            switch (currentComponentSubtree[1]) {
                case 'Adult':
                    return (
                        <Adult />

                    )
                case 'Child':
                    return (
                        <Child />
                    )
                case 'premature infant':
                    return (
                        <Toddler />
                    )
            }
        }
        return null;
    }

    const getIcon = () => {
        return <div className="xrayHelperdrawingPlaceholder">
            <img className="icon-species" src="/images/icon-mensch.svg" alt="icon-mensch.svg" />
        </div>
    }

    return (
        <>
            {procedureSelectionVisibilityState === 1 && !isXrayhelperVisible ?
                <div className="componentTree">
                    <div className={componentTreeVisible ? "componentTreeWrapper" : "componentTreeWrapper_invisible"}>
                        <ComponentTree orderId={props.orderId} />
                    </div>
                    <div className={componentTreeVisible ? "bodyPartSelectionWrapper_invisible" : "bodyPartSelectionWrapper"}>
                        <div className="bodyPartSelection">
                            {getBodyParts(currentComponentSubtree)}
                        </div>
                        <div className="speciesSelection">
                            <button className={currentComponentSubtree !== undefined && Array.isArray(currentComponentSubtree) &&
                                currentComponentSubtree.length >= 2 && currentComponentSubtree[1] === 'Adult' ? "speciesSelectionButton-selected" : "speciesSelectionButton"} onClick={() => {setSelectComponentSubtree(['human', 'Adult']); dispatch({ type: "Procedure/setIsFavoritesSelected", payload: true })}}>
                                <img src="/images/icon-mensch.svg" alt="icon-mensch.svg" />
                            </button>
                            <button className={currentComponentSubtree !== undefined && Array.isArray(currentComponentSubtree) &&
                                currentComponentSubtree.length >= 2 && currentComponentSubtree[1] === 'Child' ? "speciesSelectionButton-selected" : "speciesSelectionButton"} onClick={() => {setSelectComponentSubtree(['human', 'Child']); dispatch({ type: "Procedure/setIsFavoritesSelected", payload: true })}}>
                                <img src="/images/icon-kind.svg" alt="icon-kind.svg" />
                            </button>
                            <button className={currentComponentSubtree !== undefined && Array.isArray(currentComponentSubtree) &&
                                currentComponentSubtree.length >= 2 && currentComponentSubtree[1] === 'premature infant' ? "speciesSelectionButton-selected" : "speciesSelectionButton"} onClick={() => {setSelectComponentSubtree(['human', 'premature infant']); dispatch({ type: "Procedure/setIsFavoritesSelected", payload: true })}}>
                                <img src="/images/icon_fruehchen.svg" alt="icon_fruehchen.svg" />
                            </button>
                        </div>
                        <button className={isFavoritesSelected ? "favorites-selected venaDefaultButton" : "favorites venaDefaultButton"} onClick={() => selectfavorites()}>
                            <img src={`${imagePathForCurrentTheme}favorites.svg`}
                                onError={(event: any) => { event.target.src = "/images/favorites.svg"; event.onerror = null }} alt="favorites.svg" />
                            <label>{t('Favorites')}</label>
                        </button>
                    </div>
                </div>
                :
                <>
                    <div className="xrayHelperButtonWrapper">
                        {/* <button type="submit" className={procedureSelectionVisibilityState > 0 && currentWorkitem?.protocol ? "xrayHelperButton" : "xrayHelperButton_invisisble"}
                            onMouseDown={() => setXrayHelperState(!isXrayhelperVisible)}>
                            {isXrayhelperVisible ?
                                <img className="xrayHelperButtonImg"
                                    src={`${imagePathForCurrentTheme}annotationen_aus1.svg`}
                                    onError={(event: any) => { event.target.src = "/images/annotationen_aus1.svg"; event.onerror = null }}
                                    alt="tag browser" />
                                :
                                <img className="xrayHelperButtonImg"
                                    src={`${imagePathForCurrentTheme}annotationen_ein1.svg`}
                                    onError={(event: any) => { event.target.src = "/images/annotationen_ein1.svg"; event.onerror = null }}
                                    alt="tag browser" />
                            }
                            <label>X-Ray Helper</label>
                        </button> */}
                    </div>
                    {isXrayhelperVisible ?
                        <XrayHelper /> :
                        <div className="xrayHelperdrawingWrapper">
                            <div className="xrayHelperdrawingFrame">
                                {xrayHelper?.content?.drawing?.path !== undefined ?
                                    <img className="xrayHelperdrawing"
                                        src={`${serviceEndpoints.PROCEDURES_URL}/xrayHelperImages/${xrayHelper?.content?.drawing?.path}`}
                                        alt={`${serviceEndpoints.PROCEDURES_URL}/xrayHelperImages/${xrayHelper?.content?.drawing?.path}`}
                                    /> : getIcon()}
                            </div>
                        </div>
                    }
                </>
                //<iframe className='calibrationIFrame' src="https://www.oehm-rehbein.de/de" title="OR Technologie"></iframe>
            }
        </>
    );
}

export default BodyPartSelection;
