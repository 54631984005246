import { apiSlice, useGetSeriesQuery, useResumeWorkitemMutation, useRejectWorkitemProtocolStepMutation, workitem } from '../../apis/apiSlice';
import Instance from "./Instance";
import Spinner from "../ImageDisplay/Spinner";
import { addImageMode, getArtifactIdForSeries, loadImage, selectCurrentinstanceIndexForRawSeries, selectedStudy } from './OrdersSlice';
import { Constants } from '../../Constants';
import InfoPanel from '../Utils/ConfigurableInfoPanel/InfoPanel';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store';
import { useORTranslation } from '../Localization/ORLocalization';
import { getProtocolStepNumberFromSeries, selectedWorkitem, selectMatrixIndex } from '../ImageDisplay/ImageDisplaySlice';
import { serviceEndpoints } from '../..';
import { useRef } from 'react';


interface SeriesProps {
    orderId: string;
    workitemId: string;
    workitemData: workitem;
    seriesIds: string[];
    rawSeriesIds: string[];
    className: string;
    getRawSeries: boolean;
    includeImageDesc: boolean;
    protocolCorrelationId: string | undefined;
    onImageSelect: (workitem: string, artifactId: string | undefined, artifactId_raw: string | undefined, workitemData?: workitem | undefined, protocolCorrelationId?: string | undefined) => void;
    showRecover?: boolean;
    isRejectedSeries?: boolean;
    currentProtocolStep?: any;
}

const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
const useAppDispatch = () => useDispatch<AppDispatch>();

const Series = (props: SeriesProps) => {

    const dispatch = useAppDispatch();

    const currentStudyAsString: string | undefined = useAppSelector((state) => selectedStudy(state, props.orderId ?? ''));
    const study = currentStudyAsString ? JSON.parse(currentStudyAsString) : undefined;
    const currentWorkitemId: string = useAppSelector((state) => selectedWorkitem(state));
    const rawProtocolStepNumber: number = useAppSelector((state) => getProtocolStepNumberFromSeries(state, (props.rawSeriesIds && Array.isArray(props.rawSeriesIds) && props.rawSeriesIds.length > 0) ? props.rawSeriesIds[0] : '', props.workitemData));
    const rawProtocolStep = props.workitemData.protocol.steps[rawProtocolStepNumber];
	const ppProtocolStepNumber: number = useAppSelector((state) => getProtocolStepNumberFromSeries(state, (props.seriesIds && Array.isArray(props.seriesIds) && props.seriesIds.length > 0) ? props.seriesIds[props.seriesIds.length-1] : '', props.workitemData));
    const selectedMatrixIndex: number | undefined = useAppSelector((state) => selectMatrixIndex(state));
    const currentRawArtifactId: string | undefined = useAppSelector((state) => getArtifactIdForSeries(state, props.rawSeriesIds));
    const currentArtifactId: string | undefined = useAppSelector((state) => getArtifactIdForSeries(state, props.seriesIds));

    const [resumeWorkitemDetails] = useResumeWorkitemMutation();
	const [rejectProtocolStep] = useRejectWorkitemProtocolStepMutation();

    const currentRawArtifactIdRef = useRef<string | undefined>();
    currentRawArtifactIdRef.current = currentRawArtifactId;
    const currentArtifactIdRef = useRef<string | undefined>();
    currentArtifactIdRef.current = currentArtifactId;
    const rawProtocolStepNumberRef = useRef<number>();
    rawProtocolStepNumberRef.current = rawProtocolStepNumber;

	const ppProtocolStepNumberRef = useRef<number>();
    ppProtocolStepNumberRef.current = ppProtocolStepNumber;

    const { t } = useORTranslation(['PreviewPanel']);

    const {
        data,
        isSuccess,
    }: { data: any[], isLoading: boolean, isSuccess: boolean, isError: boolean, error: any } =
        useGetSeriesQuery(props.seriesIds, {
            refetchOnMountOrArgChange: Constants.SYNCHRONOUS_BACKEND ? true : Constants.RTK_QUERY_CACHE_REFETCHONMOUNTORARGCHANGE,
            skip: props?.seriesIds === undefined || props.seriesIds.length === 0 || (props.seriesIds[props.seriesIds.length - 1] === undefined),
        })

    const {
        data: data_raw,
        isSuccess: isSuccess_raw,
    }: { data: any[], isLoading: boolean, isSuccess: boolean, isError: boolean, error: any } = useGetSeriesQuery(props.rawSeriesIds, {
        refetchOnMountOrArgChange: Constants.RTK_QUERY_CACHE_REFETCHONMOUNTORARGCHANGE,
        skip: props.getRawSeries === false || props.rawSeriesIds === undefined || props.rawSeriesIds?.length <= 0,
    })

    const reconsiderImage = (evt: any) => {
        evt.stopPropagation();
        evt.preventDefault();
        console.log("reconsider protocol step: " + rawProtocolStepNumberRef.current);
        if ((rawProtocolStepNumberRef.current ?? -1) > -1) {
            /* dispatch(apiSlice.endpoints.reconsiderWorkitemProtocolStep.initiate(
                {
                    workitemId: props.workitemId,
                    stepNumber: rawProtocolStepNumberRef.current,
                    body: ["rejected by user"]
                }
            )); */

            const stepsToRemove = [];

            const acquistionSteps = props.workitemData?.protocol?.steps?.filter((step: any) => step.type === Constants.ACQUISITION_STEP_TYPE &&
                step?.scheduled !== undefined && step?.rejected === undefined && step?.performed === undefined);


            if (acquistionSteps && Array.isArray(acquistionSteps) && acquistionSteps.length > 0) {
                for (const acqStep of acquistionSteps) {
                    const index = props.workitemData?.protocol?.steps?.indexOf(acqStep);
                    if (index !== undefined && index >= 0) {
                        stepsToRemove.push(index)
                    }
                }
            }

            /* dispatch(
                apiSlice.util.updateQueryData('getStudyWithWorkitems', props.orderId, (data: any) => {
                    const workitemList = data?.workitems?.map((workitem: any, i: number) => workitem?.data?.id);
                    if (props.workitemId && workitemList && workitemList.includes(props.workitemId)) {
                        const currentWorkitem = data?.workitems?.find((workitem: any) => workitem.data?.id === props.workitemId);
                        currentWorkitem.data.protocol.steps[rawProtocolStepNumber].rejected = undefined;
                    }
                    return data;
                })
            ); */


            if (stepsToRemove !== undefined && stepsToRemove.length > 0) {
                for (const rmStep of stepsToRemove) {
                    if (rmStep < props.workitemData?.protocol?.steps?.length) {

                        const newProtocol = structuredClone(props.workitemData?.protocol);
                        newProtocol.steps[(rawProtocolStepNumberRef.current ??  0)].rejected = undefined
                        newProtocol.steps.splice(rmStep, 1);

                        dispatch(apiSlice.endpoints.updateWorkitemProtocol.initiate(
                            {
                                workitemId:  props.workitemId,
                                body: {
                                    protocol: newProtocol
                                }
                            }
                        ));

                        dispatch(
                            apiSlice.util.updateQueryData('getStudyWithWorkitems', props.orderId, (data: any) => {
                                const workitemList = data?.workitems?.map((workitem: any, i: number) => workitem?.data?.id);
                                if (props.workitemId && workitemList && workitemList.includes(props.workitemId)) {
                                    const currentWorkitem = data?.workitems?.find((workitem: any) => workitem.data?.id === props.workitemId);
                                    currentWorkitem.data.protocol = newProtocol;
                                }
                                return data;
                            })
                        );
                    }
                }
            }

            dispatch({
                type: "ImageDisplay/setProtocolCorrelationId",
                payload: { matrixIndex: selectedMatrixIndex, protocolCorrelationId: props.workitemData.protocol.steps[(rawProtocolStepNumberRef.current ??  0)]?.correlationId }
            });
            dispatch({ type: "Procedure/setProcedureSelectionVisibilityState", payload: 0 });

            dispatch(
                loadImage({
                    imageName: `${serviceEndpoints.ARTIFACT_STORE_URL}/artifacts/${currentArtifactIdRef.current}/raw`,
                    imageId: currentWorkitemId,
                    artifactId: currentArtifactIdRef.current,
                    artifactId_Raw: currentRawArtifactIdRef.current,
                    createPreviewImage: addImageMode.none,
                    matrixIndex: selectedMatrixIndex ?? 0,
                    display_raw: false,
                    enabledElementForExport: undefined,
                })
            );


        }
    } // reconsiderImage

    const resumeWorkitem = async (evt: any) => {
        dispatch({ type: "ImageDisplay/setIsImageLoadRequired", payload: true });
        await resumeWorkitemDetails({ workitemId: props.workitemId});
        if (ppProtocolStepNumberRef && ppProtocolStepNumberRef.current)
			rejectProtocolStep({ workitemId: props.workitemId, stepNumber: ppProtocolStepNumberRef.current, body: ["rejected by user"]});
        dispatch(
            apiSlice.util.updateQueryData('getStudyWithWorkitems', props.orderId, (data: any) => {
                const workitemList = data?.workitems?.map((workitem: any, i: number) => workitem?.data?.id);
                if (props.workitemId && workitemList && workitemList.includes(props.workitemId)) {
                    let currentWorkitem = data?.workitems?.find((workitem: any) => workitem.data?.id === props.workitemId);
                    if (currentWorkitem) {
                        currentWorkitem.data.state = "SCHEDULED";
						if (ppProtocolStepNumberRef && ppProtocolStepNumberRef.current)
							currentWorkitem.data.protocol.steps[ppProtocolStepNumberRef.current].rejected = true;
                    }
                }
                return data;
            })
        );

        /* dispatch(
            loadImage({
                imageName: `${serviceEndpoints.ARTIFACT_STORE_URL}/artifacts/${currentArtifactId}/raw`,
                imageId: currentWorkitemId,
                artifactId: currentArtifactId,
                artifactId_Raw: currentRawArtifactId,
                createPreviewImage: addImageMode.none,
                matrixIndex: selectedMatrixIndex ?? 0,
                display_raw: false,
                enabledElementForExport: undefined,
            })
        ); */
    };




    return (
        <>
            {isSuccess && (isSuccess_raw || props.getRawSeries === false) ?
                <>
                    <>
                        {data?.length > 0 ?
                            <Instance instanceId={data[0]?.instances[0]}
                                instanceId_Raw={data_raw ? data_raw[0]?.instances[selectCurrentinstanceIndexForRawSeries()] : undefined}
                                className={props.className} onImageSelect={props.onImageSelect} orderId={props.orderId} workitemId={props.workitemId}
                                workitemData={props.workitemData} getRawSeries={false}
                                includeImageDesc={props.includeImageDesc} resumeWorkitem={resumeWorkitem}
                                protocolCorrelationId={props.protocolCorrelationId} isRejectedSeries={props.isRejectedSeries}
                                currentProtocolStep={props.currentProtocolStep}
                                sources={{
                                    acquisitionDetails: [rawProtocolStep], currentStudy: study, currentWorkitem: props.workitemData, currentSeries: data[0], currentInstance: undefined,
                                    currentArtifact: undefined, currentRawSeries: data_raw, currentRawInstance: undefined
                                }}
                                rawProtocolStepNumber= {rawProtocolStepNumber}
                                showRecover={props.showRecover}
                                recoverImage={reconsiderImage}/>
                            :
                            <Spinner className={props.className} />
                        }
                    </>
                    {props.isRejectedSeries ?
                        <>
                            {/* <div className="rejectedHr"/> */}
                            <InfoPanel className="infoPanelWrapper" configFile="/ImageDescInfo.json"
                                sources={{
                                    acquisitionDetails: [rawProtocolStep], currentStudy: study, currentWorkitem: props.workitemData, currentSeries: data[0], currentInstance: undefined,
                                    currentArtifact: undefined, currentRawSeries: data_raw, currentRawInstance: undefined
                                }}
                                workitemId={props.workitemId} artifactId={props.workitemId} orderId={props.orderId} editMode={props.workitemId === currentWorkitemId && study?.state !== 'COMPLETED'}
                                editEnabled={false}
                                configFileSection={'rejected'}
                                updateOnComponentShutdown={true}
                                confirmationTextlines={{
                                    cancelButtonClick: undefined,
                                    closeButtonClick: undefined,
                                    resumeButtonClick: undefined,
                                    downloadButtonClick: undefined,
                                    editButtonClick: undefined,
                                    recoverButtonClick: props.showRecover ? [t(`recoverClosedWorkitem`), t('confirmationQuestion')] : undefined,
                                }}
                                recoverImage={reconsiderImage}
                                rawProtocolStepNumber={rawProtocolStepNumber}
                            />
                        </>
                        : null}
                </>
                : null}
        </>
    );

}

export default Series;
