import cornerstone, { EnabledElement } from "cornerstone-core";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { throttle } from 'throttle-debounce';
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { ComposedChart, Area, XAxis, YAxis, CartesianGrid, ResponsiveContainer, ReferenceLine, TooltipProps, ReferenceDot, Polygon } from "recharts";
import { Constants } from "../../Constants";
import { AppDispatch, RootState } from "../../store";
import { getResolutionMeasurementActive, getResolutionMeasurementChartActive } from "./ImageDisplaySlice";
import { useAnnotateMultipleTargetsMutation, useEditAnnotationMutation, useGetAnnotationQuery, apiSlice, useRevokeAnnotationMutation } from '../../apis/apiSlice';
import HoverRangeslider from "../Utils/HoverRangeslider";

import "./ResolutionMeasurement.scss";
import { getTargetIdsForElement } from "./Tools/utils";
import { useORTranslation } from "../Localization/ORLocalization";
import { getImagePathForCurrentTheme } from "../OrderList/OrdersSlice";

type ResolutionMeasurementProps = {
    orderId: string;
    matrixIndex: number;
};

type ResolutionProfile = {
    id: string; // image id
    matrixIndex: number;
    data: { x: number; y: number; r?: number; ry?: number; w?: number; }[]; // profile data
    p: { x: number; y: number }[]; // peak data
    d: { x: number; y: number }[]; // dip data
    b: { x: number; y: number }[]; // base data
    xMax: number; // length of profile data
    yMin: number; // smallest y in profile data
    yMax: number // largest y in profile data
    lineWidth?: number; // line width of profile
    angle?: number; // angle of profile against detector matrix
    resolution?: string; // resolution measurement result
    locked?: boolean; // is this measurement accepted and stored?
    wires?: [p: number]; // percentage of detected wire pair (D1-D15)
};
// global store of all profiles received via onGrayScaleLineProfileChanged() with meta data (yMin/yMax)
var allResolutionProfiles: ResolutionProfile[] = [];
var measurementId = -1;
const MINANGLE = 2;
const MAXANGLE = 5;
const MAXWIRES = 15; // 18?

//const throttle = csTools.importInternal('util/throttle');

const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
const useAppDispatch = () => useDispatch<AppDispatch>();


const ResolutionMeasurement = (props: ResolutionMeasurementProps) => {
    const dispatch = useAppDispatch();

    const { t } = useORTranslation(['Tools', 'common']);

    const [xMax, setXMax] = useState<number>(10000);
    const [yMin, setYMin] = useState<number>(10000);
    const [yMax, setYMax] = useState<number>(10000);

    const [imageId, setImageId] = useState<string>("");
    //const [measurementId, setMeasurementId] = useState<string>("");
    const [profileData, setProfileData] = useState<{ x: number; y: number; p?: number; d?: number; b?: number; r?: number }[]>([]);
    const [peaks, setPeaks] = useState<{ x: number; y: number; }[]>([]);
    const [dips, setDips] = useState<{ x: number; y: number; }[]>([]);
    const [base, setBase] = useState<{ x: number; y: number; w?: number; r?: number }[]>([]);
    const [legend, setLegend] = useState<{ x: number; y: number; i?: number }[]>([]);

    const [lineWidth, setLineWidth] = useState<number>(22);

    const [resolutionMeasurementLocked, setResolutionMeasurementLocked] = useState<boolean>(false);
    const [resolutionMeasurementResult, setResolutionMeasurementResult] = useState<string>("n/a");
    const [resolutionMeasurementAngle, setResolutionMeasurementAngle] = useState<number>(-1);


    const [AnnotateMulti] = useAnnotateMultipleTargetsMutation();
    const [Edit] = useEditAnnotationMutation();
    const [Revoke] = useRevokeAnnotationMutation();

    const isResolutionMeasurementChartActive: boolean | undefined = useAppSelector((state) => getResolutionMeasurementChartActive(state, props.matrixIndex ?? 0));
    const imagePathForCurrentTheme: string = useAppSelector((state) => getImagePathForCurrentTheme(state));

    const [selectedToolstateIndex, setSelectedToolstateIndex] = useState<number>(0);
    const selectedToolstateIndexRef = useRef<number>();
    selectedToolstateIndexRef.current = selectedToolstateIndex;


    const onResolutionMeasurementEnabled = (evt: any) => {
        //console.log("ENABLE RM EVENT RECEIVED");
        if (displayElement)
            if (displayElement.image) {
                let image = displayElement.image;
                // @ts-ignore
                let imageId: string = image.image_raw ? image.image_raw.targetId : image.targetId;
                //console.log("for image: "+imageId);
                setImageId(imageId);
            }
        dispatch({ type: "ImageDisplay/setResolutionMeasurementActive", payload: { matrixIndex: props.matrixIndex ?? 0, isActive: true } });
    };

    const onResolutionMeasurementDisabled = (evt: any) => {
        //console.log("DISABLE RM EVENT RECEIVED");
        if (displayElement)
            if (displayElement.image) {
                let image = displayElement.image;
                // @ts-ignore
                let imageId: string = image.image_raw ? image.image_raw.targetId : image.targetId;
                //console.log("for image: "+displayElement?.image?.imageId);
                setImageId("");
            }
        dispatch({ type: "ImageDisplay/setResolutionMeasurementActive", payload: { matrixIndex: props.matrixIndex ?? 0, isActive: false } });
    };

    const onResolutionMeasurementId = (evt: any) => {
        //console.log("RM ID EVENT RECEIVED, new id is " + evt.detail);
        //setMeasurementId(evt.detail);
        measurementId = evt.detail;
    }

    const onPointsChanged = (evt: any) => {
        //console.log("RM POINT CHANGE EVENT RECEIVED");
        if (evt && evt.detail && evt.detail[8] === measurementId) {
            //console.log(evt.detail);

            var peaks: { x: number, y: number }[] = evt.detail[0];
            var dips: { x: number, y: number }[] = evt.detail[1];
            var bases: { x: number, y: number, w?: number, r?: number }[] = evt.detail[2];
            var legend: { x: number, y: number, i?: number }[] = [];

            var profile: number[] = evt.detail[9];
            const xMax: number = profile.length;
            var npdata: any = [];
            let nyMin = 65535, nyMax = 0;
            for (var pi = 0; pi < xMax; pi++) {
                let y: number = profile[pi];
                npdata[pi] = { x: pi, y: y };
                if (nyMin > profile[pi])
                    nyMin = profile[pi];
                if (nyMax < profile[pi])
                    nyMax = profile[pi];
            }

            if (displayElement) {
                if (displayElement.image) {
                    // manage global profile data storage
                    let image = displayElement.image;
                    // @ts-ignore
                    let imageId: string = image.image_raw ? image.image_raw.targetId : image.targetId;

                    let matrixIndex: number = props.matrixIndex;
                    var data = npdata;
                    var pind = allResolutionProfiles.findIndex((element: ResolutionProfile) => { return ((element.id === imageId) && (element.matrixIndex === matrixIndex)) });
                    if (pind !== -1) {
                        data = allResolutionProfiles[pind].data;
                    } else {
                        data.id = imageId;
                        allResolutionProfiles.push(data);
                        pind = allResolutionProfiles.length - 1;
                    }
                    var wires: [] = evt.detail[3];
                    var i = 0;
                    wires.forEach((wire => {
                        bases[i].w = i + 1;
                        bases[i].r = wire;
                        i++;
                    }));

                    if (data.length > 10) {
                        var di = data.length - 1;
                        di -= Math.floor((di / 10)+10);
                        if (di > data.length)
                            di = data.length;
                        let y = nyMax - (3 * 12);
                        let yh = nyMin + (nyMax - nyMin) * 0.5;
                        let endidx = data.length - 10;
                        let ymean = (data[endidx].y + data[endidx + 2].y + data[endidx + 5].y) / 3;
                        if (ymean > yh)
                            y = nyMin + (3 * 12); // 12/2!
                        legend.push({ x: data[di]?.x, y: y, i: 1 });
                        legend.push({ x: data[di]?.x, y: y, i: 2 });
                        legend.push({ x: data[di]?.x, y: y, i: 3 });
                    }

                    allResolutionProfiles[pind].wires = evt.detail[3];
                    allResolutionProfiles[pind].lineWidth = evt.detail[4];
                    allResolutionProfiles[pind].angle = evt.detail[5];
                    allResolutionProfiles[pind].resolution = evt.detail[6];
                    allResolutionProfiles[pind].locked = evt.detail[7];


                    setLineWidth(evt.detail[4]);
                    setResolutionMeasurementLocked(evt.detail[7]);
                    setResolutionMeasurementAngle(evt.detail[5]);
                    setResolutionMeasurementResult(evt.detail[6]);

                    setLegend(legend);
                    setPeaks(peaks);
                    setDips(dips);
                    setBase(bases);
                    setProfileData(data);

                    //setXMax(xMax);
                    setYMin(nyMin);
                    setYMax(nyMax);
                } // if displayElement.image
            } // if displayElement
        } // if evt.id == measurementId
    }; // onPointsChanged

    const cornerstoneElements: EnabledElement[] = cornerstone.getEnabledElements();
    const isResolutionMeasurementActive: boolean | undefined = useAppSelector((state) => getResolutionMeasurementActive(state, props.matrixIndex ?? 0));
    const displayElement: EnabledElement | undefined = cornerstoneElements?.find(
        (element: EnabledElement) => element.element.id === `${Constants.IMAGE_DISPLAY_GENERIC_ELEMENT_NAME}_${props.matrixIndex}`
    );

    const scaleYMax = (event: any) => {
        // event.preventDefault();
        event.stopPropagation();
        // Apply scale transform
        setYMax(yMax * (1 + event.deltaY * -0.001));
    }

    function handleLineWidth(evt: any) {
        // TODO store change via Edit()?
        evt.stopPropagation();
        evt.preventDefault();
        setLineWidth(evt.target.value);
        displayElement?.element.dispatchEvent(new CustomEvent("RMLW", { detail: { value: evt.target.value, id: measurementId } }));
    }
    /*
        function toggleResolutionMeasurement(evt: any) {
            //console.log("toggleResolutionMeasurement ");
            displayElement?.element.dispatchEvent(new CustomEvent("RM", { detail: {value: !resolutionMeasurement, id: measurementId }}));
            // TODO really?
            displayElement?.element.dispatchEvent(new CustomEvent("TT", { detail: {value: undefined, id: measurementId } }));
            setResolutionMeasurement(!resolutionMeasurement);
        }
    */
    const {
        data,
        isSuccess,
        // @ts-ignore
    } = useGetAnnotationQuery(displayElement?.image?.image_raw ? displayElement?.image?.image_raw.targetId : displayElement?.image?.targetId, {
        refetchOnMountOrArgChange: true,
        skip: false,
    })

    const getResolution = (annos: any): string => {
        return "n/a"
    }

    const formatResolution = () => {
        return (<div>Spatial Resolution: {}</div>);
    }

    async function lockResolutionManagement(evt: any) {
        //console.log("lockResolutionManagement "+data);
        //@ts-ignore
        let imageId = displayElement?.image?.image_raw ? displayElement?.image?.image_raw.targetId : displayElement?.image?.targetId;
        var profile;
        const haveId = (element: ResolutionProfile) => { return element.id === imageId };
        var index = allResolutionProfiles.findIndex(haveId);
        if (index !== -1) {
            profile = allResolutionProfiles[index];

            // create or update the ResolutionMeasurementResult annotation
            //let result = { resolution: profile.resolution, angle: profile.angle/*, wires: results*/ };
            let result = { resolution: resolutionMeasurementResult, angle: resolutionMeasurementAngle/*, wires: results*/ };

            var results = [];
            for (let i = 0; i < MAXWIRES; i++)
                if (profile !== undefined && profile.wires !== undefined && profile.wires[i])
                    results.push(profile.wires[i]);
                else
                    results.push("n/a");

            profile.locked = true;

            let haveStoredResolution = false;
            if (data !== undefined) {
                for (let i = 0; i < data.length; i++) {
                    let anno = data[i];
                    if (anno.body.type === "ResolutionMeasurementResult") {
                        haveStoredResolution = true;
                        let newvalue = { /*wires: result.wires,*/ angle: result.angle, resolution: result.resolution };
                        let body = { body: { type: anno.body.type, format: anno.body.format, value: newvalue } };

                        //console.log("Edit ResolutionMeasurementResult anno with id: "+anno.id);
                        Edit({ annotationId: anno.id, body: body });

                        // @ts-ignore
                        dispatch(apiSlice.util.updateQueryData('getAnnotation', displayElement?.image?.targetId, (newData: any) => {
                            if (newData) {
                                const annotationData = newData.find((data: any) => data.id === anno.id);
                                if (annotationData?.body?.value) {
                                    annotationData.body.value = newvalue;
                                }
                            }
                            return newData;
                        })
                        );

                        // @ts-ignore
                        if (displayElement?.image?.image_raw !== undefined) {
                            // @ts-ignore
                            dispatch(apiSlice.util.updateQueryData('getAnnotation', displayElement?.image?.image_raw?.targetId, (newData: any) => {
                                if (newData) {
                                    const annotationData = newData.find((data: any) => data.id === anno.id);
                                    if (annotationData?.body?.value) {
                                        annotationData.body.value = newvalue;
                                    }
                                }
                                return newData;
                            })
                            );
                        }

                        //break;
                    }
                }
            }

            if (!haveStoredResolution) {

                if (imageId === undefined) {
                    console.log("could not store ResolutionMeasurementResult, imageId is undefined");
                    return;
                }

                let targetIds: string[] | undefined = global.structuredClone(getTargetIdsForElement(displayElement));
                //console.log("Annotate image(s) " + targetIds + " with ResolutionMeasurementResult and value" + result);
                let response = await AnnotateMulti({ targets: targetIds, type: "ResolutionMeasurementResult", value: result });
                let newAnnoId = -1;
                if (response !== undefined)
                    newAnnoId = response?.data?.id;

                // @ts-ignore
                dispatch(apiSlice.util.updateQueryData('getAnnotation', displayElement?.image?.targetId, (newData: any) => {
                    if (newData) {
                        let newvalue = { /*wires: result.wires,*/ angle: result.angle, resolution: result.resolution };
                        newData.push({
                            id: newAnnoId, target: getTargetIdsForElement(displayElement), revoked: false, version: 0,
                            body: { type: "ResolutionMeasurementResult", format: "application/cornerstonetoolv1", value: newvalue }
                        });
                    }
                    return newData;
                })
                );
                // @ts-ignore
                dispatch(apiSlice.util.updateQueryData('getAnnotation', displayElement?.image?.image_raw?.targetId, (newData: any) => {
                    if (newData) {
                        let newvalue = { /*wires: result.wires,*/ angle: result.angle, resolution: result.resolution };
                        newData.push({
                            id: newAnnoId, target: getTargetIdsForElement(displayElement), revoked: false, version: 0,
                            body: { type: "ResolutionMeasurementResult", format: "application/cornerstonetoolv1", value: newvalue }
                        });
                    }
                    return newData;
                })
                );
            }

            if (displayElement && displayElement.element) {
                cornerstone.updateImage(displayElement.element);
            }
        }

        setResolutionMeasurementLocked(true);

        displayElement?.element.dispatchEvent(new CustomEvent("RMLC", { detail: { value: true, id: measurementId } }));
    } // lockResolutionManagement

    async function unlockResolutionManagement(evt: any) {
        //console.log("unlockResolutionManagement ");
        //@ts-ignore
        let imageId = displayElement?.image?.image_raw ? displayElement?.image?.image_raw.targetId : displayElement?.image?.targetId;
        var profile;
        const haveId = (element: ResolutionProfile) => { return element.id === imageId };
        var index = allResolutionProfiles.findIndex(haveId);
        if (index !== -1) {
            profile = allResolutionProfiles[index];

            // create or update the ResolutionMeasurementResult annotation
            //let result = { resolution: profile.resolution, angle: profile.angle/*, wires: results*/ };
            let result = { resolution: resolutionMeasurementResult, angle: resolutionMeasurementAngle/*, wires: results*/ };

            var results = [];
            for (let i = 0; i < MAXWIRES; i++)
                if (profile !== undefined && profile.wires !== undefined && profile.wires[i])
                    results.push(profile.wires[i]);
                else
                    results.push("n/a");

            profile.locked = false;

            if (data !== undefined) {
                for (let i = 0; i < data.length; i++) {
                    let anno = data[i];
                    if (anno.body.type === "ResolutionMeasurementResult") {
                        //console.log("Revoke ResolutionMeasurementResult anno with id: "+anno.id);

                        Revoke({annotationId: anno.id, body: "", targetId: ""});

                        // @ts-ignore
                        dispatch(apiSlice.util.updateQueryData('getAnnotation', displayElement?.image?.targetId, (newData: any) => {
                            if (newData) {
                                const index = newData.findIndex((data: any) => data.id === anno.id);
                                if (index !== undefined && index >= 0) {
                                    newData.splice(index, 1);
                                }
                            }
                            return newData;
                        })
                        );

                        // @ts-ignore
                        if (displayElement?.image?.image_raw !== undefined) {
                            // @ts-ignore
                            dispatch(apiSlice.util.updateQueryData('getAnnotation', displayElement?.image?.image_raw?.targetId, (newData: any) => {
                                if (newData) {
                                    const index = newData.findIndex((data: any) => data.id === anno.id);
                                    if (index !== undefined && index >= 0) {
                                        newData.splice(index, 1);
                                    }
                                }
                                return newData;
                            })
                            );
                        }

                        //break;
                    }
                }
            }

            if (displayElement && displayElement.element) {
                cornerstone.updateImage(displayElement.element);
            }
        }

        setResolutionMeasurementLocked(false);

        displayElement?.element.dispatchEvent(new CustomEvent("RMLC", { detail: { value: false, id: measurementId } }));
    } // unlockResolutionManagement


    useEffect(() => {
        // a new? image appears at our matrix position => see if we should display the grayscale profile
        if (displayElement) {
            if (displayElement.image) {
                // search for profile data in global storage
                let image = displayElement.image;
                // @ts-ignore
                let imageId: string = image.image_raw ? image.image_raw.targetId : image.targetId

                let matrixIndex: number = props.matrixIndex;
                const haveProfile = (element: ResolutionProfile) => { return ((element.id === imageId) && (element.matrixIndex === matrixIndex)) };
                var index = allResolutionProfiles.findIndex(haveProfile);
                if (index === -1) {
                    // not found => disable resolution measurement display
                    setImageId("");
                    dispatch({ type: "ImageDisplay/setResolutionMeasurementActive", payload: { matrixIndex: props.matrixIndex, isActive: false } });
                } else {
                    // found profile data => enable resolution measurement display with data from storage
                    setImageId(imageId);
                    const profile: ResolutionProfile = allResolutionProfiles[index];
                    setProfileData(profile.data);
                    setXMax(profile.xMax);
                    setYMin(profile.yMin);
                    setYMax(profile.yMax);

                    if (profile.lineWidth !== undefined) {
                        setLineWidth(profile.lineWidth);
                    }
                    if (profile.resolution !== undefined) {
                        setResolutionMeasurementResult(profile.resolution);
                    }
                    if (profile.angle !== undefined) {
                        setResolutionMeasurementAngle(profile.angle);
                    }
                    if (profile.locked !== undefined) {
                        setResolutionMeasurementLocked(profile.locked);
                    }
                    dispatch({ type: "ImageDisplay/setResolutionMeasurementActive", payload: { matrixIndex: props.matrixIndex, isActive: true } });
                }
            } else {
                dispatch({ type: "ImageDisplay/setResolutionMeasurementActive", payload: { matrixIndex: props.matrixIndex, isActive: false } });
            }

            displayElement.element?.addEventListener("enableresolutionmeasurement", onResolutionMeasurementEnabled);
            displayElement.element?.addEventListener("disableresolutionmeasurement", onResolutionMeasurementDisabled);
            displayElement.element?.addEventListener("rmpoints", onPointsChanged);
            displayElement.element?.addEventListener("rmmeasurementid", onResolutionMeasurementId);

        }
        return () => {

            if (displayElement) {
                displayElement?.element.dispatchEvent(new CustomEvent("RMUM", { detail: { } }));

                displayElement.element?.removeEventListener("enableresolutionmeasurement", onResolutionMeasurementEnabled);
                displayElement.element?.removeEventListener("disableresolutionmeasurement", onResolutionMeasurementDisabled);
                displayElement.element?.removeEventListener("rmpoints", onPointsChanged);
                displayElement.element?.removeEventListener("rmmeasurementid", onResolutionMeasurementId);
                setImageId("");
            }
        };
    }, [displayElement, displayElement?.image?.imageId, isResolutionMeasurementChartActive]);

    const getTableData = (item: number | string, index: number) => {
        if (index === 0)
            return (<td className="resolutiontabletd">&nbsp;%&nbsp;</td>);
        else
            if (item === "n/a")
                return (<td className="resolutiontabletd">&nbsp;n/a&nbsp;</td>);
            else
                return (<td className="resolutiontabletd">&nbsp;{item}&nbsp;</td>);
    }

    const getResultTable = () => {
        var results = [];
        var profile;
        const haveId = (element: ResolutionProfile) => { return element.id === imageId };
        var index = allResolutionProfiles.findIndex(haveId);
        if (index !== -1) {
            profile = allResolutionProfiles[index];
        }

        var last = 0;
        for (var i = 0; i < MAXWIRES; i++) {
            if (profile !== undefined && profile.wires !== undefined && profile.wires[i])
                last++;
            else
                break;
        }
        last++;

        results.push("%");
        for (var i = 0; i < last; i++)
            if (profile !== undefined && profile.wires !== undefined && profile.wires[i])
                results.push(profile.wires[i]);
            else
                results.push("n/a");

        // construct HTML table
        return (
            <div className="resolutioninset">
                <div>Result: {resolutionMeasurementResult}&nbsp;&nbsp;{formatAngle()}</div>
                <table className="resolutiontable"><tbody>
                    <tr>
                        {results.map((_item, index) => <React.Fragment key={"h" + index}><th className="resolutiontabletd">{index > 0 ? "D" + index : ""}</th></React.Fragment>)}
                    </tr>
                    <tr>
                        {results.map((item, index) => <React.Fragment key={"d" + index}>{getTableData(item, index)}</React.Fragment>)}
                    </tr>
                </tbody></table>
            </div>
        );
    } // getResultTable

const throttleOnLineWidthChange = useCallback(throttle(200, (value) => {
    setLineWidth(value);
    const cornerstoneElements: EnabledElement[] = cornerstone.getEnabledElements();
    const displayElement: EnabledElement | undefined = cornerstoneElements?.find(
      (element: EnabledElement) => element.element.id === `${Constants.IMAGE_DISPLAY_GENERIC_ELEMENT_NAME}_${props.matrixIndex}`
    );
    if (displayElement?.element) {
        /*
      const ret = setGetToolStateItems(selectedToolstateIndexRef.current ?? 0, [{ name: "lineWidth", value }],
        ["linedata", "linedatad", "max", "max1", "min1", "xlines", "wallThickness", "scale", "length"], props.matrixIndex, toolname);
      setXlines(ret[5]?.map((value: number) => { return value * ret[7] }));
      setYMax(Math.floor((ret[2]) + 1));
      setHistData(ret[0]?.map((value: number, i: number) => { return { x: ((i + 0.5) / ret[0].length) * ret[8] * ret[7], y: value } }));
      if (ret && ret.length > 6) {
        setHistData1(ret[1]);
        setYMax1(Math.floor(ret[3] + 1));
        setYMin1(Math.floor(ret[4] - 1));
        setThickness(ret[6]);
      }
      */

     setLineWidth(value);
        displayElement?.element.dispatchEvent(new CustomEvent("RMLW", { detail: { value: value, id: measurementId } }));

    }
    //throttleUpdateAnnnotationOnInputChange(selectedToolstateIndexRef.current, props.matrixIndex, toolname);
  }), [isResolutionMeasurementChartActive]);


    const WireLabel = (props: any) => {
        return <text x={props.cx} y={props.cy - 24} fill="white" fontSize={12} textAnchor="middle">D{base[props.r].w}</text>;
    }

    const PercentLabel = (props: any) => {
        return <text x={props.cx} y={props.cy - 12} fill="white" fontSize={9} textAnchor="middle">{base[props.r].r}&thinsp;%</text>;
    }


    const LegendLabel = (props: any) => {
        var dy = -18;

        var label = "dip";
        var fill = 'var(--color-ready)';
        var filltext = 'var(--color-text)';

        if (props.r === 1) {
            label = "peak";
            dy = -6;
            fill = 'var(--color-error)';
            fill = 'var(--color-text)';
            return <g><Triangle cx={props.cx} cy={props.cy + dy - 3} fill={fill} r="3" /><text x={props.cx + 3} y={props.cy + dy} fill={filltext} fontSize={12} >&nbsp;&nbsp;{label}</text></g>;
        } else {
            if (props.r === 0) {
                label = "base";
                dy = -30;
                fill = 'var(--color-text)';
                return <g><Quad cx={props.cx} cy={props.cy + dy - 3} fill={fill} r="3" /><text x={props.cx + 3} y={props.cy + dy} fill={filltext} fontSize={12} >&nbsp;&nbsp;{label}</text></g>;
            }
        }

        return <g><circle cx={props.cx} cy={props.cy + dy - 3} fill={fill} r="2.5" stroke="none" /><text x={props.cx + 3} y={props.cy + dy} fill={filltext} fontSize={12} >&nbsp;&nbsp;{label}</text></g>;
    } // LegendLabel

    const  Triangle = (props: any) => {
        return <g><Polygon points={[{x:props.cx-4,y:props.cy+2}, {x:props.cx+4,y:props.cy+2}, {x:props.cx,y:props.cy-2}]} stroke='none' fill='var(--color-ready)'/></g>;
    } // Triangle

    const  Quad = (props: any) => {
        return <g><Polygon points={[{x:props.cx-2,y:props.cy-2}, {x:props.cx+2,y:props.cy-2}, {x:props.cx+2,y:props.cy+2}, {x:props.cx-2,y:props.cy+2}]} stroke='none' fill='var(--color-ready)'/></g>;
    } // Quad

    //
    //<foreignObject xmlns="http://www.w3.org/1999/xhtml" x="20" y="20" width="100" height="50">
    //<div>
    //Line Width: <input className="numberinput" min="0" max="9999" type="number" value={lineWidth} onChange={(evt) => handleLineWidth(evt)} />
    //</div>
    //</foreignObject>

    const getAreaChart = () => {

        return (<ComposedChart
            width={400}
            height={300}
            data={profileData}
            margin={{
                top: 10,
                right: 5,
                left: 0,
                bottom: 0,
            }}
        >
            <XAxis dataKey="x" tick={false} />
            <YAxis domain={[yMin, yMax]} padding={{ top: 24 }} style={{ fontSize: "0.8em" }} />
            <CartesianGrid />
            <ReferenceLine />
            <Area dataKey="y" className="chartArea" type="monotone" stroke='var(--color-text)' fillOpacity={0} dot={false} isAnimationActive={false} />
            {peaks.map((item, index) => <React.Fragment key={index.toString()}>{<ReferenceDot x={item.x} y={item.y} r={3} shape={<Triangle />} stroke="none" fill='var(--color-error)'/>}</React.Fragment>)}
            {dips.map((item, index) => <React.Fragment key={index.toString()}>{<ReferenceDot x={item.x} y={item.y} r={2.5} stroke="none" fill='var(--color-ready)'/>}</React.Fragment>)}
            {base.map((item, index) => <React.Fragment key={index.toString()}>{<ReferenceDot x={item.x} y={item.y} r={3} shape ={<Quad/>} stroke="none" fill='var(--color-text)' />}</React.Fragment>)}

            {base.map((item, index) => <React.Fragment key={index.toString()}>{<ReferenceDot x={item.x} y={item.y} r={index} fillOpacity={0} shape={<WireLabel />} />}</React.Fragment>)}
            {base.map((item, index) => <React.Fragment key={index.toString()}>{<ReferenceDot x={item.x} y={item.y} r={index} fillOpacity={0} shape={<PercentLabel />} />}</React.Fragment>)}

            {legend.map((item, index) => <React.Fragment key={index.toString()}>{<ReferenceDot x={item.x} y={item.y} r={index} fillOpacity={0} shape={<LegendLabel />} />}</React.Fragment>)}

        </ComposedChart>
        );

    } // getAreaChart

    const CustomToolTip = ({ active, payload, label }: TooltipProps<number, string>): JSX.Element => {
        if (active && payload && label && isResolutionMeasurementActive) {
            displayElement?.element.dispatchEvent(new CustomEvent("TT", { detail: { value: label, id: measurementId } }));
            return (<div><p>{`${label} : ${payload[0].value}`}</p></div>);
        } else {
            return (<div />);
        }
    };

    const formatAngle = () => {
        var profile;
        const haveId = (element: ResolutionProfile) => { return element.id === imageId };
        var index = allResolutionProfiles.findIndex(haveId);
        if (index !== -1) {
            profile = allResolutionProfiles[index];
        }
        if (resolutionMeasurementAngle!==-1/*profile && profile.angle !== undefined*/) {
            if ((resolutionMeasurementAngle >= MINANGLE) && (resolutionMeasurementAngle <= MAXANGLE))
                return (<span className='angle'>{`${t('angle')}:`} <span>{resolutionMeasurementAngle} &deg;</span></span>);
            else
                return (<span className='angle'>{`${t('angle')}:`} <span className="redangle"> {resolutionMeasurementAngle} &deg; </span></span>);
        } else {
            return (<span />);
        }
    }

    const getAccept = () => {
        if (resolutionMeasurementLocked)
            return (<span><input className="toolContextButton" type="button" value="Revoke!" onClick={unlockResolutionManagement} /></span>);
        else
            return (<span><input className="toolContextButton" type="button" value="Accept!" onClick={lockResolutionManagement} /></span>);
    }

    const getAcceptButton = () => {
        if (resolutionMeasurementLocked)
            return (<span><button className="toolContextButton revokeResultButton" onClick={unlockResolutionManagement} >
                <img className="revokeResultButtonImg"
                src={`${imagePathForCurrentTheme}delete-svgrepo-com.svg`}
                onError={(event: any) => {event.target.src="/images/delete-svgrepo-com.svg"; event.onerror = null}}
                alt="Button Revoke Accepted Resolution" />
                {t('revoke', {ns: 'common'})}</button></span>);
        else
            return (<span><button className="toolContextButton acceptResultButton" onClick={lockResolutionManagement} >
                <img className="acceptResultButtonImg"
                src={`${imagePathForCurrentTheme}check.svg`}
                onError={(event: any) => {event.target.src="/images/check.svg"; event.onerror = null}}
                alt="Button Accept Resolution" />{t('accept', {ns: 'common'})}</button></span>);
    }

    return (
        <div className="chartWrapper_with_result_table" onContextMenu={(e) => e.preventDefault()} onWheel={(e) => scaleYMax(e)}>
            <div className="toolContextHeader">
            {t("ResolutionMeasurement")}
            </div>
            <div className="chartWrapper" onContextMenu={(e) => e.preventDefault()} onWheel={(e) => scaleYMax(e)}>
                <ResponsiveContainer width="100%" height="100%" minWidth="40" minHeight="30">
                    {getAreaChart()}
                </ResponsiveContainer>
            </div>
            <div className="textblock">
                <div className="lineWidth">
                    <div className="lineWidthWrapper">
                        <span className="lineWidthText">{`${t('lineWidth')}:`}</span>
                        <span className="HoverRangeInput">
                            <HoverRangeslider onChange={throttleOnLineWidthChange} startValue={lineWidth} min={1} max={200} units={'px'} />
                        </span>
                    </div>
                </div>

                {/*
            <div className="numberinput">
                Line Width: <input type="number" min="0" max="9999" value={lineWidth} onChange={(evt) => handleLineWidth(evt)} />
            </div>
            */}
                <div className="result">
                    <span>{`${t('result')}: ${resolutionMeasurementResult}`} </span>{formatAngle()}{getAcceptButton()}
                </div>
            </div>
        </div>
    );
};

export default ResolutionMeasurement;
