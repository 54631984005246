import cornerstone, { EnabledElement } from "cornerstone-core";
import React, { useEffect, useState } from "react";
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, ResponsiveContainer, ReferenceLine } from "recharts";
import { Constants } from "../../Constants";
import "./WindowLevelHist.scss";

type WindowLevelHistProps = {
  orderId: string;
  matrixIndex: number;
};

const histBins: number = 1024;
const lineCaptureRegion_devider: number = 150;

const WindowLevelHist1 = (props: WindowLevelHistProps) => {

  const [windowWidth, setWindowWidth] = useState<number>(0);
  const [windowCenter, setWindowCenter] = useState<number>(0);
  const [windowMax, setwindowMax] = useState<number>(4096);
  const [yMax, setYMax] = useState<number>(10000);
  const [imageId, setImageId] = useState<string>("");
  const [histData, setHistData] = useState<{ x: number; y: number }[]>([]);
  const [chartDragActive, setChartDragActive] = useState<number>(-1);
  const [islinesHighlight, setIslinesHighlight] = useState<number>(-1);



  const onImageRendered = (evt: any) => {
    if (evt && evt.detail && evt.detail.viewport) {
      setWindowWidth(evt.detail.viewport.voi?.windowWidth ?? 0);
      setWindowCenter(evt.detail.viewport.voi?.windowCenter ?? 0);
    }
  };

  const cornerstoneElements: EnabledElement[] = cornerstone.getEnabledElements();
  const displayElement: EnabledElement | undefined = cornerstoneElements?.find(
    (element: EnabledElement) => element.element.id === `${Constants.IMAGE_DISPLAY_GENERIC_ELEMENT_NAME}_${props.matrixIndex}`
  );

  const scaleYMax = (event: any) => {
    //event.preventDefault();
    event.stopPropagation();
    // Apply scale transform
    setYMax(yMax * (1 + event.deltaY * -0.001));
  }

  const handleMouseDown = (evt: any) => {
    if (evt?.activePayload && evt?.activePayload.length > 0 && evt?.activePayload[0].payload) {
      if (Math.abs(evt?.activePayload[0].payload.x - windowCenter) < windowMax / lineCaptureRegion_devider) {
        setChartDragActive(0);
      } else if (Math.abs(evt?.activePayload[0].payload.x - Math.max((windowCenter - windowWidth / 2.0), 0)) < windowMax / lineCaptureRegion_devider) {
        setChartDragActive(1);
      } else if (Math.abs(evt?.activePayload[0].payload.x - Math.min((windowCenter + windowWidth / 2.0), windowMax)) < windowMax / lineCaptureRegion_devider) {
        setChartDragActive(2);
      }
    }
  };

  const handleMouseUp = (evt: React.MouseEvent<Element>) => {
    setChartDragActive(-1);
  };

  const handleMouseMove = (chartProps: any) => {
    if (chartProps?.activePayload && chartProps?.activePayload.length > 0 && chartProps?.activePayload[0].payload) {
      setIslinesHighlight(-1)
      if (Math.abs(chartProps?.activePayload[0].payload.x - windowCenter) < windowMax / lineCaptureRegion_devider) {
        setIslinesHighlight(0);
      } else if (Math.abs(chartProps?.activePayload[0].payload.x - Math.max((windowCenter - windowWidth / 2.0), 0)) < windowMax / lineCaptureRegion_devider) {
        setIslinesHighlight(1);
      } else if (Math.abs(chartProps?.activePayload[0].payload.x - Math.min((windowCenter + windowWidth / 2.0), windowMax)) < windowMax / lineCaptureRegion_devider) {
        setIslinesHighlight(2);
      }
    }

    if (chartDragActive > -1) {
      if (chartProps?.activePayload && chartProps?.activePayload.length > 0 && chartProps?.activePayload[0].payload) {
        if (chartDragActive === 0) {
          setWindowCenter(Math.abs(chartProps?.activePayload[0].payload.x));
          if (displayElement?.viewport?.voi?.windowCenter !== undefined) {
            displayElement.viewport.voi.windowCenter = Math.abs(chartProps?.activePayload[0].payload.x);
            cornerstone.updateImage(displayElement?.element);
          }
        } else {
          setWindowWidth(Math.abs(Math.abs(chartProps?.activePayload[0].payload.x) - windowCenter) * 2.0);
          if (displayElement?.viewport?.voi?.windowWidth !== undefined) {
            displayElement.viewport.voi.windowWidth = Math.abs(Math.abs(Math.abs(chartProps?.activePayload[0].payload.x) - windowCenter) * 2.0);
            cornerstone.updateImage(displayElement?.element);
          }
        }
      }
    }
  }


  useEffect(() => {
    if (displayElement) {
      setWindowWidth(displayElement.viewport?.voi?.windowWidth ?? 0);
      setWindowCenter(displayElement.viewport?.voi?.windowCenter ?? 0);
      displayElement.element?.addEventListener("cornerstoneimagerendered", onImageRendered);

      if (displayElement.image) {
        setImageId(displayElement.image?.imageId);
        setWindowWidth(displayElement.image.windowWidth);
        setWindowCenter(displayElement.image.windowCenter);

        let maxValue: number = Math.pow(2, 16);
        const imagePixelModule = cornerstone.metaData.get("imagePixelModule", displayElement.image.imageId) || {};

        let binWidth: number = 1;

        if (displayElement.image.color) {
          maxValue = 256;
          setwindowMax(maxValue);
        } else {
          if (displayElement.image.maxPixelValue) {
            maxValue = Math.pow(2, Math.ceil(Math.log(displayElement.image.maxPixelValue)/Math.log(2)));
          } else if (imagePixelModule && imagePixelModule.bitsStored) {
            maxValue = Math.pow(2, imagePixelModule.bitsStored);
          }
          setwindowMax(maxValue);
          binWidth = Math.max(Math.round(maxValue / histBins), 1);

        }

        const pixelData: number[] = displayElement.image.getPixelData();

        var x = Array(maxValue / binWidth).fill(0);
        if (displayElement.image.color) {
          for (let i = 0; i < pixelData.length; i = i + 4) {
            const red = pixelData[i];
            const green = pixelData[i + 1];
            const blue = pixelData[i + 2];
            const luminance: number = Math.round(0.2126 * red + 0.7152 * green + 0.0722 * blue);
            x[luminance] += 1;
          }
        } else {
          for (let i = 0; i < pixelData.length; i++) {
            x[Math.floor(pixelData[i] / binWidth)] += 1;
          }
        }
        setHistData(
          x.map((x, i) => {
            return { x: i * binWidth + Math.round(binWidth / 2), y: x };
          })
        );



        setYMax((pixelData.length * 10) / histBins);
      }
    }
    return () => {
      if (displayElement) {
        setImageId("");
        displayElement.element?.removeEventListener("cornerstoneimagerendered", onImageRendered);
      }
    };
  }, [displayElement, displayElement?.image?.imageId]);


  return (
    <div className="windowLevelHist">
      <div className="chartWrapper" onContextMenu={(e) => e.preventDefault()} onWheel={(e) => scaleYMax(e)}>
        <ResponsiveContainer width="95%" height="95%" minWidth="40" minHeight="30">
          <AreaChart
            width={300}
            height={400}
            data={histData}
            margin={{
              top: 0,
              right: 5,
              left: 0,
              bottom: 0,
            }}
            onMouseMove={(props: any) => handleMouseMove(props)}
            onMouseDown={(evt: any) => handleMouseDown(evt)}
            onMouseUp={(evt: any) => handleMouseUp(evt)}
            onMouseLeave={(e) => setChartDragActive(-1)}
          >
            <XAxis dataKey="x" type="number" ticks={[0, windowMax]} domain={[0, windowMax]} style={{ fontSize: "0.8em" }} height={31} />
            <YAxis width={5} type="number" tick={false} domain={[0, yMax]} allowDataOverflow={true} />
            <CartesianGrid />
            <ReferenceLine className={islinesHighlight === 0 ? "windowCenter_highlight" : "windowCenter"} x={Math.min(Math.max(windowCenter, 0), windowMax)} />
            <ReferenceLine className={islinesHighlight === 1 ? "windowWidth_left_highlight" : "windowWidth_left"} x={Math.max(windowCenter - windowWidth / 2, 0)} />
            <ReferenceLine className={islinesHighlight === 2 ? "windowWidth_right_highlight" : "windowWidth_right"} x={Math.min(windowCenter + windowWidth / 2, windowMax)} />
            <Area className="chartArea" type="monotone" dataKey="y" isAnimationActive={false} />
          </AreaChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default WindowLevelHist1;
