import React, { ReactElement } from "react";
import SVG from 'react-inlinesvg';

export enum DeviceType {
    Object,
    Generator,
    Sensor,
}



interface DeviceSettingsButtonProps {
    type: DeviceType;
    colorCode: number,
    icon: string | undefined,
    onClick: (evt: React.MouseEvent) => void;
}




const DeviceSettingsButton = (props: DeviceSettingsButtonProps) => {



    const DeviceOject =
        <React.Fragment>
            {/* <g transform="translate(26.147 31.965)">
            <path className="DeviceSettingsButtonb" transform="translate(-277.23 -396.81)" d="m298.15 443.58-0.189-25.845-20.736-11.134v23.331z" />
            <path className="DeviceSettingsButtonb" transform="translate(-275.47 -396.89)" d="m317.22 408.44-17.086 9.246v25.44l17.086-12.112z" />
            <path className="DeviceSettingsButtonb" transform="translate(-277.26 -398.52)" d="m297.18 398.52-19.381 6.734 22.026 10.744 19.124-9.171z" />
        </g> */}
            {/* <image x="20" y="30" width="50" height="50" href="/images/pipe-svgrepo-com.svg" /> */}
            <g className="object_img">
                <SVG src={props?.icon ?? ''} />
            </g>
        </React.Fragment>;


    const DeviceGenerator =
        <React.Fragment>
            <g transform="translate(-401 -903)" className="DeviceSettingsButton">
                <g transform="translate(279.42 390.92)">
                    <path className="DeviceSettingsButtonb" transform="translate(0)" d="M190.619,555.328c.229.177.458.354.688.524l2-.078c2.324.151,2.953,1.722,2.855,3.9v8.2c-.007,1.369-1.643,1.912-4.466,1.283-.065-.072-.465.1-.393.642v2.18c0,.17-.459.347-.459.517v1.892H155.23V572.26c0-.124-.052-.2-.19-.2H148.6a.242.242,0,0,1-.249-.255v-1.873c-.02-.157-.1-.2-.2-.2h-5.009a.245.245,0,0,1-.255-.223V561.28a.243.243,0,0,1,.2-.2h4.767c.183,0,.177-.157.177-.236v-2.01a.308.308,0,0,1,.354-.327h5.762c.707-1.342,1.421-2.685,2.128-4.02a3.206,3.206,0,0,1,2.94-1.65h9.841a2.6,2.6,0,0,1,2.665,2.488Z" />
                    <path className="DeviceSettingsButtonc" transform="translate(-48.393 -7.956)" d="M211.978,566.434H205.81c.079-.426.157-.845.229-1.27a2.113,2.113,0,0,1,2-1.977h7.87a2.1,2.1,0,0,1,2,1.977c.079.425.157.844.236,1.27Z" />
                    <line className="DeviceSettingsButtond" transform="translate(191.3 561.74)" y1="7.203" />
                </g>
            </g>
        </React.Fragment>;


    const DeviceSensor =
        <React.Fragment>
            <g transform="translate(29.227 32)" className="DeviceSettingsButton">
                <g className="DeviceSettingsButtonb" transform="translate(-.227)">
                    <rect className="DeviceSettingsButtong" width="38" height="44" />
                    <rect className="DeviceSettingsButtonh" x="1" y="1" width="36" height="42" />
                </g>
                <g className="DeviceSettingsButtone" transform="translate(5.572 4.232)">
                    <line className="DeviceSettingsButtond" x1="3.64" />
                    <line className="DeviceSettingsButtond" y1="3.847" />
                </g>
                <g className="DeviceSettingsButtone" transform="translate(28.428 4.232)">
                    <line className="DeviceSettingsButtond" x2="3.703" />
                    <line className="DeviceSettingsButtond" transform="translate(3.703)" y1="3.847" />
                </g>
                <g className="DeviceSettingsButtone" transform="translate(5.572 35.688)">
                    <line className="DeviceSettingsButtond" transform="translate(0 3.93)" x1="3.64" />
                    <line className="DeviceSettingsButtond" transform="translate(0)" y2="3.93" />
                </g>
                <g className="DeviceSettingsButtone" transform="translate(28.428 35.688)">
                    <line className="DeviceSettingsButtond" transform="translate(0 3.93)" x2="3.703" />
                    <line className="DeviceSettingsButtond" transform="translate(3.703)" y2="3.93" />
                </g>
            </g>
            <g transform="translate(-110.03 -73.816)">
                <g className="DeviceSettingsButtone" transform="translate(153.52 122.99)">
                    <line className="DeviceSettingsButtond" transform="translate(4.566)" y2="9.132" />
                    <line className="DeviceSettingsButtond" transform="translate(0 4.566)" x1="9.132" />
                </g>
            </g>
        </React.Fragment>;

    const renderSwitch = (param: DeviceType): ReactElement => {

        switch (param) {
            case DeviceType.Object:
                return DeviceOject;
            case DeviceType.Generator:
                return DeviceGenerator;
            case DeviceType.Sensor:
                return DeviceSensor;
            default:
                return DeviceOject;
        }
    }


    return (
        <svg version="1.1" viewBox="0 0 120.76 109.31" className="hexButton" onClick={(evt) => props.onClick(evt)}>
            <defs>
                <style>{".Stroke{stroke-miterlimit:10;stroke-width:6px;fill-rule:evenodd;}.DeviceSettingsButtonb{stroke:#f2f2f2;}.DeviceSettingsButtonb{fill:none;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px;}.DeviceSettingsButtonc{fill:#f2f2f2;}.DeviceSettingsButtond{stroke:#f2f2f2;stroke-width:2px;stroke-linecap:round;}.DeviceSettingsButtone{opacity:0.47;}.DeviceSettingsButtonf{fill:#b1c100;}.DeviceSettingsButtong{stroke:none;}.DeviceSettingsButtond{filter:url(#a);}"}</style>
                <filter id="a" x="0" y="3.565" width="120.76" height="105.75" filterUnits="userSpaceOnUse">
                    <feOffset dy="3" />
                    <feGaussianBlur result="b" stdDeviation="3" />
                    <feFlood floodOpacity=".161" />
                    <feComposite in2="b" operator="in" result="result1" />
                    <feComposite in="SourceGraphic" in2="result1" />
                </filter>
            </defs>
            <g transform="translate(12.477)" className={DeviceType[props.type]} id={props.colorCode.toString()}>
                <g className="DeviceSettingsButtond" transform="translate(-12.48)">
                    <path className="Stroke" transform="translate(-79.85 -334.22)" d="m188.13 387.67-11.977 20.441-11.977 20.425h-47.893l-11.982-20.425-11.972-20.441 11.972-20.44 11.977-20.441h47.892l11.977 20.441z" />
                </g>
                {renderSwitch(props.type)}
                <path className="Fill" transform="translate(42)" d="M6,0l6,11H0Z" />
            </g>
        </svg>
    );

}

export default DeviceSettingsButton;
