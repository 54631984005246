import { Calendar } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import '../OrderList/DateRangeSelector.scss';

import { useORTranslation } from '../Localization/ORLocalization';

interface DateSelectorProps {
	handleDateSelection: (ranges: any) => void;
	refresh?: number;
	date: Date;
	resetRefresh?: () => void;
	onActionDone?: () => void;
}

const DateSelector = (props: DateSelectorProps) => {

	const { locales } = useORTranslation([]);

	return (
		<div className="datepicker-wrapper">
			<div className="datepicker-wrapper">
				<Calendar className="datepicker"
					date={props.date}
					locale={locales}
					maxDate={new Date(Date.now())}
					onChange={props.handleDateSelection}
				/>
			</div>
		</div >
	);
}

export default DateSelector;
