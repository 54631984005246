
import { useEffect, useRef, useState } from 'react';
import './ComponentTree.scss';
import { macro, useGetMacrosForFavoriteTagQuery, useGetProcedureSubTreeByIdQuery, useGetProceduresForFavoriteTagQuery, useRemoveMacroFavoriteTagMutation, useRemoveProcedureFavoriteTagMutation, useSetNewFavoriteForMacroIdMutation, useSetNewFavoriteForProcedureCodeMutation } from '../../apis/apiSlice';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store';
import { selectFavouriteTag, setMacro, setProcedure } from './ProcedureSlice';
import { getImagePathForCurrentTheme } from '../OrderList/OrdersSlice';
import { submit } from '../Utils/ConfirmDialog';

interface ComponentTreeProps {
    orderId: string;
}

const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
const useAppDispatch = () => useDispatch<AppDispatch>();

const ComponentTree = (props: ComponentTreeProps) => {

    const dispatch = useAppDispatch();

    const selectedFavouriteTag: string = useAppSelector((state) => selectFavouriteTag(state));
    const imagePathForCurrentTheme: string = useAppSelector((state) => getImagePathForCurrentTheme(state));

    const [setNewFavoriteForProcedureCode] = useSetNewFavoriteForProcedureCodeMutation();
    const [removeProcedureFavorite] = useRemoveProcedureFavoriteTagMutation();
    const [removeMacroFavorite] = useRemoveMacroFavoriteTagMutation();
    const [setNewFavoriteForMacroId] = useSetNewFavoriteForMacroIdMutation();

    const [componentTree, setComponentTree] = useState<any[]>([]);
    const [currentNodeId, setCurrentNodeId] = useState<string | undefined>(undefined);

    const componentTreeRef = useRef<any[]>();
    componentTreeRef.current = componentTree;

    const {
        data: data_ComponentTree,
        isSuccess: isSuccess_ComponentTree,
    }: {
        data: any, isLoading: boolean, isSuccess: boolean, isError: boolean, error: any
    } = useGetProcedureSubTreeByIdQuery({ id: currentNodeId, maxDepth: 0, onlyLeaves: false }, {
        refetchOnMountOrArgChange: true,
        skip: (currentNodeId !== undefined && (searchTree({ id: '', children: componentTree }, currentNodeId)?.children !== undefined)),
    })

    const {
        data: data_favourites,
        isSuccess: isSuccess_favourites,
    }: { data: any, isLoading: boolean, isSuccess: boolean, isError: boolean, error: any } = useGetProceduresForFavoriteTagQuery(selectedFavouriteTag, {
        refetchOnMountOrArgChange: true,
        skip: selectedFavouriteTag === undefined || selectedFavouriteTag === '',
    })

    const {
        data: data_favourites_macros,
        isSuccess: isSuccess_favourites_macros,
    }: { data: any, isLoading: boolean, isSuccess: boolean, isError: boolean, error: any } = useGetMacrosForFavoriteTagQuery(selectedFavouriteTag, {
        refetchOnMountOrArgChange: true,
        skip: selectedFavouriteTag === undefined || selectedFavouriteTag === '',
    })

    useEffect(() => {
        if (isSuccess_ComponentTree && data_ComponentTree) {
            const componentTree_tmp = structuredClone(componentTree)
            if (currentNodeId) {
                const currentNode = searchTree({ id: '', children: componentTree_tmp }, currentNodeId);
                if (currentNode && !currentNode?.children) {
                    currentNode.children = data_ComponentTree?.map((item: any) => {
                        if (item?.showChildren !== undefined) {
                            return item;
                        } else {
                            return { ...item, showChildren: false }
                        }
                    });
                    currentNode.showChildren = true;
                    setComponentTree(componentTree_tmp);
                }
            } else {
                setComponentTree(data_ComponentTree?.map((item: any) => {
                    if (item?.showChildren !== undefined) {
                        return item;
                    } else {
                        return { ...item, showChildren: false }
                    }
                }))
            }
        }
    }, [data_ComponentTree, currentNodeId]);

    function searchTree(element: any, matchingId: string): any {
        if (element.id === matchingId) {
            return element;
        } else if (element.children !== undefined) {
            var i;
            var result = undefined;
            for (i = 0; result === undefined && i < element.children.length; i++) {
                result = searchTree(element.children[i], matchingId);
            }
            return result;
        }
        return undefined;
    }

    function Tree({ treeData }: { treeData: any }) {
        return (
            <ul>
                <>
                    {treeData?.filter((node: any) => node?.macroId !== null)?.slice().sort((a: any, b: any) => ('' + a?.name).localeCompare(b?.name))?.map((node: any) => (
                        <TreeNode node={node} key={node?.id} />
                    ))}
                </>
                <>
                    {treeData?.filter((node: any) => node?.macroId === null)?.slice().sort((a: any, b: any) => ('' + a?.name).localeCompare(b?.name))?.map((node: any) => (
                        <TreeNode node={node} key={node?.id} />
                    ))}
                </>
            </ul>
        );
    }

    function TreeNode({ node }: { node: any }) {
        const { id, name } = node;

        const handleClick = (clickedNode: any) => {
            setCurrentNodeId(id);
            if (node?.showChildren !== undefined && componentTreeRef?.current) {
                const componentTree_tmp = structuredClone(componentTreeRef?.current)
                const currentNode = searchTree({ id: '', children: componentTree_tmp }, id);
                if (currentNode && currentNode?.showChildren !== undefined) {
                    currentNode.showChildren = !currentNode?.showChildren;
                    setComponentTree(componentTree_tmp);
                }
            }

            if (clickedNode?.procedure?.procedureCode) {
                dispatch({ type: "Procedure/setSelectedProcedure", payload: clickedNode.procedure });
            } else if (clickedNode?.macro) {
                dispatch({ type: "Procedure/setSelectedMacro", payload: clickedNode?.macro });
            }
        };

        const setSelectedProcedure = async (procedure: any) => {
            dispatch(setProcedure({ procedure: procedure, orderId: props.orderId, }));
        }

        const setSelectedMacro = async (macro: macro) => {
            dispatch(setMacro({ macro: macro, orderId: props.orderId, }));
        }

        const onMouseDown = (evt: React.MouseEvent<Element>, procedure: any, fromClickEvent: boolean = false) => {
            if (evt.button === 2 || fromClickEvent) {
                if (procedure?.procedureCode && selectedFavouriteTag) {
                    if (data_favourites?.find((proc: any) => proc?.procedureCode === procedure?.procedureCode)) {
                    //    submit(["Do you want to remove '" + procedure?.procedureName + "' from favorites", "", ""],
                    //        (evt: any) => {
                                removeProcedureFavorite({ procedureCode: procedure?.procedureCode, "favoriteTag": selectedFavouriteTag })
                    //        })
                    } else {
                    //    submit(["Do you want to add '" + procedure?.procedureName + "' to favorites?", "", ""],
                    //        (evt: any) => {
                                setNewFavoriteForProcedureCode({ procedureCode: procedure?.procedureCode, "favoriteTag": selectedFavouriteTag })
                    //        })
                    }
                }
            }
        }

        const onMouseDownMacro = (evt: React.MouseEvent<Element>, macro: any, fromClickEvent: boolean = false) => {
            if (evt.button === 2 || fromClickEvent) {
                if (macro?.id && selectedFavouriteTag) {
                    if (data_favourites_macros?.find((mac: any) => mac?.id === macro?.id)) {
                //        submit(["Do you want to remove '" + macro?.name + "' from favorites", "", ""],
                //            (evt: any) => {
                                removeMacroFavorite({ macroId: macro?.id, "favoriteTag": selectedFavouriteTag })
                //            })
                    } else {
                //        submit(["Do you want to add '" + macro?.name + "' to favorites?", "", ""],
                //            (evt: any) => {
                                setNewFavoriteForMacroId({ macroId: macro?.id, "favoriteTag": selectedFavouriteTag })
                //            })
                    }
                }
            }
        }

        const isProcedureFavorite = (procedure: any): boolean => {
            return data_favourites?.find((proc: any) => proc?.procedureCode === procedure?.procedureCode) !== undefined;
        }

        const isMacroFavorite = (macro: any): boolean => {
            return data_favourites_macros?.find((mac: any) => mac.id === macro?.id) !== undefined;
        }


        return (
            <div className="componentTreeNode">
                <button className={node?.procedure?.procedureName ? "componentTreeNodeButtonLeave" : (node?.macroId ? "componentTreeNodeButtonMacro" : "componentTreeNodeButton")}
                    onClick={node?.procedure?.procedureName || node?.macro?.name ? (node?.macro ? () => setSelectedMacro(node?.macro) : () => setSelectedProcedure(node?.procedure)) : () => handleClick(node)}
                    >
                    <img className={isProcedureFavorite(node?.procedure) ||isMacroFavorite(node?.macro) ? "favourite" : "nofavourite"}
                        src={`${imagePathForCurrentTheme}${isProcedureFavorite(node?.procedure) ||isMacroFavorite(node?.macro) ? 'favorite-svgrepo-com.svg' : 'nofavorite-svgrepo-com.svg'}`}
                        onError={(event: any) => { event.target.src = `${isProcedureFavorite(node?.procedure) ||isMacroFavorite(node?.macro) ? '/images/favorite-svgrepo-com.svg' : '/images/nofavorite-svgrepo-com.svg'}`; event.onerror = null }}
                        alt="favorite-svgrepo-com.svg"
                        onClick={(e) => node?.procedure ? onMouseDown(e, node?.procedure, true) : onMouseDownMacro(e, node?.macro, true)}
                        title={isProcedureFavorite(node?.procedure) ||isMacroFavorite(node?.macro) ? 'remove form favorites' : 'add to favorites'}/>
                    <span title={id}>{name}</span>
                    {node?.procedure?.projection ?
                        <span className="projection">{node?.procedure?.projection}</span>
                        : null}
                </button>
                <ul className="componentTreeSubNodes">
                    {node?.showChildren && <Tree treeData={node?.children} />}
                </ul>
            </div>
        );
    }
    return (
        <div className="treeWrapper">
            <Tree treeData={componentTree} />
        </div>
    );
}

export default ComponentTree;
