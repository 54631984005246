/* eslint no-alert: 0 */
import csTools from 'cornerstone-tools';
import { getEnabledElement } from "cornerstone-core";
import { getFilterForColor } from "./utils";

const Cursors = csTools.importInternal('tools/cursors');
const cursor = Cursors.textMarkerCursor;
const getToolState = csTools.getToolState;
const getNewContext = csTools.import("drawing/getNewContext");
const draw = csTools.import("drawing/draw");
const drawTextBox = csTools.import("drawing/drawTextBox");
const setShadow = csTools.import("drawing/setShadow");
const external = csTools.external;
const textBoxWidth = csTools.import("drawing/textBoxWidth");
const moveHandleNearImagePoint = csTools.importInternal('manipulators/moveHandleNearImagePoint');

/**
 * @public
 * @class RestorableTextMarkerTool
 * @memberof Tools.Annotation
 * @classdesc Create and position an arrow and label
 * @extends Tools.Annotation.TextMarkerTool
 */
export default class RestorableTextMarkerTool extends csTools.TextMarkerTool {
	constructor(props = {}) {
		const defaultProps = {
			name: 'RestorableTextMarker',
			supportedInteractionTypes: ['Mouse', 'Touch'],
			configuration: {
				markers: props.configuration.markers,
				current: 'F1',
				ascending: true,
				loop: true,
				changeTextCallback: props.configuration.changeTextCallback,
				shadow: true,
			},
			svgCursor: cursor,
		};

		super(defaultProps);
		this.img = new Image();
		this.img.src = "/images/Schreiben_Tools.svg";
		this.doubleClickCallback = undefined;

		this.toolColor = 'rgba(178, 194, 0, 1)';
		this.toolSelectedColor = 'hsla(64, 87%, 79%, 1)';
		this.toolActiveColor = 'rgba(255, 255, 0, 1)';

		const elem = document.getElementById('toolsPanel');
		if (elem) {
			const selectedColor = getComputedStyle(elem)?.getPropertyValue('--toolSelectedColor');
			if (selectedColor) {
				this.toolSelectedColor = selectedColor;
			}
			const toolActiveColor = getComputedStyle(elem)?.getPropertyValue('--toolActiveColor');
			if (toolActiveColor) {
				this.toolActiveColor = toolActiveColor;
			}
		}

		this.svgFilter = getFilterForColor(this.toolColor);
        this.svgFilterActive = getFilterForColor(this.toolActiveColor);
		this.svgFilterSelected = getFilterForColor(this.toolSelectedColor)
	}

	createNewMeasurement(eventData) {
		const config = this.configuration;

		if (!config.current) {
			return;
		}

		// Create the measurement data for this tool with the end handle activated

		const measurementData = {
			visible: true,
			active: true,
			text: config.current,
			color: undefined,
			handles: {
				end: {
					x: eventData.currentPoints.image.x,
					y: eventData.currentPoints.image.y,
					highlight: true,
					active: true,
					hasBoundingBox: true,
				},
			},
		};

		// Create a rectangle representing the image
		const imageRect = {
			left: 0,
			top: 0,
			width: eventData.image.width,
			height: eventData.image.height,
		};

		// Check if the current handle is outside the image,
		// If it is, prevent the handle creation
		if (
			!external.cornerstoneMath.point.insideRect(
				measurementData.handles.end,
				imageRect
			)
		) {
			return;
		}

		// Update the current marker for the next marker
		let currentIndex = config.markers.indexOf(config.current);

		const increment = config.ascending ? 1 : -1;

		currentIndex += increment;

		if (currentIndex >= config.markers.length) {
			currentIndex = config.loop ? 0 : -1;
		} else if (currentIndex < 0) {
			currentIndex = config.loop ? config.markers.length : -1;
		}

		config.current = config.markers[currentIndex];

		return measurementData;
	}

	handleSelectedCallback(evt, toolData, handle, interactionType = 'mouse') {
		evt.stopImmediatePropagation();
		evt.stopPropagation();
		evt.preventDefault();

		const textBoxRightBorderX = toolData?.handles?.end?.boundingBox?.left + toolData?.handles?.end?.boundingBox?.width;
		const currentPointX = evt?.detail?.currentPoints?.canvas?.x;

		if (textBoxRightBorderX !== undefined && currentPointX !== undefined) {
			if (Math.abs(textBoxRightBorderX - currentPointX) < 20) {
				this.store(toolData);
				this._changeText(evt);
				if (toolData?.handles?.textBox?.active) {
					toolData.handles.textBox.active = false;
				}
			} else {
				moveHandleNearImagePoint(evt, this, toolData, handle, interactionType);
			}
		}
	}

	renderToolData(evt) {
		const eventData = evt.detail;
		const config = this.configuration;

		// If we have no toolData for this element, return immediately as there is nothing to do
		const toolData = getToolState(eventData.element, this.name);

		if (!toolData) {
			return;
		}

		// We have tool data for this element - iterate over each one and draw it
		const context = getNewContext(eventData.canvasContext.canvas);

		for (let i = 0; i < toolData.data.length; i++) {
			const data = toolData.data[i];

			if (data.visible === false) {
				continue;
			}

			const color = csTools.toolColors.getColorIfActive(data);

			draw(context, context => {
				setShadow(context, config);

				const textCoords = external.cornerstone.pixelToCanvas(
					eventData.element,
					data.handles.end
				);


				const options = {
					centering: {
						x: true,
						y: true,
					},
				};

				data.handles.end.boundingBox = drawTextBox(
					context,
					data.text,
					textCoords.x,
					textCoords.y - 10,
					color,
					options
				);

				const boundingBox = data?.handles?.end?.boundingBox;
				if (boundingBox) {
					boundingBox.width = boundingBox.width + 20;
					data.handles.end.boundingBox = boundingBox;
				}


				const padding = 0;
				const textWidth = textBoxWidth(context, data.text, padding);

				context.filter = data.active ? this.svgFilterActive : this.svgFilter;

				if (textCoords && data?.active && this.img) {
					//if (textCoords && this.img) {
					console.log(context);
					console.log(this.img);
					context.drawImage(this.img, textCoords.x + textWidth / 2 + 5, textCoords.y - 17);
				}
			});
		}
	}

	_changeText(evt) {
		const eventData = evt.detail;
		const { element, currentPoints } = eventData;
		let data;

		function doneChangingTextCallback(element, measurementData, updatedText, deleteTool) {
			if (deleteTool === true) {
				csTools.removeToolState(element, this.name, measurementData);
			} else {
				measurementData.text = updatedText;
			}

			measurementData.active = false;
			external.cornerstone.updateImage(element);
		}

		const config = this.configuration;
		const coords = currentPoints.canvas;
		const toolData = getToolState(element, this.name);

		// Now check to see if there is a handle we can move
		if (!toolData) {
			return;
		}

		for (let i = 0; i < toolData.data.length; i++) {
			data = toolData.data[i];
			if (this.pointNearTool(element, data, coords)) {
				data.active = true;
				external.cornerstone.updateImage(element);

				// Allow relabelling via a callback
				//config.changeTextCallback(data, eventData, doneChangingTextCallback);
				config.changeTextCallback(
					data,
					eventData,
					doneChangingTextCallback.bind(this, element, data)
				);

				evt.stopImmediatePropagation();
				evt.preventDefault();
				evt.stopPropagation();

				return;
			}
		}
	}

	restore(element, data) {
		var xs = data?.handles.end.x;
		var ys = data?.handles.end.y;

		let ee = getEnabledElement(element);
		let image = ee.image;
		let measurement = this.createNewMeasurement(
			{
				currentPoints: { image: { x: xs, y: ys } },
				image: { width: image.width, height: image.height },
				viewport: { rotation: undefined },
				element: element
			});

		if (measurement) measurement.text = data?.text;

		return measurement;
	}

	store(toolData) {
		toolData.storeToolData = {
			"handles": toolData.handles,
			"text": toolData.text
		}
		toolData.toolName = this.name;
	}

}
