import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
//import RetroHitCounter from 'react-retro-hit-counter';
import { apiSlice, device, deviceSet, property } from '../../apis/apiSlice';
import { AppDispatch, RootState } from '../../store';
import BatteryLevel from '../Utils/BatteryLevel';
import WifiLevel, { getValueForWifiSignal } from '../Utils/WifiLevel';
import { selectCancelCalibrationUrl, selectCurrentDeviceProperties, selectRequestManufacturerTool, selectSoftwareTriggerState, selectStartCalibrationUrl } from './AcquisitionSlice';
import { GeneratorStateMap2, getColorCode, getValueForProperty, SensorStateMap } from './Device';
import CommonDialog from "../Utils/CommonDialog";
import { useEffect, useState } from 'react';
import { useORTranslation } from '../Localization/ORLocalization';
import { getImagePathForCurrentTheme} from '../OrderList/OrdersSlice';
import './DeviceProp.scss';
import NumericInput from '../Utils/NumericInput';

interface DevicePropReadonlyProps {
	device: device | deviceSet;
	property: [string, property];
	isfirstDefaultWidget: boolean;
};

const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
const useAppDispatch = () => useDispatch<AppDispatch>();

export const getStatusText = (category: string, currentPropertyValues: [string, any][] | undefined) => {

	if (category === "DETECTOR") {
		const state: number = getValueForProperty('status', currentPropertyValues)?.status_code;
		return SensorStateMap.get(state)?.text ?? getValueForProperty('status', currentPropertyValues)?.status_string;
	} else if (category === "GENERATOR") {
		const state: string = getValueForProperty('status', currentPropertyValues);
		return GeneratorStateMap2.get(state)?.description ?? state;
	}
	else {
		return getValueForProperty('status', currentPropertyValues)?.status_string;
	}
}

const DevicePropReadonly = (props: DevicePropReadonlyProps) => {

	const dispatch = useAppDispatch();

	const { t } = useORTranslation(['Acquisition', 'common']);

	const currentPropertyValues: [string, any][] = JSON.parse(useAppSelector((state) => selectCurrentDeviceProperties(state, props.device.config.endpoint)));
	const startCalibrationUrl: string | undefined = useAppSelector((state) => selectStartCalibrationUrl(state, props.device?.config?.endpoint));
	const cancelCalibrationUrl: string | undefined = useAppSelector((state) => selectCancelCalibrationUrl(state, props.device?.config?.endpoint));
	const requestManufacturerToolUrl: string | undefined = useAppSelector((state) => selectRequestManufacturerTool(state, props.device?.config?.endpoint));
	const currentSoftwareTriggerState: string | undefined = useAppSelector((state) => selectSoftwareTriggerState(state));
	const imagePathForCurrentTheme: string = useAppSelector((state) => getImagePathForCurrentTheme(state));

	const [isCalibrationOpen, setCalibrationOpen] = useState<boolean>(false);
	const [calibrationGuiUrl, setCalibrationGuiUrl] = useState<string | undefined>(undefined);

	const [countdown, setCountdown] = useState<number>(5);

	const [secondSoftwareTriggerDelay, setSecondsSoftwareTriggerDelay] = useState<number>(10);

	const [acquisitionEXECUTED, setAcquisitionEXECUTED] = useState<boolean>(false);


	const startCalibration = (evt: React.MouseEvent<Element>, url: string) => {
		if (url) {
			setCalibrationGuiUrl(url);
			if (url !== 'mfc') {
				setCalibrationOpen(true);
			}
			const r = document.querySelector(':root');
			if (r) {
				// @ts-ignore
				r.style.setProperty('--zIndex-AquisitionOverlay', 5);
				// @ts-ignore
				r.style.setProperty('--rc-dialog-wrap-Pointer-events', 'none');
			}
			dispatch(apiSlice.endpoints.postWebThingAction.initiate({ url: startCalibrationUrl, body: { startCalibration: { input: {} } } }));
		}
	}

	const cancelCalibration = () => {
		dispatch(apiSlice.endpoints.postWebThingAction.initiate({ url: cancelCalibrationUrl, body: { cancelCalibration: { input: {} } } }));
		setCalibrationOpen(false);
		const r = document.querySelector(':root');
		if (r) {
			// @ts-ignore
			r.style.setProperty('--zIndex-AquisitionOverlay', 4);
			// @ts-ignore
			r.style.setProperty('--rc-dialog-wrap-Pointer-events', 'all');
		}
	};

	const requestManufacturerTool = (evt: React.MouseEvent<Element>, url: string) => {
		dispatch(apiSlice.endpoints.postWebThingAction.initiate({ url: requestManufacturerToolUrl, body: { requestManufacturerTool: { input: {} } } }));
	};

	function getPropsItem() {
		switch (props.property[0]) {
			case 'battery':
				return <button className="StatusLabels DeviceProp" title={`${t(props.property[1]?.description)} ${t("value", { ns: "common" })}: ${getValueForProperty('battery', currentPropertyValues)?.level} ${props.property[1]?.unit ?? ''}`}>
					<BatteryLevel colorSettingNr={1}
						batteryLevel={getValueForProperty('battery', currentPropertyValues)?.level ?? 0} />
				</button>;
			case 'connection':
				return <button className="WifiLevel DeviceProp" title={`${t(props.property[1]?.description)} ${t("value", { ns: "common" })}: ${getValueForWifiSignal(getValueForProperty('connection', currentPropertyValues)?.signal)} ${props.property[1]?.unit ?? ''}`}>
					<WifiLevel colorSettingNr={1} wifiLevel={getValueForProperty('connection', currentPropertyValues)?.signal ?? 0} />
				</button>;
			case 'status':
				return <>
					<button className="StatusLabelsState DeviceProp"
						id={getColorCode(props.device.category, getValueForProperty('status', currentPropertyValues)?.status_code).toString().toUpperCase()}
						title={`${t(props.property[1]?.description)}`}>
						<label className='DevicePropLabel'>
							{getStatusText(props.device.category, currentPropertyValues)}
						</label>
					</button>
				</>
			case 'calibrationGui':
				return <div className="calibrationGuiDiv" title={`${t(props.property[1]?.description)}`}>
					<button className="venaDefaultButton calibrationGuiButton" onClick={(ev: React.MouseEvent<Element>) => startCalibration(ev, getValueForProperty(props.property[0], currentPropertyValues))}>
						{t('startCalibration')}
					</button>
				</div>;
			case 'manufacturerTool':
				return <div className="manufacturerToolDiv" title={getValueForProperty(props.property[0], currentPropertyValues)?.description}>
					<button className="venaDefaultButton calibrationGuiButton" onClick={(ev: React.MouseEvent<Element>) => requestManufacturerTool(ev, getValueForProperty(props.property[0], currentPropertyValues))}>
						{getValueForProperty(props.property[0], currentPropertyValues)?.title}
					</button>
				</div>;
			case 'focus':
				return <button className="StatusLabelsState DeviceProp"
					id={getColorCode(props.device.category, getValueForProperty('status', currentPropertyValues)?.status_code).toString().toUpperCase()}
					title={`${t(props.property[1]?.description)}`}>
					<img className="focusButtonImg"
						src={getValueForProperty(props.property[0], currentPropertyValues) === 'small' ?
							`${imagePathForCurrentTheme}square-svgrepo-com.svg` : `${imagePathForCurrentTheme}square-svgrepo-com2.svg`}
						onError={(event: any) => {
							event.target.src = getValueForProperty(props.property[0], currentPropertyValues) === 'small' ?
								"/images/square-svgrepo-com.svg" : "/images/square-svgrepo-com2.svg"; event.onerror = null
						}}
						alt="focusButtonImg" />
				</button>;
			case 'softwareTriggerType':
				return null;
			/* case 'softwareTriggerState':
				return getExposePanel(); */
			default:
				if (props.property[1]?.type === 'integer') {
					return <button className="StatusLabelsState DeviceProp">
						<label className='DevicePropLabel' title={`${t(props.property[1]?.description)}`}>
							{`${t(props.property[1]?.title)}: ${getValueForProperty(props.property[0], currentPropertyValues) ?? t('unknown')}`}
						</label>
					</button>
				}
				if (props.property[1]?.type === 'string' || props.property[1]?.type === 'number') {
					return (
						<div className='DevicePropDefault'>
							<div className='DevicePropDefaultWrapper' title={`${t(props.property[1]?.description)}`}>
								<NumericInput title={t(props.property[1]?.title) ?? ''} units={props.property[1]?.unit?.replace('milliseconds', 'ms')}
									isLimit={0}
									startValue={getValueForProperty(props.property[0], currentPropertyValues) ?? 0} valueValidation={0}
								/>
							</div>
						</div>
					)
				} else if (props.property[1]?.type === 'boolean' && props?.device?.category !== 'SET') {
					return <div className="propertyRow" title={`${t(props.property[1]?.description)}`}>
						<div className="propertyRowInputWrapper">
							<label className="propertyRowLabel">{t(props.property[1]?.title) ?? ''}</label>
							{getValueForProperty(props.property[0], currentPropertyValues) ?
								<label className="propertyRow-Boolean_on" data-value={t("on", { ns: "common" })}>
									{t("on", { ns: "common" })}
								</label>
								:
								<label className="propertyRow-Boolean_off" data-value={t("on", { ns: "common" })}>
									{t("off", { ns: "common" })}
								</label>
							}
						</div>
					</div>
				}
		}
	}

	useEffect(() => {
		if (props.property[0] === "softwareTriggerState") {
			const softwareTriggerState: string | undefined | null = getValueForProperty(props.property[0], currentPropertyValues)?.state;

			if (softwareTriggerState !== undefined && softwareTriggerState !== null) {
				if (softwareTriggerState === 'CANCEL_REQUESTED' || softwareTriggerState === 'CANCELED') {
					dispatch({ type: 'Acquisition/setIsAcquistionOngoing', payload: false });
				}
			}

			setCountdown(getValueForProperty(props.property[0], currentPropertyValues)?.countdown ?? 0);
			setSecondsSoftwareTriggerDelay((getValueForProperty("softwareTriggerDelay", currentPropertyValues) ?? 5) / 1000);
			//setSecondsSoftwareTriggerTimeout((getValueForProperty("softwareTriggerTimeout", currentPropertyValues) ?? 5) / 1000);
		}
	}, [getValueForProperty(props.property[0], currentPropertyValues)]);

	useEffect(() => {
		if (currentSoftwareTriggerState === 'EXECUTED') {
			setAcquisitionEXECUTED(true)
		}
	}, [currentSoftwareTriggerState]);

	return (
		<>
			{props.isfirstDefaultWidget ? <hr className="hrule3" /> : null}

			<div className="vSpace" />

			{getPropsItem()}

			<CommonDialog onClose={cancelCalibration} title={'Calibration'} visible={isCalibrationOpen} okText="" cancelText={t('cancel', { ns: 'common' })}
				default={{ x: 0, y: 0, width: window.innerWidth - 500, height: window.innerHeight - 150 }} childProps={{}}>
				<>
					<iframe className='calibrationIFrame' src={calibrationGuiUrl}
						title="Calibration"></iframe>
				</>
			</CommonDialog>
		</>
	);
}

export default DevicePropReadonly;
