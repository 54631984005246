import React, { useEffect, useRef, useState } from "react";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import Overview from "./Tools/Overview";
import { selectToolsPanel, selectedWorkitem } from "./ImageDisplaySlice";
import { useORTranslation } from "../Localization/ORLocalization";
import Tools from "./Tools/Tools";
import './ToolsPanel.scss';
import { getLayout, getSelectedWorkitemMimeType } from "../OrderList/OrdersSlice";
import { selectProcedureSelectionVisibilityState } from "../Procedures/ProcedureSlice";


interface ToolsPanelProps {
    orderId: string;
}

const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
const useAppDispatch = () => useDispatch<AppDispatch>();

const ToolsPanel = (props: ToolsPanelProps) => {
    const dispatch = useAppDispatch();

    const rootDivElement = useRef<HTMLDivElement>(null);

    const { t } = useORTranslation(['Tools']);



    const [toolButtonsVisible, setToolButtonsVisible] = useState<boolean>(true);

    const isToolsPanel: boolean = useAppSelector((state) => selectToolsPanel(state));
    const overviewVisible: boolean = useAppSelector((state) => state.Overview.isOverviewVisible);
    //const currentWorkitem: workitem = useAppSelector((state) => getSelectedWorkitem(state));
    const currentWorkitemId: string = useAppSelector((state) => selectedWorkitem(state));
    const currentWorkitemMimeType: string = useAppSelector((state) => getSelectedWorkitemMimeType(state));
    const layout: any = useAppSelector((state) => getLayout(state));
    const procedureSelectionVisibilityState: number = useAppSelector((state) => selectProcedureSelectionVisibilityState(state));

    const onToolsHideButtonClicked = (evt: React.MouseEvent<HTMLButtonElement>) => {
        setToolButtonsVisible(!toolButtonsVisible);
    };

    useEffect(() => {
        let parentElement = rootDivElement.current?.parentElement;
        if ((currentWorkitemMimeType === 'application/pdf' || procedureSelectionVisibilityState > 0) && !isToolsPanel) {
            parentElement?.setAttribute("key", "Tools_key_invisible");
        } else {
            if (isToolsPanel) {
                parentElement?.setAttribute("key", "Tools_key_Panel");
            } else {
                parentElement?.setAttribute("key", "Tools_key");
            }
        }
    }, [isToolsPanel, currentWorkitemId, currentWorkitemMimeType, procedureSelectionVisibilityState]);

    useEffect(() => {
        if (layout?.display_tool_in_navbar) {
            dispatch({ type: "ImageDisplay/setToolsPanel", payload: false });
        }
    }, [JSON.stringify(layout)]);

    return (
        <div ref={rootDivElement} className="tools">
            <div className={procedureSelectionVisibilityState <= 0 ? "toolsPanel" : "toolsPanel_invisible"} id="toolsPanel">
                {isToolsPanel ? null :
                    <button className="tools_hide_button" onClick={(evt) => onToolsHideButtonClicked(evt)}>
                        <img
                            src={toolButtonsVisible ? "/images/Pfeil_nach_oben.svg" : "/images/Pfeil_nach_unten.svg"}
                            alt={"Pfeil nach oben.svg"}
                            className="tools_hide_button_img"
                        />
                        {toolButtonsVisible ? t('tools') as string : t('tools') as string}
                    </button>
                }
                {!layout?.display_tool_in_navbar && !layout?.display_tools_in_floating_Panel ?
                    <div className={toolButtonsVisible ? (overviewVisible ? "toolsButtonPanel_show" : "toolsButtonPanel_show_Overview_hidden") : "toolsButtonPanel_hide"}>
                        <Tools orderId={props.orderId ?? ''} />
                    </div>
                    : null}
                <Overview orderId={props.orderId} />
            </div>
        </div>
    );
}

export default ToolsPanel;
