import csTools from 'cornerstone-tools';
import cornerstone from "cornerstone-core";

const BaseTool = csTools.importInternal('base/BaseTool');


/**
 * @public
 * @class CloudUploadTool
 * @memberof Tools
 *
 * @classdesc Tool for uploading the current image to cloud
 * @extends Tools.Base.BaseTool
 */
export default class CloudUploadTool extends BaseTool {
	/** @inheritdoc */
	constructor(props = {}) {
		const defaultProps = {
			name: 'CloudUpload',
		};
		super(props, defaultProps);
	}

	activeCallback(element) {
		let connection = "";
		if (this.options.connection !== undefined)
			connection = this.options.connection;
		let mapping = "default";
		if (this.options.mapping !== undefined)
			mapping = this.options.mapping;
		const eventData = {
			toolName: this.name,
			toolType: this.name,
			element: element,
			measurementData: undefined,
			newIcon: "/images/Tools/cloud-progress.svg",
			connection: connection,
			mapping: mapping
		};
		cornerstone.triggerEvent(element, csTools.EVENTS.MEASUREMENT_COMPLETED, eventData);
	}
}
