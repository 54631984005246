import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { AppDispatch, RootState } from '../../store';
import { setAcquisitionActive } from '../Akquisition/AcquisitionSlice';
import {selectCurrentUser } from '../Auth/authSlice';
import { getLayout, selectExpiresWarning } from '../OrderList/OrdersSlice';
import './NavBar.scss';
import User from './User';


const useAppDispatch = () => useDispatch<AppDispatch>();
const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

const MainNavbar = () => {
	const location = useLocation();
	const navigate = useNavigate()
	const dispatch = useAppDispatch();

	const currentUser = useSelector(selectCurrentUser);
	const expiresWarning = useSelector(selectExpiresWarning);

	const layout: any= useAppSelector((state) => getLayout(state));

	/* const handleClick = async (e: React.MouseEvent) => {
		const newStudyId: any = await dispatch(setAcquisitionActive({ setAcquistionButtonActive: true, studyId: undefined, workitemId: undefined }));
		if (newStudyId?.payload?.studyId) {
			navigate(`/orders/${newStudyId?.payload?.studyId}/${location?.search ?? ''}`);
		}
	} */

	const handleClickLogo = (e: React.MouseEvent) => {
		dispatch(setAcquisitionActive({ setAcquistionButtonActive: false, studyId: undefined, workitemId: undefined }));
		dispatch({ type: 'ImageDisplay/setSelectedWorkitem', payload: "" });
		dispatch({ type: "Procedure/setSelectedComponentSubtree", payload: ['human', 'Adult'] });
		dispatch({ type: "Procedure/setProcedureSelectionVisibilityState", payload: 0 });

		const r = document.querySelector(':root');
		// @ts-ignore
		r?.style.setProperty('--img_desc_left_margin', `${0}px`);

		navigate(`/${location?.search ?? ''}`);
	}

	return (
		<nav className={location.pathname === '/' ? "navbar_main_page" : "navbar"}>
			{/* <Link to="/" className=""> */}
			{/* <NavItem icon="/images/Logo.svg"/> */}
			{/* </Link> */}
			<div className="nav-item">
				<img src={layout?.logoPath} alt="Logo.svg" onClick={handleClickLogo} className="nav-item-logo" />
			</div>
			{/* <div className={isMainOrderListOpen ? "nav-shortcut-items_invisible" : "nav-shortcut-items"}>
				<button>
					<img src="/images/Neuer_Auftrag.svg" alt="Neuer Auftrag" onClick={handleClick} />
				</button>
				<button>
					<img src="/images/Button_Liste2.svg" alt="Button Liste2" onClick={handleClickLogo} />
				</button>
			</div> */}
			<div className="navBarRight">
				<div className={currentUser ? "expiresWarning-visible" : "expiresWarning-invisible"}>
					{expiresWarning}
				</div>
				<User/>
			</div>
		</nav>
	);
}

export default MainNavbar;
