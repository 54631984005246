import React, { useEffect, useState } from "react";
import { useGetJobStateMutation, usePreviewExportMutation, useFinalizeArtifactMutation } from '../../apis/apiSlice';
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import "./FinalizeStudyDialog.scss";
import { selectFinalizeStudyDialogVisible } from "../ImageDisplay/ImageDisplaySlice";
import { getTargetExposureIndexForSelectedWorkitem, getLateralityForSelectedWorkitem } from "../OrderList/OrdersSlice";
import AnnotationData from "../OrderList/AnnotationData"

import { serviceEndpoints } from "../..";
import { useORTranslation } from "../Localization/ORLocalization";
import { Constants } from '../../Constants';


type FinalizePreviewImageProps = {
	artifactId: string,
	orderId?: string | undefined,
	showPreview: boolean,
	doFinalize: boolean,
	displayImage: boolean,
	annotationDataMap: any
};

const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
const useAppDispatch = () => useDispatch<AppDispatch>();

const FinalizePreviewImage = (props: FinalizePreviewImageProps) => {

	const { t } = useORTranslation(['FinalizeStudyDialog', 'common']);
	const dispatch = useAppDispatch();
	const isFinalizeStudyDialogVisible: number = useAppSelector((state) => selectFinalizeStudyDialogVisible(state));

	const [previewImg, setPreviewImg] = useState<string>("");
	const [previewJobId, setPreviewJobId] = useState<string>("");
	const [finalizeJobId, setFinalizeJobId] = useState<string>("");
	const [finishedPreviewJobId, setFinishedPreviewJobId] = useState<string>("");
	const [finishedExportJobId, setFinishedExportJobId] = useState<string>("");
	const [PreviewExport] = usePreviewExportMutation();
	const [FinalizeArtifact] = useFinalizeArtifactMutation();
	const [GetJobState] = useGetJobStateMutation();

	const targetExposureIndex: number | undefined = useAppSelector((state) => getTargetExposureIndexForSelectedWorkitem(state));
	const workitemLaterality: string | undefined = useAppSelector((state) => getLateralityForSelectedWorkitem(state));


	useEffect(() => {
		async function getJobState() {
			let resp = await GetJobState(previewJobId);
			if (resp && resp.data) {
				let removeJob = false;
				if (resp.data.status === "Done") {
					console.log("Preview job " + previewJobId + " is done.");
					setPreviewImg(`${serviceEndpoints.RAW_PROCESSOR_URL}/results/${previewJobId}/icon`);
					setFinishedPreviewJobId(previewJobId);
					removeJob = true;
				} else {
					if (resp.data.status === "Failed") {
						console.log("Preview job " + previewJobId + " failed.");
						removeJob = true;
					}
				}

				if (removeJob) {
					setPreviewJobId("");
				}
			}
			return;
		}
		if (props.showPreview) {

			const interval = setInterval(() => {
				if (previewJobId !== "") {
					getJobState();
				}
			}, 500);

			return () => clearInterval(interval);
		} else {
			return;
		}
	}, [previewJobId]);

	useEffect(() => {
		async function getJobState() {
			let resp = await GetJobState(finalizeJobId);
			if (resp && resp.data) {
				let removeJob = false;
				if (resp.data.status === "Done" && resp.data.artifactid && resp.data.artifactid !== "") {
					console.log("Finalize job " + finalizeJobId + " is done.");
					setFinishedExportJobId(finalizeJobId);
					removeJob = true;
				} else {
					if (resp.data.status === "Failed") {
						console.log("Finalize job " + finalizeJobId + " failed.");
						removeJob = true;
					}
				}

				if (removeJob) {
					props.annotationDataMap[props.artifactId].finalizeJobActive = false;
					setFinalizeJobId("");
					dispatch({ type: "ImageDisplay/decrFinalizeCounter", payload: true });
				}
			}
			return;
		}
		if (props.doFinalize) {
			const interval = setInterval(() => {
				if (finalizeJobId !== "") {
					getJobState();
				}
			}, 500);

			return () => clearInterval(interval);
		} else {
			return;
		}
	}, [finalizeJobId]);

	useEffect(() => {

		const firePreviewJob = async (args: any) => {
			const resp = await PreviewExport(args);
			if (resp && resp.data !== undefined) {
				setPreviewJobId(resp.data);
				setFinishedPreviewJobId("");
				console.log("Started Preview job " + resp.data + ".");
				window.URL.revokeObjectURL(`${serviceEndpoints.RAW_PROCESSOR_URL}/results/${resp.data}/icon`);				
			} else {
				console.log("Failed to start Preview job.");
			}
		}

		const fireFinalizeJob = async (args: any) => {
			const resp = await FinalizeArtifact(args);
			if (resp && resp.data !== undefined) {
				props.annotationDataMap[props.artifactId].finalizeJobActive = true;
				setFinalizeJobId(resp.data);				
				console.log("Started Finalize job " + resp.data + ".");
			} else {
				console.log("Failed to start Finalize job.");
			}
		}
		
		if (isFinalizeStudyDialogVisible > 0 && props.orderId && props.artifactId !== "" && (props.showPreview || props.doFinalize)) {

			// create rendering job in the raw processor
			
			// get ids from annodatamap
			let instanceId = undefined;
			let seriesId = undefined;
			let workitemId = undefined;
			if (props.annotationDataMap[props.artifactId]) {
				let entry = props.annotationDataMap[props.artifactId];
				instanceId = entry.instanceId;
				seriesId = entry.seriesId;
				workitemId = entry.workitemId;
				if (entry.artifactState === Constants.POSTPROCESSING_STEP_TYPE)
					return;
			}
			// get annotations from annodatamap
			let lrMarkerState: any = undefined;
			let bmState: any = undefined;
			let annos = undefined;
			let proiState: any = undefined;
			let flippedAnnoState: any = undefined;
			
			if (props.annotationDataMap[props.artifactId])
				annos = props.annotationDataMap[props.artifactId].currentData;

			if (annos) {
				for (let i = 0; i < annos.length; i++) {
					if (annos[i].body &&
						annos[i].body.format === 'application/cornerstonetoolv1' &&
						annos[i].body.type) {
						let toolName = annos[i].body.type;

						if (toolName === "LRMarker")
							lrMarkerState = annos[i].body.value;
						if (toolName === "BlackMask")
							bmState = annos[i].body.value;							
						if (toolName === "ProcessingROI")
							proiState = annos[i].body.value;
						if (toolName === "FlippedAnnotation")
							flippedAnnoState = annos[i].body.value;
					}
				}			
			}

			// get viewport state from local storage
			let flip = "none";
			let borders = [0, 0, 0, 0];
			let window = { width: -1, center: 50, name: "default" };
			let rotation = 0.0;
			let width = 0, height = 0, cut = false;
			let storedValue = localStorage.getItem(`${serviceEndpoints.ARTIFACT_STORE_URL}/artifacts/${props.artifactId}/raw`);
			if (storedValue) {
				let jsvalue: any = JSON.parse(storedValue);
				if (jsvalue) {
					if (jsvalue.flip)
						flip = jsvalue.flip;

					if (jsvalue.rotation)
						rotation = jsvalue.rotation;

					if (flip === "both") {
						flip = "none";
						rotation += 180.0;
					}

					if (jsvalue.ww)
						window.width = jsvalue.ww;

					if (jsvalue.wc)
						window.center = jsvalue.wc;

					if (jsvalue.cl) {
						borders[0] = jsvalue.cl;
						if (borders[0] > 0)
							cut = true;
					}
					if (jsvalue.cr) {
						borders[1] = jsvalue.cr;
						if (borders[1] > 0)
							cut = true;
					}
					if (jsvalue.ct) {
						borders[2] = jsvalue.ct;
						if (borders[2] > 0)
							cut = true;
					}
					if (jsvalue.cb) {
						borders[3] = jsvalue.cb;
						if (borders[3] > 0)
							cut = true;
					}
					if (jsvalue.width)
						width = jsvalue.width;
					if (jsvalue.height)
						height = jsvalue.height;
				}
			} // if have stored vp
			
			let bm = undefined;
			let dk = 0;

			if (bmState) {
				let points = bmState.points;
				let j = 0;
				bm = [];
				for (let i = 0; i < 4; i++) {
					bm[j] = points[i].x;
					j++;
					bm[j] = points[i].y;
					j++;
				}
				if (bmState.darkening)
					dk = bmState.darkening;

				if (cut) {
					console.log("cut detected");
					let minx = width, miny = height, maxx = 0, maxy = 0;
					for (let i = 0; i < 4; i++) {
						if (points[i].x < minx)
							minx = points[i].x;
						if (points[i].x > maxx)
							maxx = points[i].x;
						if (points[i].y < miny)
							miny = points[i].y;
						if (points[i].y > maxy)
							maxy = points[i].y;
					}
					borders[0] = minx;
					borders[1] = width - maxx;
					borders[2] = miny;
					borders[3] = height - maxy;
				}
			} // if have bm

			let setLaterality = "none";
			if (workitemLaterality !== undefined) {
				setLaterality = workitemLaterality;
			}

			let svgs = [];
			let svg = "";
			let svgx = 0, svgy = 0;
			if (lrMarkerState) {
				 if(lrMarkerState.setLaterality !== undefined)
                        setLaterality = lrMarkerState.setLaterality;
				
				let path = lrMarkerState.svg.slice(0, -2);

				let black = path + " fill=\"white\" stroke=\"black\" stroke-width=\"2px\" />";

				//let white = path + " fill=\"white\" transform=\"translate(-5,-5)\" />";
				let white = path + " fill=\"white\" />";

				//console.log(black);
				//console.log(white);

				svg = "<svg viewBox=\"" + 0 + " " + (-lrMarkerState.size) + " " + lrMarkerState.size + " " + lrMarkerState.size + "\" >" + black + "</svg>";
				//console.log("sending svg:" + svg);
				svgx = lrMarkerState.handle.x;
				svgy = lrMarkerState.handle.y;
				
				//console.log("borders[0]:" + borders[0]);
				if (borders[0] !== 0)
					svgx -= borders[0];

				//console.log("borders[2]:" + borders[2]);
				if (borders[2] !== 0)
					svgy -= borders[2];

				if (flip === "horizontal") {
					//console.log("fliph");
					svgx = (width-borders[0]-borders[1]) - svgx;
				}
				if (flip === "vertical") {
					//console.log("flipv");
					svgy = (height-borders[2]-borders[3]) - svgy;
				}
				if (rotation !== 0.0) {
					//console.log("rotation");
					const rotationRad = rotation * Math.PI / 180.0;
					let ox = (width - borders[0] - borders[1]) / 2;
					let oy = (height - borders[2] - borders[3]) / 2;					
					svgx -= ox;
					svgy -= oy
					let ttx = (svgx * Math.cos(rotationRad) - svgy * Math.sin(rotationRad));
					let tty = (svgy * Math.cos(rotationRad) + svgx * Math.sin(rotationRad));
					svgx = ttx + ox;
					svgy = tty + oy;
				}
				svgy -= lrMarkerState.size;
				
				svgs.push({svg,svgx,svgy});
			} // if have overlay

			if (flippedAnnoState) {
				let path = flippedAnnoState.svg.slice(0, -2);

				let black = path + " fill=\"white\" stroke=\"black\" stroke-width=\"2px\" />";

				//let white = path + " fill=\"white\" transform=\"translate(-5,-5)\" />";
				let white = path + " fill=\"white\" />";

				//console.log(black);
				//console.log(white);

				let size = flippedAnnoState.text.length * flippedAnnoState.size;
				svg = "<svg viewBox=\"" + 0 + " " + (-flippedAnnoState.size) + " " + size + " " + flippedAnnoState.size + "\" >" + black + "</svg>";
				//console.log("sending svg:" + svg);
				svgx = flippedAnnoState.handle.x;
				svgy = flippedAnnoState.handle.y;

				//console.log("borders[0]:" + borders[0]);
				if (borders[0] !== 0)
					svgx -= borders[0];

				//console.log("borders[2]:" + borders[2]);
				if (borders[2] !== 0)
					svgy -= borders[2];

				if (flip === "horizontal") {
					//console.log("fliph");
					svgx = (width - borders[0] - borders[1]) - svgx;
				}
				if (flip === "vertical") {
					//console.log("flipv");
					svgy = (height - borders[2] - borders[3]) - svgy;
				}
				if (rotation !== 0.0) {
					//console.log("rotation");
					const rotationRad = rotation * Math.PI / 180.0;
					let ox = (width - borders[0] - borders[1]) / 2;
					let oy = (height - borders[2] - borders[3]) / 2;
					svgx -= ox;
					svgy -= oy
					let ttx = (svgx * Math.cos(rotationRad) - svgy * Math.sin(rotationRad));
					let tty = (svgy * Math.cos(rotationRad) + svgx * Math.sin(rotationRad));
					svgx = ttx + ox;
					svgy = tty + oy;
				}
				svgy -= flippedAnnoState.size;

				svgs.push({ svg, svgx, svgy });
			} // have flipped anno


			let di = undefined, ei = undefined, tei = targetExposureIndex;
			
			if (proiState?.data) {
				const data = proiState?.data[0];
				ei = data.exposureindex;
			}
			if (ei !== undefined && tei !== undefined)
				di = Math.round(1000.0 * Math.log10(ei / tei)) / 100;					
					
			if (!props.doFinalize) {
				if (previewJobId === "" && previewImg === "") {
					firePreviewJob({
						artifactId: `${serviceEndpoints.ARTIFACT_STORE_URL}/artifacts/${props.artifactId}`,
						rotate: rotation, flip: flip, cut: borders, blackmask: bm, darkening: dk, window: window, svgs: svgs,
						ei: ei !== undefined ? "" + Math.round(ei) : "",
						tei: tei !== undefined ? "" + Math.round(tei) : "",
						di: di !== undefined ? "" + Math.round(di) : "",
						setLaterality: setLaterality
					});
				}
			} else {
				if (finalizeJobId === "" && finishedExportJobId === "") {
					dispatch({ type: "ImageDisplay/setImageProcessing", payload: true });
					dispatch({ type: "ImageDisplay/incrFinalizeCounter", payload: true });
					fireFinalizeJob({
						artifactId: `${serviceEndpoints.ARTIFACT_STORE_URL}/artifacts/${props.artifactId}`,
						previewId: finishedPreviewJobId,
						instanceId: instanceId,
						seriesId: seriesId,
						workitemId: workitemId,
						rotate: rotation, flip: flip, cut: borders, blackmask: bm, darkening: dk, window: window, svgs: svgs,
						ei: ei !== undefined ? "" + Math.round(ei) : "",
						tei: tei !== undefined ? "" + Math.round(tei) : "",
						di: di !== undefined ? "" + Math.round(di) : "",
						setLaterality: setLaterality
					});
				}
			} //  if export or preview
		}

	}, [isFinalizeStudyDialogVisible, props.showPreview, props.doFinalize, props.orderId]);


	const getSpinnerOverlayClass = (): string => {
		let cssclass: string = "previewImage-list-svg-off";
		if (previewJobId !== "" || finalizeJobId !== "") {
			cssclass = "previewImage-list-svg-on";
		}
		return cssclass;
	}

	const getCheckOverlayClass = (): string => {
		let cssclass: string = "previewImage-list-svg-off";
		if (props.annotationDataMap[props.artifactId]) {
			let entry = props.annotationDataMap[props.artifactId];
			if (entry.artifactState === Constants.POSTPROCESSING_STEP_TYPE) {
				cssclass = "previewImage-list-svg-on";
			}
		}
		return cssclass;
	}	

	const getImageSrc = (): string => {
		if (previewImg && props.showPreview)
			return previewImg;
		else
			if (props.artifactId && props.artifactId.length > 0)
				return `${serviceEndpoints.ARTIFACT_STORE_URL}/artifacts/${props.artifactId}/icon-as-image`;

		return "";
	}
		
	return (<React.Fragment /*key={props.artifactId + "_"}*/><div className="previewImage-list-rel">
	{props.displayImage?
		<><img src={getImageSrc()} alt=""	className="previewImage-list-img" />
		<img src="/images/overlay-spinner.svg" className={getSpinnerOverlayClass()} alt="" />
		<img src="/images/overlay-check.svg" className={getCheckOverlayClass()} alt="" /></>:null}
		<AnnotationData targetUri={`${serviceEndpoints.ARTIFACT_STORE_URL}/artifacts/${props.artifactId}/raw`} targetId={props.artifactId} annodata={props.annotationDataMap} />
	</div></React.Fragment>);
};

export default FinalizePreviewImage;
