import React from "react";
import { TypedUseSelectorHook, useSelector } from "react-redux";
import { RootState } from "../../store";
import { selectAllowComplete, selectTheme } from "../OrderList/MainOrderListSlice";
import './ItemActionButtonGroup.scss';
import { getThemes } from "../OrderList/OrdersSlice";
import { getSelectedWorkitem } from "../OrderList/OrdersSlice";
import { getHideActionButtonCloseWorkitem } from "../ImageDisplay/ImageDisplaySlice";

export type ItemActionButtonGroupCallbacks = {
    downloadButtonClick?: (e: React.MouseEvent | React.TouchEvent) => void,
    editButtonClick?: (e: React.MouseEvent | React.TouchEvent) => void,
    finalizeStudyButtonClick?: (e: React.MouseEvent | React.TouchEvent) => void,
    closeButtonClick?: (e: React.MouseEvent | React.TouchEvent) => void,
    cancelButtonClick?: (e: React.MouseEvent | React.TouchEvent) => void,
    resumeButtonClick?: (e: React.MouseEvent | React.TouchEvent) => void,
    recoverButtonClick?: (e: React.MouseEvent | React.TouchEvent) => void,
};

interface ItemActionButtonProps {
    callback?: (e: React.MouseEvent | React.TouchEvent) => void;
    icon: string;
    title: string | undefined;
    theme: string;
    themes: string[];
    id?: string;
}

interface ItemActionButtonGroupProps {
    callbacks: ItemActionButtonGroupCallbacks;
    titles?: {
        downloadButtonClick: string | undefined, editButtonClick: string | undefined,
        closeButtonClick: string | undefined, cancelButtonClick: string | undefined,
        finalizeStudyButtonClick: string | undefined, resumeButtonClick?: string | undefined,
        recoverButtonClick?: string | undefined
    };
    icons?: { editButtonIcon?: string, downloadButton?: string, cancelButton?: string, closeButton?: string, finalizeStudyButtonIcon?: string, resumeButtonIcon?: string };
    ignoreFirstVLine?: boolean;
}

function ActionButton(props: ItemActionButtonProps) {
    if (props.callback) {
        let path = '/images/';
        if (props.themes?.length > 0) {
            path = `/Themes/${props.theme}/images`;
        }
        return (
            <span className="actionButtonWrapper" id={props.id}>
                <button className="actionButton" onMouseDown={props.callback} title={props.title}>
                    <img className="actionButtonImg"
                        src={`${path}/${props.icon}`}
                        onError={(event: any) => { event.target.src = `/images/${props.icon}`; event.onerror = null }}
                        alt={props.icon} />
                </button>
            </span>
        );
    } else {
        return null;
    }
}

const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

const ItemActionButtonGroup = (props: ItemActionButtonGroupProps) => {


    const allowComplete: boolean = useAppSelector((state) => selectAllowComplete(state));

    const currentWorkitemAsString: string | undefined = useAppSelector((state) => getSelectedWorkitem(state));
    const currentWorkitem = currentWorkitemAsString ? JSON.parse(currentWorkitemAsString) : undefined;

    const currentTheme: string = useAppSelector((state) => selectTheme(state));
    const hideActionButtonCloseWorkitem: boolean = useAppSelector((state) => getHideActionButtonCloseWorkitem(state));
    const themes: string[] = useAppSelector((state) => getThemes(state));

    return (
        <div className="buttons">
            {allowComplete && currentWorkitem?.state !== "COMPLETED" ?
                <ActionButton theme={currentTheme} themes={themes} callback={props.callbacks.editButtonClick} title={props.titles?.editButtonClick} icon={props.icons?.editButtonIcon ? props.icons?.editButtonIcon : "Schreiben.svg"} />
                : null}
            <ActionButton theme={currentTheme} themes={themes} callback={props.callbacks.finalizeStudyButtonClick} title={props.titles?.finalizeStudyButtonClick} icon={props.icons?.finalizeStudyButtonIcon ? props.icons?.finalizeStudyButtonIcon : "check.svg"} />
            <ActionButton theme={currentTheme} themes={themes} callback={props.callbacks.downloadButtonClick} title={props.titles?.downloadButtonClick} icon={props.icons?.downloadButton ?? "file-download-svgrepo-com.svg"} />
            {allowComplete && currentWorkitem?.state !== "COMPLETED"  ?
                <ActionButton theme={currentTheme} themes={themes} callback={props.callbacks.cancelButtonClick} title={props.titles?.cancelButtonClick} icon={props.icons?.closeButton ?? "garbage-basket-svgrepo-com.svg"} />
                : null}
            {allowComplete && currentWorkitem?.state !== "COMPLETED" && !hideActionButtonCloseWorkitem ?
                <ActionButton theme={currentTheme} themes={themes} callback={props.callbacks.closeButtonClick} title={props.titles?.closeButtonClick} icon="pane-close-svgrepo-com.svg" />
                : null}
            {allowComplete && currentWorkitem?.state === "COMPLETED" ?
                <ActionButton theme={currentTheme} themes={themes} callback={props.callbacks.resumeButtonClick} title={props.titles?.resumeButtonClick} icon="openlock.svg" />
                : null}
            {props.callbacks.recoverButtonClick && currentWorkitem?.state !== "COMPLETED" ?
                <ActionButton id="bottom" theme={currentTheme} themes={themes} callback={props.callbacks.recoverButtonClick} title={props.titles?.resumeButtonClick} icon="reset.svg" />
                : null}
            {/* <span className="vl2" /> */}
        </div>
    );

}

export default ItemActionButtonGroup;
