import React, { useEffect, useState, useRef } from "react";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { Rnd } from "react-rnd";

import { AppDispatch, RootState } from "../../store";

import cornerstone, { EnabledElement } from "cornerstone-core";
import { Constants } from "../../Constants";
import { getFalseColorsActive, getToolInsetActive, selectMatrixColumns, selectMatrixRows, selectToolInsetVisible } from "./ImageDisplaySlice";
import Tooltabs, { toolTabsJson } from "./Tooltabs";
import "./TooltabsfloatPanel.scss";
import { selectAcquistionButtonActive } from "../Akquisition/AcquisitionSlice";
import { getImagePathForCurrentTheme } from "../OrderList/OrdersSlice";

type TooltabsfloatPanelProps = {

    orderId: string;
    matrixIndex: number;
};

//let resizing: boolean = false;

const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
const useAppDispatch = () => useDispatch<AppDispatch>();

const TooltabsfloatPanel = (props: React.PropsWithChildren<TooltabsfloatPanelProps>) => {
    const dispatch = useAppDispatch();

    const [panelSize, setPanelSize] = useState<{ width: string, height: string }>({ width: '30%', height: '48%' });

    const isFalseColorsActive: boolean | undefined = useAppSelector((state) => getFalseColorsActive(state, props.matrixIndex ?? 0));
    const isAcquisitionButtonActive: boolean = useAppSelector((state) => selectAcquistionButtonActive(state)) ?? false;
    const isToolInsetActive: boolean | undefined = useAppSelector((state) => getToolInsetActive(state, props.matrixIndex ?? 0));
    const isToolInsetVisible: boolean | undefined = useAppSelector((state) => selectToolInsetVisible(state));
    const currentMatrixColumns: number | undefined = useAppSelector(state => selectMatrixColumns(state));
    const currentMatrixRows: number | undefined = useAppSelector(state => selectMatrixRows(state));
    const imagePathForCurrentTheme: string = useAppSelector((state) => getImagePathForCurrentTheme(state));

    const RndRef = useRef<any>();

    const handelClose = (evt: any) => {
        evt?.stopPropagation();
        dispatch({ type: "ImageDisplay/setWindowLevelHistActive", payload: { matrixIndex: props.matrixIndex, isActive: false } });
        dispatch({ type: "ImageDisplay/setPipeWallThicknessChartActive", payload: { matrixIndex: props.matrixIndex, isActive: false } });
        dispatch({ type: "ImageDisplay/setLineProfileChartActive", payload: { matrixIndex: props.matrixIndex, isActive: false } });
        dispatch({ type: "ImageDisplay/setBlackMaskActive", payload: { matrixIndex: props.matrixIndex, isActive: false } });
        dispatch({ type: "ImageDisplay/setGrayscaleLineProfileChartActive", payload: { matrixIndex: props.matrixIndex, isActive: false } });
        dispatch({ type: "ImageDisplay/setResolutionMeasurementChartActive", payload: { matrixIndex: props.matrixIndex, isActive: false } });
        dispatch({ type: "ImageDisplay/setLengthCalibrationChartActive", payload: { matrixIndex: props.matrixIndex, isActive: false } });
        if (isFalseColorsActive) {
            const cornerstoneElements: EnabledElement[] = cornerstone.getEnabledElements();
            const displayElement: EnabledElement | undefined = cornerstoneElements.find(
                (element: EnabledElement) => element.element.id === `${Constants.IMAGE_DISPLAY_GENERIC_ELEMENT_NAME}_${props.matrixIndex}`
            );

            if (displayElement && displayElement.image) {
                displayElement.needsRedraw = true;
                displayElement.invalid = true;
            }
            dispatch({ type: "ImageDisplay/setFalseColorsActive", payload: { matrixIndex: props.matrixIndex, isActive: false } });
        }

        dispatch({ type: "ImageDisplay/setToolTabsModel", payload: { matrixIndex: props.matrixIndex, model: toolTabsJson } });
    };

    useEffect(() => {
        if (isAcquisitionButtonActive) {
            handelClose(undefined);
        }
    }, [isAcquisitionButtonActive]);

    useEffect(() => {
        // increase width of tools chart panel in matrix display if the are more than one column
        if (currentMatrixColumns !== undefined && (!isToolInsetVisible && isToolInsetActive)) {
            const parentWidth = RndRef?.current?.resizable?.parentNode?.clientWidth;
            const parentHeight = RndRef?.current?.resizable?.parentNode?.clientHeight;
            if (parentWidth !== undefined && parentHeight !== undefined) {
                if (currentMatrixColumns > 1) {
                    RndRef?.current?.resizable?.updateSize({
                        width: parentWidth * parseFloat(panelSize.width) * 1.7 / 100.0,
                        height: parentHeight * parseFloat(panelSize.height) * 1.2 / 100.0
                    });

                } else {
                    RndRef?.current?.resizable?.updateSize({
                        width: parentWidth * parseFloat(panelSize.width) / 100.0,
                        height: parentHeight * parseFloat(panelSize.height) / 100.0
                    });
                }
            }
        }
    }, [currentMatrixColumns, currentMatrixRows, panelSize.height, panelSize.width, isToolInsetVisible]);

    return (
        <Rnd ref={RndRef}
            bounds="parent"
            className={!isToolInsetVisible && isToolInsetActive ? "toolTabsFloatPanel_visible" : "toolTabsFloatPanel_invisible"}
            default={{
                x: 5,
                y: 85,
                width: panelSize.width,
                height: panelSize.height,
            }}
            disableDragging={false}
            enableResizing={true}
            dragHandleClassName="panel-header-drag-area"
            onDrag={(evt) => {
                evt.stopPropagation();
            }}
            onDragStart={(evt) => {
                evt.stopPropagation();
            }}
            onDragStop={(evt: any, d: any) => {
                evt.stopPropagation();
            }}

        >
            <div className="panel-header">
                <div className="panel-header-drag-area">
                </div>
                <div className="close">
                    <button className="close-image-button" onClick={(evt) => handelClose(evt)}>
                        <img className="close-image"
                        src={`${imagePathForCurrentTheme}Schliessen_Kreuz.svg`}
                        onError={(event: any) => {event.target.src="/images/Schliessen_Kreuz.svg"; event.onerror = null}}
                        alt="close" />
                    </button>
                </div>
            </div>
            <Tooltabs orderId={props.orderId} matrixIndex={props.matrixIndex} />
        </Rnd>
    );
}

export default TooltabsfloatPanel;
