import cornerstone, { EnabledElement } from "cornerstone-core";
import React, { useEffect, useRef, useState } from "react";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, ResponsiveContainer, ReferenceLine, Tooltip, TooltipProps, LineChart, Legend, Line } from "recharts";
import { Constants } from "../../Constants";
import { AppDispatch, RootState } from "../../store";
import { getGrayscaleLineProfileChartActive } from "./ImageDisplaySlice";
import { useGetAnnotationQuery } from '../../apis/apiSlice';
import "./GrayscaleLineProfile.scss";

type GrayscaleLineProfileProps = {
	orderId: string;
	matrixIndex: number;
};

type Profile = {
	id: string; // image id
	matrixIndex: number;
	data: { x: number; y: number; }[]; // profile data
	xMax: number; // length of profile data
	yMin: number; // smallest y in profile data
	yMax: number // largest y in profile data
	lineWidth?: number; // line width of profile
};
// global store of all profiles received via onGrayScaleLineProfileChanged() with meta data (yMin/yMax)
const allProfiles: Profile[] = [];

//const throttle = csTools.importInternal('util/throttle');

const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
const useAppDispatch = () => useDispatch<AppDispatch>();

const GrayscaleLineProfile = (props: GrayscaleLineProfileProps) => {
	const dispatch = useAppDispatch();

	const [xMax, setXMax] = useState<number>(10000);
	const [yMin, setYMin] = useState<number>(10000);
	const [yMax, setYMax] = useState<number>(10000);

	const [imageId, setImageId] = useState<string>("");
	const [measurementId, setMeasurementId] = useState<string>("");
	const [profileData, setProfileData] = useState<{ x: number; y: number; }[]>([]);

	const [lineWidth, setLineWidth] = useState<number>(1);

	const onGrayScaleLineProfileToolEnabled = (evt: any) => {
		// console.log("ENABLE EVENT RECEIVED");
		// console.log("for image: "+displayElement?.image?.imageId);
		if (displayElement)
			if (displayElement.image)
				setImageId(displayElement.image.imageId);

		//dispatch({ type: "ImageDisplay/setGrayscaleLineProfileActive", payload: { matrixIndex: props.matrixIndex, isActive: true } });
	};

	const onGrayScaleLineProfileId = (evt: any) => {
		//console.log("onGrayScaleLineProfileId EVENT RECEIVED");
		setMeasurementId(evt.detail);
		//dispatch({ type: "ImageDisplay/setGrayscaleLineProfileActive", payload: { matrixIndex: props.matrixIndex, isActive: true } });
	}

	const onGrayScaleLineProfileChanged = (evt: any) => {
		//console.log("PROFILE CHANGE EVENT RECEIVED");
		if (evt && evt.detail) {
			//console.log(evt.detail);
			let profile = evt.detail.profile
			const xMax: number = profile.length;
			var data: any = [];
			var yMin = 65535, yMax = 0;
			for (var i = 0; i < xMax; i++) {
				var y: number = profile[i];
				data[i] = { x: i, y: y };
				if (yMin > profile[i])
					yMin = profile[i];
				if (yMax < profile[i])
					yMax = profile[i];
			}

			if (evt.detail.lineWidth) {
				let newLineWidth = evt.detail.lineWidth;
				setLineWidth(newLineWidth);
			}
			setProfileData(data);

			//setXMax(xMax);
			setYMin(yMin);
			setYMax(yMax);

			if (displayElement) {
				if (displayElement.image) {
					// manage global profile data storage
					let id: string = displayElement.image.imageId;
					let matrixIndex: number = props.matrixIndex;
					const haveId = (element: Profile) => { return ((element.id === id) && (element.matrixIndex === matrixIndex)) };
					var index = allProfiles.findIndex(haveId);
					if (index === -1)
						// store new profile
						allProfiles.push({ id, matrixIndex, data, xMax, yMin, yMax, lineWidth });
					else
						// update existing profile with new data
						allProfiles[index] = { id, matrixIndex, data, xMax, yMin, yMax, lineWidth };

				}
			}
		}
	}; // onGrayScaleLineProfileChanged

	const cornerstoneElements: EnabledElement[] = cornerstone.getEnabledElements();
	const isGrayscaleLineProfileChartActive: boolean | undefined = useAppSelector((state) => getGrayscaleLineProfileChartActive(state, props.matrixIndex ?? 0));
	const displayElement: EnabledElement | undefined = cornerstoneElements?.find(
		(element: EnabledElement) => element.element.id === `${Constants.IMAGE_DISPLAY_GENERIC_ELEMENT_NAME}_${props.matrixIndex}`
	);

	const scaleYMax = (event: any) => {
		// event.preventDefault();
		event.stopPropagation();
		// Apply scale transform
		setYMax(yMax * (1 + event.deltaY * -0.001));
	}

	function handleLineWidth(evt: any) {
		// TODO store change via Edit()?
		evt.stopPropagation();
		evt.preventDefault();
		setLineWidth(evt.target.value);
		displayElement?.element.dispatchEvent(new CustomEvent("LW", { detail: { value: evt.target.value, id: measurementId } }));
	}

	const {
		data,
		isSuccess,
	} = useGetAnnotationQuery(displayElement?.image?.imageId, {
		refetchOnMountOrArgChange: true,
		skip: false,
	})


	useEffect(() => {
		// a new? image appears at our matrix position => see if we should display the grayscale profile
		if (displayElement) {
			if (displayElement.image && isGrayscaleLineProfileChartActive) {
				// search for profile data in global storage
				let id: string = displayElement.image.imageId;
				let matrixIndex: number = props.matrixIndex;
				const haveProfile = (element: Profile) => { return ((element.id === id) && (element.matrixIndex === matrixIndex)) };
				var index = allProfiles.findIndex(haveProfile);
				if (index === -1) {
					// not found => disable grayscale profile display
					setImageId("");
					//dispatch({ type: "ImageDisplay/setGrayscaleLineProfileActive", payload: { matrixIndex: props.matrixIndex, isActive: false } });
				} else {
					// found profile data => enable grayscale profile display with data from storage
					setImageId(displayElement.image?.imageId);
					const profile: Profile = allProfiles[index];
					setProfileData(profile.data);
					setXMax(profile.xMax);
					setYMin(profile.yMin);
					setYMax(profile.yMax);

					if (profile.lineWidth !== undefined) {
						setLineWidth(profile.lineWidth);
					}

					//dispatch({ type: "ImageDisplay/setGrayscaleLineProfileActive", payload: { matrixIndex: props.matrixIndex, isActive: true } });
				}
			} else {
				//dispatch({ type: "ImageDisplay/setGrayscaleLineProfileActive", payload: { matrixIndex: props.matrixIndex, isActive: false } });
			}

			displayElement.element?.addEventListener("enablegrayscaleprofile", onGrayScaleLineProfileToolEnabled);
			displayElement.element?.addEventListener("profile", onGrayScaleLineProfileChanged);
			displayElement.element?.addEventListener("measurementid", onGrayScaleLineProfileId);
		}
		return () => {
			if (displayElement) {
				setImageId("");
				displayElement.element?.removeEventListener("enablegrayscaleprofile", onGrayScaleLineProfileToolEnabled);
				displayElement.element?.removeEventListener("profile", onGrayScaleLineProfileChanged);
				displayElement.element?.removeEventListener("measurementid", onGrayScaleLineProfileId);
			}
		};
	}, [displayElement, displayElement?.image?.imageId, isGrayscaleLineProfileChartActive]);


	const getAreaChart = () => {
		return (<AreaChart
			width={400}
			height={300}
			data={profileData}
			margin={{
				top: 10,
				right: 5,
				left: 0,
				bottom: 0,
			}}
		>
			<XAxis dataKey="x" tick={false} />
			<YAxis domain={[yMin, yMax]} />
			<CartesianGrid />
			<ReferenceLine />
			<Tooltip content={<CustomToolTip />} />
			<Area type="monotone" stroke="red" dataKey="y" fillOpacity={0} dot={false} isAnimationActive={false} />
		</AreaChart>
		);
	} // getAreaChart

	const CustomToolTip = ({ active, payload, label }: TooltipProps<number, string>): JSX.Element => {
		if (active && payload && label && isGrayscaleLineProfileChartActive) {
			displayElement?.element.dispatchEvent(new CustomEvent("TT", { detail: { value: label, id: measurementId } }));
			return (<div><p>{`${label} : ${payload[0].value}`}</p></div>);
		} else {
			return (<div />);
		}
	};


	return (
		<div className="grayscaleLineProfile">
			<div className="header">
				Grayscale Profile
			</div>
			<div className="chartWrapper"  onContextMenu={(e) => e.preventDefault()} onWheel={(e) => scaleYMax(e)}>
				<ResponsiveContainer width="100%" height="100%" minWidth="40" minHeight="30">
					{getAreaChart()}
				</ResponsiveContainer>
			</div>
			<div>
				<div className="input">
					Line Width: <input className="input" type="number" value={lineWidth} onChange={(evt) => handleLineWidth(evt)} />
				</div>
			</div>
		</div>
	);
};

export default GrayscaleLineProfile;
