/* eslint no-alert: 0 */
import csTools from 'cornerstone-tools';

const Cursors = csTools.importInternal('tools/cursors');
const cursor = Cursors.cobbAngleCursor;
const getPixelSpacing = csTools.importInternal('util/getPixelSpacing');

/**
 * @public
 * @class RestorableCobbAngleTool
 * @memberof Tools.Annotation
 * @classdesc Create and position an arrow and label
 * @extends Tools.Annotation.CobbAngleTool
 */
export default class RestorableCobbAngleTool extends csTools.CobbAngleTool {
	constructor(props = {}) {
		const defaultProps = {
			name: 'RestorableCobbAngle',
			supportedInteractionTypes: ['Mouse', 'Touch'],
			configuration: {
				drawHandles: true,
				drawHandlesOnHover: props.configuration.drawHandlesOnHover ?? false,
				hideHandlesIfMoving: false,
				renderDashed: false,
				shadow: true,
			},
			svgCursor: cursor,
		};

		super(defaultProps);
	}

	updateCachedStats(image, element, data) {
		const { rowPixelSpacing, colPixelSpacing } = getPixelSpacing(image);

		const dx1 =
			(Math.ceil(data.handles.start.x) - Math.ceil(data.handles.end.x)) *
			(colPixelSpacing || 1);
		const dy1 =
			(Math.ceil(data.handles.start.y) - Math.ceil(data.handles.end.y)) *
			(rowPixelSpacing || 1);
		const dx2 =
			(Math.ceil(data.handles.start2.x) - Math.ceil(data.handles.end2.x)) *
			(colPixelSpacing || 1);
		const dy2 =
			(Math.ceil(data.handles.start2.y) - Math.ceil(data.handles.end2.y)) *
			(rowPixelSpacing || 1);

		let angle = Math.acos(
			Math.abs(
				(dx1 * dx2 + dy1 * dy2) /
				(Math.sqrt(dx1 * dx1 + dy1 * dy1) * Math.sqrt(dx2 * dx2 + dy2 * dy2))
			)
		);

		angle *= 180 / Math.PI;

		data.rAngle = roundToDecimal(angle, 2);
		data.invalidated = false;
	}


	restore(element, data) {
		var xs = data.handles.start.x;
		var ys = data.handles.start.y;

		let measurement = super.createNewMeasurement(
			{
				currentPoints: { image: { x: xs, y: ys } },
				viewport: { rotation: undefined },
				element: element
			});

		this.hasIncomplete = false;

		measurement.handles.end.x = data.handles.end.x;
		measurement.handles.end.y = data.handles.end.y;

		measurement.handles.start2.x = data.handles.start2.x;
		measurement.handles.start2.y = data.handles.start2.y;
		measurement.handles.start2.drawnIndependently = false;

		measurement.handles.end2.x = data.handles.end2.x;
		measurement.handles.end2.y = data.handles.end2.y;
		measurement.handles.end2.drawnIndependently = false;

		measurement.handles.textBox = JSON.parse(JSON.stringify(data.handles.textBox));
		//measurement.handles.textBox.active = true;

		this.hasIncomplete = false;
		//measurement.complete = true;

		//this.data.complete = true;

		return measurement;
	}

	store(toolData) {
		this.hasIncomplete = false;
		toolData.storeToolData = {
			"handles": toolData.handles,
		}
	}

} // RestorableCobbAngleTool



function roundToDecimal(value, precision) {
	const multiplier = Math.pow(10, precision);

	return Math.round(value * multiplier) / multiplier;
}
