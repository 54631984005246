import csTools from 'cornerstone-tools';
import cornerstone from "cornerstone-core";
import { setGetToolStateItems, updateAnnnotationOnInputChange } from "./utils";
import store from './../../../store';
import { fitToWindow } from "./utils";

const BaseTool = csTools.importInternal('base/BaseTool');
const getToolState = csTools.getToolState;

/**
 * @public
 * @class CutToBlackMaskTool
 * @memberof Tools
 *
 * @classdesc CutToBlackMaskTool
 * @extends Tools.Base.BaseTool
 */
export default class CutToBlackMaskTool extends BaseTool {
    /** @inheritdoc */
    constructor(props = {}) {
        const defaultProps = {
            name: 'CutToBlackMask',
        };
        super(props, defaultProps);
    }

    activeCallback(element) {
        const state = store.getState();
        const matrixIndex = state.ImageDisplay.selectedIndex;
        let bmname = 'BlackMask';
        let toolData = getToolState(element, bmname);
        if (toolData !== undefined && toolData.data) {
			const enabledElement = cornerstone.getEnabledElement(element);
			let image = enabledElement.image;
			if (image) {
				// instruct the BlackMaskTool to store the viewport once after its image cut border computation is done
				image.storevp = true;
			}
			let needFit = false;
            for (let i = 0; i < toolData.data.length; i++) {
                let data = toolData.data[i];
				setGetToolStateItems(i, [{ name: "storevp", value: true }], [], matrixIndex, bmname);				
                if (!data.cut) {
					needFit = true;
                    setGetToolStateItems(i, [{ name: "cut", value: true }], [], matrixIndex, bmname);
                    setGetToolStateItems(i, [{ name: "needFit", value: false }], [], matrixIndex, bmname);
                    updateAnnnotationOnInputChange(i, matrixIndex, bmname);
                } else {
                    setGetToolStateItems(i, [{ name: "cut", value: false }], [], matrixIndex, bmname);
                    setGetToolStateItems(i, [{ name: "needFit", value: true }], [], matrixIndex, bmname);
                    updateAnnnotationOnInputChange(i, matrixIndex, bmname);
                                        
                }
                if(needFit){
                	setTimeout(()=>{fitToWindow(element);}, 500);
				}
                cornerstone.updateImage(element, false);				
            }
            //cornerstone.triggerEvent(element, "cuttoblackmask");
        }
    }
}
