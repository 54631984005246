import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import cornerstone, { EnabledElement } from 'cornerstone-core';
import { formatISO } from 'date-fns';
import { v4 as uuidv4 } from 'uuid';
import { apiSlice, device, property, study, workitem } from '../../apis/apiSlice';
import { Constants } from '../../Constants';
import { RootState } from '../../store';
import { DisplayItemType, getMatrixIndexForImageId, getMatrixIndexForInsert } from '../ImageDisplay/ImageDisplaySlice';
import { getLayout, getSelectedWorkitem, selectedStudyState } from '../OrderList/OrdersSlice';
import { t } from "../Localization/ORLocalization";
import { selectProcedureSelectionVisibilityState } from '../Procedures/ProcedureSlice';

export interface IAcquisition {
    isAcquistionButtonActive: boolean;
    isDeviceRunnerActive: boolean;
    deviceRunnerError: string;
    isAcquistionOngoing: boolean;
    currentProcessingMqttPayload: string;
    deviceRunnerPath: string;
    isDeviceSetOpen: boolean;
    selectedDeviceSetId: number;
}

const initialState: IAcquisition = {
    isAcquistionButtonActive: false,
    isDeviceRunnerActive: true,
    deviceRunnerError: '',
    isAcquistionOngoing: false,
    currentProcessingMqttPayload: "",
    deviceRunnerPath: "",
    isDeviceSetOpen: false,
    selectedDeviceSetId: -1,
}

export const clearConerstoneCanvasForMatrixIndex = (matrixIndex: number) => {
    const cornerstoneElements: EnabledElement[] = cornerstone.getEnabledElements();
    const displayElement: EnabledElement | undefined = cornerstoneElements.find(
        (element: EnabledElement) => element.element.id === `${Constants.IMAGE_DISPLAY_GENERIC_ELEMENT_NAME}_${matrixIndex}`
    );

    if (displayElement && displayElement.canvas) {
        const context = displayElement.canvas.getContext('2d', {
            desynchronized: true
        });

        if (context) {
            context.setTransform(1, 0, 0, 1, 0, 0);
            // Clear the canvas
            context.fillStyle = 'black';
            context.fillRect(0, 0, displayElement.canvas.width, displayElement.canvas.height);
        }
        displayElement.image = undefined;
    }
}

export const switchDeviceset = createAsyncThunk('Acquisition/switchDeviceset',
    async (args: { devicedetId: number, procedureCode: string }, thunkApi) => {
        const getState: () => RootState = thunkApi.getState;
        if (getState()?.Acquisition?.selectedDeviceSetId >= 0) {
            await thunkApi.dispatch(apiSlice.endpoints.putChangeOperationMode.initiate(
                {
                    deviceRunnerPath: getState().Acquisition.deviceRunnerPath, qualifier: getState()?.Acquisition?.selectedDeviceSetId,
                    active: false, studyId: getState().Orders.currentOrderId, workitemId: getState().ImageDisplay.selectedWorkitem
                }))
        }

        thunkApi.dispatch(apiSlice.endpoints.putChangeOperationMode.initiate(
            {
                deviceRunnerPath: getState().Acquisition.deviceRunnerPath, qualifier: args.devicedetId,
                active: true, studyId: getState().Orders.currentOrderId, workitemId: getState().ImageDisplay.selectedWorkitem,
                procedureCode: args.procedureCode

            }))

        thunkApi.dispatch({ type: "Acquisition/setSelectedDeviceSetId", payload: args.devicedetId });
    });

export const setAcquisitionActive = createAsyncThunk('Acquisition/setAcquisitionButtonActive',
    async (args: { setAcquistionButtonActive: boolean, studyId: string | undefined, workitemId: string | undefined, studyInfo?: any }, thunkApi) => {

        let studyId: string | undefined = undefined;
        let workitemId: string | undefined = undefined;
        try {
            const getState: () => RootState = thunkApi.getState;
            if (args.setAcquistionButtonActive) {
                if (!args.studyId) {
                    const title: string = t(`new${Constants.ORDER_IDENTIFIER}`, { ns: 'Acquisition' });

                    const study_uuid: string = uuidv4();
                    const hex = "0x" + study_uuid.replace(/-/g, "");
                    const value = BigInt(hex);
                    const studyInstanceUID = '2.25.' + value.toString(); // don't convert this to a number.

                    let studyDetails = args?.studyInfo ?? {};
                    if (args?.studyInfo?.title === undefined) {
                        studyDetails = { ...studyDetails, title }
                    }
                    studyDetails = { ...studyDetails, studyInstanceUID }

                    const newStudyResponse: study | undefined = await thunkApi.dispatch(apiSlice.endpoints.putStudy.initiate(studyDetails)).unwrap();
                    if (newStudyResponse?.id) {
                        studyId = newStudyResponse?.id;
                    }
                } else {
                    const studyState: string = selectedStudyState(thunkApi.getState(), args.studyId);
                    if (studyState && (studyState === 'COMPLETED' && getState()?.MainOrderListColumns.allowComplete)) return;
                    studyId = args.studyId;
                }
                if (studyId && !args.workitemId && args.workitemId !== "-1") {
                    const title: string = t('newWorkitemTitle', { ns: 'Acquisition' });
                    const description: string = '';
                    let newWorkitemResponse: workitem | undefined = undefined;
                    try {
                        newWorkitemResponse = await thunkApi.dispatch(apiSlice.endpoints.putWorkitem.initiate({
                            details: {
                                ref_study: studyId, created: formatISO(new Date()),
                                title: title, desc: description, catalogueId: '0', document_mime_typ: ''
                            }
                        })).unwrap();
                        thunkApi.dispatch(
                            apiSlice.util.updateQueryData('getStudyWithWorkitems', args.studyId, (data: any) => {
                                const workitemList1 = data?.study?.workItems.map((id: string) => id);
                                const workitemList2 = data?.workitems?.map((workitem: any, i: number) => workitem?.data?.id);
                                if (newWorkitemResponse?.id && workitemList1 && workitemList2 && !workitemList1.includes(newWorkitemResponse?.id)) {
                                    data.study.workItems.push(newWorkitemResponse?.id);
                                    data.workitems.push({ data: newWorkitemResponse });
                                }
                                return data;
                            })
                        )
                    } catch (error) {
                        console.log(error);
                    }
                    if (newWorkitemResponse?.id) {
                        workitemId = newWorkitemResponse?.id;
                        thunkApi.dispatch({ type: "Orders/setCurrentOrderId", payload: studyId });
                        thunkApi.dispatch({ type: "ImageDisplay/setSelectedWorkitem", payload: workitemId });
                    }
                } else {
                    workitemId = args.workitemId === "-1" ? undefined : args.workitemId;
                }
                //thunkApi.dispatch({ type: "ImageDisplay/setImageDescVisible", payload: false });
            }

            const layout = getLayout(getState());
            const procedureSelectionVisibilityState = selectProcedureSelectionVisibilityState(getState());
            //console.log("putChangeOperationMode: " + args.setAcquistionButtonActive + " " + layout?.showProcedureSelectionPanel + " " + procedureSelectionVisibilityState);
            //if (getState().Acquisition.isDeviceRunnerActive && workitemId && getState().Acquisition.deviceRunnerError === '') {
            if (getState().Acquisition.isDeviceRunnerActive && getState().Acquisition.deviceRunnerError === '' && (!layout?.showProcedureSelectionPanel || procedureSelectionVisibilityState >= 2)) {
                try {
                    let currentWorkitem = undefined
                    const currentWorkitemAsString = getSelectedWorkitem(getState());
                    if (currentWorkitemAsString) {
                        currentWorkitem = currentWorkitemAsString ? JSON.parse(currentWorkitemAsString) : undefined;
                    }
                    await thunkApi.dispatch(apiSlice.endpoints.putChangeOperationMode.initiate(
                        {
                            deviceRunnerPath: getState().Acquisition.deviceRunnerPath, qualifier: 'default',
                            active: args.setAcquistionButtonActive, studyId: studyId, workitemId: workitemId,
                            procedureCode: currentWorkitem?.details?.procedureCode
                        })).unwrap();
                } catch (error) {
                    thunkApi.dispatch({ type: "Acquisition/setDeviceRunnerError", payload: 'device runner connection error' });;
                }
            }

            if (workitemId) {
                const currentMatrixIndexForWorkitem = getMatrixIndexForImageId(thunkApi.getState(), workitemId);
                const newIndex = getMatrixIndexForInsert(thunkApi.getState(), currentMatrixIndexForWorkitem);
                //thunkApi.dispatch({ type: 'ImageDisplay/setMatrixImages', payload: { matrixIndex: newIndex, imageId: workitemId, displayItemType: DisplayItemType.image } });
                thunkApi.dispatch({ type: "ImageDisplay/setSelectedIndex", payload: newIndex });
                thunkApi.dispatch({ type: "ImageDisplay/setDisplayItemType", payload: { matrixIndex: newIndex, displayItemType: DisplayItemType.image } });
                thunkApi.dispatch({ type: "ImageDisplay/setHasRawImage", payload: { matrixIndex: newIndex, hasRawImage: false } });
                clearConerstoneCanvasForMatrixIndex(newIndex);
            }
            //thunkApi.dispatch({ type: 'Acquisition/setAcquisitionButtonActive', payload: args.setAcquistionButtonActive && workitemId && getState().Acquisition.deviceRunnerError === '' });
            thunkApi.dispatch({ type: 'Acquisition/setAcquisitionButtonActive', payload: args.setAcquistionButtonActive && getState().Acquisition.deviceRunnerError === '' });
            //console.log("Acquisition/setAcquisitionButtonActive " + (args.setAcquistionButtonActive && getState().Acquisition.deviceRunnerError === ''));


            /* if (layout?.showProcedureSelectionPanel) {
                thunkApi.dispatch({ type: "Procedure/setProcedureSelectionVisibilityState", payload: args.setAcquistionButtonActive ? 1 : 0 });
            } */
            //thunkApi.dispatch({ type: 'Acquisition/setIsAcquistionOngoing', payload: args.setAcquistionButtonActive });
        } catch (error) {
            //thunkApi.dispatch({ type: "Message/setMessage", payload: { timestamp: new Date().toISOString(), severity: messageSeverity.error, text: "error set acquistion button active: " + JSON.stringify(error) } });
            //thunkApi.dispatch({ type: "Acquisition/setDeviceRunnerActive", payload: false });
        };
        return { studyId: studyId, workitemId: workitemId };
    })

const AcquisitionSlice = createSlice({
    name: 'Acquisition',
    initialState,
    reducers: {
        setAcquisitionButtonActive(state, action) {
            const isAcquistionButtonActive: boolean = action.payload;
            state.isAcquistionButtonActive = isAcquistionButtonActive;
        },
        setDeviceRunnerActive(state, action) {
            const isDeviceRunnerActive: boolean = action.payload;
            state.isDeviceRunnerActive = isDeviceRunnerActive;
        },
        setDeviceRunnerError(state, action) {
            const deviceRunnerError: string = action.payload;
            state.deviceRunnerError = deviceRunnerError;
        },
        setIsAcquistionOngoing(state, action) {
            const isAcquistionOngoing: boolean = action.payload;
            state.isAcquistionOngoing = isAcquistionOngoing;
        },
        setCurrentProcessingMqttPayload(state, action) {
            const currentProcessingMqttPayload: string = action.payload;
            state.currentProcessingMqttPayload = currentProcessingMqttPayload;
        },
        setDeviceRunnerPath(state, action) {
            const deviceRunnerPath: string = action.payload;
            state.deviceRunnerPath = deviceRunnerPath;
        },

        setDeviceSetOpen(state, action) {
            const isDeviceSetOpen: boolean = action.payload;
            state.isDeviceSetOpen = isDeviceSetOpen;
        },

        setSelectedDeviceSetId(state, action) {
            const selectedDeviceSetId: number = action.payload;
            state.selectedDeviceSetId = selectedDeviceSetId;
        },

    }
});

export const { setAcquisitionButtonActive, setDeviceRunnerActive, setIsAcquistionOngoing, setCurrentProcessingMqttPayload,
    setDeviceRunnerError, setDeviceSetOpen, setSelectedDeviceSetId } = AcquisitionSlice.actions
export default AcquisitionSlice.reducer;

export const selectProcessingCurrentMqttPayload = ((state: RootState) =>
    state.Acquisition.currentProcessingMqttPayload);

export const selectDeviceRunnerActive = ((state: RootState) =>
    state.Acquisition.isDeviceRunnerActive);

export const selectDeviceRunnerError = ((state: RootState) =>
    state.Acquisition.deviceRunnerError);

export const selectIsAcquistionOngoing = ((state: RootState) =>
    state.Acquisition.isAcquistionOngoing);

export const selectDeviceRunnerPath = ((state: RootState) =>
    state.Acquisition.deviceRunnerPath);

export const selectedDeviceSetId = ((state: RootState) =>
    state.Acquisition.selectedDeviceSetId);

export const selectStartCalibrationUrl = (state: RootState, endpoint: string): string | undefined => {
    let ret: string | undefined = undefined;
    const currentProperties = apiSlice.endpoints.getDeviceWebThing.select({ endpoint });
    const deviceActions: any = currentProperties(state)?.data?.device?.actions;

    if (deviceActions) {
        if (deviceActions?.startCalibration) {
            const url = (new URL(endpoint)).origin + deviceActions?.startCalibration.links[0]?.href;
            ret = url;
        }
    }
    return ret;
}

export const selectTriggerImageUrl = (state: RootState, endpoint: string): string | undefined => {
    let ret: string | undefined = undefined;
    const currentProperties = apiSlice.endpoints.getDeviceWebThing.select({ endpoint });
    const deviceActions: any = currentProperties(state)?.data?.device?.actions;

    if (deviceActions) {
        if (deviceActions?.triggerImage) {
            const url = (new URL(endpoint)).origin + deviceActions?.triggerImage.links[0]?.href;
            ret = url;
        }
    }
    return ret;
}

export const selectCancelCalibrationUrl = (state: RootState, endpoint: string): string | undefined => {
    let ret: string | undefined = undefined;
    const currentProperties = apiSlice.endpoints.getDeviceWebThing.select({ endpoint });
    const deviceActions: any = currentProperties(state)?.data?.device?.actions;

    if (deviceActions) {
        if (deviceActions?.cancelCalibration) {
            const url = (new URL(endpoint)).origin + deviceActions?.cancelCalibration.links[0]?.href;
            ret = url;
        }
    }
    return ret;
}

export const selectSoftwareTriggerUrl = (state: RootState, id?: number | undefined): string | undefined => {
    let ret: string | undefined = undefined;
    const selectDevicesSets = apiSlice.endpoints.getDeviceSets.select('SET');
    const deviceSets: any[] = selectDevicesSets(state).data;

    let deviceSet = undefined;
    if (id === undefined) {
        deviceSet = deviceSets?.find((deviceSet: any) => deviceSet?.data?.deviceSet?.config?.default === true);
    } else {
        deviceSet = deviceSets?.find((deviceSet: any) => deviceSet?.data?.deviceSet?.id === id);
    }
    if (deviceSet) {
        const deviceActions: any = deviceSet?.data?.deviceSetWebThing?.actions;
        if (deviceActions?.softwareTrigger) {
            const url = (new URL(deviceSet?.data?.deviceSet?.config?.endpoint)).origin + deviceActions?.softwareTrigger.links[0]?.href;
            ret = url;
        }
    }
    return ret;
}

export const selectCancelSoftwareTriggerUrl = (state: RootState, id?: number | undefined): string | undefined => {
    let ret: string | undefined = undefined;
    const selectDevicesSets = apiSlice.endpoints.getDeviceSets.select('SET');
    const deviceSets: any[] = selectDevicesSets(state).data;

    let deviceSet = undefined;
    if (id === undefined) {
        deviceSet = deviceSets?.find((deviceSet: any) => deviceSet?.data?.deviceSet?.config?.default === true);
    } else {
        deviceSet = deviceSets?.find((deviceSet: any) => deviceSet?.data?.deviceSet?.id === id);
    }
    if (deviceSet) {
        const deviceActions: any = deviceSet?.data?.deviceSetWebThing?.actions;
        if (deviceActions?.cancelSoftwareTrigger) {
            const url = (new URL(deviceSet?.data?.deviceSet?.config?.endpoint)).origin + deviceActions?.cancelSoftwareTrigger.links[0]?.href;
            ret = url;
        }
    }
    return ret;
}

export const selectRequestManufacturerTool = (state: RootState, endpoint: string): string | undefined => {
    let ret: string | undefined = undefined;
    const currentProperties = apiSlice.endpoints.getDeviceWebThing.select({ endpoint });
    const deviceActions: any = currentProperties(state)?.data?.device?.actions;

    if (deviceActions) {
        if (deviceActions?.requestManufacturerTool) {
            const url = (new URL(endpoint)).origin + deviceActions?.requestManufacturerTool.links[0]?.href;
            ret = url;
        }
    }
    return ret;
}

export const selectDeviceSetOpen = ((state: RootState) =>
    state.Acquisition.isDeviceSetOpen);

export const selectSoftwareTriggerState = (state: RootState, id?: number | undefined) => {
    let ret: string | undefined = undefined;
    const selectDevicesSets = apiSlice.endpoints.getDeviceSets.select('SET');
    const deviceSets: any[] = selectDevicesSets(state).data;

    let deviceSet = undefined;
    if (id === undefined) {
        deviceSet = deviceSets?.find((deviceSet: any) => deviceSet?.data?.deviceSet?.config?.default === true);
    } else {
        deviceSet = deviceSets?.find((deviceSet: any) => deviceSet?.data?.deviceSet?.id === id);
    }
    if (deviceSet) {
        ret = deviceSet?.data?.deviceWebThingProps?.softwareTriggerState?.state;
    }
    return ret;
}


export const selectAcquistionButtonActive = ((state: RootState) =>
    state.Acquisition.isAcquistionButtonActive);


export const selectDeviceProperties = (state: RootState, endpoint: string) => {
    let ret: [string, property][] = [];
    if (endpoint) {
        const currentProperties = apiSlice.endpoints.getDeviceWebThing.select({ endpoint });
        const device: any = currentProperties(state)?.data?.device.properties;
        const properties: [string, property][] | undefined = device ?
            Object.entries(device) : undefined;
        ret = properties ?? [];
    }
    return JSON.stringify(ret);
};

export const selectDeviceActions = (state: RootState, endpoint: string) => {
    let ret: [string, property][] = [];
    if (endpoint) {
        const currentProperties = apiSlice.endpoints.getDeviceWebThing.select({ endpoint });
        const device: any = currentProperties(state)?.data?.device.actions;
        const actions: [string, property][] | undefined = device ?
            Object.entries(device) : undefined;
        ret = actions ?? [];
    }
    return JSON.stringify(ret);
};

export const selectCurrentDeviceProperties = (state: RootState, endpoint: string) => {
    let ret: [string, Object][] = [];
    if (endpoint) {
        const currentDeviceProperties = apiSlice.endpoints.getDeviceWebThing.select({ endpoint });
        const webThing: any = currentDeviceProperties(state)?.data?.deviceWebThingProps;
        const properties: [string, Object][] | undefined = webThing ?
            Object.entries(webThing) : undefined;
        ret = properties ?? [];
    }
    return JSON.stringify(ret);
};

export const selectDeviceCount = (state: RootState) => {
    let count: number = 0;
    if (state.Acquisition.isDeviceRunnerActive) {
        const selectDevicesSets = apiSlice.endpoints.getDeviceSets.select('SET');
        const deviceSets: any[] = selectDevicesSets(state).data;
        if (deviceSets) {
            const deviceSet = deviceSets?.find((deviceSet: any) => deviceSet?.data?.deviceSet?.config?.default === true)?.data?.deviceSet?.config?.devices;
            if (deviceSet) {
                deviceSet.forEach((id: number) => {
                    const args: { deviceId: number, deviceRunnerPath: string } =
                        { deviceId: id, deviceRunnerPath: state.Acquisition.deviceRunnerPath };
                    const selectDevice = apiSlice.endpoints.getDeviceRegistry.select(args);
                    if (selectDevice(state).isSuccess) {
                        const currentDevice: device = selectDevice(state).data;
                        if (currentDevice?.config?.endpoint) {
                            count++;
                        }
                    }
                })
            }
        }
    }
    return count;
};

export const selectDeviceStatusList = (state: RootState) => {
    let deviceStatusList: any = [];
    if (state.Acquisition.isDeviceRunnerActive) {
        const selectDevicesSets = apiSlice.endpoints.getDeviceSets.select('SET');
        const deviceSets: any[] = selectDevicesSets(state).data;
        if (deviceSets) {
            const deviceSet = deviceSets?.find((deviceSet: any) => deviceSet?.data?.deviceSet?.config?.default === true)?.data?.deviceSet?.config?.devices;
            if (deviceSet) {
                deviceSet.forEach((id: number) => {
                    const args: { deviceId: number, deviceRunnerPath: string } =
                        { deviceId: id, deviceRunnerPath: state.Acquisition.deviceRunnerPath };
                    const selectDevice = apiSlice.endpoints.getDeviceRegistry.select(args);
                    const currentDevice: device = selectDevice(state).data;
                    if (currentDevice?.config?.endpoint) {
                        const currentDeviceProperties = apiSlice.endpoints.getDeviceWebThing.select({ endpoint: currentDevice?.config?.endpoint });
                        const webThing: any = currentDeviceProperties(state)?.data?.deviceWebThingProps;
                        if (webThing && webThing.status) {
                            deviceStatusList.push({ ...webThing.status, category: currentDevice.category });
                        }
                    }
                })
            }
        }
    }
    return JSON.stringify(deviceStatusList);
};

export const selectDeviceSets = (state: RootState) => {
    let deviceSetList: any = [];
    if (state.Acquisition.isDeviceRunnerActive) {
        const selectDevicesSets = apiSlice.endpoints.getDeviceSets.select('SET');
        const deviceSets: any[] = selectDevicesSets(state).data;
        if (deviceSets) {
            deviceSetList = deviceSets;
        }
    }
    return JSON.stringify(deviceSetList);
};

export const selectGeneratorForDeviceSet = (state: RootState, devciceSetId: number) => {
    let generatorDevice: any = undefined;

    const selectDevicesSets = apiSlice.endpoints.getDeviceSets.select('SET');
    const deviceSets: any[] = selectDevicesSets(state).data;
    if (deviceSets) {
        const deviceSet = deviceSets?.find((deviceSet: any) => deviceSet?.data?.deviceSet?.id === devciceSetId)?.data?.deviceSet?.config?.devices;
        if (deviceSet) {
            deviceSet.forEach((id: number) => {
                const args: { deviceId: number, deviceRunnerPath: string } =
                    { deviceId: id, deviceRunnerPath: state.Acquisition.deviceRunnerPath };
                const selectDevice = apiSlice.endpoints.getDeviceRegistry.select(args);
                const currentDevice: device = selectDevice(state).data;
                if (currentDevice?.category === 'GENERATOR') {
                    generatorDevice = currentDevice;
                }
            })
        }
    }
    return generatorDevice;
};
