import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom'
import { apiSlice } from '../../apis/apiSlice';
import { Constants } from '../../Constants';
import { AppDispatch } from '../../store';
import { refreshToken, selectCurrentExpiresIn, selectCurrentRefreshToken, selectCurrentToCookie, selectCurrentToken, selectCurrentUser, selectLicense, selectRefreshStatus } from './authSlice';


export const revokeToken = async (refreshToken: string, dispatch: any) => {
    try {
        await dispatch(apiSlice.endpoints.Revoke.initiate({ token: refreshToken })).unwrap()
    } catch (err) {
        console.log(err);
    }
}

function convertMiliseconds(miliseconds: number, format: string) {
    var days, hours, minutes, seconds, total_hours, total_minutes, total_seconds;

    total_seconds = Math.floor(miliseconds / 1000);
    total_minutes = Math.floor(total_seconds / 60);
    total_hours = Math.floor(total_minutes / 60);
    days = Math.floor(total_hours / 24);

    seconds = total_seconds % 60;
    minutes = total_minutes % 60;
    hours = total_hours % 24;

    switch(format) {
      case 's':
          return total_seconds;
      case 'm':
          return total_minutes;
      case 'h':
          return total_hours;
      case 'd':
          return days;
      default:
          return { d: days, h: hours, m: minutes, s: seconds };
    }
  };

export const checkLicense = (license : any) : {valid: boolean , expiresShortly: string} => {
    let ret: {valid: boolean , expiresShortly: string} =  {valid: false , expiresShortly: ''};
    if (!(license === undefined || license === null || license?.valid === undefined)) {
        if(license.valid) {
            if(license.expires === undefined || license.expires === null) {
                ret = {valid: true , expiresShortly: ''};
            } else {
                const timeDiff: number = (Number(license?.expires) * 1000) - Date.now();
                if (timeDiff > 0) {
                    const daysLeft: number | { d: number, h: number, m: number, s: number } = convertMiliseconds(timeDiff, 'd');
                    ret = {valid: true , expiresShortly: timeDiff < Constants.EXPIRES_WARN_DAYS_LEFT * 24 * 60 * 60 * 1000 ?
                         `license expires in ${daysLeft as number + 1} days` : ''};
                }
            }
        }
    }
    return ret;
}

const useAppDispatch = () => useDispatch<AppDispatch>();

const RequireAuth = ({ children } : any) => {

    const token = useSelector(selectCurrentToken);

    const currentRefreshToken = useSelector(selectCurrentRefreshToken);
    const currentUser = useSelector(selectCurrentUser);
    const currentExpiresIn = useSelector(selectCurrentExpiresIn);
    const currentLicense = useSelector(selectLicense);
    const toCookie = useSelector(selectCurrentToCookie);
    const refreshStatus = useSelector(selectRefreshStatus);
    const location = useLocation();
    const dispatch = useAppDispatch();

    useEffect(() => {
        let interval: any = undefined;
        if(currentRefreshToken) {
        interval = setInterval(() => {
            if (toCookie && refreshStatus !== 'loading') {
                dispatch(refreshToken({ refresh_token: currentRefreshToken, user: currentUser }));
            }
          }, Math.round(currentExpiresIn * 1000 * 0.8));
        }
          return () => {
            if (interval) {
                clearInterval(interval);
            }
        };
    }, [currentRefreshToken, refreshStatus, currentUser, currentExpiresIn, dispatch, toCookie])

    return (
        token && currentLicense?.valid
            ? children
            : <Navigate to={`/login/${location?.search}`} state={{ from: location }} replace />
    )
}
export default RequireAuth
