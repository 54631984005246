import { useEffect, useState } from 'react';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store';
import './DensityModel.scss';
import { getImagePathForCurrentTheme, getSelectedWorkitem } from '../OrderList/OrdersSlice';
import { apiSlice, device, deviceSet } from '../../apis/apiSlice';
import { Constants } from '../../Constants';
import { getCurrentProtocolStepNumber } from '../ImageDisplay/ImageDisplaySlice';

import { useORTranslation } from '../Localization/ORLocalization';
import { formatISO } from 'date-fns';
import { getTechniqueParameters, selectTechniqueParameters } from './GeneratorTechniqueParametersSlice';
import { selectGeneratorForDeviceSet } from './AcquisitionSlice';

interface DensityModelProps {
    orderId: string;
    deviceSet: any;
    device: device | deviceSet;
};

const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
const useAppDispatch = () => useDispatch<AppDispatch>();

const DensityModel = (props: DensityModelProps) => {

    const dispatch = useAppDispatch();

    const { t } = useORTranslation(['Acquisition', 'common']);

    const [updisabled, setUpdisabled] = useState<boolean>(true);
    const [downdisabled, setDowndisabled] = useState<boolean>(true);

    const [densityStep, setDensityStep] = useState<number>(0);

    const imagePathForCurrentTheme: string = useAppSelector((state) => getImagePathForCurrentTheme(state));
    const currentWorkitemAsString: string | undefined = useAppSelector((state) => getSelectedWorkitem(state));
    const currentWorkitem = currentWorkitemAsString ? JSON.parse(currentWorkitemAsString) : undefined;
    const currentProtocolSettingsStepNumber: number = useAppSelector((state) => getCurrentProtocolStepNumber(state, Constants.ACQUISITION_STEP_TYPE, 'scheduled', 'rejected'));
    const generatorForDeviceSet: any = useAppSelector((state) => selectGeneratorForDeviceSet(state, props.deviceSet?.id));
    const currentTechniqueParametersString: string | undefined = useAppSelector((state) => selectTechniqueParameters(state));
    const currentTechniqueParameters = currentTechniqueParametersString ? JSON.parse(currentTechniqueParametersString) : undefined;

    const handleClick = (inc: number) => {
        const currentProtocolStep = currentWorkitem?.protocol?.steps[currentProtocolSettingsStepNumber];
        const newWorkitemDetails = structuredClone(currentWorkitem?.details);
        let newValues: any = undefined;

        if (inc > 0) {
            newValues = {
                kv: Math.sqrt(currentProtocolStep?.scheduled?.generator?.kv * currentProtocolStep?.scheduled?.generator?.kv * 1.1),
                masWithoutAEC: (currentProtocolStep?.scheduled?.generator?.masWithoutAEC * 1.3),
                step: densityStep + 1
            }
        }

        if (inc < 0) {
            newValues = {
                kv: Math.sqrt(currentProtocolStep?.scheduled?.generator?.kv * currentProtocolStep?.scheduled?.generator?.kv / 1.1),
                masWithoutAEC: (currentProtocolStep?.scheduled?.generator?.masWithoutAEC / 1.3),
                step: densityStep - 1
            }
        }

        if (newValues) {

            setDensityStep(newValues?.step);
            newWorkitemDetails.densityStep = newValues?.step;


            const actionRef = currentWorkitem?.id + "_" + formatISO(new Date());
            dispatch({ type: "GeneratorTechniqueParameters/setCurrentActionRef", payload: actionRef });
            if (generatorForDeviceSet?.config?.endpoint) {
                const actionRef = currentWorkitem?.id + "_" + formatISO(new Date());
                dispatch(getTechniqueParameters({
                    voltage: newValues?.kv,
                    exposure: newValues?.masWithoutAEC,
                    generatorEndpoint: generatorForDeviceSet?.config?.endpoint, actionRef
                }));
            }


            dispatch(apiSlice.endpoints.updateWorkitemDetails1.initiate(
                {
                    workitemId: currentWorkitem?.id,
                    body: {
                        details: newWorkitemDetails,
                    }
                }
            ));

            dispatch(
                apiSlice.util.updateQueryData('getStudyWithWorkitems', props.orderId, (data: any) => {
                    const workitemList = data?.workitems?.map((workitem: any, i: number) => workitem?.data?.id);
                    if (currentWorkitem?.id && workitemList && workitemList.includes(currentWorkitem?.id)) {
                        const tmpWorkitem = data?.workitems?.find((workitem: any) => workitem.data?.id === currentWorkitem?.id);
                        if (tmpWorkitem?.data) {
                            tmpWorkitem.data.details = newWorkitemDetails;
                        }
                    }
                    return data;
                })
            );


        }
    }

    useEffect(() => {

        if (currentWorkitem?.protocol?.steps[currentProtocolSettingsStepNumber]?.scheduled?.setup?.measuringChamber === 0) {
            setUpdisabled(false);
            setDowndisabled(false);
        } else {
            setUpdisabled(true);
            setDowndisabled(true);
        }

        setDensityStep(currentWorkitem?.details?.densityStep ?? 0);

    }, [currentWorkitem?.id, currentWorkitem?.details?.procedureCode, currentWorkitem?.details?.densityStep, props.deviceSet?.id, currentWorkitem?.protocol?.steps[currentProtocolSettingsStepNumber]?.scheduled?.setup?.measuringChamber]);

    useEffect(() => {

        //console.log(currentTechniqueParametersString);
        if (currentWorkitem?.protocol?.steps[currentProtocolSettingsStepNumber]?.scheduled?.setup?.measuringChamber === 0) {
            if (currentTechniqueParameters?.voltage?.isLimit === 2 || currentTechniqueParameters?.exposure?.isLimit === 2) {
                setUpdisabled(true);
            } else {
                setUpdisabled(false);
            }

            if (currentTechniqueParameters?.voltage?.isLimit === 1 || currentTechniqueParameters?.exposure?.isLimit === 1) {
                setDowndisabled(true);
            } else {
                setDowndisabled(false);
            }
        }

    }, [currentTechniqueParametersString]);

    const getNumberToDisplay = () => {
        return currentWorkitem?.protocol?.steps[currentProtocolSettingsStepNumber]?.scheduled?.setup?.measuringChamber === 0 ? densityStep.toString() : "";
    }


    return (
        <div className="densityModel">
            <div className="densityModelWrapper">
                <div className="densityModelSelectButtonWrapper">
                    <button
                        className="densityModelSelectButton" disabled={currentWorkitem?.protocol?.steps[currentProtocolSettingsStepNumber]?.scheduled?.setup?.measuringChamber !== 0}>
                        <img
                            src={`${imagePathForCurrentTheme}densityModel.svg`}
                            onError={(event: any) => { event.target.src = "/images/densityModel.svg"; event.onerror = null }}
                            alt="densityModel.svg" />
                    </button>
                </div>
                <label className="densityModelLabel">
                    {getNumberToDisplay()}
                </label>
                <div className="densityModelButtons">
                    <div className="densityModelButtonsColumn">
                        <button className="numericInputButton" onClick={() => handleClick(1)} disabled={updisabled}>
                            <img style={{ transform: "rotate(90deg)" }}
                                src={`${imagePathForCurrentTheme}triangle-left-arrow-outline-svgrepo-com.svg`}
                                onError={(event: any) => { event.target.src = "/images/triangle-left-arrow-outline-svgrepo-com.svg"; event.onerror = null }}
                                alt="triangle-left-arrow-outline-svgrepo-com.svg" />
                        </button>
                        <button className="numericInputButton" onClick={() => handleClick(-1)} disabled={downdisabled}>
                            <img style={{ transform: "rotate(-90deg)" }}
                                src={`${imagePathForCurrentTheme}triangle-left-arrow-outline-svgrepo-com.svg`}
                                onError={(event: any) => { event.target.src = "/images/triangle-left-arrow-outline-svgrepo-com.svg"; event.onerror = null }}
                                alt="triangle-left-arrow-outline-svgrepo-com.svg" />
                        </button>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default DensityModel;
