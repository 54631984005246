import React, { useEffect, useState } from "react";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import cornerstone, { EnabledElement } from "cornerstone-core";
import cornerstoneWADOImageLoader from 'cornerstone-wado-image-loader';
import { AppDispatch, RootState } from "../../store";
import './DicomTagBrowser.scss';
import { Constants } from "../../Constants";
import { TAG_DICT } from "./dataDictionary";
import { formatDA, formatPN, formatTM } from '../Utils/DicomTagFormatter';

import {
    useTable,
    useResizeColumns,
    useFlexLayout,
} from 'react-table'
import { selectMatrixIndex } from "../ImageDisplay/ImageDisplaySlice";
import { messageSeverity } from "../common/MessageSlice";
import { useORTranslation } from "../Localization/ORLocalization";

const headerProps = (props: any, { column }: any) => getStyles(props, column.align)

const cellProps = (props: any, { cell }: any) => getStyles(props, cell.column.align)

const getStyles = (props: any, align = 'left') => [
    props,
    {
        style: {
            justifyContent: align === 'right' ? 'flex-end' : 'flex-start',
            alignItems: 'flex-start',
            display: 'flex',
        },
    },
]

function Table({ columns, data }: { columns: any, data: any }) {

    const { getTableProps, headerGroups, rows, prepareRow } = useTable(
        {
            columns,
            data,
            //defaultColumn,
        },
        useResizeColumns,
        useFlexLayout,
    )

    return (
        // @ts-ignore
        <div {...getTableProps()} className="table">
            <div>
                {headerGroups.map(headerGroup => (
                    // @ts-ignore
                    <div
                        {...headerGroup.getHeaderGroupProps({
                            style: { paddingRight: '10px' },
                        })}
                        className="tr"
                    >
                        {(headerGroup.headers as any).map((column: { getHeaderProps: (arg0: (props: any, { column }: any) => any[]) => JSX.IntrinsicAttributes & React.ClassAttributes<HTMLDivElement> & React.HTMLAttributes<HTMLDivElement>; render: (arg0: string) => string | number | boolean | {} | React.ReactNodeArray | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactPortal | null | undefined; canResize: any; getResizerProps: () => JSX.IntrinsicAttributes & React.ClassAttributes<HTMLDivElement> & React.HTMLAttributes<HTMLDivElement>; isResizing: any; }) => (
                            // @ts-ignore
                            <div {...column.getHeaderProps(headerProps)} className="th">
                                {column.render('Header')}
                                {/* Use column.getResizerProps to hook up the events correctly */}
                                {column.canResize && (
                                    // @ts-ignore
                                    <div
                                        {...column.getResizerProps()}
                                        className={`resizer ${column.isResizing ? 'isResizing' : ''
                                            }`}
                                    />
                                )}
                            </div>
                        ))}
                    </div>
                ))}
            </div>
            <div className="tbody">
                {rows.map(row => {
                    prepareRow(row)
                    return (
                        // @ts-ignore
                        <div {...row.getRowProps()} className="tr">
                            {row.cells.map(cell => {
                                return (
                                    // @ts-ignore
                                    <div {...cell.getCellProps(cellProps)} className="td">
                                        <span>{cell.column.id === 'tag' ? ('> ').repeat((cell.row.original as unknown as dicomTagRow).depth - 1) : ''}</span>
                                        {cell.render('Cell')}
                                    </div>
                                )
                            })}
                        </div>
                    )
                })}
            </div>
        </div>
    )
}


const {
    parseImageId,
    dataSetCacheManager,
} = cornerstoneWADOImageLoader.wadouri;

interface DicomTagBrowserProps { }

type dicomTagRow = {
    tag: string;
    length: number;
    vr: string;
    keyword: string;
    value: string | number;
    rows: dicomTagRow[] | undefined;
    depth: number;
};

const maxLenght: number = 256;

const tagsToIgnore: string[] = ['XFFFEE00D', 'XFFFEE0DD'];

const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
const useAppDispatch = () => useDispatch<AppDispatch>();

const DicomTagBrowser = (props: DicomTagBrowserProps) => {

    const dispatch = useAppDispatch();

    const { t } = useORTranslation(['NavbarImage']);

    const columns = React.useMemo(
        () => [
            {
                Header: 'Tag',
                accessor: 'tag',
                width: 15,
                minWidth: 10,
            },
            {
                Header: 'VR',
                accessor: 'vr',
                width: 6,
                minWidth: 3,
            },
            {
                Header: t('tagName'),
                accessor: 'keyword',
                width: 33,
                minWidth: 18,
            },
            {
                Header: 'Bytes',
                accessor: 'length',
                width: 9,
                minWidth: 2,
            },
            {
                Header: t('value'),
                accessor: 'value',
                width: 42,
                minWidth: 20,
            },
        ],
        []
    )

    const isDicomTagBrowserVisible: boolean = useAppSelector((state) => state.DicomTagBrowser.isDicomTagBrowserVisible);
    const selectedMatrixIndex: number | undefined = useAppSelector((state) => selectMatrixIndex(state));

    const [tags, setTags] = useState<dicomTagRow[]>([]);
    const [studyDate, setStudyDate] = useState<string>('');
    const [studyTime, setStudyTime] = useState<number>(0);
    const [studyDescription, setStudyDescription] = useState<string>('');
    const [patientId, setPatientId] = useState<string>('');
    const [patientName, setPatientName] = useState<string>('');

    const imageLoadStatus = useAppSelector(state => state.Orders.imageLoadStatus);

    const cornerstoneElements: EnabledElement[] = cornerstone.getEnabledElements();
    const displayElement: EnabledElement | undefined = cornerstoneElements.find((element: EnabledElement) => element.element.id === `${Constants.IMAGE_DISPLAY_GENERIC_ELEMENT_NAME}_${selectedMatrixIndex}`);

    useEffect(() => {
        function getTag(tag: string) {
            var group = tag.substring(1, 5);
            var element = tag.substring(5, 9);
            var tagIndex = ("(" + group + "," + element + ")").toUpperCase();
            var attr = TAG_DICT[tagIndex];
            return attr;
        }

        function isASCII(str: string, extended: boolean) {
            return (extended ? /^[\x00-\xFF]*$/ : /^[\x00-\x7F]*$/).test(str);
        }

        function toHex(buffer: any) {
            return Array.prototype.map.call(buffer, x => x.toString(16).padStart(2, '0')).join('');
        }

        function isStringVr(vr: string): boolean {
            if (vr === 'AT'
                || vr === 'FL'
                || vr === 'FD'
                || vr === 'OB'
                || vr === 'OF'
                || vr === 'OW'
                || vr === 'SI'
                || vr === 'SQ'
                || vr === 'SS'
                || vr === 'UL'
                || vr === 'US'
                || vr === 'OB|OW'
            ) {
                return false;
            }
            return true;
        }

        function getrows(dataSet: any, depth: number): dicomTagRow[] {
            let rows: dicomTagRow[] = [];
            try {
                depth += 1;
                for (var propertyName in dataSet.elements) {
                    const tagName: string = ("(" + propertyName.substring(1, 5) + "," + propertyName.substring(5, 9) + ")").toUpperCase();

                    const propertyNameUpper: string = propertyName.toUpperCase();
                    if (tagsToIgnore.find(tag => tag === propertyNameUpper)) {
                        continue;
                    }

                    var element = dataSet.elements[propertyName];
                    // The output string begins with the element tag, length and VR (if present).  VR is undefined for
                    // implicit transfer syntaxes

                    var tag = getTag(element.tag);
                    const keyword: string = tag?.name ?? (element.tag.substring(1, 5) === '0029' ? 'Unknown Tag & Data' : '');
                    let length: number = -1;
                    let vr: string = '';
                    let stringValue: string = '';

                    if (!element.hadUndefinedLength) {
                        length = element.length;
                    }

                    if (element.vr !== undefined) {
                        vr = element.vr;
                    }
                    else {
                        if (tag !== undefined) {
                            vr = (typeof tag.vr === 'string') ? tag.vr : tag.vr.join(' ');
                        }
                    }
                    var str: string = dataSet.string(propertyName);
                    var stringIsAscii: boolean = isASCII(str, false);

                    if (element.vr === undefined && tag === undefined) {
                        if (element.length === 2) {
                            stringValue = " (" + dataSet.uint16(propertyName) + ")";
                        }
                        else if (element.length === 4) {
                            stringValue = " (" + dataSet.uint32(propertyName) + ")";
                        }

                        // Next we ask the dataset to give us the element's data in string form.  Most elements are
                        // strings but some aren't so we do a quick check to make sure it actually has all ascii
                        // characters so we know it is reasonable to display it.
                        if (stringIsAscii) {
                            // the string will be undefined if the element is present but has no data
                            // (i.e. attribute is of type 2 or 3 ) so we only display the string if it has
                            // data.  Note that the length of the element will be 0 to indicate "no data"
                            // so we don't put anything here for the value in that case.
                            if (str !== undefined) {
                                stringValue = str.substring(0, maxLenght);
                                stringValue += element.length > maxLenght ? '...' : '';
                            }
                        }
                        else {
                            if (element.length !== 2 && element.length !== 4) {
                                const value: Uint8Array = dataSet.byteArray.slice(element.dataOffset, element.dataOffset + Math.min(element.length, maxLenght));
                                stringValue = toHex(value);
                                stringValue += element.length > maxLenght ? '...' : '';
                            }
                        }
                        rows.push({
                            tag: tagName,
                            length, vr, keyword: keyword,
                            value: stringValue, rows: undefined, depth
                        });
                    }
                    else {
                        if (isStringVr(vr)) {
                            if (str !== undefined) {
                                stringValue = str;
                            }
                            rows.push({
                                tag: tagName,
                                length, vr, keyword: keyword,
                                value: stringValue, rows: undefined, depth
                            });
                        }
                        else if (vr === 'SQ') {
                            let subRows: dicomTagRow[] | undefined = undefined;
                            if (element.items) {
                                element.items.forEach((item_element: { dataSet: any; }) => {
                                    if (item_element.dataSet) {
                                        subRows = getrows(item_element.dataSet, depth);
                                    }
                                })
                            }
                            rows.push({
                                tag: tagName,
                                length, vr, keyword: keyword,
                                value: stringValue, rows: subRows, depth
                            });
                        }
                        else {
                            if (vr === 'US') {
                                stringValue = dataSet.uint16(propertyName);
                                for (let i = 1; i < dataSet.elements[propertyName].length / 2; i++) {
                                    stringValue += '\\' + dataSet.uint16(propertyName, i);
                                }
                            }
                            else if (vr === 'SS') {
                                stringValue = dataSet.int16(propertyName);
                                for (let i = 1; i < dataSet.elements[propertyName].length / 2; i++) {
                                    stringValue += '\\' + dataSet.int16(propertyName, i);
                                }
                            }
                            else if (vr === 'UL') {
                                stringValue = dataSet.uint32(propertyName);
                                for (var i = 1; i < dataSet.elements[propertyName].length / 4; i++) {
                                    stringValue += '\\' + dataSet.uint32(propertyName, i);
                                }
                            }
                            else if (vr === 'SL') {
                                stringValue = dataSet.int32(propertyName);
                                for (let i = 1; i < dataSet.elements[propertyName].length / 4; i++) {
                                    stringValue += '\\' + dataSet.int32(propertyName, i);
                                }
                            }
                            else if (vr === 'FD') {
                                stringValue = dataSet.double(propertyName);
                                for (let i = 1; i < dataSet.elements[propertyName].length / 8; i++) {
                                    stringValue += '\\' + dataSet.double(propertyName, i);
                                }
                            }
                            else if (vr === 'FL') {
                                stringValue = dataSet.float(propertyName);
                                for (let i = 1; i < dataSet.elements[propertyName].length / 4; i++) {
                                    stringValue += '\\' + dataSet.float(propertyName, i);
                                }
                            }
                            else if (vr === 'OB' || vr === 'OW' || vr === 'OD' || vr === 'OF' || vr === 'OB|OW') {
                                const value: Uint8Array = dataSet.byteArray.slice(element.dataOffset, element.dataOffset + Math.min(element.length, maxLenght));
                                stringValue = toHex(value);
                                stringValue += element.length > maxLenght ? '...' : '';
                            }
                            else if (vr === 'UN') {
                                const value: Uint8Array = dataSet.byteArray.slice(element.dataOffset, element.dataOffset + Math.min(element.length, maxLenght));
                                stringValue = toHex(value);
                            }
                            else if (vr === 'AT') {
                                let group = dataSet.uint16(propertyName, 0);
                                let groupHexStr = ("0000" + group.toString(16)).substr(-4);
                                let element = dataSet.uint16(propertyName, 1);
                                let elementHexStr = ("0000" + element.toString(16)).substr(-4);
                                stringValue = "x" + groupHexStr + elementHexStr;
                            }
                            else {
                                // If it is some other length and we have no string
                                stringValue = "no display code for VR " + vr + " yet, sorry!";
                            }
                            rows.push({
                                tag: tagName,
                                length, vr, keyword: keyword,
                                value: stringValue, rows: undefined, depth
                            });
                        }
                    }
                }

            } catch (err) {
                dispatch({ type: "Message/setMessage", payload: { timestamp: new Date().toISOString(), severity: messageSeverity.error, text: "get dicom tags failed: " + JSON.stringify(err) } })
            }
            rows.sort((a, b) => (a.tag < b.tag) ? -1 : (b.tag < a.tag) ? 1 : 0);
            return rows;
        }

        function flatenRows(rows: dicomTagRow[], flatenedRows: dicomTagRow[]) {
            rows.forEach(element => {
                flatenedRows.push(element);
                if (element.rows) {
                    flatenRows(element.rows, flatenedRows);
                }
            });
        }

        if (isDicomTagBrowserVisible && displayElement) {
            if (displayElement.image) {
                const imageId: string | undefined = displayElement.image.imageId;
                if (imageId) {
                    const parsedImageId = parseImageId(imageId);
                    if (parsedImageId.scheme === 'wadouri' || parsedImageId.scheme === 'wadors' || parsedImageId.scheme === 'dicomfile') {
                        const generalStudyModule =
                            cornerstone.metaData.get('generalStudyModule', imageId) || {};
                        const { studyDate, studyTime, studyDescription } = generalStudyModule;

                        const patientModule =
                            cornerstone.metaData.get('patientModule', imageId) || {};
                        const { patientId, patientName } = patientModule;

                        setStudyDate(studyDate);
                        setStudyTime(studyTime);
                        setStudyDescription(studyDescription);
                        setPatientId(patientId);
                        setPatientName(patientName)

                        const dataSet = dataSetCacheManager.get(parsedImageId.url);
                        let depdth: number = 0;
                        let flatenedRows: dicomTagRow[] = [];
                        flatenRows(getrows(dataSet, depdth), flatenedRows);
                        setTags(flatenedRows);
                    } else {
                        setTags([]);
                    }
                }
            }
        }
        return () => {
            setTags([]);
        }
    }, [displayElement, imageLoadStatus, isDicomTagBrowserVisible, selectedMatrixIndex, dispatch])


    return (
        <>
            <div className="dicom-tag-browser-Header">
                <span className="Info">
                    <span>{formatPN(patientName)}</span>
                    <span>{patientId}</span>
                    <span>{formatDA(studyDate)} {formatTM(studyTime)}</span>
                    <span>{studyDescription}</span>
                </span>
            </div>
            <div className="dicom-tag-browser-table_wrapper">
                <Table columns={columns} data={tags} />
            </div>
        </>
    );
};

export default DicomTagBrowser;
