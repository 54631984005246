import { useEffect, useRef, useState } from 'react';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { apiSlice, device, deviceSet, property, useGetDeviceWebThingQuery } from '../../apis/apiSlice';
import { AppDispatch, RootState } from '../../store';
import { selectCurrentDeviceProperties, selectDeviceActions, selectDeviceProperties, selectDeviceRunnerActive, selectDeviceRunnerError, selectedDeviceSetId, selectTriggerImageUrl } from './AcquisitionSlice';

import { useORTranslation } from '../Localization/ORLocalization';
import './DeviceWebThing2.scss';

import { getImagePathForCurrentTheme, getLayout, getSelectedWorkitem, selectCurrentOrder } from '../OrderList/OrdersSlice';
import DevicePropReadonly, { getStatusText } from './DevicePropReadonly';
import DeviceProp from './DeviceProp';
import { getTechniqueParameters, selectCurrentActionRef } from './GeneratorTechniqueParametersSlice';
import { selectProcedureSelectionVisibilityState, setProcedure } from '../Procedures/ProcedureSlice';
import { formatISO } from 'date-fns';
import { Constants } from '../../Constants';
import { getCurrentProtocolStepNumber, getDemoMode } from '../ImageDisplay/ImageDisplaySlice';
import ExposureParameters from './ExposureParameters';
import ExposureParameters2 from './ExposureParameters2';
import PatientSizeWeight from './PatientSizeWeight';
import DensityModel from './DensityModel';
import { getColorCode, getColorCode2, getValueForProperty } from './Device';
import CommonDialog from '../Utils/CommonDialog';
import ErrorMessage from './ErrorMessage';

interface DeviceWebThing2Props {
	device: device | deviceSet;
	deviceSetId: number;
	orderId: string;
	deviceSet: any;
};

const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
const useAppDispatch = () => useDispatch<AppDispatch>();

const DeviceWebThing2 = (props: DeviceWebThing2Props) => {
	const dispatch = useAppDispatch();

	const { t } = useORTranslation(['Acquisition']);
	const propertyDefs: [string, property][] = JSON.parse(useAppSelector((state) => selectDeviceProperties(state, props.device.config.endpoint)));
	const actions: [string, any][] = JSON.parse(useAppSelector((state) => selectDeviceActions(state, props.device.config.endpoint)));
	const isDeviceRunnerActive: boolean = useAppSelector((state) => selectDeviceRunnerActive(state));
	const deviceRunnerError: string = useAppSelector((state) => selectDeviceRunnerError(state));
	const currentWorkitemAsString: string | undefined = useAppSelector((state) => getSelectedWorkitem(state));
	const currentWorkitem = currentWorkitemAsString ? JSON.parse(currentWorkitemAsString) : undefined;
	const currentDeviceSetId: number = useAppSelector((state) => selectedDeviceSetId(state));
	const procedureSelectionVisibilityState: number = useAppSelector((state) => selectProcedureSelectionVisibilityState(state));
	const actionRef: string | undefined = useAppSelector((state) => selectCurrentActionRef(state));
	const currentProtocolSettingsStepNumber: number = useAppSelector((state) => getCurrentProtocolStepNumber(state, Constants.ACQUISITION_STEP_TYPE, 'scheduled', 'rejected'));
	const currentOrderId: string | undefined = useAppSelector((state) => selectCurrentOrder(state));
	const layout: any = useAppSelector((state) => getLayout(state));
	const imagePathForCurrentTheme: string = useAppSelector((state) => getImagePathForCurrentTheme(state));
	const currentPropertyValues: [string, any][] = JSON.parse(useAppSelector((state) => selectCurrentDeviceProperties(state, props.device.config.endpoint)));
	const triggerImageUrl: string | undefined = useAppSelector((state) => selectTriggerImageUrl(state, props.device?.config?.endpoint));
	const demoMode: boolean = useAppSelector((state) => getDemoMode(state));

	const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

	//const activeEventSubscribers: number[] = useAppSelector((state) => selectActiveEventSubscribers(state));

	const currentWorkitemRef = useRef<any>();
	currentWorkitemRef.current = currentWorkitem;

	const currentProtocolSettingsStepNumberRef = useRef<number>();
	currentProtocolSettingsStepNumberRef.current = currentProtocolSettingsStepNumber;

	let propertyOrder = { status: 1, battery: 2, connection: 3, calibrationGui: 4, active: -1, focus: -1, voltage: 5, exposure: 6, sensitivityFactor: -1, current: 7, firmware: -1, serialNumber: -1, manufacturer: -1, model: -1};

	if (layout?.propertyOrder) {
		propertyOrder = layout?.propertyOrder;
	}

	useEffect(() => {
		if (!deviceRunnerError) {
			dispatch(apiSlice.util.invalidateTags([{ type: 'DeviceWebThing', id: props.device.config.endpoint }]));
		}
	}, [dispatch, deviceRunnerError]);


	const {
		data,
		isSuccess,
	}: { data: any, isSuccess: boolean } = useGetDeviceWebThingQuery({ endpoint: props.device.config.endpoint, category: props.device?.category, id: props.device?.id }, {
		refetchOnMountOrArgChange: false,
		skip: isDeviceRunnerActive === false || deviceRunnerError !== '',
	});

	useEffect(() => {
		if (isSuccess && props.device.category === 'GENERATOR' && data?.deviceWebThingProps?.eventSubscriptionActive) {
			if (procedureSelectionVisibilityState > 1 && props.deviceSetId === currentDeviceSetId) {
				const actionRef = currentWorkitem?.id + "_" + formatISO(new Date());
				dispatch({ type: "GeneratorTechniqueParameters/setCurrentActionRef", payload: actionRef });
				dispatch({ type: "GeneratorTechniqueParameters/setValueValidationStatus", payload: 1 });
				dispatch(getTechniqueParameters({
					voltage: currentWorkitem?.protocol?.steps[currentProtocolSettingsStepNumber]?.scheduled?.generator?.kv,
					exposure: (currentWorkitem?.protocol?.steps[currentProtocolSettingsStepNumber]?.scheduled?.setup?.measuringChamber === 0) ? currentWorkitem?.protocol?.steps[currentProtocolSettingsStepNumber]?.scheduled?.generator?.masWithoutAEC : currentWorkitem?.protocol?.steps[currentProtocolSettingsStepNumber]?.scheduled?.generator?.masWithAEC,
					generatorEndpoint: props.device.config.endpoint, actionRef
				}));
			}
		}
	}, [currentWorkitem?.id, currentWorkitem?.details?.procedureCode,
		currentDeviceSetId, isSuccess, data?.deviceWebThingProps?.eventSubscriptionActive, currentWorkitem?.protocol?.steps[currentProtocolSettingsStepNumber]?.scheduled?.setup?.measuringChamber]);


	useEffect(() => {
		//console.log(actionRef + " " + data?.deviceWebThingProps?.setTechniqueParametersResult?.data?.actionRef);
		if (isSuccess && props.device.category === 'GENERATOR') {
			if (data?.deviceWebThingProps?.eventSubscriptionActive && data?.deviceWebThingProps?.setTechniqueParametersResult?.data?.actionRef === actionRef) {
				dispatch({ type: "GeneratorTechniqueParameters/setCurrentActionRef", payload: '' });
				if (data?.deviceWebThingProps?.setTechniqueParametersResult?.data?.success) {
					dispatch({ type: "GeneratorTechniqueParameters/setValueValidationStatus", payload: 2 });

					const currentProtocolStep = currentWorkitem?.protocol?.steps[currentProtocolSettingsStepNumber];
					const currentExposure = currentProtocolStep.scheduled?.setup?.measuringChamber === 0 ?
						currentProtocolStep.scheduled.generator.masWithoutAEC : currentProtocolStep.scheduled.generator.masWithAEC;

					if (currentExposure !== data?.deviceWebThingProps?.exposure ||
						currentProtocolStep.scheduled.generator.kv !== data?.deviceWebThingProps?.voltage) {

						const newProtocolStep = structuredClone(currentProtocolStep);

						if (newProtocolStep?.scheduled?.generator) {
							if (newProtocolStep.scheduled?.setup?.measuringChamber === 0) {
								newProtocolStep.scheduled.generator.masWithoutAEC = data?.deviceWebThingProps?.exposure;
							} else {
								newProtocolStep.scheduled.generator.masWithAEC = data?.deviceWebThingProps?.exposure;
							}
							newProtocolStep.scheduled.generator.kv = data?.deviceWebThingProps?.voltage;

							//console.log(newProtocolStep);
							dispatch(apiSlice.endpoints.updateWorkitemProtocolStep.initiate(
								{
									workitemId: currentWorkitem?.id,
									stepNumber: currentProtocolSettingsStepNumber,
									body: {
										protocol: newProtocolStep
									}
								}
							));

							dispatch(
								apiSlice.util.updateQueryData('getStudyWithWorkitems', currentOrderId, (data: any) => {
									const workitemList = data?.workitems?.map((workitem: any, i: number) => workitem?.data?.id);
									if (currentWorkitem?.id && workitemList && workitemList.includes(currentWorkitem?.id)) {
										const tmpWorkitem = data?.workitems?.find((workitem: any) => workitem.data?.id === currentWorkitem?.id);
										if (tmpWorkitem?.data) {
											tmpWorkitem.data.protocol.steps[currentProtocolSettingsStepNumber] = newProtocolStep;
										}
									}
									return data;
								})
							);
						}
					}
				} else {
					dispatch({ type: "GeneratorTechniqueParameters/setValueValidationStatus", payload: 3 });
				}
			}
		}
	}, [isSuccess, data?.deviceWebThingProps, data?.deviceWebThingProps?.eventSubscriptionActive]);

	function getProperty(property: [string, property], i: number) {
		//console.log(property);
		if (property[1].readOnly === true) {
			const set_technique_parameters = actions.find((action: any) => action[0] === 'setTechniqueParameters');
			//console.log(set_technique_parameters);
			if (set_technique_parameters && set_technique_parameters[1].input?.properties[property[0]] && property[0] !== 'exposureTime' && property[0] !== 'current') {
				return <DeviceProp key={i.toString()} device={props.device} property={property} endpoint={props.device.config.endpoint} techniqueParameters={set_technique_parameters[1]} isfirstDefaultWidget={firstDefaultWidget(property) === i} />
			} else {
				return <DevicePropReadonly device={props.device} property={property} key={i.toString()} isfirstDefaultWidget={firstDefaultWidget(property) === i} />
			}
		} else {
			return <DeviceProp device={props.device} property={property} endpoint={props.device.config.endpoint} key={i.toString()} isfirstDefaultWidget={firstDefaultWidget(property) === i} />
		}
	}

	function isSpecialWidget(property: [string, property]): boolean {
		return ["battery", "connection", "status", "manufacturerTool", "calibrationGui", "pulseTime", "softwareTriggerType", "softwareTriggerState", "active", "focus"].includes(property[0]);
	}

	function firstDefaultWidget(property: [string, property]): number {
		let ret: number = 0;
		if (propertyDefs) {
			for (let i = 0; i < propertyDefs.filter((property: [string, property]) => propertyOrder[property[0] as keyof typeof propertyOrder] !== -1).sort(compareFn).length; i++) {
				if (!isSpecialWidget(propertyDefs.filter((property: [string, property]) => propertyOrder[property[0] as keyof typeof propertyOrder] !== -1).sort(compareFn)[i])) {
					ret = i;
					break;
				}
			}
		}
		return ret;
	}

	function compareFn(item1: [string, property], item2: [string, property]) {

		const orderItem1: number = propertyOrder[item1[0] as keyof typeof propertyOrder] ?? 999;

		const orderItem2: number = propertyOrder[item2[0] as keyof typeof propertyOrder] ?? 999;

		if (orderItem1 > orderItem2) {
			return 1;
		} else if (orderItem2 > orderItem1) {
			return -1;
		} else {
			return 0;
		}
	}

	const onResetClicked = async () => {
		await dispatch(setProcedure({ procedure: { procedureCode: currentWorkitemRef?.current?.details?.procedureCode, procedureName: currentWorkitemRef?.current?.details?.procedureName, projection: currentWorkitemRef?.current?.details?.projection }, orderId: props.orderId, }));
		const actionRef = currentWorkitem?.id + "_" + formatISO(new Date());
		//dispatch({ type: "GeneratorTechniqueParameters/setCurrentActionRef", payload: actionRef });
		dispatch(getTechniqueParameters({
			voltage: currentWorkitemRef?.current?.protocol?.steps[currentProtocolSettingsStepNumberRef.current ?? 0]?.scheduled?.generator?.kv,
			exposure: (currentWorkitemRef?.current?.protocol?.steps[currentProtocolSettingsStepNumberRef.current ?? 0]?.scheduled?.setup?.measuringChamber === 0) ? currentWorkitemRef?.current?.protocol?.steps[currentProtocolSettingsStepNumberRef.current ?? 0]?.scheduled?.generator?.masWithoutAEC : currentWorkitemRef?.current?.protocol?.steps[currentProtocolSettingsStepNumberRef.current ?? 0]?.scheduled?.generator?.masWithAEC,
			generatorEndpoint: props.device.config.endpoint, actionRef
		}));
	}

	const triggerImage = () => {
		if (demoMode) {
			dispatch(apiSlice.endpoints.postWebThingAction.initiate({ url: triggerImageUrl, body: { triggerImage: { input: {} } } }));
		}
	}

	const onErrorClick = () => {
		if (getValueForProperty('status', currentPropertyValues) === 'Error' || getValueForProperty('status', currentPropertyValues) === 'Disconnected') {
			setIsDialogOpen(true);
		}
	}

	const getGeneratorStatusIcon = (state: string) => {

		switch (state) {
			case 'Idle':
				return <svg width="64" height="64" viewBox="0 0 64 64" fill="none">
					<rect width="64" height="64" rx="6" fill="#7C7C7C" />
					<path d="M14.2227 36.444L25.7782 46.2218L48.8893 17.7773" stroke="white" strokeWidth="3.5" strokeLinecap="round" strokeLinejoin="round" />
				</svg>

			case 'Busy':
			case 'Init':
			case 'ShuttingDown':
				return <svg width="64" height="64" viewBox="0 0 64 64" fill="none">
					<rect width="64" height="64" rx="4" fill="#7C7C7C" />
					<path d="M43.96 19.1804C44.2055 17.9279 44.2717 16.6329 44.4262 15.3076L41.6376 15.2227C41.8638 16.5783 41.6514 17.8884 41.3259 19.1804C40.8101 21.2366 39.7151 22.9046 38.3801 24.4058C37.2272 25.7038 36.0825 27.0109 34.9296 28.3119C33.0706 30.4106 32.9685 33.9013 34.9544 36.097L35.0862 36.243C36.554 37.8684 38.0242 39.4965 39.4007 41.2132C40.9811 43.1815 41.8059 45.5227 41.7976 48.1794C41.7948 48.4827 41.759 48.7859 41.7369 49.1196L44.2524 49.1529V48.6525C44.3241 45.0678 43.2898 41.9229 41.097 39.2723C39.6379 37.5072 38.0905 35.8331 36.6231 34.0772C35.6881 32.9581 35.6964 31.4842 36.6425 30.3621C37.7954 28.9943 39.009 27.6872 40.2006 26.3558C42.0182 24.3209 43.4084 22.0008 43.96 19.1804Z" fill="white" stroke="white" strokeWidth="0.3" strokeMiterlimit="10" />
					<path d="M19.2353 19.1804C18.9898 17.9279 18.9236 16.6329 18.7691 15.3076L21.5577 15.2227C21.3315 16.5783 21.5439 17.8884 21.8694 19.1804C22.3852 21.2366 23.4802 22.9046 24.8152 24.4058C25.9681 25.7038 27.1128 27.0109 28.2657 28.3119C30.1248 30.4106 30.2268 33.9013 28.2409 36.097L28.1091 36.243C26.6413 37.8684 25.1712 39.4965 23.7946 41.2132C22.2142 43.1815 21.3895 45.5227 21.3977 48.1794C21.4005 48.4827 21.4363 48.7859 21.4584 49.1196L18.9429 49.1529V48.6525C18.8712 45.0678 19.9055 41.9229 22.0983 39.2723C23.5574 37.5072 25.1048 35.8331 26.5722 34.0772C27.5072 32.9581 27.4989 31.4842 26.5529 30.3621C25.3999 28.9943 24.1863 27.6872 22.9947 26.3558C21.1771 24.3209 19.7869 22.0008 19.2353 19.1804Z" fill="white" stroke="white" strokeWidth="0.3" strokeMiterlimit="10" />
					<rect x="14.2227" y="48.7969" width="33.7778" height="2.93206" rx="1.46603" fill="white" />
					<rect x="14.2227" y="12.4453" width="33.7778" height="2.93206" rx="1.46603" fill="white" />
				</svg>

			case 'Sleep':
				return <svg width="64" height="64" viewBox="0 0 64 64" fill="none">
					<rect width="64" height="64" rx="4" fill="#7C7C7C" />
					<path d="M32.7926 32.7764C31.1609 30.0951 30.3067 27.0242 30.319 23.8839C30.3313 20.7437 31.2097 17.6493 32.8626 14.9235C32.9496 14.7795 32.9911 14.6143 32.9818 14.449C32.9725 14.2837 32.9128 14.1257 32.8104 13.995C32.7079 13.8644 32.5672 13.7671 32.4063 13.7154C32.2454 13.6637 32.0714 13.66 31.9066 13.7048C29.2159 14.4418 26.7259 15.7712 24.6312 17.5891C22.5365 19.407 20.8939 21.6642 19.8317 24.1841C18.7696 26.704 18.3167 29.4184 18.5084 32.1152C18.7001 34.8119 19.5313 37.4179 20.937 39.7294C22.3427 42.041 24.2848 43.9954 26.6114 45.44C28.9381 46.8846 31.5862 47.7801 34.349 48.0567C37.1117 48.3332 39.9141 47.9833 42.5371 47.0341C45.1601 46.085 47.5326 44.5625 49.4693 42.5855C49.5899 42.4644 49.6712 42.3116 49.7025 42.1467C49.7337 41.9819 49.7136 41.8125 49.6448 41.6606C49.5759 41.5087 49.4614 41.3812 49.316 41.2945C49.1707 41.2079 49.0012 41.1661 48.8295 41.1745C45.6029 41.3118 42.4129 40.605 39.5922 39.1279C36.7715 37.6507 34.4233 35.4573 32.7926 32.7764Z" stroke="white" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
				</svg>

			case 'PreparingExposure':
				return <svg width="64" height="64" viewBox="0 0 64 64" fill="none">
					<rect width="64" height="64" rx="6" fill="#D6B663" />
					<path d="M14.2227 36.444L25.7782 46.2218L48.8893 17.7773" stroke="white" strokeWidth="3.5" strokeLinecap="round" strokeLinejoin="round" />
				</svg>

			case 'ReadyForExposure':
				return <svg width="64" height="64" viewBox="0 0 64 64" fill="none">
					<rect width="64" height="64" rx="6" fill="#66A960" />
					<path d="M14.2227 36.444L25.7782 46.2218L48.8893 17.7773" stroke="white" strokeWidth="3.5" strokeLinecap="round" strokeLinejoin="round" />
				</svg>

			case 'Exposure':
				return <svg width="64" height="64" viewBox="0 0 64 64" fill="none">
				<rect width="62.2703" height="64" rx="4" fill="#FFFF00"/>
				<path d="M60.0007 0H5.73047C3.52133 0 1.73047 1.79086 1.73047 4V60C1.73047 62.2091 3.52133 64 5.73047 64H60.0007C62.2099 64 64.0007 62.2091 64.0007 60V4C64.0007 1.79086 62.2099 0 60.0007 0Z" fill="#FFFF00"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M28.9789 38.8164L19.8945 54.9864C28.1634 59.8931 37.5716 59.8931 45.8405 54.9864L36.7562 38.8164C36.696 38.8522 36.6389 38.8923 36.5778 38.9264C36.3572 39.0496 36.1295 39.1618 35.8967 39.2631C35.6639 39.3643 35.4236 39.4551 35.1799 39.5331C34.9363 39.611 34.6873 39.6764 34.434 39.7297C34.1807 39.7831 33.9237 39.8224 33.6621 39.8497C33.4005 39.8771 33.136 39.8931 32.8675 39.8931C32.599 39.8931 32.3345 39.8771 32.0729 39.8497C31.8113 39.8224 31.5543 39.7831 31.301 39.7297C31.0477 39.6764 30.7988 39.611 30.5551 39.5331C30.3114 39.4551 30.0711 39.3643 29.8383 39.2631C29.6055 39.1618 29.3778 39.0496 29.1572 38.9264C29.0961 38.8923 29.039 38.8522 28.9789 38.8164Z" fill="black"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M19.8909 8.79297C11.622 13.6996 6.91797 22.0763 6.91797 31.8896H25.0801C25.0801 31.6136 25.0925 31.3385 25.119 31.0696C25.1456 30.8008 25.1872 30.5366 25.239 30.2763C25.2909 30.016 25.3546 29.7601 25.4304 29.5096C25.5062 29.2592 25.5946 29.0156 25.6931 28.7763C25.7916 28.5371 25.9008 28.303 26.0207 28.0763C26.1405 27.8496 26.2699 27.6291 26.4099 27.4163C26.5498 27.2035 26.6988 26.9972 26.8574 26.7996C27.0161 26.6021 27.184 26.414 27.3601 26.233C27.5362 26.052 27.7225 25.8794 27.9147 25.7163C28.107 25.5532 28.3044 25.4001 28.5115 25.2563C28.6608 25.1526 28.8188 25.0594 28.9753 24.9663L19.8909 8.79297Z" fill="black"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M45.8421 8.79297L36.7578 24.9663C36.9143 25.0594 37.0722 25.1526 37.2216 25.2563C37.4287 25.4001 37.6261 25.5532 37.8184 25.7163C38.0106 25.8794 38.1968 26.052 38.3729 26.233C38.5491 26.414 38.717 26.6021 38.8757 26.7996C39.0343 26.9972 39.1833 27.2035 39.3232 27.4163C39.4631 27.6291 39.5925 27.8496 39.7124 28.0763C39.8323 28.303 39.9415 28.5371 40.04 28.7763C40.1385 29.0156 40.2268 29.2592 40.3027 29.5096C40.3785 29.7601 40.4422 30.016 40.494 30.2763C40.5459 30.5366 40.5874 30.8008 40.614 31.0696C40.6406 31.3385 40.6529 31.6136 40.6529 31.8896H58.8151C58.8151 22.0763 54.111 13.6996 45.8421 8.79297Z" fill="black"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M38.0542 31.8919C38.0542 33.3064 37.5074 34.663 36.5343 35.6632C35.5611 36.6634 34.2412 37.2253 32.865 37.2253C31.4887 37.2253 30.1688 36.6634 29.1957 35.6632C28.2225 34.663 27.6758 33.3064 27.6758 31.8919C27.6758 30.4774 28.2225 29.1209 29.1957 28.1207C30.1688 27.1205 31.4887 26.5586 32.865 26.5586C34.2412 26.5586 35.5611 27.1205 36.5343 28.1207C37.5074 29.1209 38.0542 30.4774 38.0542 31.8919Z" fill="black"/>
				</svg>

			case 'Error':
				return <svg width="64" height="64" viewBox="0 0 64 64" fill="none" >
					<rect width="64" height="64" rx="4" fill="#C54B4B" />
					<path d="M34.8434 15.8029C34.6306 15.4177 34.3185 15.0965 33.9394 14.8729C33.5604 14.6492 33.1284 14.5312 32.6883 14.5312C32.2482 14.5312 31.8161 14.6492 31.4371 14.8729C31.058 15.0965 30.7459 15.4177 30.5331 15.8029L14.5293 45.3483C14.3224 45.7231 14.2168 46.1454 14.2229 46.5736C14.229 47.0017 14.3467 47.4208 14.5643 47.7896C14.7819 48.1583 15.092 48.464 15.4638 48.6763C15.8356 48.8886 16.2563 49.0004 16.6845 49.0004H48.692C49.1202 49.0004 49.5409 48.8886 49.9128 48.6763C50.2846 48.464 50.5946 48.1583 50.8122 47.7896C51.0298 47.4208 51.1475 47.0017 51.1536 46.5736C51.1597 46.1454 51.0541 45.7231 50.8472 45.3483L34.8434 15.8029Z" fill="#C54B4B" stroke="white" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
					<path d="M32.6875 36.6889V25.6094V36.6889Z" fill="white" />
					<path d="M32.6875 36.6889V25.6094" stroke="white" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
					<path d="M32.6937 42.8443C32.5304 42.8443 32.3738 42.7795 32.2584 42.6641C32.143 42.5486 32.0781 42.3921 32.0781 42.2288C32.0781 42.0656 32.143 41.909 32.2584 41.7936C32.3738 41.6781 32.5304 41.6133 32.6937 41.6133" fill="white" />
					<path d="M32.6937 42.8443C32.5304 42.8443 32.3738 42.7795 32.2584 42.6641C32.143 42.5486 32.0781 42.3921 32.0781 42.2288C32.0781 42.0656 32.143 41.909 32.2584 41.7936C32.3738 41.6781 32.5304 41.6133 32.6937 41.6133" stroke="white" strokeWidth="2.5" />
					<path d="M32.6875 42.8443C32.8507 42.8443 33.0073 42.7795 33.1227 42.6641C33.2382 42.5486 33.303 42.3921 33.303 42.2288C33.303 42.0656 33.2382 41.909 33.1227 41.7936C33.0073 41.6781 32.8507 41.6133 32.6875 41.6133" fill="white" />
					<path d="M32.6875 42.8443C32.8507 42.8443 33.0073 42.7795 33.1227 42.6641C33.2382 42.5486 33.303 42.3921 33.303 42.2288C33.303 42.0656 33.2382 41.909 33.1227 41.7936C33.0073 41.6781 32.8507 41.6133 32.6875 41.6133" stroke="white" strokeWidth="2.5" />
				</svg>

			case 'Disconnected':
				return <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
					<rect width="64" height="64" rx="4" fill="#C54B4B" />
					<path d="M24.3715 24.7574C23.2991 25.8296 22.4639 27.1153 21.9201 28.531C21.3763 29.9467 21.1361 31.4609 21.215 32.9754C21.2939 34.4899 21.6901 35.9709 22.378 37.3225C23.0659 38.674 24.0301 39.8659 25.2082 40.821C27.4077 42.4844 30.1414 43.2824 32.8904 43.0635C35.6394 42.8445 38.2124 41.6239 40.1209 39.6334L42.7451 37.0092C43.151 36.6033 43.3791 36.0527 43.3791 35.4787C43.3791 34.9047 43.151 34.3542 42.7451 33.9483L30.5015 21.7046C30.0956 21.2987 29.5451 21.0707 28.9711 21.0707C28.397 21.0707 27.8465 21.2987 27.4406 21.7046L24.3715 24.7574Z" fill="#C54B4B" stroke="white" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
					<path d="M43.4118 31.9242L49.7852 25.5508" stroke="white" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
					<path d="M32.7594 21.264L39.1328 14.8906" stroke="white" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
					<path d="M23.2148 40.5391L16.2003 47.5536" stroke="white" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
					<rect x="26.4414" y="16.2364" width="31.0984" height="1.73354" rx="0.86677" transform="rotate(45 26.4414 16.2364)" fill="white" stroke="white" strokeWidth="1.5" />
				</svg>

			default:
				return <svg width="12" height="22" viewBox="0 0 12 22" fill="none">
					<path d="M10.5273 3.11738L10.5273 9.4695C10.5273 9.4695 10.5273 11.5869 8.40997 11.5869L3.11653 11.5869C3.11653 11.5869 0.99915 11.5869 0.99915 9.4695L0.99915 3.11738C0.99915 3.11738 0.99915 1 3.11653 1L8.40997 1C8.40997 1 10.5273 1 10.5273 3.11738Z" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
					<path d="M3.64491 13.7045L2.44012 16.1148C2.39983 16.1955 2.38083 16.2851 2.38493 16.3753C2.38902 16.4654 2.41608 16.5529 2.46352 16.6296C2.51096 16.7064 2.57722 16.7697 2.65601 16.8136C2.7348 16.8575 2.82351 16.8806 2.91371 16.8806L8.61157 16.8806C8.70178 16.8806 8.79051 16.8576 8.86932 16.8137C8.94812 16.7698 9.01438 16.7064 9.06178 16.6297C9.10918 16.5529 9.13615 16.4653 9.14011 16.3752C9.14408 16.2851 9.12492 16.1954 9.08445 16.1148L7.87966 13.7045" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
					<path d="M7.87681 19.412L9.46484 21" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
					<path d="M3.64063 19.412L2.05259 21" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
					<path className="statusIndicator" id={getColorCode(props.device.category, getValueForProperty('status', currentPropertyValues)?.status_code).toString().toUpperCase()} d="M8.82422 4.13073L8.82422 8.30465C8.82422 8.30465 8.82422 9.69595 7.47156 9.69595L4.08992 9.69595C4.08992 9.69595 2.73726 9.69595 2.73726 8.30465L2.73726 4.13073C2.73726 4.13073 2.73726 2.73943 4.08992 2.73943L7.47156 2.73943C7.47156 2.73943 8.82422 2.73943 8.82422 4.13073Z" fill="#D76161" />
				</svg>
		}
	}

	return (
		<div className="deviveWebThingPanel">
			{isSuccess && data ?
				<div className="deviveWebThingWrapper" >
					<div className="deviveWebThing" onClick={(evt) => evt.stopPropagation()}>
						{props.device.category !== 'SET' ?
							<>
								<div className="deviveWebThingHeader">
									<h1>
										<label className="deviveWebThingHeaderLabel1">{`${t(props.device.category)}:`}&nbsp;</label>
										<label className="deviveWebThingHeaderLabel2">{`${props.device.title}`}</label>
									</h1>
									<div className="deviveWebThingHeaderButtons">
										{propertyDefs?.find((property: [string, property]) => property[0] === 'battery') !== undefined ?
											<div className="deviveProperties_header">
												{getProperty(propertyDefs?.find((property: [string, property]) => property[0] === 'battery')!, -1)}
											</div>
											: null}
										{propertyDefs?.find((property: [string, property]) => property[0] === 'connection') !== undefined ?
											<div className="deviveProperties_header">
												{getProperty(propertyDefs?.find((property: [string, property]) => property[0] === 'connection')!, -1)}
											</div>
											: null}
										<button className="settingsButton venaDefaultButton">
											<img
												src={`${imagePathForCurrentTheme}settings.svg`}
												onError={(event: any) => { event.target.src = "/images/settings.svg"; event.onerror = null }}
												alt="settings.svg" />
										</button>
									</div>

								</div>
								<hr className="hruleDeviceWebThing" />

							</>
							: null}

						<div className="deviveProperties">
							{props.device?.category === 'GENERATOR' ?
								<ExposureParameters2 orderId={props.orderId} deviceSet={props?.deviceSet} device={props.device} />
								: null}
							<div className="devivePropertiesPanel">
								{propertyDefs?.filter((property: [string, property]) => propertyOrder[property[0] as keyof typeof propertyOrder] !== -1).sort(compareFn)
									.map((property: [string, property], i: number) => (
										getProperty(property, i)
									))}
							</div>

							{props.device?.category === 'GENERATOR' ?
								<>
									<div className="patientSizeWeightDiv">
										<div className="hruleDeviceWebThing2" />
										<div className="patientSizeWeightPointsPanel">
											<div className="patientSizeWeightPoints">
												<PatientSizeWeight orderId={props.orderId} deviceSet={props?.deviceSet} device={props.device} />
												<DensityModel orderId={props.orderId} deviceSet={props?.deviceSet} device={props.device} />
											</div>
										</div>
									</div>
									<div className="statusMessageDivWrapper">
										<div className="statusMessageDiv">
											<div className="statusIcon">
												{getGeneratorStatusIcon(getValueForProperty('status', currentPropertyValues))}
											</div>
											<div className="statusLabelWrapper">
												<label className="statusLabel" onClick={() => onErrorClick()}
													id={getColorCode2(getValueForProperty('status', currentPropertyValues))?.toString()}>{t(getStatusText(props.device.category, currentPropertyValues))}
												</label>
											</div>
											<div className="resetButtonWrapper">
												<button className="resetButton" onClick={onResetClicked}>
													<img
														src={`${imagePathForCurrentTheme}reset.svg`}
														onError={(event: any) => { event.target.src = "/images/reset.svg"; event.onerror = null }}
														alt="reset.svg" />
												</button>
											</div>
										</div>
									</div>
								</>
								: null}


							{props.device?.category === 'DETECTOR' ?
								<div className="statusDivWrapper">
									<div className="statusDiv">
										<div className="statusIcon" onClick={() => triggerImage()}>
											<svg id={getColorCode(props.device.category, getValueForProperty('status', currentPropertyValues)?.status_code).toString().toUpperCase()}
												width="14" height="14" viewBox="0 0 14 14" >
												<g >
													<circle cx="7" cy="7" r="7" />
												</g>

											</svg>
										</div>
										<div className="statusLabelWrapper">
											<label className="statusLabel" id={getColorCode(props.device.category, getValueForProperty('status', currentPropertyValues)?.status_code).toString().toUpperCase()}>
												{t(getStatusText(props.device.category, currentPropertyValues))}</label>
										</div>

									</div>
								</div>
								: null}

							{props.device?.category === 'GENERATOR' ?
								<ExposureParameters orderId={props.orderId} deviceSet={props?.deviceSet} />
								: null}
						</div>

					</div>

				</div>
				:
				null
			}
			<CommonDialog onOk={() => setIsDialogOpen(false)} onClose={() => setIsDialogOpen(false)} title={'Error decription'} visible={isDialogOpen} okText={t('Ok', { ns: 'common' })} cancelText=""
				childProps={{}}>
				<ErrorMessage message={getValueForProperty('lastError', currentPropertyValues)?.message} />
			</CommonDialog>
		</div>
	);
}

export default DeviceWebThing2;
