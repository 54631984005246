import React, { useEffect, useState } from "react";
import * as cornerstoneTools from "cornerstone-tools";
import cornerstone, { EnabledElement } from "cornerstone-core";
import { TypedUseSelectorHook, useSelector } from "react-redux";
import { RootState } from "../../../store";
import {  selectMatrixIndex } from "../ImageDisplaySlice";
import { Constants } from "../../../Constants";
// TODO check following import
import './Tools.scss';

type ToolButtonImageProps = {
	icon: string;
	name: string;
	cssclass: string;
	toolName: string;
};

const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

const ToolButtonImage = (props: ToolButtonImageProps) => {

	const selectedMatrixIndex: number | undefined = useAppSelector((state) => selectMatrixIndex(state));

	const [icon, setIcon] = useState<string>(props.icon);

	useEffect(() => {

		const onMeasureCompleted = (ev: any) => {
			// some events come without toolName set, see if there is a name in the respective toolState
			let toolName = undefined;
			if (ev.detail.measurementData)
				toolName = ev.detail.measurementData.toolName;
			if (ev.detail.toolName)
				toolName = ev.detail.toolName;

			if(props.toolName === toolName && ev.detail.newIcon !== undefined)
				setIcon(ev.detail.newIcon);				
		};

		const cornerstoneElements: EnabledElement[] = cornerstone.getEnabledElements();
		if (selectedMatrixIndex !== undefined && selectedMatrixIndex >= 0) {
			const element: EnabledElement | undefined = cornerstoneElements.find(
				(element: EnabledElement) => element.element.id === `${Constants.IMAGE_DISPLAY_GENERIC_ELEMENT_NAME}_${selectedMatrixIndex}`
			);
			if (element && element.element) {
				element.element?.addEventListener(
					cornerstoneTools.EVENTS.MEASUREMENT_COMPLETED,
					onMeasureCompleted
				);
			}
		}

		return () => {
			const cornerstoneElements: EnabledElement[] = cornerstone.getEnabledElements();
			if (selectedMatrixIndex !== undefined && selectedMatrixIndex >= 0) {
				const element: EnabledElement | undefined = cornerstoneElements.find(
					(element: EnabledElement) => element.element.id === `${Constants.IMAGE_DISPLAY_GENERIC_ELEMENT_NAME}_${selectedMatrixIndex}`
				);

				if (element && element.element) {
					element.element?.removeEventListener(
						cornerstoneTools.EVENTS.MEASUREMENT_COMPLETED,
						onMeasureCompleted
					);
				}
			}
		}
	}, [props.toolName, selectedMatrixIndex]);

	return (
		<img src={icon} alt={props.name} className={props.cssclass} />
	);
};

export default ToolButtonImage;
