//import { useEffect, useState } from 'react';
import { TypedUseSelectorHook, useSelector } from 'react-redux';
import { deviceSet, deviceWebThing } from '../../apis/apiSlice';
//import { selectDeviceRunnerError, selectSoftwareTriggerState } from './AcquisitionSlice';
import DeviceWebThing from './DeviceWebThing';
import AkquisitionProgress from './AkquisitionProgress';
import { RootState } from '../../store';
import { getLayout } from '../OrderList/OrdersSlice';

interface DeviceWebThingProps {
	deviceSet: deviceSet | undefined;
	deviceSetWebThing: deviceWebThing  | undefined;
	colorCode: number;
};

const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
//const useAppDispatch = () => useDispatch<AppDispatch>();


const DeviceSet = (props: DeviceWebThingProps) => {
	//const dispatch = useAppDispatch();

	/* const [softwareTriggerUrl, setSoftwareTriggerUrl] = useState<string>('');
	const [cancelSoftwareTriggerUrl, setCancelSoftwareTriggerUrl] = useState<string>('');
	const [acquisitionEXECUTED, setAcquisitionEXECUTED] = useState<boolean>(false);

	const actions: [string, any][] | undefined = props?.deviceSetWebThing?.actions ?
		Object.entries(props?.deviceSetWebThing?.actions) : undefined;

	const deviceRunnerError: string = useAppSelector((state) => selectDeviceRunnerError(state));

	//const currentSoftwareTriggerState = currentPropertyValues?.find((property: [string, any]) => property[0] === 'softwareTriggerState');
	const currentSoftwareTriggerState: string = useAppSelector((state) => selectSoftwareTriggerState(state));
 */
	/* const onExposeClick = (evt: React.MouseEvent<Element>) => {
		dispatch({ type: 'Acquisition/setIsAcquistionOngoing', payload: true });
		if (softwareTriggerUrl && softwareTriggerUrl.length > 0) {
			dispatch(apiSlice.endpoints.postWebThingAction.initiate({ url: softwareTriggerUrl, body: { softwareTrigger: { input: {} } } }));
		}
	};

	const onExposeCancelClick = (evt: React.MouseEvent<Element>) => {
		dispatch({ type: 'Acquisition/setIsAcquistionOngoing', payload: false });
		if (cancelSoftwareTriggerUrl && cancelSoftwareTriggerUrl.length > 0) {
			dispatch(apiSlice.endpoints.postWebThingAction.initiate({ url: cancelSoftwareTriggerUrl, body: { cancelSoftwareTrigger: { input: {} } } }));
		}
	}; */

	/* useEffect(() => {
		if (currentSoftwareTriggerState === 'EXECUTED') {
			setAcquisitionEXECUTED(true)
		}
	}, [currentSoftwareTriggerState]); */

	/* useEffect(() => {
		if (props.deviceSet) {

			actions?.forEach(action => {
				if (action[0] === 'softwareTrigger') {
					const url = (new URL(props.deviceSet.config.endpoint)).origin + action[1]?.links[0]?.href;
					//dispatch({ type: "Acquisition/setSoftwareTriggerUrl", payload: url });
					setSoftwareTriggerUrl(url);
				}
				if (action[0] === 'cancelSoftwareTrigger') {
					const url = (new URL(props.deviceSet.config.endpoint)).origin + action[1]?.links[0]?.href;
					//dispatch({ type: "Acquisition/setCancelSoftwareTriggerUrl", payload: url });
					setCancelSoftwareTriggerUrl(url);
				}
			});
		}
	}, [props.deviceSet, dispatch]); */

	/* function getExposeButton() {
		if (acquisitionEXECUTED) {
			return null
		}
		if (deviceRunnerError !== '') {
			return <button className="venaDefaultButton exposeStopButton" title='Stop Expose'>
				<label>{'Disconnected'}</label>
			</button>
		}
		if (props.colorCode !== 2 && currentSoftwareTriggerState === 'IDLE') {
			return <button id={props.colorCode.toString()} className="venaDefaultButton exposeInvalidStatusButton" title='Stop Expose'>
				<label>{'Device set not ready'}</label>
			</button>
		}
		if (currentSoftwareTriggerState === 'IDLE') {
			return <button className="venaDefaultButton exposeButton" onClick={onExposeClick} title='Expose'>
				<img src="/images/Radiation_warning_symbol2.svg" alt="Radiation_warning_symbol2.svg" />
				<label>{'Expose'}</label>
			</button>
		}
		if (currentSoftwareTriggerState === "TRIGGER_REQUESTED") {
			return <button className="venaDefaultButton exposeStopButton" onClick={onExposeCancelClick} title='Stop Expose'>
				<img src="/images/stop-palm-svgrepo-com.svg" alt="stop-palm-svgrepo-com.svg" />
				<label>{'Cancel'}</label>
			</button>
		}

	} */

	const layout: any= useAppSelector((state) => getLayout(state));

	return (
		<>
			{props.deviceSet ?
				<div className="deviceSet" onClick={(evt) => evt.stopPropagation()}>
					<DeviceWebThing device={props.deviceSet} colorCode={props.colorCode} />
					{layout?.deviceSet_panel_display_footer === false ?
							<div className="deviceSet-acquistion-info">
								<AkquisitionProgress/>
							</div>
							: null
						}

					{/* <div className="exposeButtonWrapper">
						{getExposeButton()}
					</div> */}
				</div>
				:
				null
			}
		</>
	);
}

export default DeviceSet;
