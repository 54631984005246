import React, { useCallback, useRef } from "react";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import ReactPaginate from 'react-paginate';
import { debounce } from 'throttle-debounce';
import { AppDispatch, RootState } from "../../store";
import { selectRowsPerPage, selectSelectedPage, selectTotalElement, selectTotalPages } from "../ImageDisplay/ImageDisplaySlice";
import './FooterOrderlist.scss';
import { apiSlice } from "../../apis/apiSlice";
import { selectAllowComplete } from "../OrderList/MainOrderListSlice";
import { useORTranslation } from "../Localization/ORLocalization";
import { Constants } from "../../Constants";
import { getLayout } from "../OrderList/OrdersSlice";

const useAppDispatch = () => useDispatch<AppDispatch>();
const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

const FooterOrderlist = () => {

    const dispatch = useAppDispatch();

    const { t } = useORTranslation(['OrderList']);



    const selectedRowsPerPage: number = useAppSelector((state) => selectRowsPerPage(state));
    const selectedPage: number = useAppSelector((state) => selectSelectedPage(state));
    const totalPages: number = useAppSelector((state) => selectTotalPages(state));
    const totalElements: number = useAppSelector((state) => selectTotalElement(state));
    const allowComplete: boolean = useAppSelector((state) => selectAllowComplete(state));
    const layout: any = useAppSelector((state) => getLayout(state));

    const selectedPageRef = useRef<number>();
	selectedPageRef.current = selectedPage;


    const changeHandlerRowsPerPageSelect = (value: number | null) => {
        dispatch({ type: "ImageDisplay/setRowsPerPage", payload: value ?? 0 });
        console.log(value);
        dispatch(apiSlice.util.invalidateTags([{ type: 'Studies' }]));
    };

    const changeHandlerPageNrSelect = (value: number | null) => {
        if (value !== selectedPageRef.current) {
            dispatch({ type: "ImageDisplay/setSelectedPage", payload: value ? value : 0 });

            dispatch(apiSlice.util.invalidateTags([{ type: 'Studies' }]));
        }
    };

    const handleClick = (event: any) => {

    };

    const throttleOnRowsPerPageSelect = useCallback(debounce(500, changeHandlerRowsPerPageSelect), [dispatch]);
    const throttleOnPageNrSelect = useCallback(debounce(500, changeHandlerPageNrSelect), [dispatch]);

    const handlePageClick = (event: any) => {
        //console.log(event);
        throttleOnPageNrSelect(Math.min(event.selected ?? 0, totalPages))
    };

    const handleChange = (event: any) => {
        throttleOnRowsPerPageSelect(event.target.value);
    };



    return (
        <div className="footerOrderlist">
            {allowComplete ?
                <div className="open-status">
                    <span className="dot open-status-open" />
                    <label> {t(`open${Constants.ORDER_IDENTIFIER}`)}</label>
                    <span className="dot open-status-closed" />
                    <label> {t(`closed${Constants.ORDER_IDENTIFIER}`)}</label>
                </div> : null
            }
            <div className="pageSelection">
                <span className="reactPaginate">
                    <ReactPaginate
                        breakLabel="..."
                        nextLabel={layout?.PaginateNextLabel ?? ">>"}
                        initialPage={selectedPage}
                        onPageChange={handlePageClick}
                        onClick={handleClick}
                        pageRangeDisplayed={layout?.PaginatePageRangeDisplayed ?? 6}
                        pageCount={totalPages}
                        marginPagesDisplayed={1}
                        previousLabel={layout?.PaginatePreviousLabel ?? "<<"}
                        renderOnZeroPageCount={undefined}
                        containerClassName={"pagination"}
                        previousLinkClassName={"pagination__link"}
                        nextLinkClassName={"pagination__link"}
                        disabledClassName={"pagination__link--disabled"}
                        activeClassName={"pagination__link--active"}
                    />
                </span>
                <span className="total">
                    <span>{totalElements}</span>
                    <label>{t('results', { count: totalElements })}</label>
                </span>
                <span className="rowsPerPage">
                    <select onChange={handleChange} value={selectedRowsPerPage}>
                        {Array.from(Array(5).keys()).map((i: number) =>
                            <option value={(i * 5 + 5).toString()} key={i.toString()}>{(i * 5 + 5).toString()}</option>
                        )}
                    </select>
                </span>

            </div>

        </div>
    );
}

export default FooterOrderlist;
