import csTools from 'cornerstone-tools';
import cornerstone from "cornerstone-core";
import store from '../../../store';
import { Constants } from '../../../Constants';
import { fitToWindow } from "./utils";

const BaseTool = csTools.importInternal('base/BaseTool');

/**
 * @public
 * @class ResetTool
 * @memberof Tools
 *
 * @classdesc Tool for setting wwwc based on a rectangular region.
 * @extends Tools.Base.BaseTool
 */
export default class ResetTool extends BaseTool {
    /** @inheritdoc */
    constructor(props = {}) {
        const defaultProps = {
            name: 'Reset',
        };
        super(props, defaultProps);
    }

    activeCallback(element: HTMLElement) {
        let viewport = cornerstone.getViewport(element);
        if (viewport) {
            cornerstone.reset(element);            
            fitToWindow(element);
            csTools.clearToolState(element, 'NewWwwcRegion');
            csTools.clearToolState(element, 'MultiWwwcRegions');
            const matrixIndexStr = element?.id?.split("_").pop();
            if (matrixIndexStr) {
                const matrixIndex = parseInt(matrixIndexStr);
                if (!isNaN(matrixIndex)) {
                    store.dispatch({ type: "ImageDisplay/setFalseColorsFrac", payload: { matrixIndex: matrixIndex, colorFrac: Constants.DEFAULT_FALSE_COLOR_FRAC } });
                    cornerstone.updateImage(element);
                }
			}
			const eventData = {
				toolName: "ResetViewport",
				toolType: "ResetViewport",
				element: element,
				measurementData: undefined,
			};
			// @ts-ignore
			cornerstone.triggerEvent(element, csTools.EVENTS.MEASUREMENT_COMPLETED, eventData);
        }
    }
}
