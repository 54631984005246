import React, { useEffect } from "react";
import { useGetAnnotationQuery } from '../../apis/apiSlice';


interface AnnotationDataProps {
	targetUri: string;
	targetId: string;
	annodata: any;
}

const AnnotationData = (props: AnnotationDataProps) => {
	const {
		isSuccess,
		currentData,
	} = useGetAnnotationQuery(props.targetUri, {
		skip: (props.targetId === undefined || props.targetId === ""),
	})

	useEffect(() => {
		if (props.annodata) {
			if (isSuccess && currentData) {	
				let data = props.annodata[props.targetId];
				if (data) {					
					data.currentData = currentData;
				}
			}
		}
	}, [isSuccess, currentData, props.annodata, props.targetId]);

	return (
		<></>
	);

}

export default AnnotationData;
