import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiSlice } from "../../apis/apiSlice";
import { RootState } from "../../store";
import { selectDeviceActions } from "./AcquisitionSlice";

export type techniqueParametersValue = {
    value: number,
    isLimit: number,
};

export interface ITechniqueParameters {
    voltage: techniqueParametersValue;
    current: techniqueParametersValue;
    exposure: techniqueParametersValue;
    exposure_time: techniqueParametersValue;
    valueValidationStatus: number;
    currentActionRef: string;
    activeEventSubscribers: number[];
}

const initialState: ITechniqueParameters = {
    voltage: { value: 0, isLimit: 0 },
    current: { value: 0, isLimit: 0 },
    exposure: { value: 0, isLimit: 0 },
    exposure_time: { value: 0, isLimit: 0 },
    valueValidationStatus: 0,
    currentActionRef: '',
    activeEventSubscribers: [],
}


const findNearestValueInSortedList = (value: number, list: number[]) => {
    let deviation: number = Number.MAX_VALUE;
    let finalValue: number = value;
    let isLimit = 0

    let index: number = -1;

    for (let i = 0; i < list.length; i++) {

        if (Math.abs(list[i] - value) < deviation) {
            finalValue = list[i];
            deviation = Math.abs(list[i] - value);
            index = i;
        }
    }

    if (index === 0) {
        isLimit = 1;
    }
    if (index === list.length - 1) {
        isLimit = 2;
    }

    return { value: finalValue, isLimit };
}

export const getTechniqueParameters = createAsyncThunk('GeneratorTechniqueParameters/getTechniqueParameters',
    async (args: { voltage: number, exposure: number, generatorEndpoint: string, actionRef: string }, thunkApi) => {
        const getState: () => RootState = thunkApi.getState;

        const currentProperties = apiSlice.endpoints.getDeviceWebThing.select({ endpoint: args.generatorEndpoint });
        const webThing: any = currentProperties(getState())?.data?.deviceWebThingProps;

        if (webThing?.generator_params?.voltageList && webThing?.generator_params?.exposureList) {
            const finalVoltage = findNearestValueInSortedList(args.voltage, webThing?.generator_params?.voltageList)?.value;
            const finalVoltageIsLimit = findNearestValueInSortedList(args.voltage, webThing?.generator_params?.voltageList)?.isLimit;

            let finalMode: number = -1;
            let finalCurrent: number = 0;
            let finalExposure: number = 0;

            let exposureDeviation: number = Number.MAX_VALUE;
            for (let i = 0; i < webThing?.generator_params?.modes.length; i++) {
                const mode = webThing?.generator_params?.modes[i]
                if (finalVoltage >= mode.minimumVoltage && finalVoltage <= mode.maximumVoltage) {
                    for (const exposure of mode.exposureList) {
                        if (Math.abs(exposure - args.exposure) < exposureDeviation) {
                            exposureDeviation = Math.abs(exposure - args.exposure);
                            finalExposure = exposure;
                            finalMode = i;
                            for (const current of mode.currentList) {
                                if (current > finalCurrent) {
                                    finalCurrent = current;
                                    finalMode = i;
                                }
                            }
                        } else if (Math.abs(exposure - args.exposure) === exposureDeviation) {
                            for (const current of mode.currentList) {
                                if (current > finalCurrent) {
                                    finalCurrent = current;
                                    finalMode = i;
                                }
                            }
                        }
                    }
                }
            }
            const exposure_time = 1000 * finalExposure / finalCurrent;
            console.log("finalMode: " + finalMode + " finalVoltage: " + finalVoltage + " finalExposure: " +
                finalExposure + " finalCurrent: " + finalCurrent + " finalExposure_time: " + exposure_time);

            let isMin: boolean = true;
            let isMax: boolean = true;
            for (let i = 0; i < webThing?.generator_params?.modes.length; i++) {
                const mode = webThing?.generator_params?.modes[i]
                if (finalVoltage >= mode.minimumVoltage && finalVoltage <= mode.maximumVoltage) {
                    for (const exposure of mode.exposureList) {
                        if (exposure < finalExposure) {
                            isMin = false
                        }
                        if (exposure > finalExposure) {
                            isMax = false
                        }
                    }
                }
            }

            const isExposureLimit: number = isMin ? 1 : (isMax ? 2 : 0);


            const techniqueParameters = { voltage: { value: finalVoltage, isLimit: finalVoltageIsLimit }, exposure: { value: finalExposure, isLimit: isExposureLimit }, exposure_time: { value: exposure_time, isLimit: 0 }, current: { value: finalCurrent, isLimit: 0 } };
            thunkApi.dispatch({ type: "GeneratorTechniqueParameters/setTechniqueParameters", payload: techniqueParameters });

            const actions: [string, any][] = JSON.parse(selectDeviceActions(getState(), args.generatorEndpoint));
            const set_technique_parameters = actions.find((action: any) => action[0] === 'setTechniqueParameters');
            let bodyInput: any = { actionRef: args.actionRef };

            bodyInput['voltage'] = finalVoltage;
            //bodyInput['voltage'] = 200;
            bodyInput['exposure'] = finalExposure;
            bodyInput['current'] = finalCurrent;
            bodyInput['exposureTime'] = exposure_time/1000;
            const body = { setTechniqueParameters: { input: bodyInput } };
            if (set_technique_parameters) {
                const url = (new URL(args.generatorEndpoint)).origin + set_technique_parameters[1].links[0]?.href;

                try {
                    thunkApi.dispatch(apiSlice.endpoints.postWebThingAction.initiate({ url: url, body: body })).unwrap();
                } catch (err: any) {
                    console.log(err?.error);
                }
            }

        }
    })



const GeneratorTechniqueParametersSlice = createSlice({
    name: 'GeneratorTechniqueParameters',
    initialState,
    reducers: {
        setvoltage(state, action) {
            const voltage: number = action.payload;
            state.voltage = { value: voltage, isLimit: 0 };
        },

        setTechniqueParameters(state, action) {
            const voltage: { value: number, isLimit: number } = action.payload?.voltage;
            const exposure: { value: number, isLimit: number } = action.payload?.exposure;
            const exposure_time: { value: number, isLimit: number } = action.payload?.exposure_time;
            const current: { value: number, isLimit: number } = action.payload?.current;
            state.voltage = voltage;
            state.exposure = exposure;
            state.exposure_time = exposure_time;
            state.current = current;
        },

        setValueValidationStatus(state, action) {
            const valueValidationStatus: number = action.payload;
            state.valueValidationStatus = valueValidationStatus;
        },

        setCurrentActionRef(state, action) {
            const currentActionRef: string = action.payload;
            state.currentActionRef = currentActionRef;
        },

        addToActiveEventSubscribers(state, action) {
            const id: number = action.payload;
            if (!state.activeEventSubscribers.includes(id)) {
                const newActiveEventSubscribers = [...state.activeEventSubscribers];
                newActiveEventSubscribers.push(id);
                state.activeEventSubscribers = newActiveEventSubscribers;
            }
        },
    }
});

export const { setvoltage, setTechniqueParameters, setValueValidationStatus, setCurrentActionRef } = GeneratorTechniqueParametersSlice.actions
export default GeneratorTechniqueParametersSlice.reducer;

export const selectValueValidationStatus = ((state: RootState) =>
    state.GeneratorTechniqueParameters.valueValidationStatus);

export const selectCurrentActionRef = ((state: RootState) =>
    state.GeneratorTechniqueParameters.currentActionRef);

export const selectvoltage = ((state: RootState) =>
    state.GeneratorTechniqueParameters.voltage);

export const selectActiveEventSubscribers = ((state: RootState) =>
    state.GeneratorTechniqueParameters.activeEventSubscribers);

export const selectTechniqueParameters = (state: RootState) => {
    return JSON.stringify({
        voltage: state.GeneratorTechniqueParameters.voltage, exposure: state.GeneratorTechniqueParameters.exposure,
        exposure_time: state.GeneratorTechniqueParameters.exposure_time, current: state.GeneratorTechniqueParameters.current
    });
}

export const getValueForTechniqueParameter = (state: RootState, propertyName: string) => {
    let ret: any = undefined;
    switch (propertyName) {
        case 'voltage':
            ret = state.GeneratorTechniqueParameters.voltage;
            break;
        case 'exposure':
            ret = state.GeneratorTechniqueParameters.exposure;
            break;
        case 'exposure_time':
            ret = state.GeneratorTechniqueParameters.exposure_time;
            break;
        case 'current':
            ret = state.GeneratorTechniqueParameters.current;
            break;
    }
    return ret;
}
