export class Constants {
    public static get  IMAGE_DISPLAY_GENERIC_ELEMENT_NAME(): string  { return 'ImageDispayCanvas'; }
    public static get  OVERVIEW_ELEMENT_NAME(): string  { return 'OverviewCanvas'; }
    public static get  CREATE_PREVIEW_IMAGE_ELEMENT(): string  { return 'CreatePreviewImageCanvas'; }
    public static get  MQTT_PROCESSING_QUEU(): string  { return 'de/or/processing'; }
    public static get  PROCESSING_TIMEOUT(): number  { return 20000; }
    public static get  MAX_MESSAGES(): number  { return 200; }
    public static get  DEV_MODE(): boolean  { return true; }
    public static get  RTK_QUERY_CACHE_LIFETIME(): number  { return 120 }
    public static get  RTK_QUERY_CACHE_REFETCHONMOUNTORARGCHANGE(): number  { return 120 }
    public static get  TOKEN_LICENSE_TAG(): string  { return 'https://orhro.com/jwt/license'; }
    public static get  EXPIRES_WARN_DAYS_LEFT(): number  { return 7; }
    //public static get  DEFAULT_FALSE_COLOR_FRAC(): number[]  { return [0.25, 0.5, 0.75] }
    //public static get  DEFAULT_FALSE_COLORS(): number[]  { return [0.0,0.0,1.0,  0.0,1.0,1.0,   0.0,1.0,1.0,  0.0,1.0,0.0,   0.0,1.0,0.0, 1.0, 1.0, 0.0,   1.0,1.0,0.0, 1.0, 0.0, 0.0] }
    public static get  DEFAULT_FALSE_COLOR_FRAC(): number[]  { return [0.33, 0.66] }
    public static get  DEFAULT_FALSE_COLORS(): number[]  { return [0.0,0.0,1.0, 0.0,1.0,0.0,    0.0,1.0,0.0, 1.0,1.0,0.0,    1.0,1.0,0.0, 1.0,0.0,0.0] }
    public static get  ORDER_IDENTIFIER(): string  { return 'Study'; }
    public static get  MULTIWWWCREGIONS_MAX(): number  { return 8; }
    public static get  MAX_WORKITEMS_IN_LISTDISPLAY(): number  { return 7; }
    public static get  DEVICERUNNER_HEALTH_POLLING_INTERVALL(): number  { return 3000; }
    public static get   ACQUISITION_STEP_TYPE(): string  { return 'de.or.protocol.steps/acquisition'; }
    public static get   PROCESSING_STEP_TYPE(): string  { return 'de.or.protocol.steps/processing'; }
    public static get   POSTPROCESSING_STEP_TYPE(): string  { return 'de.or.protocol.steps/post-processing'; }
    public static get  SYNCHRONOUS_BACKEND(): boolean  { return false; }
}
