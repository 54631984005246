import { format } from "date-fns";
import { InstanceAndartefactIds } from "../Preview/ExportDialog";

export const getImageName = (item: InstanceAndartefactIds, imageType: string, mimeType: string): string => {
    let imageName: string = '';
    imageName += format(new Date(), "yyyyMMddHHmmss");
    imageName += '__';
    imageName += item?.workitemTitle?.replace(' ','_');
    imageName += '__';
    imageName += imageType;
    imageName += '__';
    imageName += item.instanceId;
    imageName += '.';
    imageName += mimeType;
    return imageName;
};
