import React from "react";
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'

import type { AppDispatch, RootState } from '../../store'
import SVG from 'react-inlinesvg';
import { CatalogueEntry, getCatalogueEntries, getSelectedCatalogueEntry } from "./CatalogueSlice";
import { getSelectedWorkitemTitle, isSelectedWorkitemPlanning } from "../OrderList/OrdersSlice";
import { apiSlice, useUpdateWorkitemDetails1Mutation } from "../../apis/apiSlice";
import { useORTranslation } from "../Localization/ORLocalization";
import { selectedWorkitem } from "../ImageDisplay/ImageDisplaySlice";
import { current } from "@reduxjs/toolkit";

const Styles = {
    selectedBorder: { border: 'solid 2px #b6c1c4' },
    notSelectedBorder: { border: 'solid 1px #3a5a65' },
    selectedFontWeight: { fontWeight: 500 },
    notSelectedFontWeight: { fontWeight: 50 },
} as const;

interface ObjectSelectionProps {
    orderId: string;
}

const useAppDispatch = () => useDispatch<AppDispatch>();
const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

const ObjectSelection = (props: ObjectSelectionProps) => {

    const catalogueEntries: CatalogueEntry[] | undefined = useAppSelector((state) => getCatalogueEntries(state));
    const selectedCatalogueEntry: CatalogueEntry | undefined = useAppSelector((state) => getSelectedCatalogueEntry(state));
    const isWorkitemPlanning: boolean = useAppSelector((state) => isSelectedWorkitemPlanning(state));
    const isAkquisitionButtonActive: boolean = useAppSelector((state) => state.Acquisition.isAcquistionButtonActive);
    const currentWorkitemId: string = useAppSelector((state) => selectedWorkitem(state));
    const currentWorkitemTitle: string = useAppSelector((state) => getSelectedWorkitemTitle(state));

    const [UpdateWorkitemDetails] = useUpdateWorkitemDetails1Mutation();

    const dispatch = useAppDispatch();

    const { t } = useORTranslation(['Acquisition']);

    const handleSelect = (selectedCatalogueEntry: CatalogueEntry) => {
        if (isWorkitemPlanning && isAkquisitionButtonActive && currentWorkitemId) {
            let workitemTitle: string =  structuredClone(currentWorkitemTitle);
            if(workitemTitle === t('newWorkitemTitle')) {
                workitemTitle = selectedCatalogueEntry?.title;
            }
            UpdateWorkitemDetails({ workitemId: currentWorkitemId, body: {details: {catalogueId: selectedCatalogueEntry?.catalogueId?? '0', title: workitemTitle}}}).unwrap();

            dispatch(apiSlice.util.updateQueryData('getStudyWithWorkitems', props.orderId, (data: any) => {
                const workitemList = data?.workitems?.map((workitem: any, i: number) => workitem?.data?.id);
                if (currentWorkitemId && workitemList && workitemList.includes(currentWorkitemId)) {
                    const tmpWorkitem = data?.workitems?.find((workitem: any) => workitem.data?.id === currentWorkitemId);
                    if (tmpWorkitem?.data) {
                        tmpWorkitem.data.details = {catalogueId: selectedCatalogueEntry?.catalogueId?? '0', title: workitemTitle};
                    }
                }
                return data;
            }))
        }
        dispatch({ type: 'Catalogue/setSelectedCatalogueEntry', payload: selectedCatalogueEntry.id });
    }

    return (

        <div className="objectsSettings" >
            <div className="objectsSettingsInset" onClick={(evt) => evt.stopPropagation()}>
                <div className="objectsSettingsHeader">
                    <h1>{t('objectOfInvestigation')}</h1>
                    <hr />
                    <h2>{`${t('selected')}:`}</h2>
                    <h2 className="selected">{selectedCatalogueEntry ? t(selectedCatalogueEntry.title) : ""}</h2>
                    <hr className="hrule2" />
                </div>
                <div className="objectsSettingsObjects">
                    <div className="StatusDiv">
                        {catalogueEntries ? catalogueEntries.map((object: CatalogueEntry, i: number) => <div key={i} id={object.catalogueId}
                            className="StatusLabels" title={object.title}>
                            <button className={selectedCatalogueEntry && selectedCatalogueEntry.id === object.id ? "CatalogueButton_selected" : "CatalogueButton"}
                                onClick={() => handleSelect(object)} title={t(object.description as string) as string}>
                                <SVG src={object.icon} />
                            </button>
                            <label className="ImageLabel"
                                style={selectedCatalogueEntry && selectedCatalogueEntry.id === object.id ? Styles.selectedFontWeight : Styles.notSelectedFontWeight}>{t(object.title)}</label>
                        </div>) : null}
                    </div>
                </div>
            </div>

        </div>
    );

}

export default ObjectSelection;
