import React from "react";
import { instance, useGetInstanceQuery, workitem } from "../../apis/apiSlice";
import PreviewImage from "../Preview/PreviewImage";
import Spinner from "../ImageDisplay/Spinner";
import { Constants } from "../../Constants";

interface InstanceProps {
    orderId: string;
    workitemData: workitem;
    workitemId: string;
    instanceId: string;
    instanceId_Raw: string | undefined;
    className: string;
    getRawSeries: boolean;
    includeImageDesc: boolean;
    protocolCorrelationId: string | undefined;
    onImageSelect: (workitem: string, artifactId: string | undefined, artifactId_raw: string | undefined, workitemData?: workitem | undefined, protocolCorrelationId?: string|undefined) => void;
    isRejectedSeries?: boolean;
    resumeWorkitem?: (evt: any) => void;
    currentProtocolStep?: any;
    sources?: any;
    rawProtocolStepNumber?: number;
    showRecover?: boolean;
    recoverImage?: (evt: any) => void;
}

const Instance = (props: InstanceProps) => {

    const {
        data,
        isSuccess,
    }: {
        data: instance,
        isLoading: boolean, isSuccess: boolean, isError: boolean, error: any
    } = useGetInstanceQuery(props.instanceId, {
        refetchOnMountOrArgChange: Constants.SYNCHRONOUS_BACKEND ? true : Constants.RTK_QUERY_CACHE_REFETCHONMOUNTORARGCHANGE,
        skip: props.instanceId === undefined || props.instanceId === '',
    })

    const {
        data: data_raw,
        isSuccess: isSuccess_raw,
    }: {
        data: instance,
        isLoading: boolean, isSuccess: boolean, isError: boolean, error: any
    } = useGetInstanceQuery(props.instanceId_Raw, {
        refetchOnMountOrArgChange: Constants.RTK_QUERY_CACHE_REFETCHONMOUNTORARGCHANGE,
        skip: props.instanceId_Raw === undefined || props.instanceId_Raw === '',
    })

    return (
        <>
            {isSuccess && data?.details?.artifact_id && (isSuccess_raw || props.instanceId_Raw === undefined ||  props.instanceId_Raw === '') ?
                <PreviewImage orderId={props.orderId} artifactId={data?.details?.artifact_id} artifactId_Raw={data_raw?.details?.artifact_id}
                    className={props.className} onImageSelect={props.onImageSelect}
                    workitemId={props.workitemId} workitemData={props.workitemData}
                    includeImageDesc={props.includeImageDesc} protocolCorrelationId={props.protocolCorrelationId}
                    isRejectedSeries={props.isRejectedSeries} resumeWorkitem={props.resumeWorkitem}
                    currentProtocolStep={props.currentProtocolStep}
                    sources={props?.sources}
                    rawProtocolStepNumber= {props.rawProtocolStepNumber}
                    showRecover={props.showRecover}
                    recoverImage={props.recoverImage}
                />
                :
                <Spinner className={props.className}/>
                }
        </>
    )
}

export default Instance;
