import csTools from 'cornerstone-tools';
import cornerstone from "cornerstone-core";
import { setGetToolStateItems, updateAnnnotationOnInputChange } from "./utils";
import store from './../../../store';

const BaseTool = csTools.importInternal('base/BaseTool');
const getToolState = csTools.getToolState;

/**
 * @public
 * @class RotateToBlackMaskTool
 * @memberof Tools
 *
 * @classdesc RotateToBlackMaskTool
 * @extends Tools.Base.BaseTool
 */
export default class RotateToBlackMaskTool extends BaseTool {
    /** @inheritdoc */
    constructor(props = {}) {
        const defaultProps = {
            name: 'RotateToBlackMask',
        };
        super(props, defaultProps);
    }

    activeCallback(element) {
        const state = store.getState();
        const matrixIndex = state.ImageDisplay.selectedIndex;
        let bmname = 'BlackMask';
        let toolData = getToolState(element, bmname);
        if (toolData !== undefined && toolData.data) {
            for (let i = 0; i < toolData.data.length; i++) {
                let data = toolData.data[i];
                
                if (!data.angle) {
                    this.rotateToBlackMask(element);                    
                    setGetToolStateItems(i, [{ name: "angle", value: data.angle }], [], matrixIndex, bmname);
                    setGetToolStateItems(i, [{ name: "needFit", value: true }], [], matrixIndex, bmname);
                    updateAnnnotationOnInputChange(i, matrixIndex, bmname);
                } else {
                    var viewport = cornerstone.getViewport(element);
                    if (viewport) {
                        viewport.rotation = 0;
                        cornerstone.setViewport(element, viewport);
                    }
                    setGetToolStateItems(i, [{ name: "angle", value: undefined }], [], matrixIndex, bmname);
                    setGetToolStateItems(i, [{ name: "needFit", value: true }], [], matrixIndex, bmname);
                    updateAnnnotationOnInputChange(i, matrixIndex, bmname);
                }
                cornerstone.updateImage(element, false);
            }
            //cornerstone.triggerEvent(element, "rotatetoblackmask");
        }
    }
    
    
    rotateToBlackMask(displayElement) {
        const stateManager = csTools.getElementToolStateManager(displayElement);
        let toolstate = stateManager.get(displayElement, "BlackMask");
        if (toolstate && toolstate.data) {
            for (let i = 0; i < toolstate?.data.length; i++) {
                const h = toolstate?.data[i].handles;
                let roi = [];
                for (let j = 1; j < 5; j++) {
                    roi.push(h[j]);
                }
				roi.push(h[1]);
				// compute minimal angle of all roi segments with (0,1) (y-axis)
				let rangle = 360.0;
				for (let j = 0; j < 4; j++) {
					var dx = (roi[j + 1].x - roi[j].x);
					var dy = (roi[j + 1].y - roi[j].y);

					var l = Math.sqrt(dx * dx + dy * dy);
					if (l > 0.05) {
						var angle = dy / l;
						if (angle <= -1.0)
							angle = -Math.PI;
						else
							if (angle > 1.0)
								angle = Math.PI;
							else
								angle = Math.acos(angle);
						angle = angle * 180.0 / Math.PI;

						if (dx < 0)
							angle = -angle;
					} else {
						angle = 0.0;
					}

					if (Math.abs(angle) < Math.abs(rangle)) {
						rangle = angle;
					}
				} // for
                
                var viewport = cornerstone.getViewport(displayElement);
                if (viewport) {                    
                    viewport.rotation = rangle;
                    if (toolstate && toolstate.data) {
                        let data = toolstate?.data[i];
                        data.angle = rangle;
                    }
                    cornerstone.setViewport(displayElement, viewport);
                }
            } // for

        }
        return;
    } // rotateToBlackMask
}
