import cornerstone from "cornerstone-core";
import UTIF from "utif";
import { calculateMinMaxMeanVar, getWindowWidthFromMinMaxMeanVar, swap16 } from "./Tools/utils";

let files: File[] = [];
let loadTime: number = 0;

function addtif(file: File): string {
    const fileIndex = files.push(file);
    return `tiffile:${fileIndex - 1}`;
}

function addraw2(file: File): string {
    const fileIndex = files.push(file);
    return `raw2file:${fileIndex - 1}`;
}

function get(index: number): File {
    return files[index];
}

function remove(index: number): void {
    files.splice(index, 1);
}

function purge(): void {
    files = [];
}

function parseImageId(imageId: string) {
    // build a url by parsing out the url scheme and frame index from the imageId
    const firstColonIndex: number = imageId.indexOf(':');
    let url: string = imageId.substring(firstColonIndex + 1);
    const frameIndex: number = url.indexOf('frame=');
    let frame: number = 0;

    if (frameIndex !== -1) {
        const frameStr: string = url.substring(frameIndex + 6);

        frame = parseInt(frameStr, 10);
        url = url.substring(0, frameIndex - 1);
    }

    return {
        scheme: imageId.substring(0, firstColonIndex),
        url,
        frame
    };
}


function loadtifImage(imageId: string) {
    const parsedImageId = parseImageId(imageId);
    const fileIndex: number = parseInt(parsedImageId.url, 10);
    const file: File = get(fileIndex);
    loadTime = (new Date()).getTime();

    const promise = new Promise<cornerstone.Image>((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.onload = (e) => {
            try {
                const imageAsArrayBuffer: ArrayBuffer | string | null = e?.target?.result ?? null;
                if (imageAsArrayBuffer && imageAsArrayBuffer instanceof ArrayBuffer) {

                    if (parsedImageId.scheme === 'tiffile') {
                        var ifds = UTIF.decode(imageAsArrayBuffer);

                        if (Array.isArray(ifds) && ifds.length > 0) {
                            const ifds0: UTIF.IFD = ifds[0];
                            if (ifds0.t256 && ifds0.t257 && ifds0.t273) {
                                const height = (ifds0.t257 as Uint8Array)[0];
                                const width = (ifds0.t256 as Uint8Array)[0];
                                let offset = ((ifds0.t273 as Uint8Array)[0]) / 2;
                                let photometricInterpretation = 1;
                                if (ifds0.t262) {
                                    photometricInterpretation = (ifds0?.t262 as Uint8Array)[0];
                                }
                                const byteArray = new Uint16Array(imageAsArrayBuffer).subarray(offset);
                                const minMaxMean = calculateMinMaxMeanVar(
                                    byteArray,
                                    0,
                                    65535
                                );
                                const isMSB = (new TextDecoder().decode(imageAsArrayBuffer.slice(0, 2))) === 'MM';
                                const timeDiff: number = (new Date()).getTime() - loadTime;
                                const getPixelData = () => {return isMSB ? (byteArray.map((val: number) => swap16(val))) : byteArray};
                                const image = {
                                    imageId,
                                    minPixelValue: minMaxMean.min,
                                    maxPixelValue: minMaxMean.max,
                                    slope: 1,
                                    intercept: 0,
                                    windowCenter: minMaxMean.mean,
                                    windowWidth: getWindowWidthFromMinMaxMeanVar(minMaxMean),
                                    pixelData: getPixelData(),
                                    getPixelData: () => image.pixelData,
                                    //getPixelData: () => {return isMSB ? (byteArray.map((val: number) => swap16(val))) : byteArray},
                                    rows: height,
                                    columns: width,
                                    height: height,
                                    width: width,
                                    color: false,
                                    rgba: false,
                                    invert: false,
                                    sizeInBytes: height * width * 2,
                                    totalTimeInMS: timeDiff,
                                    photometricInterpretation: photometricInterpretation,
                                };
                                resolve(image as unknown as cornerstone.Image);
                            }
                        }
                    } else if (parsedImageId.scheme === 'raw2file') {
                        if (imageAsArrayBuffer && imageAsArrayBuffer.byteLength >= 8) {

                            const view1 = new DataView(imageAsArrayBuffer, 0, 4)
                            const view2 = new DataView(imageAsArrayBuffer, 4, 4)

                            const height = view2.getUint32(0, true);
                            const width = view1.getUint32(0, true);

                            const byteArray = new Uint16Array(imageAsArrayBuffer).subarray(4);
                            const minMaxMean = calculateMinMaxMeanVar(
                                byteArray,
                                0,
                                65535
                            );
                            const timeDiff: number = (new Date()).getTime() - loadTime;
                            const image = {
                                imageId,
                                minPixelValue: minMaxMean.min,
                                maxPixelValue: minMaxMean.max,
                                slope: 1,
                                intercept: 0,
                                windowCenter: minMaxMean.mean,
                                windowWidth: getWindowWidthFromMinMaxMeanVar(minMaxMean),
                                getPixelData: () => byteArray,
                                rows: height,
                                columns: width,
                                height: height,
                                width: width,
                                color: false,
                                rgba: false,
                                invert: false,
                                sizeInBytes: height * width * 2,
                                totalTimeInMS: timeDiff,
                            };
                            resolve(image as unknown as cornerstone.Image);
                        }
                    }
                }
            } catch (err) {
                reject(err);
            }
        };

        fileReader.onerror = reject;

        fileReader.readAsArrayBuffer(file);
    });

    return {
        promise
    };

}

const TifRaw2FileImageLoader = {
    addtif,
    addraw2,
    get,
    remove,
    purge,
    parseImageId,
    loadtifImage,
};

export default TifRaw2FileImageLoader;
