import { createSlice } from '@reduxjs/toolkit'

import { RootState } from '../../store'
import { apiSlice } from '../../apis/apiSlice';


export interface ISubjectSlice {
    searchString: string;
    selectedSubject: any;
    rowsPerPage: number;
    selectedPage: number;
    totalPages: number;
    totalElements: number;
    rowsPerPageRelatedStudies: number;
    selectedPageRelatedStudies: number;
    totalPagesRelatedStudies: number;
    totalElementsRelatedStudies: number;
}

const initialState: ISubjectSlice = {
    searchString: '',
    selectedSubject: {},
    rowsPerPage: 10,
    selectedPage: 0,
    totalPages: 1,
    totalElements: 0,
    rowsPerPageRelatedStudies: 10,
    selectedPageRelatedStudies: 0,
    totalPagesRelatedStudies: 1,
    totalElementsRelatedStudies: 0,
};

const SubjectSlice = createSlice({
    name: 'Subject',
    initialState,
    reducers: {
        setSearchString(state, action) {
            const searchString: string = action.payload;
            state.searchString = searchString;
        },

        setSelectedSubject(state, action) {
            const selectedSubject: any = action.payload;
            state.selectedSubject = selectedSubject;
        },
        setRowsPerPage(state, action) {
            const rowsPerPage: number = action.payload;
            state.rowsPerPage = rowsPerPage;
        },
        setSelectedPage(state, action) {
            const selectedPage: number = action.payload;
            state.selectedPage = selectedPage;
        },
        setTotalPages(state, action) {
            const totalPages: number = action.payload;
            state.totalPages = totalPages;
        },
        setTotalElements(state, action) {
            const totalElements: number = action.payload;
            state.totalElements = totalElements;
        },

        setRowsPerPageRelatedStudies(state, action) {
            const rowsPerPage: number = action.payload;
            state.rowsPerPage = rowsPerPage;
        },
        setSelectedPageRelatedStudies(state, action) {
            const selectedPage: number = action.payload;
            state.selectedPage = selectedPage;
        },
        setTotalPagesRelatedStudies(state, action) {
            const totalPages: number = action.payload;
            state.totalPages = totalPages;
        },
        setTotalElementsRelatedStudies(state, action) {
            const totalElements: number = action.payload;
            state.totalElements = totalElements;
        },
    }
});

export const { setSearchString,
    setRowsPerPage,
    setSelectedPage,
    setTotalPages,
    setTotalElements, setRowsPerPageRelatedStudies,
    setSelectedPageRelatedStudies, setTotalPagesRelatedStudies, setTotalElementsRelatedStudies} = SubjectSlice.actions
export default SubjectSlice.reducer;

export const getSearchString = (state: RootState) =>
    state.Subject.searchString;

export const getSelectedSubject = (state: RootState) =>
    state.Subject.selectedSubject;

export const getSearchResult = (state: RootState) => {
    let searchResult: any = undefined;
    const selectSearchResult = apiSlice.endpoints.searchSubjects.select(state.Subject.searchString);
    searchResult = selectSearchResult(state)?.data;
    return searchResult;
};

export const selectRowsPerPage = (state: RootState) => state.Subject.rowsPerPage;

export const selectSelectedPage = (state: RootState) => state.Subject.selectedPage;

export const selectTotalPages = (state: RootState) => state.Subject.totalPages;

export const selectTotalElement = (state: RootState) => state.Subject.totalElements;

export const selectRowsPerPageRelatedStudies = (state: RootState) => state.Subject.rowsPerPageRelatedStudies;

export const selectSelectedPageRelatedStudies = (state: RootState) => state.Subject.selectedPageRelatedStudies;

export const selectTotalPagesRelatedStudies = (state: RootState) => state.Subject.totalPagesRelatedStudies;

export const selectTotalElementRelatedStudies = (state: RootState) => state.Subject.totalElementsRelatedStudies;
