
import { TypedUseSelectorHook, useSelector } from 'react-redux';
import { useGetDeviceRunnerHealthQuery } from '../../apis/apiSlice';
import { RootState } from '../../store';
import { selectDeviceRunnerPath } from './AcquisitionSlice';
import { Constants } from '../../Constants';


interface DeviceRunnerHealthProps {

};

const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

const DeviceRunnerHealth = (props: DeviceRunnerHealthProps) => {

	const deviceRunnerPath: string = useAppSelector((state) => selectDeviceRunnerPath(state));

	const {
        data: data,
        isSuccess: isSuccess,
    }: { data: any, isSuccess: boolean } = useGetDeviceRunnerHealthQuery(deviceRunnerPath, {
        skip: !(deviceRunnerPath !== undefined && deviceRunnerPath !== ''),
        pollingInterval: Constants.DEVICERUNNER_HEALTH_POLLING_INTERVALL,
    })

	return (
		<></>
	);
}

export default DeviceRunnerHealth;
