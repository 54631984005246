import React from "react";

type colorSetting = {
    countour1: string;
    countour2: string;
    levelbar1: string;
    levelbar2: string;
};

interface BatteryLevelProps {
    colorSettingNr: number;
    batteryLevel: number;
}

const minLevel: number = 10;
const warnColor: string = "#fc5555";
const colorEmpty: string = "#70848BB8";


const colorSettings: colorSetting[] = [{ countour1: "#241c1c", countour2: "#41c1c", levelbar1: "#b2c200", levelbar2: "#b2c200" },
{ countour1: "#F2F2F2", countour2: "#F2F2F275", levelbar1: "#b2c200", levelbar2: "#b2c200" }];

const BatteryLevel = (props: BatteryLevelProps) => {



    let levelBars = [];
    let levelBarsShadow = [];

    let start_y1: number = 408;
    let start_y2: number = 432;
    let start_y3: number = 96;
    let i_level = Math.round(props.batteryLevel / 20);


    for (var i = 0; i < Math.min(i_level, 5); i++) {

        levelBars.push(<path d={`m214.4 ${start_y1}c0 12.8-10.4 24-23.2 24h-126.4c-12.8 0-23.2-11.2-23.2-24s10.4-24 23.2-24h125.6c13.6 0 24 11.2 24 24z`} className="batteryBars" fill={props.batteryLevel > minLevel ? colorSettings[props.colorSettingNr].levelbar1 : colorEmpty}  key={`a${i}`}/>);
        levelBars.push(<path d={`m64.8  ${start_y2}c-12.8 0-23.2-11.2-23.2-24s10.4-24 23.2-24h127.2`} className="batteryBars" fill={props.batteryLevel > minLevel ? colorSettings[props.colorSettingNr].levelbar2 : colorEmpty} key={`b${i}`}/>);
        levelBarsShadow.push(<path d={`m${start_y3} 161.6c-12.8 0-24 10.4-24 23.2v125.6c0 12.8 11.2 23.2 24 23.2s24-10.4 24-23.2v-125.6c0-12.8-11.2-23.2-24-23.2z`} key={`c${i}`}/>);
        start_y1 -= 72;
        start_y2 -= 72;
        start_y3 += 72;
    }

    return (
        <svg version="1.1" viewBox="0 0 270 500" className="batteryLevel">
            <g transform="rotate(-90,188,308)" fill={props.batteryLevel > minLevel ? colorSettings[props.colorSettingNr].countour1 : warnColor}>
                {levelBarsShadow}
                <path d="m456 174.4c12.8 0 24 10.4 24 24v100c0 12.8-11.2 24-24 24" />
            </g>

            <path d="m48 31.2v0.8h-16c-19.2 0-32 14.4-32 32.8v398.4c0 17.6 13.6 32.8 32 32.8h192c19.2 0 32-15.2 32-32.8v-398.4c0-17.6-13.6-32.8-32-32.8h-16v-0.8c0-17.6-12.8-31.2-29.6-31.2h-100.8c-16.8 0-29.6 13.6-29.6 31.2zm176 16.8c10.4 0 16 7.2 16 16.8v398.4c0 8.8-6.4 16.8-16 16.8h-192c-10.4 0-16-8-16-16.8v-398.4c0-9.6 6.4-16.8 16-16.8zm-45.6-32c8.8 0 13.6 6.4 13.6 15.2v0.8h-128v-0.8c0-8.8 4.8-15.2 13.6-15.2z" fill={props.batteryLevel > minLevel ? colorSettings[props.colorSettingNr].countour1 : warnColor} />
            <path d="m240 232.8v230.4c0 8.8-6.4 16.8-16 16.8h-192c-6.4 0-10.4-3.2-14.4-8l-9.6 12.8c5.6 7.2 13.6 11.2 24 11.2h192c19.2 0 32-15.2 32-32.8v-247.2z" fill={props.batteryLevel > minLevel ? colorSettings[props.colorSettingNr].countour2 : warnColor} />

            {levelBars}
        </svg>
    );

}

export default BatteryLevel;
