import csTools from 'cornerstone-tools';
import cornerstone from "cornerstone-core";

const BaseTool = csTools.importInternal('base/BaseTool');


/**
 * @public
 * @class ResetProcessingTool
 * @memberof Tools
 *
 * @classdesc ResetProcessingTool
 * @extends Tools.Base.BaseTool
 */
export default class ResetProcessingTool extends BaseTool {
    /** @inheritdoc */
    constructor(props = {}) {
        const defaultProps = {
            name: 'ResetProcessing',
        };
        super(props, defaultProps);
    }

    activeCallback(element) {
		  const eventData = {
                toolName: this.name,
                toolType: this.name,
                element: element,
                measurementData: undefined,
            };
 			cornerstone.triggerEvent(element, csTools.EVENTS.MEASUREMENT_COMPLETED, eventData);
    } // activeCallback
} // ResetProcessingTool
