import { TypedUseSelectorHook, useSelector } from 'react-redux';
import { device, useGetDeviceRegistryQuery } from '../../apis/apiSlice';
import { RootState } from '../../store';
import { selectDeviceRunnerPath } from './AcquisitionSlice';
import './GenericDevice.scss';
import DeviceWebThing2 from './DeviceWebThing2';

interface GenericDeviceProps {
	deviceId: number;
	deviceSetId: number;
	orderId: string;
	deviceSet: any;
};

const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

const GenericDevice = (props: GenericDeviceProps) => {

	const deviceRunnerPath: string = useAppSelector((state) => selectDeviceRunnerPath(state));

	const {
		data,
		isSuccess,
	}: { data: device, isSuccess: boolean } = useGetDeviceRegistryQuery({ deviceId: props.deviceId, deviceRunnerPath: deviceRunnerPath }, {
		refetchOnMountOrArgChange: false,
	});

	return (
		<>
			{isSuccess && data?.config?.endpoint && (data?.category === 'GENERATOR' || data?.category === 'DETECTOR')?
				<div className="GenericDevice">
					<div >
						<DeviceWebThing2 orderId={props.orderId} device={data} deviceSetId={props.deviceSetId} deviceSet={props?.deviceSet}/>
					</div>
				</div>
				: null}
		</>
	);
}

export default GenericDevice;
