import csTools from 'cornerstone-tools';
import cornerstone from "cornerstone-core";
import { isRotated } from "./utils.js";
const BaseTool = csTools.importInternal('base/BaseTool');
/**
 * @public
 * @class Percent100
 * @memberof Tools
 *
 * @classdesc Percent100
 * @extends Tools.Base.BaseTool
 */
export default class Percent100Tool extends BaseTool {
    /** @inheritdoc */
    constructor(props = {}) {
        const defaultProps = {
            name: 'Percent100',
        };
        super(props, defaultProps);
    }

    activeCallback(element : HTMLElement) {
        let viewport = cornerstone.getViewport(element);
        if (viewport) {
            cornerstone.fitToWindow(element);            
            viewport.scale = 1;
            const image = cornerstone.getImage(element);
            
            // @ts-ignore
            if (image.cutwidth) {
                // @ts-ignore
                let tx = image.width / 2 - image.cutx, ty = image.height / 2 - image.cuty;
                if (viewport.hflip)
                    // @ts-ignore
                    tx = image.width / 2 - (image.width - image.cutx);
                if (viewport.vflip)
                    // @ts-ignore
                    ty = image.height / 2 - (image.height - image.cuty);

                let rotation = viewport.rotation;
                if (isRotated(rotation)) {
                    const rotationRad = rotation * Math.PI / 180.0;
                    tx = (tx * Math.abs(Math.cos(rotationRad)) - ty * Math.abs(Math.sin(rotationRad)));
                    ty = (ty * Math.abs(Math.cos(rotationRad)) + tx * Math.abs(Math.sin(rotationRad)));
                }

                viewport.translation = { x: tx, y: ty };
            }
            cornerstone.setViewport(element, viewport);
			const eventData = {
				toolName: "StoreViewport",
				toolType: "StoreViewport",
				element: element,
				measurementData: undefined,
			};
			// @ts-ignore
			cornerstone.triggerEvent(element, csTools.EVENTS.MEASUREMENT_COMPLETED, eventData);
        }
    }
}
