import csTools from 'cornerstone-tools';
import cornerstone from "cornerstone-core";
import { fitToWindow } from "./utils";

const BaseTool = csTools.importInternal('base/BaseTool');

/**
 * @public
 * @class RotateLeftTool
 * @memberof Tools
 *
 * @classdesc Tool for rotating the image by 90 degrees anti-clockwise..
 * @extends Tools.Base.BaseTool
 */
export default class RotateLeftTool extends BaseTool {
    /** @inheritdoc */
    constructor(props = {}) {
        const defaultProps = {
            name: 'RotateLeft',
        };
        super(props, defaultProps);
    }

    activeCallback(element: HTMLElement) {
        let viewport = cornerstone.getViewport(element);
        if (viewport) {
            const canvas: HTMLCanvasElement | undefined = cornerstone.getEnabledElement(element).canvas;
            if (canvas) {
                const image: cornerstone.Image | undefined = cornerstone.getEnabledElement(element).image;
                if (image) {
                    const trans = {x: viewport.translation.y, y: -viewport.translation.x};
                    viewport.rotation += -90;
                    viewport.translation = trans;
					cornerstone.setViewport(element, viewport);
					// @ts-ignore
					if (image.cutwidth !== undefined) {
						viewport = cornerstone.getViewport(element);
						if (viewport !== undefined) {
							let scale = viewport.scale;
							fitToWindow(element);
							viewport.scale = scale;
							cornerstone.setViewport(element, viewport);
						}
					}
					
					const eventData = {
						toolName: "StoreViewport",
						toolType: "StoreViewport",
						element: element,
						measurementData: undefined,
					};
					// @ts-ignore
					cornerstone.triggerEvent(element, csTools.EVENTS.MEASUREMENT_COMPLETED, eventData);
                }
            }
        }
    }
}
