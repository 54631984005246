import Viewer from "../components/ImageDisplay/Viewer";

const OrderDetail = () => {

	return (
		<Viewer />
	)

}

export default OrderDetail;
