import csTools from 'cornerstone-tools';
import { getPixels } from "./utils";
import { selectedAnnotations } from '../../OrderList/OrdersSlice';
import { t } from "../../Localization/ORLocalization";

import store from '../../../store';

const getNewContext = csTools.import("drawing/getNewContext");
const getPixelSpacing = csTools.importInternal('util/getPixelSpacing');
const calculateSUV = csTools.importInternal('util/calculateSUV');
const draw = csTools.import("drawing/draw");
const setShadow = csTools.import("drawing/setShadow");
const drawRect = csTools.import("drawing/drawRect");
const drawHandles = csTools.import("drawing/drawHandles");
const drawLinkedTextBox = csTools.import("drawing/drawLinkedTextBox");
const getROITextBoxCoords = csTools.import("util/getROITextBoxCoords");
const numbersWithCommas = csTools.import("util/numbersWithCommas");
const getToolState = csTools.getToolState;

/**
 * @public
 * @class RectangleRoiTool
 * @memberof Tools
 *
 * @classdesc Tool for setting wwwc based on a rectangular region.
 * @extends Tools.Annotation.EllipticalRoiTool
 */
export default class RectangleRoiExtendedTool extends csTools.RectangleRoiTool {
  /** @inheritdoc */
  constructor(props = {}) {
    const defaultProps = {
      name: 'RectangleRoiExtended',
      supportedInteractionTypes: ['Mouse', 'Touch'],
      configuration: {
        showMinMax: true,
        // showHounsfieldUnits: true,
        drawHandlesOnHover: props.configuration.drawHandlesOnHover ?? false,
        hideHandlesIfMoving: false,
        renderDashed: false,
        shadow: true,
      },
    };
    super(defaultProps);
    this.pixelToLengthFactor = undefined;
    this.scaleFactor = undefined;
  }

  renderToolData(evt) {
    const toolData = csTools.getToolState(evt.currentTarget, this.name);

    if (!toolData) {
      return;
    }

    const eventData = evt.detail;
    const { image, element } = eventData;
    const lineWidth = csTools.toolStyle.getToolWidth();
    const lineDash = csTools.getModule('globalConfiguration').configuration.lineDash;
    const {
      handleRadius,
      drawHandlesOnHover,
      hideHandlesIfMoving,
      renderDashed,
    } = this.configuration;
    const context = getNewContext(eventData.canvasContext.canvas);
    const { rowPixelSpacing, colPixelSpacing } = getPixelSpacing(image);

    // Meta
    const seriesModule =
      csTools.external.cornerstone.metaData.get('generalSeriesModule', image.imageId) ||
      {};

    // Pixel Spacing
    const modality = seriesModule.modality;
    const hasPixelSpacing = rowPixelSpacing && colPixelSpacing;
    const pixelToLengthFactor = this.pixelToLengthFactor;
    const scaleFactor = this.scaleFactor;


    draw(context, context => {
      // If we have tool data for this element - iterate over each set and draw it
      for (let i = 0; i < toolData.data.length; i++) {
        const data = toolData.data[i];

        if (data.visible === false) {
          continue;
        }

        // Configure
        const color = csTools.toolColors.getColorIfActive(data);
        const handleOptions = {
          color,
          handleRadius,
          drawHandlesIfActive: drawHandlesOnHover,
          hideHandlesIfMoving,
        };

        setShadow(context, this.configuration);

        const rectOptions = { color };

        if (renderDashed) {
          rectOptions.lineDash = lineDash;
        }

        // Draw
        drawRect(
          context,
          element,
          data.handles.start,
          data.handles.end,
          rectOptions,
          'pixel',
          data.handles.initialRotation
        );

        if (this.configuration.drawHandles) {
          drawHandles(context, eventData, data.handles, handleOptions);
        }

        // Update textbox stats
        if (data.invalidated === true) {
          if (data.cachedStats) {
            this.throttledUpdateCachedStats(image, element, data);
          } else {
            this.updateCachedStats(image, element, data);
          }
        }

        // Default to textbox on right side of ROI
        if (!data.handles.textBox.hasMoved) {
          const defaultCoords = getROITextBoxCoords(
            eventData.viewport,
            data.handles
          );

          Object.assign(data.handles.textBox, defaultCoords);
        }

        const textBoxAnchorPoints = handles =>
          _findTextBoxAnchorPoints(handles.start, handles.end);
        if (data.cachedStats) {
          const textBoxContent = _createTextBoxContent(
            context,
            image.color,
            data.cachedStats,
            modality,
            hasPixelSpacing,
            pixelToLengthFactor,
            scaleFactor,
            this.configuration
          );

          data.unit = _getUnit(modality, this.configuration.showHounsfieldUnits);

          drawLinkedTextBox(
            context,
            element,
            data.handles.textBox,
            textBoxContent,
            data.handles,
            textBoxAnchorPoints,
            color,
            lineWidth,
            10,
            true
          );
        }
      }
    });
  }

  updateCachedStats(image, element, data) {
    if (data?.toolName !== this.name) return;
    const seriesModule =
      csTools.external.cornerstone.metaData.get('generalSeriesModule', image.imageId) ||
      {};
    const modality = seriesModule.modality;
    const pixelSpacing = getPixelSpacing(image);

    const stats = _calculateStats(
      image?.image_raw ? image.image_raw : image,
      image,
      element,
      data.handles,
      modality,
      pixelSpacing,
      this.pixelToLengthFactor,
      this.scaleFactor,
    );

    data.cachedStats = stats;
    data.invalidated = false;
  }

  restore(element, data) {
    var xs = data.handles.start.x;
    var ys = data.handles.start.y;

    let measurement = super.createNewMeasurement(
      {
        currentPoints: { image: { x: xs, y: ys } },
        viewport: { rotation: undefined },
        element: element
      });

    measurement.handles.end.x = data.handles.end.x;
    measurement.handles.end.y = data.handles.end.y;
    measurement.handles.textBox = JSON.parse(JSON.stringify(data.handles.textBox));

    measurement.handles.initialRotation = data.handles.initialRotation;

    return measurement;
  }

  store(toolData) {
    toolData.storeToolData = {
      "handles": toolData.handles,
    }
  }

  setLenghtCalibration(element, pixelToLengthFactor) {
    const toolData = getToolState(element?.element, this.name);
    this.pixelToLengthFactor = global.structuredClone(pixelToLengthFactor);

    if (!toolData) {
      return;
    }

    for (let i = 0; i < toolData.data.length; i++) {
      this.updateCachedStats(element?.image, element?.element, toolData.data[i]);
    }
  }

  setScaleCalibration(element, scaleFactor) {
		const toolData = getToolState(element?.element, this.name);
		this.scaleFactor = scaleFactor;

		if (!toolData) {
			return;
		}

		for (let i = 0; i < toolData.data.length; i++) {
			this.updateCachedStats(element?.image, element?.element, toolData.data[i]);
		}
	}

}

function _getRectangleImageCoordinates(startHandle, endHandle) {
  return {
    left: Math.min(startHandle.x, endHandle.x),
    top: Math.min(startHandle.y, endHandle.y),
    width: Math.abs(startHandle.x - endHandle.x),
    height: Math.abs(startHandle.y - endHandle.y),
  };
}

function _formatArea(area, hasPixelSpacing, pixelToLengthFactor) {
  // This uses Char code 178 for a superscript 2
  const suffix = hasPixelSpacing || pixelToLengthFactor !== undefined
    ? ` mm${String.fromCharCode(178)}`
    : ` px${String.fromCharCode(178)}`;

  return `${t('area', {ns: 'Tools'})}: ${numbersWithCommas(area.toFixed(2))}${suffix}`;
}

function _getUnit(modality, showHounsfieldUnits) {
  return modality === 'CT' && showHounsfieldUnits !== false ? 'HU' : '';
}


function _findTextBoxAnchorPoints(startHandle, endHandle) {
  const { left, top, width, height } = _getRectangleImageCoordinates(
    startHandle,
    endHandle
  );

  return [
    {
      // Top middle point of rectangle
      x: left + width / 2,
      y: top,
    },
    {
      // Left middle point of rectangle
      x: left,
      y: top + height / 2,
    },
    {
      // Bottom middle point of rectangle
      x: left + width / 2,
      y: top + height,
    },
    {
      // Right middle point of rectangle
      x: left + width,
      y: top + height / 2,
    },
  ];
}

function _createTextBoxContent(
  context,
  isColorImage,
  { area, width, height, widthPx, heightPx, mean, stdDev, min, max, meanStdDevSUV, resolution },
  modality,
  hasPixelSpacing,
  pixelToLengthFactor,
  scaleFactor,
  options = {}
) {
  const showMinMax = options.showMinMax || false;
  const textLines = [];

  const otherLines = [];

  if (!isColorImage) {
    const hasStandardUptakeValues = meanStdDevSUV && meanStdDevSUV.mean !== 0;
    const unit = _getUnit(modality, options.showHounsfieldUnits);

    let meanString = `${t('mean', {ns: 'Tools'})}: ${numbersWithCommas(mean.toFixed(2))} ${unit}`;
    const stdDevString = `${t('stdDev', {ns: 'Tools'})}: ${numbersWithCommas(
      stdDev.toFixed(2)
    )} ${unit}`;

    const SNRString = `SNR: ${numbersWithCommas(
      (mean / stdDev)?.toFixed(2)
    )}`;

    const SNRnString = `SNRn: ${numbersWithCommas(
      (((mean / stdDev) * 0.886) / (resolution * 10.0))?.toFixed(2)
    )}`;

    // If this image has SUV values to display, concatenate them to the text line
    if (hasStandardUptakeValues) {
      const SUVtext = ' SUV: ';

      const meanSuvString = `${SUVtext}${numbersWithCommas(
        meanStdDevSUV.mean.toFixed(2)
      )}`;
      const stdDevSuvString = `${SUVtext}${numbersWithCommas(
        meanStdDevSUV.stdDev.toFixed(2)
      )}`;

      const targetStringLength = Math.floor(
        context.measureText(`${stdDevString}     `).width
      );

      while (context.measureText(meanString).width < targetStringLength) {
        meanString += ' ';
      }

      otherLines.push(`${meanString}${meanSuvString}`);
      otherLines.push(`${stdDevString}     ${stdDevSuvString}`);
    } else {
      const widthString = `${t('width', {ns: 'Tools'})}: ${(width).toFixed(2)} mm / ${widthPx.toFixed(0)} px`
      const heightString = `${t('height', {ns: 'Tools'})}: ${(height).toFixed(2)} mm / ${heightPx.toFixed(0)} px`
      otherLines.push(widthString);
      otherLines.push(heightString);
      otherLines.push(`${meanString}`);
      otherLines.push(`${stdDevString}`);
      otherLines.push(`${SNRString}`);
    }
    if (resolution && resolution !== '') otherLines.push(`${SNRnString}`);

    if (showMinMax) {
      let minString = `Min: ${min} ${unit}`;
      const maxString = `Max: ${max} ${unit}`;
      const targetStringLength = hasStandardUptakeValues
        ? Math.floor(context.measureText(`${stdDevString} `).width)
        : Math.floor(context.measureText(`${meanString} `).width);

      /* while (context.measureText(minString).width < targetStringLength) {
        minString += ' ';
      } */
      minString += ' ';

      otherLines.push(`${minString}${maxString}`);
    }
  }

  textLines.push(_formatArea(area, hasPixelSpacing, pixelToLengthFactor));
  otherLines.forEach(x => textLines.push(x));

  return textLines;
}

function _calculateStats(image, image_org, element, handles, modality, pixelSpacing, pixelToLengthFactor, scaleFactor) {
  // Retrieve the bounds of the rectangle in image coordinates
  const roiCoordinates = _getRectangleImageCoordinates(
    handles.start,
    handles.end
  );

  // Retrieve the array of pixels that the rectangle bounds cover
  const pixels = getPixels(
    element, image,
    roiCoordinates.left,
    roiCoordinates.top,
    roiCoordinates.width,
    roiCoordinates.height
  );

  // Calculate the mean & standard deviation from the pixels and the rectangle details
  const roiMeanStdDev = _calculateRectangleStats(pixels, roiCoordinates);

  // Fetch resolution from annotation
  const imageId = image_org?.targetId;
  const annotations = selectedAnnotations(store.getState(), imageId);
  const resolution = annotations?.find((annotation) => annotation?.body?.type === "ResolutionMeasurementResult")?.body?.value?.resolution;

  let meanStdDevSUV;

  if (modality === 'PT') {
    meanStdDevSUV = {
      mean: calculateSUV(image, roiMeanStdDev.mean, true) || 0,
      stdDev: calculateSUV(image, roiMeanStdDev.stdDev, true) || 0,
    };
  }

  // Calculate the image area from the rectangle dimensions and pixel spacing
  const width = roiCoordinates.width * (pixelToLengthFactor?.col || pixelSpacing.colPixelSpacing || 1) * (scaleFactor ?? 1);
  const height = roiCoordinates.height * (pixelToLengthFactor?.row || pixelSpacing.rowPixelSpacing || 1) * (scaleFactor ?? 1)
  const area = width * height;

  const perimeter =
    roiCoordinates.width * 2 * (pixelToLengthFactor?.col || pixelSpacing.colPixelSpacing || 1) * (scaleFactor ?? 1) +
    roiCoordinates.height * 2 * (pixelToLengthFactor?.row || pixelSpacing.rowPixelSpacing || 1)* (scaleFactor ?? 1);

  return {
    area: area || 0,
    width: width,
    height: height,
    widthPx: roiCoordinates.width,
    heightPx: roiCoordinates.height,
    perimeter,
    count: roiMeanStdDev.count || 0,
    mean: roiMeanStdDev.mean || 0,
    variance: roiMeanStdDev.variance || 0,
    stdDev: roiMeanStdDev.stdDev || 0,
    min: roiMeanStdDev.min || 0,
    max: roiMeanStdDev.max || 0,
    meanStdDevSUV,
    resolution: parseFloat(resolution)
  };
}

/**
 *
 *
 * @param {*} sp
 * @param {*} rectangle
 * @returns {{ count, number, mean: number,  variance: number,  stdDev: number,  min: number,  max: number }}
 */
function _calculateRectangleStats(sp, rectangle) {
  let sum = 0;
  let sumSquared = 0;
  let count = 0;
  let index = 0;
  let min = sp ? sp[0] : null;
  let max = sp ? sp[0] : null;

  for (let y = rectangle.top; y < rectangle.top + rectangle.height; y++) {
    for (let x = rectangle.left; x < rectangle.left + rectangle.width; x++) {
      sum += sp[index];
      sumSquared += sp[index] * sp[index];
      min = Math.min(min, sp[index]);
      max = Math.max(max, sp[index]);
      count++; // TODO: Wouldn't this just be sp.length?
      index++;
    }
  }

  if (count === 0) {
    return {
      count,
      mean: 0.0,
      variance: 0.0,
      stdDev: 0.0,
      min: 0.0,
      max: 0.0,
    };
  }

  const mean = sum / count;
  const variance = sumSquared / count - mean * mean;

  return {
    count,
    mean,
    variance,
    stdDev: Math.sqrt(variance),
    min,
    max,
  };
}
