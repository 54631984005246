import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import { apiSlice, deviceSet } from "../../apis/apiSlice";
import { selectDeviceRunnerPath, selectedDeviceSetId } from "./AcquisitionSlice";
import GenericDevice from "./GenericDevice";
import './AcquisitionPanel.scss';
import { useEffect } from "react";
import { getSelectedWorkitem } from "../OrderList/OrdersSlice";

interface AcquisitionPanelProps {
    orderId: string;
    deviceSet: deviceSet;
}

const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
const useAppDispatch = () => useDispatch<AppDispatch>();

const AcquisitionPanel = (props: AcquisitionPanelProps) => {
    const dispatch = useAppDispatch();

    const deviceRunnerPath: string = useAppSelector((state) => selectDeviceRunnerPath(state));
    const currentDeviceSetId: number = useAppSelector((state) => selectedDeviceSetId(state));
    const currentWorkitemAsString: string | undefined = useAppSelector((state) => getSelectedWorkitem(state));
    const currentWorkitem = currentWorkitemAsString ? JSON.parse(currentWorkitemAsString) : undefined;

    /* useEffect(() => {
        if (currentDeviceSetId === props.deviceSet?.id) {
            try {
                console.log("putChangeOperationMode true");
                dispatch(apiSlice.endpoints.putChangeOperationMode.initiate(
                    {
                        deviceRunnerPath: deviceRunnerPath, qualifier: currentDeviceSetId,
                        active: true, studyId: props.orderId, workitemId: currentWorkitem?.id,
                        procedureCode: currentWorkitem?.details?.procedureCode

                    })).unwrap();
            } catch (error) {
                dispatch({ type: "Acquisition/setDeviceRunnerError", payload: 'device runner connection error' });;
            }
        }
        return () => {
            if (currentDeviceSetId === props.deviceSet?.id) {
                console.log("putChangeOperationMode false");
                dispatch(apiSlice.endpoints.putChangeOperationMode.initiate(
                    {
                        deviceRunnerPath: deviceRunnerPath, qualifier: currentDeviceSetId,
                        active: false, studyId: props.orderId, workitemId: currentWorkitem?.id
                    })).unwrap();
            }
        }
    }, [currentDeviceSetId]); */

    return (
        <div className="AcquisitionPanelWrapper">
            {props?.deviceSet?.config?.devices?.map((deviceId: number, j: number) => (
                <GenericDevice orderId={props.orderId} deviceSet={props?.deviceSet} deviceId={deviceId} deviceSetId={props?.deviceSet?.id} key={props?.deviceSet?.id.toString() + "_" + deviceId.toString()} />
            ))}
            {/* <ExposureParameters orderId={props.orderId} deviceSet={props?.deviceSet}/> */}
        </div>
    );
}

export default AcquisitionPanel;
