import React from "react";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";

import "./OrderInfoPanel.scss";
import { getSelectedWorkitem, selectArtifactForSelectedWorkitem, selectArtifactIdForSelectedWorkitem, selectedStudy,
    selectInstanceForWorkitem, selectSeriesDataForWorkitem } from "./OrdersSlice";
import { selectedWorkitem, selectOrderInfoVisible } from "../ImageDisplay/ImageDisplaySlice";
import InfoPanel from "../Utils/ConfigurableInfoPanel/InfoPanel";
import CommonDialog from "../Utils/CommonDialog";
import { selectAllowComplete } from "./MainOrderListSlice";
import { Constants } from "../../Constants";
import { useORTranslation } from "../Localization/ORLocalization";

type OrderInfoProps = {
    orderId: string;
    default: { x: number, y: number, width: number, height: number | undefined };
};

const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
const useAppDispatch = () => useDispatch<AppDispatch>();

const OrderInfoPanel = (props: OrderInfoProps) => {
    const dispatch = useAppDispatch();

    const { t } = useORTranslation(['common', 'InfoPanel']);

    const currentWorkitemId: string = useAppSelector((state) => selectedWorkitem(state));
    //const study: any = useAppSelector((state) => selectedStudy(state, props.orderId ?? ''));
    const currentStudyAsString: string | undefined = useAppSelector((state) => selectedStudy(state, props.orderId ?? ''));
    const study = currentStudyAsString ? JSON.parse(currentStudyAsString) : undefined;
    const currentWorkitemAsString: string | undefined = useAppSelector((state) => getSelectedWorkitem(state));
    const currentWorkitem = currentWorkitemAsString ? JSON.parse(currentWorkitemAsString) : undefined;
    const currentSeriesAsString: string | undefined = useAppSelector((state) => selectSeriesDataForWorkitem(state, currentWorkitemId));
    const currentSeries = currentSeriesAsString ? JSON.parse(currentSeriesAsString) : undefined;
    const currentInstanceAsString: string | undefined = useAppSelector((state) => selectInstanceForWorkitem(state, currentWorkitemId));
    const currentInstance = currentInstanceAsString ? JSON.parse(currentInstanceAsString) : undefined;
    const currentArtifactAsString: string | undefined = useAppSelector((state) => selectArtifactForSelectedWorkitem(state));
    const currentArtifact = currentArtifactAsString ? JSON.parse(currentArtifactAsString) : undefined;

    const currentArtifactId: string | undefined = useAppSelector((state) => selectArtifactIdForSelectedWorkitem(state));
    const allowComplete: boolean = useAppSelector((state) => selectAllowComplete(state));

    const isOrderInfoVisible: boolean = useAppSelector((state) => selectOrderInfoVisible(state));

    const handelClose = () => {
        dispatch({ type: "ImageDisplay/setOrderInfoVisible", payload: false });
    };

    return (
        <CommonDialog onClose={handelClose} title={t(`${Constants.ORDER_IDENTIFIER}Info`, {ns: 'InfoPanel'})} visible={isOrderInfoVisible} okText={t("OK")} cancelText={t("cancel")} default={props.default} childProps={{}}>
            <InfoPanel className="infoPanelWrapper" configFile="/OrderDescConfig.json"
                sources={{ currentStudy: study, currentWorkitem, currentSeries, currentInstance, currentArtifact }}
                workitemId={currentWorkitemId} artifactId={currentArtifactId} orderId={props.orderId}
                editMode={false} editEnabled={study && (study.state !== 'COMPLETED' || !allowComplete)}/>
        </CommonDialog>
    );
}

export default OrderInfoPanel;
