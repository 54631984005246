import csTools from 'cornerstone-tools';
import cornerstone from "cornerstone-core";
import { getStoredPixels } from "./utils";
const getRGBPixels = csTools.importInternal('util/getRGBPixels');
const calculateSUV = csTools.importInternal('util/calculateSUV');
const drawHandles = csTools.importInternal("drawing/drawHandles");
const getNewContext = csTools.import("drawing/getNewContext");
const getToolState = csTools.getToolState;
const draw = csTools.import("drawing/draw");
const drawTextBox = csTools.import("drawing/drawTextBox");
const toolColors = csTools.toolColors;
const getModule = csTools.getModule;
const setShadow = csTools.import("drawing/setShadow");

/**
 * @public
 * @class ProbeRawTool
 * @memberof Tools
 *
 * @classdesc Probe Tool.
 * @extends Tools.Annotation.ProbeTool
 */
export default class ProbeRawTool extends csTools.ProbeTool {
  /** @inheritdoc */
  constructor(props = {}) {
    const defaultProps = {
      name: 'ProbeRaw',
      supportedInteractionTypes: ['Mouse', 'Touch'],
      configuration: {
        showMinMax: true,
        //showHounsfieldUnits: true,
        //drawHandlesOnHover: false,
        //hideHandlesIfMoving: false,
        renderDashed: false,
        shadow: true,
      },
    };
    super(defaultProps);
  }

  updateCachedStats(image, element, data) {

    if (data?.toolName !== this.name) {
      return;
    }

    const x = Math.round(data.handles.end.x);
    const y = Math.round(data.handles.end.y);

    const stats = {};

    if (x >= 0 && y >= 0 && x < image.columns && y < image.rows) {
      stats.x = x;
      stats.y = y;

      if (image.color) {
        stats.storedPixels = getRGBPixels(element, x, y, 1, 1);
      } else {
        if (image.image_raw) {
          stats.storedPixels = getStoredPixels(
            image.image_raw,
            x,
            y,
            1,
            1
          );
        } else {
          stats.storedPixels = cornerstone.getStoredPixels(
            element,
            x,
            y,
            1,
            1
          );
        }
        stats.sp = stats.storedPixels[0];
        stats.mo = stats.sp * image.slope + image.intercept;
        stats.suv = calculateSUV(image, stats.sp);
      }
    }

    data.cachedStats = stats;
    data.invalidated = false;


  }

  renderToolData(evt) {
    const eventData = evt.detail;
    const { handleRadius, renderDashed } = this.configuration;
    const toolData = getToolState(evt.currentTarget, this.name);

    if (!toolData) {
      return;
    }

    // We have tool data for this element - iterate over each one and draw it
    const context = getNewContext(eventData.canvasContext.canvas);
    const { image, element } = eventData;
    const fontHeight = csTools.textStyle.getFontSize();
    const lineDash = getModule('globalConfiguration').configuration.lineDash;

    for (let i = 0; i < toolData.data.length; i++) {
      const data = toolData.data[i];

      if (data.visible === false) {
        continue;
      }

      draw(context, context => {
        const color = toolColors.getColorIfActive(data);

        setShadow(context, this.configuration);

        if (this.configuration.drawHandles) {
          // Draw the handles
          const handleOptions = { handleRadius, color };

          if (renderDashed) {
            handleOptions.lineDash = lineDash;
          }

          drawHandles(context, eventData, data.handles, handleOptions);
        }

        // Update textbox stats
        if (data.invalidated === true) {
          if (data.cachedStats) {
            this.throttledUpdateCachedStats(image, element, data);
          } else {
            this.updateCachedStats(image, element, data);
          }
        }

        let text, str;

        if (data.cachedStats) {
          const { x, y, storedPixels, sp, suv } = data.cachedStats;

          if (x >= 0 && y >= 0 && x < image.columns && y < image.rows) {
            text = `${x}, ${y}`;

            if (image.color) {
              str = `R: ${storedPixels[0]} G: ${storedPixels[1]} B: ${storedPixels[2]
                }`;
            } else {
              // Draw text
              //str = `SP: ${sp} MO: ${parseFloat(mo.toFixed(3))}`;
              str = `SP: ${sp}`;
              if (suv) {
                str += ` SUV: ${parseFloat(suv.toFixed(3))}`;
              }
            }

            // Coords for text
            const coords = {
              // Translate the x/y away from the cursor
              x: data.handles.end.x + 3,
              y: data.handles.end.y - 3,
            };
            const textCoords = cornerstone.pixelToCanvas(
              eventData.element,
              coords
            );

            drawTextBox(
              context,
              str,
              textCoords.x,
              textCoords.y + fontHeight + 5,
              color
            );
            drawTextBox(context, text, textCoords.x, textCoords.y, color);
          }
        }
      });
    }
  }

  restore(element, data) {
    var xe = data.handles.end.x;
    var ye = data.handles.end.y;

    let measurement = super.createNewMeasurement(
      {
        currentPoints: { image: { x: xe, y: ye } },
        viewport: { rotation: undefined },
        element: element
      });

    measurement.handles.end.x = data.handles.end.x;
    measurement.handles.end.y = data.handles.end.y;

    return measurement;
  }

  store(toolData) {
    toolData.storeToolData = {
      "handles": toolData.handles,
    }
  }


}
