import cornerstone, { EnabledElement } from "cornerstone-core";
import * as cornerstoneTools from "cornerstone-tools";
import { throttle } from 'throttle-debounce';
import React, { useCallback, useEffect, useRef, useState } from "react";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, ResponsiveContainer, ReferenceLine } from "recharts";
import { Constants } from "../../Constants";
import { AppDispatch, RootState } from "../../store";
import { getLengthCalibrationActive, getMaxLineWidth, selectToolInsetVisible } from "./ImageDisplaySlice";
import "./LengthCalibrationHist.scss";
import HoverRangeslider from "../Utils/HoverRangeslider";
import { calculateTicks, getTargetIdsForElement, setGetToolStateItems, updateAnnnotationOnInputChange } from "./Tools/utils";
import { lmin } from "./PipeWallThicknessHist";
import { edit, revoke, storeData } from "./Tools/Restorable";
import { submit } from "../Utils/ConfirmDialog";
import { useORTranslation } from "../Localization/ORLocalization";
import { getImagePathForCurrentTheme } from "../OrderList/OrdersSlice";

const getPixelSpacing = cornerstoneTools.importInternal('util/getPixelSpacing');

type LengthCalibrationHistProps = {
  orderId: string;
  matrixIndex: number;
};

const defaultColPixelSpacing = 0.1;
const defaultRowPixelSpacing = 0.1;

const xlineCaptureRegion: number = 2;

const toolname: string = 'LengthCalibration';

const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
const useAppDispatch = () => useDispatch<AppDispatch>();

const LengthCalibrationHist = (props: LengthCalibrationHistProps) => {
  const dispatch = useAppDispatch();

  const { t } = useORTranslation(['Tools', 'common']);

  const [chartDragActive, setChartDragActive] = useState<number>(-1);
  const [isXlinesHifhlight, setIsXlinesHifhlight] = useState<number>(-1);
  const [currentHist, setCurrentHist] = useState<number>(1);
  const [colPixelSpacing, setColPixelSpacing] = useState<number>(defaultColPixelSpacing);
  const [rowPixelSpacing, setRowPixelSpacing] = useState<number>(defaultRowPixelSpacing);
  const [yMin, setYMin] = useState<number>(0);
  const [yMax, setYMax] = useState<number>(10000);
  const [yMin1, setYMin1] = useState<number>(0);
  const [yMax1, setYMax1] = useState<number>(10000);
  const [yMin2, setYMin2] = useState<number>(0);
  const [yMax2, setYMax2] = useState<number>(10000);
  const [xlines, setXlines] = useState<number[]>([]);
  const [histData, setHistData] = useState<{ x: number; y: number }[]>([]);
  const [windowMax, setwindowMax] = useState<number>(4096);
  const [lineWidth, setLineWidth] = useState<number>(1);
  const [windowSize, setwindowSize] = useState<{ width: number, height: number }>({ width: 440, height: 440 });
  const [selectedToolstateIndex, setSelectedToolstateIndex] = useState<number>(0);
  const [measuredLength, setMeasuredLength] = useState<number>(0);
  const [lengthMeasurementLocked, setLengthMeasurementLocked] = useState<boolean>(false);
  const [pixelspacingChanged, setPixelspacingChanged] = useState<boolean>(false);
  const [imageHaspixelspacing, setImageHaspixelspacing] = useState<boolean>(false);
  const [custompixelspacing, setCustompixelspacing] = useState<boolean>(false);

  const lineWidthRef = useRef<number>();
  lineWidthRef.current = lineWidth;
  const measuredLengthRef = useRef<number>();
  measuredLengthRef.current = measuredLength;
  const lengthMeasurementLockedRef = useRef<boolean>();
  lengthMeasurementLockedRef.current = lengthMeasurementLocked;
  const selectedToolstateIndexRef = useRef<number>();
  selectedToolstateIndexRef.current = selectedToolstateIndex;
  const chartDragActiveRef = useRef<number>();
  chartDragActiveRef.current = chartDragActive;
  const colPixelSpacingRef = useRef<number>();
  colPixelSpacingRef.current = colPixelSpacing;
  const rowPixelSpacingRef = useRef<number>();
  rowPixelSpacingRef.current = rowPixelSpacing;

  const xlinesRef = useRef<number[]>();
  xlinesRef.current = xlines;

  const cornerstoneElements: EnabledElement[] = cornerstone.getEnabledElements();
  const isLengthCalibration: boolean | undefined = useAppSelector((state) => getLengthCalibrationActive(state, props.matrixIndex ?? 0));
  const isToolInsetVisible: boolean | undefined = useAppSelector((state) => selectToolInsetVisible(state));
  const imagePathForCurrentTheme: string = useAppSelector((state) => getImagePathForCurrentTheme(state));
  const maxLineWidth: number = useAppSelector((state) => getMaxLineWidth(state));

  const displayElement: EnabledElement | undefined = cornerstoneElements?.find(
    (element: EnabledElement) => element.element.id === `${Constants.IMAGE_DISPLAY_GENERIC_ELEMENT_NAME}_${props.matrixIndex}`
  );

  const handleMouseDown = (evt: any) => {
    if (evt?.activePayload && evt?.activePayload.length > 0 && evt?.activePayload[0].payload) {
      const ret = setGetToolStateItems(selectedToolstateIndexRef.current ?? 0, [], ["measurementaccepted"], props.matrixIndex, toolname);
      if (!ret[0] && xlinesRef.current) {
        for (let i = 0; i < xlinesRef.current.length; i++) {
          if (Math.abs(evt?.activePayload[0].payload.x - xlinesRef.current[i]) < xlineCaptureRegion) {
            setChartDragActive(i);
            break;
          }
        }
      }
    }
  };

  const handleMouseUp = (evt: React.MouseEvent<Element>) => {
    if ((chartDragActiveRef.current ?? -1) >= 0) {
      updateAnnnotationOnInputChange(selectedToolstateIndexRef.current, props.matrixIndex, toolname);
    }
    setChartDragActive(-1);
  };

  function handleMeasurentLength(evt: any) {
    evt.stopPropagation();
    evt.preventDefault();
    setMeasuredLength(evt.target.value);
    setGetToolStateItems(selectedToolstateIndexRef.current ?? 0, [{ name: "measuredLength", value: evt?.target?.value }], [], props.matrixIndex, toolname);
    throttleUpdateAnnnotationOnInputChange(selectedToolstateIndexRef.current, props.matrixIndex, toolname);
  }

  const handleColPixelSpacingInput = (evt: any) => {
    evt.stopPropagation();
    evt.preventDefault();
    setPixelspacingChanged(true);
    setColPixelSpacing(evt?.target?.value);
  };

  const handleRowPixelSpacingInput = (evt: any) => {
    evt.stopPropagation();
    evt.preventDefault();
    setPixelspacingChanged(true);
    setRowPixelSpacing(evt?.target?.value);
  };

  async function locklengthMeasurent(evt: any) {
    evt.stopPropagation();
    evt.preventDefault();
    setLengthMeasurementLocked(true);

    const stateManager = cornerstoneTools.getElementToolStateManager(displayElement?.element);
    const toolstate = stateManager.get(displayElement?.element, toolname);
    const tool = cornerstoneTools.getToolForElement(displayElement?.element, toolname);
    const ret = setGetToolStateItems(selectedToolstateIndexRef.current, [], ["measuredLength", "pixelSpacingLength", "xlines", "length"], props.matrixIndex, toolname);

    let scaleFactor: number | undefined = undefined;

    if (ret[2] !== undefined && Array.isArray(ret[2]) && ret[2].length > 1 &&
      ret[0] !== undefined && ret[1] !== undefined && ret[3] !== undefined && ret[3] > 0) {

      const rawLenght = Math.abs(ret[2][1] - ret[2][0]);
      const rawLenghtWithPixelSpacing = rawLenght * ret[1] / ret[3];

      scaleFactor = ret[0] / rawLenghtWithPixelSpacing;

      // change flag for accepted measurement to selected annotation
      if (toolstate?.data && displayElement) {
        for (let i = 0; i < toolstate?.data.length; i++) {
          if (scaleFactor !== undefined) {
            if (i !== selectedToolstateIndexRef.current) {
              setGetToolStateItems(i, [{ name: "measurementaccepted", value: false }], [], props.matrixIndex, toolname);
            } else {
              setGetToolStateItems(i, [{ name: "measurementaccepted", value: true }], [], props.matrixIndex, toolname);
            }
            updateAnnnotationOnInputChange(i, props.matrixIndex, toolname);
          }
        }

        // store new scaleFactor
        if (scaleFactor !== undefined) {
          const targetIds: string[] | undefined = getTargetIdsForElement(displayElement);
          if (tool?.scaleFactorAnnotationId) {
            const data = { toolName: "ScaleCalibrationResult", storeToolData: { scaleFactor: scaleFactor }, annotationId: tool?.scaleFactorAnnotationId };
            edit(data, targetIds, dispatch);
          } else {
            const data = { toolName: "ScaleCalibrationResult", storeToolData: { scaleFactor: scaleFactor }, annotationId: -1 };
            await storeData(data, targetIds, dispatch);
            if (tool) {
              tool.scaleFactorAnnotationId = data?.annotationId;
              tool.scaleFactor = scaleFactor;
            }
          }
        }

        // update all other tools which depends on length calibration
        const toolsToUpdate = cornerstoneTools.store?.state?.tools?.filter(
          (tool: any) =>
            tool.element === displayElement?.element && tool.setScaleCalibration
        );
        toolsToUpdate?.forEach((tool: any) => {
          if (tool?.setScaleCalibration) {
            tool.setScaleCalibration(displayElement, scaleFactor);
          }
        });

        if (selectedToolstateIndexRef.current !== undefined) {
          const ret = setGetToolStateItems(selectedToolstateIndexRef.current, [], ["linedata", "max", "max1", "min1", "xlines", "length", "scale"], props.matrixIndex, toolname);
          throttleOnValueChange({ linedata: ret[0], max: ret[1], max1: ret[2], min1: ret[3], xlines: ret[4], length: ret[5], scale: ret[6] });
        }
        cornerstone.updateImage(displayElement?.element);
      }
    }
  }

  async function lockPixelspacing(evt: any) {
    evt.stopPropagation();
    evt.preventDefault();
    setPixelspacingChanged(false);

    // store new pixelToLengthFactor
    let pixelToLengthFactor = { row: rowPixelSpacingRef.current, col: colPixelSpacingRef.current };
    const tool = cornerstoneTools.getToolForElement(displayElement?.element, toolname);

    if (pixelToLengthFactor !== undefined) {
      const targetIds: string[] | undefined = getTargetIdsForElement(displayElement);
      if (tool?.resultAnnotationId) {
        const data = { toolName: "LenghtCalibrationResult", storeToolData: { pixelToLengthFactor: pixelToLengthFactor }, annotationId: tool?.resultAnnotationId };
        edit(data, targetIds, dispatch);
      } else {
        const data = { toolName: "LenghtCalibrationResult", storeToolData: { pixelToLengthFactor: pixelToLengthFactor }, annotationId: -1 };
        await storeData(data, targetIds, dispatch);
        if (tool) {
          tool.resultAnnotationId = data?.annotationId;
          tool.pixelToLengthFactor = structuredClone(pixelToLengthFactor);
        }
      }
    }

    setCustompixelspacing(true);

    // update all other tools which depends on length calibration
    if (displayElement) {
      const toolsToUpdate = cornerstoneTools.store?.state?.tools?.filter(
        (tool: any) =>
          tool.element === displayElement?.element && tool.setLenghtCalibration
      );
      toolsToUpdate?.forEach((tool: any) => {
        tool?.setLenghtCalibration(displayElement, pixelToLengthFactor)
      });

      if (selectedToolstateIndexRef.current !== undefined) {
        const ret = setGetToolStateItems(selectedToolstateIndexRef.current, [], ["linedata", "max", "max1", "min1", "xlines", "length", "scale"], props.matrixIndex, toolname);
        throttleOnValueChange({ linedata: ret[0], max: ret[1], max1: ret[2], min1: ret[3], xlines: ret[4], length: ret[5], scale: ret[6] });
        if (ret && ret.length > 6) {
          setXlines(ret[4]?.map((value: number) => { return value * ret[6] }));
          setYMax(Math.floor((ret[1]) + 1));
          setHistData(ret[0]?.map((value: number, i: number) => { return { x: ((i + 0.5) / ret[0].length) * ret[5] * ret[6], y: value } }));
          setYMax1(Math.floor(ret[2] + 1));
          setYMin1(Math.floor(ret[3] - 1));
        }
      }
      cornerstone.updateImage(displayElement?.element);
    }
  }

  function resetPixelSpacing(evt: any) {

    if (displayElement) {

      const tool = cornerstoneTools.getToolForElement(displayElement?.element, toolname);
      const targetIds: string[] | undefined = getTargetIdsForElement(displayElement);
      if (tool?.resultAnnotationId) {
        const data = { toolName: "LenghtCalibrationResult", storeToolData: { pixelToLengthFactor: { row: rowPixelSpacingRef.current, col: colPixelSpacingRef.current } }, annotationId: tool?.resultAnnotationId };
        revoke(displayElement?.element, data, targetIds, dispatch);
      }

      // update all other tools which depends on length calibration
      const toolsToUpdate = cornerstoneTools.store?.state?.tools?.filter(
        (tool: any) =>
          tool.element === displayElement?.element && tool.setLenghtCalibration
      );
      toolsToUpdate?.forEach((tool: any) => {
        if (tool?.setLenghtCalibration) {
          tool.setLenghtCalibration(displayElement, undefined);
          tool.resultAnnotationId = undefined;
        }
      });

      if (imageHaspixelspacing && displayElement?.image) {
        let { rowPixelSpacing: orgRowPixelSpacing, colPixelSpacing: orgColPixelSpacing } = getPixelSpacing(displayElement.image);
        setRowPixelSpacing(orgRowPixelSpacing);
        setColPixelSpacing(orgColPixelSpacing);
      } else {
        setRowPixelSpacing(defaultRowPixelSpacing);
        setColPixelSpacing(defaultColPixelSpacing);
      }

      setCustompixelspacing(false);

      if (selectedToolstateIndexRef.current !== undefined) {
        const ret = setGetToolStateItems(selectedToolstateIndexRef.current, [], ["linedata", "max", "max1", "min1", "xlines", "length", "scale"], props.matrixIndex, toolname);
        throttleOnValueChange({ linedata: ret[0], max: ret[1], max1: ret[2], min1: ret[3], xlines: ret[4], length: ret[5], scale: ret[6] });
      }
      cornerstone.updateImage(displayElement?.element);
    }
  }

  function unlockScaleCalibrationClick(evt: any) {
    const stateManager = cornerstoneTools.getElementToolStateManager(displayElement?.element);
    const toolstate = stateManager.get(displayElement?.element, toolname);

    if (toolstate?.data && displayElement) {
      for (let i = 0; i < toolstate?.data.length; i++) {
        const ret = setGetToolStateItems(i, [], ["measurementaccepted"], props.matrixIndex, toolname);
        if (ret && ret.length > 0 && ret[0] === true) {
          setGetToolStateItems(i, [{ name: "measurementaccepted", value: false }], [], props.matrixIndex, toolname);
          updateAnnnotationOnInputChange(i, props.matrixIndex, toolname);
        }
      }

      setLengthMeasurementLocked(false);
      const tool = cornerstoneTools.getToolForElement(displayElement?.element, toolname);
      const targetIds: string[] | undefined = getTargetIdsForElement(displayElement);
      if (tool?.scaleFactorAnnotationId) {
        const data = { toolName: "ScaleCalibrationResult", storeToolData: { scaleFactor: tool?.scaleFactor }, annotationId: tool?.scaleFactorAnnotationId };
        revoke(displayElement?.element, data, targetIds, dispatch);
      }

      // update all other tools which depends on length calibration
      const toolsToUpdate = cornerstoneTools.store?.state?.tools?.filter(
        (tool: any) =>
          tool.element === displayElement?.element && tool.setScaleCalibration
      );
      toolsToUpdate?.forEach((tool: any) => {
        if (tool?.setScaleCalibration) {
          tool.setScaleCalibration(displayElement, undefined);
          tool.scaleFactorAnnotationId = undefined;
        }
      });

      if (selectedToolstateIndexRef.current !== undefined) {
        const ret = setGetToolStateItems(selectedToolstateIndexRef.current, [], ["linedata", "max", "max1", "min1", "xlines", "length", "scale"], props.matrixIndex, toolname);
        throttleOnValueChange({ linedata: ret[0], max: ret[1], max1: ret[2], min1: ret[3], xlines: ret[4], length: ret[5], scale: ret[6] });
      }
      cornerstone.updateImage(displayElement?.element);
    }
  }

  const xMarkerChange = (chartProps: any) => {
    if (chartProps?.activePayload && chartProps?.activePayload.length > 0 && chartProps?.activePayload[0].payload) {
      setIsXlinesHifhlight(-1);
      if (xlinesRef.current) {
        for (let i = 0; i < xlinesRef.current?.length; i++) {
          if (Math.abs(chartProps?.activePayload[0].payload.x - xlinesRef.current[i]) < xlineCaptureRegion) {
            setIsXlinesHifhlight(i);
            break;
          }
        }
      }
    }

    if ((chartDragActiveRef.current ?? -1) > -1 && chartProps?.activePayload && chartProps?.activePayload.length > 0 &&
      chartProps?.activePayload[0].payload && xlinesRef.current) {
      const newLines = structuredClone(xlinesRef.current);
      newLines[(chartDragActiveRef.current ?? 0)] = Math.max(lmin, chartProps?.activePayload[0].payload.x);
      setXlines(newLines);
      if (xlinesRef.current.length > 1) {
        const ret = setGetToolStateItems(selectedToolstateIndexRef.current ?? 0, [], ["scale"], props.matrixIndex, toolname);
        setGetToolStateItems(selectedToolstateIndexRef.current ?? 0, [{
          name: "xlines", value: newLines?.map((value: number) => { return value / (ret[0] ?? 1) })
        }], [], props.matrixIndex, toolname);
        //throttleUpdateAnnnotationOnInputChange(selectedToolstateIndexRef.current, props.matrixIndex, toolname);
      }
    }
  };

  const scaleYMax = (event: any) => {
    //event.preventDefault();
    event.stopPropagation();
    // Apply scale transform
    if (currentHist > 0) {
      switch (currentHist) {
        case 1:
          setYMax(Math.floor(yMax * (1 + event.deltaY * -0.001)) + 1);
          break;
        case 2:
          setYMin1(Math.floor(yMin1 * (1 + event.deltaY * -0.001)) + 1);
          setYMax1(Math.floor(yMax1 * (1 + event.deltaY * -0.001)) - 1);
          break;
        case 3:
          setYMin2(Math.floor(yMin2 * (1 + event.deltaY * -0.001)) + 1);
          setYMax2(Math.floor(yMax2 * (1 + event.deltaY * -0.001)) - 1);
          break;
      }
    }
  }

  const throttleUpdateAnnnotationOnInputChange = useCallback(throttle(500, (index: number | undefined, matrixIndex: number, toolname: string) => {
    updateAnnnotationOnInputChange(index, matrixIndex, toolname);
  }), [lineWidth, measuredLength, lengthMeasurementLocked, rowPixelSpacing, colPixelSpacing]);

  const throttleOnValueChange = useCallback(throttle(200, (measurementData) => {
    if (measurementData?.linedata) {
      setwindowMax(Math.floor(measurementData?.length * measurementData?.scale + 1));
      const ticks: number[] = calculateTicks(measurementData?.min, measurementData?.max, 4);
      setYMin(ticks[0]);
      setYMax(ticks[ticks?.length - 1]);
      setHistData(measurementData?.linedata?.map((value: number, i: number) => { return { x: ((i + 0.5) / measurementData?.linedata.length) * measurementData?.length * measurementData?.scale, y: value } }));
      setXlines(measurementData?.xlines?.map((value: number) => { return value * measurementData?.scale }));
    }
  }), [isLengthCalibration]);

  const throttleOnLineWidthChange = useCallback(throttle(200, (value) => {
    setLineWidth(value);
    const cornerstoneElements: EnabledElement[] = cornerstone.getEnabledElements();
    const displayElement: EnabledElement | undefined = cornerstoneElements?.find(
      (element: EnabledElement) => element.element.id === `${Constants.IMAGE_DISPLAY_GENERIC_ELEMENT_NAME}_${props.matrixIndex}`
    );
    if (displayElement?.element) {
      const ret = setGetToolStateItems(selectedToolstateIndexRef.current ?? 0, [{ name: "lineWidth", value }], ["linedata", "max", "max1", "min1", "xlines", "scale", "length", "min"], props.matrixIndex, toolname);
      setXlines(ret[4]?.map((value: number) => { return value * ret[5] }));
      const ticks: number[] = calculateTicks(ret[7], ret[1], 4);
      setYMin(ticks[0]);
      setYMax(ticks[ticks?.length - 1]);

      setHistData(ret[0]?.map((value: number, i: number) => { return { x: ((i + 0.5) / ret[0].length) * ret[6] * ret[5], y: value } }));
    }
    throttleUpdateAnnnotationOnInputChange(selectedToolstateIndexRef.current, props.matrixIndex, toolname);
  }), [isLengthCalibration]);


  useEffect(() => {
    const onMeasureModified = (ev: any) => {
      if (ev?.detail?.toolName === toolname) {
        throttleOnValueChange(ev?.detail?.measurementData);
      }
    };

    const onMeasureAdded = (ev: any) => {
      if (ev?.detail?.toolName === toolname) {
        setLineWidth(1);
        const cornerstoneElements: EnabledElement[] = cornerstone.getEnabledElements();
        const displayElement: EnabledElement | undefined = cornerstoneElements?.find(
          (element: EnabledElement) => element.element.id === `${Constants.IMAGE_DISPLAY_GENERIC_ELEMENT_NAME}_${props.matrixIndex}`
        );
        const stateManager = cornerstoneTools.getElementToolStateManager(displayElement?.element);
        const toolstate = stateManager.get(displayElement?.element, toolname);
        let currentSelectedToolstateIndex: number = 0;
        if (toolstate?.data) {
          currentSelectedToolstateIndex = toolstate?.data.length - 1;
          setSelectedToolstateIndex(currentSelectedToolstateIndex);
          const tool = cornerstoneTools.getToolForElement(displayElement?.element, toolname);
          if (tool) {
            tool.selectedToolstateIndex = currentSelectedToolstateIndex;
          }
        }

        setGetToolStateItems(currentSelectedToolstateIndex, [{ name: "lineWidth", value: 1 },
        { name: "currentToolstateIndex", value: currentSelectedToolstateIndex },
        { name: "xlines", value: ev?.detail?.measurementData?.xlines }, { name: "dragActive", value: true },
        { name: "measurementaccepted", value: false },
        { name: "pixelToLengthFactor", value: { row: rowPixelSpacingRef.current, col: colPixelSpacingRef.current } }],
          [], props.matrixIndex, toolname);

        setMeasuredLength(0);
        setLengthMeasurementLocked(false);
      }
    };

    const onCurrentToolstateIndex = (ev: any) => {
      if (ev?.detail?.data?.toolName === toolname && (ev?.detail?.data?.currentToolstateIndex !== selectedToolstateIndexRef.current || ev?.detail?.forceRedisplay)) {
        if (ev?.detail?.data?.currentToolstateIndex === -1) {
          setSelectedToolstateIndex(-1);
          setXlines([]);
          setHistData([]);
        } else {
          setSelectedToolstateIndex(ev?.detail?.data?.currentToolstateIndex);
          setXlines(structuredClone(ev?.detail?.data?.xlines?.map((value: number) => { return value * ev?.detail?.data?.scale })));
          setLineWidth(ev?.detail?.data?.lineWidth);
          setMeasuredLength(ev?.detail?.data?.measuredLength);
          setLengthMeasurementLocked(ev?.detail?.data?.measurementaccepted);

          if (ev?.detail?.data?.pixelToLengthFactor !== undefined) {
            setRowPixelSpacing(ev?.detail?.data?.pixelToLengthFactor?.y);
            setColPixelSpacing(ev?.detail?.data?.pixelToLengthFactor?.x);
          }
        }
      }
    };

    setwindowSize({ width: windowSize.width, height: 220 });

    //setLineWidth(1);


    if (displayElement?.image && displayElement?.element) {
      displayElement.element?.addEventListener(
        cornerstoneTools.EVENTS.MEASUREMENT_MODIFIED,
        onMeasureModified
      );
      displayElement.element?.addEventListener(
        cornerstoneTools.EVENTS.MEASUREMENT_ADDED,
        onMeasureAdded
      );
      /* displayElement.element?.addEventListener(
        cornerstoneTools.EVENTS.MEASUREMENT_COMPLETED,
        onMeasureCompleted
      ); */
      displayElement.element?.addEventListener(
        "currentToolstateIndex",
        onCurrentToolstateIndex
      );
    }

    return () => {
      if (displayElement) {
        displayElement.element?.removeEventListener(
          cornerstoneTools.EVENTS.MEASUREMENT_MODIFIED,
          onMeasureModified);
        displayElement.element?.removeEventListener(
          cornerstoneTools.EVENTS.MEASUREMENT_ADDED,
          onMeasureAdded
        );
        displayElement.element?.removeEventListener(
          "currentToolstateIndex",
          onCurrentToolstateIndex
        );
      }
    };
  }, [displayElement?.image?.imageId, isLengthCalibration]);




  useEffect(() => {
    if (displayElement?.image) {
      let { rowPixelSpacing: orgRowPixelSpacing, colPixelSpacing: orgColPixelSpacing } = getPixelSpacing(displayElement.image);

      if (orgRowPixelSpacing !== undefined && orgRowPixelSpacing !== undefined) {
        setImageHaspixelspacing(true);
      }

      const tool = cornerstoneTools.getToolForElement(displayElement?.element, toolname);
      if (tool?.pixelToLengthFactor) {
        orgRowPixelSpacing = tool?.pixelToLengthFactor.row;
        orgColPixelSpacing = tool?.pixelToLengthFactor.col;
        setCustompixelspacing(true);
      }

      //setHistData([]);
      const ret = setGetToolStateItems(0, [], ["linedata", "max", "max1", "min1", "xlines", "length", "scale", "min"], props.matrixIndex, toolname);
      if (ret[0]) {
        const tool = cornerstoneTools.getToolForElement(displayElement?.element, toolname);
        if (tool) {
          tool.selectedToolstateIndex = 0;
        }
        setSelectedToolstateIndex(0);
        throttleOnValueChange({ linedata: ret[0], max: ret[1], max1: ret[2], min1: ret[3], xlines: ret[4], length: ret[5], scale: ret[6], min: ret[7] });
      } else {
        setHistData([]);
      }

      if (rowPixelSpacing !== undefined) {
        setRowPixelSpacing(orgRowPixelSpacing);
      }
      if (colPixelSpacing !== undefined) {
        setColPixelSpacing(orgColPixelSpacing);
      }
    }
    return () => {
      setSelectedToolstateIndex(-1);
    };
  }, [displayElement, displayElement?.image?.imageId]);


  useEffect(() => {
    if (selectedToolstateIndex !== undefined && selectedToolstateIndex > -1) {
      const ret = setGetToolStateItems(selectedToolstateIndex, [], ["linedata", "max", "max1", "min1", "xlines", "length", "scale", "min"], props.matrixIndex, toolname);
      throttleOnValueChange({ linedata: ret[0], max: ret[1], max1: ret[2], min1: ret[3], xlines: ret[4], length: ret[5], scale: ret[6], min: ret[7] });
    }
  }, [selectedToolstateIndex]);

  useEffect(() => {
    let toolSelectedToolstateIndex: number = 0;
    const cornerstoneElements: EnabledElement[] = cornerstone.getEnabledElements();
    const displayElement: EnabledElement | undefined = cornerstoneElements?.find(
      (element: EnabledElement) => element.element.id === `${Constants.IMAGE_DISPLAY_GENERIC_ELEMENT_NAME}_${props.matrixIndex}`
    );
    if (displayElement) {
      const tool = cornerstoneTools.getToolForElement(displayElement.element, toolname);
      if (tool?.selectedToolstateIndex !== undefined) {
        toolSelectedToolstateIndex = tool?.selectedToolstateIndex;
      }
    }

    setGetToolStateItems(toolSelectedToolstateIndex,
      [{ name: "dragActive", value: false }], [], props.matrixIndex, toolname);

    const ret = setGetToolStateItems(toolSelectedToolstateIndex, [], ["linedata", "max", "max1", "min1", "xlines",
      "length", "lineWidth", "measuredLength", "measurementaccepted", "scale", "rowPixelSpacing", "colPixelSpacing", "min"], props.matrixIndex, toolname);
    throttleOnValueChange({ linedata: ret[0], max: ret[1], max1: ret[2], min1: ret[3], xlines: ret[4], length: ret[5], scale: ret[9], min: ret[12] });
    if (ret[4] && ret[9]) {
      setXlines(ret[4]?.map((value: number) => { return value * ret[9] }));
    }
    if (ret[6]) {
      setLineWidth(ret[6]);
    }
    if (ret[7]) {
      setMeasuredLength(ret[7]);
    }
    if (ret[8]) {
      setLengthMeasurementLocked(ret[8]);
    }
    if (ret[10]) {
      setRowPixelSpacing(ret[10]);
    }
    if (ret[11]) {
      setColPixelSpacing(ret[11]);
    }
  }, [isToolInsetVisible, props.matrixIndex]);

  return (
    <div className="LengthCalibrationHist">
      <div className="toolContextHeader">
        {t("LengthCalibration")}
      </div>

      <div className="chartWrapper" onContextMenu={(e) => e.preventDefault()} onWheel={(e) => scaleYMax(e)}>
        <ResponsiveContainer width="100%" height="100%" minWidth="40" minHeight="3">
          <AreaChart
            width={500}
            height={450}
            data={histData}
            margin={{
              top: 5,
              right: 18,
              left: 41,
              bottom: 0,
            }}
            //onClick={onHistClick}
            onMouseMove={(props: any) => xMarkerChange(props)}
            onMouseDown={(evt: any) => handleMouseDown(evt)}
            onMouseUp={(evt: any) => handleMouseUp(evt)}
            onMouseEnter={(evt: any) => setCurrentHist(1)}
          >
            <XAxis dataKey="x" type="number" domain={[0, 'auto']} style={{ fontSize: "0.8em" }} height={31} />
            <YAxis width={5} type="number" domain={[yMin, yMax]} style={{ fontSize: "0.8em" }} allowDataOverflow={true} />
            <CartesianGrid />
            <Area className="chartArea" type="monotone" dataKey="y" isAnimationActive={false} />
            {xlines?.map((val, i) => <ReferenceLine className={isXlinesHifhlight === i ? "xline_active" : "xline"} x={val} strokeWidth={chartDragActive === i ? 2 : 1} key={i} />)}
          </AreaChart>
        </ResponsiveContainer>
      </div>

      <div className="textblock">
        <div className="lineWidth">
          <div className="lineWidthWrapper">
            <span className="lineWidthText">{`${t('lineWidth')}:`}</span>
            {lengthMeasurementLocked ?
              <>
                <span className="lineWidthValue">{lineWidth}</span>
                <span className="lineWidthUnit">px</span>
              </>
              :
              <span className="HoverRangeInput">
                <HoverRangeslider onChange={throttleOnLineWidthChange} startValue={lineWidth} min={1} max={maxLineWidth} units={'px'} />
              </span>
            }
          </div>
        </div>



        <div className={lengthMeasurementLocked ? "referenceLengthInput_locked" : "referenceLengthInput"}>
          <div className="inputReferenceLengthWrapper">
            <label className="inputReferenceLengthLabel">{`${t('referenceLength')}:`}</label>
            <input className={lengthMeasurementLocked ? "inputReferenceLength_readonly" : "inputReferenceLength"} type="number" min="0" max="9999" value={measuredLength ?? 0}
              readOnly={lengthMeasurementLocked ? true : false} onChange={(evt) => handleMeasurentLength(evt)} />
            <span className="referenceLengthUnit">mm</span>
          </div>
          <button className={lengthMeasurementLocked ? "toolContextButton unlockInputReferenceLength_visible" : "unlockInputReferenceLength_invisible"}
            title='Revoke Scale Calibration'
            onClick={() => {
              submit([t('lenghtCalibrationWarning'), t('lenghtCalibrationHint'), t('doActionQuestion')],
                unlockScaleCalibrationClick)
            }}>
            <img className="unlockInputReferenceLengthImg"
              src={`${imagePathForCurrentTheme}delete-svgrepo-com.svg`}
              onError={(event: any) => { event.target.src = "/images/delete-svgrepo-com.svg"; event.onerror = null }}
              alt="Button Unlock PixelSpacing" />
            {t('revoke', { ns: 'common' }) as string}
          </button>
          <button className={lengthMeasurementLocked ? "acceptInputReferenceLength_invisible" : "toolContextButton acceptInputReferenceLength_visible"}
            title={t('acceptCalibration') as string} onClick={locklengthMeasurent} >
            <img className="acceptInputReferenceLengthImg"
              src={`${imagePathForCurrentTheme}check.svg`}
              onError={(event: any) => { event.target.src = "/images/check.svg"; event.onerror = null }}
              alt="Button Unlock PixelSpacing" />
            {t('accept', { ns: 'common' }) as string}
          </button>
        </div>

        <div className="pixelspacing">
          <div className="pixelSpacingWrapper">
            <label className="pixelSpacingText">{`${t('pixelSpacing')} ${t('row')}/${t('col')}:`}</label>
            <span className="rowPixelSpacingSpan">
              <input className={custompixelspacing ? "inputrowPixelSpacing_custom" : "inputrowPixelSpacing"} readOnly={custompixelspacing}
                type="number" value={rowPixelSpacing ?? defaultRowPixelSpacing} step='0.001' onChange={(evt) => handleRowPixelSpacingInput(evt)} />
              {/* <span className="pixelSpacingUnit">{'mm'}</span> */}
              <span>
                {/* <label className="colPixelSpacingText">{'Row Pixelspacing:'}</label> */}
                <label className="rowPixelSpacingText">{'\\'}</label>
                <input className={custompixelspacing ? "inputcolPixelSpacing_custom" : "inputcolPixelSpacing"} readOnly={custompixelspacing}
                  type="number" value={colPixelSpacing ?? defaultColPixelSpacing} step='0.001' onChange={(evt) => handleColPixelSpacingInput(evt)} />
                <span className="pixelSpacingUnit">{'mm'}</span>
              </span>
            </span>
          </div>
          <div className="pixelSpacingButtons">
            {custompixelspacing ?
              <button className="toolContextButton resetPixelSpacing" title={t('unlockPixelSpacing') as string}
                onClick={() => { submit([t('pixelspacingWarning'), t('lenghtCalibrationHint'), t('doActionQuestion')], resetPixelSpacing) }}>
                <img className="resetPixelSpacingImg"
                  src={`${imagePathForCurrentTheme}reset-svgrepo2-com.svg`}
                  onError={(event: any) => { event.target.src = "/images/reset-svgrepo2-com.svg"; event.onerror = null }}
                  alt="Button Unlock PixelSpacing" />
                {t('reset', { ns: 'common' }) as string}
              </button>
              : null}
            {!custompixelspacing ?
              <button className={pixelspacingChanged ? "toolContextButton lockPixelSpacing" : "toolContextButton_inactive lockPixelSpacing"} title={t('lockPixelSpacing') as string}
                onClick={() => { submit([t('pixelspacingWarning'), t('lenghtCalibrationHint'), t('doActionQuestion')], lockPixelspacing) }} >
                <img className="lockPixelSpacingImg"
                  src={`${imagePathForCurrentTheme}check.svg`}
                  onError={(event: any) => { event.target.src = "/images/check.svg"; event.onerror = null }}
                  alt="Button Lock PixelSpacing" />
                {t('set', { ns: 'common' }) as string}
              </button>
              : null}

          </div>
        </div>

      </div>
      {/* <div className="accept">
        {getAcceptProjectionCalibration()}
        {getAcceptPixelspacing()}
      </div> */}
    </div>
  );
};

export default LengthCalibrationHist;
