import React, { useEffect, useState } from "react";
import { TypedUseSelectorHook, useSelector, useDispatch } from 'react-redux'
import { useBeforeUnload } from "react-router-dom";
import ObjectSelection from './ObjectSelection';
import XRayButton from './XRayButton';
import DeviceSettingsButton from './DeviceSettingsButton';
import { DeviceType } from './DeviceSettingsButton';
import './Akquisition.scss';
import type { RootState, AppDispatch } from '../../store'
import { CatalogueEntry, getSelectedCatalogueEntry } from "./CatalogueSlice";
import { apiSlice, deviceSet, deviceWebThing, useGetDeviceDefaultDeviceSet1Query, useGetDeviceSetsQuery} from "../../apis/apiSlice";
import Device, { GeneratorStateMap, SensorStateMap } from "./Device";
import { selectCancelSoftwareTriggerUrl, selectDeviceCount, selectDeviceRunnerActive, selectDeviceRunnerError, selectDeviceSetOpen, selectDeviceStatusList, selectSoftwareTriggerState, setAcquisitionActive } from "./AcquisitionSlice";
import CommonDialog from "../Utils/CommonDialog";
import DeviceSet from "./DevicSet";
import { useORTranslation } from "../Localization/ORLocalization";
import DeviceRunnerHealth from "./DeviceRunnerHealth";
import { getImagePathForCurrentTheme, getLayout } from "../OrderList/OrdersSlice";

export enum DeviceState {
    Ready,
    NotReady,
    Error,
    Unknown
}

interface AkquisitionProps {
    orderId: string;
}

const useAppDispatch = () => useDispatch<AppDispatch>();
const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

const Akquisition = (props: AkquisitionProps) => {

    const dispatch = useAppDispatch();

    const { t } = useORTranslation(['Acquisition', 'common']);

    const isDeviceRunnerActive: boolean = useAppSelector((state) => selectDeviceRunnerActive(state));
    const deviceRunnerError: string = useAppSelector((state) => selectDeviceRunnerError(state));
    const layout: any= useAppSelector((state) => getLayout(state));

    useEffect(() => {
        if (!deviceRunnerError) {
            dispatch(apiSlice.util.invalidateTags([{ type: 'DeviceDefaultDeviceSet' }]));
        }
    }, [dispatch, deviceRunnerError]);


    /* const {
        data,
        isSuccess,
    }: { data: { deviceSet: deviceSet, deviceSetWebThing: deviceWebThing, deviceWebThingProps: any }, isSuccess: boolean } = useGetDeviceDefaultDeviceSet1Query('default', {
        refetchOnMountOrArgChange: true,
        skip: isDeviceRunnerActive === false || deviceRunnerError !== '',
    }); */

    const {
        data,
        isSuccess,
    }: { data: {data: { deviceSet: deviceSet, deviceSetWebThing: deviceWebThing, deviceWebThingProps: any }}[], isSuccess: boolean } = useGetDeviceSetsQuery('SET', {
        refetchOnMountOrArgChange: true,
        skip: isDeviceRunnerActive === false || deviceRunnerError !== '',
    });


    const isAkquisitionButtonActive: boolean = useAppSelector((state) => state.Acquisition.isAcquistionButtonActive) ?? false;
    const isDeviceSetOpen: boolean = useAppSelector((state) => selectDeviceSetOpen(state)) ?? false;

    const selectedCatalogueEntry: CatalogueEntry | undefined = useAppSelector((state) => getSelectedCatalogueEntry(state));
    const deviceCount: number = useAppSelector((state) => selectDeviceCount(state));
    const deviceStatusList: any = JSON.parse(useAppSelector((state) => selectDeviceStatusList(state)));
    const currentSoftwareTriggerState: string | undefined = useAppSelector((state) => selectSoftwareTriggerState(state));
    const cancelSoftwareTriggerUrl: string | undefined = useAppSelector((state) => selectCancelSoftwareTriggerUrl(state));
    const imagePathForCurrentTheme: string = useAppSelector((state) => getImagePathForCurrentTheme(state));

    const [buttonBarExpanded, setButtonBarExpanded] = useState<boolean>(false);
    const [obejctSelectionOpen, setObjetctSelectionOpen] = useState<boolean>(false);

    const handleBarClick = (evt: React.MouseEvent<HTMLDivElement>) => {
        evt.stopPropagation();
        setButtonBarExpanded(!buttonBarExpanded);
    }

    const handleobjetctSelectionOpen = (evt: React.MouseEvent<Element>) => {
        evt.stopPropagation();
        setObjetctSelectionOpen(!obejctSelectionOpen);
    }

    const onBackButtonClick = () => {
        //dispatch({ type: 'Acquisition/setAcquisitionButtonActive', payload: false });
        dispatch(setAcquisitionActive({ setAcquistionButtonActive: false, studyId: undefined, workitemId: undefined }));
        dispatch({ type: 'Acquisition/setIsAcquistionOngoing', payload: false });
    }

    const getColorCode = (state: number): number => {
        let colorCode: number = 0;
        if (deviceStatusList) {
            let isReady: boolean = true
            for (const element of deviceStatusList) {
                let code: number = 0;
                if (element?.category === "DETECTOR") {
                    code = SensorStateMap.get(element?.status_code)?.colorCode ?? 0;
                } else if (element?.category === "GENERATOR") {
                    code = GeneratorStateMap.get(element?.status_code)?.colorCode ?? 0;
                }
                if (code === 3) {
                    colorCode = 3;
                    return colorCode;
                } else if (code === 0) {
                    colorCode = 0;
                    isReady = false;
                    return colorCode;
                } else if (code === 1) {
                    isReady = false;
                }
            }
            isReady ? colorCode = 2 : colorCode = 1;
        }
        return colorCode;
    }

    const handleDeviceSetOpen = () => {
        dispatch({ type: 'Acquisition/setDeviceSetOpen', payload: true });
    };

    const handleDeviceSetClose = () => {
        dispatch({ type: 'Acquisition/setDeviceSetOpen', payload: false });
    };

    useBeforeUnload(
        React.useCallback(() => {
            if(cancelSoftwareTriggerUrl && currentSoftwareTriggerState === 'TRIGGER_REQUESTED') {
                dispatch(apiSlice.endpoints.postWebThingAction.initiate({ url: cancelSoftwareTriggerUrl, body: { cancelSoftwareTrigger: { input: {} } } }));
            }
        }, [currentSoftwareTriggerState, cancelSoftwareTriggerUrl])
    );

    return (
        <div>
            {isDeviceRunnerActive && !layout?.showProcedureSelectionPanel ?
                <>
                    <div>
                        <div className={buttonBarExpanded ? "AquisitionOverlay" : "AquisitionOverlayCollapsed"}>
                            <div className="backButton" >
                                <div className={isAkquisitionButtonActive ? "backButtonBar" : "backButtonBar_none"} onClick={() => onBackButtonClick()}>
                                    <img src={`${imagePathForCurrentTheme}Pfeil_nach_links.svg`}
                                    onError={(event: any) => {event.target.src="/images/Pfeil_nach_links.svg"; event.onerror = null}} alt="Pfeil nach links" />
                                </div>
                            </div>
                            <div className="AquisitionButton" data-error={deviceRunnerError !== '' ? deviceRunnerError : null}>
                                <XRayButton orderId={props.orderId} openDeviceSet={handleDeviceSetOpen} colorCode={isAkquisitionButtonActive ? getColorCode(0) : (deviceRunnerError === '' ? -1 : -2)} />
                            </div>

                            <div className={isAkquisitionButtonActive ? (deviceCount > 1 ? (buttonBarExpanded ? "AquisitionButtonBarDiv" : "AquisitionButtonBarDivAnimated") : "AquisitionButtonBarDiv_short") : "AquisitionButtonBarDiv_none"}
                                onClick={(evt) => handleBarClick(evt)}>
                                <div className={buttonBarExpanded ? "AquisitionButtonBarExpanded" : "AquisitionButtonBarCollapsed"}>
                                    <div className={buttonBarExpanded ? "AquisitionButtonBarInner" : "AquisitionButtonBarInner_Collapsed"}>
                                        <div className="AquisitionButtonBarInnerDiv">
                                            <div className={deviceCount > 1 ? "hexagonInset" : "hexagonInset_b"}>
                                                <div className={obejctSelectionOpen ? "hexagonInsetDivWrapper" : "hexagonInsetDivWrapper_none"}>
                                                    <ObjectSelection orderId={props.orderId}/>
                                                </div>
                                                <div className="hexagonInsetDiv">
                                                    <div className="hexagonInsetInfoButton"></div>
                                                    <DeviceSettingsButton type={DeviceType.Object} onClick={handleobjetctSelectionOpen} icon={selectedCatalogueEntry?.icon}
                                                        colorCode={0} />
                                                    <div className="hexagonInsetInfo hexagonInsetInfo_ObejctSelection">
                                                        <label className="hexagonInsetInfoObject">{selectedCatalogueEntry ? t(selectedCatalogueEntry.title) : ""}</label>
                                                    </div>
                                                </div>
                                            </div>
                                            {data?.find((deviceSet: any) =>  deviceSet?.data?.deviceSet?.config?.default === true)?.data?.deviceSet?.config?.devices?.map((device: number, i: number) => (
                                                <Device deviceId={device} colorCode={isAkquisitionButtonActive ? getColorCode(0) : (deviceRunnerError === '' ? -1 : -2)} key={i.toString()} />
                                            ))}
                                        </div>
                                    </div>
                                    <img src={`${imagePathForCurrentTheme}Pfeil_nach_links.svg`}
                                    onError={(event: any) => {event.target.src="/images/Pfeil_nach_links.svg"; event.onerror = null}}
                                    alt="Pfeil nach links" style={buttonBarExpanded ? { transform: 'rotate(0deg)' } : { transform: 'rotate(180deg)' }} />
                                </div>

                            </div>
                        </div >
                    </div >
                    <CommonDialog onClose={handleDeviceSetClose} title={t('expose')} visible={isDeviceSetOpen} skipFooter={layout?.deviceSet_panel_display_footer === false} okText="" cancelText={t('cancel', { ns: 'common' })}
                        maskClosable={false} closeDisabled={currentSoftwareTriggerState === "TRIGGER_REQUESTED" && deviceRunnerError === ''}
                        default={{ x: (window.innerWidth - 500) / 20, y: (window.innerHeight - 400) / 6, width: 500, height: layout?.deviceSet_panel_display_footer === false ? 570 : 490} } childProps={{}}>
                        <DeviceSet deviceSet={data?.find((deviceSet: any) =>  deviceSet?.data?.deviceSet?.config?.default === true)?.data?.deviceSet} deviceSetWebThing={data?.find((deviceSet: any) =>  deviceSet?.data?.deviceSet?.config?.default === true)?.data?.deviceSetWebThing}
                            colorCode={isAkquisitionButtonActive ? getColorCode(0) : (deviceRunnerError === '' ? -1 : -2)} />
                    </CommonDialog>
                    {/* <AkquisitionProgress /> */}
                </>
                : null}
                <DeviceRunnerHealth/>
        </div >
    );
}

export default Akquisition;
