import React from "react";
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux'
import './index.scss';
import App from "./App";
import store from './store'
import { apiSlice } from "./apis/apiSlice";
import { init } from "./components/Localization/ORLocalization";

// import i18n (needs to be bundled ;))
//import './i18n';

export let serviceEndpoints = {
	AUTHSERVER_URL: "https://auth.vena.dev.ork1.de",
	BACKEND_URL: "https://backend.vena.dev.ork1.de",
	ARTIFACT_STORE_URL: "https://artifact-store.vena.dev.ork1.de",
	CATALOGUE_URL: "https://catalogue.vena.dev.ork1.de/catalogue",
	RAW_PROCESSOR_URL: "https://raw-processor.vena.dev.ork1.de",
	PROCEDURES_URL: "https://10.0.3.137:8080",
	MQTT_HOST: "10.0.82.122",
	MQTT_PORT: "15675",
	MQTT_PORT_TLS: "15676"
}

export interface ConfigSourceData {
	name: string,
	profiles: string[],
	label: string,
	version: string,
	state: string,
	propertySources: PropertySource[]
}

export interface PropertySource {
	name: string,
	source: Record<string, string>
}

fetch('/ServiceEndpoints.json')
	.then(response => response.text())
	.then(textString => {

		const data = JSON.parse(textString);
		serviceEndpoints = { ...data };

	}).catch(function (e) {
		console.log("error: service endpoints not found");

	}).then(async function () {
		console.log("init");
		console.log(JSON.stringify(serviceEndpoints));

		const globalConfig = await store.dispatch(apiSlice.endpoints.config.initiate({ file: 'GlobalConfig.json' })).unwrap();
		console.log(JSON.stringify(globalConfig, null, 2));

		let configServerDefaultConfig: ConfigSourceData | undefined
		let themeFromDefault: string | undefined
		let configServerProfileConfig: ConfigSourceData | undefined
		try {
			// only try to fetch config from config server, if url is configured
			if (globalConfig?.configServerURL) {
				// get default config from config server to determine configured variant
				configServerDefaultConfig = await store.dispatch(apiSlice.endpoints.configServer.initiate({ baseURL: globalConfig?.configServerURL, service: 'or-frontend-service' })).unwrap();
				themeFromDefault = getConfigForKey(configServerDefaultConfig, "global.theme")
				// get config variant
				configServerProfileConfig = await store.dispatch(apiSlice.endpoints.configServer.initiate({ baseURL: globalConfig?.configServerURL, service: 'or-frontend-service', profile: themeFromDefault ?? "default"})).unwrap();
				console.log(JSON.stringify(configServerProfileConfig, null, 2));
			}
		} catch (error) {
			console.error("Couldn't fetch config from server: ", globalConfig?.configServerURL)
		}

		// use separate scope to avoid potential variable name clashes
		{
			const imagePolarity = getConfigForKey(configServerProfileConfig, "global.imagePolarity") || globalConfig?.imagePolarity
			store.dispatch({ type: "MainOrderListColumns/setImagePolarity", payload: globalConfig?.imagePolarity });

			const versionString = getConfigForKey(configServerProfileConfig, "global.versionString") || globalConfig?.versionString
			store.dispatch({ type: "MainOrderListColumns/setVersionString", payload: versionString });

			const theme = getConfigForKey(configServerProfileConfig, "global.theme") || globalConfig?.theme
			store.dispatch({ type: "MainOrderListColumns/setTheme", payload: theme });

			const showThemeSelection = (getConfigForKey(configServerProfileConfig, "global.showThemeSelection") || String(globalConfig?.showThemeSelection)) === "true"
			store.dispatch({ type: "MainOrderListColumns/setShowThemeSelection", payload: showThemeSelection });

			const Device_Runner_URL = getConfigForKey(configServerProfileConfig, "global.Device_Runner_URL") || globalConfig?.Device_Runner_URL
			store.dispatch({ type: "Acquisition/setDeviceRunnerPath", payload: Device_Runner_URL });

			const drawHandlesOnHover = getConfigForKey(configServerProfileConfig, "global.drawHandlesOnHover") || globalConfig?.drawHandlesOnHover
			store.dispatch({ type: "ImageDisplay/setDrawHandlesOnHover", payload: drawHandlesOnHover ?? false });

			const pixelReplication = getConfigForKey(configServerProfileConfig, "global.pixelReplication") || globalConfig?.pixelReplication
			store.dispatch({ type: "ImageDisplay/setPixelReplication", payload: pixelReplication ?? false });

			const demoMode = (getConfigForKey(configServerProfileConfig, "global.demoMode") || String(globalConfig?.demoMode)) === "true"
			store.dispatch({ type: "ImageDisplay/setDemoMode", payload: demoMode ?? false });

			const maxLineWidth = getConfigForKey(configServerProfileConfig, "global.maxLineWidth") || globalConfig?.maxLineWidth
			store.dispatch({ type: "ImageDisplay/setMaxLineWidth", payload: maxLineWidth });

			const procedureSettingVariantList = getConfigForKey(configServerProfileConfig, "global.procedureSettingVariantList") || globalConfig?.procedureSettingVariantList
			store.dispatch({ type: "Procedure/setSelectedProcedureSettingVariantArray", payload: procedureSettingVariantList });

			const xrayHelperVariantList = getConfigForKey(configServerProfileConfig, "global.xrayHelperVariantList") || globalConfig?.xrayHelperVariantList
			store.dispatch({ type: "Procedure/setSelectedXRayHelperVariantArray", payload: xrayHelperVariantList });

			const favoriteTag = getConfigForKey(configServerProfileConfig, "global.favoriteTag") || globalConfig?.favoriteTag
			store.dispatch({ type: "Procedure/setFavouriteTag", payload: favoriteTag });

			const intlConfigOptions = getConfigForKey(configServerProfileConfig, "global.intlConfigOptions") || globalConfig?.intlConfigOptions
			store.dispatch({ type: "ImageDisplay/setIntlConfigOptions", payload: intlConfigOptions });

			const dateFormat = getConfigForKey(configServerProfileConfig, "global.dateFormat") || globalConfig?.dateFormat
			store.dispatch({ type: "ImageDisplay/setDateFormat", payload: dateFormat });

			const timeFormat = getConfigForKey(configServerProfileConfig, "global.timeFormat") || globalConfig?.timeFormat
			store.dispatch({ type: "ImageDisplay/setTimeFormat", payload: timeFormat });

			if (globalConfig?.Device_Runner_URL) {
				store.dispatch({ type: "Acquisition/setDeviceRunnerActive", payload: true });
			} else {
				store.dispatch({ type: "Acquisition/setDeviceRunnerActive", payload: false });
			}
		}

		const state = store.getState();

		const elem = document.getElementById('MainHtml');
		const themeName = getConfigForKey(configServerProfileConfig, "global.theme") || globalConfig?.theme
		if (elem) {
			elem.classList.add(themeName);
		}
		const elemTitle = document.getElementById('mainTitle');
		if (elemTitle) {
			elemTitle.innerHTML = themeName;
		}

		init(state);
		store.dispatch(apiSlice.endpoints.getCatalogue.initiate());
		//store.dispatch(apiSlice.endpoints.config.initiate({file: 'ToolsConfig.json'}));
		store.dispatch(apiSlice.endpoints.config.initiate({ file: 'Themes.json' }));
		const container = document.getElementById("root");
		const root = ReactDOM.createRoot(container!);

		//console.log(`${process.env.REACT_APP_NAME} ${process.env.REACT_APP_VERSION}`)

		root.render(
			<Provider store={store}>
				<App />
			</Provider>
		);
	});

function getConfigForKey(config: ConfigSourceData | undefined, configKey: string) {
	return config?.propertySources.find(source => configKey in source.source)?.source[configKey]
}
