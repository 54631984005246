/* eslint no-alert: 0 */
import csTools from 'cornerstone-tools';

const Cursors = csTools.importInternal('tools/cursors');
const cursor = Cursors.angleCursor;
const getPixelSpacing = csTools.importInternal('util/getPixelSpacing');

/**
 * @public
 * @class RestorableAngleTool
 * @memberof Tools.Annotation
 * @classdesc Create and position an arrow and label
 * @extends Tools.Annotation.AngleTool
 */
export default class RestorableAngleTool extends csTools.AngleTool {
	constructor(props = {}) {
		const defaultProps = {
			name: 'RestorableAngle',
			supportedInteractionTypes: ['Mouse', 'Touch'],
			configuration: {
				drawHandles: true,
				drawHandlesOnHover: props.configuration.drawHandlesOnHover ?? false,
				hideHandlesIfMoving: false,
				renderDashed: false,
				shadow: true,
			},
			svgCursor: cursor,
		};

		super(defaultProps);
	}


	updateCachedStats(image, element, data) {
		if(data?.toolName !== this.name) return;
		const sideA = getSide(image, data.handles.middle, data.handles.start);
		const sideB = getSide(image, data.handles.end, data.handles.middle);
		const sideC = getSide(image, data.handles.end, data.handles.start);

		const sideALength = length(sideA);
		const sideBLength = length(sideB);
		const sideCLength = length(sideC);

		// Cosine law
		let angle = Math.acos(
			(Math.pow(sideALength, 2) +
				Math.pow(sideBLength, 2) -
				Math.pow(sideCLength, 2)) /
			(2 * sideALength * sideBLength)
		);

		angle *= 180 / Math.PI;

		data.rAngle = roundToDecimal(angle, 2);
		data.invalidated = false;
	}

	restore(element, data) {
		var xs = data.handles.start.x;
		var ys = data.handles.start.y;

		let measurement = super.createNewMeasurement(
			{
				currentPoints: { image: { x: xs, y: ys } },
				viewport: { rotation: undefined },
				element: element
			});

		measurement.handles.middle.x = data.handles.middle.x;
		measurement.handles.middle.y = data.handles.middle.y;

		measurement.handles.end.x = data.handles.end.x;
		measurement.handles.end.y = data.handles.end.y;
		measurement.handles.textBox = JSON.parse(JSON.stringify(data.handles.textBox));

		return measurement;
	}

	store(toolData) {
		toolData.storeToolData = {
			"handles": toolData.handles,
		}
	}

} // RestorableAngleTool

function length(vector) {
	return Math.sqrt(Math.pow(vector.x, 2) + Math.pow(vector.y, 2));
}

function getSide(image, handleEnd, handleStart) {
	const { rowPixelSpacing, colPixelSpacing } = getPixelSpacing(image);

	return {
		x: (handleEnd.x - handleStart.x) * (colPixelSpacing || 1),
		y: (handleEnd.y - handleStart.y) * (rowPixelSpacing || 1),
	};
}

function roundToDecimal(value, precision) {
	const multiplier = Math.pow(10, precision);

	return Math.round(value * multiplier) / multiplier;
}
