import React, { useEffect, useRef } from "react";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { Layout, Model, TabNode, IJsonModel, Actions, DockLocation, ITabRenderValues, Action, TabSetNode, RowNode } from "flexlayout-react";

import { AppDispatch, RootState } from "../../store";

import cornerstone, { EnabledElement } from "cornerstone-core";
import { Constants } from "../../Constants";
import { getFalseColorsActive, getGrayscaleLineProfileChartActive, getBlackMaskActive, getLengthCalibrationChartActive, getLineProfileChartActive, getPipeWallThicknessChartActive, getResolutionMeasurementChartActive, getSelectedToolTab, getToolTabsModel, getWindowLevelHistActive } from "./ImageDisplaySlice";
import WindowLevelHist from "./WindowLevelHist";
import FalseColor from "./Tools/FalseColor";
import PipeWallThicknessHist from "./PipeWallThicknessHist";
import GrayscaleLineProfile from './GrayscaleLineProfile';
import BlackMask from './BlackMask';
import ResolutionMeasurement from "./ResolutionMeasurement";
import LineProfileHist from "./LineProfileHist";
import LengthCalibrationHist from "./LengthCalibrationHist";
import { ToolsListEntry } from "../../App";
import { getToolList } from "../OrderList/OrdersSlice";

export const toolTabsJson: IJsonModel = {
    global: {
        tabEnableClose: false, tabEnableDrag: true, tabSetEnableDrag: true,
        splitterSize: 3, tabEnableFloat: true, tabSetEnableMaximize: false, tabSetHeaderHeight: 22, tabSetTabStripHeight: 22
    },
    borders: [
        {
            "type": "border",
            "location": "left",
            "enableAutoHide": true,
            "size": 100,
            "children": []
        }
    ],
    layout: {
        "type": "row",
        "weight": 100,
        "id": "ToolsTabsetRow",
        "children": [
        ]
    }
};

type TooltabsProps = {
    orderId: string;
    matrixIndex: number;
};

const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
const useAppDispatch = () => useDispatch<AppDispatch>();

const Tooltabs = (props: TooltabsProps) => {
    const dispatch = useAppDispatch();



    const LayoutRef = useRef<Layout>(null);

    const wrapperDivElement = useRef<HTMLDivElement>(null);

    const isWindowLevelHistActive: boolean | undefined = useAppSelector((state) => getWindowLevelHistActive(state, props.matrixIndex ?? 0));
    const isFalseColorsActive: boolean | undefined = useAppSelector((state) => getFalseColorsActive(state, props.matrixIndex ?? 0));

    const selectedToolTab: string = useAppSelector((state) => getSelectedToolTab(state, props.matrixIndex ?? 0));

    const isPipeWallThicknessChartActive: boolean | undefined = useAppSelector((state) => getPipeWallThicknessChartActive(state, props.matrixIndex ?? 0));
    const isLineProfileChartActive: boolean | undefined = useAppSelector((state) => getLineProfileChartActive(state, props.matrixIndex ?? 0));
    const isGrayscaleLineProfileChartActive: boolean | undefined = useAppSelector((state) => getGrayscaleLineProfileChartActive(state, props.matrixIndex ?? 0));
    const isBlackMaskActive: boolean | undefined = useAppSelector((state) => getBlackMaskActive(state, props.matrixIndex ?? 0));
    const isResolutionMeasurementChartActive: boolean | undefined = useAppSelector((state) => getResolutionMeasurementChartActive(state, props.matrixIndex ?? 0));
    const isLengthCalibrationChartActive: boolean | undefined = useAppSelector((state) => getLengthCalibrationChartActive(state, props.matrixIndex ?? 0));
    const tools: ToolsListEntry[] = useAppSelector((state) => getToolList(state));

    const toolsRef = useRef<ToolsListEntry[] | undefined>();
    toolsRef.current = tools;

    const jsonModel: IJsonModel = useAppSelector((state) => getToolTabsModel(state, props.matrixIndex ?? 0)) ?? toolTabsJson;
    const model = Model?.fromJson(jsonModel);

    const factory = (node: TabNode) => {
        var component = node.getComponent();
        if (component === "text") {
            return (<div className="panel">Panel {node.getName()}</div>);
        }
        if (component === "WindowLevelHist") {
            return (<WindowLevelHist orderId={props.orderId} matrixIndex={props.matrixIndex}></WindowLevelHist>);
        }
        if (component === "FalseColor") {
            return (<FalseColor orderId={props.orderId} matrixIndex={props.matrixIndex} />);
        }
        if (component === "BlackMask") {
            return (<BlackMask orderId={props.orderId} matrixIndex={props.matrixIndex} />);
        }
        if (component === "PipeWallThickness") {
            return (<PipeWallThicknessHist orderId={props.orderId} matrixIndex={props.matrixIndex} />);
        }
        if (component === "GrayscaleProfile") {
            return (<LineProfileHist orderId={props.orderId} matrixIndex={props.matrixIndex} />);
        }
        if (component === "GrayscaleProfile2") {
            return (<GrayscaleLineProfile orderId={props.orderId} matrixIndex={props.matrixIndex} />);
        }
        if (component === "ResolutionMeasurement") {
            return (<ResolutionMeasurement orderId={props.orderId} matrixIndex={props.matrixIndex} />);
        }
        if (component === "LengthCalibration") {
            return (<LengthCalibrationHist orderId={props.orderId} matrixIndex={props.matrixIndex} />);
        }
    }

    const onRenderTab = (node: TabNode, renderValues: ITabRenderValues) => {
        if (toolsRef.current) {
            if (node.getComponent() === "WindowLevelHist") {
                renderValues.buttons.push(<img className='toolIcon' src='/images/Tools/windowlevel.svg' alt='/images/windowlevel.svg' key="WindowLevelHist" />);
            }
            if (node.getComponent() === "FalseColor") {
                renderValues.buttons.push(<img className='toolIcon' src={toolsRef.current?.flatMap(x => x?.subMenu.length > 0 ? x?.subMenu : x)?.find((tool: ToolsListEntry) => tool.name === 'FalseColor')?.icon} alt='color-svgrepo-com.svg' key="FalseColor" />);
            }
            if (node.getComponent() === "GrayscaleProfile") {
                renderValues.buttons.push(<img className='toolIcon' src={toolsRef.current?.flatMap(x => x?.subMenu.length > 0 ? x?.subMenu : x)?.find((tool: ToolsListEntry) => tool.name === 'LineProfile')?.icon} alt='/images/chart-median-svgrepo-com.svg' key="PipeWallThickness1" />);
            }
            if (node.getComponent() === "PipeWallThickness") {
                renderValues.buttons.push(<img className='toolIcon' src={toolsRef.current?.flatMap(x => x?.subMenu.length > 0 ? x?.subMenu : x)?.find((tool: ToolsListEntry) => tool.name === 'PipeWallThickness')?.icon} alt='/images/pipes-pipe-svgrepo-com.svg' key="PipeWallThickness2" />);
            }

            if (node.getComponent() === "ResolutionMeasurement") {
                renderValues.buttons.push(<img className='toolIcon' src={toolsRef.current?.flatMap(x => x?.subMenu.length > 0 ? x?.subMenu : x)?.find((tool: ToolsListEntry) => tool.name === 'ResolutionMeasurement')?.icon} alt='/images/chart-median-svgrepo-com.svg' key="PipeWallThickness1" />);
            }
            if (node.getComponent() === "LengthCalibration") {
                renderValues.buttons.push(<img className='toolIcon' src={toolsRef.current?.flatMap(x => x?.subMenu.length > 0 ? x?.subMenu : x)?.find((tool: ToolsListEntry) => tool.name === 'LengthCalibration')?.icon} alt='/images/caliper-svgrepo-com.svg' key="LengthCalibration" />);
            }
        }
    }

    const onModelChange = () => {
        dispatch({ type: "ImageDisplay/setToolTabsModel", payload: { matrixIndex: props.matrixIndex, model: model.toJson() } });
    }


    const addTab = (id: string, dockLocation: DockLocation = DockLocation.CENTER) => {
        if (!model.getNodeById(id)) {
            let activeTabsetId = model.getActiveTabset()?.getId();

            if (!activeTabsetId) {
                const row: RowNode = model.getNodeById("ToolsTabsetRow") as RowNode;
                if (row && row.getChildren()?.length > 0) {
                    activeTabsetId = (row.getChildren()?.[0] as TabSetNode)?.getId();
                }
            }

            if (activeTabsetId) {
                model.doAction(Actions.addNode(
                    { type: "tab", component: id, name: "", id: id, enableClose: true },
                    activeTabsetId, DockLocation.CENTER, -1, true));
            }
            dispatch({ type: "ImageDisplay/setSelectedToolTab", payload: { matrixIndex: props.matrixIndex, selectedTooltab: id } });
        }
    }

    useEffect(() => {
        if (model.getNodeById(selectedToolTab)) {
            model.doAction(Actions.selectTab(selectedToolTab));
        }

    }, [selectedToolTab]);


    useEffect(() => {
        if (isWindowLevelHistActive) {
            if (!model.getNodeById("WindowLevelHist")) {
                addTab("WindowLevelHist");
            }
        }
    }, [isWindowLevelHistActive]);

    useEffect(() => {
        if (isFalseColorsActive) {
            //console.log(JSONPath({ path: "$..id", json: model.toJson() }).toString() + " " + props.matrixIndex);
            if (!model.getNodeById("FalseColor")) {
                addTab("FalseColor", DockLocation.LEFT);
            }
        } else {
            model.doAction(Actions.deleteTab("FalseColor"));
            const cornerstoneElements: EnabledElement[] = cornerstone.getEnabledElements();
            const displayElement: EnabledElement | undefined = cornerstoneElements.find(
                (element: EnabledElement) => element.element.id === `${Constants.IMAGE_DISPLAY_GENERIC_ELEMENT_NAME}_${props.matrixIndex}`
            );

            if (displayElement && displayElement.image) {
                displayElement.needsRedraw = true;
                displayElement.invalid = true;
            }
        }
    }, [isFalseColorsActive]);

    useEffect(() => {
        if (isPipeWallThicknessChartActive) {
            if (!model.getNodeById("PipeWallThickness")) {
                addTab("PipeWallThickness");
            }

        }
    }, [isPipeWallThicknessChartActive]);

    useEffect(() => {
        if (isLineProfileChartActive) {
            if (!model.getNodeById("GrayscaleProfile")) {
                addTab("GrayscaleProfile");
            }
        }
    }, [isLineProfileChartActive]);


    useEffect(() => {
        if (isGrayscaleLineProfileChartActive) {
            if (!model.getNodeById("GrayscaleProfile2")) {
                addTab("GrayscaleProfile2");
            }
        }
    }, [isGrayscaleLineProfileChartActive]);

    useEffect(() => {
        if (isBlackMaskActive) {
            if (!model.getNodeById("BlackMask")) {
                addTab("BlackMask");
            }
        }
    }, [isBlackMaskActive]);

    useEffect(() => {
        if (isResolutionMeasurementChartActive) {
            if (!model.getNodeById("ResolutionMeasurement")) {
                addTab("ResolutionMeasurement");
            }
        }
    }, [isResolutionMeasurementChartActive]);

    useEffect(() => {
        if (isLengthCalibrationChartActive) {
            if (!model.getNodeById("LengthCalibration")) {
                addTab("LengthCalibration");
            }
        }
    }, [isLengthCalibrationChartActive]);


    const onAction = (action: Action) => {
        if (action.type === 'FlexLayout_DeleteTab') {
            switch (action?.data?.node) {
                case 'WindowLevelHist':
                    dispatch({ type: "ImageDisplay/setWindowLevelHistActive", payload: { matrixIndex: props.matrixIndex, isActive: false } });
                    break;
                case 'FalseColor':
                    if (isFalseColorsActive) {
                        const cornerstoneElements: EnabledElement[] = cornerstone.getEnabledElements();
                        const displayElement: EnabledElement | undefined = cornerstoneElements.find(
                            (element: EnabledElement) => element.element.id === `${Constants.IMAGE_DISPLAY_GENERIC_ELEMENT_NAME}_${props.matrixIndex}`
                        );

                        if (displayElement && displayElement.image) {
                            displayElement.needsRedraw = true;
                            displayElement.invalid = true;
                        }
                        dispatch({ type: "ImageDisplay/setFalseColorsActive", payload: { matrixIndex: props.matrixIndex, isActive: false } });
                    }
                    break;
                case 'PipeWallThickness':
                    dispatch({ type: "ImageDisplay/setPipeWallThicknessChartActive", payload: { matrixIndex: props.matrixIndex, isActive: false } });
                    break;
                case 'GrayscaleProfile':
                    dispatch({ type: "ImageDisplay/setLineProfileChartActive", payload: { matrixIndex: props.matrixIndex, isActive: false } });
                    break;
                case 'GrayscaleProfile2':
                    dispatch({ type: "ImageDisplay/setGrayscaleLineProfileChartActive", payload: { matrixIndex: props.matrixIndex, isActive: false } });
                    break;
                case 'BlackMask':
                    dispatch({ type: "ImageDisplay/setBlackMaskActive", payload: { matrixIndex: props.matrixIndex, isActive: false } });
                    break;
                case 'ResolutionMeasurement':
                    dispatch({ type: "ImageDisplay/setResolutionMeasurementChartActive", payload: { matrixIndex: props.matrixIndex, isActive: false } });
                    break;
                case 'LengthCalibration':
                    dispatch({ type: "ImageDisplay/setLengthCalibrationChartActive", payload: { matrixIndex: props.matrixIndex, isActive: false } });
                    break;
                default:
                    console.log("id " + action?.data?.node + " not found");
            }
        }
        return action;
    }

    return (
        <div className="toolsInsetWrapper" ref={wrapperDivElement} onContextMenu={(e) => e.preventDefault()}>
            <Layout
                model={model}
                ref={LayoutRef}
                onRenderTab={onRenderTab}
                onModelChange={onModelChange}
                onAction={onAction}
                realtimeResize={true}
                factory={factory.bind(this)} />
        </div>
    );
}

export default Tooltabs;
