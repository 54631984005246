import {createSlice} from '@reduxjs/toolkit'

export interface IobejectSelection { 
    selectedObjectIndex?: number;   
}

const initialState: IobejectSelection = {
    selectedObjectIndex: 0,   
}

const ObjectSelectionSlice = createSlice({
    name: 'ObjectSelection',
    initialState,
    reducers: {
        selectedObjectIndexChanged(state, action) {
            const index: number = action.payload;
            state.selectedObjectIndex = index;
        },       
    }
});

export const {selectedObjectIndexChanged} = ObjectSelectionSlice.actions
export default ObjectSelectionSlice.reducer;
