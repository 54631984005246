import { createSlice } from "@reduxjs/toolkit";
import cornerstone, { EnabledElement, LUT, vec2, VOI } from "cornerstone-core";
import { IJsonModel } from "flexlayout-react";
import { Constants } from "../../Constants";
import { RootState } from "../../store";
import { toolTabsJson } from "./Tooltabs";
import { ToolsListEntry } from "../../App";
import { getWorkitemFromStudyWithWorkitems } from "../OrderList/OrdersSlice";
import { apiSlice, workitem } from "../../apis/apiSlice";

export type Viewport = {
	scale?: number;
	translation?: vec2;
	voi?: { voi: VOI | undefined, voi_raw: VOI | undefined };
	invert?: boolean;
	hflip?: boolean;
	vflip?: boolean;
	rotation?: number;
	modalityLUT?: LUT;
	voiLUT?: LUT;
	colormap?: unknown;
	labelmap?: boolean;
}

export enum DisplayItemType {
	image,
	pdf
}

export enum ViewportStorageType {
	none,
	session,
	local,
	annotation
}

export function cornerstoneViewportToViewport(viewport: cornerstone.Viewport, isRaw: boolean): Viewport {
	return {
		scale: viewport.scale, translation: viewport.translation, voi: isRaw ? { voi: undefined, voi_raw: viewport.voi } : { voi: viewport.voi, voi_raw: undefined },
		invert: viewport.invert, hflip: viewport.hflip, vflip: viewport.vflip, rotation: viewport.rotation, modalityLUT: viewport.modalityLUT,
		voiLUT: viewport.voiLUT, colormap: viewport.colormap, labelmap: viewport.labelmap
	}
};

export function viewportToCornerstoneViewport(viewport: Viewport, isRaw: boolean): cornerstone.Viewport {
	return {
		scale: viewport.scale, translation: viewport.translation, voi: isRaw ? viewport.voi?.voi_raw : viewport.voi?.voi,
		invert: viewport.invert, hflip: viewport.hflip, vflip: viewport.vflip, rotation: viewport.rotation, modalityLUT: viewport.modalityLUT,
		voiLUT: viewport.voiLUT, colormap: viewport.colormap, labelmap: viewport.labelmap
	}
};

export type matrixImagesItem = {
	matrixIndex: number; imageId: string, selectedTool: ToolsListEntry, windowLevelHistActive: boolean, blackMaskActive: boolean,
	pipeWallThicknessHistActive: boolean, lineProfileActive: boolean, lengthCalibrationActive: boolean,
	falseColorsActive: boolean, colorFrac: number[], grayscaleLineProfileActive: boolean, resolutionMeasurementActive: boolean,
	fftFilterActive: boolean, originalImage: string,
	hasRawImage: boolean, rawImageActive: boolean, toolTabsModel: IJsonModel,
	grayscaleLineProfileChartActive: boolean, resolutionMeasurementChartActive: boolean,
	pipeWallThicknessChartActive: boolean, lineProfileChartActive: boolean, lengthCalibrationChartActive: boolean, selectedToolTab: string,
	isPWTContextViewExtended: boolean, PWT_geometry_visible: boolean, displayItemType: DisplayItemType,
	viewports: { id: string, viewport: Viewport }[],
	protocolCorrelationId: string
};

export type protocolStepSelectorPropsItem = {
	workitemId: string;
	stepType: string,
	requiredAttribute: string,
	excludedAttribute: string,
	index: number,
	seriesIndex: number,
	correlationId: string,
}

export interface ImageDisplay {
	isCornerstoneInitialized: boolean;
	isViewportSynchronization: boolean;
	isImageProcessing: boolean;
	finalizeCounter: number;
	isToolsPanel: boolean;
	IsOverviewAndAnnotationsVisible: boolean;
	imageDescVisible: boolean;
	orderInfoVisible: boolean;
	isToolInsetVisible: boolean;
	exportDialogVisible: number;
	procedureEditDialogVisible: number,
	finalizeStudyDialogVisible: number;
	subjectDialogVisible: number;
	completeIncompleteStudyVisible: boolean;
	reloadApplicationDialogVisible: boolean;
	tooltabsPosition: string;
	matrixColumns: number;
	matrixRows: number;
	currentIndex: number;
	selectedIndex: number;
	rowsPerPage: number;
	selectedPage: number;
	totalPages: number;
	totalElements: number;
	studiesSortString: string;
	selectedWorkitem: string;
	activeToolIcon: string,
	drawHandlesOnHover: boolean;
	pixelReplication: boolean,
	demoMode: boolean,
	maxLineWidth: number,
	isExportActive: boolean,
	matrixImages: matrixImagesItem[],
	protocolStepSelectorProps: protocolStepSelectorPropsItem[],
	viewportStorageType: ViewportStorageType;
	deviationIndexThresholds: [number, number];
	showDeviationIndexWidget: boolean;
	showDeviationIndexValue: boolean;
	showExposureIndexValue: boolean;
	hideActionButtonCloseWorkitem: boolean;
	showSValue: boolean;
	intlConfigOptions: object;
	dataFormat: string;
	timeFormat: string;
	pregnancyAgeLimits: number[];
	cutToBMOnFinalize: boolean;
	supressWWWCHistonRightMouse: boolean;
	isImageMaximized: boolean;
	isImageLoadRequired: boolean;
	activatePlanningMode: boolean;
	reloadApplicationTimeoutId: number | undefined;
	reloadApplicationTimeout: number;
}

const initialState: ImageDisplay = {
	isCornerstoneInitialized: false,
	isViewportSynchronization: false,
	isImageProcessing: false,
	finalizeCounter: 0,
	isToolsPanel: false,
	IsOverviewAndAnnotationsVisible: true,
	imageDescVisible: false,
	orderInfoVisible: false,
	isToolInsetVisible: false,
	exportDialogVisible: 0,
	procedureEditDialogVisible: -1,
	finalizeStudyDialogVisible: 0,
	subjectDialogVisible: 0,
	completeIncompleteStudyVisible: false,
	reloadApplicationDialogVisible: false,
	tooltabsPosition: 'right',
	matrixColumns: 1,
	matrixRows: 1,
	currentIndex: -1,
	selectedIndex: -1,
	rowsPerPage: 10,
	selectedPage: 0,
	totalPages: 1,
	studiesSortString: '&sort=details.modified,desc',
	totalElements: 0,
	selectedWorkitem: '',
	activeToolIcon: '',
	drawHandlesOnHover: false,
	pixelReplication: false,
	demoMode: false,
	maxLineWidth: 200,
	isExportActive: false,
	matrixImages: [],
	protocolStepSelectorProps: [],
	viewportStorageType: ViewportStorageType.none,
	deviationIndexThresholds: [2, 5],
	showDeviationIndexWidget: false,
	showDeviationIndexValue: false,
	showExposureIndexValue: false,
	hideActionButtonCloseWorkitem: false,
	showSValue: false,
	intlConfigOptions: {},
	dataFormat: "yyyyMMddHHmmss",
	timeFormat: "yyyyMMddHHmmss",
	pregnancyAgeLimits: [12, 70],
	cutToBMOnFinalize: true,
	supressWWWCHistonRightMouse: false,
	isImageMaximized: false,
	isImageLoadRequired: false,
	activatePlanningMode: false,
	reloadApplicationTimeoutId: undefined,
	reloadApplicationTimeout: 0
};

function replacer(key: string, value: any) {
	if (value instanceof Map) {
		return {
			dataType: 'Map',
			value: Array.from(value.entries()), // or with spread: value: [...value]
		};
	} else {
		return value;
	}
}

const ImageDisplaySlice = createSlice({
	name: "ImageDisplay",
	initialState,
	reducers: {
		setMatrix(state, action) {
			const columns: number = action.payload.columns;
			const rows: number = action.payload.rows;
			state.matrixColumns = columns;
			state.matrixRows = rows;
		},
		setCurrentIndex(state, action) {
			const index: number = action.payload;
			state.currentIndex = index;
		},
		setSelectedIndex(state, action) {
			const index: number = action.payload;
			state.selectedIndex = index;
		},
		setRowsPerPage(state, action) {
			const rowsPerPage: number = action.payload;
			state.rowsPerPage = rowsPerPage;
		},
		setSelectedPage(state, action) {
			const selectedPage: number = action.payload;
			state.selectedPage = selectedPage;
		},
		setTotalPages(state, action) {
			const totalPages: number = action.payload;
			state.totalPages = totalPages;
		},
		setTotalElements(state, action) {
			const totalElements: number = action.payload;
			state.totalElements = totalElements;
		},
		setStudiesSortString(state, action) {
			const studiesSortString: string = action.payload;
			state.studiesSortString = studiesSortString;
		},
		setSelectedWorkitem(state, action) {
			const workitem: string = action.payload;
			state.selectedWorkitem = workitem;
		},

		setCornerstoneInitialized(state, action) {
			const isCornerstoneInitialized: boolean = action.payload;
			state.isCornerstoneInitialized = isCornerstoneInitialized;
		},
		setViewportSynchronization(state, action) {
			const isViewportSynchronization: boolean = action.payload;
			state.isViewportSynchronization = isViewportSynchronization;
		},
		setImageProcessing(state, action) {
			const isImageProcessing: boolean = action.payload;
			state.isImageProcessing = isImageProcessing;
		},
		incrFinalizeCounter(state, action) {
			state.finalizeCounter = state.finalizeCounter + 1;
		},
		decrFinalizeCounter(state, action) {
			if (state.finalizeCounter === 1) {
				//console.log("setImageProcessing false");
				state.isImageProcessing = false;
			}
			state.finalizeCounter = state.finalizeCounter - 1;
		},
		setToolsPanel(state, action) {
			const isToolsPanel: boolean = action.payload;
			state.isToolsPanel = isToolsPanel;
		},
		setIsOverviewAndAnnotationsVisible(state, action) {
			const IsOverviewAndAnnotationsVisible: boolean = action.payload;
			state.IsOverviewAndAnnotationsVisible = IsOverviewAndAnnotationsVisible;
		},
		setImageDescVisible(state, action) {
			const imageDescVisible: boolean = action.payload;
			state.imageDescVisible = imageDescVisible;
		},
		setOrderInfoVisible(state, action) {
			const orderInfoVisible: boolean = action.payload;
			state.orderInfoVisible = orderInfoVisible;
		},
		setToolInsetVisible(state, action) {
			const isToolInsetVisible: boolean = action.payload;
			state.isToolInsetVisible = isToolInsetVisible;
		},
		setExportDialogVisible(state, action) {
			const exportDialogVisible: number = action.payload;
			state.exportDialogVisible = exportDialogVisible;
		},

		setProcedureEditVisible(state, action) {
			const procedureEditDialogVisible: number = action.payload;
			state.procedureEditDialogVisible = procedureEditDialogVisible;
		},

		setFinalizeStudyDialogVisible(state, action) {
			const finalizeStudyDialogVisible: number = action.payload;
			state.finalizeStudyDialogVisible = finalizeStudyDialogVisible;
		},
		setSubjectDialogVisible(state, action) {
			const subjectDialogVisible: number = action.payload;
			state.subjectDialogVisible = subjectDialogVisible;
		},
		setCompleteIncompleteStudyVisible(state, action) {
			const visible: boolean = action.payload;
			state.completeIncompleteStudyVisible = visible;
		},		
		setReloadApplicationDialogVisible(state, action) {
			const visible: boolean = action.payload;
			state.reloadApplicationDialogVisible = visible;
		},
		
		setReloadApplicationTimeoutId(state, action){
			const id : number|undefined = action.payload;
			state.reloadApplicationTimeoutId = id;
		},

		setReloadApplicationTimeout(state, action) {
			if (action.payload) {
				if ((typeof action.payload) === "number") {
					const to: number = action.payload;
					state.reloadApplicationTimeout = to;
				}
			}
		},
		setTooltabsPosition(state, action) {
			const tooltabsPosition: string = action.payload;
			state.tooltabsPosition = tooltabsPosition;
		},
		setMatrixImages(state, action) {
			const matrixImage: matrixImagesItem = action.payload;
			const index: matrixImagesItem | undefined = state.matrixImages.find((mi) => mi.matrixIndex === matrixImage.matrixIndex);
			if (index) {
				const arrayIndex: number = state.matrixImages.indexOf(index);
				if (arrayIndex >= 0) {
					state.matrixImages[arrayIndex].imageId = matrixImage.imageId;
					state.matrixImages[arrayIndex].displayItemType = matrixImage.displayItemType;
				}
			} else {
				state.matrixImages.push({ ...matrixImage, viewports: [] });
			}
		},

		setMatrixSelectedTool(state, action) {
			const matrixIndex: number = action.payload.matrixIndex;
			const matrixSelectedTool: ToolsListEntry = action.payload.matrixSelectedTool;
			const index: matrixImagesItem | undefined = state.matrixImages.find((mi) => mi.matrixIndex === matrixIndex);
			if (index) {
				const arrayIndex: number = state.matrixImages.indexOf(index);
				if (arrayIndex >= 0) state.matrixImages[arrayIndex].selectedTool = matrixSelectedTool;
			}
		},

		setDisplayItemType(state, action) {
			const matrixIndex: number = action.payload.matrixIndex;
			const displayItemType: DisplayItemType = action.payload.displayItemType;
			const index: matrixImagesItem | undefined = state.matrixImages.find((mi) => mi.matrixIndex === matrixIndex);
			if (index) {
				const arrayIndex: number = state.matrixImages.indexOf(index);
				if (arrayIndex >= 0) state.matrixImages[arrayIndex].displayItemType = displayItemType;
			}
		},

		addMatrixSelectedTool(state, action) {
			const matrixIndex: number = action.payload.matrixIndex;
			const matrixSelectedTool: ToolsListEntry = action.payload.matrixSelectedTool;
			const index: matrixImagesItem | undefined = state.matrixImages.find((mi) => mi.matrixIndex === matrixIndex);
			if (!index) {
				state.matrixImages.push({
					matrixIndex: matrixIndex, imageId: '', selectedTool: matrixSelectedTool, windowLevelHistActive: false, pipeWallThicknessHistActive: false,
					lineProfileActive: false, blackMaskActive: false,
					falseColorsActive: false, colorFrac: Constants.DEFAULT_FALSE_COLOR_FRAC, grayscaleLineProfileActive: false, resolutionMeasurementActive: false,
					fftFilterActive: false, originalImage: '', hasRawImage: false, rawImageActive: false, toolTabsModel: toolTabsJson,
					grayscaleLineProfileChartActive: false, resolutionMeasurementChartActive: false,
					pipeWallThicknessChartActive: false, lineProfileChartActive: false, lengthCalibrationActive: false, lengthCalibrationChartActive: false,
					selectedToolTab: '',
					isPWTContextViewExtended: false, PWT_geometry_visible: true, displayItemType: DisplayItemType.image,
					viewports: [], protocolCorrelationId: ""
				});
			}
		},

		setWindowLevelHistActive(state, action) {
			const matrixIndex: number = action.payload.matrixIndex;
			const isActive: boolean = action.payload.isActive;
			const index: matrixImagesItem | undefined = state.matrixImages.find((mi) => mi.matrixIndex === matrixIndex);
			if (index) {
				index.windowLevelHistActive = isActive;
			}
		},

		setBlackMaskActive(state, action) {
			const matrixIndex: number = action.payload.matrixIndex;
			const isActive: boolean = action.payload.isActive;
			const index: matrixImagesItem | undefined = state.matrixImages.find((mi) => mi.matrixIndex === matrixIndex);
			if (index) {
				index.blackMaskActive = isActive;
			}
		},

		setIntlConfigOptions(state, action) {
			const intlConfigOptions: object = action.payload;
			state.intlConfigOptions = intlConfigOptions;
		},

		setDateFormat(state, action) {
			const dataFormat: string = action.payload;
			state.dataFormat = dataFormat;
		},

		setTimeFormat(state, action) {
			const timeFormat: string = action.payload;
			state.timeFormat = timeFormat;
		},

		setPregnancyAgeLimits(state, action) {
			const pregnancyAgeLimits: number[] = action.payload;
			state.pregnancyAgeLimits = pregnancyAgeLimits;
		},

		setPipeWallThicknessHistActive(state, action) {
			const matrixIndex: number = action.payload.matrixIndex;
			const isActive: boolean = action.payload.isActive;
			const index: matrixImagesItem | undefined = state.matrixImages.find((mi) => mi.matrixIndex === matrixIndex);
			if (index) {
				index.pipeWallThicknessHistActive = isActive;
			}
		},

		setLineProfileActive(state, action) {
			const matrixIndex: number = action.payload.matrixIndex;
			const isActive: boolean = action.payload.isActive;
			const index: matrixImagesItem | undefined = state.matrixImages.find((mi) => mi.matrixIndex === matrixIndex);
			if (index) {
				index.lineProfileActive = isActive;
			}
		},

		setFalseColorsActive(state, action) {
			const matrixIndex: number = action.payload.matrixIndex;
			const isActive: boolean = action.payload.isActive;
			const index: matrixImagesItem | undefined = state.matrixImages.find((mi) => mi.matrixIndex === matrixIndex);
			if (index) {
				index.falseColorsActive = isActive;
			}
		},

		setToolTabsModel(state, action) {
			const matrixIndex: number = action.payload.matrixIndex;
			const model: IJsonModel = action.payload.model;
			const index: matrixImagesItem | undefined = state.matrixImages.find((mi) => mi.matrixIndex === matrixIndex);
			if (index) {
				index.toolTabsModel = model;
			}
		},

		setFFTFilterActive(state, action) {
			const matrixIndex: number = action.payload.matrixIndex;
			const isActive: boolean = action.payload.isActive;
			const originalImage: string = action.payload.originalImage;
			const index: matrixImagesItem | undefined = state.matrixImages.find((mi) => mi.matrixIndex === matrixIndex);
			if (index) {
				index.fftFilterActive = isActive;
				index.originalImage = originalImage;
			}
		},

		setHasRawImage(state, action) {
			const matrixIndex: number = action.payload.matrixIndex;
			const hasRawImage: boolean = action.payload.hasRawImage;
			const index: matrixImagesItem | undefined = state.matrixImages.find((mi) => mi.matrixIndex === matrixIndex);
			if (index) {
				index.hasRawImage = hasRawImage;
			}
		},

		setRawImageActive(state, action) {
			const matrixIndex: number = action.payload.matrixIndex;
			const isActive: boolean = action.payload.isActive;
			const index: matrixImagesItem | undefined = state.matrixImages.find((mi) => mi.matrixIndex === matrixIndex);
			if (index) {
				index.rawImageActive = isActive;
			}
		},

		setFalseColorsFrac(state, action) {
			const matrixIndex: number = action.payload.matrixIndex;
			const fracs: number[] = action.payload.colorFrac;
			const index: matrixImagesItem | undefined = state.matrixImages.find((mi) => mi.matrixIndex === matrixIndex);
			if (index) {
				index.colorFrac = fracs;
			}
		},

		setActiveToolIcon(state, action) {
			const activeToolIcon: string = action.payload;
			state.activeToolIcon = activeToolIcon;
		},

		setDrawHandlesOnHover(state, action) {
			const drawHandlesOnHover: boolean = action.payload;
			state.drawHandlesOnHover = drawHandlesOnHover;
		},

		setPixelReplication(state, action) {
			const pixelReplication: boolean = action.payload;
			state.pixelReplication = pixelReplication;
		},

		setDemoMode(state, action) {
			const demoMode: boolean = action.payload;
			state.demoMode = demoMode;
		},

		setExportActive(state, action) {
			const isExportActive: boolean = action.payload;
			state.isExportActive = isExportActive;
		},

		setMaxLineWidth(state, action) {
			const maxLineWidth: number = action.payload;
			state.maxLineWidth = maxLineWidth;
		},

		setGrayscaleLineProfileActive(state, action) {
			const matrixIndex: number = action.payload.matrixIndex;
			const isActive: boolean = action.payload.isActive;
			const index: matrixImagesItem | undefined = state.matrixImages.find((mi) => mi.matrixIndex === matrixIndex);
			if (index) {
				index.grayscaleLineProfileActive = isActive;
			}
		},

		setResolutionMeasurementActive(state, action) {
			const matrixIndex: number = action.payload.matrixIndex;
			const isActive: boolean = action.payload.isActive;
			const index: matrixImagesItem | undefined = state.matrixImages.find((mi) => mi.matrixIndex === matrixIndex);
			if (index) {
				index.resolutionMeasurementActive = isActive;
			}
		},

		setAllWindowLevelHistActive(state, action) {
			const isActive: boolean = action.payload;
			state.matrixImages.forEach(item => item.windowLevelHistActive = isActive);
		},

		removeMatrixImages(state, action) {
			const matrixIndex: number = action.payload;
			const index: matrixImagesItem | undefined = state.matrixImages.find((mi) => mi.matrixIndex === matrixIndex);
			if (index) {
				const arrayIndex: number = state.matrixImages.indexOf(index);
				state.matrixImages.splice(arrayIndex, 1);
			}
		},
		purgeMatrixImages(state, action) {
			state.matrixImages.splice(0, state.matrixImages.length);
		},
		cutMatrixImages(state, action) {
			const matrixIndices: number[] = state.matrixImages.map(mi => mi.matrixIndex);
			for (let index: number = 0; index < matrixIndices.length; ++index) {
				if (matrixIndices[index] >= state.matrixColumns * state.matrixRows) {
					state.matrixImages.splice(index, 1);
				}
			}
		},

		setViewportForMatrixIndex(state, action) {
			const matrixIndex: number = action.payload.matrixIndex;
			const viewport: cornerstone.Viewport = action.payload.viewport;
			const currentWorkitemId: string = action.payload.currentWorkitemId;
			const indexInMatrix: number = state.matrixImages.findIndex((mi) => mi.matrixIndex === matrixIndex);

			if (indexInMatrix >= 0) {
				const index: number = state.matrixImages[indexInMatrix]?.viewports?.findIndex(currentViewport => currentViewport.id === currentWorkitemId);
				if (index >= 0) {
					state.matrixImages[indexInMatrix].viewports[index] = { id: currentWorkitemId, viewport: cornerstoneViewportToViewport(viewport, state.matrixImages[indexInMatrix].rawImageActive) };
				} else {
					state.matrixImages[indexInMatrix]?.viewports?.push({ id: currentWorkitemId, viewport: cornerstoneViewportToViewport(viewport, false) });
				}
			}
		},

		setGrayscaleLineProfileChartActive(state, action) {
			const matrixIndex: number = action.payload.matrixIndex;
			const isActive: boolean = action.payload.isActive;
			const index: matrixImagesItem | undefined = state.matrixImages.find((mi) => mi.matrixIndex === matrixIndex);
			if (index) {
				index.grayscaleLineProfileChartActive = isActive;
			}
		},

		setResolutionMeasurementChartActive(state, action) {
			const matrixIndex: number = action.payload.matrixIndex;
			const isActive: boolean = action.payload.isActive;
			const index: matrixImagesItem | undefined = state.matrixImages.find((mi) => mi.matrixIndex === matrixIndex);
			if (index) {
				index.resolutionMeasurementChartActive = isActive;
			}
		},

		setPipeWallThicknessChartActive(state, action) {
			const matrixIndex: number = action.payload.matrixIndex;
			const isActive: boolean = action.payload.isActive;
			const index: matrixImagesItem | undefined = state.matrixImages.find((mi) => mi.matrixIndex === matrixIndex);
			if (index) {
				index.pipeWallThicknessChartActive = isActive;
			}
		},

		setLineProfileChartActive(state, action) {
			const matrixIndex: number = action.payload.matrixIndex;
			const isActive: boolean = action.payload.isActive;
			const index: matrixImagesItem | undefined = state.matrixImages.find((mi) => mi.matrixIndex === matrixIndex);
			if (index) {
				index.lineProfileChartActive = isActive;
			}
		},

		setLengthCalibrationChartActive(state, action) {
			const matrixIndex: number = action.payload.matrixIndex;
			const isActive: boolean = action.payload.isActive;
			const index: matrixImagesItem | undefined = state.matrixImages.find((mi) => mi.matrixIndex === matrixIndex);
			if (index) {
				index.lengthCalibrationChartActive = isActive;
			}
		},

		setLengthCalibrationActive(state, action) {
			const matrixIndex: number = action.payload.matrixIndex;
			const isActive: boolean = action.payload.isActive;
			const index: matrixImagesItem | undefined = state.matrixImages.find((mi) => mi.matrixIndex === matrixIndex);
			if (index) {
				index.lengthCalibrationActive = isActive;
			}
		},

		setSelectedToolTab(state, action) {
			const matrixIndex: number = action.payload.matrixIndex;
			const selectedToolTab: string = action.payload.selectedTooltab;
			const index: matrixImagesItem | undefined = state.matrixImages.find((mi) => mi.matrixIndex === matrixIndex);
			if (index) {
				index.selectedToolTab = selectedToolTab;
			}
		},

		setPWTContextViewExtended(state, action) {
			const matrixIndex: number = action.payload.matrixIndex;
			const isPWTContextViewExtended: boolean = action.payload.isPWTContextViewExtended;
			const index: matrixImagesItem | undefined = state.matrixImages.find((mi) => mi.matrixIndex === matrixIndex);
			if (index) {
				index.isPWTContextViewExtended = isPWTContextViewExtended;
			}
		},

		setPWT_geometry_visible(state, action) {
			const matrixIndex: number = action.payload.matrixIndex;
			const PWT_geometry_visible: boolean = action.payload.PWT_geometry_visible;
			const index: matrixImagesItem | undefined = state.matrixImages.find((mi) => mi.matrixIndex === matrixIndex);
			if (index) {
				index.PWT_geometry_visible = PWT_geometry_visible;
			}
		},

		setViewportStorageType(state, action) {
			if (action.payload) {
				let storageType: ViewportStorageType = ViewportStorageType.none;
				if ((typeof action.payload) === "string")
					storageType = ViewportStorageType[action.payload as keyof typeof ViewportStorageType];
				else
					// assume correct type
					storageType = action.payload;
				state.viewportStorageType = storageType;
			}
		},
		setDeviationIndexThresholds(state, action) {
			if (action.payload !== undefined) {
				// assume correct type
				let thresholds: [number, number] = action.payload;
				state.deviationIndexThresholds = thresholds;
			} else {
				state.deviationIndexThresholds = [2, 5];
			}
		},
		setShowDeviationIndexWidget(state, action) {
			if (action.payload !== undefined) {
				// assume correct type
				state.showDeviationIndexWidget = action.payload;
			} else {
				state.showDeviationIndexWidget = false;
			}
		},
		setShowDeviationIndexValue(state, action) {
			if (action.payload !== undefined) {
				// assume correct type
				state.showDeviationIndexValue = action.payload;
			} else {
				state.showDeviationIndexValue = false;
			}
		},
		setHideActionButtonCloseWorkitem(state, action) {
			if (action.payload !== undefined) {
				// assume correct type
				state.hideActionButtonCloseWorkitem = action.payload;
			} else {
				state.hideActionButtonCloseWorkitem = false;
			}
		},
		setShowExposureIndexValue(state, action) {
			if (action.payload !== undefined) {
				// assume correct type
				state.showExposureIndexValue = action.payload;
			} else {
				state.showExposureIndexValue = false;
			}
		},
		setShowSValue(state, action) {
			if (action.payload !== undefined) {
				// assume correct type
				state.showSValue = action.payload;
			} else {
				state.showSValue = false;
			}
		},

		setCutToBMOnFinalize(state, action) {
			if (action.payload !== undefined) {
				// assume correct type
				state.cutToBMOnFinalize = action.payload;
			} else {
				state.cutToBMOnFinalize = true;
			}
		},

		setSupressWWWCHistonRightMouse(state, action) {
			if (action.payload !== undefined) {
				// assume correct type
				state.supressWWWCHistonRightMouse = action.payload;
			} else {
				state.supressWWWCHistonRightMouse = false;
			}
		},

		setIsImageMaximized(state, action) {
			if (action.payload !== undefined) {
				// assume correct type
				state.isImageMaximized = action.payload;
			} else {
				state.isImageMaximized = false;
			}
		},

		setIsImageLoadRequired(state, action) {
			if (action.payload !== undefined) {
				// assume correct type
				state.isImageLoadRequired = action.payload;
			} else {
				state.isImageLoadRequired = false;
			}
		},

		setActivatePlanningMode(state, action) {
			if (action.payload !== undefined) {
				// assume correct type
				state.activatePlanningMode = action.payload;
			} else {
				state.activatePlanningMode = false;
			}
		},

		setProtocolCorrelationId(state, action) {

			const protocolCorrelationId: string = action.payload.protocolCorrelationId;
			const matrixIndex: number = action.payload.matrixIndex;

			const index: matrixImagesItem | undefined = state.matrixImages.find((mi) => mi.matrixIndex === matrixIndex);
			if (index) {
				console.log("set correlation id " + protocolCorrelationId + " for matrix index: " + matrixIndex);
				index.protocolCorrelationId = protocolCorrelationId;
			} else {
				console.log("set correlation id " + protocolCorrelationId + " for NEW matrix index: " + matrixIndex);
				state.matrixImages.push({
					matrixIndex: matrixIndex, imageId: '', selectedTool: { name: "Pan", icon: "/images/Tools/arrows.svg", hasState: true, sticky: false, subMenu: [], config: {}, id: "" }, windowLevelHistActive: false, pipeWallThicknessHistActive: false,
					lineProfileActive: false, blackMaskActive: false,
					falseColorsActive: false, colorFrac: Constants.DEFAULT_FALSE_COLOR_FRAC, grayscaleLineProfileActive: false, resolutionMeasurementActive: false,
					fftFilterActive: false, originalImage: '', hasRawImage: false, rawImageActive: false, toolTabsModel: toolTabsJson,
					grayscaleLineProfileChartActive: false, resolutionMeasurementChartActive: false,
					pipeWallThicknessChartActive: false, lineProfileChartActive: false, lengthCalibrationActive: false, lengthCalibrationChartActive: false,
					selectedToolTab: '',
					isPWTContextViewExtended: false, PWT_geometry_visible: true, displayItemType: DisplayItemType.image,
					viewports: [], protocolCorrelationId: protocolCorrelationId
				});
			}
		},

		clearViewportsForImageId(state, action) {
			const imageId: string = action.payload;
			const matrixImage: matrixImagesItem | undefined = state.matrixImages.find(
				(mi: matrixImagesItem) => mi.imageId === imageId
			);
			if (matrixImage) {
				matrixImage.viewports = [];
			}
		},

		setProtocolStepSelectionProps(state, action) {
			const { workitemId, stepType, requiredAttribute, excludedAttribute, index, seriesIndex, correlationId } = action.payload;
			const index1: number = state.protocolStepSelectorProps.findIndex((mi: protocolStepSelectorPropsItem) => mi?.workitemId === workitemId);
			if (index1 < 0) {
				state.protocolStepSelectorProps.push({ workitemId, stepType, requiredAttribute, excludedAttribute, index, seriesIndex, correlationId });
			} else {
				state.protocolStepSelectorProps[index1] = { workitemId, stepType, requiredAttribute, excludedAttribute, index, seriesIndex, correlationId };
			}
		},

		removeProtocolStepSelectionProps(state, action) {
			const workitemId = action.payload;
			const index: number = state.protocolStepSelectorProps.findIndex((mi: protocolStepSelectorPropsItem) => mi?.workitemId === workitemId);
			if (index >= 0) {
				state.protocolStepSelectorProps.splice(index, 1);
			}
		},

		clearProtocolStepSelectionProps(state, action) {
			console.log("clearProtocolStepSelectionProps");
			state.protocolStepSelectorProps = [];
		}
	}
});

export const {
	setMatrix,
	setCurrentIndex,
	setSelectedIndex,
	setSelectedWorkitem,
	setCornerstoneInitialized,
	setViewportSynchronization,
	setImageDescVisible,
	setImageProcessing,
	incrFinalizeCounter,
	decrFinalizeCounter,
	setToolsPanel,
	setIsOverviewAndAnnotationsVisible,
	setOrderInfoVisible,
	setToolInsetVisible,
	setExportDialogVisible,
	setProcedureEditVisible,
	setFinalizeStudyDialogVisible,
	setReloadApplicationDialogVisible,
	setSubjectDialogVisible,
	setTooltabsPosition,
	setProtocolStepSelectionProps,
	removeProtocolStepSelectionProps,
	clearProtocolStepSelectionProps,
	setMatrixImages,
	removeMatrixImages,
	purgeMatrixImages,
	cutMatrixImages,
	setMatrixSelectedTool,
	addMatrixSelectedTool,
	setRowsPerPage,
	setSelectedPage,
	setTotalPages,
	setTotalElements,
	setStudiesSortString,
	setViewportForMatrixIndex,
	setWindowLevelHistActive,
	setPipeWallThicknessHistActive,
	setFalseColorsActive,
	setToolTabsModel,
	setHasRawImage,
	setRawImageActive,
	setAllWindowLevelHistActive,
	setGrayscaleLineProfileActive,
	setBlackMaskActive,
	setResolutionMeasurementActive,
	setLineProfileActive,
	setFFTFilterActive,
	setGrayscaleLineProfileChartActive,
	setResolutionMeasurementChartActive,
	setPipeWallThicknessChartActive,
	setLineProfileChartActive,
	setLengthCalibrationActive,
	setLengthCalibrationChartActive,
	clearViewportsForImageId,
	setActiveToolIcon,
	setDrawHandlesOnHover,
	setPixelReplication,
	setDemoMode,
	setMaxLineWidth,
	setExportActive,
	setPWTContextViewExtended,
	setPWT_geometry_visible,
	setDisplayItemType,
	setViewportStorageType,
	setDeviationIndexThresholds,
	setShowExposureIndexValue,
	setHideActionButtonCloseWorkitem,
	setIntlConfigOptions,
	setDateFormat,
	setTimeFormat,
	setPregnancyAgeLimits,
	setCutToBMOnFinalize,
	setSupressWWWCHistonRightMouse,
	setIsImageMaximized,
	setIsImageLoadRequired,
	setActivatePlanningMode,
	setProtocolCorrelationId,
	setReloadApplicationTimeoutId,
	setReloadApplicationTimeout
} = ImageDisplaySlice.actions;

export default ImageDisplaySlice.reducer;

export const selectMatrixCurrentIndex = (state: RootState): number => state.ImageDisplay.currentIndex;

export const selectMatrixPreviousIndex = (state: RootState): number => state.ImageDisplay.previousIndex;

export const selectMatrixIndex = (state: RootState) => state.ImageDisplay.selectedIndex;

export const selectRowsPerPage = (state: RootState) => state.ImageDisplay.rowsPerPage;

export const selectSelectedPage = (state: RootState) => state.ImageDisplay.selectedPage;

export const selectTotalPages = (state: RootState) => state.ImageDisplay.totalPages;

export const selectTotalElement = (state: RootState) => state.ImageDisplay.totalElements;

export const selectStudiesSortString = (state: RootState) => state.ImageDisplay.studiesSortString;

export const selectedWorkitem = (state: RootState) => state.ImageDisplay.selectedWorkitem;

export const selectMatrixColumns = (state: RootState): number => state.ImageDisplay.matrixColumns;

export const selectMatrixRows = (state: RootState): number => state.ImageDisplay.matrixRows;

export const selectCornerstoneInitialized = (state: RootState): boolean => state.ImageDisplay.isCornerstoneInitialized;

export const selectViewportSynchronized = (state: RootState): boolean => state.ImageDisplay.isViewportSynchronization;

export const selectImageDescVisible = (state: RootState): boolean => state.ImageDisplay.imageDescVisible;

export const selectToolsPanel = (state: RootState): boolean => state.ImageDisplay.isToolsPanel;

export const selectIsOverviewAndAnnotationsVisible = (state: RootState): boolean => state.ImageDisplay.IsOverviewAndAnnotationsVisible;

export const selectOrderInfoVisible = (state: RootState): boolean => state.ImageDisplay.orderInfoVisible;

export const selectToolInsetVisible = (state: RootState): boolean => state.ImageDisplay.isToolInsetVisible;

export const selectExportDialogVisible = (state: RootState): number => state.ImageDisplay.exportDialogVisible;

export const selectProcedureEditVisible = (state: RootState): number => state.ImageDisplay.procedureEditDialogVisible

export const selectFinalizeStudyDialogVisible = (state: RootState): number => state.ImageDisplay.finalizeStudyDialogVisible;

export const selectSubjectDialogVisible = (state: RootState): number => state.ImageDisplay.subjectDialogVisible;

export const selectCompleteIncompleteStudyVisible = (state: RootState): boolean => state.ImageDisplay.completeIncompleteStudyVisible;

export const selectReloadApplicationDialogVisible = (state: RootState): boolean => state.ImageDisplay.reloadApplicationDialogVisible;

export const selectReloadApplicationTimeoutId = (state: RootState): number|undefined => state.ImageDisplay.reloadApplicationTimeoutId;

export const selectReloadApplicationTimeout = (state: RootState): number => state.ImageDisplay.reloadApplicationTimeout;

export const selectTooltabsPosition = (state: RootState): string => state.ImageDisplay.tooltabsPosition;

export const selectMatrixImages = (state: RootState): matrixImagesItem[] => state.ImageDisplay.matrixImages;

export const selectIntlConfigOptions = (state: RootState) => {
	return state.ImageDisplay.intlConfigOptions;
}

export const selectDataFormat = (state: RootState): string => state.ImageDisplay.dataFormat;
export const selectTimeFormat = (state: RootState): string => state.ImageDisplay.timeFormat;

export const selectPregnancyAgeLimits = (state: RootState): number[] => state.ImageDisplay.pregnancyAgeLimits;

export const getImageIdAtMatrixIndex = (state: RootState, matrixIndex: number): string => {
	let ret: string = "";
	const matrixImage: matrixImagesItem = state.ImageDisplay.matrixImages.find(
		(mi: matrixImagesItem) => mi.matrixIndex === matrixIndex
	);
	if (matrixImage) {
		ret = matrixImage.imageId;
	}
	return ret;
};

export const getProtocolCorrelationIdAtMatrixIndex = (state: RootState, matrixIndex: number): string => {
	let ret: string = "";
	const matrixImage: matrixImagesItem = state.ImageDisplay.matrixImages.find(
		(mi: matrixImagesItem) => mi.matrixIndex === matrixIndex
	);
	if (matrixImage) {
		ret = matrixImage.protocolCorrelationId;
	}
	return ret;
};

export const getCornerstoneImageIdAtMatrixIndex = (state: RootState, matrixIndex: number): string => {
	let ret: string = "";
	const matrixImage: matrixImagesItem = state.ImageDisplay.matrixImages.find(
		(mi: matrixImagesItem) => mi.matrixIndex === matrixIndex
	);
	if (matrixImage) {
		const cornerstoneElements: EnabledElement[] = cornerstone.getEnabledElements();
		const displayElement: EnabledElement | undefined = cornerstoneElements.find(
			(element: EnabledElement) => element.element.id === `${Constants.IMAGE_DISPLAY_GENERIC_ELEMENT_NAME}_${matrixIndex}`
		);
		if (displayElement?.image?.imageId) {
			ret = displayElement?.image?.imageId;
		}
	}
	return ret;
};

export const getCornerstoneTargetIdAtMatrixIndex = (state: RootState, matrixIndex: number): string => {
	let ret: string = "";
	const matrixImage: matrixImagesItem = state.ImageDisplay.matrixImages.find(
		(mi: matrixImagesItem) => mi.matrixIndex === matrixIndex
	);
	if (matrixImage) {
		const cornerstoneElements: EnabledElement[] = cornerstone.getEnabledElements();
		const displayElement: EnabledElement | undefined = cornerstoneElements.find(
			(element: EnabledElement) => element.element.id === `${Constants.IMAGE_DISPLAY_GENERIC_ELEMENT_NAME}_${matrixIndex}`
		);
		// @ts-ignore
		if (displayElement?.image?.targetId) {
			// @ts-ignore
			ret = displayElement?.image?.targetId;
		}
	}
	return ret;
};

export const getSelectedToolAtMatrixIndex = (state: RootState, matrixIndex: number): ToolsListEntry | undefined => {
	let ret: ToolsListEntry | undefined = undefined;
	const matrixImage: matrixImagesItem = state.ImageDisplay.matrixImages.find(
		(mi: matrixImagesItem) => mi.matrixIndex === matrixIndex
	);
	if (matrixImage) {
		ret = matrixImage.selectedTool;
	}
	return ret;
};

export const getWindowLevelHistActive = (state: RootState, matrixIndex: number): boolean => {
	let ret: boolean = false;
	const matrixImage: matrixImagesItem = state.ImageDisplay.matrixImages.find(
		(mi: matrixImagesItem) => mi.matrixIndex === matrixIndex
	);
	if (matrixImage) {
		ret = matrixImage.windowLevelHistActive;
	}
	return ret;
};

export const getBlackMaskActive = (state: RootState, matrixIndex: number): boolean => {
	let ret: boolean = false;
	const matrixImage: matrixImagesItem = state.ImageDisplay.matrixImages.find(
		(mi: matrixImagesItem) => mi.matrixIndex === matrixIndex
	);
	if (matrixImage) {
		ret = matrixImage.blackMaskActive;
	}
	return ret;
};

export const getPipeWallThicknessHistActive = (state: RootState, matrixIndex: number): boolean => {
	let ret: boolean = false;
	const matrixImage: matrixImagesItem = state.ImageDisplay.matrixImages.find(
		(mi: matrixImagesItem) => mi.matrixIndex === matrixIndex
	);
	if (matrixImage) {
		ret = matrixImage.pipeWallThicknessHistActive;
	}
	return ret;
};

export const getLineProfileActive = (state: RootState, matrixIndex: number): boolean => {
	let ret: boolean = false;
	const matrixImage: matrixImagesItem = state.ImageDisplay.matrixImages.find(
		(mi: matrixImagesItem) => mi.matrixIndex === matrixIndex
	);
	if (matrixImage) {
		ret = matrixImage.lineProfileActive;
	}
	return ret;
};

export const getFalseColorsActive = (state: RootState, matrixIndex: number): boolean => {
	let ret: boolean = false;
	const matrixImage: matrixImagesItem = state.ImageDisplay.matrixImages.find(
		(mi: matrixImagesItem) => mi.matrixIndex === matrixIndex
	);
	if (matrixImage) {
		ret = matrixImage.falseColorsActive;
	}
	return ret;
};

export const getToolTabsModel = (state: RootState, matrixIndex: number): IJsonModel | undefined => {
	let ret: IJsonModel | undefined = undefined;
	const matrixImage: matrixImagesItem = state.ImageDisplay.matrixImages.find(
		(mi: matrixImagesItem) => mi.matrixIndex === matrixIndex
	);
	if (matrixImage && matrixImage.toolTabsModel) {
		//console.log(matrixImage.toolTabsModel);
		ret = matrixImage.toolTabsModel;
	}
	return ret;
};

/* export const getHasRawImage = (state: RootState, matrixIndex: number): boolean => {
  let ret: boolean = false;
  const matrixImage: matrixImagesItem = state.ImageDisplay.matrixImages.find(
	(mi: matrixImagesItem) => mi.matrixIndex === matrixIndex
  );
  if (matrixImage) {
	ret = matrixImage.hasRawImage;
  }
  return ret;
}; */

export const getHasRawImage = (state: RootState, matrixIndex: number): boolean => {
	let ret: boolean = false;

	let workitem: any = undefined;

	if (state.ImageDisplay.selectedWorkitem) {
		const selectWorkitemResult = apiSlice.endpoints.getworkitem.select(state.ImageDisplay.selectedWorkitem);
		workitem = selectWorkitemResult(state)?.data;
		if (workitem === undefined) {
			workitem = getWorkitemFromStudyWithWorkitems(state, state.Orders.currentOrderId, state.ImageDisplay.selectedWorkitem);
		}
	}

	const correlationId = getProtocolCorrelationIdAtMatrixIndex(state, matrixIndex);

	if (correlationId && workitem?.protocol?.steps) {
		ret = (workitem.protocol?.steps?.findIndex((step: any) => step?.type === Constants.ACQUISITION_STEP_TYPE && step.performed !== undefined && step?.correlationId === correlationId)) >= 0;
	}

	return ret;
};

export const getRawImageActive = (state: RootState, matrixIndex: number): boolean => {
	let ret: boolean = false;
	const matrixImage: matrixImagesItem = state.ImageDisplay.matrixImages.find(
		(mi: matrixImagesItem) => mi.matrixIndex === matrixIndex
	);
	if (matrixImage) {
		ret = matrixImage.rawImageActive;
	}
	return ret;
};

export const getActiveToolIcon = (state: RootState): string => state.ImageDisplay.activeToolIcon;

export const getDrawHandlesOnHover = (state: RootState): boolean => state.ImageDisplay.drawHandlesOnHover;

export const getPixelReplication = (state: RootState): boolean => state.ImageDisplay.pixelReplication;

export const getDemoMode = (state: RootState): boolean => state.ImageDisplay.demoMode;

export const getMaxLineWidth = (state: RootState): number => state.ImageDisplay.maxLineWidth;

export const getExportActive = (state: RootState): boolean => state.ImageDisplay.isExportActive;

export const getIsImageMaximized = (state: RootState): boolean => state.ImageDisplay.isImageMaximized;

export const getIsImageLoadRequired = (state: RootState): boolean => state.ImageDisplay.isImageLoadRequired;

export const getActivatePlanningMode = (state: RootState): boolean => state.ImageDisplay.activatePlanningMode;

export const getColorFracAtMatrixIndex = (state: RootState, matrixIndex: number): number[] | undefined => {
	let ret: number[] | undefined = undefined;
	const matrixImage: matrixImagesItem = state.ImageDisplay.matrixImages.find(
		(mi: matrixImagesItem) => mi.matrixIndex === matrixIndex
	);
	if (matrixImage) {
		ret = matrixImage.colorFrac;
	}
	return ret;
};

export const getDisplayItemTypeAtMatrixIndex = (state: RootState, matrixIndex: number): DisplayItemType => {
	let ret: DisplayItemType = DisplayItemType.image;
	const matrixImage: matrixImagesItem = state.ImageDisplay.matrixImages.find(
		(mi: matrixImagesItem) => mi.matrixIndex === matrixIndex
	);
	if (matrixImage) {
		ret = matrixImage.displayItemType;
	}
	return ret;
};

export const getGrayscaleLineProfileActive = (state: RootState, matrixIndex: number): boolean => {
	let ret: boolean = false;
	const matrixImage: matrixImagesItem = state.ImageDisplay.matrixImages.find(
		(mi: matrixImagesItem) => mi.matrixIndex === matrixIndex
	);
	if (matrixImage) {
		ret = matrixImage.grayscaleLineProfileActive;
	}
	return ret;
};

export const getResolutionMeasurementActive = (state: RootState, matrixIndex: number): boolean => {
	let ret: boolean = false;
	const matrixImage: matrixImagesItem = state.ImageDisplay.matrixImages.find(
		(mi: matrixImagesItem) => mi.matrixIndex === matrixIndex
	);
	if (matrixImage) {
		ret = matrixImage.resolutionMeasurementActive;
	}
	return ret;
};


export const getPipeWallThicknessChartActive = (state: RootState, matrixIndex: number): boolean => {
	let ret: boolean = false;
	const matrixImage: matrixImagesItem = state.ImageDisplay.matrixImages.find(
		(mi: matrixImagesItem) => mi.matrixIndex === matrixIndex
	);
	if (matrixImage) {
		ret = matrixImage.pipeWallThicknessChartActive;
	}
	return ret;
};

export const getLineProfileChartActive = (state: RootState, matrixIndex: number): boolean => {
	let ret: boolean = false;
	const matrixImage: matrixImagesItem = state.ImageDisplay.matrixImages.find(
		(mi: matrixImagesItem) => mi.matrixIndex === matrixIndex
	);
	if (matrixImage) {
		ret = matrixImage.lineProfileChartActive;
	}
	return ret;
};

export const getLengthCalibrationActive = (state: RootState, matrixIndex: number): boolean => {
	let ret: boolean = false;
	const matrixImage: matrixImagesItem = state.ImageDisplay.matrixImages.find(
		(mi: matrixImagesItem) => mi.matrixIndex === matrixIndex
	);
	if (matrixImage) {
		ret = matrixImage.lengthCalibrationActive;
	}
	return ret;
};

export const getLengthCalibrationChartActive = (state: RootState, matrixIndex: number): boolean => {
	let ret: boolean = false;
	const matrixImage: matrixImagesItem = state.ImageDisplay.matrixImages.find(
		(mi: matrixImagesItem) => mi.matrixIndex === matrixIndex
	);
	if (matrixImage) {
		ret = matrixImage.lengthCalibrationChartActive;
	}
	return ret;
};

export const getSelectedToolTab = (state: RootState, matrixIndex: number): string => {
	let ret: string = '';
	const matrixImage: matrixImagesItem = state.ImageDisplay.matrixImages.find(
		(mi: matrixImagesItem) => mi.matrixIndex === matrixIndex
	);
	if (matrixImage) {
		ret = matrixImage.selectedToolTab;
	}
	return ret;
};

export const getPWTContextViewExtended = (state: RootState, matrixIndex: number): boolean => {
	let ret: boolean = false;
	const matrixImage: matrixImagesItem = state.ImageDisplay.matrixImages.find(
		(mi: matrixImagesItem) => mi.matrixIndex === matrixIndex
	);
	if (matrixImage) {
		ret = matrixImage.isPWTContextViewExtended;
	}
	return ret;
};

export const getPWT_geometry_visible = (state: RootState, matrixIndex: number): boolean => {
	let ret: boolean = false;
	const matrixImage: matrixImagesItem = state.ImageDisplay.matrixImages.find(
		(mi: matrixImagesItem) => mi.matrixIndex === matrixIndex
	);
	if (matrixImage) {
		ret = matrixImage.PWT_geometry_visible;
	}
	return ret;
};

export const getGrayscaleLineProfileChartActive = (state: RootState, matrixIndex: number): boolean => {
	let ret: boolean = false;
	const matrixImage: matrixImagesItem = state.ImageDisplay.matrixImages.find(
		(mi: matrixImagesItem) => mi.matrixIndex === matrixIndex
	);
	if (matrixImage) {
		ret = matrixImage.grayscaleLineProfileChartActive;
	}
	return ret;
};

export const getResolutionMeasurementChartActive = (state: RootState, matrixIndex: number): boolean => {
	let ret: boolean = false;
	const matrixImage: matrixImagesItem = state.ImageDisplay.matrixImages.find(
		(mi: matrixImagesItem) => mi.matrixIndex === matrixIndex
	);
	if (matrixImage) {
		ret = matrixImage.resolutionMeasurementChartActive;
	}
	return ret;
};


export const getFFTFilterActive = (state: RootState, matrixIndex: number): boolean => {
	let ret: boolean = false;
	const matrixImage: matrixImagesItem = state.ImageDisplay.matrixImages.find(
		(mi: matrixImagesItem) => mi.matrixIndex === matrixIndex
	);
	if (matrixImage) {
		ret = matrixImage.fftFilterActive;
	}
	return ret;
};

export const getToolInsetActive = (state: RootState, matrixIndex: number): boolean => {
	let ret: boolean = false;
	const matrixImage: matrixImagesItem = state.ImageDisplay.matrixImages.find(
		(mi: matrixImagesItem) => mi.matrixIndex === matrixIndex
	);
	if (matrixImage) {
		ret = matrixImage.windowLevelHistActive || matrixImage.falseColorsActive || matrixImage.pipeWallThicknessChartActive ||
			matrixImage.lineProfileChartActive || matrixImage.grayscaleLineProfileChartActive ||
			matrixImage.resolutionMeasurementChartActive || matrixImage.lengthCalibrationChartActive || matrixImage.blackMaskActive;
	}
	return ret;
};

export const getOriginalImage = (state: RootState, matrixIndex: number): string => {
	let ret: string = '';
	const matrixImage: matrixImagesItem = state.ImageDisplay.matrixImages.find(
		(mi: matrixImagesItem) => mi.matrixIndex === matrixIndex
	);
	if (matrixImage) {
		ret = matrixImage.originalImage;
	}
	return ret;
};

export const getMatrixIndexForImageId = (state: RootState, imageId: string): number => {
	let ret: number = -1;
	const matrixImage: matrixImagesItem = state.ImageDisplay.matrixImages.find(
		(mi: matrixImagesItem) => mi.imageId === imageId
	);
	if (matrixImage) {
		ret = matrixImage.matrixIndex;
	}
	return ret;
};

export const getMatrixIndexForInsert = (state: RootState, matrixIndex: number): number => {
	let newIndex: number = 0;
	const newMatrixIndex: number = state.ImageDisplay.currentIndex;
	if (matrixIndex < 0) {
		let freeMatrixCell: matrixImagesItem | undefined = undefined;
		for (let j = 0; j < state.ImageDisplay.matrixImages.length; j++) {
			if (state.ImageDisplay.matrixImages[j].imageId.trim() === '') {
				freeMatrixCell = state.ImageDisplay.matrixImages[j];
				break;
			}
		}
		if (freeMatrixCell) {
			newIndex = freeMatrixCell.matrixIndex;
		} else {
			newIndex = (newMatrixIndex + 1) % ((state.ImageDisplay.matrixColumns * state.ImageDisplay.matrixRows));
		}
	} else {
		newIndex = matrixIndex;
	}
	return newIndex;
};

export const getViewportForMatrixIndexAndImageID = (state: RootState, matrixIndex: number, imageId: string | undefined): Viewport | undefined => {
	let ret: Viewport | undefined = undefined;
	const indexInMatrix: number = state.ImageDisplay.matrixImages.findIndex((mi: matrixImagesItem) => mi.matrixIndex === matrixIndex);
	if (indexInMatrix >= 0 && imageId) {
		const index: number = state.ImageDisplay.matrixImages[indexInMatrix]?.viewports?.findIndex((currentViewport: { id: string, viewport: cornerstone.Viewport }) => currentViewport.id === imageId);
		if (index >= 0) {
			ret = JSON.parse(JSON.stringify(state.ImageDisplay.matrixImages[indexInMatrix]?.viewports[index]?.viewport));
		}
	}

	return ret;
};

export const getVoiRawForMatrixIndexAndImageID = (state: RootState, matrixIndex: number, imageId: string | undefined): VOI | undefined => {
	let ret: VOI | undefined = undefined;
	const indexInMatrix: number = state.ImageDisplay.matrixImages.findIndex((mi: matrixImagesItem) => mi.matrixIndex === matrixIndex);
	if (indexInMatrix >= 0 && imageId) {
		const index: number = state.ImageDisplay.matrixImages[indexInMatrix]?.viewports?.findIndex((currentViewport: { id: string, viewport: cornerstone.Viewport }) => currentViewport.id === imageId);
		if (index >= 0) {
			const voi_tmp: VOI | undefined = state.ImageDisplay.matrixImages[indexInMatrix]?.viewports[index]?.voi_raw;
			if (voi_tmp) {
				ret = JSON.parse(JSON.stringify(state.ImageDisplay.matrixImages[indexInMatrix]?.viewports[index]?.voi_raw));
			}
		}
	}

	return ret;
};

export const getNewSelectedMatrixIndex = (state: RootState): { matrixIndex: number; imageId: string } | undefined => {
	let newIndex: { matrixIndex: number; imageId: string } | undefined = undefined;
	let matrixImageAtStart: matrixImagesItem | undefined = undefined;
	for (let j = 0; j < state.ImageDisplay.matrixImages.length; j++) {
		if (state.ImageDisplay.matrixImages[j].imageId.trim() !== '') {
			matrixImageAtStart = state.ImageDisplay.matrixImages[j];
			break;
		}
	}

	if (state.ImageDisplay.matrixColumns * state.ImageDisplay.matrixRows < (state.ImageDisplay.selectedIndex + 1) && matrixImageAtStart) {
		newIndex = { matrixIndex: matrixImageAtStart.matrixIndex, imageId: matrixImageAtStart.imageId };
	}
	return newIndex;
}

export const getIsImageProcessing = (state: RootState): boolean => {
	return state.ImageDisplay.isImageProcessing;
}

export const getIntlOptions = (state: RootState) => state.ImageDisplay.intlConfigOptions;

export const getViewportStorageType = (state: RootState): ViewportStorageType => state.ImageDisplay.viewportStorageType;
export const getDeviationIndexThresholds = (state: RootState): [number, number] => state.ImageDisplay.deviationIndexThresholds;
export const getShowDeviationIndexWidget = (state: RootState): boolean => state.ImageDisplay.showDeviationIndexWidget;
export const getShowDeviationIndexValue = (state: RootState): boolean => state.ImageDisplay.showDeviationIndexValue;
export const getShowExposureIndexValue = (state: RootState): boolean => state.ImageDisplay.showExposureIndexValue;
export const getHideActionButtonCloseWorkitem = (state: RootState): boolean => state.ImageDisplay.hideActionButtonCloseWorkitem;
export const getShowSValue = (state: RootState): boolean => state.ImageDisplay.showSValue;

export const getProtocolStepSelectionProps = (state: RootState, workitemId: string) => {
	let ret: any = undefined;
	const protocolStepSelectorProps: protocolStepSelectorPropsItem = state.ImageDisplay.protocolStepSelectorProps.find(
		(mi: protocolStepSelectorPropsItem) => mi.workitemId === workitemId
	);
	if (protocolStepSelectorProps) {
		ret = { stepType: protocolStepSelectorProps.stepType, requiredAttribute: protocolStepSelectorProps.requiredAttribute, excludedAttribute: protocolStepSelectorProps.excludedAttribute, index: protocolStepSelectorProps.index, seriesIndex: protocolStepSelectorProps.seriesIndex, correlationId: protocolStepSelectorProps.correlationId }
	}
	return JSON.stringify(ret);
};

export const getIsProtocolStepRejected = (state: RootState, stepType: string, requiredAttribute: string | undefined, currentWorkitem?: workitem, seriesIndex = -1) => {
	let stepNumber: number = -1;

	let workitem: any = undefined;
	if (currentWorkitem === undefined) {
		if (state.ImageDisplay.selectedWorkitem) {
			const selectWorkitemResult = apiSlice.endpoints.getworkitem.select(state.ImageDisplay.selectedWorkitem);
			workitem = selectWorkitemResult(state)?.data;
			if (workitem === undefined) {
				workitem = getWorkitemFromStudyWithWorkitems(state, state.Orders.currentOrderId, state.ImageDisplay.selectedWorkitem);
			}
		}
	} else {
		workitem = currentWorkitem;
	}

	let step = undefined;
	if (workitem?.protocol?.steps) {
		const retAll = workitem.protocol?.steps?.filter((step: any) => step?.type === stepType && (requiredAttribute === undefined || step[requiredAttribute] !== undefined));
		if (seriesIndex < 0) {
			step = retAll[Math.max(retAll.length + seriesIndex, 0)];
		} else {
			step = retAll[Math.min(seriesIndex, retAll.length - 1)]
		}

	}

	if (step !== undefined)
		return step.rejected !== undefined;
	else
		return false;
}

export const getCurrentProtocolStepNumber = (state: RootState, stepType: string, requiredAttribute: string | undefined, excludedAttribute: string | undefined, currentWorkitem?: workitem, index?: number, seriesIndex = -1, correlationId?: string) => {
	let stepNumber: number = -1;

	let workitem: any = undefined;
	if (currentWorkitem === undefined) {
		if (state.ImageDisplay.selectedWorkitem) {
			const selectWorkitemResult = apiSlice.endpoints.getworkitem.select(state.ImageDisplay.selectedWorkitem);
			workitem = selectWorkitemResult(state)?.data;
			if (workitem === undefined) {
				workitem = getWorkitemFromStudyWithWorkitems(state, state.Orders.currentOrderId, state.ImageDisplay.selectedWorkitem);
			}
		}
	} else {
		workitem = currentWorkitem;
	}

	if (workitem?.protocol?.steps) {
		let ret = undefined;

		const retAll = workitem.protocol?.steps?.filter((step: any) => step?.type === stepType);
		const finalSteps: any[] = [];
		if (retAll) {
			retAll.forEach((step: any) => {
				if (step.type !== Constants.ACQUISITION_STEP_TYPE) {
					if (requiredAttribute === undefined || step[requiredAttribute] !== undefined) {
						if ((excludedAttribute === undefined || step[excludedAttribute] === undefined)) {
							// check if correlated raw setp is rejected
							if (step?.correlationId) {
								const rawStep = workitem.protocol?.steps?.find((st: any) => st.type === Constants.ACQUISITION_STEP_TYPE && st.correlationId === step?.correlationId);
								if (rawStep) {
									if (rawStep?.rejected === undefined) {
										finalSteps.push(step)
									}
								} else {
									finalSteps.push(step)
								}
							} else {
								finalSteps.push(step)
							}
						}
					}
				} else {
					if (requiredAttribute === undefined || step[requiredAttribute] !== undefined) {
						if ((excludedAttribute === undefined || step[excludedAttribute] === undefined)) {
							finalSteps.push(step)
						}
					}
				}
			})
		}

		//const retAll1 = workitem.protocol?.steps?.filter((step: any) => step?.type === stepType && (requiredAttribute === undefined || step[requiredAttribute] !== undefined) && (excludedAttribute === undefined || step[excludedAttribute] === undefined));
		if (seriesIndex < 0) {
			ret = finalSteps[Math.max(finalSteps.length + seriesIndex, 0)];
		} else {
			ret = finalSteps[Math.min(seriesIndex, finalSteps.length - 1)]
		}

		if (ret) {
			stepNumber = workitem.protocol?.steps?.indexOf(ret);
		}
	}
	return stepNumber;
}

export const getCurrentProtocolStep = (state: RootState, currentWorkitem?: workitem) => {
	let ret: any = undefined;

	let workitem: any = undefined;
	if (currentWorkitem === undefined) {
		if (state.ImageDisplay.selectedWorkitem) {
			const selectWorkitemResult = apiSlice.endpoints.getworkitem.select(state.ImageDisplay.selectedWorkitem);
			workitem = selectWorkitemResult(state)?.data;
			if (workitem === undefined) {
				workitem = getWorkitemFromStudyWithWorkitems(state, state.Orders.currentOrderId, state.ImageDisplay.selectedWorkitem);
			}
		}
	} else {
		workitem = currentWorkitem;
	}

	if (workitem !== undefined) {

		if (workitem.state === "COMPLETED") {
			const postProcessedSteps = workitem?.protocol?.steps?.filter((step: any) => step?.type === Constants.POSTPROCESSING_STEP_TYPE && step?.performed !== undefined)
			if (postProcessedSteps && Array.isArray(postProcessedSteps) && postProcessedSteps.length > 0) {
				ret = postProcessedSteps[postProcessedSteps.length - 1];
			}
		} else {
			const foundImageSteps = workitem.protocol?.steps?.filter((step: any) => step.type !== Constants.ACQUISITION_STEP_TYPE &&
				step?.rejected === undefined && step?.performed?.output?.series !== undefined &&
				Array.isArray(step?.performed?.output?.series) && step?.performed?.output?.series.length > 0);

			if (foundImageSteps !== undefined && foundImageSteps.length > 0) {
				for (const step of foundImageSteps) {
					const rawStep = workitem.protocol?.steps?.filter((step1: any) => step1.type === Constants.ACQUISITION_STEP_TYPE &&
						step1?.correlationId === step?.correlationId && step1?.rejected === undefined);
					if (rawStep !== undefined && rawStep.length > 0) {
						ret = step;						
					}
				}
			}

			if (ret === undefined) {
				const foundRawImageSteps = workitem.protocol?.steps?.filter((step: any) => step.type === Constants.ACQUISITION_STEP_TYPE &&
					step?.rejected === undefined && step?.performed?.output?.series !== undefined &&
					Array.isArray(step?.performed?.output?.series) && step?.performed?.output?.series.length > 0);

				if (foundRawImageSteps !== undefined && foundRawImageSteps.length > 0) {
					ret = foundRawImageSteps[foundRawImageSteps.length - 1];
				}
			}
		}
	}

	return ret;
}

export const getRejectedSeriesFromProtocolSteps = (state: RootState, currentWorkitem?: workitem, showRejected?: boolean) => {
	if (showRejected === false)
		return "[]";

	let seriesIds: any = [];

	let workitem: any = undefined;
	if (currentWorkitem === undefined) {
		if (state.ImageDisplay.selectedWorkitem) {
			const selectWorkitemResult = apiSlice.endpoints.getworkitem.select(state.ImageDisplay.selectedWorkitem);
			workitem = selectWorkitemResult(state)?.data;
			if (workitem === undefined) {
				workitem = getWorkitemFromStudyWithWorkitems(state, state.Orders.currentOrderId, state.ImageDisplay.selectedWorkitem);
			}
		}
	} else {
		workitem = currentWorkitem;
	}

	if (workitem?.protocol?.steps) {
		const steps = workitem.protocol?.steps?.filter((step: any) => step.type === Constants.ACQUISITION_STEP_TYPE && step?.rejected !== undefined);

		for (let i = 0; i < steps.length; i++) {
			let elem = steps[i];
			const series = elem.performed?.output?.series;
			if (series && series.length > 0)
				seriesIds.push(series[0]);
		}
	}

	return JSON.stringify(seriesIds);
}

export const getSeriesForProtocolStep = (state: RootState, currentWorkitem: workitem, stepNumber: number) => {

	let series: any = undefined;

	let workitem: any = undefined;
	if (currentWorkitem === undefined) {
		if (state.ImageDisplay.selectedWorkitem) {
			const selectWorkitemResult = apiSlice.endpoints.getworkitem.select(state.ImageDisplay.selectedWorkitem);
			workitem = selectWorkitemResult(state)?.data;
			if (workitem === undefined) {
				workitem = getWorkitemFromStudyWithWorkitems(state, state.Orders.currentOrderId, state.ImageDisplay.selectedWorkitem);
			}
		}
	} else {
		workitem = currentWorkitem;
	}

	if (workitem?.protocol?.steps && stepNumber !== undefined && stepNumber >= 0) {
		const step = workitem.protocol?.steps[stepNumber];

		if (step?.performed?.output?.series && Array.isArray(step?.performed?.output?.series) && step?.performed?.output?.series.length > 0) {
			const selectSeriesResult = apiSlice.endpoints.getSeries.select(step?.performed?.output?.series);

			if (selectSeriesResult && selectSeriesResult(state)?.data) {
				if (selectSeriesResult(state)?.data && selectSeriesResult(state)?.data?.length > 0) {
					series = selectSeriesResult(state)?.data;
				}
			}
		}
	}

	return JSON.stringify(series);
}

export const getProtocolStepNumberFromSeries = (state: RootState, seriesId: string, currentWorkitem?: workitem) => {
	let workitem: any = undefined;
	if (currentWorkitem === undefined) {
		if (state.ImageDisplay.selectedWorkitem) {
			const selectWorkitemResult = apiSlice.endpoints.getworkitem.select(state.ImageDisplay.selectedWorkitem);
			workitem = selectWorkitemResult(state)?.data;
			if (workitem === undefined) {
				workitem = getWorkitemFromStudyWithWorkitems(state, state.Orders.currentOrderId, state.ImageDisplay.selectedWorkitem);
			}
		}
	} else {
		workitem = currentWorkitem;
	}

	if (workitem?.protocol?.steps) {
		let n = workitem?.protocol?.steps.length;
		for (let i = 0; i < n; i++) {
			const step = workitem?.protocol?.steps[i];
			if (step && step.performed && step.performed.output && step.performed.output.series) {
				if (step.performed.output.series.includes(seriesId)) {
					return i;
				}
			}
		}
	}
	return -1;
}



export const getAreAllAcquisitionStepsRejected = (state: RootState, currentWorkitem?: workitem) => {
	let workitem: any = undefined;
	if (currentWorkitem === undefined) {
		if (state.ImageDisplay.selectedWorkitem) {
			const selectWorkitemResult = apiSlice.endpoints.getworkitem.select(state.ImageDisplay.selectedWorkitem);
			workitem = selectWorkitemResult(state)?.data;
			if (workitem === undefined) {
				workitem = getWorkitemFromStudyWithWorkitems(state, state.Orders.currentOrderId, state.ImageDisplay.selectedWorkitem);
			}
		}
	} else {
		workitem = currentWorkitem;
	}

	if (workitem?.protocol?.steps) {
		let n = workitem?.protocol?.steps.length;
		for (let i = 0; i < n; i++) {
			const step = workitem?.protocol?.steps[i];
			if (step && step.type === Constants.ACQUISITION_STEP_TYPE) {
				if (step.rejected === undefined) {
					return false;
				}
			}
		}
	}
	return true;
}

export const getAllDisplayStepsForRejectedAcquisitions = (state: RootState, currentWorkitem?: workitem) => {
	let workitem: any = undefined;
	if (currentWorkitem === undefined) {
		if (state.ImageDisplay.selectedWorkitem) {
			const selectWorkitemResult = apiSlice.endpoints.getworkitem.select(state.ImageDisplay.selectedWorkitem);
			workitem = selectWorkitemResult(state)?.data;
			if (workitem === undefined) {
				workitem = getWorkitemFromStudyWithWorkitems(state, state.Orders.currentOrderId, state.ImageDisplay.selectedWorkitem);
			}
		}
	} else {
		workitem = currentWorkitem;
	}

	let ret = [];

	if (workitem?.protocol?.steps) {
		let n = workitem?.protocol?.steps.length;
		for (let i = 0; i < n; i++) {
			const step = workitem?.protocol?.steps[i];
			if (step && step.type === Constants.ACQUISITION_STEP_TYPE && step.rejected !== undefined) {
				const correlatedSteps = workitem?.protocol?.steps.filter((item: any) => item.type === Constants.PROCESSING_STEP_TYPE && item.correlationId === step.correlationId);
				if (correlatedSteps && correlatedSteps.length > 0)
					ret.push(correlatedSteps[correlatedSteps.length - 1]);
			}
		}
	}

	return JSON.stringify(ret);
}

export const getDisplayStep = (state: RootState, currentWorkitem?: workitem, showRawImage?: boolean) => {
	let workitem: any = undefined;
	if (currentWorkitem === undefined) {
		if (state.ImageDisplay.selectedWorkitem) {
			const selectWorkitemResult = apiSlice.endpoints.getworkitem.select(state.ImageDisplay.selectedWorkitem);
			workitem = selectWorkitemResult(state)?.data;
			if (workitem === undefined) {
				workitem = getWorkitemFromStudyWithWorkitems(state, state.Orders.currentOrderId, state.ImageDisplay.selectedWorkitem);
			}
		}
	} else {
		workitem = currentWorkitem;
	}

	let ret = "";

	if (workitem?.protocol?.steps) {
		let n = workitem?.protocol?.steps.length;
		for (let i = 0; i < n; i++) {
			const step = workitem?.protocol?.steps[i];
			if (step && step.type === Constants.ACQUISITION_STEP_TYPE) {
				if (showRawImage) {
					// return acquisition step regardless of rejection state
					ret = step;
				} else {
					if (step.rejected === undefined) {
						let correlatedSteps = workitem?.protocol?.steps.filter((item: any) => item.correlationId === step.correlationId);

						if (correlatedSteps && correlatedSteps.length > 0) {
							const postprocessingSteps = workitem?.state === "COMPLETED" ? correlatedSteps.filter((item: any) => item.type === Constants.POSTPROCESSING_STEP_TYPE && item.performed?.output?.series !== undefined) : [];
							if (postprocessingSteps.length === 0) {
								const processingSteps = correlatedSteps.filter((item: any) => item.type === Constants.PROCESSING_STEP_TYPE && item.performed?.output?.series !== undefined);
								if (processingSteps.length === 0) {
									const acquisitionSteps = correlatedSteps.filter((item: any) => item.type === Constants.ACQUISITION_STEP_TYPE && item.performed?.output?.series !== undefined);
									if (acquisitionSteps.length !== 0) {
										ret = acquisitionSteps[acquisitionSteps.length - 1];
									}
								} else {
									ret = processingSteps[processingSteps.length - 1];
								}

							} else {
								ret = postprocessingSteps[postprocessingSteps.length - 1];
							}

							break;
						}
					}
				}
			}
		}
	}
	return JSON.stringify(ret);
}

export const getFirstProcessingStep = (state: RootState, currentWorkitem?: workitem) => {
	let workitem: any = undefined;
	if (currentWorkitem === undefined) {
		if (state.ImageDisplay.selectedWorkitem) {
			const selectWorkitemResult = apiSlice.endpoints.getworkitem.select(state.ImageDisplay.selectedWorkitem);
			workitem = selectWorkitemResult(state)?.data;
			if (workitem === undefined) {
				workitem = getWorkitemFromStudyWithWorkitems(state, state.Orders.currentOrderId, state.ImageDisplay.selectedWorkitem);
			}
		}
	} else {
		workitem = currentWorkitem;
	}

	let ret = "";

	if (workitem?.protocol?.steps) {
		let n = workitem?.protocol?.steps.length;
		for (let i = 0; i < n; i++) {
			const step = workitem?.protocol?.steps[i];
			if (step && step.type === Constants.ACQUISITION_STEP_TYPE) {
				if (step.rejected === undefined) {
					let correlatedSteps = workitem?.protocol?.steps.filter((item: any) => item.correlationId === step.correlationId);
					if (correlatedSteps && correlatedSteps.length > 0) {
						const processingSteps = correlatedSteps.filter((item: any) => item.type === Constants.PROCESSING_STEP_TYPE && item.performed?.output?.series !== undefined);
						if (processingSteps && processingSteps.length > 0) {
							ret = processingSteps[0];
							break;
						}
					}
				}
			}
		}
	}
	return JSON.stringify(ret);
} // getFirstProcessingStep

export const getCutToBMOnFinalize = (state: RootState): boolean => state.ImageDisplay.cutToBMOnFinalize;
export const getSupressWWWCHistonRightMouse = (state: RootState): boolean => state.ImageDisplay.supressWWWCHistonRightMouse;
