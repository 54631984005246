import { useEffect } from "react";
import { TypedUseSelectorHook, useSelector, useDispatch } from "react-redux";
import { AppDispatch, RootState } from "../store";
import ActionButton from "../components/OrderList/ActionButton";
import MainOrderList from "../components/OrderList/MainOrderList";
import { selectCancelSoftwareTriggerUrl, selectSoftwareTriggerState } from "../components/Akquisition/AcquisitionSlice";
import { apiSlice } from "../apis/apiSlice";


const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
const useAppDispatch = () => useDispatch<AppDispatch>();

const OrderList = () => {
	const dispatch = useAppDispatch();


	const cancelSoftwareTriggerUrl: string | undefined = useAppSelector((state) => selectCancelSoftwareTriggerUrl(state));
	const currentSoftwareTriggerState: string | undefined = useAppSelector((state) => selectSoftwareTriggerState(state));

	useEffect(() => {
		/* console.log("OrderList");
		console.log(cancelSoftwareTriggerUrl);
		console.log(currentSoftwareTriggerState); */
		dispatch({ type: 'Acquisition/setDeviceSetOpen', payload: false });
		dispatch({ type: 'Acquisition/setIsAcquistionOngoing', payload: false });
		dispatch({ type: 'Acquisition/setAcquisitionButtonActive', payload: false });
		if(currentSoftwareTriggerState === 'TRIGGER_REQUESTED') {
			//console.log('cancelSoftwareTrigger');
			dispatch(apiSlice.endpoints.postWebThingAction.initiate({ url: cancelSoftwareTriggerUrl, body: { cancelSoftwareTrigger: { input: {} } } }));
		}
	}, [dispatch]);
	return (
		<div className="main-order-list">
			<ActionButton title="expose object" />
			<MainOrderList />
		</div>
	);
}

export default OrderList;
