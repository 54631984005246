import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit'
import { loadAndDisplayImage } from "../ImageDisplay/LoadAndDisplayImage";
import * as cornerstoneWADOImageLoader from "cornerstone-wado-image-loader";
import * as cornerstoneFileImageLoader from "cornerstone-file-image-loader";
import TifRaw2FileImageLoader from "../ImageDisplay/TifRaw2FileImageLoader";
import { RootState } from '../../store';
import { apiSlice, instance, study, transformStudy, workitem } from '../../apis/apiSlice';
import { messageSeverity } from '../common/MessageSlice';
import { formatISO } from 'date-fns';
import { GetImageType } from '../Utils/GetImageType';
import { CatalogueEntry } from '../Akquisition/CatalogueSlice';
import { clearConerstoneCanvasForMatrixIndex, setAcquisitionActive } from '../Akquisition/AcquisitionSlice';
import cornerstone, { EnabledElement } from 'cornerstone-core';
import { DisplayItemType, getCurrentProtocolStep, getMatrixIndexForInsert, getProtocolCorrelationIdAtMatrixIndex, matrixImagesItem, protocolStepSelectorPropsItem } from '../ImageDisplay/ImageDisplaySlice';
import { PDFStart } from '../ImageDisplay/PdfDocument';
import { serviceEndpoints } from '../..';
import { t } from "../Localization/ORLocalization";
import { ToolsListEntry } from '../../App';
import { Constants } from '../../Constants';


export enum addImageMode {
    none,
    createPreview,
    noPreviewCreation,
}

function Sleep(milliseconds: number) {
    return new Promise(resolve => setTimeout(resolve, milliseconds));
}

isDataURL.regex = /^\s*data:([a-z]+\/[a-z0-9-+.]+(;[a-z-]+=[a-z0-9-]+)?)?(;base64)?,([a-z0-9!$&',()*+;=\-._~:@/?%\s]*)\s*$/i;
function isDataURL(s: string): boolean {
    return !!s.match(isDataURL.regex);
}

const initialState: { imageLoadStatus: 'idle' | 'loading' | 'succeeded' | 'failed', imageDisplayInvalidated: boolean, currentOrderId: string, currentSeriesId: string, isMainOrderListOpen: boolean, expiresWarning: string, isRelatedStudiesOpen: boolean } =
    { imageLoadStatus: 'idle', imageDisplayInvalidated: false, currentOrderId: '', currentSeriesId: '', isMainOrderListOpen: false, expiresWarning: '', isRelatedStudiesOpen: false };

export const loadImage = createAsyncThunk('Orders/loadImage', async (args: {
    imageName: string | File | undefined, imageId: string | undefined,
    artifactId: string | undefined, artifactId_Raw: string | undefined, createPreviewImage: addImageMode,
    matrixIndex: number, display_raw: boolean, enabledElementForExport: EnabledElement | undefined
}, thunkApi) => {
    if (args && args.imageName) {
        let imageName: string = '';
        let imageFile: File | undefined = undefined;

        if (args.imageName instanceof File) {    //load image from file
            imageFile = args.imageName;
            const imageFileExt: string = args.imageName.name.split('\\')?.pop()?.split('.').pop() ?? "";
            if (imageFileExt.toLowerCase() === 'dcm') {
                imageName = cornerstoneWADOImageLoader.wadouri.fileManager.add(args.imageName);
            } else if (imageFileExt.toLowerCase() === 'jpg' || imageFileExt.toLowerCase() === 'png') {
                imageName = cornerstoneFileImageLoader.fileManager.add(args.imageName);
            } else if (imageFileExt.toLowerCase() === 'tif' || imageFileExt.toLowerCase() === 'tiff') {
                imageName = TifRaw2FileImageLoader.addtif(args.imageName);
            } else if (imageFileExt.toLowerCase() === 'raw2') {
                imageName = TifRaw2FileImageLoader.addraw2(args.imageName);
            } else if (imageFileExt.toLowerCase() === 'pdf') {
                imageName = `file-document:${args.imageName}`;
            } else {
                // not valid extension -> read and inspect header
                try {
                    let blob: Blob = args.imageName.slice(0, 132);
                    let contentBuffer: ArrayBuffer = await blob.arrayBuffer();
                    let array = new Uint8Array(contentBuffer);

                    const fileType: string | undefined = GetImageType(array)?.fileType;

                    if (fileType) {
                        if (fileType === 'IMG') {
                            imageName = cornerstoneFileImageLoader.fileManager.add(args.imageName);
                        } else if (fileType === 'TIF') {
                            imageName = TifRaw2FileImageLoader.addtif(args.imageName);
                        } else if (fileType === 'DICM') {
                            imageName = cornerstoneWADOImageLoader.wadouri.fileManager.add(args.imageName);
                        }
                    } else {
                        console.log("not a valid image format: " + args.imageName.name);
                    }

                } catch (err) {
                    console.log(err);
                }
            }
        } else { // get image from API
            let imageFileExt: string = args.imageName.split('\\')?.pop()?.split('.').pop() ?? "";

            if (args.artifactId && args.artifactId.length > 0) {

                // try to get image ext. from artifactPath (artifact store response)

                let artifactMimeType: string = selectArtifactMimeType(thunkApi.getState(), args.artifactId ?? '');

                let getArtifactQeury_executed: boolean = false
                let artifactDispatch: any = undefined;
                if (artifactMimeType === undefined) {

                    artifactDispatch = thunkApi.dispatch(apiSlice.endpoints.getArtifactMimeType.initiate(args.artifactId));
                    artifactMimeType = (await artifactDispatch)?.data;

                    getArtifactQeury_executed = true;
                }

                if (artifactMimeType) {
                    switch (artifactMimeType) {
                        case "image/jpeg":
                            imageFileExt = 'jpg';
                            break;
                        case "image/png":
                            imageFileExt = 'png';
                            break;
                        case "application/dicom":
                            imageFileExt = 'dcm';
                            break;
                        case "image/tiff":
                            imageFileExt = 'tif';
                            break;
                        case "application/octet-stream":
                            imageFileExt = 'raw2';
                            break;
                        case "application/pdf":
                            imageFileExt = 'pdf';
                            break;
                        default:
                            imageFileExt = 'dcm';
                    }
                }

                if (getArtifactQeury_executed) {
                    artifactDispatch?.unsubscribe();
                }
            }


            // temporary file from drag & drop
            if (args.imageName.startsWith("dicomfile:") || args.imageName.startsWith("tiffile:") || args.imageName.startsWith("raw2file:") || args.imageName.startsWith("imagefile:")) {
                imageName = args.imageName;
                // processing api call
            } else if (args.imageName.startsWith("wadouri:")) {
                imageName = args.imageName;
            }
            // image url's from artifact store
            else if (imageFileExt.toLowerCase() === 'tif' || imageFileExt.toLowerCase() === 'tiff') {
                imageName = `tifUri:${args.imageName}`;
                // raw2 url's from demoCtrl
            } else if (imageFileExt.toLowerCase() === 'raw2') {
                imageName = `raw2Uri:${args.imageName}`;
                // web image loader
            } else if (imageFileExt.toLowerCase() === 'jpg' || imageFileExt.toLowerCase() === 'png') {
                imageName = `${args.imageName}`;
                // pdf document
            } else if (imageFileExt.toLowerCase() === 'pdf') {
                imageName = `document:${args.imageName}`;
                // wadouri image loader
            } else { // image load from url
                imageName = `wadouri:${args.imageName}`;
                //imageName = `tifUri:${args.imageName}`;
            }
        }

        if (imageName.startsWith('document:') || imageName.startsWith('file-document:')) {
            const newIndex = getMatrixIndexForInsert(thunkApi.getState(), args.matrixIndex);
            clearConerstoneCanvasForMatrixIndex(newIndex);

            if (imageName.startsWith('file-document:')) {
                const reader = new FileReader();
                reader.onloadend = async () => {
                    const documentUrl: any = reader.result;
                    await PDFStart(documentUrl, newIndex);
                    const canvas: HTMLCanvasElement | null = document.querySelector(`#documentCanvas_${newIndex.toString()}`) as HTMLCanvasElement;
                    if (canvas) {
                        const previewImageUrl: string = canvas.toDataURL('image/jpeg', 0.1) ?? '';
                        const base64Image: string = previewImageUrl.replace('data:image/jpeg;base64,', '');
                        thunkApi.dispatch(addImageFromFile({
                            image: undefined, imageFile: args.imageName as File, imageId: args.imageId, base64Image:
                                base64Image, matrixIndex: newIndex, displayItemType: DisplayItemType.pdf, title: t('documentFromFile', { ns: 'Acquisition' }), imageName: imageName
                        }));
                    }
                }
                reader.readAsDataURL(args.imageName as File);
            } else {
                PDFStart(`${serviceEndpoints.ARTIFACT_STORE_URL}/artifacts/${args.artifactId}/raw`, newIndex);
            }

            thunkApi.dispatch({ type: "ImageDisplay/setSelectedIndex", payload: newIndex });
            thunkApi.dispatch({ type: "ImageDisplay/setSelectedWorkitem", payload: args.imageId });
            thunkApi.dispatch({ type: "ImageDisplay/setWindowLevelHistActive", payload: { matrixIndex: newIndex, isActive: false } });
            thunkApi.dispatch({ type: "ImageDisplay/setFalseColorsActive", payload: { matrixIndex: newIndex, isActive: false } });
            thunkApi.dispatch({ type: "ImageDisplay/setPipeWallThicknessChartActive", payload: { matrixIndex: newIndex, isActive: false } });
            thunkApi.dispatch({ type: "ImageDisplay/setLineProfileChartActive", payload: { matrixIndex: newIndex, isActive: false } });
            thunkApi.dispatch({ type: "ImageDisplay/setGrayscaleLineProfileChartActive", payload: { matrixIndex: newIndex, isActive: false } });
            thunkApi.dispatch({ type: "ImageDisplay/setResolutionMeasurementChartActive", payload: { matrixIndex: newIndex, isActive: false } });
            thunkApi.dispatch({ type: "ImageDisplay/setLengthCalibrationChartActive", payload: { matrixIndex: newIndex, isActive: false } });
            thunkApi.dispatch({ type: "ImageDisplay/setToolTabsModel", payload: { matrixIndex: newIndex, model: undefined } });
            thunkApi.dispatch({ type: "ImageDisplay/setHasRawImage", payload: { matrixIndex: newIndex, hasRawImage: false } });
            thunkApi.dispatch({ type: 'ImageDisplay/setMatrixImages', payload: { matrixIndex: newIndex, imageId: args.imageId, displayItemType: DisplayItemType.pdf } });
            thunkApi.dispatch({ type: 'ImageDisplay/setCurrentIndex', payload: newIndex });


        } else {
            await loadAndDisplayImage({
                imageFile: imageFile, imageName: imageName, imageId: args.imageId, artifactId: args.artifactId, artifactId_Raw: args.artifactId_Raw, createPreviewImage: args.createPreviewImage, matrixIndex: args.matrixIndex, display_raw: args.display_raw,
                enabledElementForExport: args.enabledElementForExport, dispatch: thunkApi.dispatch, getState: thunkApi.getState
            });
        }

    }
})

export const deleteImage = createAsyncThunk('Orders/deleteImage', async (args: string, thunkApi) => {

    try {
        const getState: () => RootState = thunkApi.getState;
        await thunkApi.dispatch(apiSlice.endpoints.cancelWorkitem.initiate({ workitemId: args }));
        thunkApi.dispatch({ type: "ImageDisplay/setSelectedIndex", payload: -1 });
        thunkApi.dispatch({ type: "ImageDisplay/purgeMatrixImages", payload: {} });
        if (getState().Procedure.procedureSelectionVisibilityState === 2) {
            const firstPlanningWorkitemIdInOrder = selectFirstPlanningWorkitemIdInOrder(getState(), getState().Orders.currentOrderId);
            if (firstPlanningWorkitemIdInOrder !== "") {
                thunkApi.dispatch({ type: 'ImageDisplay/setSelectedWorkitem', payload: firstPlanningWorkitemIdInOrder });
            } else {
                thunkApi.dispatch({ type: 'ImageDisplay/setSelectedWorkitem', payload: "" });
                thunkApi.dispatch({ type: "Procedure/setProcedureSelectionVisibilityState", payload: 1 });
            }
        } else {
            thunkApi.dispatch({ type: 'ImageDisplay/setSelectedWorkitem', payload: "" });
        }
        //if (isSelectedWorkitemPlanning(thunkApi.getState())) {
        thunkApi.dispatch(setAcquisitionActive({ setAcquistionButtonActive: false, studyId: undefined, workitemId: undefined }));
        //thunkApi.dispatch({ type: "Procedure/setProcedureSelectionVisibilityState", payload: 0 });
        //}
    } catch (error) {
        thunkApi.dispatch({ type: "Message/setMessage", payload: { timestamp: new Date().toISOString(), severity: messageSeverity.error, text: "error removing workitem: " + JSON.stringify(error) } });
    };
})

export const addImageFromFile = createAsyncThunk('Orders/addImageFromFile', async (args: { image: any, imageFile: File | undefined, imageId: string | undefined, base64Image: string | undefined, matrixIndex: number | undefined, displayItemType: DisplayItemType, title: string, imageName: string }, thunkApi) => {
    try {
        let mimeType: string | undefined = undefined;
        let blob: Blob | undefined = args.imageFile?.slice(0, 132);
        if (blob) {
            let contentBuffer: ArrayBuffer = await blob.arrayBuffer();
            let array = new Uint8Array(contentBuffer);
            mimeType = GetImageType(array)?.mimeType;
        }

        const addNewArtifactResponse: any = await thunkApi.dispatch(apiSlice.endpoints.putArtifact.initiate({ base64Image: args.base64Image, mimeType: mimeType })).unwrap();
        if (args.imageFile && addNewArtifactResponse?.id) {
            //const new_artifact = new FormData();
            //new_artifact.append("raw", args.imageFile);
            //const addNewArtifactUploadResponse: any = await thunkApi.dispatch(apiSlice.endpoints.putArtifactUpload.initiate({ id: addNewArtifactResponse?.id, body: new_artifact })).unwrap();
            const getState: () => RootState = thunkApi.getState;
            const studyId: string = getState().Orders.currentOrderId;
            const title: string = args.title;
            const desc: string = args.imageFile.name;
            if (addNewArtifactResponse?.id) {
                let workitemId: string | undefined = undefined;

                const newWorkitemResponse = await thunkApi.dispatch(apiSlice.endpoints.putWorkitem.initiate({
                    details:
                    {
                        ref_study: studyId, created: formatISO(new Date()),
                        title: title, desc: desc, document_mime_typ: args.image ? (args?.imageName?.startsWith('tiffile') ? 'application/tiff' : '') : 'application/pdf'
                    }
                })).unwrap();
                workitemId = newWorkitemResponse?.id;

                if (workitemId) {
                    let modality: string = '';
                    if (args.image) {
                        const generalImageModule = cornerstone.metaData.get('generalImageModule', args.image?.imageId) || {};
                        modality = generalImageModule?.modality ?? '';
                    } else {
                        modality = 'DOC';
                    }

                    const newSeriesResponse: any = await thunkApi.dispatch(apiSlice.endpoints.putSeries.initiate({
                        workListId: workitemId, created: formatISO(new Date()),
                        title: title, desc: desc, modality: modality,
                    })).unwrap();

                    // -----------------------------------------------------------------------------

                    if (newSeriesResponse?.id) {
                        const newInstanceResponse: any = await thunkApi.dispatch(apiSlice.endpoints.putInstances.initiate({
                            studyId: studyId, workitemId: workitemId, seriesId: newSeriesResponse?.id, artifactId: addNewArtifactResponse?.id, created: formatISO(new Date()),
                            title: title, desc: desc
                        })).unwrap();
                        if (newInstanceResponse?.id) {

                            thunkApi.dispatch(apiSlice.util.upsertQueryData('getInstance', newInstanceResponse?.id,
                                {
                                    id: newInstanceResponse?.id,
                                    details: {
                                        ref_series: newSeriesResponse?.id, artifact_id: addNewArtifactResponse?.id,
                                        ref_user_name: getState().auth?.jwtData?.name
                                    },
                                }));
                            thunkApi.dispatch(apiSlice.util.upsertQueryData('getSeries', [newSeriesResponse?.id],
                                [{
                                    state: 'COMPLETED',
                                    details: {
                                        ref_workitem: workitemId, created: formatISO(new Date()),
                                        title: title, desc: desc, modality: modality,
                                        ref_user_name: getState().auth?.jwtData?.name,
                                    },
                                    id: newSeriesResponse?.id,
                                    instances: [newInstanceResponse?.id]
                                }]));
                            const newWorkitem = structuredClone(newWorkitemResponse);
                            newWorkitem.state = 'IN_PROGRESS';

                            thunkApi.dispatch(
                                apiSlice.util.updateQueryData('getStudyWithWorkitems', studyId, (data: any) => {
                                    const workitemList1 = data?.study?.workItems.map((id: string) => id);
                                    const workitemList2 = data?.workitems?.map((workitem: any, i: number) => workitem?.data?.id);
                                    if (workitemId && workitemList1 && workitemList2 && !workitemList1.includes(workitemId)) {
                                        data.study.workItems.push(workitemId);
                                        data.workitems.push({ data: newWorkitem });
                                    }
                                    return data;
                                })
                            )

                            // -----------------------------------------------------------------------------

                            const new_artifact = new FormData();
                            new_artifact.append("raw", args.imageFile);
                            await thunkApi.dispatch(apiSlice.endpoints.putArtifactUpload.initiate({ id: addNewArtifactResponse?.id, body: new_artifact })).unwrap();

                            if (args.image && addNewArtifactResponse?.id) {
                                args.image.targetId = `${serviceEndpoints.ARTIFACT_STORE_URL}/artifacts/${addNewArtifactResponse?.id}/raw`;
                            }

                            thunkApi.dispatch({ type: "ImageDisplay/setSelectedWorkitem", payload: workitemId });
                            thunkApi.dispatch({ type: 'ImageDisplay/setMatrixImages', payload: { matrixIndex: args.matrixIndex, imageId: workitemId, displayItemType: args.displayItemType } });
                        }
                    }
                }
                thunkApi.dispatch(setAcquisitionActive({ setAcquistionButtonActive: false, studyId: undefined, workitemId: undefined }));
                thunkApi.dispatch({ type: "Procedure/setProcedureSelectionVisibilityState", payload: 0 });
            }
        }
    } catch (error) {
        thunkApi.dispatch({ type: "Message/setMessage", payload: { timestamp: new Date().toISOString(), severity: messageSeverity.error, text: "error uploading/storing image: " + JSON.stringify(error) } });
    }
})

export const addImageFromProcessing = createAsyncThunk('Orders/addImageFromProcessing', async (args: { workitemId: string | undefined, artifactId: string | undefined }, thunkApi) => {
    try {
        if (args.workitemId && args.artifactId) {

            const getState: () => RootState = thunkApi.getState;
            const studyId: string = getState().Orders.currentOrderId;
            const title: string = "processed image";
            const desc: string = "image from processing";

            const newSeriesResponse: any = await thunkApi.dispatch(apiSlice.endpoints.putSeries.initiate({
                workListId: args.workitemId, created: formatISO(new Date()),
                title: title, desc: desc
            })).unwrap();
            if (newSeriesResponse?.id) {
                const newInstanceResponse: any = await thunkApi.dispatch(apiSlice.endpoints.putInstances.initiate({
                    studyId: studyId, workitemId: args.workitemId, seriesId: newSeriesResponse?.id, artifactId: args.artifactId, created: formatISO(new Date()),
                    title: title, desc: desc
                })).unwrap();
            }
        }
    } catch (error) {
        thunkApi.dispatch({ type: "Message/setMessage", payload: { timestamp: new Date().toISOString(), severity: messageSeverity.error, text: "error uploading/storing processed image: " + JSON.stringify(error) } });
    }
})

const OrdersSlice = createSlice({
    name: 'Orders',
    initialState,
    reducers: {
        setImageDisplayInvalidated(state, action) {
            const imageDisplayInvalidated = action.payload
            state.imageDisplayInvalidated = imageDisplayInvalidated;
        },
        setCurrentOrderId(state, action) {
            const currentOrderId: string = action.payload;
            state.currentOrderId = currentOrderId;
        },
        setCurrentSeriesId(state, action) {
            const currentSeriesId: string = action.payload;
            state.currentSeriesId = currentSeriesId;
        },
        setMainOrderListOpen(state, action) {
            const isMainOrderListOpen: boolean = action.payload;
            state.isMainOrderListOpen = isMainOrderListOpen;
        },


        setExpiresWarning(state, action) {
            const expiresWarning: string = action.payload;
            state.expiresWarning = expiresWarning;
        },

        setIsRelatedStudiesOpen(state, action) {
            const isRelatedStudiesOpen: boolean = action.payload;
            state.isRelatedStudiesOpen = isRelatedStudiesOpen;
        },
    },

    extraReducers(builder) {
        builder
            .addCase(loadImage.pending, (state, action) => {
                state.imageLoadStatus = 'loading'
            })
            .addCase(loadImage.fulfilled, (state, action) => {
                state.imageLoadStatus = 'succeeded'
            })
            .addCase(loadImage.rejected, (state, action) => {
                state.imageLoadStatus = 'failed'
                //state.error = action.error.message
            })
            .addCase(deleteImage.fulfilled, (state, action) => {
                state.imageDisplayInvalidated = true;
            })
    }
});

const getCurrentProtocolStepSelector = (state: RootState, workitem: any) => {
    let ret: any = undefined;
    if (workitem?.protocol?.steps) {
        const protocolStepSelectorProps: protocolStepSelectorPropsItem = state.ImageDisplay.protocolStepSelectorProps.find(
            (mi: protocolStepSelectorPropsItem) => mi.workitemId === workitem?.id
        );
        if (protocolStepSelectorProps) {
            ret = { stepType: protocolStepSelectorProps.stepType, requiredAttribute: protocolStepSelectorProps.requiredAttribute, seriesIndex: protocolStepSelectorProps.seriesIndex }
        }
    }
    return ret;
}

const getCurrentSeriesForProtocolStepSelector = (state: RootState, workitem: any) => {
    let seriesData: any = undefined;
    const ret = getCurrentProtocolStepSelector(state, workitem);

    if (ret) {
        const retAll = workitem.protocol?.steps?.filter((step: any) => step?.type === ret?.stepType && (ret?.requiredAttribute === undefined || step[ret?.requiredAttribute] !== undefined) && !step?.rejected);

        let selectedStep = undefined;
        if (ret?.seriesIndex < 0) {
            selectedStep = retAll[Math.max(retAll.length + ret?.seriesIndex, 0)];
        } else {
            selectedStep = retAll[Math.min(ret?.seriesIndex, retAll.length - 1)]
        }

        if (selectedStep) {
            const series = selectedStep[ret?.requiredAttribute]?.output?.series;
            const selectSeriesResult = apiSlice.endpoints.getSeries.select(series);

            if (selectSeriesResult && selectSeriesResult(state)?.data) {
                if (selectSeriesResult(state)?.data && selectSeriesResult(state)?.data?.length > 0) {
                    seriesData = selectSeriesResult(state)?.data[selectSeriesResult(state)?.data?.length - 1];
                }
            }
        }
        return seriesData;
    }
}

export const getCurrentRawSeries = (state: RootState, workitem: any) => {
    let selectRawSeriesResult = undefined;

    let protocolCorrelationId: string = "";
    const matrixImage: matrixImagesItem = state.ImageDisplay.matrixImages.find(
        (mi: matrixImagesItem) => mi.matrixIndex === state.ImageDisplay.selectedIndex
    );
    if (matrixImage) {
        protocolCorrelationId = matrixImage.protocolCorrelationId;
    }

    if (workitem) {

        /* console.log(protocolCorrelationId);
        console.log(workitem?.protocol?.steps);

        let currentProtocolStepRaw = undefined
        if (protocolCorrelationId === undefined) {
            currentProtocolStepRaw = workitem.protocol?.steps?.find((step: any) => step?.type === 'de.or.protocol.steps/acquisition' &&
                step.performed !== undefined && !step?.rejected);
        } else {
            currentProtocolStepRaw = workitem.protocol?.steps?.find((step: any) => step?.type === 'de.or.protocol.steps/acquisition' &&
                step.performed !== undefined && protocolCorrelationId === step?.correlationId);
        }

        console.log(currentProtocolStepRaw); */

        const currentProtocolStepRaw = workitem.protocol?.steps?.find((step: any) => step?.type === 'de.or.protocol.steps/acquisition' &&
            step.performed !== undefined && !step?.rejected);
        if (currentProtocolStepRaw) {
            const outputSeriesRaw = currentProtocolStepRaw.performed?.output?.series;
            const selectSeriesRaw = apiSlice.endpoints.getSeries.select(outputSeriesRaw);
            if (selectSeriesRaw && selectSeriesRaw(state)?.data) {
                selectRawSeriesResult = selectSeriesRaw(state)?.data[selectSeriesRaw(state)?.data?.length - 1];
            }
        }
    }
    return selectRawSeriesResult;
}

export const { setImageDisplayInvalidated, setCurrentOrderId, setCurrentSeriesId,
    setMainOrderListOpen, setExpiresWarning } = OrdersSlice.actions
export default OrdersSlice.reducer;

export const selectCurrentOrder = (state: RootState) => {
    return state.Orders.currentOrderId;
}

export const selectSeriesOrder = (state: RootState) => {
    return state.Orders.currentSeriesId;
}

export const selectMainOrderListOpen = (state: RootState) => {
    return state.Orders.isMainOrderListOpen;
}

export const selectExpiresWarning = (state: RootState) => {
    return state.Orders.expiresWarning;
}

export const selectIsRelatedStudiesOpen = (state: RootState) => {
    return state.Orders.isRelatedStudiesOpen;
}

export const getImageDisplayInvalidated = (state: RootState) => {
    return state.Orders.imageDisplayInvalidated;
}

export const selectProcessingTime = (state: RootState, orderId: string | undefined, imageId: string | undefined) => {
    let ret: number | undefined = undefined;
    /* if (orderId && imageId) {
        const order: OrdersItem | undefined = state.Orders.orders.find((order: OrdersItem) => order.orderNumber === orderId);
        if (order) {
            const orderImage: OrderImage | undefined = order.images?.find(image => image.id === imageId);
            if (orderImage) {
                ret = orderImage.processingTime;
            }
        }
    } */
    return ret;
}


export const selectStudiesResult = apiSlice.endpoints.searchStudies.select();

export const selectStudyList = createSelector(
    selectStudiesResult,
    (selectStudiesResult: any) => selectStudiesResult?.data?.content?.map((item: any) => transformStudy(item)) ?? undefined,
);

export const selectStudiesCount = createSelector(
    selectStudyList,
    (studyList: any[] | undefined) => studyList?.length ?? undefined,
);

export const selectWorkItemList = createSelector(
    selectStudiesResult,
    (selectStudiesResult: any) => selectStudiesResult?.data?.content ?? undefined,
);

export const selectedStudy = (state: RootState, studyId: string) => {
    let ret: study | undefined = undefined;
    const selectedStudyResult = apiSlice.endpoints.getStudyWithWorkitems.select(studyId);
    ret = selectedStudyResult(state)?.data?.study;
    if (!ret) {
        const selectStudiesResult = apiSlice.endpoints.searchStudies.select();
        const studies = selectStudiesResult(state)?.data?.content;
        if (studies) {
            const selectedStudy: study = studies.find((st: study) => st.id === studyId);
            if (selectedStudy) {
                ret = selectedStudy;
            }
        }
    }
    return JSON.stringify(ret);
}

export const selectedStudyState = (state: RootState, studyId: string) => {
    let ret: string = '';
    const selectedStudyResult = apiSlice.endpoints.getStudyWithWorkitems.select(studyId);
    ret = selectedStudyResult(state)?.data?.study;
    if (!ret) {
        const selectStudiesResult = apiSlice.endpoints.searchStudies.select();
        const studies = selectStudiesResult(state)?.data?.content;
        if (studies) {
            const selectedStudy: study = studies.find((st: study) => st.id === studyId);
            if (selectedStudy) {
                ret = selectedStudy.state;
            }
        }
    }
    return JSON.stringify(ret);
}

export const studyWorkitemsList = (state: RootState, studyId: string) => {
    let ret: string[] = [];
    const selectedStudyResult = apiSlice.endpoints.getStudyWithWorkitems.select(studyId);
    ret = selectedStudyResult(state)?.data?.study?.workItems;
    if (!ret) {
        const selectStudiesResult = apiSlice.endpoints.searchStudies.select();
        const studies = selectStudiesResult(state)?.data?.content;
        if (studies) {
            const selectedStudy: study = studies.find((st: study) => st.id === studyId);
            if (selectedStudy) {
                ret = selectedStudy.workItems ?? [];
            }
        }
    }
    return JSON.stringify(ret);
}

export const selectedStudyTitle = (state: RootState, studyId: string) => {
    let ret: study | undefined = undefined;
    const selectedStudyResult = apiSlice.endpoints.getStudyWithWorkitems.select(studyId);
    ret = selectedStudyResult(state)?.data?.study;
    if (!ret) {
        const selectStudiesResult = apiSlice.endpoints.searchStudies.select();
        const studies = selectStudiesResult(state)?.data?.content;
        if (studies) {
            const selectedStudy: study = studies.find((st: study) => st.id === studyId);
            if (selectedStudy) {
                ret = selectedStudy;
            }
        }
    }
    return ret?.details?.title ?? '';
}

/* export const selectWorkitemsList = (state: RootState, orderId: string) => {
    const workitemList: any[] = [];
    if (orderId) {
        const selectStudyResult = apiSlice.endpoints.getStudyWithWorkitems.select(orderId);
        const workitems = selectStudyResult(state)?.data?.workitems;
        if (workitems) {
            workitems?.forEach((workitem: workitem) => {
                workitemList.push(workitem);
            });
        }
    }
    return workitemList;
} */

export const selectCountImages = (state: RootState, orderId: string) => {
    const workitemList: any[] = [];
    if (orderId) {
        const selectStudyResult = apiSlice.endpoints.getStudyWithWorkitems.select(orderId);
        const workitems = selectStudyResult(state)?.data?.workitems;
        if (workitems) {
            workitems?.forEach((workitem: workitem) => {
                workitemList.push(workitem);
            });
        }
    }
    return workitemList?.filter(workitem => ((workitem?.data?.protocol?.steps && workitem?.data?.protocol?.steps.length > 0 && (workitem?.data?.state !== 'SCHEDULED')))).length;
}

export const selectCountPlannings = (state: RootState, orderId: string) => {
    const workitemList: any[] = [];
    if (orderId) {
        const selectStudyResult = apiSlice.endpoints.getStudyWithWorkitems.select(orderId);
        const workitems = selectStudyResult(state)?.data?.workitems;
        if (workitems) {
            workitems?.forEach((workitem: workitem) => {
                workitemList.push(workitem);
            });
        }
    }
    return workitemList?.filter(workitem => !(((workitem?.data?.protocol?.steps && workitem?.data?.protocol?.steps.length > 0 && (workitem?.data?.state !== 'SCHEDULED'))))).length;
}

export const selectCurrentinstanceIndexForSeries = (): number => {
    return 0;
}

export const selectRawSeries = (): number => {
    return 0;
}

export const selectCurrentinstanceIndexForRawSeries = (): number => {
    return 0;
}

export const selectSeriesDataForWorkitem = (state: RootState, workitemId: string) => {
    let seriesData: any = undefined;
    let seriesData_raw: any = undefined;
    const selectWorkitemResult = apiSlice.endpoints.getworkitem.select(workitemId);
    let workitem = selectWorkitemResult(state)?.data;
    if (workitem === undefined) {
        workitem = getWorkitemFromStudyWithWorkitems(state, state.Orders.currentOrderId, workitemId);
    }

    seriesData = getCurrentSeriesForProtocolStepSelector(state, workitem);
    seriesData_raw = getCurrentRawSeries(state, workitem);

    return JSON.stringify({ series: seriesData, rawSeries: seriesData_raw });
};

export const selectModalityFromSeries = (state: RootState, workitemId: string) => {
    let seriesData: any = undefined;
    const selectWorkitemResult = apiSlice.endpoints.getworkitem.select(workitemId);
    let workitem = selectWorkitemResult(state)?.data;
    if (workitem === undefined) {
        workitem = getWorkitemFromStudyWithWorkitems(state, state.Orders.currentOrderId, workitemId);
    }

    seriesData = getCurrentSeriesForProtocolStepSelector(state, workitem);

    let ret: string = '';
    if (seriesData) {
        if (seriesData?.details) {
            ret = seriesData?.details.modality ?? '';
        }
    }
    return ret;
};

export const selectPixelSpacingFromSeries = (state: RootState, workitemId: string) => {
    let seriesData: any = undefined;
    const selectWorkitemResult = apiSlice.endpoints.getworkitem.select(workitemId);
    let workitem = selectWorkitemResult(state)?.data;
    if (workitem === undefined) {
        workitem = getWorkitemFromStudyWithWorkitems(state, state.Orders.currentOrderId, workitemId);
    }

    seriesData = getCurrentRawSeries(state, workitem);
    let ret: string = '';
    if (seriesData) {
        if (seriesData?.details) {
            ret = seriesData?.details.pixelSpacing ?? '';
        }
    }
    return ret;
};


export const selectPixelSpacingFromInstance = (state: RootState, workitemId: string) => {
    const selectWorkitemResult = apiSlice.endpoints.getworkitem.select(workitemId);
    let workitem = selectWorkitemResult(state)?.data;
    let ret = '';
    if (workitem === undefined) {
        workitem = getWorkitemFromStudyWithWorkitems(state, state.Orders.currentOrderId, workitemId);
    }

    if (workitem) {
        const selectSeriesResult = getCurrentRawSeries(state, workitem);
        if (selectSeriesResult) {
            const rawInstances: string[] | undefined = selectSeriesResult?.instances;
            if (rawInstances && rawInstances.length > selectCurrentinstanceIndexForRawSeries()) {
                const selectInstanceResult = apiSlice.endpoints.getInstance.select(rawInstances[selectCurrentinstanceIndexForRawSeries()]);
                ret = selectInstanceResult(state)?.data?.details?.pixelSpacing ?? '';
            }
        }
    }
    return ret;
};


export const selectRawSeriesDataForWorkitem = (state: RootState, workitemId: string) => {
    let selectSeriesResult = undefined;
    const selectWorkitemResult = apiSlice.endpoints.getworkitem.select(workitemId);
    let workitem = selectWorkitemResult(state)?.data;
    if (workitem === undefined) {
        workitem = getWorkitemFromStudyWithWorkitems(state, state.Orders.currentOrderId, workitemId);
    }

    selectSeriesResult = getCurrentRawSeries(state, workitem);

    return selectSeriesResult ? JSON.stringify(selectSeriesResult) : '';
}

export const selectArtifactIdForSelectedWorkitem = (state: RootState) => {
    let artifactId: string | undefined = undefined;
    if (state.ImageDisplay.selectedWorkitem) {
        const selectWorkitemResult = apiSlice.endpoints.getworkitem.select(state.ImageDisplay.selectedWorkitem);
        let workitem = selectWorkitemResult(state)?.data;
        if (workitem === undefined) {
            workitem = getWorkitemFromStudyWithWorkitems(state, state.Orders.currentOrderId, state.ImageDisplay.selectedWorkitem);
        }

        if (workitem?.protocol?.steps) {
            const currentProtocolStep = getCurrentProtocolStep(state, workitem);

            const seriesId = currentProtocolStep?.performed?.output?.series;
            const selectSeriesResult = apiSlice.endpoints.getSeries.select(seriesId);
            if (selectSeriesResult(state)?.data && selectSeriesResult(state)?.data?.length > 0) {
                const seriesData = selectSeriesResult(state)?.data[selectSeriesResult(state)?.data?.length - 1];
                if (seriesData) {
                    const instances: string[] | undefined = seriesData?.instances;
                    if (instances && instances.length > 0) {
                        const selectInstanceResult = apiSlice.endpoints.getInstance.select(instances[selectCurrentinstanceIndexForSeries()]);
                        artifactId = selectInstanceResult(state)?.data?.details?.artifact_id;
                    }
                }
            }
        }
    }
    //console.log(artifactId);
    return artifactId;
};

export const selectArtifactIdForCorrelationId = (state: RootState, matrixIndex: number, isRaw?: boolean) => {
    let artifactId: string | undefined = undefined;
    if (state.ImageDisplay.selectedWorkitem) {
        const selectWorkitemResult = apiSlice.endpoints.getworkitem.select(state.ImageDisplay.selectedWorkitem);
        let workitem = selectWorkitemResult(state)?.data;
        if (workitem === undefined) {
            workitem = getWorkitemFromStudyWithWorkitems(state, state.Orders.currentOrderId, state.ImageDisplay.selectedWorkitem);
        }

        const correlationId = getProtocolCorrelationIdAtMatrixIndex(state, matrixIndex);


        //console.log(correlationId);
        if (correlationId && workitem?.protocol?.steps) {
            const isRejectedStep = workitem?.protocol?.steps?.find((s: any) => s.type === Constants.ACQUISITION_STEP_TYPE &&
                s.correlationId === correlationId && s.rejected !== undefined) !== undefined;
            //console.log(isRejectedStep);
            let currentProtocolStep = undefined;
            if (isRaw) {
                const postProcessedSteps = workitem?.protocol?.steps?.filter((step: any) => step?.type === Constants.ACQUISITION_STEP_TYPE &&
                    step?.performed !== undefined && step.correlationId === correlationId)
                if (postProcessedSteps && Array.isArray(postProcessedSteps) && postProcessedSteps.length > 0) {
                    currentProtocolStep = postProcessedSteps[postProcessedSteps.length - 1]
                }
            } else {
                if (workitem.state === "COMPLETED" && !isRejectedStep) {
                    const postProcessedSteps = workitem?.protocol?.steps?.filter((step: any) => step?.type === Constants.POSTPROCESSING_STEP_TYPE &&
                        step?.performed !== undefined && step.correlationId === correlationId)
                    if (postProcessedSteps && Array.isArray(postProcessedSteps) && postProcessedSteps.length > 0) {
                        currentProtocolStep = postProcessedSteps[postProcessedSteps.length - 1]
                    }
                } else {
                    const postProcessedSteps = workitem?.protocol?.steps?.filter((step: any) => step?.type === Constants.PROCESSING_STEP_TYPE &&
                        step?.performed !== undefined && step.correlationId === correlationId)
                    if (postProcessedSteps && Array.isArray(postProcessedSteps) && postProcessedSteps.length > 0) {
                        currentProtocolStep = postProcessedSteps[postProcessedSteps.length - 1]
                    }
                }
            }

            //console.log(currentProtocolStep);
            if (currentProtocolStep) {
                const seriesId = currentProtocolStep?.performed?.output?.series;
                const selectSeriesResult = apiSlice.endpoints.getSeries.select(seriesId);
                if (selectSeriesResult(state)?.data && selectSeriesResult(state)?.data?.length > 0) {
                    const seriesData = selectSeriesResult(state)?.data[selectSeriesResult(state)?.data?.length - 1];
                    //console.log(selectSeriesResult(state)?.data);
                    if (seriesData) {
                        const instances: string[] | undefined = seriesData?.instances;
                        if (instances && instances.length > 0) {
                            const selectInstanceResult = apiSlice.endpoints.getInstance.select(instances[selectCurrentinstanceIndexForSeries()]);
                            artifactId = selectInstanceResult(state)?.data?.details?.artifact_id;
                        }
                    }
                }
            }
        }
    }
    return artifactId;
};


export const getArtifactIdForSeries = (state: RootState, seriesId: string[]) => {
    let artifactId: string | undefined = undefined;
    const selectSeriesResult = apiSlice.endpoints.getSeries.select(seriesId);

    if (selectSeriesResult && selectSeriesResult(state)?.data) {
        if (selectSeriesResult(state)?.data && selectSeriesResult(state)?.data?.length > 0) {
            const seriesData = selectSeriesResult(state)?.data[selectSeriesResult(state)?.data?.length - 1];
            const instances: string[] | undefined = seriesData?.instances;
            if (instances && instances.length > 0) {
                const selectInstanceResult = apiSlice.endpoints.getInstance.select(instances[selectCurrentinstanceIndexForSeries()]);
                artifactId = selectInstanceResult(state)?.data?.details?.artifact_id;
            }
        }
    }
    return artifactId;
}

export const selectRawArtifactIdForSelectedWorkitem = (state: RootState) => {
    let artifactId_raw: string | undefined = undefined;
    if (state.ImageDisplay.selectedWorkitem) {
        const selectWorkitemResult = apiSlice.endpoints.getworkitem.select(state.ImageDisplay.selectedWorkitem);
        let workitem = selectWorkitemResult(state)?.data;
        if (workitem === undefined) {
            workitem = getWorkitemFromStudyWithWorkitems(state, state.Orders.currentOrderId, state.ImageDisplay.selectedWorkitem);
        }
        if (workitem?.protocol?.steps) {
            const seriesData: any = getCurrentRawSeries(state, workitem);
            if (seriesData) {
                const rawInstances: string[] | undefined = seriesData?.instances;
                if (rawInstances && rawInstances.length > 0) {
                    const selectInstanceResult = apiSlice.endpoints.getInstance.select(rawInstances[selectCurrentinstanceIndexForRawSeries()]);
                    artifactId_raw = selectInstanceResult(state)?.data?.details?.artifact_id;
                }
            }
        }
    }
    return artifactId_raw;
};

export const selectInstanceAndArtifactIdsForOrder = (state: RootState, orderId: string) => {
    const artifactIdList: { workitemId: string, workitemTitle: string, workitemState: string, seriesId: string, instanceId: string, instanceId_raw: string, artifactId: string, artifactId_raw: string }[] = [];

    if (orderId) {
        let workitems = undefined;
        const selectStudyResult = apiSlice.endpoints.getStudyWithWorkitems.select(orderId);
        workitems = selectStudyResult(state)?.data?.study?.workItems;
        if (!workitems) {
            const selectStudiesResult = apiSlice.endpoints.searchStudies.select();
            const studies = selectStudiesResult(state)?.data?.content;
            if (studies) {
                const selectedStudy: study = studies.find((st: study) => st.id === orderId);
                if (selectedStudy) {
                    workitems = selectedStudy.workItems;
                }
            }
        }
        if (workitems) {
            workitems?.forEach((workitem: any) => {
                if (workitem) {
                    const selectWorkitemResult = apiSlice.endpoints.getworkitem.select(workitem);
                    let fetchedWorkitem = selectWorkitemResult(state)?.data;
                    if (fetchedWorkitem === undefined) {
                        fetchedWorkitem = getWorkitemFromStudyWithWorkitems(state, state.Orders.currentOrderId, workitem);
                    }
                    if (fetchedWorkitem) {

                        if (fetchedWorkitem?.protocol?.steps) {

                            const selectProcessedSeriesResult: any = getCurrentSeriesForProtocolStepSelector(state, fetchedWorkitem);
                            const selectRawSeriesResult: any = getCurrentRawSeries(state, fetchedWorkitem)

                            if (selectProcessedSeriesResult) {
                                let artifactId: string = '';
                                let instanceId: string = '';
                                let artifactId_raw: string = '';
                                let rawInstancesId: string = '';
                                if (selectProcessedSeriesResult) {
                                    const instances: string[] | undefined = selectProcessedSeriesResult?.instances;
                                    if (instances && instances.length > 0) {
                                        instanceId = instances[selectCurrentinstanceIndexForSeries()];
                                        const selecInstanceResult = apiSlice.endpoints.getInstance.select(instanceId);
                                        artifactId = selecInstanceResult(state)?.data?.details?.artifact_id;
                                    }
                                } else {
                                    console.log("No processed series found!");
                                }
                                if (selectRawSeriesResult) {
                                    const instances: string[] | undefined = selectRawSeriesResult?.instances;
                                    if (instances && instances.length > 0) {
                                        rawInstancesId = instances[selectCurrentinstanceIndexForRawSeries()];
                                        const selectRawInstanceResult = apiSlice.endpoints.getInstance.select(rawInstancesId);
                                        artifactId_raw = selectRawInstanceResult(state)?.data?.details?.artifact_id;
                                    }
                                }
                                artifactIdList.push({
                                    workitemId: workitem, workitemTitle: fetchedWorkitem?.details?.title, workitemState: fetchedWorkitem?.state, seriesId: selectProcessedSeriesResult ? selectProcessedSeriesResult?.id : '', instanceId: instanceId,
                                    instanceId_raw: rawInstancesId, artifactId, artifactId_raw
                                })

                            }
                        }
                    }
                }
            });
        }
    }
    return JSON.stringify(artifactIdList);
};

export const selectExportInstanceAndArtifactIdsForOrder = (state: RootState, orderId: string) => {
    const artifactIdList: { workitemId: string, workitemTitle: string, workitemState: string, seriesId: string, instanceId: string, artifactId: string }[] = [];

    if (orderId) {
        let workitems = undefined;
        const selectStudyResult = apiSlice.endpoints.getStudyWithWorkitems.select(orderId);
        workitems = selectStudyResult(state)?.data?.study?.workItems;
        if (!workitems) {
            const selectStudiesResult = apiSlice.endpoints.searchStudies.select();
            const studies = selectStudiesResult(state)?.data?.content;
            if (studies) {
                const selectedStudy: study = studies.find((st: study) => st.id === orderId);
                if (selectedStudy) {
                    workitems = selectedStudy.workItems;
                }
            }
        }
        if (workitems) {
            workitems?.forEach((workitem: any) => {
                if (workitem) {
                    const selectWorkitemResult = apiSlice.endpoints.getworkitem.select(workitem);
                    let fetchedWorkitem = selectWorkitemResult(state)?.data;
                    if (fetchedWorkitem === undefined) {
                        fetchedWorkitem = getWorkitemFromStudyWithWorkitems(state, state.Orders.currentOrderId, workitem);
                    }
                    if (fetchedWorkitem?.protocol?.steps && fetchedWorkitem?.state !== 'SCHEDULED') {
                        const selectProcessedSeriesResult: any = getCurrentSeriesForProtocolStepSelector(state, fetchedWorkitem);
                        let artifactId: string = '';
                        let instanceId: string = '';
                        if (selectProcessedSeriesResult) {
                            const instances: string[] | undefined = selectProcessedSeriesResult?.instances;
                            if (instances && instances.length > 0) {
                                instanceId = instances[selectCurrentinstanceIndexForSeries()];
                                const selecInstanceResult = apiSlice.endpoints.getInstance.select(instanceId);
                                artifactId = selecInstanceResult(state)?.data?.details?.artifact_id;
                            }
                        } else {
                            console.log("No processed series found!");
                        }

                        artifactIdList.push({
                            workitemId: workitem, workitemTitle: fetchedWorkitem?.details?.title, workitemState: fetchedWorkitem?.state, seriesId: selectProcessedSeriesResult ? selectProcessedSeriesResult?.id : '', instanceId, artifactId
                        })
                    }
                }
            });
        }
    }
    return JSON.stringify(artifactIdList);
};
export const selectFinalizeInstanceAndArtifactIdsForOrder = (state: RootState, orderId: string) => {
    const artifactIdList: { workitemId: string, workitemTitle: string, artifactState: string, seriesId: string, instanceId: string, artifactId: string }[] = [];

    if (orderId) {
        let workitems = undefined;
        const selectStudyResult = apiSlice.endpoints.getStudyWithWorkitems.select(orderId);
        workitems = selectStudyResult(state)?.data?.study?.workItems;
        if (!workitems) {
            const selectStudiesResult = apiSlice.endpoints.searchStudies.select();
            const studies = selectStudiesResult(state)?.data?.content;
            if (studies) {
                const selectedStudy: study = studies.find((st: study) => st.id === orderId);
                if (selectedStudy) {
                    workitems = selectedStudy.workItems;
                }
            }
        }
        if (workitems) {
            workitems?.forEach((workitem: any) => {
                if (workitem) {
                    const selectWorkitemResult = apiSlice.endpoints.getworkitem.select(workitem);
                    let fetchedWorkitem = selectWorkitemResult(state)?.data;
                    if (fetchedWorkitem === undefined) {
                        fetchedWorkitem = getWorkitemFromStudyWithWorkitems(state, state.Orders.currentOrderId, workitem);
                    }
                    if (fetchedWorkitem?.protocol?.steps /*&& fetchedWorkitem?.state !== 'SCHEDULED'*/) {

                        for (const step of (fetchedWorkitem.protocol?.steps || [])) {
                            let done = false;
                            let seriesData = undefined;
                            if (step.type === Constants.ACQUISITION_STEP_TYPE && step.rejected === undefined && step.performed?.output?.series !== undefined) {
                                let correlatedSteps = fetchedWorkitem.protocol?.steps.filter((item: any) => item.correlationId === step.correlationId).reverse();
                                for (const cstep of correlatedSteps) {
                                    if (cstep.type === Constants.POSTPROCESSING_STEP_TYPE && cstep.rejected === undefined && cstep.performed?.output?.series !== undefined) {

                                        const selectSeriesResult = apiSlice.endpoints.getSeries.select(cstep.performed?.output?.series);

                                        if (selectSeriesResult && selectSeriesResult(state)?.data) {
                                            if (selectSeriesResult(state)?.data && selectSeriesResult(state)?.data?.length > 0) {
                                                seriesData = selectSeriesResult(state)?.data[selectSeriesResult(state)?.data?.length - 1];

                                                const instances: string[] | undefined = seriesData.instances;
                                                if (instances && instances.length > 0) {
                                                    let instanceId = instances[selectCurrentinstanceIndexForSeries()];
                                                    const instanceResult = apiSlice.endpoints.getInstance.select(instanceId);
                                                    let artifactId = instanceResult(state)?.data?.details?.artifact_id;

                                                    artifactIdList.push({
                                                        workitemId: workitem, workitemTitle: fetchedWorkitem?.details?.title, artifactState: Constants.POSTPROCESSING_STEP_TYPE, seriesId: seriesData.id, instanceId, artifactId
                                                    })
                                                    //console.log(artifactIdList[artifactIdList.length-1]);
                                                    done = true;
                                                }


                                            }
                                        }
                                    }
                                }

                                if (done)
                                    break;

                                for (const cstep of correlatedSteps) {
                                    if (cstep.type === Constants.PROCESSING_STEP_TYPE && cstep.rejected === undefined && cstep.performed?.output?.series !== undefined) {

                                        const selectSeriesResult = apiSlice.endpoints.getSeries.select(cstep.performed?.output?.series);

                                        if (selectSeriesResult && selectSeriesResult(state)?.data) {
                                            if (selectSeriesResult(state)?.data && selectSeriesResult(state)?.data?.length > 0) {
                                                seriesData = selectSeriesResult(state)?.data[selectSeriesResult(state)?.data?.length - 1];

                                                const instances: string[] | undefined = seriesData.instances;
                                                if (instances && instances.length > 0) {
                                                    let instanceId = instances[selectCurrentinstanceIndexForSeries()];
                                                    const instanceResult = apiSlice.endpoints.getInstance.select(instanceId);
                                                    let artifactId = instanceResult(state)?.data?.details?.artifact_id;

                                                    artifactIdList.push({
                                                        workitemId: workitem, workitemTitle: fetchedWorkitem?.details?.title, artifactState: Constants.PROCESSING_STEP_TYPE, seriesId: seriesData.id, instanceId, artifactId
                                                    })
                                                    //console.log(artifactIdList[artifactIdList.length-1]);
                                                    done = true;
                                                }
                                            }
                                        }
                                    }
                                    if (done)
                                        break;
                                }
                            }
                            if (done)
                                break;
                        }
                    }
                }

            });
        }
    }
    return JSON.stringify(artifactIdList);
};

export const selectArtifactIdsForOrder = (state: RootState, orderId: string) => {
    const artifactIdList: string[] = [];

    if (orderId) {
        let workitems = undefined;
        const selectStudyResult = apiSlice.endpoints.getStudyWithWorkitems.select(orderId);
        workitems = selectStudyResult(state)?.data?.study?.workItems;
        if (!workitems) {
            const selectStudiesResult = apiSlice.endpoints.searchStudies.select();
            const studies = selectStudiesResult(state)?.data?.content;
            if (studies) {
                const selectedStudy: study = studies.find((st: study) => st.id === orderId);
                if (selectedStudy) {
                    workitems = selectedStudy.workItems;
                }
            }
        }
        if (workitems) {
            workitems?.forEach((workitem: any) => {
                if (workitem) {
                    let artifactId: string | undefined = undefined;
                    const selectWorkitemResult = apiSlice.endpoints.getworkitem.select(workitem);
                    let fetchedWorkitem = selectWorkitemResult(state)?.data;
                    if (fetchedWorkitem === undefined) {
                        fetchedWorkitem = getWorkitemFromStudyWithWorkitems(state, state.Orders.currentOrderId, workitem);
                    }
                    if (fetchedWorkitem?.protocol?.steps) {
                        const seriesData: any = getCurrentSeriesForProtocolStepSelector(state, fetchedWorkitem);

                        if (seriesData) {
                            const instances: string[] | undefined = seriesData?.instances;
                            if (instances && instances.length > 0) {
                                const selectInstanceResult = apiSlice.endpoints.getInstance.select(instances[selectCurrentinstanceIndexForSeries()]);
                                artifactId = selectInstanceResult(state)?.data?.details?.artifact_id;
                            }
                        }
                    }
                    if (artifactId) {
                        artifactIdList.push(artifactId);
                    }
                }
            });
        }
    }
    return JSON.stringify(artifactIdList);
};


export const selectFirstPlanningWorkitemIdInOrder = (state: RootState, orderId: string) => {
    let planningWorkitemId = '';

    if (orderId) {
        let workitems = undefined;
        const selectStudyResult = apiSlice.endpoints.getStudyWithWorkitems.select(orderId);
        workitems = selectStudyResult(state)?.data?.study?.workItems;
        if (!workitems) {
            const selectStudiesResult = apiSlice.endpoints.searchStudies.select();
            const studies = selectStudiesResult(state)?.data?.content;
            if (studies) {
                const selectedStudy: study = studies.find((st: study) => st.id === orderId);
                if (selectedStudy) {
                    workitems = selectedStudy.workItems;
                }
            }
        }
        if (workitems) {
            for (const workitem of workitems) {
                if (workitem && workitem !== state.ImageDisplay.selectedWorkitem) {
                    const selectWorkitemResult = apiSlice.endpoints.getworkitem.select(workitem);
                    let fetchedWorkitem = selectWorkitemResult(state)?.data;
                    if (fetchedWorkitem === undefined) {
                        fetchedWorkitem = getWorkitemFromStudyWithWorkitems(state, state.Orders.currentOrderId, workitem);
                    }

                    if (fetchedWorkitem?.protocol?.steps) {
                        const rawStep = fetchedWorkitem.protocol?.steps?.filter((step: any) => step.type === Constants.ACQUISITION_STEP_TYPE &&
                            step?.scheduled !== undefined && step?.rejected === undefined && step?.performed === undefined);
                        if (rawStep && Array.isArray(rawStep) && rawStep.length > 0) {
                            planningWorkitemId = fetchedWorkitem?.id;
                            break;
                        }
                    }
                }
            };
        }
    }
    return planningWorkitemId;
};


export const selectInstanceIdForSelectedWorkitem = (state: RootState) => {
    let instanceId: string | undefined = undefined;
    if (state.ImageDisplay.selectedWorkitem) {
        const selectWorkitemResult = apiSlice.endpoints.getworkitem.select(state.ImageDisplay.selectedWorkitem);
        let workitem = selectWorkitemResult(state)?.data;
        if (workitem === undefined) {
            workitem = getWorkitemFromStudyWithWorkitems(state, state.Orders.currentOrderId, state.ImageDisplay.selectedWorkitem);
        }
        const seriesData: any = getCurrentSeriesForProtocolStepSelector(state, workitem);
        if (seriesData) {
            const instances: string[] | undefined = seriesData?.instances;
            if (instances && instances.length > 0) {
                instanceId = instances[instances.length - 1];
            }
        }
    }
    //console.log(instanceId);
    return instanceId;
};

export const selectInstanceRawIdForSelectedWorkitem = (state: RootState) => {
    let instanceId_raw: string | undefined = undefined;
    if (state.ImageDisplay.selectedWorkitem) {
        const selectWorkitemResult = apiSlice.endpoints.getworkitem.select(state.ImageDisplay.selectedWorkitem);
        let workitem = selectWorkitemResult(state)?.data;
        if (workitem === undefined) {
            workitem = getWorkitemFromStudyWithWorkitems(state, state.Orders.currentOrderId, state.ImageDisplay.selectedWorkitem);
        }
        const seriesData: any = getCurrentRawSeries(state, workitem)
        if (seriesData) {
            const rawInstances: string[] | undefined = seriesData?.instances;
            if (rawInstances && rawInstances.length > 0) {
                instanceId_raw = rawInstances[selectCurrentinstanceIndexForRawSeries()];
            }
        }
    }
    //console.log(instanceId_raw);
    return instanceId_raw;
};

export const selectInstanceForWorkitem = (state: RootState, workitemId: string) => {
    let instance: instance | undefined = undefined;
    const selectWorkitemResult = apiSlice.endpoints.getworkitem.select(workitemId);
    let workitem = selectWorkitemResult(state)?.data;
    if (workitem === undefined) {
        workitem = getWorkitemFromStudyWithWorkitems(state, state.Orders.currentOrderId, workitemId);
    }

    const seriesData: any = getCurrentSeriesForProtocolStepSelector(state, workitem);
    if (seriesData) {
        const instances: string[] | undefined = seriesData?.instances;
        if (instances && instances.length > 0) {
            const selectInstanceResult = apiSlice.endpoints.getInstance.select(instances[selectCurrentinstanceIndexForSeries()]);
            instance = selectInstanceResult(state)?.data;
        }
    }
    return JSON.stringify(instance);
};

export const selectRawInstanceForWorkitem = (state: RootState, workitemId: string) => {
    let instance: instance | undefined = undefined;
    const selectWorkitemResult = apiSlice.endpoints.getworkitem.select(workitemId);
    let workitem = selectWorkitemResult(state)?.data;
    if (workitem === undefined) {
        workitem = getWorkitemFromStudyWithWorkitems(state, state.Orders.currentOrderId, workitemId);
    }

    const seriesData: any = getCurrentRawSeries(state, workitem)
    if (seriesData) {
        const rawInstances: string[] | undefined = seriesData?.instances;
        if (rawInstances && rawInstances.length > 0) {
            const selectInstanceResult = apiSlice.endpoints.getInstance.select(rawInstances[selectCurrentinstanceIndexForRawSeries()]);
            instance = selectInstanceResult(state)?.data;
        }
    }
    return JSON.stringify(instance);
};

export const selectArtifactForSelectedWorkitem = (state: RootState) => {
    let artifact: any | undefined = undefined;

    if (state.ImageDisplay.selectedWorkitem) {
        const selectWorkitemResult = apiSlice.endpoints.getworkitem.select(state.ImageDisplay.selectedWorkitem);
        let workitem = selectWorkitemResult(state)?.data;
        if (workitem === undefined) {
            workitem = getWorkitemFromStudyWithWorkitems(state, state.Orders.currentOrderId, state.ImageDisplay.selectedWorkitem);
        }

        const seriesData: any = getCurrentSeriesForProtocolStepSelector(state, workitem);

        if (seriesData) {
            const instances: string[] | undefined = seriesData?.instances;
            if (instances && instances.length > 0) {
                const selectInstanceResult = apiSlice.endpoints.getInstance.select(instances[selectCurrentinstanceIndexForSeries()]);

                const artifactId: string = selectInstanceResult(state)?.data?.details?.artifact_id;

                if (artifactId) {
                    const selectArtifactResult = apiSlice.endpoints.getArtifact.select(artifactId);
                    artifact = selectArtifactResult(state)?.data;
                }
            }
        }
    }
    return JSON.stringify(artifact);
};

export const isSelectedWorkitemPlanning = (state: RootState) => {
    let isPlanning: boolean = false;
    if (state.ImageDisplay.selectedWorkitem) {
        const selectWorkitemResult = apiSlice.endpoints.getworkitem.select(state.ImageDisplay.selectedWorkitem);
        let workitem = selectWorkitemResult(state)?.data;
        if (workitem === undefined) {
            workitem = getWorkitemFromStudyWithWorkitems(state, state.Orders.currentOrderId, state.ImageDisplay.selectedWorkitem);
        }

        if (workitem?.protocol?.steps) {
            const foundSteps = workitem.protocol?.steps?.filter((step: any) => step.type === Constants.ACQUISITION_STEP_TYPE
                && step?.rejected === undefined && step?.performed === undefined && step.scheduled !== undefined);
            isPlanning = (foundSteps !== undefined && foundSteps.length > 0);
        }
    }
    return isPlanning;
};

export const workitemHasImage = (state: RootState) => {
    let hasImage: boolean = false;
    if (state.ImageDisplay.selectedWorkitem) {
        const selectWorkitemResult = apiSlice.endpoints.getworkitem.select(state.ImageDisplay.selectedWorkitem);
        let workitem = selectWorkitemResult(state)?.data;
        if (workitem === undefined) {
            workitem = getWorkitemFromStudyWithWorkitems(state, state.Orders.currentOrderId, state.ImageDisplay.selectedWorkitem);
        }

        if (workitem?.protocol?.steps) {
            const foundSteps = workitem.protocol?.steps?.filter((step: any) => step?.performed?.output?.series !== undefined);
            hasImage = (foundSteps !== undefined && foundSteps.length > 0);
        }
    }
    return hasImage;
};

export const getSelectedWorkitem = (state: RootState) => {
    let workitem: any = undefined;
    if (state.ImageDisplay.selectedWorkitem) {
        const selectWorkitemResult = apiSlice.endpoints.getworkitem.select(state.ImageDisplay.selectedWorkitem);
        workitem = selectWorkitemResult(state)?.data;
        if (workitem === undefined) {
            workitem = getWorkitemFromStudyWithWorkitems(state, state.Orders.currentOrderId, state.ImageDisplay.selectedWorkitem);
        }
    }
    return JSON.stringify(workitem);
};

export const getWorkitemFromStudyWithWorkitems = (state: RootState, studyId: string, workitemId: string) => {
    let workitem: any = undefined;
    const selectedStudyResult = apiSlice.endpoints.getStudyWithWorkitems.select(studyId);
    const workitems = selectedStudyResult(state)?.data?.workitems;
    if (workitems) {
        workitem = workitems.find((item: any) => item?.data?.id === workitemId)?.data;
    }
    return workitem;
}

export const haveOneImageToComplete = (state: RootState, studyId: string) => {
    let ret: boolean = false;
    const selectedStudyResult = apiSlice.endpoints.getStudyWithWorkitems.select(studyId);

    if (selectedStudyResult(state)?.data?.study?.state !== "OPENED")
        return false;

    const workitems = selectedStudyResult(state)?.data?.workitems;
    if (workitems) {
        for (const workitem of workitems) {
            for (const step of (workitem.data?.protocol?.steps || [])) {
                if (step.type === Constants.ACQUISITION_STEP_TYPE && step.rejected === undefined && step.performed?.output?.series !== undefined) {
                    let correlatedSteps = workitem.data?.protocol?.steps.filter((item: any) => item.correlationId === step.correlationId)
                    if (correlatedSteps.find((item: any) => item.type === Constants.PROCESSING_STEP_TYPE && item.performed?.output?.series !== undefined))
                        return true;
                }
            }
        }
    }
    return ret;
}

export const haveOnlyCompleteWorkitems = (state: RootState, studyId: string) => {
    let ret: boolean = true;
    const selectedStudyResult = apiSlice.endpoints.getStudyWithWorkitems.select(studyId);

    if (selectedStudyResult(state)?.data?.study?.state !== "OPENED")
        return false;

    const workitems = selectedStudyResult(state)?.data?.workitems;
    if (workitems) {
        for (const workitem of workitems) {
            if (workitem.data?.state !== "COMPLETED")
                return false;
        }
    }
    return ret;
}

export const haveOpenPlannings = (state: RootState, studyId: string) => {
    let ret: boolean = false;
    const selectedStudyResult = apiSlice.endpoints.getStudyWithWorkitems.select(studyId);
    const workitems = selectedStudyResult(state)?.data?.workitems;
    if (workitems) {
        for (const workitem of workitems) {
            let haveOutputSeries: boolean = false;
            for (const step of (workitem.data?.protocol?.steps || [])) {
                if (step.type === Constants.ACQUISITION_STEP_TYPE && step.rejected === undefined && step.performed?.output?.series !== undefined) {
                    haveOutputSeries = true;
                    break;
                }
            }
            if (!haveOutputSeries) {
                for (const step of (workitem.data?.protocol?.steps || [])) {
                    if (step.type === Constants.ACQUISITION_STEP_TYPE && step.rejected === undefined && step.performed?.output?.series === undefined) {
                        return true;
                    }
                }
            }
        }
    }
    return ret;
}

export const getOpenWorkitemIds = (state: RootState, studyId: string) => {
    let ret: any = [];
    const selectedStudyResult = apiSlice.endpoints.getStudyWithWorkitems.select(studyId);
    const workitems = selectedStudyResult(state)?.data?.workitems;
    if (workitems) {
        for (const workitem of workitems) {
            let haveOutputSeries: boolean = false;
            for (const step of (workitem.data?.protocol?.steps || [])) {
                if (step.type === Constants.ACQUISITION_STEP_TYPE && step.rejected === undefined && step.performed?.output?.series !== undefined) {
                    haveOutputSeries = true;
                    break;
                }
            }
            if (!haveOutputSeries) {
                for (const step of (workitem.data?.protocol?.steps || [])) {
                    if (step.type === Constants.ACQUISITION_STEP_TYPE && step.rejected === undefined && step.performed?.output?.series === undefined) {
                        if (!ret.includes(workitem.data?.id))
                            ret.push(workitem.data?.id)
                    }
                }
            }
        }
    }
    return ret;
}

export const getCompletableWorkitemIds = (state: RootState, studyId: string) => {
    let ret: any = [];
    const selectedStudyResult = apiSlice.endpoints.getStudyWithWorkitems.select(studyId);
    const workitems = selectedStudyResult(state)?.data?.workitems;
    if (workitems) {
        for (const workitem of workitems) {
            if (workitem.data?.state === "IN_PROGRESS" || workitem.data?.state === "SCHEDULED") {
                for (const step of (workitem.data?.protocol?.steps || [])) {
                    if (step.type === Constants.ACQUISITION_STEP_TYPE && step.rejected === undefined && step.performed?.output?.series !== undefined) {
                        let correlatedSteps = workitem.data?.protocol?.steps.filter((item: any) => item.correlationId === step.correlationId)
                        for (const cstep of correlatedSteps) {
                            if (cstep.type === Constants.PROCESSING_STEP_TYPE && cstep.rejected === undefined && cstep.performed?.output?.series !== undefined) {
                                if (!ret.includes(workitem.data?.id))
                                    ret.push(workitem.data?.id);
                                break;
                            }
                        }
                    }
                }
            }
        }
    }
    return JSON.stringify(ret);
}

export const getSelectedWorkitemMimeType = (state: RootState) => {
    let document_mime_typ: string = '';
    if (state.ImageDisplay.selectedWorkitem) {
        const selectWorkitemResult = apiSlice.endpoints.getworkitem.select(state.ImageDisplay.selectedWorkitem);

        let workitem = selectWorkitemResult(state)?.data;
        if (workitem === undefined) {
            workitem = getWorkitemFromStudyWithWorkitems(state, state.Orders.currentOrderId, state.ImageDisplay.selectedWorkitem);
        }

        if (workitem?.details?.title) {
            document_mime_typ = workitem.details?.document_mime_typ;
        }
    }

    return document_mime_typ;
};

export const getSelectedWorkitemTitle = (state: RootState) => {
    let workitemTitle: string = '';
    if (state.ImageDisplay.selectedWorkitem) {
        const selectWorkitemResult = apiSlice.endpoints.getworkitem.select(state.ImageDisplay.selectedWorkitem);
        let workitem = selectWorkitemResult(state)?.data;
        if (workitem === undefined) {
            workitem = getWorkitemFromStudyWithWorkitems(state, state.Orders.currentOrderId, state.ImageDisplay.selectedWorkitem);
        }
        if (workitem?.details?.title) {
            workitemTitle = workitem.details?.title;
        }
    }
    return workitemTitle;
};

export const getSelectedWorkitemState = (state: RootState) => {
    let workitemState: string = '';
    if (state.ImageDisplay.selectedWorkitem) {
        const selectWorkitemResult = apiSlice.endpoints.getworkitem.select(state.ImageDisplay.selectedWorkitem);
        let workitem = selectWorkitemResult(state)?.data;
        if (workitem === undefined) {
            workitem = getWorkitemFromStudyWithWorkitems(state, state.Orders.currentOrderId, state.ImageDisplay.selectedWorkitem);
        }
        if (workitem?.state) {
            workitemState = workitem.state;
        }
    }
    return workitemState;
};

export const getWorkitemForId = (state: RootState, workitemId: string) => {
    let workitem: any = undefined;
    if (workitemId) {
        const selectWorkitemResult = apiSlice.endpoints.getworkitem.select(workitemId);
        workitem = selectWorkitemResult(state)?.data;
        if (workitem === undefined) {
            workitem = getWorkitemFromStudyWithWorkitems(state, state.Orders.currentOrderId, workitemId);
        }
    }

    return workitem;
};

export const selectGetWorkitemList = (state: RootState, orderId: string) => {
    let workitems: any = undefined;
    const selectedStudyResult = apiSlice.endpoints.getStudyWithWorkitems.select(orderId);
    let ret = selectedStudyResult(state)?.data?.study?.workItems;
    if (ret) {
        const selectStudiesResult = apiSlice.endpoints.searchStudies.select();
        const studies = selectStudiesResult(state)?.data?.content;
        if (studies) {
            const selectedStudy: study = studies.find((st: study) => st.id === orderId);
            if (selectedStudy) {
                ret = selectedStudy.workItems ?? [];
                const selectWorkitemListResult = apiSlice.endpoints.getWorkitemList.select(selectedStudy.workItems);
                workitems = selectWorkitemListResult(state)?.data;
            }
        }
    }
    return workitems;
};

export const getCatalogEntryForSelectedWorkitem = (state: RootState, workitem: any) => {
    let catalogEntry: CatalogueEntry = state.Catalogue.CatalogueEntries[0];
    if (workitem) {
        const catalogId: string | undefined = workitem?.details?.catalogueId;
        if (catalogId) {
            const entry: CatalogueEntry = state.Catalogue.CatalogueEntries.find((entry: CatalogueEntry) => entry.catalogueId === catalogId);
            if (entry) {
                catalogEntry = entry;
            }
        }
    }
    return catalogEntry;
};

export const getTargetExposureIndexForSelectedWorkitem = (state: RootState) => {
    let targetExposureIndex: number | undefined = undefined;
    if (state.ImageDisplay.selectedWorkitem) {
        const selectWorkitemResult = apiSlice.endpoints.getworkitem.select(state.ImageDisplay.selectedWorkitem);
        let workitem = selectWorkitemResult(state)?.data;
        if (workitem === undefined) {
            workitem = getWorkitemFromStudyWithWorkitems(state, state.Orders.currentOrderId, state.ImageDisplay.selectedWorkitem);
        }
        if (workitem?.details?.targetExposureIndex) {
            targetExposureIndex = workitem?.details?.targetExposureIndex;
        }
    }
    return targetExposureIndex;
};

export const getLateralityForSelectedWorkitem = (state: RootState) => {
    let lat: string | undefined = undefined;
    if (state.ImageDisplay.selectedWorkitem) {
        const selectWorkitemResult = apiSlice.endpoints.getworkitem.select(state.ImageDisplay.selectedWorkitem);
        let workitem = selectWorkitemResult(state)?.data;
        if (workitem === undefined) {
            workitem = getWorkitemFromStudyWithWorkitems(state, state.Orders.currentOrderId, state.ImageDisplay.selectedWorkitem);
        }
        if (workitem?.details?.laterality) {
            lat = workitem?.details?.laterality;
        }
    }
    return lat;
};
export const getLateralityForWorkitem = (state: RootState, workitemId: string) => {
    let lat: string | undefined = undefined;
    let workitem: any = undefined;
    if (workitemId) {
        const selectWorkitemResult = apiSlice.endpoints.getworkitem.select(workitemId);
        workitem = selectWorkitemResult(state)?.data;
        if (workitem === undefined) {
            workitem = getWorkitemFromStudyWithWorkitems(state, state.Orders.currentOrderId, workitemId);
        }
        if (workitem !== undefined) {
            if (workitem?.details?.laterality) {
                lat = workitem?.details?.laterality;
            }
        }
    }
    return lat;
};
export const selectArtifact = (state: RootState, artifactId: string) => {
    if (artifactId) {
        const selectArtifactResult = apiSlice.endpoints.getArtifact.select(artifactId);
        return selectArtifactResult(state)?.data ?? undefined;
    } else {
        return undefined;
    }
};

export const selectArtifactMimeType = (state: RootState, artifactId: string) => {
    if (artifactId) {
        const selectArtifactMimeTypeResult = apiSlice.endpoints.getArtifactMimeType.select(artifactId);
        return selectArtifactMimeTypeResult(state)?.data ?? undefined;
    } else {
        return undefined;
    }
};

export const selectedAnnotations = (state: RootState, targetId: string | undefined) => {
    let ret: any = undefined;
    if (targetId) {
        const selectedAnnotationsResult = apiSlice.endpoints.getAnnotation.select(targetId);
        ret = selectedAnnotationsResult(state)?.data?.filter((annotation: any) => annotation?.revoked === false);
    }
    return ret;
}

export const selectedAnnotationPixelToLengthFactor = (state: RootState, targetId: string | undefined) => {
    let ret: number | undefined = undefined;
    if (targetId) {
        const selectedAnnotationsResult = apiSlice.endpoints.getAnnotation.select(targetId);
        const annosWithCalibrationFactor = selectedAnnotationsResult(state)?.data?.filter((annotation: any) => annotation?.revoked === false &&
            annotation?.body?.type === 'LenghtCalibrationResult' && annotation?.body?.value?.pixelToLengthFactor !== undefined
        );
        if (annosWithCalibrationFactor && Array.isArray(annosWithCalibrationFactor) && annosWithCalibrationFactor.length > 0) {
            ret = annosWithCalibrationFactor[annosWithCalibrationFactor.length - 1]?.body?.value?.pixelToLengthFactor;
        }
    }
    return ret;
}

export const getToolList = (state: RootState) => {
    let tools: ToolsListEntry[] = [];
    let currentThemesDir = './';
    if (getThemes(state)?.length > 0) {
        currentThemesDir = `Themes/${state.MainOrderListColumns.theme}`;
    }
    const selectToolListResult = apiSlice.endpoints.config.select({ file: 'ToolsConfig.json', path: currentThemesDir });
    tools = selectToolListResult(state)?.data;
    return tools;
};

export const getThemes = (state: RootState) => {
    let themes: string[] = [];
    const selectThemesResult = apiSlice.endpoints.config.select({ file: 'Themes.json' });
    themes = selectThemesResult(state)?.data;
    return themes;
};

export const getLayout = (state: RootState): any => {
    let layout = {};
    let currentThemesDir = './';
    if (getThemes(state)?.length > 0) {
        currentThemesDir = `Themes/${state.MainOrderListColumns.theme}`;
    }
    const selectLayoutResult = apiSlice.endpoints.config.select({ file: 'Layout.json', path: currentThemesDir });
    layout = selectLayoutResult(state)?.data;
    return layout;
};

export const getOrderDescBarConfig = (state: RootState): any => {
    let orderDescBarConfig = {};
    const configFile = state?.MainOrderListColumns?.allowComplete ? "/OrderDescBarConfig.json" : "/OrderDescBarConfig_noOrderState.json";
    let currentThemesDir = './';
    if (getThemes(state)?.length > 0) {
        currentThemesDir = `Themes/${state.MainOrderListColumns.theme}`;
    }
    const selectLayoutResult = apiSlice.endpoints.config.select({ file: configFile, path: currentThemesDir });
    orderDescBarConfig = selectLayoutResult(state)?.data;
    return orderDescBarConfig;
};

export const getWorkflow = (state: RootState) => {
    let workflow = {};
    let currentThemesDir = './';
    if (getThemes(state)?.length > 0) {
        currentThemesDir = `Themes/${state.MainOrderListColumns.theme}`;
    }
    const selectworkflowResult = apiSlice.endpoints.config.select({ file: 'WorkflowConfig.json', path: currentThemesDir });
    workflow = selectworkflowResult(state)?.data;
    return workflow;
};

export const getImagePathForCurrentTheme = (state: RootState) => {
    let path = '/images/';
    if (getThemes(state)?.length > 0) {
        path = `/Themes/${state.MainOrderListColumns.theme}/images/`;
    }
    return path;
};
