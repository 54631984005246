import { useEffect } from 'react';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { apiSlice, device, deviceSet, property, useGetDeviceWebThingQuery } from '../../apis/apiSlice';
import { AppDispatch, RootState } from '../../store';
import { selectDeviceProperties, selectDeviceRunnerActive, selectDeviceRunnerError } from './AcquisitionSlice';
import DeviceChangeableProp from './DeviceChangeableProp';
import DeviceReadonlyProp from './DeviceReadonlyProp';
import { useORTranslation } from '../Localization/ORLocalization';

import { getLayout } from '../OrderList/OrdersSlice';

interface DeviceWebThingProps {
	device: device | deviceSet;
	colorCode: number;
};

const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
const useAppDispatch = () => useDispatch<AppDispatch>();

const DeviceWebThing = (props: DeviceWebThingProps) => {
	const dispatch = useAppDispatch();
	const { t } = useORTranslation(['Acquisition']);
	const propertyDefs: [string, any][] = JSON.parse(useAppSelector((state) => selectDeviceProperties(state, props.device.config.endpoint)));
	const isDeviceRunnerActive: boolean = useAppSelector((state) => selectDeviceRunnerActive(state));
	const deviceRunnerError: string = useAppSelector((state) => selectDeviceRunnerError(state));
	const layout: any= useAppSelector((state) => getLayout(state));

	useEffect(() => {
		if (!deviceRunnerError) {
			dispatch(apiSlice.util.invalidateTags([{ type: 'DeviceWebThing', id: props.device.config.endpoint }]));
		}
	}, [dispatch, deviceRunnerError]);

	const {
		data,
		isSuccess,
	}: { data: any, isSuccess: boolean } = useGetDeviceWebThingQuery({ endpoint: props.device.config.endpoint, category: props.device?.category, id: props.device?.id }, {
		refetchOnMountOrArgChange: true,
		skip: isDeviceRunnerActive === false || deviceRunnerError !== '',
	});

	function getProperty(property: [string, property], i: number) {
		if (property[1].readOnly === true) {
			return <DeviceReadonlyProp device={props.device} property={property} key={i.toString()} isfirstDefaultWidget={firstDefaultWidget(property) === i} colorCode={props.colorCode} />
		} else {
			return <DeviceChangeableProp device={props.device} property={property} endpoint={props.device.config.endpoint} key={i.toString()} isfirstDefaultWidget={firstDefaultWidget(property) === i} colorCode={props.colorCode} />
		}
	}

	function isSpecialWidget(property: [string, property]): boolean {
		return ["battery", "connection", "status", "manufacturerTool", "calibrationGui", "pulseTime", "softwareTriggerType", "softwareTriggerState"].includes(property[0]);
	}

	function firstDefaultWidget(property: [string, property]): number {
		let ret: number = 0;
		if (propertyDefs) {
			for (let i = 0; i < propertyDefs.length; i++) {
				if (!isSpecialWidget(propertyDefs[i])) {
					ret = i;
					break;
				}
			}
		}
		return ret;
	}

	function compareFn(item1: [string, property], item2: [string, property]) {
		if (props.device.category === 'SET' && layout?.deviceSet_panel_display_footer === false) {
			if (item1[0] === 'softwareTriggerState') {
				return 1;
			}
		}
		if (isSpecialWidget(item1) && isSpecialWidget(item2)) {
			if (["manufacturerTool", "calibrationGui"].includes(item1[0]) && ["manufacturerTool", "calibrationGui"].includes(item2[0])) {
				return 0;
			}
			if (["manufacturerTool", "calibrationGui"].includes(item1[0])) {
				return 1;
			}
			if (["manufacturerTool", "calibrationGui"].includes(item2[0])) {
				return -1;
			}
			return -1;
		}
		if (isSpecialWidget(item1)) {
			return -1;
		}
		if (isSpecialWidget(item2)) {
			return 1;
		}
		if (item1[1]?.readOnly) {
			return 1;
		}
		if (item2[1]?.readOnly) {
			return -1;
		}
		return 0;
	}

	return (
		<>
			{isSuccess && data ?
				<>
					<div className="generatorSettings" >
						<div className="generatorSettingsInset" onClick={(evt) => evt.stopPropagation()}>
							{props.device.category !== 'SET' ?
								<>
									<h1>{`${t(props.device.category)}: ${props.device.title}`}</h1>
									<hr className="hrule1" />
								</> : null}
							<div className="StatusDiv">
								{propertyDefs?.sort(compareFn).filter((property: [string, property]) => property[0] !== 'softwareTriggerTimeout')
									.map((property: [string, property], i: number) => (
										getProperty(property, i)
									))}
							</div>
						</div>

					</div>

				</>
				:
				<div className="deviceSetDisconnected" >
					{deviceRunnerError !== '' ?
						<svg version="1.1" viewBox="0 0 893.21 773.54">
							<polygon className="innerHexagon" transform="translate(83.603 72.769)"
								points="723 314 543 625.77 183 625.77 3 314 183 2.2309 543 2.2309"
								fill="none" stroke="#b2c200" strokeWidth="150" />
							<g className="exposeHexagonInner" transform="matrix(6.3585 0 0 6.3585 -2709.2 -1620)" fill="#b1c100" fillRule="evenodd">
								<path className="a" d="m495.95 321.95a6.9 6.9 0 1 0-6.917-6.888 6.92 6.92 0 0 0 6.917 6.888z" />
								<path className="a" d="m491.19 323.54a10.209 10.209 0 0 0 9.326-0.028l10.715 19.9c-11.622 5.159-18.964 5.018-30.558 0z" />
								<path className="a" d="m490.79 307.01a10.2 10.2 0 0 0-4.621 8.107l-22.592-0.68c1.332-12.642 5.131-18.935 15.251-26.447z" />
								<path className="a" d="m501.16 307.01a10.274 10.274 0 0 1 4.62 8.107l22.592-0.68c-1.332-12.642-5.131-18.935-15.279-26.447z" />
							</g>
							<foreignObject className="text" width="100%" height="100%" transform="translate(30,175)">
								<p>Disconnected</p>
							</foreignObject>
						</svg>
						: null}
				</div>
			}
		</>
	);
}

export default DeviceWebThing;
