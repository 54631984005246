import { Middleware } from "redux";
import * as actions from "./mqttActions";
import type { RootState } from "../../store";
import * as mqtt from "precompiled-mqtt"
import { Constants } from "../../Constants";
import { messageSeverity } from "../../components/common/MessageSlice";
import { addImageMode, getWorkitemFromStudyWithWorkitems, loadImage, selectRawArtifactIdForSelectedWorkitem, selectedStudy } from "../../components/OrderList/OrdersSlice";
import { apiSlice } from "../apiSlice";
import { getCurrentProtocolStepNumber, getMatrixIndexForImageId, getMatrixIndexForInsert, getProtocolCorrelationIdAtMatrixIndex } from "../../components/ImageDisplay/ImageDisplaySlice";
import { serviceEndpoints } from "../..";
import { v4 as uuidv4 } from 'uuid';
import { current } from "@reduxjs/toolkit";

//let client: Paho.Client | null = null;

let client: mqtt.MqttClient | undefined = undefined;

/* function Sleep(milliseconds: number) {
  return new Promise(resolve => setTimeout(resolve, milliseconds));
} */


let currentProtocolStepCorrelationId: string = '';

function build_connection(storeApi: any) {

  const options = {
    username: 'mqtt-test',
    password: 'mqtt-test',
    clientId: "myclientid_" + Math.random() * 100,
    reconnectPeriod: 200,
  }
  const wsbroker = serviceEndpoints.MQTT_HOST;  //mqtt websocket enabled broker
  const wsport = (window.location.protocol === 'https:') ? serviceEndpoints.MQTT_PORT_TLS : serviceEndpoints.MQTT_PORT; // port for above
  const wsProtocol = (window.location.protocol === 'https:') ? "mqtts:" : "mqtt:"

  if (client === undefined || client.disconnected) {
    console.log(`CONNECT TO ${wsbroker}:${wsport}`);
    client = mqtt.connect(`${wsProtocol}//${wsbroker}:${wsport}/ws`, options);
  }

  client.on('connect', function () {
    console.log("client connected");
    storeApi.dispatch(actions.mqttConnected());
    /* client?.subscribe(Constants.MQTT_PROCESSING_QUEU, { qos: 1 });
    client?.subscribe('de/or/events/vena/instance/#', { qos: 1 }); */
    client?.subscribe({ 'de/or/events/vena/workitem/#': { qos: 1 }, 'de/or/events/vena/instance/#': { qos: 1 }, 'de/or/events/vena/series/#': { qos: 1 }, 'de/or/events/vena/study/#': { qos: 1 } }, function (err) {
      if (err) {
        storeApi.dispatch({ type: "Message/setMessage", payload: { timestamp: new Date().toISOString(), severity: messageSeverity.error, text: "CONNECTION FAILURE - " + JSON.stringify(err) } });
      }
    })
  })

  client.on('message', function (topic, message) {

    let isSynchronousBackend: boolean = Constants.SYNCHRONOUS_BACKEND;

    // handle resume
    if (message && topic.startsWith('de/or/events/vena/workitem/')) {
      console.log("Receive message on: " + topic + " " + message.toString());
      const messageObject: any = JSON.parse(message.toString());
      const eventType: string = messageObject?.eventEnvelope?.eventType;
      if (eventType === 'WorkItemResumed') {
        console.log("handle resume message");
        if (isSynchronousBackend) {
          const workitemId: string = messageObject?.eventEnvelope?.aggregateId;
          const studyId: string = storeApi.getState().Orders.currentOrderId;
          storeApi.dispatch(apiSlice.util.invalidateTags([{ type: 'Workitem', id: workitemId }, { type: 'Study', id: studyId }]));

          let currentProtocolSettingsStepNumber = -1;
          const selectWorkitemResult = apiSlice.endpoints.getworkitem.select(storeApi.getState().ImageDisplay.selectedWorkitem);
          let workitem = selectWorkitemResult(storeApi.getState())?.data;
          if (workitem === undefined) {
            workitem = getWorkitemFromStudyWithWorkitems(storeApi.getState(), storeApi.getState().Orders.currentOrderId, storeApi.getState().ImageDisplay.selectedWorkitem);
          }
          if (workitem !== undefined) {
            currentProtocolSettingsStepNumber = getCurrentProtocolStepNumber(storeApi.getState(), Constants.POSTPROCESSING_STEP_TYPE, undefined, undefined, workitem?.data) - 1;
          }
          //currentProtocolSettingsStepNumber = 0;
          storeApi.dispatch({ type: 'ImageDisplay/setProtocolStepSelectionProps', payload: { workitemId: workitemId, stepType: Constants.POSTPROCESSING_STEP_TYPE, requiredAttribute: 'performed', excludedAttribute: 'rejected', index: -1, seriesIndex: currentProtocolSettingsStepNumber, correlationId: '' } });

          if (studyId === undefined) {
            const rawArtifactId = selectRawArtifactIdForSelectedWorkitem(storeApi.getState());
            let artifactId: string = ""; // <= ???
            const currentMatrixIndexForWorkitem = getMatrixIndexForImageId(storeApi.getState(), workitemId);
            const selectedWorkitem: string = storeApi.getState()?.ImageDisplay.selectedWorkitem;
            storeApi.dispatch(
              loadImage({
                imageName: `${serviceEndpoints.ARTIFACT_STORE_URL}/artifacts/${artifactId}/raw`,
                imageId: selectedWorkitem,
                artifactId: artifactId,
                artifactId_Raw: rawArtifactId,
                createPreviewImage: addImageMode.none,
                matrixIndex: currentMatrixIndexForWorkitem,
                display_raw: false,
                enabledElementForExport: undefined,
              })
            );
          }
        }
      }
    }

    // get modality from series event
    if (message && topic.startsWith('de/or/events/vena/series/')) {
      const selectedWorkitem: string = storeApi.getState()?.ImageDisplay.selectedWorkitem;
      const messageObject: any = JSON.parse(message.toString());
      const eventType: string = messageObject?.eventEnvelope?.eventType;
      //console.log(eventType);
      if (eventType === 'SeriesDetailsUpdated') {
        /* console.log(messageObject);
        console.log("Receive message on: " + topic + " " + message.toString());
        console.log(messageObject?.eventEnvelope?.aggregateId); */
        storeApi.dispatch(
          apiSlice.util.updateQueryData('getSeries', [messageObject?.eventEnvelope?.aggregateId], (data: any) => {
            const currentSeries = data?.find((item: any) => item.id === messageObject?.eventEnvelope?.aggregateId);
            //console.log(current(currentSeries));
            if (currentSeries.details) {
              currentSeries.details = {...currentSeries.details, ...messageObject?.eventEnvelope?.event?.details};
            }
            return data;
          })
        )
      }
      if (eventType === 'SeriesExpected') {
        console.log("Receive message on: " + topic + " " + message.toString());
        const workitemId: string = messageObject?.eventEnvelope?.event?.details?.ref_workitem;
        const presentation_intent: string = messageObject?.eventEnvelope?.event?.details?.presentation_intent;

        if (selectedWorkitem === workitemId) {
          //storeApi.dispatch({ type: "Procedure/setProcedureSelectionVisibilityState", payload: 0 });
          const currentOrderId: string = storeApi.getState()?.Orders.currentOrderId ?? "";
          if (!isSynchronousBackend)
            storeApi.dispatch(
              apiSlice.util.updateQueryData('getworkitem', workitemId, (data: any) => {
                if (data?.protocol) {
                  if (presentation_intent === 'for_export') {
                    data.state = 'COMPLETED';
                  }
                  if (presentation_intent === 'for_presentation') {
                    data.state = 'IN_PROGRESS';
                  }
                }
                return data;
              })
            )

          let currentProtocolSettingsStepNumber = -1;
          let protocolType: string = '';

          switch (presentation_intent) {
            case 'for_processing':
              protocolType = Constants.ACQUISITION_STEP_TYPE;
              currentProtocolStepCorrelationId = uuidv4();
              break;
            case 'for_presentation':
              protocolType = Constants.PROCESSING_STEP_TYPE;
              break;
            case 'for_export':
              protocolType = Constants.POSTPROCESSING_STEP_TYPE;
              currentProtocolStepCorrelationId = getProtocolCorrelationIdAtMatrixIndex(storeApi.getState(), storeApi.getState().ImageDisplay.selectedIndex);
              break;
          } // switch



          if (!isSynchronousBackend) {
            storeApi.dispatch(
              apiSlice.util.updateQueryData('getStudyWithWorkitems', currentOrderId, (data: any) => {
                const workitemList = data?.workitems?.map((workitem: any, i: number) => workitem?.data?.id);
                if (workitemId && workitemList && workitemList.includes(workitemId)) {
                  const currentWorkitem = data?.workitems?.find((workitem: any) => workitem.data?.id === workitemId);

                  if (protocolType !== '') {
                    currentProtocolSettingsStepNumber = getCurrentProtocolStepNumber(storeApi.getState(), protocolType, 'scheduled', 'rejected', currentWorkitem?.data);

                    if (currentProtocolSettingsStepNumber > -1 && currentWorkitem?.data?.protocol?.steps && currentWorkitem?.data?.protocol?.steps[currentProtocolSettingsStepNumber]?.performed === undefined) {
                      let series = currentWorkitem.data.protocol.steps[currentProtocolSettingsStepNumber]?.performed?.output?.series;
                      if (series) {
                        //series.push(messageObject?.eventEnvelope?.aggregateId);
                        series = [...series, messageObject?.eventEnvelope?.aggregateId];
                      } else {
                        series = [messageObject?.eventEnvelope?.aggregateId];
                      }
                      currentWorkitem.data.protocol.steps[currentProtocolSettingsStepNumber].correlationId = currentProtocolStepCorrelationId;
                      currentWorkitem.data.protocol.steps[currentProtocolSettingsStepNumber].performed = { output: { series } }
                    } else {
                      //currentWorkitem.data.protocol.steps.push({ type: protocolType, performed: { output: { series: [messageObject?.eventEnvelope?.aggregateId] } } })
                      currentWorkitem.data.protocol.steps = [...currentWorkitem.data.protocol.steps, { correlationId: currentProtocolStepCorrelationId, type: protocolType, performed: { output: { series: [messageObject?.eventEnvelope?.aggregateId] } } }];
                    }

                    if (protocolType === Constants.POSTPROCESSING_STEP_TYPE) {
                      currentWorkitem.data.state = 'COMPLETED';
                    }
                    if (protocolType === Constants.PROCESSING_STEP_TYPE) {
                      currentWorkitem.data.state = 'IN_PROGRESS';
                    }
                  }
                }
                return data;
              })
            )
            console.log("updateQueryData  getStudyWithWorkitems");
          }

          const selectWorkitemResult = apiSlice.endpoints.getworkitem.select(storeApi.getState().ImageDisplay.selectedWorkitem);
          let workitem = selectWorkitemResult(storeApi.getState())?.data;
          if (workitem === undefined) {
            workitem = getWorkitemFromStudyWithWorkitems(storeApi.getState(), storeApi.getState().Orders.currentOrderId, storeApi.getState().ImageDisplay.selectedWorkitem);
          }

          const newData = [];
          newData.push({
            state: messageObject?.eventEnvelope?.event?.state,
            details: messageObject?.eventEnvelope?.event?.details,
            instances: messageObject?.eventEnvelope?.event?.instances ?? [],
            id: messageObject?.eventEnvelope?.aggregateId,
          });

          if (!isSynchronousBackend) {
            storeApi.dispatch(apiSlice.util.upsertQueryData('getSeries', [messageObject?.eventEnvelope?.aggregateId], newData));
            console.log("updateQueryData  Series " + messageObject?.eventEnvelope?.aggregateId);
          }

          storeApi.dispatch({ type: 'ImageDisplay/setProtocolStepSelectionProps', payload: { workitemId: workitemId, stepType: protocolType, requiredAttribute: 'performed', excludedAttribute: 'rejected', index: -1, seriesIndex: -1, correlationId: '' } });
        }
      }
    }

    if (message && topic.startsWith('de/or/events/vena/instance/')) {
      const isAcquistionButtonActive: boolean = storeApi.getState()?.Acquisition.isAcquistionButtonActive;
      const isImageProcessing: boolean = storeApi.getState()?.ImageDisplay.isImageProcessing;
      const finalizeCounter: number = storeApi.getState()?.ImageDisplay.finalizeCounter;
      const currentOrderId: string = storeApi.getState()?.Orders.currentOrderId ?? "";
      const selectedWorkitem: string = storeApi.getState()?.ImageDisplay.selectedWorkitem;

      const messageObject: any = JSON.parse(message.toString());
      if (selectedWorkitem && currentOrderId) {
        console.log("Receive message on: " + topic + " " + message.toString());
        const artifactId: string = messageObject?.eventEnvelope?.event?.details?.artifact_id;
        const workitemId: string = messageObject?.eventEnvelope?.event?.details?.ref_workitem;
        const studyId: string = messageObject?.eventEnvelope?.event?.details?.ref_study;
        const type: string = messageObject?.eventEnvelope?.event?.details?.type;

        if (isSynchronousBackend) {
          const studyId: string = storeApi.getState().Orders.currentOrderId;
          storeApi.dispatch(apiSlice.util.invalidateTags([{ type: 'Workitem', id: workitemId }, { type: 'Study', id: studyId }]));
          //storeApi.dispatch(apiSlice.util.invalidateTags([{type: 'Workitem', id: workitemId}]));
        }

        //if ((isAcquistionButtonActive || isImageProcessing) && workitemId === selectedWorkitem) {
        if (workitemId === selectedWorkitem) {

          // due to 'eventually consistency' backend store series
          if (type !== 'ORIGINAL/PRIMARY/preview') {
            if (!isSynchronousBackend)
              storeApi.dispatch(apiSlice.util.upsertQueryData('getInstance', messageObject?.eventEnvelope?.aggregateId,
                {
                  details: messageObject?.eventEnvelope?.event?.details,
                  id: messageObject?.eventEnvelope?.aggregateId,
                }));

            const selectWorkitemResult = apiSlice.endpoints.getworkitem.select(storeApi.getState().ImageDisplay.selectedWorkitem);
            let workitem = selectWorkitemResult(storeApi.getState())?.data;
            if (workitem === undefined) {
              workitem = getWorkitemFromStudyWithWorkitems(storeApi.getState(), storeApi.getState().Orders.currentOrderId, storeApi.getState().ImageDisplay.selectedWorkitem);
            }

            if (!isSynchronousBackend) {
              storeApi.dispatch(
                apiSlice.util.updateQueryData('getSeries', [messageObject?.eventEnvelope?.event?.details?.ref_series], (data: any) => {
                  const currentSeries = data?.find((item: any) => item.id === messageObject?.eventEnvelope?.event?.details?.ref_series);
                  if (currentSeries /*&& currentSeries?.instances*/) {
                    currentSeries.state = 'COMPLETED';
                    if (currentSeries.instances) {
                      //currentSeries.instances.push(messageObject?.eventEnvelope?.aggregateId);
                      currentSeries.instances = [...currentSeries.instances, messageObject?.eventEnvelope?.aggregateId];
                    } else {
                      currentSeries.instances = [messageObject?.eventEnvelope?.aggregateId];
                    }
                  }
                  return data;
                })
              )
              console.log("updateQueryData  Series add instances " + messageObject?.eventEnvelope?.aggregateId);
            }
          }

          const rawArtifactId = selectRawArtifactIdForSelectedWorkitem(storeApi.getState());


          //const newArtifact = await storeApi.dispatch(apiSlice.endpoints.getArtifact.initiate(artifactId));
          const currentMatrixIndexForWorkitem = getMatrixIndexForImageId(storeApi.getState(), workitemId);
          storeApi.dispatch({ type: "Procedure/setProcedureSelectionVisibilityState", payload: 0 });
          const newIndex = getMatrixIndexForInsert(storeApi.getState(), currentMatrixIndexForWorkitem);
          storeApi.dispatch({ type: "ImageDisplay/setProtocolCorrelationId", payload: { matrixIndex: newIndex, protocolCorrelationId: currentProtocolStepCorrelationId } });
          //if (newArtifact.status === 'fulfilled') {
          storeApi.dispatch(
            loadImage({
              imageName: `${serviceEndpoints.ARTIFACT_STORE_URL}/artifacts/${artifactId}/raw`,
              imageId: selectedWorkitem,
              artifactId: artifactId,
              artifactId_Raw: rawArtifactId,
              createPreviewImage: addImageMode.none,
              matrixIndex: currentMatrixIndexForWorkitem,
              display_raw: false,
              enabledElementForExport: undefined,
            })
          );
          //}
          storeApi.dispatch({ type: 'Acquisition/setDeviceSetOpen', payload: false });

          //storeApi.dispatch(apiSlice.util.invalidateTags([{type: 'Workitem', id: workitemId}, 'Series', 'Instance', Artifact']));
          //storeApi.dispatch(apiSlice.util.invalidateTags(['Workitem', 'Series', 'Artifact']));

			if (finalizeCounter === 0) {
				// TODO also check for acquisition protocol type
				if (rawArtifactId === undefined)
					storeApi.dispatch({ type: "ImageDisplay/setImageProcessing", payload: true });
				else
					storeApi.dispatch({ type: "ImageDisplay/setImageProcessing", payload: false });
			} else {
				if (finalizeCounter === 1)
					storeApi.dispatch({ type: "ImageDisplay/setImageProcessing", payload: false });
				storeApi.dispatch({ type: "ImageDisplay/decrFinalizeCounter", payload: false });
			}

          storeApi.dispatch({ type: 'Acquisition/setIsAcquistionOngoing', payload: false });
        }
        //storeApi.dispatch({ type: "Message/setMessage", payload: { timestamp: new Date().toISOString(), severity: messageSeverity.info, text: "receiving mqtt message: " + message.toString() } });
        if (topic === Constants.MQTT_PROCESSING_QUEU) {
          //storeApi.dispatch({ type: "Acquisition/setCurrentProcessingMqttPayload", payload: message.payloadString });
        }
      }
    }
  })
}

export const MqttMiddleware: Middleware<{}, RootState> = (storeApi) => (next) => (action) => {
  switch (action.type) {
    case "MQTT_CONNECT":
      build_connection(storeApi);
      break;
    case "MQTT_CONNECTED":
      break;
    case "MQTT_DISCONNECT":
      /* if (client && client.isConnected()) {
        client.disconnect();
      } */
      break;
    case "NEW_MESSAGE":
      break;
    default:
      return next(action);
  }
};
