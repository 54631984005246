import csTools from 'cornerstone-tools';
import cornerstone from "cornerstone-core";

import { addImageMode, loadImage/*, selectArtifactIdForSelectedWorkitem*/ } from "../../OrderList/OrdersSlice";
import { getFFTFilterActive, getOriginalImage, selectMatrixIndex, selectedWorkitem } from "../ImageDisplaySlice";
import {selectArtifactIdForSelectedWorkitem,  selectRawArtifactIdForSelectedWorkitem} from "../../OrderList/OrdersSlice";
import { serviceEndpoints } from "../../..";
//import { messageSeverity } from "../../common/MessageSlice";

import store from '../../../store';

const BaseTool = csTools.importInternal('base/BaseTool');

/**
 * @public
 * @class FFTFilterTool
 * @memberof Tools
 * @classdesc Tool for filtering the raw image using a 2D-FFT.
 * @extends Tools.Base.BaseTool
 */
export default class FFTFilterTool extends BaseTool {

	constructor(props = {}) {
		const defaultProps = {
			name: 'FFTFilter',
		};
		super(props, defaultProps);
	}

	activeCallback(element) {
		//console.log("activeCallback() called");
		var currentImage = cornerstone.getImage(element);
		//const currentViewport = JSON.parse(JSON.stringify(cornerstone.getViewport(element)));
		//console.log("viewport: "+cornerstone.getViewport(element));
		const state = store.getState();
		const matrixIndex = selectMatrixIndex(state);//state.ImageDisplay.selectedIndex;
		const artifactId1 = selectArtifactIdForSelectedWorkitem(state);
		const artifactIdRaw = selectRawArtifactIdForSelectedWorkitem(state);
		const currentWorkitem = selectedWorkitem(state);

		const isFFTFilterActive = getFFTFilterActive(state, matrixIndex);
		store.dispatch({
			type: "ImageDisplay/setFFTFilterActive", payload: {
				matrixIndex: matrixIndex,
				isActive: !isFFTFilterActive, originalImage: isFFTFilterActive ? '' : currentImage.imageId
			}
		});

		var currentArtifactId = currentImage.imageId;
		if (currentArtifactId.startsWith("wadouri"))
			currentArtifactId = currentArtifactId.slice(8);

		var encoded = encodeData(currentArtifactId);
		if (!isFFTFilterActive) {
			//console.log("enable FFT from:" + currentImage.imageId + " original:" + currentImage.imageId);
			const imageName = `wadouri:${serviceEndpoints.RAW_PROCESSOR_URL}/dicom?uri=${encoded}&renderer=fft`;
			//console.log(imageName + " // " + currentArtifactId);
			store.dispatch(loadImage({
				imageName: imageName, imageId: currentWorkitem, artifactId: artifactId1,
				artifactId_Raw: artifactIdRaw, createPreviewImage: addImageMode.none, matrixIndex: matrixIndex, display_raw: false, enabledElementForExport: undefined,
			}));
		} else {
			//console.log("disable FFT from:" + currentImage.imageId);
			const originalImage = getOriginalImage(state, matrixIndex);
			store.dispatch(loadImage({
				imageName: originalImage, imageId: currentWorkitem, artifactId: artifactId1,
				artifactId_Raw: artifactIdRaw, createPreviewImage: addImageMode.none, matrixIndex: matrixIndex, display_raw: false, enabledElementForExport: undefined,
			}));
		};

		//store.dispatch({ type: "ImageDisplay/setViewportForMatrixIndex", payload: { matrixIndex: matrixIndex, viewport: currentViewport, currentWorkitemId: selectedWorkitem(state) } });

		return;
	} // activeCallback

} // FFTFilterTool



var encodeData = function(s) {
	return encodeURIComponent(s).replace(/\-/g, "%2D").replace(/\_/g, "%5F").replace(/\./g, "%2E").replace(/\!/g, "%21").replace(/\~/g, "%7E").replace(/\*/g, "%2A").replace(/\'/g, "%27").replace(/\(/g, "%28").replace(/\)/g, "%29");
}
