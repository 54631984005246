import csTools from 'cornerstone-tools';
import cornerstone from "cornerstone-core";
import { fitToWindow } from "./utils";

const BaseTool = csTools.importInternal('base/BaseTool');

/**
 * @public
 * @class FitToWindowTool
 * @memberof Tools
 *
 * @classdesc FitToWindowTool
 * @extends Tools.Base.BaseTool
 */
export default class FitToWindowTool extends BaseTool {
    /** @inheritdoc */
    constructor(props = {}) {
        const defaultProps = {
            name: 'FitToWindow',
        };
        super(props, defaultProps);
    }

    activeCallback(element : HTMLElement) {
        let viewport = cornerstone.getViewport(element);
        if (viewport) {
            fitToWindow(element);
			const eventData = {
				toolName: "StoreViewport",
				toolType: "StoreViewport",
				element: element,
				measurementData: undefined,
			};
			// @ts-ignore
			cornerstone.triggerEvent(element, csTools.EVENTS.MEASUREMENT_COMPLETED, eventData);
        }
    }
}
