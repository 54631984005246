import csTools from 'cornerstone-tools';
import cornerstone from "cornerstone-core";
import store from './../../../store';
import { getFalseColorsActive } from '../ImageDisplaySlice';

const BaseTool = csTools.importInternal('base/BaseTool');

/**
 * @public
 * @class FalseColorTool
 * @memberof Tools
 *
 * @classdesc Tool for setting wwwc based on a rectangular region.
 * @extends Tools.Base.BaseTool
 */
export default class FalseColorTool extends BaseTool {
    /** @inheritdoc */
    constructor(props = {}) {
        const defaultProps = {
            name: 'FalseColor',
        };
        super(props, defaultProps);
    }

    activeCallback(element: HTMLElement) {
        const state = store.getState();
        const isFalseColorActive = getFalseColorsActive(state, state.ImageDisplay.selectedIndex);
        store.dispatch({ type: "ImageDisplay/setFalseColorsActive", payload: { matrixIndex: state.ImageDisplay.selectedIndex, isActive: !isFalseColorActive } });
        cornerstone.updateImage(element);
    }
}
