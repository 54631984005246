import cornerstone, { EnabledElement } from "cornerstone-core";
import * as cornerstoneTools from "cornerstone-tools";
import { throttle } from 'throttle-debounce';
import React, { useCallback, useEffect, useRef, useState } from "react";
import { TypedUseSelectorHook, useSelector } from "react-redux";
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, ResponsiveContainer, ReferenceLine, Tooltip } from "recharts";
import { Constants } from "../../Constants";
import { RootState } from "../../store";
import { getLineProfileActive, getMaxLineWidth, selectToolInsetVisible } from "./ImageDisplaySlice";
import "./LineProfileHist.scss";
import HoverRangeslider from "../Utils/HoverRangeslider";
import { calculateTicks, setGetToolStateItems, updateAnnnotationOnInputChange } from "./Tools/utils";
import { lmin } from "./PipeWallThicknessHist";
import { useORTranslation } from "../Localization/ORLocalization";

type LineProfileHistProps = {
  orderId: string;
  matrixIndex: number;
};

const xlineCaptureRegion: number = 2;

const toolname: string = 'LineProfile';
export const DEFAULT_LINEWIDTH = 21;

const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

const LineProfileHist = (props: LineProfileHistProps) => {

  const { t } = useORTranslation(['Tools', 'common']);

  const [chartDragActive, setChartDragActive] = useState<number>(-1);
  const [isXlinesHifhlight, setIsXlinesHifhlight] = useState<number>(-1);
  const [tooltipActive, setTooltipActive] = useState<boolean>(false);
  const [currentHist, setCurrentHist] = useState<number>(1);
  const [yMin, setYMin] = useState<number>(0);
  const [yMax, setYMax] = useState<number>(10000);
  const [yMin1, setYMin1] = useState<number>(0);
  const [yMax1, setYMax1] = useState<number>(10000);
  const [yMin2, setYMin2] = useState<number>(0);
  const [yMax2, setYMax2] = useState<number>(10000);
  const [xline, setXline] = useState<number>(10);
  const [xlines, setXlines] = useState<number[]>([]);
  const [histData, setHistData] = useState<{ x: number; y: number }[]>([]);
  const [windowMax, setwindowMax] = useState<number>(4096);
  const [lineWidth, setLineWidth] = useState<number>(DEFAULT_LINEWIDTH);
  const [windowSize, setwindowSize] = useState<{ width: number, height: number }>({ width: 440, height: 440 });
  const [selectedToolstateIndex, setSelectedToolstateIndex] = useState<number>(0);

  const lineWidthRef = useRef<number>();
  lineWidthRef.current = lineWidth;
  const selectedToolstateIndexRef = useRef<number>();
  selectedToolstateIndexRef.current = selectedToolstateIndex;
  const xlinesRef = useRef<number[]>();
  xlinesRef.current = xlines;
  const chartDragActiveRef = useRef<number>();
  chartDragActiveRef.current = chartDragActive;

  const cornerstoneElements: EnabledElement[] = cornerstone.getEnabledElements();
  const isLineProfileActive: boolean | undefined = useAppSelector((state) => getLineProfileActive(state, props.matrixIndex ?? 0));
  const isToolInsetVisible: boolean | undefined = useAppSelector((state) => selectToolInsetVisible(state));
  const maxLineWidth: number = useAppSelector((state) => getMaxLineWidth(state));

  const displayElement: EnabledElement | undefined = cornerstoneElements?.find(
    (element: EnabledElement) => element.element.id === `${Constants.IMAGE_DISPLAY_GENERIC_ELEMENT_NAME}_${props.matrixIndex}`
  );

  const handleMouseDown = (evt: any) => {
    if (evt?.activePayload && evt?.activePayload.length > 0 && evt?.activePayload[0].payload) {
      let isChartDragActive: boolean = false;
      if (xlinesRef.current) {
        for (let i = 0; i < xlinesRef.current.length; i++) {
          if (Math.abs(evt?.activePayload[0].payload.x - xlinesRef.current[i]) < xlineCaptureRegion) {
            setChartDragActive(i);
            isChartDragActive = true;
            //setXline(xlines[i]);
            //setGetToolStateItems([{ name: "xMarker", value: xlines[i] }], [], props.matrixIndex, toolname);
            break;
          }
        }
      }
      if (!isChartDragActive) {
        setTooltipActive(true);
      }
    }
  };

  const handleMouseUp = (evt: React.MouseEvent<Element>) => {
    if ((chartDragActiveRef.current ?? -1) >= 0) {
      updateAnnnotationOnInputChange(selectedToolstateIndexRef.current, props.matrixIndex, toolname);
    }
    setChartDragActive(-1);
    setTooltipActive(false);
  };

  const onHistClick = (evt: any) => {
    if (xlinesRef.current && xlinesRef.current?.length < 2) {
      const newXlines: number[] = structuredClone(xlinesRef.current);
      newXlines.push(xline);
      setXlines(newXlines);
      setGetToolStateItems(selectedToolstateIndexRef.current ?? 0, [{ name: "xlines", value: newXlines }], [], props.matrixIndex, toolname);
    }
  };

  const xMarkerChange = (chartProps: any) => {
    if (chartProps?.activePayload && chartProps?.activePayload.length > 0 && chartProps?.activePayload[0].payload) {
      setIsXlinesHifhlight(-1);
      if (xlinesRef.current) {
        for (let i = 0; i < xlines?.length; i++) {
          if (Math.abs(chartProps?.activePayload[0].payload.x - xlinesRef.current[i]) < xlineCaptureRegion) {
            setIsXlinesHifhlight(i);
            break;
          }
        }
      }
    }

    const ret = setGetToolStateItems(selectedToolstateIndexRef.current ?? 0, [], ["scale"], props.matrixIndex, toolname);

    if (chartProps?.isTooltipActive) {
      setCurrentHist(DEFAULT_LINEWIDTH);
      if (chartProps?.activePayload && chartProps?.activePayload.length > 0 && chartProps?.activePayload[0].payload) {
        setXline(chartProps?.activePayload[0].payload.x);
        setGetToolStateItems(selectedToolstateIndexRef.current ?? 0, [{ name: "xMarker", value: chartProps?.activePayload[0].payload.x / (ret[0] ?? 1) }], [], props.matrixIndex, toolname);
      }
    }

    if ((chartDragActiveRef.current ?? -1) > -1 && chartProps?.activePayload && chartProps?.activePayload.length > 0 &&
      chartProps?.activePayload[0].payload && xlinesRef.current) {
      const newLines = structuredClone(xlinesRef.current);
      newLines[(chartDragActiveRef.current ?? 0)] = Math.max(lmin, chartProps?.activePayload[0].payload.x);
      setXlines(newLines);
      if (xlinesRef.current.length > 1) {
        setGetToolStateItems(selectedToolstateIndexRef.current ?? 0, [{
          name: "xlines", value: newLines?.map((value: number) => { return value / (ret[0] ?? 1) })
        }], [], props.matrixIndex, toolname);
        //throttleUpdateAnnnotationOnInputChange(selectedToolstateIndexRef.current, props.matrixIndex, toolname);
      }
    }
  };

  const scaleYMax = (event: any) => {
    //event.preventDefault();
    event.stopPropagation();
    // Apply scale transform
    if (currentHist > 0) {
      switch (currentHist) {
        case 1:
          setYMax(Math.floor(yMax * (1 + event.deltaY * -0.001)) + 1);
          break;
        case 2:
          setYMin1(Math.floor(yMin1 * (1 + event.deltaY * -0.001)) + 1);
          setYMax1(Math.floor(yMax1 * (1 + event.deltaY * -0.001)) - 1);
          break;
        case 3:
          setYMin2(Math.floor(yMin2 * (1 + event.deltaY * -0.001)) + 1);
          setYMax2(Math.floor(yMax2 * (1 + event.deltaY * -0.001)) - 1);
          break;
      }
    }
  }

  const throttleUpdateAnnnotationOnInputChange = useCallback(throttle(500, (index: number | undefined, matrixIndex: number, toolname: string) => {
    updateAnnnotationOnInputChange(index, matrixIndex, toolname);
  }), [lineWidth]);

  const throttleOnValueChange = useCallback(throttle(200, (measurementData) => {
    if (measurementData?.linedata) {
      setwindowMax(Math.floor(measurementData?.length + 1));
      const ticks: number[] = calculateTicks(measurementData?.min, measurementData?.max, 4);
      setYMin(ticks[0]);
      setYMax(ticks[ticks?.length - 1]);
      /* if (measurementData?.xMarker > measurementData?.length) {
        setXline(measurementData?.length * measurementData?.scale);
      } */
      setXline(measurementData?.xMarker * measurementData?.scale);
      setHistData(measurementData?.linedata?.map((value: number, i: number) => { return { x: ((i + 0.5) / measurementData?.linedata.length) * measurementData?.length * measurementData?.scale, y: value } }));
      setXlines(measurementData?.xlines?.map((value: number) => { return value * measurementData?.scale }));
    }
  }), [isLineProfileActive]);

  const throttleOnLineWidthChange = useCallback(throttle(200, (value) => {
    setLineWidth(value);
    const cornerstoneElements: EnabledElement[] = cornerstone.getEnabledElements();
    const displayElement: EnabledElement | undefined = cornerstoneElements?.find(
      (element: EnabledElement) => element.element.id === `${Constants.IMAGE_DISPLAY_GENERIC_ELEMENT_NAME}_${props.matrixIndex}`
    );
    if (displayElement?.element) {
      const ret = setGetToolStateItems(selectedToolstateIndexRef.current ?? 0, [{ name: "lineWidth", value }], ["linedata", "max", "max1", "min1", "xlines", "scale", "length", "min"], props.matrixIndex, toolname);
      setXlines(ret[4]?.map((value: number) => { return value * ret[5] }));
      const ticks: number[] = calculateTicks(ret[7], ret[1], 4);
      setYMin(ticks[0]);
      setYMax(ticks[ticks?.length - 1]);
      setHistData(ret[0]?.map((value: number, i: number) => { return { x: ((i + 0.5) / ret[0].length) * ret[6] * ret[5], y: value } }));
    }
    throttleUpdateAnnnotationOnInputChange(selectedToolstateIndexRef.current, props.matrixIndex, toolname);
  }), [isLineProfileActive]);


  useEffect(() => {
    const onMeasureModified = (ev: any) => {
      if (ev?.detail?.toolName === toolname) {
        throttleOnValueChange(ev?.detail?.measurementData);
      }
    };


    const onMeasureAdded = (ev: any) => {
      if (ev?.detail?.toolName === toolname) {
        setLineWidth(DEFAULT_LINEWIDTH);
        const cornerstoneElements: EnabledElement[] = cornerstone.getEnabledElements();
        const displayElement: EnabledElement | undefined = cornerstoneElements?.find(
          (element: EnabledElement) => element.element.id === `${Constants.IMAGE_DISPLAY_GENERIC_ELEMENT_NAME}_${props.matrixIndex}`
        );
        const stateManager = cornerstoneTools.getElementToolStateManager(displayElement?.element);
        const toolstate = stateManager.get(displayElement?.element, toolname);
        let currentSelectedToolstateIndex: number = 0;
        if (toolstate?.data) {
          currentSelectedToolstateIndex = toolstate?.data.length - 1;
          setSelectedToolstateIndex(currentSelectedToolstateIndex);
          const tool = cornerstoneTools.getToolForElement(displayElement?.element, toolname);
          if (tool) {
            tool.selectedToolstateIndex = currentSelectedToolstateIndex;
          }
        }

        setGetToolStateItems(currentSelectedToolstateIndex, [{ name: "lineWidth", value: DEFAULT_LINEWIDTH },
        //{ name: "currentToolstateIndex", value: currentSelectedToolstateIndex },
        { name: "xlines", value: ev?.detail?.measurementData?.xlines }, { name: "dragActive", value: true }],
          [], props.matrixIndex, toolname);
      }
    };

    const onCurrentToolstateIndex = (ev: any) => {
      if (ev?.detail?.data?.toolName === toolname && (ev?.detail?.data?.currentToolstateIndex !== selectedToolstateIndexRef.current || ev?.detail?.forceRedisplay)) {
        if (ev?.detail?.data?.currentToolstateIndex === -1) {
          setSelectedToolstateIndex(-1);
          setXlines([]);
          setHistData([]);
        } else {
          setSelectedToolstateIndex(ev?.detail?.data?.currentToolstateIndex);
          setXlines(structuredClone(ev?.detail?.data?.xlines?.map((value: number) => { return value * ev?.detail?.data?.scale })));
          setLineWidth(ev?.detail?.data?.lineWidth);
        }
      }
    };

    setwindowSize({ width: windowSize.width, height: 220 });

    //setLineWidth(DEFAULT_LINEWIDTH);

    if (displayElement) {
      if (displayElement.image) {

        displayElement.element?.addEventListener(
          cornerstoneTools.EVENTS.MEASUREMENT_MODIFIED,
          onMeasureModified
        );
        displayElement.element?.addEventListener(
          cornerstoneTools.EVENTS.MEASUREMENT_ADDED,
          onMeasureAdded
        );
        /* displayElement.element?.addEventListener(
          cornerstoneTools.EVENTS.MEASUREMENT_COMPLETED,
          onMeasureCompleted
        ); */
        displayElement.element?.addEventListener(
          "currentToolstateIndex",
          onCurrentToolstateIndex
        );
      }
    }
    return () => {

      if (displayElement) {
        displayElement.element?.removeEventListener(
          cornerstoneTools.EVENTS.MEASUREMENT_MODIFIED,
          onMeasureModified
        );
        displayElement.element?.removeEventListener(
          cornerstoneTools.EVENTS.MEASUREMENT_ADDED,
          onMeasureAdded
        );
        displayElement.element?.removeEventListener(
          "currentToolstateIndex",
          onCurrentToolstateIndex
        );
      }
    };
  }, [displayElement, displayElement?.image?.imageId, isLineProfileActive]);

  useEffect(() => {
    if (selectedToolstateIndex !== undefined && selectedToolstateIndex > -1) {
      const ret = setGetToolStateItems(selectedToolstateIndex, [], ["linedata", "max", "max1", "min1", "xlines", "length", "scale", "xMarker", "min"], props.matrixIndex, toolname);
      throttleOnValueChange({ linedata: ret[0], max: ret[1], max1: ret[2], min1: ret[3], xlines: ret[4], length: ret[5], scale: ret[6], xMarker: ret[7], min: ret[8] });
    }
  }, [selectedToolstateIndex]);

  useEffect(() => {
    if (displayElement) {
      //setHistData([]);

      const ret = setGetToolStateItems(0, [], ["linedata", "max", "max1", "min1", "xlines", "length", "scale", "xMarker", "min"], props.matrixIndex, toolname);
      if (ret[0]) {
        const tool = cornerstoneTools.getToolForElement(displayElement?.element, toolname);
        if (tool) {
          tool.selectedToolstateIndex = 0;
        }
        setSelectedToolstateIndex(0);
        throttleOnValueChange({ linedata: ret[0], max: ret[1], max1: ret[2], min1: ret[3], xlines: ret[4], length: ret[5], scale: ret[6], xMarker: ret[7], min: ret[8] });
      } else {
        setHistData([]);
      }
    }
    return () => {
      setSelectedToolstateIndex(-1);
    };
  }, [displayElement, displayElement?.image?.imageId]);

  useEffect(() => {
    let toolSelectedToolstateIndex: number = 0;
    const cornerstoneElements: EnabledElement[] = cornerstone.getEnabledElements();
    const displayElement: EnabledElement | undefined = cornerstoneElements?.find(
      (element: EnabledElement) => element.element.id === `${Constants.IMAGE_DISPLAY_GENERIC_ELEMENT_NAME}_${props.matrixIndex}`
    );
    if (displayElement) {
      const tool = cornerstoneTools.getToolForElement(displayElement.element, toolname);
      if (tool?.selectedToolstateIndex !== undefined) {
        toolSelectedToolstateIndex = tool?.selectedToolstateIndex;
      }
    }

    setGetToolStateItems(toolSelectedToolstateIndex,
      [{ name: "dragActive", value: false }], [], props.matrixIndex, toolname);

    const ret = setGetToolStateItems(toolSelectedToolstateIndex, [], ["linedata", "max", "max1", "min1", "xlines",
      "length", "lineWidth", "scale", "min"], props.matrixIndex, toolname);
    throttleOnValueChange({ linedata: ret[0], max: ret[1], max1: ret[2], min1: ret[3], xlines: ret[4], length: ret[5], scale: ret[7], min: ret[8] });
    if (ret[4] && ret[9]) {
      setXlines(ret[4]?.map((value: number) => { return value * ret[9] }));
    }
    if (ret[6]) {
      setLineWidth(ret[6]);
    }
  }, [isToolInsetVisible, props.matrixIndex]);

  return (
    <div className="LineProfileHist">
      <div className="toolContextHeader">
        {t("GrayScaleProfile")}
      </div>
      <div className="chartWrapper" onContextMenu={(e) => e.preventDefault()} onWheel={(e) => scaleYMax(e)}>
        <ResponsiveContainer width="100%" height="100%" minWidth="40" minHeight="3">
          <AreaChart
            width={500}
            height={450}
            data={histData}
            margin={{
              top: 5,
              right: 18,
              left: 41,
              bottom: 0,
            }}
            onClick={onHistClick}
            onMouseMove={(props: any) => xMarkerChange(props)}
            onMouseDown={(evt: any) => handleMouseDown(evt)}
            onMouseUp={(evt: any) => handleMouseUp(evt)}
            onMouseEnter={(evt: any) => setCurrentHist(1)}
          >
            <XAxis dataKey="x" type="number" domain={[0, 'auto']} style={{ fontSize: "0.8em" }} height={31} />
            <YAxis width={5} type="number" domain={[yMin, yMax]}
              style={{ fontSize: "0.8em" }} allowDataOverflow={true} />
            <CartesianGrid />
            {xlines && xlines.length < 2 || (chartDragActive < 0 && tooltipActive) ?
              <>
                <Tooltip cursor={{ stroke: 'hsla(0, 97%, 66%, 1.0)', strokeWidth: 1 }}
                  /* formatter={(value: number, name: string, props: any) => { throttleOnTooltipChanged(props); return value?.toFixed(2) }} */
                  // @ts-ignore
                  formatter={(value: number, name: string, props: any) => { return value?.toFixed(2) }}
                  labelFormatter={(value: any, props: any) => { return value?.toFixed(2) }} />
              </>
              : null}
            <Area className="chartArea" type="monotone" dataKey="y" isAnimationActive={false} />
            <ReferenceLine className="tooltipline_active" x={xline} />
            {xlines?.map((val, i) => <ReferenceLine className={isXlinesHifhlight === i ? "xline_active" : "xline"}
              x={val} strokeWidth={chartDragActive === i ? 2 : 1} strokeDasharray="4 3" key={i} isFront={true} />)}
          </AreaChart>
        </ResponsiveContainer>
      </div>

      <div className="lineWidth">
        <span className="lineWidthText">{`${t('lineWidth')}:`}</span>
        <span className="HoverRangeInput">
          <HoverRangeslider onChange={throttleOnLineWidthChange} startValue={lineWidth} min={1} max={maxLineWidth} units={'px'} />
        </span>
      </div>
    </div>
  );
};

export default LineProfileHist;
