
import { useEffect, useState } from 'react';
import './XrayHelper.scss';
import { TypedUseSelectorHook, useSelector } from 'react-redux';
import { RootState } from '../../store';
import { getImagePathForCurrentTheme, getSelectedWorkitem } from '../OrderList/OrdersSlice';
import { useGetXrayHelperByProcedureCodeAndVariantListQuery } from '../../apis/apiSlice';
import React from 'react';
import { serviceEndpoints } from '../..';
import { selectedXRayHelperVariantArray } from './ProcedureSlice';
import { Constants } from '../../Constants';
import { getCurrentProtocolStepNumber } from '../ImageDisplay/ImageDisplaySlice';

interface XrayHelperProps {
}

const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

const XrayHelper = (props: XrayHelperProps) => {

    const currentWorkitemAsString: string | undefined = useAppSelector((state) => getSelectedWorkitem(state));
    const currentWorkitem = currentWorkitemAsString ? JSON.parse(currentWorkitemAsString) : undefined;
    const imagePathForCurrentTheme: string = useAppSelector((state) => getImagePathForCurrentTheme(state));
    const xrayHelperVariantArray: string[] = useAppSelector((state) => selectedXRayHelperVariantArray(state));
    const currentProtocolSettingsStepNumber: number = useAppSelector((state) => getCurrentProtocolStepNumber(state, Constants.ACQUISITION_STEP_TYPE, 'scheduled', 'rejected'));

    const [selectedImage, setSelectedImage] = useState<{ type: number, file: string, title?: string }>({ type: 0, file: '' });

    const {
        data: data_xrayHelper,
        isSuccess: isSuccess_xrayHelper,
    }: {
        data: any, isLoading: boolean, isSuccess: boolean, isError: boolean, error: any
    } = useGetXrayHelperByProcedureCodeAndVariantListQuery({ procedureCode: currentWorkitem?.details?.procedureCode, variant: xrayHelperVariantArray }, {
        refetchOnMountOrArgChange: true,
        skip: currentWorkitem?.details?.procedureCode === undefined,
    })

    useEffect(() => {
        if (isSuccess_xrayHelper) {
            setSelectedImage({ type: data_xrayHelper?.content?.setup?.images[0]?.type, file: data_xrayHelper?.content?.setup?.images[0]?.path });
        }
    }, [currentWorkitem?.id, isSuccess_xrayHelper, data_xrayHelper?.content?.setup?.images[0]?.path]);

    const tableSettingsLabels = [{ type: 'material', label: 'Material:' }, { type: 'bucky', label: 'bucky:' },
    { type: 'film_EK', label: 'Speed Class:' }, { type: 'format', label: 'Format:' }, { type: 'exp_EU', label: 'Exp. EU:' },
    { type: 'exp_Switzerland', label: 'Exp. CH:' }, { type: 'ffa_EU', label: 'TFD (=FFD, =SID) EU:' },
    { type: 'ffa_Switzerland', label: 'TFD (=FFD, =SID) CH:' }, { type: 'ffa_USA_UK', label: 'TFD (=FFD, =SID) USA/UK:' },
    { type: 'ffa_WHO', label: 'TFD (=FFD, =SID) WHO:' }, { type: 'grid', label: 'Grid:' }, { type: 'filter', label: 'Filter:' }]

    const tableSettingsLabels2 = [{ type: 'patient_positioning', label: 'Patient position:' }, { type: 'variant_notes', label: 'Alternative:' },
    { type: 'central_ray', label: 'Central ray:' }, { type: 'special_remarks', label: 'Comments/Notes:' }, { type: 'notes', label: 'Hints:' }];

    return (
        <div className="xrayHelperPanel">
            <div className="xrayHelperVspace" />
            {isSuccess_xrayHelper && selectedImage?.file !== '' ?
                <div className="xrayHelperWrapper">
                    <div className="xrayHelperContent">
                        <div className="xrayHelperCol1">
                            <div className="xrayHelperPositionImages">
                                <>
                                    {data_xrayHelper?.content?.setup?.images?.map((item: any, i: number) => (
                                        <img className={selectedImage?.file === item?.path ? "procedureHelperImage xrayHelperImage_selected" : "procedureHelperImage xrayHelperImage"} key={i.toString()}
                                            src={`${serviceEndpoints.PROCEDURES_URL}/xrayHelperImages/${item?.path}`}
                                            alt={`${serviceEndpoints.PROCEDURES_URL}/xrayHelperImages/${item?.path}_${item?.type}`}
                                            onClick={() => setSelectedImage({ type: item?.type, file: item?.path })}
                                        />
                                    ))}
                                </>
                                <>
                                    {data_xrayHelper?.content?.videos?.filter((item: any) => item?.language === 'de')?.map((item: any, i: number) => (
                                        <div className="procedureHelperVideoWrapper" key={i.toString()}>
                                            <video
                                                className={selectedImage?.file === item?.file ? "procedureHelperVideoPoster xrayHelperImage_selected" : "procedureHelperVideoPoster xrayHelperImage"}
                                                autoPlay={false} controls={false}
                                                onClick={(event: any) => { setSelectedImage({ type: item?.type, file: item?.file }); event.target.pause(); event.target.currentTime = 1 }}
                                                onMouseOver={(event: any) => event.target.play()}
                                                onMouseOut={(event: any) => { event.target.pause(); event.target.currentTime = 1 }}>
                                                <source src={`${serviceEndpoints.PROCEDURES_URL}/xrayHelperImages/videos/${data_xrayHelper?.content?.videos?.find((item: any) => item.language === 'de')?.file}#t=1.0`} type="video/mp4"></source>
                                            </video>
                                            <img className="videoImg"
                                                src={`${imagePathForCurrentTheme}video-camera-round-964-svgrepo-com.svg`}
                                                onError={(event: any) => { event.target.src = "/images/video-camera-round-964-svgrepo-com.svg"; event.onerror = null }}
                                                alt="tag browser" />
                                        </div>
                                    ))}
                                </>
                            </div>
                            <div className="tableSettings">
                                <>
                                    {tableSettingsLabels?.filter((item: any) => data_xrayHelper?.content?.settingsStandards[item?.type] !== undefined)?.map((item: any, i: number) =>
                                        <React.Fragment key={i.toString()}>
                                            <label className="label">{item.label}</label>
                                            <label id={`${i.toString()}b`} key={`${i.toString()}b`}>{data_xrayHelper?.content?.settingsStandards[item?.type]}</label>
                                        </React.Fragment>
                                    )}
                                    <label className="label">{'Measuring Chamber:'}</label>
                                    <label>{currentWorkitem?.protocol?.steps[currentProtocolSettingsStepNumber]?.scheduled?.setup?.measuringChamber}</label>
                                    <label className="label">{'Focus:'}</label>
                                    <label>{currentWorkitem?.protocol?.steps[currentProtocolSettingsStepNumber]?.scheduled?.generator?.focusId}</label>
                                </>
                            </div>
                            <div className="drawingAndCorrectXray">
                                <div className="drawing">
                                    <label>{'Drawing'}</label>
                                    {data_xrayHelper?.content?.drawing?.path ?
                                        <img className={selectedImage?.file === data_xrayHelper?.content?.drawing?.path ? "procedureHelperImage xrayHelperImage_selected" : "procedureHelperImage xrayHelperImage"}
                                            onClick={() => setSelectedImage({ type: 7, file: data_xrayHelper?.content?.drawing?.path })}
                                            src={`${serviceEndpoints.PROCEDURES_URL}/xrayHelperImages/${data_xrayHelper?.content?.drawing?.path}`}
                                            alt={`${serviceEndpoints.PROCEDURES_URL}/xrayHelperImages/${data_xrayHelper?.content?.drawing?.path}`}
                                        />
                                        : null
                                    }

                                </div>
                                <div className="correctXray">
                                    <label>{'Correct X-ray'}</label>
                                    <div className="correctXrayImages">
                                        {data_xrayHelper?.content?.correctXRay?.images?.map((item: any, i: number) => (
                                            <img key={i.toString()} onClick={() => setSelectedImage({ type: item?.type, file: item?.path, title: item?.title })}
                                                src={`${serviceEndpoints.PROCEDURES_URL}/xrayHelperImages/${item?.path}`}
                                                alt={`${serviceEndpoints.PROCEDURES_URL}/xrayHelperImages/${item?.path}_${item?.type}`}
                                                className={selectedImage?.file === item?.file ? "procedureHelperImage xrayHelperImage_selected" : "procedureHelperImage xrayHelperImage"}
                                            />
                                        ))}
                                    </div>
                                </div>
                            </div>

                            {data_xrayHelper?.content?.skeleton?.images?.length > 0 ?
                                <div className="furtherImformationWrapper">
                                    <label>{'Positioning, Errors, Further Information'}</label>
                                    <div className="furtherImformation">
                                        <div className="furtherImformation_images">
                                            {data_xrayHelper?.content?.skeleton?.images?.map((item: any, i: number) => (
                                                <img key={i.toString()} onClick={() => setSelectedImage({ type: item?.type, file: item?.path, title: item?.title })}
                                                    src={`${serviceEndpoints.PROCEDURES_URL}/xrayHelperImages/${item?.path}`}
                                                    alt={`${serviceEndpoints.PROCEDURES_URL}/xrayHelperImages/${item?.path}_${item?.type}`}
                                                    className={selectedImage?.file === item?.file ? "procedureHelperImage xrayHelperImage_selected" : "procedureHelperImage xrayHelperImage"}
                                                />
                                            ))}
                                        </div>
                                        <div className="furtherImformation_images">
                                            {data_xrayHelper?.content?.xrayMiss?.images?.map((item: any, i: number) => (
                                                <img key={i.toString()} onClick={() => setSelectedImage({ type: item?.type, file: item?.path, title: item?.title })}
                                                    src={`${serviceEndpoints.PROCEDURES_URL}/xrayHelperImages/${item?.path}`}
                                                    alt={`${serviceEndpoints.PROCEDURES_URL}/xrayHelperImages/${item?.path}_${item?.type}`}
                                                    className={selectedImage?.file === item?.file ? "procedureHelperImage xrayHelperImage_selected" : "procedureHelperImage xrayHelperImage"}
                                                />
                                            ))}
                                        </div>
                                    </div>
                                </div>
                                : null}

                        </div>
                        <div className="xrayHelperCol2">
                            <div className="selectedImage">
                                {selectedImage?.type === 6 ?
                                    <video className="procedureHelperVideo" autoPlay={true} controls={true}>
                                        <source src={`${serviceEndpoints.PROCEDURES_URL}/xrayHelperImages/videos${data_xrayHelper?.content?.videos?.find((item: any) => item.language === 'de')?.file}`} type="video/mp4"></source>
                                    </video>
                                    :
                                    <img
                                        src={`${serviceEndpoints.PROCEDURES_URL}/xrayHelperImages/${selectedImage?.file}`}
                                        alt={`${serviceEndpoints.PROCEDURES_URL}/xrayHelperImages/${selectedImage?.file}_${selectedImage?.type}`}
                                        className="procedureHelperImage"
                                    />
                                }
                            </div>
                            {selectedImage?.type <= 7 ?
                                <div className="tableSettingWrapper">
                                    <div className="tableSettings2">
                                        {tableSettingsLabels2?.map((item: any, i: number) =>
                                            <React.Fragment key={i.toString()}>
                                                {item.type in data_xrayHelper?.content?.furtherText ?
                                                    <>
                                                        <label className="label">{item.label}</label>
                                                        <label id={`${i.toString()}b`} key={`${i.toString()}b`}>{data_xrayHelper?.content?.furtherText[item.type]}</label>
                                                    </>
                                                    : null}
                                            </React.Fragment>
                                        )}
                                    </div>
                                </div>
                                :
                                <div className="tableSettingWrapper">
                                    {selectedImage?.title ?
                                        <div className="tableSettings2">
                                            <label className="label">{selectedImage?.type <= 10 ? 'Criteria for a correct X-ray:' : 'Comments:'}</label>
                                            <label className="label">{selectedImage?.title}</label>
                                            {selectedImage?.type <= 10 ?
                                                <>
                                                    {'common_mistakes' in data_xrayHelper?.content?.furtherText ?
                                                        <>
                                                            {data_xrayHelper?.content?.furtherText['common_mistakes'].split('\n')?.map((item: any, i: number) => (
                                                                <React.Fragment key={i.toString()}>
                                                                    <label className="label1">{i === 0 ? 'Common errors:' : ''}</label>
                                                                    <label className={data_xrayHelper?.content?.furtherText['common_mistakes'].split('\n').length > 1 ? "label2" : "label"}>
                                                                        {item}
                                                                    </label>
                                                                </React.Fragment>
                                                            ))
                                                            }
                                                        </>
                                                        : null

                                                    }
                                                </>
                                                : null}
                                        </div>
                                        : null}
                                </div>
                            }
                        </div>
                    </div>
                </div>
                : null}
        </div>
    );
}

export default XrayHelper;
