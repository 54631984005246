import React, { useCallback, useEffect, useState } from 'react';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../store';
import { selectMatrixColumns, selectMatrixRows } from '../ImageDisplaySlice';

type Cell= { row: number; column: number; className: string };

interface LayoutChooserProps {
    Rows: number,
    Columns: number,
}

const useAppDispatch = () => useDispatch<AppDispatch>();
const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

const emptyCell: Cell = {row: -1,column: -1,className: ''};

const LayoutChooser = (props: LayoutChooserProps) =>  {

    const dispatch = useAppDispatch();

    const currentMatrixColumns: number | undefined = useAppSelector((state) => selectMatrixColumns(state));
    const currentMatrixRows: number | undefined = useAppSelector((state) => selectMatrixRows(state));

    const [selectedCell, setSelectedCell] = useState<Cell>({row: ((currentMatrixRows??0) - 1), column: ((currentMatrixColumns??0) - 1), className: '' });
    const [table, setTable] = useState<Cell[][]>([[]]);

    const onClick = (currentCell: Cell) =>{
        setSelectedCell(currentCell);
        highlightCells(currentCell);
        dispatch({ type: "ImageDisplay/setMatrix", payload: {columns: currentCell.column+1, rows:  currentCell.row+1} });

    }
    const isRange = (cell: Cell, parentCell: Cell) => {
        return cell.row <= parentCell.row && cell.column <= parentCell.column;
    };

    const highlightCells = useCallback((currentCell: Cell) => {
        let table = [];
        for (let row = 0; row < props.Rows; row++) {
            let newRow = [];
            for (let col = 0; col < props.Columns; col++) {
                let cell = { row: row, column: col, className: '' };
                if (isRange(cell, currentCell)) {
                    cell.className = 'hover';
                } else if (
                    isRange(currentCell, emptyCell) &&
                    isRange(cell, selectedCell)
                ) {
                    cell.className = 'selectedBefore';
                }
                newRow.push(cell);
            }
            table.push(newRow);
        }
        setTable(table);
    },[selectedCell, props.Columns, props.Rows]);

    useEffect(() => {
        highlightCells(emptyCell);
    }, [currentMatrixColumns, currentMatrixRows, highlightCells]);

        return (
            <div
                className="layoutChooserMatrix"
            >
                <table>
                    <tbody>
                        {table.map((row, i) => {
                            return (
                                <tr key={i}>
                                    {row.map((cell, j) => {
                                        return (
                                            <td
                                                className={cell.className}
                                                key={j}
                                                onMouseEnter={() => highlightCells(cell)}
                                                onMouseLeave={() => highlightCells(emptyCell)}
                                                onMouseDown={() => onClick(cell)}
                                                onTouchStart={() => onClick(cell)}
                                            />
                                        );
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        );

}

export default LayoutChooser;
