/* eslint no-alert: 0 */
import csTools from 'cornerstone-tools';
import { getFilterForColor } from "./utils";
const Cursors = csTools.importInternal('tools/cursors');
const arrowAnnotateCursor = Cursors.arrowAnnotateCursor;
const getToolState = csTools.getToolState;
const getNewContext = csTools.import("drawing/getNewContext");
const drawLinkedTextBox = csTools.import("drawing/drawLinkedTextBox");
const textBoxWidth = csTools.import("drawing/textBoxWidth");
const drawArrow = csTools.import("drawing/drawArrow");
const drawHandles = csTools.importInternal("drawing/drawHandles");
const draw = csTools.import("drawing/draw");
const setShadow = csTools.import("drawing/setShadow");
const external = csTools.external;
const moveHandleNearImagePoint = csTools.importInternal('manipulators/moveHandleNearImagePoint');

/**
 * @public
 * @class RestorableArrowAnnotateTool
 * @memberof Tools.Annotation
 * @classdesc Create and position an arrow and label
 * @extends Tools.Annotation.ArrowAnnotateTool
 */
export default class RestorableArrowAnnotateTool extends csTools.ArrowAnnotateTool {
	constructor(props = {}) {
		const defaultProps = {
			name: 'RestorableArrowAnnotate',
			supportedInteractionTypes: ['Mouse', 'Touch'],
			configuration: {
				getTextCallback: props.configuration.getTextCallback,
				changeTextCallback: props.configuration.changeTextCallback,
				drawHandles: true,
				drawHandlesOnHover: props.configuration.drawHandlesOnHover ?? false,
				hideHandlesIfMoving: false,
				arrowFirst: true,
				renderDashed: false,
				allowEmptyLabel: false,
				shadow: true,
			},
			svgCursor: arrowAnnotateCursor,
		};

		super(defaultProps);

		this.getTextCallback = props?.getTextCallback
		this.changeTextCallback = props?.changeTextCallback
		this.preventNewMeasurement = false;
		this.img = new Image();
		this.img.src = "/images/Schreiben_Tools.svg";

		this.toolColor = 'rgba(178, 194, 0, 1)';
		this.toolSelectedColor = 'hsla(64, 87%, 79%, 1)';
		this.toolActiveColor = 'rgba(255, 255, 0, 1)';

		const elem = document.getElementById('toolsPanel');
		if (elem) {
			const selectedColor = getComputedStyle(elem)?.getPropertyValue('--toolSelectedColor');
			if (selectedColor) {
				this.toolSelectedColor = selectedColor;
			}
			const toolActiveColor = getComputedStyle(elem)?.getPropertyValue('--toolActiveColor');
			if (toolActiveColor) {
				this.toolActiveColor = toolActiveColor;
			}
		}

		this.svgFilter = getFilterForColor(this.toolColor);
        this.svgFilterActive = getFilterForColor(this.toolActiveColor);
        this.svgFilterSelected = getFilterForColor(this.toolSelectedColor)
	}


	createNewMeasurement(evt) {
		// Create the measurement data for this tool with the end handle activated

		return {
			visible: true,
			active: true,
			color: undefined,
			handles: {
				start: {
					x: evt.detail.currentPoints.image.x,
					y: evt.detail.currentPoints.image.y,
					highlight: true,
					active: false,
				},
				end: {
					x: evt.detail.currentPoints.image.x,
					y: evt.detail.currentPoints.image.y,
					highlight: true,
					active: false,
				},
				textBox: {
					active: false,
					hasMoved: false,
					movesIndependently: false,
					drawnIndependently: true,
					allowedOutsideImage: true,
					hasBoundingBox: true,
				},
			},
		};
	}

	handleSelectedCallback(evt, toolData, handle, interactionType = 'mouse') {
		evt.stopImmediatePropagation();
		evt.stopPropagation();
		evt.preventDefault();

		const textBoxRightBorderX = toolData?.handles?.textBox?.boundingBox?.left + toolData?.handles?.textBox?.boundingBox?.width;
		const currentPointX = evt?.detail?.currentPoints?.canvas?.x;
		if (textBoxRightBorderX !== undefined && currentPointX !== undefined) {
			if (Math.abs(textBoxRightBorderX - currentPointX) < 20) {
				this.store(toolData);
				this._updateTextForNearbyAnnotation(evt);
				if (toolData?.handles?.textBox?.active) {
					toolData.handles.textBox.active = false;
				}
			} else {
				moveHandleNearImagePoint(evt, this, toolData, handle, interactionType);
			}
		}
	}

	doubleClickCallback(evt) {
	}

	renderToolData(evt) {
		const { element, enabledElement } = evt.detail;
		const {
			handleRadius,
			drawHandlesOnHover,
			hideHandlesIfMoving,
			renderDashed,
		} = this.configuration;

		// If we have no toolData for this element, return immediately as there is nothing to do
		const toolData = getToolState(element, this.name);

		if (!toolData) {
			return;
		}

		// We have tool data for this element - iterate over each one and draw it
		const canvas = evt.detail.canvasContext.canvas;
		const context = getNewContext(canvas);

		const lineWidth = csTools.toolStyle.getToolWidth();

		let lineDash;
		if (renderDashed) {
			lineDash = csTools.getModule('globalConfiguration').configuration.lineDash;
		}

		for (let i = 0; i < toolData.data.length; i++) {
			const data = toolData.data[i];

			if (data.visible === false) {
				continue;
			}

			draw(context, context => {
				setShadow(context, this.configuration);

				const color = csTools.toolColors.getColorIfActive(data);

				// Draw the arrow
				const handleStartCanvas = external.cornerstone.pixelToCanvas(
					element,
					data.handles.start
				);
				const handleEndCanvas = external.cornerstone.pixelToCanvas(
					element,
					data.handles.end
				);

				// Config.arrowFirst = false;
				if (this.configuration.arrowFirst) {
					drawArrow(
						context,
						handleEndCanvas,
						handleStartCanvas,
						color,
						lineWidth,
						lineDash
					);
				} else {
					drawArrow(
						context,
						handleStartCanvas,
						handleEndCanvas,
						color,
						lineWidth,
						lineDash
					);
				}

				const handleOptions = {
					color,
					handleRadius,
					drawHandlesIfActive: drawHandlesOnHover,
					hideHandlesIfMoving,
				};

				if (this.configuration.drawHandles) {
					drawHandles(context, evt.detail, data.handles, handleOptions);
				}

				let text = textBoxText(data);

				if (text === '') text = ' ';

				// Draw the text
				let textCoords;
				const padding = 5;
				const textWidth = textBoxWidth(context, text, padding);
				const textHeight = csTools.textStyle.getFontSize() + 10;

				if (text && text !== '') {
					// Calculate the text coordinates.

					let distance = Math.max(textWidth, textHeight) / 2 + 5;

					if (handleEndCanvas.x < handleStartCanvas.x) {
						distance = -distance;
					}

					if (!data.handles.textBox.hasMoved) {


						if (this.configuration.arrowFirst) {
							textCoords = {
								x: handleEndCanvas.x - textWidth / 2 + distance,
								y: handleEndCanvas.y - textHeight / 2,
							};
						} else {
							// If the arrow is at the End position, the text should
							// Be placed near the Start position
							textCoords = {
								x: handleStartCanvas.x - textWidth / 2 - distance,
								y: handleStartCanvas.y - textHeight / 2,
							};
						}

						const transform = external.cornerstone.internal.getTransform(
							enabledElement
						);

						transform.invert();

						const coords = transform.transformPoint(textCoords.x, textCoords.y);

						data.handles.textBox.x = coords.x;
						data.handles.textBox.y = coords.y;
					}

					drawLinkedTextBox(
						context,
						element,
						data.handles.textBox,
						text,
						data.handles,
						textBoxAnchorPoints,
						color,
						lineWidth,
						0,
						false
					);

					const boundingBox = data?.handles?.textBox?.boundingBox;
					if (boundingBox) {
						boundingBox.width = boundingBox.width + 20;
						data.handles.textBox.boundingBox = boundingBox;
					}

					context.filter = data.active ? this.svgFilterActive : (this.selectedToolstateIndex === i ? this.svgFilterSelected : this.svgFilter);

					if (boundingBox && data?.handles?.textBox?.active && this.img) {
						context.drawImage(this.img, boundingBox.left + textWidth, boundingBox.top + 8);
					}

				}

			});
		}

		function textBoxText(data) {
			return data.text;
		}

		function textBoxAnchorPoints(handles) {
			const midpoint = {
				x: (handles.start.x + handles.end.x) / 2,
				y: (handles.start.y + handles.end.y) / 2,
			};

			return [handles.start, midpoint, handles.end];
		}
	}

	restore(element, data) {
		var xs = data.handles.start.x;
		var ys = data.handles.start.y;
		var xe = data.handles.end.x;
		var ye = data.handles.end.y;
		let measurement = this.createNewMeasurement(
			{
				detail: {
					currentPoints: { image: { x: xs, y: ys }, end: { x: xe, y: ye } },
					viewport: { rotation: undefined },
					element: element
				}
			});

		measurement.handles.end.x = data.handles.end.x;
		measurement.handles.end.y = data.handles.end.y;
		measurement.text = data.text;
		measurement.handles.textBox = JSON.parse(JSON.stringify(data.handles.textBox));

		return measurement;
	} // restore

	store(toolData) {
		toolData.storeToolData = {
			"handles": toolData.handles,
			"text": toolData.text
		}
	}

} // RestorableArrowAnnotateTool
