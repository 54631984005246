import { parse, format } from 'date-fns';
import cornerstone from 'cornerstone-core';

export function formatPN(name: string) {
    if (!name) {
        return;
    }
    // Convert the first ^ to a ', '. String.replace() only affects
    // the first appearance of the character.
    const commaBetweenFirstAndLast = name.replace('^', ', ');

    // Replace any remaining '^' characters with spaces
    const cleaned = commaBetweenFirstAndLast.replace(/\^/g, ' ');

    // Trim any extraneous whitespace
    return cleaned.trim();
}

export function formatDA(date: string, strFormat: string = 'MMM d, yyyy') {
    if (!date) {
        return;
    }
    // Goal: 'Apr 5, 1999'
    try {
        const parsedDateTime = parse(date, 'yyyyMMdd', new Date());
        const formattedDateTime = format(parsedDateTime, strFormat);

        return formattedDateTime;
    } catch (err) {
        // swallow?
    }
    return;
}

export function formatTM(time: number, strFormat: string = 'HH:mm:ss') {
    if (!time) {
        return;
    }

    // DICOM Time is stored as HHmmss.SSS, where:
    //      HH 24 hour time:
    //      m mm    0..59   Minutes
    //      s ss    0..59   Seconds
    //      S SS SSS    0..999  Fractional seconds
    //
    // Goal: '24:12:12'
    try {
        const inputFormat = 'HHmmss.SSS';
        const strTime = time.toString().substring(0, inputFormat.length);
        const parsedDateTime = parse(strTime, inputFormat.substring(0, strTime.length), new Date(0));
        const formattedDateTime = format(parsedDateTime, strFormat);

        return formattedDateTime;
    } catch (err) {
        // swallow?
    }

    return;
}

export function formatNumberPrecision(number: string, precision: number) {
    if (number !== null) {
        return parseFloat(number).toFixed(precision);
    }
}

export function isValidNumber(value: number) {
    return typeof value === 'number' && !isNaN(value);
}

export function getCompression(imageId: string): string {
    const generalImageModule =
        cornerstone.metaData.get('generalImageModule', imageId) || {};
    const {
        lossyImageCompression,
        lossyImageCompressionRatio,
        lossyImageCompressionMethod,
    } = generalImageModule;

    if (lossyImageCompression === '01' && lossyImageCompressionRatio !== '') {
        const compressionMethod = lossyImageCompressionMethod || 'Lossy: ';
        const compressionRatio = formatNumberPrecision(
            lossyImageCompressionRatio,
            2
        );
        return compressionMethod + compressionRatio + ' : 1';
    }

    return 'Lossless / Uncompressed';
}
