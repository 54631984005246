import { createSlice } from "@reduxjs/toolkit";
import { Constants } from "../../Constants";
import { RootState } from "../../store";

export enum messageSeverity {
    info,
    warning,
    error,
}

export type message = {
    timestamp: string;
    severity: messageSeverity;
    text: string;
};

export interface IMessages {
  messages: message[];
  isMessageHistoryVisible: boolean;
}

const initialState: IMessages = {
    messages: [],
  isMessageHistoryVisible: false,
};

const MessageSlice = createSlice({
  name: "Message",
  initialState,
  reducers: {
    setMessage(state, action) {
      const message: message = action.payload;
      state.messages.push(message);
      state.messages.sort((a, b) => (a.timestamp < b.timestamp) ? 1 : (b.timestamp < a.timestamp) ? -1 : 0);
      if(state.messages.length > Constants.MAX_MESSAGES) {
        state.messages.pop();
      }
      console.error(message);
    },
    setMessageHistoryVisible(state, action) {
      const isMessageHistoryVisible: boolean = action.payload;
      state.isMessageHistoryVisible = isMessageHistoryVisible;
    },
  },
});

export const { setMessage, setMessageHistoryVisible } = MessageSlice.actions;
export default MessageSlice.reducer;

export const selectMessage = (state: RootState) => {return state.Message.messages.length > 0 ? state.Message.messages[0] : undefined};
export const selectMessages = (state: RootState) => state.Message.messages;

export const selectIsMessageHistoryVisible = (state: RootState) => state.Message.isMessageHistoryVisible;
