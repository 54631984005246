import cornerstone from "cornerstone-core";
import * as cornerstoneTools from "cornerstone-tools";
import csTools from 'cornerstone-tools';
import { Constants } from "../../../Constants";
import { lmin } from '../PipeWallThicknessHist';
import Color1 from "color";
const clip = csTools.importInternal('util/clip');
const getLuminance = csTools.importInternal('util/getLuminance');
const moveAllHandles = cornerstoneTools.import("manipulators/moveAllHandles");

const getStoredPixels = (image, x, y, width, height) => {
    if (image === undefined) {
        throw new Error('getStoredPixels: parameter image must not be undefined');
    }

    x = Math.round(x);
    y = Math.round(y);

    const storedPixels = [];
    let index = 0;
    const pixelData = image.getPixelData();

    for (let row = 0; row < height; row++) {
        for (let column = 0; column < width; column++) {
            const spIndex = ((row + y) * image.columns) + (column + x);

            storedPixels[index++] = pixelData[spIndex];
        }
    }

    return storedPixels;
}

const getPixels = (element, image, x, y, width, height) => {
    const storedPixels = getStoredPixels(image, x, y, width, height);
    const ee = cornerstone.getEnabledElement(element);
    const mlutfn = getModalityLUT(image.slope, image.intercept, ee.viewport.modalityLUT);

    return storedPixels.map(mlutfn);
}

function generateLinearModalityLUT(slope, intercept) {
    return (storedPixelValue) => storedPixelValue * slope + intercept;
}

function generateNonLinearModalityLUT(modalityLUT) {
    const minValue = modalityLUT.lut[0];
    const maxValue = modalityLUT.lut[modalityLUT.lut.length - 1];
    const maxValueMapped = modalityLUT.firstValueMapped + modalityLUT.lut.length;

    return (storedPixelValue) => {
        if (storedPixelValue < modalityLUT.firstValueMapped) {
            return minValue;
        } else if (storedPixelValue >= maxValueMapped) {
            return maxValue;
        }

        return modalityLUT.lut[storedPixelValue];
    };
}

/**
 * Get the appropriate Modality LUT for the current situation.
 *
 * @param {Number} [slope] m in the equation specified by Rescale Intercept (0028,1052).
 * @param {Number} [intercept] The value b in relationship between stored values (SV) and the output units specified in Rescale Type (0028,1054).
 * @param {Function} [modalityLUT] A modality LUT function. Given a stored pixel it returns the modality pixel value.
 *
 * @return {function(*): *} A modality LUT function. Given a stored pixel it returns the modality pixel value.
 * @memberof Internal
 */
function getModalityLUT(slope, intercept, modalityLUT) {
    if (modalityLUT) {
        return generateNonLinearModalityLUT(modalityLUT);
    }

    return generateLinearModalityLUT(slope, intercept);
}

function calculateEllipseStatistics(sp, ellipse) {
    let sum = 0;
    let sumSquared = 0;
    let count = 0;
    let index = 0;
    let min = null;
    let max = null;

    for (let y = ellipse.top; y < ellipse.top + ellipse.height; y++) {
        for (let x = ellipse.left; x < ellipse.left + ellipse.width; x++) {
            const point = {
                x,
                y,
            };

            if (pointInEllipse(ellipse, point)) {
                if (min === null) {
                    min = sp[index];
                    max = sp[index];
                }

                sum += sp[index];
                sumSquared += sp[index] * sp[index];
                min = Math.min(min, sp[index]);
                max = Math.max(max, sp[index]);
                count++;
            }

            index++;
        }
    }

    if (count === 0) {
        return {
            count,
            mean: 0.0,
            variance: 0.0,
            stdDev: 0.0,
            min: 0.0,
            max: 0.0,
        };
    }

    const mean = sum / count;
    const variance = sumSquared / count - mean * mean;

    return {
        count,
        mean,
        variance,
        stdDev: Math.sqrt(variance),
        min,
        max,
    };
}

function pointInEllipse(ellipse, location) {
    const xRadius = ellipse.width / 2;
    const yRadius = ellipse.height / 2;

    if (xRadius <= 0.0 || yRadius <= 0.0) {
        return false;
    }

    const center = {
        x: ellipse.left + xRadius,
        y: ellipse.top + yRadius,
    };

    /* This is a more general form of the circle equation
     *
     * X^2/a^2 + Y^2/b^2 <= 1
     */

    const normalized = {
        x: location.x - center.x,
        y: location.y - center.y,
    };

    const inEllipse =
        (normalized.x * normalized.x) / (xRadius * xRadius) +
        (normalized.y * normalized.y) / (yRadius * yRadius) <=
        1.0;

    return inEllipse;
}

function calculateFreehandStatistics(sp, boundingBox, dataHandles) {
    const statisticsObj = {
        count: 0,
        mean: 0.0,
        variance: 0.0,
        stdDev: 0.0,
    };

    const sum = getSum(sp, boundingBox, dataHandles);

    if (sum.count === 0) {
        return statisticsObj;
    }

    statisticsObj.count = sum.count;
    statisticsObj.mean = sum.value / sum.count;
    statisticsObj.variance =
        sum.squared / sum.count - statisticsObj.mean * statisticsObj.mean;
    statisticsObj.stdDev = Math.sqrt(statisticsObj.variance);

    return statisticsObj;
}

/**
 * Calculates the sum, squared sum and count of all pixels within the polygon.
 * @private
 * @method
 * @name getSum
 *
 * @param {Object} sp An array of the pixel data.
 * @param {Object} boundingBox Rectangular box enclosing the polygon.
 * @param {Object} dataHandles Data object associated with the tool.
 * @returns {Object} Object containing the sum, squared sum and pixel count.
 */
function getSum(sp, boundingBox, dataHandles) {
    const sum = {
        value: 0,
        squared: 0,
        count: 0,
    };
    let index = 0;

    for (let y = boundingBox.top; y < boundingBox.top + boundingBox.height; y++) {
        for (
            let x = boundingBox.left;
            x < boundingBox.left + boundingBox.width;
            x++
        ) {
            const point = {
                x,
                y,
            };

            sumPointIfInFreehand(dataHandles, point, sum, sp[index]);
            index++;
        }
    }

    return sum;
}

/**
 * Adds the pixel to the workingSum if it is within the polygon.
 * @private
 * @method sumPointIfInFreehand
 *
 * @param {Object} dataHandles Data object associated with the tool.
 * @param {Object} point The pixel coordinates.
 * @param {Object} workingSum The working sum, squared sum and pixel count.
 * @param {Object} pixelValue The pixel value. // @modifies {workingSum}
 * @returns {undefined}
 */
function sumPointIfInFreehand(dataHandles, point, workingSum, pixelValue) {
    if (pointInFreehand(dataHandles, point)) {
        workingSum.value += pixelValue;
        workingSum.squared += pixelValue * pixelValue;
        workingSum.count++;
    }
}

function pointInFreehand(dataHandles, location) {
    let inROI = false;

    // Cycle round pairs of points
    let j = dataHandles.length - 1; // The last vertex is the previous one to the first

    for (let i = 0; i < dataHandles.length; i++) {
        if (rayFromPointCrosssesLine(location, dataHandles[i], dataHandles[j])) {
            inROI = !inROI;
        }

        j = i; // Here j is previous vertex to i
    }

    return inROI;
}

/**
 * Returns true if the y-position yp is enclosed within y-positions y1 and y2.
 * @private
 * @method
 * @name isEnclosedY
 *
 * @param {number} yp The y position of point p.
 * @param {number} y1 The y position of point 1.
 * @param {number} y2 The y position of point 2.
 * @returns {boolean} True if the y-position yp is enclosed within y-positions y1 and y2.
 */
function isEnclosedY(yp, y1, y2) {
    if ((y1 < yp && yp < y2) || (y2 < yp && yp < y1)) {
        return true;
    }

    return false;
}

/**
 * Returns true if the line segment is to the right of the point.
 * @private
 * @method
 * @name isLineRightOfPoint
 *
 * @param {Object} point The point being queried.
 * @param {Object} lp1 The first point of the line segment.
 * @param {Object} lp2 The second point of the line segment.
 * @returns {boolean} True if the line is to the right of the point.
 */
function isLineRightOfPoint(point, lp1, lp2) {
    // If both right of point return true
    if (lp1.x > point.x && lp2.x > point.x) {
        return true;
    }

    // Catch when line is vertical.
    if (lp1.x === lp2.x) {
        return point.x < lp1.x;
    }

    // Put leftmost point in lp1
    if (lp1.x > lp2.x) {
        const lptemp = lp1;

        lp1 = lp2;
        lp2 = lptemp;
    }
    const lPointY = lineSegmentAtPoint(point, lp1, lp2);

    // If the lp1.x and lp2.x enclose point.x check gradient of line and see if
    // Point is above or below the line to calculate if it inside.
    if (
        Math.sign(lPointY.gradient) * point.y >
        Math.sign(lPointY.gradient) * lPointY.value
    ) {
        return true;
    }

    return false;
}

/**
 * Returns the y value of the line segment at the x value of the point.
 * @private
 * @method
 * @name lineSegmentAtPoint
 *
 * @param {Object} point The point being queried.
 * @param {Object} lp1 The first point of the line segment.
 * @param {Object} lp2 The second point of the line segment.
 * @returns {Object} An object containing the y value as well as the gradient of the line segment.
 */
function lineSegmentAtPoint(point, lp1, lp2) {
    const dydx = (lp2.y - lp1.y) / (lp2.x - lp1.x);
    const fx = {
        value: lp1.y + dydx * (point.x - lp1.x),
        gradient: dydx,
    };

    return fx;
}

/**
 * Returns true if a rightwards ray originating from the point crosses the line defined by handleI and handleJ.
 * @private
 * @method
 * @name rayFromPointCrosssesLine
 *
 * @param {Object} point The point being queried.
 * @param {Object} handleI The first handle of the line segment.
 * @param {Object} handleJ The second handle of the line segment.
 * @returns {boolean} True if a rightwards ray originating from the point crosses the line defined by handleI and handleJ.
 */
function rayFromPointCrosssesLine(point, handleI, handleJ) {
    if (
        isEnclosedY(point.y, handleI.y, handleJ.y) &&
        isLineRightOfPoint(point, handleI, handleJ)
    ) {
        return true;
    }

    return false;
}

function freehandArea(dataHandles, scaling) {
    let freeHandArea = 0;
    let j = dataHandles.length - 1; // The last vertex is the previous one to the first

    scaling = scaling || 1; // If scaling is falsy, set scaling to 1

    for (let i = 0; i < dataHandles.length; i++) {
        freeHandArea +=
            (dataHandles[j].x + dataHandles[i].x) *
            (dataHandles[j].y - dataHandles[i].y);
        j = i; // Here j is previous vertex to i
    }

    return Math.abs((freeHandArea * scaling) / 2.0);
}

const applyWWWCRegion = function (image, viewport, element, startPoint, endPoint, config) {

    // Get the rectangular region defined by the handles
    let left = Math.min(startPoint.x, endPoint.x);
    let top = Math.min(startPoint.y, endPoint.y);
    let width = Math.abs(startPoint.x - endPoint.x);
    let height = Math.abs(startPoint.y - endPoint.y);

    // Bound the rectangle so we don't get undefined pixels
    left = clip(left, 0, image.width);
    top = clip(top, 0, image.height);
    width = Math.floor(Math.min(width, Math.abs(image.width - left)));
    height = Math.floor(Math.min(height, Math.abs(image.height - top)));

    // Get the pixel data in the rectangular region
    const pixelLuminanceData = getLuminance(element, left, top, width, height);

    // Calculate the minimum and maximum pixel values
    const minMaxMean = _calculateMinMaxMean(
        pixelLuminanceData,
        image.minPixelValue,
        image.maxPixelValue
    );

    // Adjust the viewport window width and center based on the calculated values
    //const viewport = eventData.viewport;

    if (config.minWindowWidth === undefined) {
        config.minWindowWidth = 10;
    }

    viewport.voi.windowWidth = Math.max(
        Math.abs(minMaxMean.max - minMaxMean.min),
        config.minWindowWidth
    );
    viewport.voi.windowCenter = minMaxMean.mean;

    // Unset any existing VOI LUT
    viewport.voiLUT = undefined;

    cornerstone.setViewport(element, viewport);
    //cornerstone.updateImage(element);
};

const getWWWCRegion = function (image, element, startPoint, endPoint, config) {

    let wwwc = { windowWidth: 0, windowCenter: 0 };

    // Get the rectangular region defined by the handles
    let left = Math.min(startPoint.x, endPoint.x);
    let top = Math.min(startPoint.y, endPoint.y);
    let width = Math.abs(startPoint.x - endPoint.x);
    let height = Math.abs(startPoint.y - endPoint.y);

    // Bound the rectangle so we don't get undefined pixels
    left = clip(left, 0, image.width);
    top = clip(top, 0, image.height);
    width = Math.floor(Math.min(width, Math.abs(image.width - left)));
    height = Math.floor(Math.min(height, Math.abs(image.height - top)));

    // Get the pixel data in the rectangular region
    const pixelLuminanceData = getLuminance(element, left, top, width, height);

    // Calculate the minimum and maximum pixel values
    const minMaxMean = _calculateMinMaxMean(
        pixelLuminanceData,
        image.minPixelValue,
        image.maxPixelValue
    );

    // Adjust the viewport window width and center based on the calculated values
    //const viewport = eventData.viewport;

    if (config.minWindowWidth === undefined) {
        config.minWindowWidth = 10;
    }

    wwwc.windowWidth = Math.max(
        Math.abs(minMaxMean.max - minMaxMean.min),
        config.minWindowWidth
    );
    wwwc.windowCenter = minMaxMean.mean;

    return wwwc;

};

/**
 * Calculates the minimum, maximum, and mean value in the given pixel array
 *
 * @private
 * @method _calculateMinMaxMean
 * @param {number[]} pixelLuminance array of pixel luminance values
 * @param {number} globalMin starting "min" valie
 * @param {number} globalMax starting "max" value
 * @returns {Object} {min: number, max: number, mean: number }
 */
const _calculateMinMaxMean = function (pixelLuminance, globalMin, globalMax) {
    const numPixels = pixelLuminance.length;
    let min = globalMax;
    let max = globalMin;
    let sum = 0;

    if (numPixels < 2) {
        return {
            min,
            max,
            mean: (globalMin + globalMax) / 2,
        };
    }

    for (let index = 0; index < numPixels; index++) {
        const spv = pixelLuminance[index];

        min = Math.min(min, spv);
        max = Math.max(max, spv);
        sum += spv;
    }

    return {
        min,
        max,
        mean: sum / numPixels,
    };
};

const calculateMinMaxMeanVar = function (pixelLuminance, globalMin, globalMax) {
    const numPixels = pixelLuminance.length;
    let min = globalMax;
    let max = globalMin;
    let sum = 0;
    let sumSquare = 0;

    if (numPixels < 2) {
        return {
            min,
            max,
            mean: (globalMin + globalMax) / 2,
        };
    }

    for (let index = 0; index < numPixels; index++) {
        const spv = pixelLuminance[index];

        min = Math.min(min, spv);
        max = Math.max(max, spv);
        sum += spv;
        sumSquare += spv * spv;
    }

    return {
        min,
        max,
        mean: sum / numPixels,
        standardDeviation: Math.sqrt(Math.abs(sumSquare / numPixels - ((sum * sum) / (numPixels * numPixels))))
    };
};

function getWindowWidthFromMinMaxMeanVar(minMaxMean) {
    //return Math.max(Math.abs(minMaxMean.max - minMaxMean.min), 10),
    return Math.max(3 * minMaxMean.standardDeviation, 10);
}

function setToolCursor(element, svgCursor) {

    if (!csTools.getModule('globalConfiguration').configuration.showSVGCursors) {
        return;
    }
    // TODO: (state vs options) Exit if cursor wasn't updated
    // TODO: Exit if invalid options to create cursor

    // Note: Max size of an SVG cursor is 128x128, default is 32x32.
    const cursorBlob = svgCursor.getIconWithPointerSVG();
    const mousePoint = svgCursor.mousePoint;

    const svgCursorUrl = window.URL.createObjectURL(cursorBlob);

    element.style.cursor = `url('${svgCursorUrl}') ${mousePoint}, auto`;

    csTools.store.state.svgCursorUrl = svgCursorUrl;
}

function hideToolCursor(element) {
    if (!csTools.getModule('globalConfiguration').configuration.showSVGCursors) {
        return;
    }

    _clearStateAndSetCursor(element, 'none');
}

function _clearStateAndSetCursor(element, cursorSeting) {
    if (csTools.store.state.svgCursorUrl) {
        window.URL.revokeObjectURL(csTools.store.state.svgCursorUrl);
    }

    csTools.store.state.svgCursorUrl = null;
    element.style.cursor = cursorSeting;
}

function validateParameterUndefinedOrNull(checkParam, errorMsg) {
    if (checkParam === undefined || checkParam === null) {
        throw new Error(errorMsg);
    }
}

function isRotated(rotation) {
    return !(rotation === null || rotation === undefined || rotation === 0 || rotation === 180);
}

function getImageSize(image, rotation = null) {

    validateParameterUndefinedOrNull(image, 'getImageSize: parameter image must not be undefined');
    validateParameterUndefinedOrNull(image.width, 'getImageSize: parameter image must have width');
    validateParameterUndefinedOrNull(image.height, 'getImageSize: parameter image must have height');

    if (image.cutwidth && image.cutheight) {
        if (isRotated(rotation)) {
            const rotationRad = rotation * Math.PI / 180.0;
            return {
                width: image.cutwidth * Math.abs(Math.cos(rotationRad)) + image.cutheight * Math.abs(Math.sin(rotationRad)),
                height: image.cutheight * Math.abs(Math.cos(rotationRad)) + image.cutwidth * Math.abs(Math.sin(rotationRad))
            };
        }
        return {
            width: image.cutwidth,
            height: image.cutheight
        };
    }

    if (isRotated(rotation)) {
        const rotationRad = rotation * Math.PI / 180.0;
        return {
            width: image.width * Math.abs(Math.cos(rotationRad)) + image.height * Math.abs(Math.sin(rotationRad)),
            height: image.height * Math.abs(Math.cos(rotationRad)) + image.width * Math.abs(Math.sin(rotationRad))
        };
    }

    return {
        width: image.width,
        height: image.height
    };
}

function getImageFitScale(windowSize, image, rotation = null) {

    validateParameterUndefinedOrNull(windowSize, 'getImageScale: parameter windowSize must not be undefined');
    validateParameterUndefinedOrNull(image, 'getImageScale: parameter image must not be undefined');

    const imageSize = getImageSize(image, rotation);
    const rowPixelSpacing = image.rowPixelSpacing || 1;
    const columnPixelSpacing = image.columnPixelSpacing || 1;
    let verticalRatio = 1;
    let horizontalRatio = 1;

    if (rowPixelSpacing < columnPixelSpacing) {
        horizontalRatio = columnPixelSpacing / rowPixelSpacing;
    } else {
        // even if they are equal we want to calculate this ratio (the ration might be 0.5)
        verticalRatio = rowPixelSpacing / columnPixelSpacing;
    }

    const verticalScale = windowSize.height / imageSize.height / verticalRatio;
    const horizontalScale = windowSize.width / imageSize.width / horizontalRatio;

    // Fit image to window
    return {
        verticalScale,
        horizontalScale,
        scaleFactor: Math.min(horizontalScale, verticalScale)
    };
}

const fitToWindow = (element) => {
    const enabledElement = cornerstone.getEnabledElement(element);
    const { image } = enabledElement;

    // The new scale is the minimum of the horizontal and vertical scale values
    enabledElement.viewport.scale = getImageFitScale(enabledElement.canvas, image, enabledElement.viewport.rotation).scaleFactor;

    if (image.cutx && image.cuty) {
        let rotation = enabledElement.viewport.rotation;
        if (rotation && rotation !== 0.0) {
            const rotationRad = rotation * Math.PI / 180.0;
            let tx = (image.width / 2 - image.cutx), ty = (image.height / 2 - image.cuty);
            if (enabledElement.viewport.hflip)
                tx = image.width / 2 - (image.width - image.cutx);
            if (enabledElement.viewport.vflip)
                ty = image.height / 2 - (image.height - image.cuty);
            enabledElement.viewport.translation.x = (tx * Math.cos(rotationRad) - ty * Math.sin(rotationRad));
            enabledElement.viewport.translation.y = (ty * Math.cos(rotationRad) + tx * Math.sin(rotationRad));
        } else {
            let tx = image.width / 2 - image.cutx, ty = image.height / 2 - image.cuty;
            if (enabledElement.viewport.hflip)
                tx = image.width / 2 - (image.width - image.cutx);
            if (enabledElement.viewport.vflip)
                ty = image.height / 2 - (image.height - image.cuty);
            enabledElement.viewport.translation = { x: tx, y: ty };
        }
    } else {
        enabledElement.viewport.translation.x = 0;
        enabledElement.viewport.translation.y = 0;
    }
    cornerstone.updateImage(element);
}




/**
 * Attempts to sanitize a value by casting as a number; if unable to cast,
 * we return `undefined`
 *
 * @param {*} value
 * @returns a number or undefined
 */
function sanitizeMeasuredValue(value) {
    const parsedValue = Number(value);
    const isNumber = !isNaN(parsedValue);

    return isNumber ? parsedValue : undefined;
}


function lineValues(pixelData, columns, x0, y0, x1, y1) {
    var dx = Math.abs(x1 - x0);
    var dy = Math.abs(y1 - y0);
    var sx = (x0 < x1) ? 1 : -1;
    var sy = (y0 < y1) ? 1 : -1;
    var err = dx - dy;

    const linedata = [];
    let index = 0;
    let max = Number.MIN_SAFE_INTEGER;
    let min = Number.MAX_SAFE_INTEGER;

    while (true) {
        const spIndex = (y0 * columns) + x0;
        const data = pixelData[spIndex];
        if (data > max) {
            max = data;
        }
        if (data < min) {
            min = data;
        }
        linedata[index++] = data;

        if ((x0 === x1) && (y0 === y1)) break;
        var e2 = 2 * err;
        if (e2 > -dy) { err -= dy; x0 += sx; };
        if (e2 < dx) { err += dx; y0 += sy; };
    }

    return { linedata: linedata, max: max,  min: min};
}

function meanValues(pixelData, columns, x_in0, y_in0, x_in1, y_in1, h) {
    const linedataRect = getCornerPoints({ x: x_in0, y: y_in0 }, { x: x_in1, y: y_in1 }, h);
    const ar1 = line(Math.round(linedataRect[3].x), Math.round(linedataRect[3].y), Math.round(linedataRect[2].x), Math.round(linedataRect[2].y));
    const ar2 = line(Math.round(linedataRect[0].x), Math.round(linedataRect[0].y), Math.round(linedataRect[1].x), Math.round(linedataRect[1].y));

    const linedata = [];

    let max = Number.MIN_SAFE_INTEGER;
    let min = Number.MAX_SAFE_INTEGER;

    for (let i = 0; i < Math.min(ar1.length, ar2.length); i++) {
        let x0 = Math.round(ar1[i].x);
        let y0 = Math.round(ar1[i].y);
        let x1 = Math.round(ar2[i].x);
        let y1 = Math.round(ar2[i].y);

        const dx = Math.abs(x1 - x0);
        const dy = Math.abs(y1 - y0);
        const sx = (x0 < x1) ? 1 : -1;
        const sy = (y0 < y1) ? 1 : -1;
        let err = dx - dy;

        let index = 0;
        let sumData = 0;

        while (true) {
            const spIndex = (y0 * columns) + x0;
            sumData += pixelData[spIndex];
            if ((x0 === x1) && (y0 === y1)) break;
            var e2 = 2 * err;
            if (e2 > -dy) { err -= dy; x0 += sx; };
            if (e2 < dx) { err += dx; y0 += sy; };
            //console.log(x0 + " " + y0 + " " + x1 + " " + y1);
            index++;
        }
        const data = sumData / (index + 1);
        linedata[i] = data;
        if (data > max) {
            max = data;
        }
        if (data < min) {
            min = data;
        }
    }

    return { linedata: linedata, max: max, min: min };
}

function line(x0, y0, x1, y1) {
    var dx = Math.abs(x1 - x0);
    var dy = Math.abs(y1 - y0);
    var sx = (x0 < x1) ? 1 : -1;
    var sy = (y0 < y1) ? 1 : -1;
    var err = dx - dy;

    const linexy = [];

    let index = 0;
    while (true) {
        linexy[index++] = { x: x0, y: y0 };
        if ((x0 === x1) && (y0 === y1)) break;
        var e2 = 2 * err;
        if (e2 > -dy) { err -= dy; x0 += sx; };
        if (e2 < dx) { err += dx; y0 += sy; };
    }
    return linexy;
}

function getCornerPoints(p1, p2, height) {
    const w = distance(p1, p2);
    const linevec = { x: (p2.x - p1.x) / w, y: (p2.y - p1.y) / w };
    const ortho = { x: -linevec.y, y: linevec.x }

    // create points for polygon
    const pa = { x: p1.x + ((height / 2.0) * ortho.x), y: p1.y + ((height / 2.0) * ortho.y) };
    const pb = { x: pa.x + (w * linevec.x), y: pa.y + (w * linevec.y) };
    const pd = { x: p1.x - ((height / 2.0) * ortho.x), y: p1.y - ((height / 2.0) * ortho.y) };
    const pc = { x: pd.x + (w * linevec.x), y: pd.y + (w * linevec.y) };

    return [pa, pb, pc, pd];
}

function distance(p1, p2) {
    const dx = p1.x - p2.x;
    const dy = p1.y - p2.y;
    return Math.sqrt(dx * dx + dy * dy);
}

function getLuminanceData(isColor, pixelData, width, height) {
    let luminance = [];
    let index = 0;

    let spIndex, row, column;

    if (isColor) {
        for (row = 0; row < height; row++) {
            for (column = 0; column < width; column++) {
                spIndex = ((row) * width + (column)) * 4;
                const red = pixelData[spIndex];
                const green = pixelData[spIndex + 1];
                const blue = pixelData[spIndex + 2];

                luminance[index++] = 0.2126 * red + 0.7152 * green + 0.0722 * blue;
            }
        }
    } else {
        luminance = pixelData;
    }

    return luminance;
}

function calcProfile(image, data, doWallThicknessCalculation, isRawImage = false, scaleFactor = undefined) {

    const x0 = Math.round(data.handles.start.x);
    const y0 = Math.round(data.handles.start.y);
    const x1 = Math.round(data.handles.end.x);
    const y1 = Math.round(data.handles.end.y);

    if (image) {

        const dx1 = (x1 - x0);
        const dy1 = (y1 - y0);
        const l = Math.sqrt(dx1 * dx1 + dy1 * dy1);

        if (l > lmin) {
            const currentImage = image.image_raw ? image.image_raw : image;
            //const pixelData = currentImage.getPixelData();
            const pixelData = getLuminanceData(currentImage.color, currentImage.getPixelData(), currentImage.width, currentImage.height)

            const columns = currentImage.columns;
            let linedata;

            if (data?.lineWidth > 1) {
                const linedata_tmp = meanValues(pixelData, columns, data.handles.start.x, data.handles.start.y, data.handles.end.x, data.handles.end.y, data?.lineWidth ?? 1);
                //linedata = { linedata: linedata_tmp?.linedata?.map((value, i) => { return { x: i, y: value } }), max: linedata_tmp?.max };
                linedata = { linedata: linedata_tmp?.linedata, max: linedata_tmp?.max, min: linedata_tmp?.min };
            } else {
                const linedata_tmp = lineValues(pixelData, columns, x0, y0, x1, y1);
                //linedata = { linedata: linedata_tmp?.linedata?.map((value, i) => { return { x: i , y: value } }), max: linedata_tmp?.max };
                linedata = { linedata: linedata_tmp?.linedata, max: linedata_tmp?.max, min: linedata_tmp?.min};
            }
            data.linedata = linedata.linedata;
            data.max = linedata.max;
            data.min = linedata.min;
        }

        if (doWallThicknessCalculation) {

            if (data.linedata) {
                const resDiff = diff(data, isRawImage);
                let thickness = 0;
                if (data.autoCalc) {
                    thickness = geometryCorr(Math.abs((isRawImage ? resDiff?.xmin : resDiff?.xmax) - resDiff?.xdelta), data?.spd, data?.sdd, data?.radius,
                        data?.useReferenceObject, scaleFactor);
                    data.wallEnd = isRawImage ? resDiff?.xmin : resDiff?.xmax;
                    data.wallStart = resDiff?.xdelta;
                    const inversScaleFactor = data?.linedata?.length / (data?.length * data?.scale);
                    data.xlines = [data?.wallStart * inversScaleFactor, data?.wallEnd * inversScaleFactor];
                } else if (data.xlines && data.xlines.length > 1) {
                    thickness = geometryCorr(Math.abs(data.xlines[1] - data.xlines[0]) * data?.scale, data?.spd, data?.sdd, data?.radius,
                        data?.useReferenceObject, scaleFactor);
                } else if (data.xlines && data.xlines.length === 1) {
                    thickness = geometryCorr((data.xMarker - data.xlines[0]) * data?.scale, data?.spd, data?.sdd, data?.radius,
                        data?.useReferenceObject, scaleFactor);
                }
                data.wallThickness = thickness;
                data.min1 = resDiff?.min1;
                data.max1 = resDiff?.max1;
            }
        }
    }
}


function geometryCorr(meas, spd_string, sdd_string, r_string, useReferenceObject, scaleFactor) {
    if (useReferenceObject) {
        if (scaleFactor !== undefined && scaleFactor > 0) {
            return meas * scaleFactor;
        } else {
            return undefined;
        }
    } else {
        if (spd_string && spd_string.length > 0 && Number(spd_string) > 0 && sdd_string && sdd_string.length > 0 && Number(sdd_string) > 0) {
            const spd = Number(spd_string);
            const sdd = Number(sdd_string);
            if (r_string && r_string.length > 0 && Number(r_string)) {
                const r = Number(r_string);
                const fac1 = r / Math.sqrt(spd * spd - r * r);
                const numerator = spd * (fac1 - meas / sdd);
                const denumerator = Math.sqrt(1.0 + (fac1 - meas / sdd) ** 2);
                return Math.abs(r - numerator / denumerator);
            } else {
                return Math.abs(meas * spd / sdd);
            }
        } else {
            return undefined;
        }
    }
}


function diff(data, isRaw) {
    let ret = [];
    let x = 0;
    let y = 0;
    let min = Number.MAX_SAFE_INTEGER;
    let max = Number.MIN_SAFE_INTEGER;
    let min1 = Number.MAX_SAFE_INTEGER;
    let max1 = Number.MIN_SAFE_INTEGER;
    let ext1 = Number.MIN_SAFE_INTEGER;
    let xmin = 0;
    let xmax = 0;
    let xext1 = 0;
    let xmax1 = 0;
    let ext1y = 0;
    let sign = 1;

    const scaleFactor = data?.length * data?.scale / data?.linedata.length;

    for (let i = 1; i < data?.linedata.length - 1; ++i) {
        x = (i + 0.5) * scaleFactor;
        y = (data?.linedata[i + 1] - data?.linedata[i - 1]) / (((i + 1) * scaleFactor - (i - 1) * scaleFactor));
        if (y > max1) {
            max1 = y;
            xmax1 = x;
        }
        if (y < min1) {
            min1 = y;
        }
        if (Math.abs(y) > ext1) {
            ext1 = Math.abs(y);
            xext1 = x;
            ext1y = data?.linedata[i];
            sign = Math.sign(y)
        }
        if (data?.linedata[i] < min) {
            min = data?.linedata[i];
            xmin = i * scaleFactor;
        }
        if (data?.linedata[i] > max) {
            max = data?.linedata[i];
            xmax = i * scaleFactor;
        }
        ret.push({ x, y });
    }

    const delta = ((isRaw ? max : min) - ext1y) / ext1;
    data.linedatad = ret;
    return { min, max, xmin, xmax, min1: min1, max1, xmin1: xext1, xmax1, xdelta: xext1 + sign * delta };
}

/**
* Communicate toolstate
* toolstateIndex: index of toolstate in toolstate array;
* itemsIn: properties to set in toolstate.
* itemsOut: properties to read from toolstate.
* matrixIndex: index of viewport.
* toolname: name of tool.
*/
const setGetToolStateItems = (toolstateIndex, itemsIn, itemsOut, matrixIndex, toolname) => {
    const ret = [];
    const cornerstoneElements = cornerstone.getEnabledElements();
    const displayElement = cornerstoneElements?.find(
        (element) => element.element.id === `${Constants.IMAGE_DISPLAY_GENERIC_ELEMENT_NAME}_${matrixIndex}`
    );
    if (displayElement?.element) {
        const stateManager = cornerstoneTools.getElementToolStateManager(displayElement?.element);
        const toolstate = stateManager.get(displayElement?.element, toolname);
        if (toolstate && toolstate.data && toolstateIndex >= 0 && toolstate.data.length > toolstateIndex) {
            const activeTool = cornerstoneTools.getToolForElement(displayElement?.element, toolname);
            for (let i = 0; i < itemsIn.length; i++) {
                if (typeof itemsIn[i].value === 'object') {
                    toolstate.data[toolstateIndex][itemsIn[i].name] = global.structuredClone(itemsIn[i].value);
                } else {
                    toolstate.data[toolstateIndex][itemsIn[i].name] = itemsIn[i].value;
                }
            }
            if (activeTool) {
                activeTool.updateCachedStats(displayElement.image, displayElement?.element, toolstate.data[toolstateIndex]);
                cornerstone.updateImage(displayElement?.element);
            }
            for (let i = 0; i < itemsOut.length; i++) {
                ret.push(toolstate.data[toolstateIndex][itemsOut[i]]);
            }
        }
    }
    return ret;
}

/**
* triggers storing of toolstate
* index: index of toolstate in toolstate array;
* matrixIndex: index of viewport.
* toolname: name of tool.
*/
const updateAnnnotationOnInputChange = (index, matrixIndex, toolname) => {
    const cornerstoneElements = cornerstone.getEnabledElements();
    const displayElement = cornerstoneElements?.find(
        (element) => element.element.id === `${Constants.IMAGE_DISPLAY_GENERIC_ELEMENT_NAME}_${matrixIndex}`
    );
    if (displayElement?.element) {
        const stateManager = cornerstoneTools.getElementToolStateManager(displayElement?.element);
        const toolstate = stateManager.get(displayElement.element, toolname);
        if (toolstate && toolstate.data && index != undefined && toolstate.data.length > index) {
            if (toolstate.data[index] !== undefined) {
                // @ts-ignore
                cornerstone.triggerEvent(displayElement.element, cornerstoneTools.EVENTS.MEASUREMENT_COMPLETED, { element: displayElement.element, measurementData: toolstate.data[index] });
            }
        }
    }
}

function swap16(val) {
    return ((val & 0xFF) << 8)
        | ((val >> 8) & 0xFF);
}

const moveAnnotationWithCompletedTrigger = function (
    evt,
    toolName,
    annotation,
    options,
    interactionType = 'mouse',
) {
    annotation.active = true;
    cornerstoneTools.store.isToolLocked = true;
    moveAllHandles(
        evt.detail,
        toolName,
        annotation,
        null,
        options,
        interactionType,
        () => {
            annotation.active = false;
            cornerstoneTools.store.isToolLocked = false;
            const modifiedEventData = {
                toolName: toolName,
                toolType: toolName,
                element: evt.target,
                measurementData: annotation,
            };
            cornerstone.triggerEvent(evt.target, cornerstoneTools.EVENTS.MEASUREMENT_COMPLETED, modifiedEventData);
        }
    );
    evt.stopImmediatePropagation();
    evt.stopPropagation();
    evt.preventDefault();
    return;
};

const getTargetIdsForElement = (enabledElement) => {
    let targetIds = undefined;
    // @ts-ignore
    if (enabledElement.image?.image_raw?.targetId) {
        // @ts-ignore
        targetIds = [enabledElement.image?.targetId, enabledElement.image?.image_raw?.targetId];
        // @ts-ignore
    } else if (enabledElement.image?.image_org) {
        // @ts-ignore
        targetIds = [enabledElement.image?.targetId, enabledElement.image?.image_org?.targetId];;
    } else {
        // @ts-ignore
        targetIds = [enabledElement.image?.targetId];
    }
    return targetIds;
}




class Color {
    constructor(r, g, b) {
        this.set(r, g, b);
    }

    toString() {
        return `rgb(${Math.round(this.r)}, ${Math.round(this.g)}, ${Math.round(this.b)})`;
    }

    set(r, g, b) {
        this.r = this.clamp(r);
        this.g = this.clamp(g);
        this.b = this.clamp(b);
    }

    hueRotate(angle = 0) {
        angle = angle / 180 * Math.PI;
        const sin = Math.sin(angle);
        const cos = Math.cos(angle);

        this.multiply([
            0.213 + cos * 0.787 - sin * 0.213,
            0.715 - cos * 0.715 - sin * 0.715,
            0.072 - cos * 0.072 + sin * 0.928,
            0.213 - cos * 0.213 + sin * 0.143,
            0.715 + cos * 0.285 + sin * 0.140,
            0.072 - cos * 0.072 - sin * 0.283,
            0.213 - cos * 0.213 - sin * 0.787,
            0.715 - cos * 0.715 + sin * 0.715,
            0.072 + cos * 0.928 + sin * 0.072,
        ]);
    }

    grayscale(value = 1) {
        this.multiply([
            0.2126 + 0.7874 * (1 - value),
            0.7152 - 0.7152 * (1 - value),
            0.0722 - 0.0722 * (1 - value),
            0.2126 - 0.2126 * (1 - value),
            0.7152 + 0.2848 * (1 - value),
            0.0722 - 0.0722 * (1 - value),
            0.2126 - 0.2126 * (1 - value),
            0.7152 - 0.7152 * (1 - value),
            0.0722 + 0.9278 * (1 - value),
        ]);
    }

    sepia(value = 1) {
        this.multiply([
            0.393 + 0.607 * (1 - value),
            0.769 - 0.769 * (1 - value),
            0.189 - 0.189 * (1 - value),
            0.349 - 0.349 * (1 - value),
            0.686 + 0.314 * (1 - value),
            0.168 - 0.168 * (1 - value),
            0.272 - 0.272 * (1 - value),
            0.534 - 0.534 * (1 - value),
            0.131 + 0.869 * (1 - value),
        ]);
    }

    saturate(value = 1) {
        this.multiply([
            0.213 + 0.787 * value,
            0.715 - 0.715 * value,
            0.072 - 0.072 * value,
            0.213 - 0.213 * value,
            0.715 + 0.285 * value,
            0.072 - 0.072 * value,
            0.213 - 0.213 * value,
            0.715 - 0.715 * value,
            0.072 + 0.928 * value,
        ]);
    }

    multiply(matrix) {
        const newR = this.clamp(this.r * matrix[0] + this.g * matrix[1] + this.b * matrix[2]);
        const newG = this.clamp(this.r * matrix[3] + this.g * matrix[4] + this.b * matrix[5]);
        const newB = this.clamp(this.r * matrix[6] + this.g * matrix[7] + this.b * matrix[8]);
        this.r = newR;
        this.g = newG;
        this.b = newB;
    }

    brightness(value = 1) {
        this.linear(value);
    }
    contrast(value = 1) {
        this.linear(value, -(0.5 * value) + 0.5);
    }

    linear(slope = 1, intercept = 0) {
        this.r = this.clamp(this.r * slope + intercept * 255);
        this.g = this.clamp(this.g * slope + intercept * 255);
        this.b = this.clamp(this.b * slope + intercept * 255);
    }

    invert(value = 1) {
        this.r = this.clamp((value + this.r / 255 * (1 - 2 * value)) * 255);
        this.g = this.clamp((value + this.g / 255 * (1 - 2 * value)) * 255);
        this.b = this.clamp((value + this.b / 255 * (1 - 2 * value)) * 255);
    }

    hsl() {
        // Code taken from https://stackoverflow.com/a/9493060/2688027, licensed under CC BY-SA.
        const r = this.r / 255;
        const g = this.g / 255;
        const b = this.b / 255;
        const max = Math.max(r, g, b);
        const min = Math.min(r, g, b);
        let h, s, l = (max + min) / 2;

        if (max === min) {
            h = s = 0;
        } else {
            const d = max - min;
            s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
            switch (max) {
                case r:
                    h = (g - b) / d + (g < b ? 6 : 0);
                    break;

                case g:
                    h = (b - r) / d + 2;
                    break;

                case b:
                    h = (r - g) / d + 4;
                    break;
            }
            h /= 6;
        }

        return {
            h: h * 100,
            s: s * 100,
            l: l * 100,
        };
    }

    clamp(value) {
        if (value > 255) {
            value = 255;
        } else if (value < 0) {
            value = 0;
        }
        return value;
    }
}

class Solver {
    constructor(target, baseColor) {
        this.target = target;
        this.targetHSL = target.hsl();
        this.reusedColor = new Color(0, 0, 0);
    }

    solve() {
        const result = this.solveNarrow(this.solveWide());
        return {
            values: result.values,
            loss: result.loss,
            filter: this.css(result.values),
        };
    }

    solveWide() {
        const A = 5;
        const c = 15;
        const a = [60, 180, 18000, 600, 1.2, 1.2];

        let best = { loss: Infinity };
        for (let i = 0; best.loss > 25 && i < 3; i++) {
            const initial = [50, 20, 3750, 50, 100, 100];
            const result = this.spsa(A, a, c, initial, 1000);
            if (result.loss < best.loss) {
                best = result;
            }
        }
        return best;
    }

    solveNarrow(wide) {
        const A = wide.loss;
        const c = 2;
        const A1 = A + 1;
        const a = [0.25 * A1, 0.25 * A1, A1, 0.25 * A1, 0.2 * A1, 0.2 * A1];
        return this.spsa(A, a, c, wide.values, 500);
    }

    spsa(A, a, c, values, iters) {
        const alpha = 1;
        const gamma = 0.16666666666666666;

        let best = null;
        let bestLoss = Infinity;
        const deltas = new Array(6);
        const highArgs = new Array(6);
        const lowArgs = new Array(6);

        for (let k = 0; k < iters; k++) {
            const ck = c / Math.pow(k + 1, gamma);
            for (let i = 0; i < 6; i++) {
                deltas[i] = Math.random() > 0.5 ? 1 : -1;
                highArgs[i] = values[i] + ck * deltas[i];
                lowArgs[i] = values[i] - ck * deltas[i];
            }

            const lossDiff = this.loss(highArgs) - this.loss(lowArgs);
            for (let i = 0; i < 6; i++) {
                const g = lossDiff / (2 * ck) * deltas[i];
                const ak = a[i] / Math.pow(A + k + 1, alpha);
                values[i] = fix(values[i] - ak * g, i);
            }

            const loss = this.loss(values);
            if (loss < bestLoss) {
                best = values.slice(0);
                bestLoss = loss;
            }
        }
        return { values: best, loss: bestLoss };

        function fix(value, idx) {
            let max = 100;
            if (idx === 2 /* saturate */) {
                max = 7500;
            } else if (idx === 4 /* brightness */ || idx === 5 /* contrast */) {
                max = 200;
            }

            if (idx === 3 /* hue-rotate */) {
                if (value > max) {
                    value %= max;
                } else if (value < 0) {
                    value = max + value % max;
                }
            } else if (value < 0) {
                value = 0;
            } else if (value > max) {
                value = max;
            }
            return value;
        }
    }

    loss(filters) {
        // Argument is array of percentages.
        const color = this.reusedColor;
        color.set(0, 0, 0);

        color.invert(filters[0] / 100);
        color.sepia(filters[1] / 100);
        color.saturate(filters[2] / 100);
        color.hueRotate(filters[3] * 3.6);
        color.brightness(filters[4] / 100);
        color.contrast(filters[5] / 100);

        const colorHSL = color.hsl();
        return (
            Math.abs(color.r - this.target.r) +
            Math.abs(color.g - this.target.g) +
            Math.abs(color.b - this.target.b) +
            Math.abs(colorHSL.h - this.targetHSL.h) +
            Math.abs(colorHSL.s - this.targetHSL.s) +
            Math.abs(colorHSL.l - this.targetHSL.l)
        );
    }

    css(filters) {
        function fmt(idx, multiplier = 1) {
            return Math.round(filters[idx] * multiplier);
        }
        return `invert(${fmt(0)}%) sepia(${fmt(1)}%) saturate(${fmt(2)}%) hue-rotate(${fmt(3, 3.6)}deg) brightness(${fmt(4)}%) contrast(${fmt(5)}%)`;
    }
}

const toHSLObject = hslStr => {
    const [hue, saturation, lightness] = hslStr.match(/\d+/g).map(Number);
    return { hue, saturation, lightness };
};

const toHSLAObject = hslStr => {
    const [hue, saturation, lightness, alpha] = hslStr.match(/\d+/g).map(Number);
    return { hue, saturation, lightness, alpha };
};

function hslToRgb(h, s, l) {
    var r, g, b;

    if (s == 0) {
        r = g = b = l; // achromatic
    } else {
        function hue2rgb(p, q, t) {
            if (t < 0) t += 1;
            if (t > 1) t -= 1;
            if (t < 1 / 6) return p + (q - p) * 6 * t;
            if (t < 1 / 2) return q;
            if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
            return p;
        }

        var q = l < 0.5 ? l * (1 + s) : l + s - l * s;
        var p = 2 * l - q;

        r = hue2rgb(p, q, h + 1 / 3);
        g = hue2rgb(p, q, h);
        b = hue2rgb(p, q, h - 1 / 3);
    }

    return [r * 255, g * 255, b * 255];
}

function hexToRgb(hex) {
    // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
    const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, (m, r, g, b) => {
        return r + r + g + g + b + b;
    });

    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
        ? [
            parseInt(result[1], 16),
            parseInt(result[2], 16),
            parseInt(result[3], 16),
        ]
        : null;
}

function parse_rgb_string(rgb) {
    rgb = rgb.replace(/[^\d,]/g, '').split(',');
    return rgb;
}

function blpo2(x) {
    /*  x = x | (x >> 1);
     x = x | (x >> 2);
     x = x | (x >> 4);
     x = x | (x >> 8);
     x = x | (x >> 16);
     x = x | (x >> 32);
     return x - (x >> 1); */
    return 1 << 31 - Math.clz32(x);
}

function rotatePoint(point, center, angle) {
    const angleRadians = angle * (Math.PI / 180); // Convert to radians

    const rotatedX =
        Math.cos(angleRadians) * (point.x - center.x) -
        Math.sin(angleRadians) * (point.y - center.y) +
        center.x;

    const rotatedY =
        Math.sin(angleRadians) * (point.x - center.x) +
        Math.cos(angleRadians) * (point.y - center.y) +
        center.y;

    return {
        x: rotatedX,
        y: rotatedY,
    };
}

function getFilterForColor(color) {
    const toolColorButtonObj = Color1(color.trim());
    const colorRgbArray = toolColorButtonObj.rgb().array();
    const imgColor = new Color(colorRgbArray[0], colorRgbArray[1], colorRgbArray[2]);
    const solver = new Solver(imgColor);
    const result = solver.solve();
    const svgFilter = result?.filter
    return svgFilter
}

function calculateTicks(min, max, tickCount) {
    var span = max - min,
        step = Math.pow(10, Math.floor(Math.log(span / tickCount) / Math.LN10)),
        err = tickCount / span * step;

    // Filter ticks to get closer to the desired count.
    if (err <= .15) step *= 10;
    else if (err <= .35) step *= 5;
    else if (err <= .75) step *= 2;

    // Round start and stop values to step interval.
    var tstart = Math.floor(min / step) * step,
        tstop = Math.ceil(max / step) * step + step * .5,
        ticks = [];

    // now generate ticks
    for (let i=tstart; i < tstop; i += step) {
        ticks.push(i);
    }
    return ticks;
}

export {
    getStoredPixels, getPixels, calculateEllipseStatistics, calculateFreehandStatistics, freehandArea, applyWWWCRegion,
    getWWWCRegion, setToolCursor, hideToolCursor, getImageFitScale, fitToWindow, sanitizeMeasuredValue, calcProfile,
    distance, getCornerPoints, setGetToolStateItems, updateAnnnotationOnInputChange, swap16, getLuminanceData,
    moveAnnotationWithCompletedTrigger, getTargetIdsForElement, Color, Solver, getWindowWidthFromMinMaxMeanVar,
    toHSLObject, toHSLAObject, hslToRgb, hexToRgb, parse_rgb_string, _calculateMinMaxMean, calculateMinMaxMeanVar, blpo2, rotatePoint,
    getFilterForColor, isRotated, calculateTicks, pointInFreehand
};
