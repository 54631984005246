import csTools from 'cornerstone-tools';
import cornerstone from "cornerstone-core";
import { degToRad } from "cornerstone-math";
import { isRotated, getImageFitScale } from "./utils";


const BaseAnnotationTool = csTools.importInternal('base/BaseAnnotationTool');

const toolColors = csTools.toolColors;
const getToolState = csTools.getToolState;
const clearToolState = csTools.clearToolState;

const getNewContext = csTools.import("drawing/getNewContext");
const drawRect = csTools.importInternal("drawing/drawRect");
const draw = csTools.import("drawing/draw");
const drawHandles = csTools.importInternal("drawing/drawHandles");
const setShadow = csTools.importInternal("drawing/setShadow");

const Cursors = csTools.importInternal('tools/cursors');
const rectangleRoiCursor = Cursors.rectangleRoiCursor;
const getModule = csTools.getModule;
const external = csTools.external;

export const clamp = (num, min, max) => {
  const min1 = Math.min(min, max);
  const max1 = Math.max(min, max);
  return Math.min(Math.max(num, min1), max1);
};


const minX = (p) => {
  let ret = Number.MAX_SAFE_INTEGER;
  for (const point of p) {
    if(point.x < ret) {
      ret = point.x;
    }
  }
  return ret;
}

const minY = (p) => {
  let ret = Number.MAX_SAFE_INTEGER;
  for (const point of p) {
    if(point.y < ret) {
      ret = point.y;
    }
  }
  return ret;
}

const maxX = (p) => {
  let ret = Number.MIN_SAFE_INTEGER;
  for (const point of p) {
    if(point.x > ret) {
      ret = point.x;
    }
  }
  return ret;
}

const maxY = (p) => {
  let ret = Number.MIN_SAFE_INTEGER;
  for (const point of p) {
    if(point.y > ret) {
      ret = point.y;
    }
  }
  return ret;
}

const minAreaZoom = 0.02;
const minAreaTrans = 0.1;

/**
 * @public
 * @class /OverviewTool
 * @memberof Tools.Annotation
 * @classdesc Tool for drawing rectangular regions of interest, and measuring
 * the statistics of the enclosed pixels.
 * @extends Tools.Base.BaseAnnotationTool
 */
export default class OverviewTool extends BaseAnnotationTool {
  constructor(props = {}) {
    const defaultProps = {
      name: 'Overview',
      supportedInteractionTypes: ['Mouse', 'Touch'],
      configuration: {
        drawHandles: true,
        drawHandlesOnHover: props.configuration.drawHandlesOnHover ?? false,
        hideHandlesIfMoving: false,
        renderDashed: false,
        // showMinMax: false,
        // showHounsfieldUnits: true
      },
      svgCursor: rectangleRoiCursor,
    };

    super(props, defaultProps);

    this.x_previous = 0;
    this.y_previous = 0;
    this.diag_previous = 0;
  }

  activeCallback(element) {
    if (this.options.element && this.options.element.element) {
      this.options.element.element.addEventListener('cornerstoneimagerendered', this.onImageRendered);
    }
  }

  onImageRendered = (evt) => {
    if (evt && evt.detail && evt.detail.element) {
      const image = evt.detail.image;
      const viewport = cornerstone.getViewport(this.element);
      if (image) {
        const stateManager = csTools.getElementToolStateManager(this.element);
        const toolstate = stateManager.get(this.element, this.name);
        if (!toolstate || toolstate.data.length === 0) {
          const data = {
            toolName: this.name,
            visible: true,
            active: true,
            color: undefined,
            invalidated: true,
            handles: {
              start: {
                x: 0,
                y: 0,
                highlight: true,
                active: false,
              },
              end: {
                x: image.width,
                y: image.height,
                highlight: true,
                active: true,
              },
            },
            initialRotation: viewport?.rotation ?? 0,
          };

          stateManager.add(this.element, this.name, data);
        }
        if (toolstate && toolstate.data && toolstate.data.length > 0) {
              viewport.rotation = evt.detail.viewport.rotation;
              viewport.hflip = evt.detail.viewport.hflip;
              viewport.vflip = evt.detail.viewport.vflip;

              const canvas = cornerstone.getEnabledElement(this.options.element.element).canvas;
              let pixelToCanvasx1 = cornerstone.pixelToCanvas(evt.detail.element, { x: 0, y: 0 });
              let pixelToCanvasx2 = cornerstone.pixelToCanvas(evt.detail.element, { x: image.width, y: 0 });
              let pixelToCanvasx3 = cornerstone.pixelToCanvas(evt.detail.element, { x: image.width, y: image.height });
              let pixelToCanvasx4 = cornerstone.pixelToCanvas(evt.detail.element, { x: 0, y: image.height });
              if (image.cutwidth) {
                  const cw = image.cutwidth, ch = image.cutheight;
                  const cx = image.cutx, cy = image.cuty;
                  pixelToCanvasx1 = cornerstone.pixelToCanvas(evt.detail.element, { x: cx - (cw / 2), y: cy - (ch / 2) });
                  pixelToCanvasx2 = cornerstone.pixelToCanvas(evt.detail.element, { x: cx + (cw / 2), y: cy - (ch / 2) });
                  pixelToCanvasx3 = cornerstone.pixelToCanvas(evt.detail.element, { x: cx + (cw / 2), y: cy + (ch / 2) });
                  pixelToCanvasx4 = cornerstone.pixelToCanvas(evt.detail.element, { x: cx - (cw / 2), y: cy + (ch / 2) });
              }
              const p1 = { x: clamp(pixelToCanvasx1.x, 0, canvas.clientWidth), y: clamp(pixelToCanvasx1.y, 0, canvas.clientHeight) };
              const p2 = { x: clamp(pixelToCanvasx2.x, 0, canvas.clientWidth), y: clamp(pixelToCanvasx2.y, 0, canvas.clientHeight) };
              const p3 = { x: clamp(pixelToCanvasx3.x, 0, canvas.clientWidth), y: clamp(pixelToCanvasx3.y, 0, canvas.clientHeight) };
              const p4 = { x: clamp(pixelToCanvasx4.x, 0, canvas.clientWidth), y: clamp(pixelToCanvasx4.y, 0, canvas.clientHeight) };
              const canvasToPixelStart1 = cornerstone.canvasToPixel(evt.detail.element, { x: minX([p1, p2, p3, p4]), y: minY([p1, p2, p3, p4]), _canvasCoordinateBrand: "" });
              const canvasToPixelEnd1 = cornerstone.canvasToPixel(evt.detail.element, { x: maxX([p1, p2, p3, p4]), y: maxY([p1, p2, p3, p4]), _canvasCoordinateBrand: "" });

              toolstate.data[0].handles.start.x = canvasToPixelStart1.x;
              toolstate.data[0].handles.end.x = canvasToPixelEnd1.x;
              toolstate.data[0].handles.start.y = canvasToPixelStart1.y;
              toolstate.data[0].handles.end.y = canvasToPixelEnd1.y;

              this.x_previous = toolstate.data[0].handles.start.x;
              this.y_previous = toolstate.data[0].handles.start.y;
              this.diag_previous = Math.sqrt((toolstate.data[0].handles.end.x - toolstate.data[0].handles.start.x) ** 2 +
                                             (toolstate.data[0].handles.end.y - toolstate.data[0].handles.start.y) ** 2);

              const newScale = getImageFitScale({ width: this.element.clientWidth, height: this.element.clientHeight }, evt.detail.image, viewport.rotation);
              viewport.scale = newScale.scaleFactor;
              if (image.cutwidth) {
                  let tx = image.width / 2 - image.cutx, ty = image.height / 2 - image.cuty;
                  if (viewport.hflip)
                      tx = image.width / 2 - (image.width - image.cutx);
                  if (viewport.vflip)
                      ty = image.height / 2 - (image.height - image.cuty);
                  let ttx = tx, tty = ty;
                  if (viewport.rotation && viewport.rotation !== 0.0) {
                      let rotation = viewport.rotation;                      
                      ttx = tx * Math.cos(degToRad(rotation)) - ty * Math.sin(degToRad(rotation));
                      tty = tx * Math.sin(degToRad(rotation)) + ty * Math.cos(degToRad(rotation));
                  }
                  viewport.translation = { x: ttx, y: tty };
              }
              cornerstone.setViewport(this.element, viewport);
          }
      }
    }
  }

  passiveCallback(element) {
    clearToolState(element, this.name);
    if (this.options.element && this.options.element.element) {
      this.options.element.element.removeEventListener('cornerstoneimagerendered', this.onImageRendered);
    }
  }

  createNewMeasurement(eventData) {
    if (eventData && eventData.currentPoints) {
      const stateManager = csTools.getElementToolStateManager(eventData.event.currentTarget);
      const toolstate = stateManager.get(eventData.event.currentTarget, this.name);

      if (toolstate && toolstate.data && toolstate.data.length > 0) {
        const center_x = toolstate.data[0].handles.start.x + (toolstate.data[0].handles.end.x - toolstate.data[0].handles.start.x) / 2;
        const center_y = toolstate.data[0].handles.start.y + (toolstate.data[0].handles.end.y - toolstate.data[0].handles.start.y) / 2;
        const enabledElement = cornerstone.getEnabledElement(this.options.element.element);

        if (enabledElement) {
          const viewport = enabledElement.viewport;
          if (viewport) {
            const delta_x = center_x - eventData.currentPoints.image.x;
            const delta_y = center_y - eventData.currentPoints.image.y;
            const trans_x = (viewport.hflip ? -delta_x : delta_x) * Math.cos(degToRad(viewport.rotation)) - (viewport.vflip ? -delta_y : delta_y) * Math.sin(degToRad(viewport.rotation));
            const trans_y = (viewport.hflip ? -delta_x : delta_x) * Math.sin(degToRad(viewport.rotation)) + (viewport.vflip ? -delta_y : delta_y) * Math.cos(degToRad(viewport.rotation));
            viewport.translation.x += trans_x;
            viewport.translation.y += trans_y;
            cornerstone.updateImage(this.options.element.element);
          }
        }
      }
    }
  }

  pointNearTool(element, data, coords, interactionType) {
    const hasStartAndEndHandles =
      data && data.handles && data.handles.start && data.handles.end;
    const validParameters = hasStartAndEndHandles;

    if (!validParameters || data.visible === false) {
      return false;
    }

    const distance = interactionType === 'mouse' ? 15 : 25;
    const startCanvas = external.cornerstone.pixelToCanvas(
      element,
      data.handles.start
    );
    const endCanvas = external.cornerstone.pixelToCanvas(
      element,
      data.handles.end
    );

    const rect = {
      left: Math.min(startCanvas.x, endCanvas.x),
      top: Math.min(startCanvas.y, endCanvas.y),
      width: Math.abs(startCanvas.x - endCanvas.x),
      height: Math.abs(startCanvas.y - endCanvas.y),
    };

    const distanceToPoint = external.cornerstoneMath.rect.distanceToPoint(
      rect,
      coords
    );
    return distanceToPoint < distance;
  }

  updateCachedStats(image, element, data) {
    if(data?.toolName !== this.name) return;
    if (data && data.handles && data.handles.start && data.handles.end) {
      const enabledElement = cornerstone.getEnabledElement(this.options.element.element);

      if (enabledElement) {
        const viewport = enabledElement.viewport;
        const canvas = enabledElement.canvas;
        if (viewport && canvas) {
          if (!(data.handles.start.active || data.handles.end.active)) {
            const delta_x = data.handles.start.x - this.x_previous;
            const delta_y = data.handles.start.y - this.y_previous;

            const trans_x = (viewport.hflip ? -delta_x : delta_x) * Math.cos(degToRad(viewport.rotation)) - (viewport.vflip ? -delta_y : delta_y) * Math.sin(degToRad(viewport.rotation));
            const trans_y = (viewport.hflip ? -delta_x : delta_x) * Math.sin(degToRad(viewport.rotation)) + (viewport.vflip ? -delta_y : delta_y) * Math.cos(degToRad(viewport.rotation));

            const canvasToPixelStart = cornerstone.canvasToPixel(enabledElement.element, { x: trans_x, y: trans_y, _canvasCoordinateBrand: "" });
            const canvasToPixelEnd = cornerstone.canvasToPixel(enabledElement.element, { x: canvas?.width + trans_x ?? 0, y: canvas?.height + trans_y ?? 0, _canvasCoordinateBrand: "" });
            const start_x = clamp(0, canvasToPixelStart.x, canvasToPixelEnd.x);
            const end_x = clamp(enabledElement.image.width, canvasToPixelStart.x, canvasToPixelEnd.x);
            const start_y = clamp(0, canvasToPixelStart.y, canvasToPixelEnd.y);
            const end_y = clamp(enabledElement.image.height, canvasToPixelStart.y, canvasToPixelEnd.y);

            const pixelToCanvasStart = cornerstone.pixelToCanvas(enabledElement.element, { x: start_x, y: start_y, _pixelCoordinateBrand: "" });
            const pixelToCanvasEnd = cornerstone.pixelToCanvas(enabledElement.element, { x: end_x, y: end_y, _pixelCoordinateBrand: "" });
            const area = Math.abs(pixelToCanvasEnd.x - pixelToCanvasStart.x) * Math.abs(pixelToCanvasEnd.y - pixelToCanvasStart.y);

            if (area > minAreaTrans * canvas.width * canvas.height) {
              viewport.translation.x -= trans_x;
              viewport.translation.y -= trans_y;
            }

            cornerstone.updateImage(this.options.element.element);
          } else {
            const diag = Math.sqrt((data.handles.end.x - data.handles.start.x) ** 2 + (data.handles.end.y - data.handles.start.y) ** 2);
            const scale = this.diag_previous / diag;

            const canvasToPixelStart = cornerstone.canvasToPixel(enabledElement.element, { x: 0, y: 0, _canvasCoordinateBrand: "" });
            const canvasToPixelEnd = cornerstone.canvasToPixel(enabledElement.element, { x: canvas?.width / (scale) ?? 0, y: canvas?.height / (scale) ?? 0, _canvasCoordinateBrand: "" });
            const start_x = clamp(0, canvasToPixelStart.x, canvasToPixelEnd.x);
            const end_x = clamp(enabledElement.image.width, canvasToPixelStart.x, canvasToPixelEnd.x);
            const start_y = clamp(0, canvasToPixelStart.y, canvasToPixelEnd.y);
            const end_y = clamp(enabledElement.image.height, canvasToPixelStart.y, canvasToPixelEnd.y);
            const area = Math.abs(end_x - start_x) * Math.abs(end_y - start_y);

            if (area > minAreaZoom * enabledElement.image.width * enabledElement.image.height) {
              viewport.scale = viewport.scale * scale;
            }
            cornerstone.updateImage(this.options.element.element);
          }
        }
      }
    }
  }

  renderToolData(evt) {
    const toolData = getToolState(evt.currentTarget, this.name);

    if (!toolData) {
      return;
    }

    const eventData = evt.detail;
    const { element } = eventData;
    const lineDash = getModule('globalConfiguration').configuration.lineDash;
    const {
      handleRadius,
      drawHandlesOnHover,
      hideHandlesIfMoving,
      renderDashed,
    } = this.configuration;
    const context = getNewContext(eventData.canvasContext.canvas);

    draw(context, context => {
      // If we have tool data for this element - iterate over each set and draw it
      for (let i = 0; i < toolData.data.length; i++) {
        const data = toolData.data[i];

        if (data.visible === false) {
          continue;
        }

        // Configure
        const color = toolColors.getColorIfActive(data);
        const handleOptions = {
          color,
          handleRadius,
          drawHandlesIfActive: drawHandlesOnHover,
          hideHandlesIfMoving,
        };

        setShadow(context, this.configuration);

        const rectOptions = { color };

        if (renderDashed) {
          rectOptions.lineDash = lineDash;
        }

        // Draw
        drawRect(
          context,
          element,
          data.handles.start,
          data.handles.end,
          rectOptions,
          'pixel',
          //data.handles.initialRotation,
          this.options?.element?.viewport?.rotation
        );

        if (this.configuration.drawHandles) {
          drawHandles(context, eventData, data.handles, handleOptions);
        }
      }
    });
  }
}
