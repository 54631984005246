/* eslint no-bitwise: 0 */

import { shaders, dataUtilities } from './shaders/index.js';
import { vertexShader } from './VertexShader.js';
import { webGL } from 'cornerstone-core';
import store from './../../store';
import { Constants } from "./../../Constants";
import { getColorFracAtMatrixIndex, getFalseColorsActive } from './../ImageDisplay/ImageDisplaySlice';
import { selectImagePolarity } from "../OrderList/MainOrderListSlice";
//import { selectMatrixIndex } from "../ImageDisplay/ImageDisplaySlice";
import cornerstone from 'cornerstone-core';
import * as cornerstoneTools from "cornerstone-tools";
import {rotatePoint} from "../ImageDisplay/Tools/utils";

const getToolState = cornerstoneTools.getToolState;

const renderCanvas = document.createElement('canvas');
let gl;
let texCoordBuffer;
let positionBuffer;
let isWebGLInitialized = false;

export { isWebGLInitialized };

export function getRenderCanvas() {
  return renderCanvas;
}

/**
 * Replace %(id)s in strings with values in objects(s)
 *
 * Given a string like `"Hello %(name)s from $(user.country)s"`
 * and an object like `{name:"Joe",user:{country:"USA"}}` would
 * return `"Hello Joe from USA"`.
 *
 * @function
 * @param {string} str string to do replacements in
 * @param {Object|Object[]} params one or more objects.
 * @returns {string} string with replaced parts
 * @memberOf module:Strings
 */
var replaceParams = (function () {
  var replaceParamsRE = /%\(([^\)]+)\)s/g;

  return function (str, params) {
    if (!params.length) {
      params = [params];
    }

    return str.replace(replaceParamsRE, function (match, key) {
      var keys = key.split('.');
      for (var ii = 0; ii < params.length; ++ii) {
        var obj = params[ii];
        for (var jj = 0; jj < keys.length; ++jj) {
          var part = keys[jj];
          obj = obj[part];
          if (obj === undefined) {
            break;
          }
        }
        if (obj !== undefined) {
          return obj;
        }
      }
      console.error("unknown key: " + key);
      return "%(" + key + ")s";
    });
  };
}());

function initShaders() {
  for (const id in shaders) {
    // Console.log("WEBGL: Loading shader", id);
    const shader = shaders[id];

    shader.attributes = {};
    shader.uniforms = {};
    shader.vert = vertexShader;

    shader.frag = replaceParams(shader.frag, {
      numFracs: Constants.DEFAULT_FALSE_COLOR_FRAC.length,
      numColorPoint: Constants.DEFAULT_FALSE_COLORS.length / 3,
      numRects: Constants.MULTIWWWCREGIONS_MAX,
    });

    shader.program = webGL.createProgramFromString(gl, shader.vert, shader.frag);

    shader.attributes.texCoordLocation = gl.getAttribLocation(shader.program, 'a_texCoord');
    gl.enableVertexAttribArray(shader.attributes.texCoordLocation);

    shader.attributes.positionLocation = gl.getAttribLocation(shader.program, 'a_position');
    gl.enableVertexAttribArray(shader.attributes.positionLocation);

    shader.uniforms.resolutionLocation = gl.getUniformLocation(shader.program, 'u_resolution');
  }
}

export function initRenderer() {
  if (isWebGLInitialized === true) {
    // Console.log("WEBGL Renderer already initialized");
    return;
  }

  if (initWebGL(renderCanvas)) {
    initBuffers();
    initShaders();
    // Console.log("WEBGL Renderer initialized!");
    isWebGLInitialized = true;
  }
}

function updateRectangle(gl, width, height) {
  gl.bufferData(gl.ARRAY_BUFFER, new Float32Array([
    width, height,
    0, height,
    width, 0,
    0, 0]), gl.STATIC_DRAW);
}

function handleLostContext(event) {
  event.preventDefault();
  console.warn('WebGL Context Lost!');
}

function handleRestoredContext(event) {
  event.preventDefault();
  isWebGLInitialized = false;
  webGL.textureCache.purgeCache();
  initRenderer();
  // Console.log('WebGL Context Restored.');
}

function initWebGL(canvas) {

  gl = null;
  try {
    // Try to grab the standard context. If it fails, fallback to experimental.
    const options = {
      desynchronized: false,
      preserveDrawingBuffer: true // Preserve buffer so we can copy to display canvas element
    };

    // ---------------- Testing purposes -------------
    // If (debug === true && WebGLDebugUtils) {
    //    RenderCanvas = WebGLDebugUtils.makeLostContextSimulatingCanvas(renderCanvas);
    // }
    // ---------------- Testing purposes -------------

    gl = canvas.getContext('webgl', options) || canvas.getContext('experimental-webgl', options);

    // Set up event listeners for context lost / context restored
    canvas.removeEventListener('webglcontextlost', handleLostContext, false);
    canvas.addEventListener('webglcontextlost', handleLostContext, false);

    canvas.removeEventListener('webglcontextrestored', handleRestoredContext, false);
    canvas.addEventListener('webglcontextrestored', handleRestoredContext, false);

  } catch (error) {
    throw new Error('Error creating WebGL context');
  }

  // If we don't have a GL context, give up now
  if (!gl) {
    console.error('Unable to initialize WebGL. Your browser may not support it.');
    gl = null;
  }

  return gl;
}

/**
 * Returns the image data type as a string representation.
 * @param {any} image The cornerstone image object
 * @returns {string} image data type (rgb, iint16, uint16, int8 and uint8)
 * @memberof WebGLRendering
 */
function getImageDataType(image) {
  if (image.color) {
    return 'rgb';
  }

  const pixelData = image.getPixelData();

  if (pixelData instanceof Int16Array) {
    return 'int16';
  }

  if (pixelData instanceof Uint16Array) {
    return 'uint16';
  }

  if (pixelData instanceof Int8Array) {
    return 'int8';
  }

  return 'uint8';
}

function getShaderProgram(image) {

  const datatype = getImageDataType(image);
  // We need a mechanism for
  // Choosing the shader based on the image datatype
  // Console.log("Datatype: " + datatype);

  if (shaders.hasOwnProperty(datatype)) {
    return shaders[datatype];
  }

  return shaders.rgb;
}

function generateTexture(image) {
  const TEXTURE_FORMAT = {
    uint8: gl.LUMINANCE,
    int8: gl.LUMINANCE_ALPHA,
    uint16: gl.LUMINANCE_ALPHA,
    int16: gl.RGB,
    rgb: gl.RGB
  };

  const TEXTURE_BYTES = {
    int8: 1, // Luminance
    uint16: 2, // Luminance + Alpha
    int16: 3, // RGB
    rgb: 3 // RGB
  };

  const imageDataType = getImageDataType(image);
  const format = TEXTURE_FORMAT[imageDataType];

  // GL texture configuration
  const texture = gl.createTexture();

  gl.bindTexture(gl.TEXTURE_2D, texture);

  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.NEAREST);
  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.NEAREST);
  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
  gl.pixelStorei(gl.UNPACK_ALIGNMENT, 1);

  const imageData = dataUtilities[imageDataType].storedPixelDataToImageData(image, image.width, image.height);

  gl.texImage2D(gl.TEXTURE_2D, 0, format, image.width, image.height, 0, format, gl.UNSIGNED_BYTE, imageData);

  // Calculate the size in bytes of this image in memory
  const sizeInBytes = image.width * image.height * TEXTURE_BYTES[imageDataType];

  return {
    texture,
    sizeInBytes
  };
}

function getImageTexture(image) {
  let imageTexture = webGL.textureCache.getImageTexture(image.imageId);

  if (!imageTexture) {
    // Console.log("Generating texture for imageid: ", image.imageId);
    imageTexture = generateTexture(image);
    webGL.textureCache.putImageTexture(image, imageTexture);
  }

  return imageTexture.texture;
}

function initBuffers() {
  positionBuffer = gl.createBuffer();
  gl.bindBuffer(gl.ARRAY_BUFFER, positionBuffer);
  gl.bufferData(gl.ARRAY_BUFFER, new Float32Array([
    1, 1,
    0, 1,
    1, 0,
    0, 0
  ]), gl.STATIC_DRAW);


  texCoordBuffer = gl.createBuffer();
  gl.bindBuffer(gl.ARRAY_BUFFER, texCoordBuffer);
  gl.bufferData(gl.ARRAY_BUFFER, new Float32Array([
    1.0, 1.0,
    0.0, 1.0,
    1.0, 0.0,
    0.0, 0.0
  ]), gl.STATIC_DRAW);
}

function renderQuad(shader, parameters, texture, width, height, enabledElement) {
  gl.clearColor(1.0, 0.0, 0.0, 1.0);
  gl.viewport(0, 0, width, height);

  gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT);
  gl.useProgram(shader.program);

  gl.bindBuffer(gl.ARRAY_BUFFER, texCoordBuffer);
  gl.vertexAttribPointer(shader.attributes.texCoordLocation, 2, gl.FLOAT, false, 0, 0);

  gl.bindBuffer(gl.ARRAY_BUFFER, positionBuffer);
  gl.vertexAttribPointer(shader.attributes.positionLocation, 2, gl.FLOAT, false, 0, 0);

  for (const key in parameters) {
    const uniformLocation = gl.getUniformLocation(shader.program, key);

    if (!uniformLocation) {
      continue;

      // Disabling this error for now since RGB requires minPixelValue
      // but the other shaders do not.
      // throw `Could not access location for uniform: ${key}`;
    }

    const uniform = parameters[key];

    const type = uniform.type;
    const value = uniform.value;

    if (type === 'i') {
      gl.uniform1i(uniformLocation, value);
    } else if (type === 'f') {
      gl.uniform1f(uniformLocation, value);
    } else if (type === '2f') {
      gl.uniform2f(uniformLocation, value[0], value[1]);
    }
  }

  if (enabledElement?.element?.id && enabledElement?.element?.id.startsWith(Constants.OVERVIEW_ELEMENT_NAME)) {
    gl.uniform1i(gl.getUniformLocation(shader.program, "isFalseColorActive"), false);
    gl.uniform1i(gl.getUniformLocation(shader.program, "isRect"), false);
  } else {
    const matrixIndexStr = enabledElement?.element?.id?.split("_").pop();
    if (matrixIndexStr) {
      const matrixIndex = parseInt(matrixIndexStr);
      if (!isNaN(matrixIndex)) {

        const state = store.getState();
        const colorfrac = getColorFracAtMatrixIndex(state, matrixIndex);
        const isFalseColorActive = getFalseColorsActive(state, matrixIndex);
        gl.uniform1i(gl.getUniformLocation(shader.program, "isFalseColorActive"), isFalseColorActive);

        if (Array.isArray(colorfrac) && colorfrac.length === Constants.DEFAULT_FALSE_COLORS.length / 6 - 1) {
          gl.uniform1fv(gl.getUniformLocation(shader.program, "frac"), colorfrac);
          gl.uniform3fv(gl.getUniformLocation(shader.program, "colorPoints"), Constants.DEFAULT_FALSE_COLORS);
        }

        const toolData = getToolState(enabledElement?.element, 'MultiWwwcRegions');
        if (toolData?.data && Array.isArray(toolData?.data) && toolData?.data.length > 0) {
          const rectpoints = Array(Constants.MULTIWWWCREGIONS_MAX * 8).fill(0.0);
          const wwwc = Array(Constants.MULTIWWWCREGIONS_MAX * 2).fill(0.0);

          for (let j = 0; j < toolData?.data.length; j++) {

            let rotation = toolData?.data[j]?.handles?.initialRotation;

            const viewport = cornerstone.getViewport(enabledElement?.element);

            if(viewport?.hflip || viewport?.vflip){
              rotation = -rotation;
            }

            const centerPoint = {
              x: (toolData?.data[j]?.handles?.end?.x - toolData?.data[j]?.handles?.start?.x) / 2.0,
              y: (toolData?.data[j]?.handles?.end?.y - toolData?.data[j]?.handles?.start?.y) / 2.0,
            };


            let corner1 = {
              x: toolData?.data[j]?.handles?.start?.x,
              y: toolData?.data[j]?.handles?.start?.y,
            };

            let corner2 = {
              x: toolData?.data[j]?.handles?.end?.x,
              y: toolData?.data[j]?.handles?.end?.y,
            };

            if (Math.abs(rotation) > 0.05) {
              corner1 = rotatePoint(corner1, centerPoint, rotation);
              corner2 = rotatePoint(corner2, centerPoint, rotation);
            }

            const w = Math.abs(corner1.x - corner2.x);
            const h = Math.abs(corner1.y - corner2.y);

            corner1 = {
              x: Math.min(corner1.x, corner2.x),
              y: Math.min(corner1.y, corner2.y),
            };

            corner2 = {
              x: corner1.x + w,
              y: corner1.y + h,
            };

            let corner3 = {
              x: corner1.x + w,
              y: corner1.y,
            };

            let corner4 = {
              x: corner1.x,
              y: corner1.y + h,
            };

            if (Math.abs(rotation) > 0.05) {
              corner1 = rotatePoint(corner1, centerPoint, -rotation);
              corner2 = rotatePoint(corner2, centerPoint, -rotation);
              corner3 = rotatePoint(corner3, centerPoint, -rotation);
              corner4 = rotatePoint(corner4, centerPoint, -rotation);
            }

            rectpoints[j*8 + 0] = corner1.x;
            rectpoints[j*8 + 1] = corner1.y;
            rectpoints[j*8 + 2] = corner3.x;
            rectpoints[j*8 + 3] = corner3.y;
            rectpoints[j*8 + 4] = corner2.x;
            rectpoints[j*8 + 5] = corner2.y;
            rectpoints[j*8 + 6] = corner4.x;
            rectpoints[j*8 + 7] = corner4.y;

            wwwc[j * 2 + 0] = toolData?.data[j]?.wwwc?.windowWidth;
            wwwc[j * 2 + 1] = toolData?.data[j]?.wwwc?.windowCenter;
          }

          //gl.uniform1i(gl.getUniformLocation(shader.program, "isRect"), selectedTool.name === 'NewWwwcRegion');
          gl.uniform1i(gl.getUniformLocation(shader.program, "isRect"), true);
          gl.uniform1i(gl.getUniformLocation(shader.program, "actRects"), toolData?.data.length);
          gl.uniform1fv(gl.getUniformLocation(shader.program, "rectpoints"), rectpoints);
          gl.uniform2f(gl.getUniformLocation(shader.program, "u_textureSize"), width, height);
          gl.uniform2fv(gl.getUniformLocation(shader.program, "wwwc"), wwwc);
        }
      }
    }
  }



  updateRectangle(gl, width, height);

  gl.activeTexture(gl.TEXTURE0);
  gl.bindTexture(gl.TEXTURE_2D, texture);
  gl.drawArrays(gl.TRIANGLE_STRIP, 0, 4);

}

export function render(enabledElement) {

  const state = store.getState();
  const imagePolarity = selectImagePolarity(state);
  //const selectedMatrixIndex = selectMatrixIndex(state);

  // const context = enabledElement.canvas.getContext('2d');
  // context.fillStyle = "#ffff00";
  // context.clearRect(0, 0, enabledElement.canvas.width, enabledElement.canvas.height);
  // context.fillRect(0, 0, enabledElement.canvas.width, enabledElement.canvas.height);
  //context.fill();


  // Resize the canvas
  const image = enabledElement.image;

  const imagePlaneModule = cornerstone.metaData.get('imagePlaneModule', image?.imageId) || {};

  renderCanvas.width = image.width;
  renderCanvas.height = image.height;

  const viewport = enabledElement.viewport;


  // Render the current image
  const shader = getShaderProgram(image);
  const texture = getImageTexture(image);

  let photometricInterpretation = 0;
  if (imagePlaneModule?.photometricInterpretation !== undefined) {
    if (imagePlaneModule?.photometricInterpretation === "MONOCHROME1") {
      photometricInterpretation = 1;
    }
  } else if (image?.photometricInterpretation !== undefined) {
    photometricInterpretation = image?.photometricInterpretation;
  }

  // adapt overview to current image polarity
  /* let index = -1;
  if (enabledElement?.element) {
    const matrixIndexStr = enabledElement?.element.id.split("_").pop();
    if (matrixIndexStr) {
      const matrixIndex = parseInt(matrixIndexStr);
      if (!isNaN(matrixIndex)) {
        index = matrixIndex;
      }
    }
  }
  const cornerstoneElements = cornerstone.getEnabledElements();
  const overviewElement = cornerstoneElements.find((element) => element.element.id === Constants.OVERVIEW_ELEMENT_NAME);
  if (overviewElement?.viewport && index > -1 && selectedMatrixIndex > -1 && index === selectedMatrixIndex) {
    overviewElement.viewport.invert = imagePolarity !== photometricInterpretation;
    cornerstone.updateImage(overviewElement?.element);
  } */

  let invert = imagePolarity === photometricInterpretation ? 0 : 1;
  invert = (viewport.invert ? !invert : invert) ? 1 : 0;

  const parameters = {
    u_resolution: {
      type: '2f',
      value: [image.width, image.height]
    },
    wc: {
      type: 'f',
      value: viewport.voi.windowCenter
    },
    ww: {
      type: 'f',
      value: viewport.voi.windowWidth
    },
    slope: {
      type: 'f',
      value: image.slope
    },
    intercept: {
      type: 'f',
      value: image.intercept
    },
    minPixelValue: {
      type: 'f',
      value: image.minPixelValue
    },

    invert: {
      type: 'i',
      value: invert,

    }
  };

  renderQuad(shader, parameters, texture, image.width, image.height, enabledElement);

  return renderCanvas;
}

export function isWebGLAvailable() {
  // Adapted from
  // http://stackoverflow.com/questions/9899807/three-js-detect-webgl-support-and-fallback-to-regular-canvas

  const options = {
    failIfMajorPerformanceCaveat: true
  };

  try {
    const canvas = document.createElement('canvas');

    return Boolean(window.WebGLRenderingContext) &&
      (canvas.getContext('webgl', options) || canvas.getContext('experimental-webgl', options));
  } catch (e) {
    return false;
  }
}
