import React from "react";
import './HoverRangeSlider.scss';
import { TypedUseSelectorHook, useSelector } from "react-redux";
import { RootState } from "../../store";
import './NumericInput.scss';
import { getImagePathForCurrentTheme } from "../OrderList/OrdersSlice";

interface NumericInputProps {
    startValue: number;
    title: string;
    units?: string;
    readOnly?: boolean;
    valueValidation: number;
    onPlusChange?: (up: boolean) => void;
    onMinusChange?: (up: boolean) => void;
    isLimit?: number | undefined;
}

const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

const NumericInput = (props: NumericInputProps) => {

    const imagePathForCurrentTheme: string = useAppSelector((state) => getImagePathForCurrentTheme(state));

    const onPlusChange = () => {
        if (props.onPlusChange) {
            props.onPlusChange(true);
        }
    }

    const onMinusChange = () => {
        if (props.onMinusChange) {
            props.onMinusChange(false);
        }
    }

    return (
        <div className="numericInputWrapper">
            <label className="numericInputTitleLabel">{props.title}</label>
            <div className="numericInputValue">
                <button className= {props.onMinusChange ? "numericInputButton": "numericInputButton_invisible"} onClick={onMinusChange} disabled={props.isLimit === 1}>
                    <img
                        src={`${imagePathForCurrentTheme}triangle-left-arrow-outline-svgrepo-com.svg`}
                        onError={(event: any) => { event.target.src = "/images/triangle-left-arrow-outline-svgrepo-com.svg"; event.onerror = null }}
                        alt="triangle-left-arrow-outline-svgrepo-com.svg" />
                </button>
                <div className="numericInputLabelWrapper">
                    <label className="numericInputLabel" id={props.valueValidation.toString()}>{Math.round(props.startValue * 100) / 100}</label>
                    <label className="numericInputLabelUnit">{props.units}</label>
                </div>
                <button className={props.onPlusChange ? "numericInputButton": "numericInputButton_invisible"} style={{ transform: 'rotate(180deg)' }} onClick={onPlusChange} disabled={props.isLimit === 2}>
                    <img
                        src={`${imagePathForCurrentTheme}triangle-left-arrow-outline-svgrepo-com.svg`}
                        onError={(event: any) => { event.target.src = "/images/triangle-left-arrow-outline-svgrepo-com.svg"; event.onerror = null }}
                        alt="triangle-left-arrow-outline-svgrepo-com.svg" />
                </button>

            </div>
        </div>
    )
}

export default NumericInput;
