import React, { useEffect, useState } from "react";
import './HoverRangeSlider.scss';
import { TypedUseSelectorHook, useSelector } from "react-redux";
import { RootState } from "../../store";
import { getImagePathForCurrentTheme } from "../OrderList/OrdersSlice";

interface HoverRangesliderProps {
    startValue: number;
    units?: string;
    min: number;
    max: number;
    steps?: number;
    readOnly?: boolean;
    onChange: (value: number) => void;
}

const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;


const HoverRangeslider = (props: HoverRangesliderProps) => {

    const [inputValue, setInputValue] = useState<string>('');

    const imagePathForCurrentTheme: string = useAppSelector((state) => getImagePathForCurrentTheme(state));

    const onValueChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(evt.target.value);
        props.onChange(Number(evt.target.value));
    }

    const onPlusChange = () => {
        const newValue: number = Math.min((Number(inputValue) + (props.steps ?? 1)), props.max);
        setInputValue(newValue.toString());
        props.onChange(newValue);
    }

    const onMinusChange = () => {
        const newValue: number = Math.max((Number(inputValue) - (props.steps ?? 1)), props.min);
        setInputValue(newValue.toString());
        props.onChange(newValue);
    }

    const onValueChange2 = (evt: React.ChangeEvent<HTMLInputElement>) => {
        let value: string = evt.target.value;
        if (Number(value) < props.min) {
            //value = props.min.toString();
            setInputValue(value);
            //props.onChange(props.min);
        } else if (Number(value) > props.max) {
            value = props.max.toString();
            setInputValue(value);
            props.onChange(Number(value));
        } else {
            setInputValue(value);
            props.onChange(Number(value));
        }
    }

    useEffect(() => {
        setInputValue(props.startValue.toString());
    }, [props.startValue]);

    return (
        <div className="RangeInputElement" >
            <div className="RangeInputLabelDiv">
                <input type="number" className={Number(inputValue) < props.min ? "RangeInputNumber_invalid" : "RangeInputNumber"} value={inputValue}
                    onChange={onValueChange2} title={Number(inputValue) < props.min ? `value too small (< ${props.min})` : undefined} disabled={props.readOnly}/>
                <label className="RangeInputLabelUnits"> {`${props.units ? props.units : ""} `} </label>
            </div>
            <div className="RangeInputDiv">

                <button className="RangeInputButton" onClick={onMinusChange}  disabled={props.readOnly}>
                    <img
                    src={`${imagePathForCurrentTheme}minus.svg`}
                    onError={(event: any) => {event.target.src="/images/minus.svg"; event.onerror = null}}
                    alt="initiate search" />
                </button>
                <input className="RangeInput" id="RangeInput" type="range" value={inputValue}
                    min={props.min} max={props.max} onChange={onValueChange}
                    onClick={(evt) => evt.stopPropagation()} step={props.steps} disabled={props.readOnly}>
                </input>
                <button className="RangeInputButton" onClick={onPlusChange} disabled={props.readOnly}>
                    <img
                    src={`${imagePathForCurrentTheme}plus.svg`}
                    onError={(event: any) => {event.target.src="/images/plus.svg"; event.onerror = null}}
                    alt="initiate search" />
                </button>





            </div>
        </div>
    )
}

export default HoverRangeslider;
