/* eslint no-bitwise: 0 */

const uint16Shader = {};

/**
 * Convert stored pixel data to image data.
 *
 * For uint16 pack uint16 into two uint8 channels (r and a).
 *
 * @param {Image} image A Cornerstone Image Object
 * @returns {Uint8Array} The image data for use by the WebGL shader
 * @memberof WebGLRendering
 */
function storedPixelDataToImageData (image) {

  // Transfer image data to alpha and luminance channels of WebGL texture
  // Credit to @jpambrun and @fernandojsg

  // Pack uint16 into two uint8 channels (r and a)
  const pixelData = image.getPixelData();
  const numberOfChannels = 2;
  const data = new Uint8Array(image.width * image.height * numberOfChannels);
  let offset = 0;

  for (let i = 0; i < pixelData.length; i++) {
    const val = pixelData[i];

    data[offset++] = val & 0xFF;
    data[offset++] = val >> 8;
  }

  return data;
}

export const uint16DataUtilities = {
  storedPixelDataToImageData
};

uint16Shader.frag = 'precision mediump float;' +
    'uniform sampler2D u_image;' +
    'uniform float ww;' +
    'uniform float wc;' +
    'uniform float slope;' +
    'uniform float intercept;' +
    'uniform int invert;' +
    'uniform vec2 u_textureSize;' +
    'varying vec2 v_texCoord;' +


    'uniform bool isFalseColorActive;' +
    'uniform float frac[%(numFracs)s];' +
    'uniform vec3 colorPoints[%(numColorPoint)s];' +
    'uniform bool isRect;' +
    'uniform int actRects;' +
    'uniform float rectpoints[%(numRects)s * 8];' +
    'uniform vec2 wwwc[%(numRects)s];' +

    'void main() {' +
        // Get texture

        'vec3 point1 = colorPoints[0];' +
        'vec3 point2 = colorPoints[1];' +
        'vec3 point3 = colorPoints[2];' +
        'vec3 point4 = colorPoints[3];' +

        'vec4 color = texture2D(u_image, v_texCoord);' +

        // Calculate luminance from packed texture
        'float intensity0 = color.r*256.0 + color.a*65536.0;' +

        // Rescale based on slope and window settings
        'float intensity1 = intensity0 * slope + intercept;' +
        'float center0 = wc - 0.5;' +
        'float width0 = max(ww, 1.0);' +

        'float intensity = (intensity1 - center0) / width0 + 0.5;' +

        'if(isRect) {' +
          'float m[2];' +
          'm[0] = (v_texCoord*u_textureSize).x;' +
          'm[1] = (v_texCoord*u_textureSize).y;' +
          'bool isPointInRect = false;' +

          'for (int i = 0; i < %(numRects)s; i++) {' +
            'if(i < actRects) {' +
                'isPointInRect = (0.0 < ((m[0] - rectpoints[i*8 + 0]) * (rectpoints[i*8 +2] - rectpoints[i*8 +0])) + ((rectpoints[i*8 +1] - m[1]) * (rectpoints[i*8 +1] - rectpoints[i*8 +3]))) &&' +
                '(((m[0] - rectpoints[i*8 +0]) * (rectpoints[i*8 +2] - rectpoints[i*8 +0])) + ((m[1] - rectpoints[i*8 +1]) * (rectpoints[i*8 +3] - rectpoints[i*8 +1])) < ' +
                '((rectpoints[i*8 +2] - rectpoints[i*8 +0]) * (rectpoints[i*8 +2] - rectpoints[i*8 +0])) + ((rectpoints[i*8 +3] - rectpoints[i*8 +1]) * (rectpoints[i*8 +3] - rectpoints[i*8 +1]))) && ' +
                '(0.0 < ((m[0] - rectpoints[i*8 +0]) * (rectpoints[i*8 +6] - rectpoints[i*8 +0])) + ((rectpoints[i*8 +1] - m[1]) * (rectpoints[i*8 +1] - rectpoints[i*8 +7]))) && ' +
                ' (((m[0] - rectpoints[i*8 +0]) * (rectpoints[i*8 +6] - rectpoints[i*8 +0])) + ((rectpoints[i*8 +1] - m[1]) * (rectpoints[i*8 +1] - rectpoints[i*8 +7]))  < ' +
                '((rectpoints[i*8 +6] - rectpoints[i*8 +0]) * (rectpoints[i*8 +6] - rectpoints[i*8 +0])) + ((rectpoints[i*8 +7] - rectpoints[i*8 +1]) * (rectpoints[i*8 +7] - rectpoints[i*8 +1])));' +
                'if(isPointInRect) {' +
                    'float center1 = wwwc[i].y - 0.5;' +
                    'float width1 = max(wwwc[i].x, 1.0);' +
                    'intensity = (intensity1 - center1) / width1 + 0.5;' +
                ' }' +
              ' }' +
          ' }' +
        ' }' +


        // Clamp intensity
        'intensity = clamp(intensity, 0.0, 1.0);' +


        // RGBA output

        'if(isFalseColorActive) {' +
        ' vec3 res;' +
        '   for (int i = 0; i <= %(numFracs)s; i++) {' +
        '     if(intensity >= (i > 0 ? frac[i-1] : 0.0) && intensity <= (i < %(numFracs)s ? frac[i] : 1.0)){' +
        '       float intensityfrac = (intensity - (i > 0 ? frac[i-1] : 0.0))  / ((i < %(numFracs)s ? frac[i] : 1.0) - (i > 0 ? frac[i-1] : 0.0));' +
        '       res = (1.0 - intensityfrac)*colorPoints[i*2] + intensityfrac*(colorPoints[i*2+1]);' +
        '     }' +
        ' }' +

        ' gl_FragColor = vec4(res[0], res[1], res[2], 1.0);' +
        ' }' +
        'else {' +
          'gl_FragColor = vec4(intensity, intensity, intensity, 1.0);' +
        '}' +
        // Apply any inversion necessary
        'if (invert == 1)' +
            'gl_FragColor.rgb = 1.0 - gl_FragColor.rgb;' +
    '}';

export { uint16Shader };
