import csTools from 'cornerstone-tools';
import cornerstone from "cornerstone-core";

const BaseAnnotationTool = csTools.importInternal('base/BaseAnnotationTool');
const external = csTools.external;
const getToolState = csTools.getToolState;
const addToolState = csTools.addToolState;
const getNewContext = csTools.import("drawing/getNewContext");
const draw = csTools.import("drawing/draw");
const getLogger = csTools.importInternal('util/getLogger');
const Cursors = csTools.importInternal('tools/cursors');
const rectangleRoiCursor = Cursors.rectangleRoiCursor;
const logger = getLogger('tools:annotation:OverviewBlackMaskTool');


/**
 * @public
 * @class OverviewBlackMaskTool
 * @memberof Tools.Annotation
 * @classdesc Tool for displaying/realizing the cut black mask in the overview/navigation
 * @extends Tools.Base.BaseAnnotationTool
 */
export default class OverviewBlackMaskTool extends BaseAnnotationTool {
    constructor(props = {}) {
        const defaultProps = {
            name: 'OverviewBlackMask',
            supportedInteractionTypes: ['Mouse', 'Touch'],
            configuration: {
                drawHandles: true,
                drawHandlesOnHover: false,
                hideHandlesIfMoving: false,
                renderDashed: false,
                shadow: true,
            },
            svgCursor: rectangleRoiCursor,
        };

        super(props, defaultProps);
    } // constructor

    passiveCallback(element) {
      
    }

    activeCallback(element) {
       
    }

    delete(element) {
      
    }

    createNewMeasurement(eventData) {
        if (eventData && eventData.event !== undefined) {
            let td = getToolState(eventData.event.currentTarget, this.name);
            if (td !== undefined && td.data.length !== 0) { return; }
        }
        const goodEventData = eventData && eventData.currentPoints && eventData.currentPoints.image;
        if (!goodEventData) {
            logger.error(
                `required eventData not supplied to tool ${this.name}'s createNewMeasurement`
            );
            return;
        }

        const element = eventData.element;

        if (eventData.event) {
            // TODO check if we really want this (remove all other blackmasks drawing a new one)

            //this.delete(eventData.event.currentTarget);
            //clearToolState(eventData.event.currentTarget, this.name);
        }
        let x = eventData.currentPoints.image.x;
        let y = eventData.currentPoints.image.y;
        var toolData = {
            visible: true,
            active: true,
            color: undefined,
            invalidated: true,
            locked: false,
            handles: {
                "1": {
                    x: x-100,
                    y: y+100,
                    highlight: false,
                    active: false,
                    allowedOutsideImage: true,
                },
                "2": {
                    x: x+100,
                    y: y+100,
                    highlight: false,
                    active: false,
                    allowedOutsideImage: true,
                },
                "3": {
                    x: x+100,
                    y: y-100,
                    highlight: false,
                    active: false,
                    allowedOutsideImage: true,
                },
                "4": {
                    x: x-100,
                    y: y-100,
                    highlight: false,
                    active: false,
                    allowedOutsideImage: true,
                },
                 end: {
                    x: x,
                    y: y,
                    highlight: false,
                    active: false,
                    allowedOutsideImage: true,
                },

                //initialRotation: eventData.viewport.rotation,
                              
            },
            matrixIndex: this.initialConfiguration.matrixIndex,
        };
        
        return toolData;
    } // createNewMeasurement


     // providing an own addNewMeasurement to improve initial interaction
    addNewMeasurement(evt, interactionType) {
        evt.preventDefault();
        evt.stopPropagation();
        const eventData = evt.detail;
        const element = eventData.element;
        const measurementData = this.createNewMeasurement(eventData);

        if (!measurementData) {
            return;
        }

        addToolState(element, this.name, measurementData);

        external.cornerstone.updateImage(element);
 
        const modifiedEventData = {
            toolName: this.name,
            toolType: this.name,
            element: evt.target,
            measurementData: measurementData,
        };
        cornerstone.triggerEvent(evt.target, csTools.EVENTS.MEASUREMENT_COMPLETED, modifiedEventData);
        
        return;
    }
    
    
    pointNearTool(element, data, coords, interactionType) {
        // no interaction     
        return false;
    } // pointNearTool

    renderToolData(evt) {

        var eventData = evt.detail;

        const toolData = getToolState(evt.currentTarget, this.name);

        if (!toolData) {
            return;
        }

        const { image, element, viewport, canvasContext } = eventData;

        if (element === undefined) {
            return;
        }

        var imageWidth = image.columns;
        var imageHeight = image.rows;
        var layerCanvas = document.createElement('canvas');
        layerCanvas.width = imageWidth;
        layerCanvas.height = imageHeight;
        var layerContext = layerCanvas.getContext('2d');
        if (toolData && toolData.data[0]) {            
            if (toolData.data[0].cut) {                
                const h = toolData.data[0].handles;
                let points = [];
                for (var j = 1; j <= 4; j++) {
                    points.push({ x: h[j].x, y: h[j].y })
                }
                let path = new Path2D();
                path.moveTo(0, 0);
                path.lineTo(imageWidth, 0);
                path.lineTo(imageWidth, imageHeight);
                path.lineTo(0, imageHeight);
                path.lineTo(0, 0);
                path.moveTo(points[0].x, points[0].y);
                for (let i = 0; i < points.length; i++)
                    path.lineTo(points[i].x, points[i].y);
                path.lineTo(points[points.length - 1].x, points[points.length - 1].y);

                let minx = imageWidth, maxx = 0, miny = imageHeight, maxy = 0;
                for (let i = 0; i < points.length; i++) {
                    if (points[i].x < minx)
                        minx = points[i].x;
                    if (points[i].x > maxx)
                        maxx = points[i].x;
                    if (points[i].y < miny)
                        miny = points[i].y;
                    if (points[i].y > maxy)
                        maxy = points[i].y;
                }

                layerContext.filter = "opacity(100%)";                

                layerContext.fill(path, "evenodd");

                canvasContext.drawImage(layerCanvas, 0, 0);

                canvasContext.lineWidth = 9;
                canvasContext.beginPath();
                canvasContext.moveTo(0, 0);
                canvasContext.lineTo(imageWidth, 0);
                canvasContext.lineTo(imageWidth, imageHeight);
                canvasContext.lineTo(0, imageHeight);
                canvasContext.lineTo(0, 0);

                canvasContext.strokeStyle = "black";
                canvasContext.stroke();
            }

        }

        const context = getNewContext(eventData.canvasContext.canvas);

        draw(context, context => {
            // If we have tool data for this element - iterate over each set and draw it
            for (let i = 0; i < toolData.data.length; i++) {
                const data = toolData.data[i];

                if (data && (data.matrixIndex === undefined || data.matrixIndex === this.initialConfiguration.matrixIndex)) {

                    if (data.visible === false || data.cut === true) {
                        continue;
                    }                                     
                }
            }
        }); // draw
    } // renderToolData

    //restore(element, data) {
    //}

    //store(toolData) {
    //}
 
    
    updateCachedStats(image, element, data) {
        return;
    }
} // OverviewBlackMaskTool
