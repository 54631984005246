import React, { useEffect, useRef } from "react";
import { addImageMode, loadImage } from "./OrdersSlice";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import { getIsImageLoadRequired, getProtocolStepSelectionProps, selectMatrixIndex, selectedWorkitem } from "../ImageDisplay/ImageDisplaySlice";
import { serviceEndpoints } from "../..";
import { Constants } from "../../Constants";


interface LoadImageProps {
    orderId: string;
    workitemId: string;
    artifactId: string;
    protocolCorrelationId: string | undefined;
    artifactId_Raw: string | undefined;
    currentProtocolStep?: any;
}

const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
const useAppDispatch = () => useDispatch<AppDispatch>();

const useIsMount = () => {
    const isMountRef = useRef(true);
    useEffect(() => {
        isMountRef.current = false;
    }, []);
    return isMountRef.current;
};

const LoadImage = (props: LoadImageProps) => {
    const dispatch = useAppDispatch();

    const selectedMatrixIndex: number | undefined = useAppSelector((state) => selectMatrixIndex(state));
    const currentWorkitemId: string = useAppSelector((state) => selectedWorkitem(state));
    const protocolStepSelectionProps: string = useAppSelector((state) => getProtocolStepSelectionProps(state, props.workitemId));
    const isImageLoadRequired: boolean = useAppSelector((state) => getIsImageLoadRequired(state));


    const isMount = useIsMount();

    /* useEffect(() => {
        if (currentWorkitemId === props.workitemId && !isMount) {
            console.log("LoadImage: " + isMount + " " + props.artifactId + " " + currentWorkitemId + " " + selectedMatrixIndex + " " + protocolStepSelectionProps);
            dispatch(
                loadImage({
                    imageName: `${serviceEndpoints.ARTIFACT_STORE_URL}/artifacts/${props.artifactId}/raw`,
                    imageId: currentWorkitemId,
                    artifactId: props.artifactId,
                    artifactId_Raw: undefined,
                    createPreviewImage: addImageMode.none,
                    matrixIndex: selectedMatrixIndex ?? -1,
                    display_raw: false,
                    enabledElementForExport: undefined,
                })
            );
        }

    }, [props.artifactId]); */


    useEffect(() => {
        if (!isMount && isImageLoadRequired && currentWorkitemId === props.workitemId &&
            props?.currentProtocolStep?.type === Constants.PROCESSING_STEP_TYPE &&
            props?.currentProtocolStep?.performed !== undefined && props?.currentProtocolStep?.rejected === undefined) {
            console.log("LoadImage: " + isMount + " " + props.artifactId + " " + props.artifactId_Raw + " " + currentWorkitemId);

            dispatch(
                loadImage({
                    imageName: `${serviceEndpoints.ARTIFACT_STORE_URL}/artifacts/${props.artifactId}/raw`,
                    imageId: currentWorkitemId,
                    artifactId: props.artifactId,
                    artifactId_Raw: props.artifactId_Raw,
                    createPreviewImage: addImageMode.none,
                    matrixIndex: selectedMatrixIndex ?? -1,
                    display_raw: false,
                    enabledElementForExport: undefined,
                })
            );

            dispatch({ type: "ImageDisplay/setIsImageLoadRequired", payload: false });
        }


    }, [isImageLoadRequired, props.artifactId]);

    /* useEffect(() => {
        if (currentWorkitemId === props.workitemId && !isMount) {

            dispatch(
                loadImage({
                    imageName: `${serviceEndpoints.ARTIFACT_STORE_URL}/artifacts/${props.artifactId}/raw`,
                    imageId: currentWorkitemId,
                    artifactId: props.artifactId,
                    artifactId_Raw: undefined,
                    createPreviewImage: addImageMode.none,
                    matrixIndex: selectedMatrixIndex ?? -1,
                    display_raw: false,
                    enabledElementForExport: undefined,
                    correlationId: undefined,
                })
            );
        }
    }, [props.artifactId, currentWorkitemId, selectedMatrixIndex, protocolStepSelectionProps]); */

    return null;
}

export default LoadImage;
