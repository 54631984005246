import React from "react";

import { TypedUseSelectorHook, useDispatch, useSelector, } from "react-redux";
import { AppDispatch, RootState } from "../../store";


import "./PregnancyStatus.scss";
import { getSelectedSubject } from "./SubjectSlice";
import { useORTranslation } from "../Localization/ORLocalization";

type PregnancyStatusProps = {
    required?: boolean;
};

const useAppDispatch = () => useDispatch<AppDispatch>();
const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

const PregnancyStatus = (props: PregnancyStatusProps) => {

    const dispatch = useAppDispatch();

    const { t } = useORTranslation(['Subject', 'common']);

    const subject: any = useAppSelector((state) => getSelectedSubject(state));

    const onPregnancyClicked = (value: string) => {
        const newSubject: any = structuredClone(subject);
        const { details } = newSubject;

        newSubject.details = { ...details, pregnancyStatus: value };
        dispatch({ type: "Subject/setSelectedSubject", payload: newSubject });
    }

    return (
        <div className="Pregnancy">
            <label>
                <span>{t('Pregnancy', {ns: 'InfoPanel'})}</span>
                <span className="required">&nbsp;{`${props.required ? '*' : ''}`}</span>
            </label>
            <div className="PregnancyButtons">
                <button className={`${subject?.details?.pregnancyStatus === 'definitely_pregnant' ? 'PregnancyButton_selected' : 'PregnancyButton'}  venaDefaultButton`} onClick={() => onPregnancyClicked('definitely_pregnant')} >{t('yes', {ns: 'common'})}</button>
                <button className={`${subject?.details?.pregnancyStatus === 'not_pregnant' ? 'PregnancyButton_selected' : 'PregnancyButton'}  venaDefaultButton`} onClick={() => onPregnancyClicked('not_pregnant')} >{t('no', {ns: 'common'})}</button>
                <button className={`${subject?.details?.pregnancyStatus === 'possibly_pregnant' ? 'PregnancyButton_selected' : 'PregnancyButton'}  venaDefaultButton`} onClick={() => onPregnancyClicked('possibly_pregnant')} >{t('maybe', {ns: 'common'})}</button>
                <button className={`${subject?.details?.pregnancyStatus === 'unknown' ? 'PregnancyButton_selected' : 'PregnancyButton'}  venaDefaultButton`} onClick={() => onPregnancyClicked('unknown')} >{t('unknown', {ns: 'common'})}</button>
            </div>
        </div>
    );
};

export default PregnancyStatus;
