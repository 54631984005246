import csTools from 'cornerstone-tools';
import cornerstone from "cornerstone-core";
import opentype from "opentype.js";
import store from './../../../store';


const BaseAnnotationTool = csTools.importInternal('base/BaseAnnotationTool');
const MouseCursor = csTools.import("tools/cursors/MouseCursor");
const getNewContext = csTools.import("drawing/getNewContext");
const getToolState = csTools.getToolState;
const addToolState = csTools.addToolState;
const draw = csTools.import("drawing/draw");
const setShadow = csTools.import("drawing/setShadow");

const Cursors = csTools.importInternal('tools/cursors');
const rectangleRoiCursor = Cursors.rectangleRoiCursor;
const getLogger = csTools.importInternal('util/getLogger');
const external = csTools.external;
const logger = getLogger('tools:annotation:FlippedAnnotationTool');

/**
 * @public
 * @class FlippedAnnotationTool
 * @memberof Tools
 *
 * @classdesc Tool for managing the automatic flipped annotation.
 * @extends BaseAnnotationTool
 */
export default class LRMarkerTool extends BaseAnnotationTool {
	/** @inheritdoc */
	constructor(props = {}) {
		const defaultProps = {
			name: 'FlippedAnnotation',
			supportedInteractionTypes: ['Mouse', 'Touch'],
			configuration: {
				drawHandles: false,
				//drawHandlesOnHover: true,
				hideHandlesIfMoving: true,
				renderDashed: false,
				shadow: true,
				text: undefined,
			},
			svgCursor: rectangleRoiCursor,
		};
 		
		super(props, defaultProps);
	}


	activeCallback(element) {
	
	} // activeCallback


	createNewMeasurement(eventData) {
		let toolData = undefined;
		if (eventData && eventData.event !== undefined) {
			toolData = getToolState(eventData.event.currentTarget, this.name);
			// TODO this would avoid creating a second anno of this type...
			if (toolData !== undefined && toolData.data.length !== 0) { return; }
		}
		const goodEventData =
			eventData && eventData.currentPoints && eventData.currentPoints.image;

		if (!goodEventData) {
			logger.error(
				`required eventData not supplied to tool ${this.name}'s createNewMeasurement`
			);
			return;
		}

		const element = eventData.element;
		var image = cornerstone.getImage(element);
		let imageId = image.image_raw ? image.image_raw.targetId : undefined;
		if (imageId === undefined) {
			console.log("no raw image");
			return;
		}

		if (!this.svg || this.text !== this.options.text) {
			const state = store.getState();
			const currentTheme = state.MainOrderListColumns.theme;

			let font = "Vera.ttf";
			if (this.options.font !== undefined)
				font = this.options.font;

			opentype.load("/Themes/" + currentTheme + "/fonts/" + font, (err, font) => {

				if (err) {
					console.log(err);
					return;
				}

				let text = "flipped";

				if (this.options.text !== undefined)
					text = this.options.text;

				let size = 144;

				if (this.options.size !== undefined)
					size = this.options.size;

				let options = { kerning: true, language: "en", script: "latn" };
				let path = font.getPath(text, 0, 0, size, options);
				let svg = path.toSVG();

				this.text = text;
				this.svg = svg;
				this.fontSize = size;

			}); // opentype.load
		} // if have no svg yet

		// TODO displace original coordinate by font size/2 so that Letter appears centered around click?
		return {
			visible: true,
			active: false,
			color: undefined,
			invalidated: true,
			artifactId: _getArtifactIDFromURI(imageId),
			svg: this.svg,
			text: this.text,
			fontSize: this.fontSize,
			handles: {
				start: {
					x: eventData.currentPoints.image.x,
					y: eventData.currentPoints.image.y,
					highlight: false,
					active: true,
				},
				end: {
					x: eventData.currentPoints.image.x,
					y: eventData.currentPoints.image.y,
					highlight: false,
					active: true,
				},
				/* initialRotation: eventData.viewport.rotation,
				textBox: {
				  active: false,
				  hasMoved: false,
				  movesIndependently: false,
				  drawnIndependently: true,
				  allowedOutsideImage: true,
				  hasBoundingBox: true,
				}, */
			},
			matrixIndex: this.initialConfiguration.matrixIndex,
		};
	} // createNewMeasurement

	// providing an own addNewMeasurement to improve initial interaction
    addNewMeasurement(evt, interactionType) {		
        evt.preventDefault();
        evt.stopPropagation();
        const eventData = evt.detail;
        const element = eventData.element;
        const measurementData = this.createNewMeasurement(eventData);

        if (!measurementData) {
            return;
        }

        addToolState(element, this.name, measurementData);

        external.cornerstone.updateImage(element);
 
        const modifiedEventData = {
            toolName: this.name,
            toolType: this.name,
            element: evt.target,
            measurementData: measurementData,
        };
        cornerstone.triggerEvent(evt.target, csTools.EVENTS.MEASUREMENT_COMPLETED, modifiedEventData);
        
        return;
    }
    
	pointNearTool(element, data, coords, interactionType) {

		const hasHandles =
			data && data.handles && data.handles.start && data.handles.end;
		const validParameters = hasHandles;

		if (!validParameters) {
			logger.warn(
				`invalid parameters supplied to tool ${this.name}'s pointNearTool`
			);
		}

		if (!validParameters || data.visible === false) {
			return false;
		}

		let end = { x: data.handles.start.x, y: data.handles.start.y };

		let num = 1;
		if (data.text)
			num = data.text.length;

		end.x += num*data.fontSize;
		end.y -= data.fontSize;

		const startCanvas = cornerstone.pixelToCanvas(
			element,
			data.handles.start
		);
		const endCanvas = cornerstone.pixelToCanvas(
			element,
			end
		);
		
		let rect = {
			left: Math.min(startCanvas.x, endCanvas.x),
			top: Math.min(startCanvas.y, endCanvas.y),
			width: Math.abs(startCanvas.x - endCanvas.x),
			height: Math.abs(startCanvas.y - endCanvas.y),
		};

		let distance = interactionType === 'mouse' ? 15 : 25;
		
		const distanceToPoint = external.cornerstoneMath.rect.distanceToPoint(
			rect,
			coords
		);
		
        let dxc = Math.abs(startCanvas.x - endCanvas.x);
        let dyc = Math.abs(startCanvas.y - endCanvas.y);
        
        endCanvas.x = startCanvas.x + dxc;
        endCanvas.y = startCanvas.y - dyc;
        
        let inside = false;
        if(coords.x < Math.max(startCanvas.x, endCanvas.x) &&
           coords.x > Math.min(startCanvas.x, endCanvas.x) &&
           coords.y < Math.max(startCanvas.y, endCanvas.y) &&
           coords.y > Math.min(startCanvas.y, endCanvas.y))
           inside = true;

        return inside;//distanceToPoint < distance;
	} // pointNearTool


	renderToolData(evt) {
		const toolData = csTools.getToolState(evt.currentTarget, this.name);

		if (!toolData) {
			return;
		}

		const eventData = evt.detail;
		const { image, element } = eventData;

		const context = getNewContext(eventData.canvasContext.canvas);

		draw(context, context => {
			// If we have tool data for this element - iterate over each set and draw it
			for (let i = 0; i < toolData.data.length; i++) {
				const data = toolData.data[i];

				if (data.visible === false) {
					continue;
				}

				// Configure
				let color = csTools.toolColors.getColorIfActive(data);

				//setShadow(context, this.configuration);

				const startCanvas = cornerstone.pixelToCanvas(
					element,
					data.handles.start
				);

				if (data.svg) {
					let pd = data.svg.slice(9, -3);
					let svgPath = new Path2D(pd);
					context.save();
					context.translate(startCanvas.x, startCanvas.y);
					if(data.active === true) {
					    context.strokeStyle = "white";
					    const viewport = cornerstone.getViewport(element);
					    context.scale(viewport.scale, viewport.scale);
					    context.lineWidth = 2.0;
					    context.stroke(svgPath);
					} else {
					    context.fillStyle = "black";
					    const viewport = cornerstone.getViewport(element);
					    context.scale(viewport.scale, viewport.scale);
					    context.stroke(svgPath);
					    context.lineWidth = 2.0;
					    //context.fill(svgPath);
					    context.fillStyle = "white";
					    //context.translate(5, 5);
					    context.fill(svgPath);
					}
					context.restore();
				}
			}
		});
	} // renderToolData


	restore(element, data) {
		let measurement = undefined;
		if (data.handle !== undefined) {
			var xs = data.handle.x;
			var ys = data.handle.y;

			measurement = this.createNewMeasurement(
				{
					currentPoints: { image: { x: xs, y: ys } },
					viewport: { rotation: undefined },
					element: element,
				});
			if (measurement) {
				if (data.svg)
					measurement.svg = data.svg;
				if (data.size)
					measurement.fontSize = data.size;
				if (data.text)
					measurement.text = data.text;
			}
		}

		return measurement;
	}

	store(toolData) {
		toolData.storeToolData = {
			"handle": toolData.handles.start,
			"svg": toolData.svg,
			"text": toolData.text,
			"size": toolData.fontSize,			
		}
	}

	updateCachedStats(image, element, data) { }

} // LRMarkerTool


function _getArtifactIDFromURI(uri) {
	let url = new URL(uri);

	let path = url.pathname;

	let pc = path.split('/');
	for (var i = 0; i < pc.length; i++) {
		if (pc[i] === "artifacts") {
			return pc[i + 1];
		}
	}

	return undefined;
}

function setToolCursor(element, svgCursor) {

	if (!csTools.getModule('globalConfiguration').configuration.showSVGCursors) {
		return;
	}
	// TODO: (state vs options) Exit if cursor wasn't updated
	// TODO: Exit if invalid options to create cursor

	// Note: Max size of an SVG cursor is 128x128, default is 32x32.
	const cursorBlob = svgCursor.getIconWithPointerSVG();
	const mousePoint = svgCursor.mousePoint;

	const svgCursorUrl = window.URL.createObjectURL(cursorBlob);

	element.style.cursor = `url('${svgCursorUrl}') ${mousePoint}, auto`;

	csTools.store.state.svgCursorUrl = svgCursorUrl;
}
