import csTools from 'cornerstone-tools';
import cornerstone from "cornerstone-core";
import { fitToWindow } from "./utils";

const BaseTool = csTools.importInternal('base/BaseTool');

/**
 * @public
 * @class FlipHorizontalTool
 * @memberof Tools
 *
 * @classdesc Tool for switching the horizontal image orientation.
 * @extends Tools.Base.BaseTool
 */
export default class FlipHorizontalTool extends BaseTool {
    /** @inheritdoc */
    constructor(props = {}) {
        const defaultProps = {
            name: 'FlipHorizontal',
        };
        super(props, defaultProps);
    }

    activeCallback(element : HTMLElement) {
        let viewport = cornerstone.getViewport(element);
        if (viewport) {
            const canvas: HTMLCanvasElement | undefined = cornerstone.getEnabledElement(element).canvas;
            if (canvas) {
                const image: cornerstone.Image | undefined = cornerstone.getEnabledElement(element).image;
                if (image) {
					const canvasToPixelStart = cornerstone.canvasToPixel(element, { x: 0, y: 0, _canvasCoordinateBrand: "" });
					const canvasToPixelEnd = cornerstone.canvasToPixel(element, { x: canvas?.width ?? 0, y: canvas?.height ?? 0, _canvasCoordinateBrand: "" });
					viewport.translation!.x -= (viewport.hflip ? -1 : 1) * (image?.width - (canvasToPixelEnd.x + canvasToPixelStart.x));
                    viewport.hflip = !viewport.hflip;
                    cornerstone.setViewport(element, viewport);
                    // @ts-ignore
					if (image.cutwidth !== undefined) {
						viewport = cornerstone.getViewport(element);
						if (viewport !== undefined) {
							let scale = viewport.scale;
							fitToWindow(element);
							viewport.scale = scale;
							cornerstone.setViewport(element, viewport);
						}
					}

					let eventData = {
						toolName: "StoreViewport",
						toolType: "StoreViewport",
						element: element,
						measurementData: undefined,
					};
					// @ts-ignore
					cornerstone.triggerEvent(element, csTools.EVENTS.MEASUREMENT_COMPLETED, eventData);
					
					eventData = {
						toolName: "FlipHorizontal",
						toolType: "FlipHorizontal",
						element: element,
						measurementData: undefined,
					};
					// @ts-ignore
					cornerstone.triggerEvent(element, csTools.EVENTS.MEASUREMENT_COMPLETED, eventData);
				}
            }
        }
    }
}
