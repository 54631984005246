import {createSlice } from '@reduxjs/toolkit'

import { RootState } from '../../store'

export type GeneratorCatalogueItems = {
    pulse?: number;
    time?: number;
    mAs?: number;
    kv?: number;
}

export type SensorCatalogueItems = {
    frameTime?: number;
    frameNumber?: number;
    mean?: boolean;
}

export type ImageProcessingParameter = {
    rotation?: number;
    flip?: number;
    parameter?: string;
}

export type CatalogueEntry = {
    id: string;
    catalogueId?: string;
    title: string;
    description?: string;
    icon: string;
    generator: GeneratorCatalogueItems;
    sensor: SensorCatalogueItems;
    imageProcessing: ImageProcessingParameter
}

export interface ICatalogueSlice {
    selectedCatalogueEntry?: string;
    CatalogueEntries: CatalogueEntry[];
}

const initialState: ICatalogueSlice = {
    selectedCatalogueEntry: '0',
    CatalogueEntries: [{
        id: '0',
        catalogueId: '0',
        title: 'Default',
        description: 'Default settings',
        icon: '<svg enable-background="new 0 0 200 200" version="1.0" viewBox="0 0 200 200"> <style type="text/css">.st0{fill:none;stroke:#FEFEFE;stroke-width:5;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}</style> <polygon class="st0" points="98.1 20 28.3 43.2 106.9 83.3 175.4 48.9"/> <polygon class="st0" points="112.1 185.2 112.3 91.9 176 57.5 175 136.8"/> <polygon class="st0" points="103.4 185.2 102.6 92.3 27.3 51.5 28.3 131"/> </svg>',
        generator: { pulse: 14, time: 4, mAs: 0, kv: 0 },
        sensor: { frameTime: 11, frameNumber: 5, mean: true },
        imageProcessing: { rotation: 0, flip: 0, parameter: 'default' },
    }]
};

const CatalogueSlice = createSlice({
    name: 'Catalogue',
    initialState,
    reducers: {
        setSelectedCatalogueEntry(state, action) {
            const index: string = action.payload;
            state.selectedCatalogueEntry = index;
        },
        setGeneratorPulse(state, action) {
            const pulse: number = action.payload;
            const selectedCatalogueItem: CatalogueEntry | undefined = state.CatalogueEntries.find((entry: CatalogueEntry) => entry.id === state.selectedCatalogueEntry);
            if (selectedCatalogueItem) {
                selectedCatalogueItem.generator.pulse = pulse;
            }
        },
        setGeneratorTime(state, action) {
            const time: number = action.payload;
            const selectedCatalogueItem: CatalogueEntry | undefined = state.CatalogueEntries.find((entry: CatalogueEntry) => entry.id === state.selectedCatalogueEntry);
            if (selectedCatalogueItem) {
                selectedCatalogueItem.generator.time = time;
            }
        },
        setSensorFrameTime(state, action) {
            const frameTime: number = action.payload;
            const selectedCatalogueItem: CatalogueEntry | undefined = state.CatalogueEntries.find((entry: CatalogueEntry) => entry.id === state.selectedCatalogueEntry);
            if (selectedCatalogueItem) {
                selectedCatalogueItem.sensor.frameTime = frameTime;
            }
        },
        setSensorFrameNumber(state, action) {
            const frameNumber: number = action.payload;
            const selectedCatalogueItem: CatalogueEntry | undefined = state.CatalogueEntries.find((entry: CatalogueEntry) => entry.id === state.selectedCatalogueEntry);
            if (selectedCatalogueItem) {
                selectedCatalogueItem.sensor.frameNumber = frameNumber;
            }
        },
        setSensorIsMean(state, action) {
            const isMean: boolean = action.payload;
            const selectedCatalogueItem: CatalogueEntry | undefined = state.CatalogueEntries.find((entry: CatalogueEntry) => entry.id === state.selectedCatalogueEntry);
            if (selectedCatalogueItem) {
                selectedCatalogueItem.sensor.mean = isMean;
            }
        },
        setCatalogue(state, action) {
            const catalogueEntry: CatalogueEntry = action.payload;
            state.CatalogueEntries.push(catalogueEntry);
        },
        purgeCatalogue(state, action) {
            state.CatalogueEntries.splice(1, state.CatalogueEntries.length);
        },
    }
});

export const { setSelectedCatalogueEntry, setGeneratorPulse, setGeneratorTime, setSensorFrameTime, setSensorFrameNumber, setSensorIsMean, setCatalogue, purgeCatalogue } = CatalogueSlice.actions
export default CatalogueSlice.reducer;

export const getCatalogueEntries = (state: RootState) =>
    state.Catalogue.CatalogueEntries;

export const getSelectedCatalogueEntry = (state: RootState) => {
    const selectedCatalogueEntry = state.Catalogue.CatalogueEntries.find((entry: CatalogueEntry) => entry.id === state.Catalogue.selectedCatalogueEntry);
    return selectedCatalogueEntry;
}

export const getCatalogueEntryForId = (state: RootState, id: string) => {
    const selectedCatalogueEntry = state.Catalogue.CatalogueEntries.find((entry: CatalogueEntry) => entry.id === id);
    return selectedCatalogueEntry;
}
