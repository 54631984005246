import React from "react";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import { wsAcquisition } from '../../apis/websocket/websocket';
import { isSelectedWorkitemPlanning } from "../OrderList/OrdersSlice";
import { selectedWorkitem, selectToolInsetVisible } from "../ImageDisplay/ImageDisplaySlice";
import { selectDeviceRunnerError, setAcquisitionActive } from "./AcquisitionSlice";


interface XRayButtonProps {
    colorCode: number;
    orderId: string;
    openDeviceSet: () => void;
}

const useAppDispatch = () => useDispatch<AppDispatch>();

const Inset1 = (currentClassName: string, colorCode: number) =>
    <React.Fragment>
        <g transform="translate(-91.328 -346.79)">
            <path className={currentClassName} id={colorCode.toString()} d="m174.62 422.25a2.565 2.565 0 1 0-2.58-2.579 2.583 2.583 0 0 0 2.58 2.579z" />
            <path className={currentClassName} id={colorCode.toString()} d="m178.44 419.58a3.445 3.445 0 0 0-1.729-3.4l4.479-7.908c4.167 2.692 6.6 6.321 6.435 11.31z" />
            <path className={currentClassName} id={colorCode.toString()} d="M172.633,422.928a3.388,3.388,0,0,0,3.8.141l4.7,7.767c-4.393,2.353-8.73,2.693-13.011.114Z" />
            <path className={currentClassName} id={colorCode.toString()} d="m170.82 419.58a3.428 3.428 0 0 1 1.7-3.4l-4.451-7.908c-4.2 2.692-6.6 6.321-6.462 11.31z" />
        </g>
        <g transform="translate(-148.43 -348.06)">
            <path className="XRayButtonc" d="m278.88 421.96-11.82 20.441-11.792 20.438h-47.225l-11.793-20.438-11.82-20.438 11.82-20.466 11.792-20.438h47.225l11.792 20.438z" />
            <path className={currentClassName} id={colorCode.toString()} d="m231.62 428a6.9 6.9 0 1 0-6.917-6.888 6.92 6.92 0 0 0 6.917 6.888z" />
            <path className={currentClassName} id={colorCode.toString()} d="m226.86 429.58a10.209 10.209 0 0 0 9.326-0.028l10.715 19.9c-11.622 5.159-18.964 5.018-30.558 0z" />
            <path className={currentClassName} id={colorCode.toString()} d="m226.46 413.06a10.2 10.2 0 0 0-4.621 8.107l-22.592-0.68c1.332-12.642 5.131-18.935 15.251-26.447z" />
            <path className={currentClassName} id={colorCode.toString()} d="m236.84 413.06a10.274 10.274 0 0 1 4.62 8.107l22.592-0.68c-1.332-12.642-5.131-18.935-15.279-26.447z" />
        </g>
    </React.Fragment>;

const Inset2 = (currentClassName: string, colorCode: number) =>
    <React.Fragment>
        <g transform="translate(-148.42 -348.06)">
            <path className="XRayButtonc" d="m278.88 421.96-11.82 20.441-11.792 20.438h-47.225l-11.793-20.438-11.82-20.438 11.82-20.466 11.792-20.438h47.225l11.792 20.438z" />
            <path className={currentClassName} id={colorCode.toString()} transform="translate(215.43 450.06)" d="M9.814-22.314V-57.1H23.682v34.781ZM8.4-6.494a7.967,7.967,0,0,1,2.441-5.884A8.147,8.147,0,0,1,16.8-14.795,8.05,8.05,0,0,1,22.681-12.4,7.985,7.985,0,0,1,25.1-6.494,8.147,8.147,0,0,1,22.681-.537,7.967,7.967,0,0,1,16.8,1.9a8.048,8.048,0,0,1-5.933-2.49A8.112,8.112,0,0,1,8.4-6.494Z" />
        </g>
    </React.Fragment>;

const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;


const XRayButton = (props: XRayButtonProps) => {

    const currentClassName: string = "XRayButtonBackground";

    const dispatch = useAppDispatch();

    const onClick = (evt: React.MouseEvent<Element>) => {
        dispatch(wsAcquisition());
        if (props.colorCode === 2) {
            /* dispatch({ type: 'Acquisition/setIsAcquistionOngoing', payload: true });
            if (exposeActionUrl && exposeActionUrl.length > 0) {
                dispatch(apiSlice.endpoints.postWebThingAction.initiate({ url: exposeActionUrl, body: { expose: { input: {} } } }));
                dispatch(apiSlice.endpoints.postWebThingAction.initiate({ url: triggerImageUrl, body: { triggerImage: { input: {} } } }));
            } */

            props?.openDeviceSet();
        }
    };

    const isWorkitemPlanning: boolean = useAppSelector((state) => isSelectedWorkitemPlanning(state));
    const currentWorkitemId: string = useAppSelector((state) => selectedWorkitem(state));
    //const isAkquisitionButtonActive: boolean = useAppSelector((state) => state.Acquisition.isAcquistionButtonActive);
    const isToolInsetVisible: boolean | undefined = useAppSelector((state) => selectToolInsetVisible(state));
    const deviceRunnerError: string = useAppSelector((state) => selectDeviceRunnerError(state));

    const activate = (evt: React.MouseEvent<Element>) => {
        evt.stopPropagation();
        /* if (props.colorCode <= -1) {
            if (isWorkitemPlanning) {
                dispatch(setAcquisitionActive({ setAcquistionButtonActive: !isAkquisitionButtonActive, studyId: props.orderId, workitemId: currentWorkitemId }));
            } else {
                dispatch(setAcquisitionActive({ setAcquistionButtonActive: !isAkquisitionButtonActive, studyId: props.orderId, workitemId: undefined }));
            }
        } */

        if (isWorkitemPlanning) {
            dispatch(setAcquisitionActive({ setAcquistionButtonActive: true, studyId: props.orderId, workitemId: currentWorkitemId }));
        } else {
            dispatch(setAcquisitionActive({ setAcquistionButtonActive: true, studyId: props.orderId, workitemId: undefined }));
        }
    };

    return (
        <svg className={props.colorCode <= -1 ? (isToolInsetVisible ? 'XRayButtonSVG_inactive_shifted' : 'XRayButtonSVG_inactive') : 'XRayButtonSVG'}
            version="1.1" viewBox="0 0 184.65 162.31" onClick={(evt) => activate(evt)}>
            <defs>
                <style>{".XRayButtonb,.XRayButtonc{fill - rule:evenodd;}.XRayButtonc{fill:#002936;}.XRayButtond{filter:url(#a);}"}</style>
                <filter id="a" x="0" y="0" width="184.65" height="162.31" filterUnits="userSpaceOnUse">
                    <feOffset dy="3" />
                    <feGaussianBlur result="b" stdDeviation="3" />
                    <feFlood floodOpacity=".502" />
                    <feComposite in2="b" operator="in" result="result1" />
                    <feComposite in="SourceGraphic" in2="result1" />
                </filter>
            </defs>
            <g transform="translate(9 6)">

                <g className="XRayButtond" transform="translate(-9 -6)">
                    <path className={currentClassName} id={props.colorCode.toString()} transform="translate(-83.33 -340.79)" d="M258.977,418.959l-20.835,36.085L217.307,491.1H134l-20.835-36.057L92.328,418.959l20.835-36.085L134,346.789h83.31l20.835,36.085Z" />
                </g>
                <g className={`${currentClassName}_hover`} onClick={(evt) => onClick(evt)}>
                    {props.colorCode >= 3 ? Inset2(currentClassName, props.colorCode) : Inset1(currentClassName, props.colorCode)}
                </g>
                {deviceRunnerError !== '' ?
                    <text x="138" y="88" fill="red" fontSize="4em">!</text>
                    : null}
            </g>
        </svg>
    );

}

export default XRayButton;
