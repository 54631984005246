import csTools from 'cornerstone-tools';
import cornerstone from "cornerstone-core";
//import { setGetToolStateItems, updateAnnnotationOnInputChange } from "./utils";
//import store from './../../../store';
import { fitToWindow } from "./utils";

const BaseTool = csTools.importInternal('base/BaseTool');
const getToolState = csTools.getToolState;
const removeToolState = csTools.removeToolState;

/**
 * @public
 * @class DeclineBlackMaskTool
 * @memberof Tools
 *
 * @classdesc DeclineBlackMaskTool
 * @extends Tools.Base.BaseTool
 */
export default class DeclineBlackMaskTool extends BaseTool {
    /** @inheritdoc */
    constructor(props = {}) {
        const defaultProps = {
            name: 'DeclineBlackMask',
        };
        super(props, defaultProps);
    }

    activeCallback(element) {
        //const state = store.getState();
        //const matrixIndex = state.ImageDisplay.selectedIndex;
        let bmname = 'BlackMask';
        let needFit = false;
        let needUnrotate = false;
        let toolData = getToolState(element, bmname);
        if (toolData !== undefined && toolData.data) {
            for (let i = 0; i < toolData.data.length; i++) {
                let data = toolData.data[i];
                if (data.cut) {                    
                    needFit = true;
                }
                if(data.angle !== undefined) {                    
                    needUnrotate = true;
                }
                removeToolState(element, bmname, data);                
            }
            //cornerstone.triggerEvent(element, "declineblackmask");
        }
		const enabledElement = cornerstone.getEnabledElement(element);
        if (needUnrotate) {            
            enabledElement.viewport.rotation = 0;
        }
        if (needFit) {
            // can not rely on proper viewport management after reloading the annotation because we deleted it
            // so we need to clean up the image state wrt. cutting manually
            let image = enabledElement.image;
            if (image) {
                image.cutwidth = undefined;
                image.cutheight = undefined;
                image.cutx = undefined;
                image.cuty = undefined;                
            }
           
            fitToWindow(element);
        }
		let image = enabledElement.image;
		if (image) {
			// instruct the BlackMaskTool to store the viewport once after its image cut border computation is done
			image.storevp = true;
		}
		cornerstone.updateImage(element, false);
    }
}
