import cornerstone from "cornerstone-core";
import UTIF from "utif";
import { GetImageType } from "../Utils/GetImageType";
import { calculateMinMaxMeanVar, getWindowWidthFromMinMaxMeanVar, swap16 } from "./Tools/utils";

let loadTime: number = 0;

function checkStatus(response: Response) {
    if (!response.ok) {
        //throw new Error(`HTTP ${response.status} - ${response.statusText}`);
        console.log(`failed to load image:  HTTP ${response.status} - ${response.statusText}`);
    }
    return response;
}


function loadtifImage(imageUri: string) {
    loadTime = (new Date()).getTime();
    const imageId: string = imageUri.slice(imageUri.indexOf(':') + 1);
    const promise = new Promise<cornerstone.Image>((resolve, reject) => {
        fetch(imageId)
            .then(response => checkStatus(response) && response.arrayBuffer())
            .then(buffer => {
                if (buffer && buffer instanceof ArrayBuffer) {
                    const byteArray = new Uint8Array(buffer).subarray(0, 132);
                    const fileType: string | undefined = GetImageType(byteArray)?.fileType;

                    if (fileType === 'TIF') {
                        var ifds = UTIF.decode(buffer);
                        const isMSB = (new TextDecoder().decode(byteArray.subarray(0, 2))) === 'MM';
                        if (Array.isArray(ifds) && ifds.length > 0) {

                            const ifds0: UTIF.IFD = ifds[0];
                            if (ifds0.t256 && ifds0.t257 && ifds0.t273) {
                                const height = (ifds0.t257 as Uint8Array)[0];
                                const width = (ifds0.t256 as Uint8Array)[0];

                                const bits = ifds0.t258 as number;
                                const bitRange = Math.pow(2, bits);

                                //let offset = ((ifds0.t273 as Uint8Array)[0]) / 2;
                                let offset = ((ifds0.t273 as Uint8Array)[0]);

                                let photometricInterpretation = 1;
                                if (ifds0.t262) {
                                    photometricInterpretation = (ifds0?.t262 as Uint8Array)[0];
                                }

                                //const byteArray = new Uint16Array(buffer).subarray(offset);
                                const byteArray = new Uint16Array(buffer.slice(offset));
                                const timeDiff: number = (new Date()).getTime() - loadTime;

                                // const windowLevelTime = (new Date()).getTime();
                                const minMaxMean = calculateMinMaxMeanVar(
                                    byteArray,
                                    0,
                                    bitRange - 1
                                );

                                //const timeDiffWindowLevel: number = (new Date()).getTime() - windowLevelTime;
                                //console.log(timeDiffWindowLevel);

                                const getPixelData = () => {return isMSB ? (byteArray.map((val: number) => swap16(val))) : byteArray};

                                const image = {
                                    imageId,
                                    minPixelValue: minMaxMean.min,
                                    maxPixelValue: minMaxMean.max,
                                    slope: 1,
                                    intercept: 0,
                                    windowCenter: minMaxMean.mean,
                                    windowWidth: getWindowWidthFromMinMaxMeanVar(minMaxMean),
                                    //getPixelData: () => {console.log("getPixelData"); return isMSB ? (byteArray.map((val: number) => swap16(val))) : byteArray},
                                    pixelData: getPixelData(),
                                    getPixelData: () => image.pixelData,
                                    rows: height,
                                    columns: width,
                                    height: height,
                                    width: width,
                                    color: false,
                                    rgba: false,
                                    invert: false,
                                    sizeInBytes: height * width * 2,
                                    totalTimeInMS: timeDiff,
                                    photometricInterpretation: photometricInterpretation,
                                };
                                resolve(image as unknown as cornerstone.Image);
                            }
                        }
                    } else {
                        if (buffer && buffer.byteLength >= 8) {

                            const view1 = new DataView(buffer, 0, 4)
                            const view2 = new DataView(buffer, 4, 4)

                            const height = view2.getUint32(0, true);
                            const width = view1.getUint32(0, true);

                            const byteArray = new Uint16Array(buffer).subarray(4);
                            const minMaxMean = calculateMinMaxMeanVar(
                                byteArray,
                                0,
                                65535
                            );

                            const timeDiff: number = (new Date()).getTime() - loadTime;
                            const image = {
                                imageId,
                                minPixelValue: minMaxMean.min,
                                maxPixelValue: minMaxMean.max,
                                slope: 1,
                                intercept: 0,
                                windowCenter: minMaxMean.mean,
                                windowWidth: getWindowWidthFromMinMaxMeanVar(minMaxMean),
                                getPixelData: () => byteArray,
                                rows: height,
                                columns: width,
                                height: height,
                                width: width,
                                color: false,
                                rgba: false,
                                invert: false,
                                sizeInBytes: height * width * 2,
                                totalTimeInMS: timeDiff,
                                photometricInterpretation: 1,
                            };
                            resolve(image as unknown as cornerstone.Image);
                        }
                    }
                }
            })
            .catch(error => {
                console.error('There has been a problem with the fetch operation:', error);
                reject(error);
            });
    });

    return {
        promise
    };

}

const TifRaw2UriImageLoader = {
    loadtifImage,
};

export default TifRaw2UriImageLoader;
