import React, { useRef } from "react";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import { getSelectedWorkitem } from "../OrderList/OrdersSlice";
import { apiSlice } from "../../apis/apiSlice";
import { Constants } from "../../Constants";
import { getCurrentProtocolStepNumber } from "../ImageDisplay/ImageDisplaySlice";
import { getTechniqueParameters } from "./GeneratorTechniqueParametersSlice";
import { selectGeneratorForDeviceSet } from "./AcquisitionSlice";
import { formatISO } from "date-fns";

interface AECButtonProps {
    measuringChamber: number;
    orderId: string;
    deviceSet: any;
    preventClick?: boolean;
}

const useAppDispatch = () => useDispatch<AppDispatch>();
const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

const AECButton = (props: AECButtonProps) => {

    const dispatch = useAppDispatch();

    const currentWorkitemAsString: string | undefined = useAppSelector((state) => getSelectedWorkitem(state));
    const currentWorkitem = currentWorkitemAsString ? JSON.parse(currentWorkitemAsString) : undefined;
    const currentProtocolSettingsStepNumber: number = useAppSelector((state) => getCurrentProtocolStepNumber(state, Constants.ACQUISITION_STEP_TYPE, 'scheduled', 'rejected'));
    const generatorForDeviceSet: any = useAppSelector((state) => selectGeneratorForDeviceSet(state, props.deviceSet?.id));

    const handleAECButtonClick = (evt: React.MouseEvent<Element>) => {
        if (!props.preventClick) {
            const currentProtocolStep = currentWorkitem?.protocol?.steps[currentProtocolSettingsStepNumber];
            const newWorkitemProtocolStep = structuredClone(structuredClone(currentProtocolStep));

            if (newWorkitemProtocolStep?.scheduled?.setup) {
                newWorkitemProtocolStep.scheduled.setup.measuringChamber = props.measuringChamber;
            }

            const actionRef = currentWorkitem?.id + "_" + formatISO(new Date());
            //dispatch({ type: "GeneratorTechniqueParameters/setCurrentActionRef", payload: actionRef });
            dispatch(getTechniqueParameters({
                voltage: currentWorkitem?.protocol?.steps[currentProtocolSettingsStepNumber]?.scheduled?.generator?.kv,
                exposure: (props.measuringChamber === 0) ? currentWorkitem?.protocol?.steps[currentProtocolSettingsStepNumber]?.scheduled?.generator?.masWithoutAEC : currentWorkitem?.protocol?.steps[currentProtocolSettingsStepNumber]?.scheduled?.generator?.masWithAEC,
                generatorEndpoint: generatorForDeviceSet?.config?.endpoint,
                actionRef: actionRef,
            }));


            dispatch(apiSlice.endpoints.updateWorkitemProtocolStep.initiate(
                {
                    workitemId: currentWorkitem?.id,
                    stepNumber: currentProtocolSettingsStepNumber,
                    body: {
                        protocol: newWorkitemProtocolStep
                    }
                }
            ));
            dispatch(
                apiSlice.util.updateQueryData('getStudyWithWorkitems', props.orderId, (data: any) => {
                    const workitemList = data?.workitems?.map((workitem: any, i: number) => workitem?.data?.id);
                    if (currentWorkitem?.id && workitemList && workitemList.includes(currentWorkitem?.id)) {
                        const tmpWorkitem = data?.workitems?.find((workitem: any) => workitem.data?.id === currentWorkitem?.id);
                        if (tmpWorkitem?.data) {
                            tmpWorkitem.data.protocol.steps[currentProtocolSettingsStepNumber] = newWorkitemProtocolStep;
                        }
                    }
                    return data;
                })
            );
        }
    }

    return (
        <button onMouseDown={handleAECButtonClick}  id={props.measuringChamber === 0 ? "none" : ""}
            className={currentWorkitem?.protocol?.steps[currentProtocolSettingsStepNumber]?.scheduled?.setup?.measuringChamber === props.measuringChamber ? "AECButton_selected" : "AECButton"}>
            <svg width="800px" height="800px" fill="none" version="1.1" viewBox="0 0 24 24">
                <path d="m1.9344 1.0026h5.0772v10.898h-5.0772z" fill={props.measuringChamber === 1 || props.measuringChamber === 2 || props.measuringChamber === 5 || props.measuringChamber === 7 ? "#000" : "none"} stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.0228" />
                <path d="m9.2719 12.056h5.0772v10.898h-5.0772z" fill={props.measuringChamber === 1 || props.measuringChamber === 3 || props.measuringChamber === 6 || props.measuringChamber === 7? "#000" : "none"} stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.0228" />
                <path d="m17 0.98848h5.0772v10.898h-5.0772z" fill={props.measuringChamber === 1 || props.measuringChamber === 2  || props.measuringChamber === 4 || props.measuringChamber === 6 ? "#000" : "none"} stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.0228" />
            </svg>
        </button>
    );

}

export default AECButton;
