import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../store";
import { useEffect, useRef, useState } from "react";
import './User.scss';
import { useORTranslation } from "../Localization/ORLocalization";
import { logOut, selectCurrentRefreshToken, selectCurrentUser, selectJwtData } from "../Auth/authSlice";
import { revokeToken } from "../Auth/RequireAuth";
import { useLocation } from "react-router-dom";
import { selectShowThemeSelection, selectTheme } from "../OrderList/MainOrderListSlice";
import { getImagePathForCurrentTheme, getThemes } from "../OrderList/OrdersSlice";

const useAppDispatch = () => useDispatch<AppDispatch>();

const User = () => {
	const dispatch = useAppDispatch();
	const location = useLocation();

	const { language } = useORTranslation([])
	const { changeLanguage } = useORTranslation([])

	const { t } = useORTranslation(['common']);

	const rootDivElement = useRef<HTMLDivElement>(null);

	const currentUser = useSelector(selectCurrentUser);
	const jwtData = useSelector(selectJwtData);
	const currentRefreshToken = useSelector(selectCurrentRefreshToken);
	const currentTheme: string = useSelector(selectTheme);
	const showThemeSelection: boolean = useSelector(selectShowThemeSelection);
	const themes: string[] = useSelector(getThemes);
	const imagePathForCurrentTheme: string = useSelector(getImagePathForCurrentTheme);

	const [open, setOpen] = useState(false);
	const [languageSelectionOpen, setLanguageSelectionOpen] = useState(false);
	const [themeSelectionOpen, setThemeSelectionOpen] = useState(false);

	const options = [
		{ value: 'en', label: 'English', image: '/images/gb.svg' },
		{ value: 'de', label: 'Deutsch', image: '/images/de.svg' }
	]

	const handleOpen = () => {
		setOpen(!open);
	};


	const handleLanguageSelectionOpen = (evt: React.MouseEvent<Element>) => {
		evt.stopPropagation();
		setLanguageSelectionOpen(false);
	};

	const handleThemeSelectionOpen = (evt: React.MouseEvent<Element>) => {
		evt.stopPropagation();
		setThemeSelectionOpen(false);
	};

	const handleLogout = async (e: React.MouseEvent) => {
		revokeToken(currentRefreshToken, dispatch);
		location.pathname = '/';
		dispatch(logOut());
	}

	const handleOpenThemeItemSelected = (theme: string) => {
		const elem = document.getElementById('MainHtml');
		if (elem) {
			elem.classList.remove(currentTheme);
			elem.classList.add(theme?.trim());
		}
		const elemTitle = document.getElementById('mainTitle');
		if (elemTitle) {
			elemTitle.innerHTML = theme?.trim();
		}

		dispatch({ type: "MainOrderListColumns/setTheme", payload: theme?.trim() })

	};

	useEffect(() => {
		const onBodyClick = (event: Event) => {
			if (rootDivElement.current?.contains(event.target as Node)) {
				return;
			}
			setOpen(false);
		};
		document.body.addEventListener("click", onBodyClick, { capture: true });
		return () => {
			document.body.removeEventListener("click", onBodyClick, { capture: true });
		};
	}, []);

	return (
		<div className={currentUser ? "nav-current-User-visible" : "nav-current-User-invisible"} onMouseDown={handleOpen} ref={rootDivElement}>
			<img className="Anmelder" src={`${imagePathForCurrentTheme}Anmelder.svg`}
				onError={(event: any) => { event.target.src = "/images/Anmelder.svg"; event.onerror = null }} alt="Anmelder" />
			<label>
				{jwtData?.name ?? currentUser}
			</label>
			<div className="menuOpenArrow">
				<img className="menuOpenArrowImg"
					src={open ? `${imagePathForCurrentTheme}Pfeil_nach_oben1.svg` : `${imagePathForCurrentTheme}Pfeil_nach_unten1.svg`}
					onError={(event: any) => { event.target.src = open ? "/images/Pfeil_nach_oben1.svg" : "/images/Pfeil_nach_unten1.svg" ; event.onerror = null }}
					alt={"Pfeil nach oben.svg"}
				/>
			</div>

			<ul className={open ? "menu-visible" : "menu-invisible"}>
				<li className="menu-item" onMouseDown={handleLanguageSelectionOpen}>
					<span>
						<img
							src={`${imagePathForCurrentTheme}Pfeil_nach_unten.svg`}
							onError={(event: any) => { event.target.src = "/images/Pfeil_nach_unten.svg"; event.onerror = null }}
							alt={"Pfeil nach oben.svg"}
							className="tools_hide_button_img"
						/>
					</span>
					<button className="language-select">
						<img src={options.find((option) => option.value === language.substring(0, 2))?.image}
							alt={options.find((option) => option.value === language.substring(0, 2))?.image} />
						{options.find((option) => option.value === language.substring(0, 2))?.label}
					</button>

					<ul className="language-menu">
						{options.filter((option) => option.value !== language.substring(0, 2))?.map((option, i) => <li className="language-menu-item"
							onMouseDown={(e) => { e.stopPropagation(); changeLanguage(option.value) }} key={i.toString()}>
							<img src={option?.image} alt={option?.image} />
							{option.label}
						</li>)}
					</ul>
				</li>


				<li className={showThemeSelection && themes?.length > 1 ? "menu-item-Themes" : "menu-item-Themes-invisible"} onMouseDown={handleThemeSelectionOpen}>
					<span>
						<img

							src={`${imagePathForCurrentTheme}Pfeil_nach_unten.svg`}
							onError={(event: any) => { event.target.src = "/images/Pfeil_nach_unten.svg"; event.onerror = null }}
							alt={"Pfeil nach oben.svg"}
							className="tools_hide_button_img"
						/>
					</span>
					<button className="language-select">
						<img className="theme-select-img"
							src={`${imagePathForCurrentTheme}theme-svgrepo-com.svg`}
							onError={(event: any) => { event.target.src = "/images/theme-svgrepo-com.svg"; event.onerror = null }}
							alt={"theme-svgrepo-com.svg"} />
						{currentTheme}
					</button>

					<ul className="themes-menu">
						{themes?.filter((theme: string) => theme?.trim() !== currentTheme)?.map((theme: string, i: number) => <li className="language-menu-item"
							onMouseDown={(e) => { e.stopPropagation(); handleOpenThemeItemSelected(theme) }} key={i.toString()}>
							{/* <img src={theme?.image} alt={option?.image} /> */}
							{theme?.trim()}
						</li>)}
					</ul>

				</li>

				<li className="menu-item">
					<span></span>
					<button className="logout" title={t('logout') as string} onMouseDown={handleLogout}>
						<img
							src={`${imagePathForCurrentTheme}logout-svgrepo-com.svg`}
							onError={(event: any) => { event.target.src = "/images/logout-svgrepo-com.svg"; event.onerror = null }}
							alt="Button Liste2" />
						{t('logout')}
					</button>
				</li>
			</ul>

		</div>
	)
}

export default User;
