import React, { useEffect, useState } from 'react';
import * as pdfjs from 'pdfjs-dist';
import './PdfDocument.scss';

import { DisplayItemType, matrixImagesItem, selectMatrixImages, selectedWorkitem } from './ImageDisplaySlice';
import { TypedUseSelectorHook, useSelector } from 'react-redux';
import { RootState } from '../../store';
import { getImagePathForCurrentTheme, getSelectedWorkitemTitle, selectArtifactIdForSelectedWorkitem, selectInstanceIdForSelectedWorkitem } from '../OrderList/OrdersSlice';
import saveAs from 'file-saver';
import { getImageName } from '../Utils/getItemExportName';
import { serviceEndpoints } from '../..';
import { useORTranslation } from '../Localization/ORLocalization';

//pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

pdfjs.GlobalWorkerOptions.workerSrc = "/pdf.worker.min.js";

let pdfDocs = new Map<number, pdfjs.PDFDocumentProxy | null>();

const initialScale = 1.5;

const GeneratePDF = async (numPage: number, pdfDoc: pdfjs.PDFDocumentProxy | null, index: number, scale: number) => {
    if (pdfDoc) {
        const canvas: HTMLCanvasElement | null = document.querySelector(`#documentCanvas_${index.toString()}`) as HTMLCanvasElement;
        if (canvas) {
            const page: pdfjs.PDFPageProxy = await pdfDoc.getPage(numPage);
            if (page) {
                let viewport = page.getViewport({ scale: scale });
                canvas.height = viewport.height;
                canvas.width = viewport.width;

                const ctx: CanvasRenderingContext2D | null = canvas.getContext('2d');

                if (ctx) {

                    let renderContext = {
                        canvasContext: ctx,
                        viewport: viewport
                    }

                    //page.render(renderContext);
                    const renderTask = page.render(renderContext);
                    await renderTask.promise;
                }
            }
        }
    }
}

export const PDFStart = async (nameRoute: string | URL, index: number) => {
    let loadingTask = pdfjs.getDocument(nameRoute);
    let pdfDoc: pdfjs.PDFDocumentProxy | null = null;

    let scale = initialScale;
    let numPage = 1;

    pdfDoc = await loadingTask.promise;
    if (pdfDoc) {
        await GeneratePDF(numPage, pdfDoc, index, scale);
        //console.log("PDFStart set pdfDoc");
        pdfDocs.set(index, pdfDoc);
    }
}

type PdfDocumentProps = {
    matrixIndex: number;
    displayItemType: DisplayItemType;
};


const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

function PdfDocument(props: PdfDocumentProps) {

    const { t } = useORTranslation(['DocumentViewer']);

    const [scale, setScale] = useState(initialScale);
    const [numPages, setNumPages] = useState(1);
    const [pageNumber, setPageNumber] = useState(1);

    const isFirstPage = pageNumber === 1;
    const isLastPage = pageNumber === numPages;

    const firstPageClass = isFirstPage ? 'disabled' : 'clickable';
    const lastPageClass = isLastPage ? 'disabled' : 'clickable';

    const currentWorkitemId: string = useAppSelector((state) => selectedWorkitem(state));
    const currentArtifactId: string | undefined = useAppSelector((state) => selectArtifactIdForSelectedWorkitem(state));
    //const currentWorkitem: workitem = useAppSelector((state) => getSelectedWorkitem(state));
    const currentWorkitemTitle: string = useAppSelector((state) => getSelectedWorkitemTitle(state));
    const currentInstanceId: string | undefined = useAppSelector((state) => selectInstanceIdForSelectedWorkitem(state));
    const matrixImages: matrixImagesItem[] = useAppSelector((state) => selectMatrixImages(state));
    const imagePathForCurrentTheme: string = useAppSelector((state) => getImagePathForCurrentTheme(state));

    const goToFirstPage = () => {
        const pdfDoc = pdfDocs.get(props.matrixIndex);
        if (pdfDoc) {
            if (pageNumber > 1) {
                setPageNumber(1);
                GeneratePDF(1, pdfDoc, props.matrixIndex, scale);
            }
        }
    };

    const goToPreviousPage = () => {
        const pdfDoc = pdfDocs.get(props.matrixIndex);
        if (pdfDoc) {
            if (pageNumber > 1) {
                setPageNumber(pageNumber - 1);
                GeneratePDF(pageNumber - 1, pdfDoc, props.matrixIndex, scale);
            }
        }
    };
    const goToNextPage = () => {
        const pdfDoc = pdfDocs.get(props.matrixIndex);
        if (pdfDoc) {
            if (pageNumber < pdfDoc.numPages) {
                setPageNumber(pageNumber + 1);
                GeneratePDF(pageNumber + 1, pdfDoc, props.matrixIndex, scale);
            }
        }
    };
    const goToLastPage = () => {
        const pdfDoc = pdfDocs.get(props.matrixIndex);
        if (pdfDoc) {
            if (pageNumber < pdfDoc.numPages) {
                setPageNumber(pdfDoc.numPages);
                GeneratePDF(pdfDoc.numPages, pdfDoc, props.matrixIndex, scale);
            }
        }
    };

    const onPageChange = (e: any) => {
        const pdfDoc = pdfDocs.get(props.matrixIndex);
        if (pdfDoc) {
            const { value } = e.target;
            setPageNumber(Number(value));
            GeneratePDF(Number(value), pdfDoc, props.matrixIndex, scale);
        }
    };

    const isMinZoom = scale < 0.6;
    const isMaxZoom = scale >= 2.0;

    const zoomOutClass = isMinZoom ? 'disabled' : 'clickable';
    const zoomInClass = isMaxZoom ? 'disabled' : 'clickable';

    const zoomOut = () => {
        const pdfDoc = pdfDocs.get(props.matrixIndex);
        if (pdfDoc) {
            let newScale = scale;
            if (!isMinZoom) newScale = scale - 0.1;
            setScale(newScale);
            GeneratePDF(pageNumber, pdfDoc, props.matrixIndex, newScale);
        }
    };

    const zoomIn = () => {
        const pdfDoc = pdfDocs.get(props.matrixIndex);
        if (pdfDoc) {
            let newScale = scale;
            if (!isMaxZoom) newScale = scale + 0.1;
            setScale(newScale);
            GeneratePDF(pageNumber, pdfDoc, props.matrixIndex, newScale);
        }
    };

    const saveDocument = async () => {

        if (currentArtifactId) {
            //window.open(`${serviceEndpoints.ARTIFACT_STORE_URL}/artifacts/${currentArtifactId}/raw`)

            const url = `${serviceEndpoints.ARTIFACT_STORE_URL}/artifacts/${currentArtifactId}/raw`;
            const image = await fetch(url);
            const imageBlob = await image.blob();
            saveAs(imageBlob, getImageName({ workitemId: currentWorkitemId, workitemTitle: currentWorkitemTitle ?? '', instanceId: currentInstanceId, instanceId_raw: '', artifactId: currentArtifactId, artifactId_raw: '' }, 'document', 'pdf'));
        }
    }

    useEffect(() => {
        //console.log("useEffect PdfDocument");
        if (pdfDocs.get(props.matrixIndex)) {
            setNumPages(pdfDocs.get(props.matrixIndex)?.numPages ?? 1)
        }
    }, [props.matrixIndex, currentWorkitemId, matrixImages.length]);

    return (
        <div className={props.displayItemType === DisplayItemType.image  ? "PdfDocumentWrapper_invisible" : "PdfDocumentWrapper"}>
            <div className="control-panel">
                <div className="d-flex justify-content-between align-items-baseline pages" >
                    <i className={`fa-fast-backward ${firstPageClass}`} onClick={goToFirstPage}>
                        <img className="PlayerIcon"
                        src={`${imagePathForCurrentTheme}player-skip-back-svgrepo-com.svg`}
                        onError={(event: any) => {event.target.src="/images/player-skip-back-svgrepo-com.svg"; event.onerror = null}}
                        alt="skip-back" />
                    </i>
                    <i className={`fa-backward ${firstPageClass}`} onClick={goToPreviousPage}>
                        <img className="PlayerIcon"
                        src={`${imagePathForCurrentTheme}player-skip-back-svgrepo-com.svg`}
                        onError={(event: any) => {event.target.src="/images/player-skip-back-svgrepo-com.svg"; event.onerror = null}}
                        alt="backwards" />
                    </i>
                    <span>
                        {t('page')}{' '}
                        <input
                            name="pageNumber"
                            type="number"
                            min={1}
                            max={numPages || 1}
                            className="pageNumber"
                            value={pageNumber}
                            onChange={onPageChange}
                        />{' '}
                        {t('of')} {numPages}
                    </span>
                    <i className={`fa-forward ${lastPageClass}`} onClick={goToNextPage}>
                        <img className="PlayerIcon"
                        src={`${imagePathForCurrentTheme}player-play-svgrepo-com.svg`}
                        onError={(event: any) => {event.target.src="/images/player-play-svgrepo-com.svg"; event.onerror = null}}
                        alt="forward" />
                    </i>

                    <i className={`fa-fast-forward ${lastPageClass}`} onClick={goToLastPage}>
                        <img className="PlayerIcon"
                        src={`${imagePathForCurrentTheme}player-skip-forward-svgrepo-com.svg`}
                        onError={(event: any) => {event.target.src="/images/player-skip-forward-svgrepo-com.svg"; event.onerror = null}}
                        alt="skip-forward" />
                    </i>
                </div>
                <div className="zoom">
                    <i className={`fas fa-search-minus ${zoomOutClass}`} onClick={zoomOut}>
                        <img className="zoomIcon"
                        src={`${imagePathForCurrentTheme}search-minus-svgrepo-com.svg`}
                        onError={(event: any) => {event.target.src="/images/search-minus-svgrepo-com.svg"; event.onerror = null}}
                        alt="zoom out" />
                    </i>
                    <span>{(scale * 100).toFixed()}%</span>
                    <i className={`fas fa-search-plus ${zoomInClass}`} onClick={zoomIn}>
                        <img className="zoomIcon"
                        src={`${imagePathForCurrentTheme}search-plus-svgrepo-com.svg`}
                        onError={(event: any) => {event.target.src="/images/search-plus-svgrepo-com.svg"; event.onerror = null}}
                        alt="zoom in" />
                    </i>
                </div>
                <div className="save" onClick={saveDocument}>
                    <img className="saveIcon"
                    src={`${imagePathForCurrentTheme}save-alt-svgrepo-com.svg`}
                    onError={(event: any) => {event.target.src="/images/save-alt-svgrepo-com.svg"; event.onerror = null}}
                    alt="zoom out" />
                </div>
            </div>
            <section className="PdfDocument">
                <canvas className="documentCanvas" id={`documentCanvas_${props.matrixIndex}`} />
            </section>
        </div>
    );
}

export default PdfDocument;
