import React, { useEffect } from "react";
import { useGetAnnotationQuery } from '../../apis/apiSlice';
import { Constants } from "../../Constants";
import cornerstone, { EnabledElement } from "cornerstone-core";
import { restore } from "../ImageDisplay/Tools/Restorable.js";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import { selectIsOverviewAndAnnotationsVisible, selectMatrixIndex } from "../ImageDisplay/ImageDisplaySlice";


interface AnnotationProps {
    orderId: string;
    workitemId: string;
    targetId: string;
    matrixIndex: number;
    imageLoadStatus: string;
}

const useAppDispatch = () => useDispatch<AppDispatch>();
const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

const Annotation = (props: AnnotationProps) => {
    const {
        isSuccess,
        currentData,
    } = useGetAnnotationQuery(props.targetId, {
        skip: props.targetId === "" || props.workitemId === '',
    })

    const dispatch = useAppDispatch();
    const selectedMatrixIndex: number | undefined = useAppSelector((state) => selectMatrixIndex(state));
    const isOverviewAndAnnotationsVisible: boolean = useAppSelector((state) => selectIsOverviewAndAnnotationsVisible(state));

    useEffect(() => {
        if (isSuccess && currentData && props.imageLoadStatus === 'succeeded') {
            // if the annotations have calibration constants (length, resolution) other tools might change and have to rerendered
            const cornerstoneElements: EnabledElement[] = cornerstone.getEnabledElements();
            const displayElement: EnabledElement | undefined = cornerstoneElements.find(
                (element: EnabledElement) => element.element.id === `${Constants.IMAGE_DISPLAY_GENERIC_ELEMENT_NAME}_${props.matrixIndex}`
            );
            if (displayElement) {
                // restore annotations from backend server
                restore(displayElement, currentData, props.targetId, props.matrixIndex, isOverviewAndAnnotationsVisible, dispatch);
            }
        }

    }, [isSuccess, props.imageLoadStatus, selectedMatrixIndex, props.matrixIndex, props.workitemId, currentData, isOverviewAndAnnotationsVisible]);

    return (
        <></>
    );

}

export default Annotation;
