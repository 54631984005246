import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
//import RetroHitCounter from 'react-retro-hit-counter';
import { apiSlice, device, deviceSet, property } from '../../apis/apiSlice';
import { AppDispatch, RootState } from '../../store';
import BatteryLevel from '../Utils/BatteryLevel';
import WifiLevel from '../Utils/WifiLevel';
import { selectCancelCalibrationUrl, selectCancelSoftwareTriggerUrl, selectCurrentDeviceProperties, selectRequestManufacturerTool, selectSoftwareTriggerState, selectSoftwareTriggerUrl, selectStartCalibrationUrl } from './AcquisitionSlice';
import { GeneratorStateMap, getColorCode, getValueForProperty, SensorStateMap } from './Device';
import CommonDialog from "../Utils/CommonDialog";
import { useEffect, useState } from 'react';
import { useORTranslation } from '../Localization/ORLocalization';
import { getLayout } from '../OrderList/OrdersSlice';

interface DeviceReadonlyPropProps {
	device: device | deviceSet;
	property: [string, property];
	isfirstDefaultWidget: boolean;
	colorCode: number;
};

const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
const useAppDispatch = () => useDispatch<AppDispatch>();

export const getStatusText = (category: string, currentPropertyValues: [string, any][] | undefined) => {
	const state: number = getValueForProperty('status', currentPropertyValues)?.status_code;
	if (category === "DETECTOR") {
		return SensorStateMap.get(state)?.text ?? getValueForProperty('status', currentPropertyValues)?.status_string;
	} else if (category === "GENERATOR") {
		return GeneratorStateMap.get(state)?.text ?? getValueForProperty('status', currentPropertyValues)?.status_string;
	}
	else {
		return getValueForProperty('status', currentPropertyValues)?.status_string;
	}
}

const DeviceReadonlyProp = (props: DeviceReadonlyPropProps) => {

	const dispatch = useAppDispatch();

	const { t } = useORTranslation(['Acquisition', 'common']);

	const currentPropertyValues: [string, any][] = JSON.parse(useAppSelector((state) => selectCurrentDeviceProperties(state, props.device.config.endpoint)));
	const startCalibrationUrl: string | undefined = useAppSelector((state) => selectStartCalibrationUrl(state, props.device?.config?.endpoint));
	const cancelCalibrationUrl: string | undefined = useAppSelector((state) => selectCancelCalibrationUrl(state, props.device?.config?.endpoint));
	const requestManufacturerToolUrl: string | undefined = useAppSelector((state) => selectRequestManufacturerTool(state, props.device?.config?.endpoint));
	const currentSoftwareTriggerState: string | undefined= useAppSelector((state) => selectSoftwareTriggerState(state));
	const softwareTriggerUrl: string | undefined = useAppSelector((state) => selectSoftwareTriggerUrl(state));
	const cancelSoftwareTriggerUrl: string | undefined = useAppSelector((state) => selectCancelSoftwareTriggerUrl(state));
	const layout: any = useAppSelector((state) => getLayout(state));

	const [isCalibrationOpen, setCalibrationOpen] = useState<boolean>(false);
	const [calibrationGuiUrl, setCalibrationGuiUrl] = useState<string | undefined>(undefined);

	const [countdown, setCountdown] = useState<number>(5);

	const [secondSoftwareTriggerDelay, setSecondsSoftwareTriggerDelay] = useState<number>(10);

	const [acquisitionEXECUTED, setAcquisitionEXECUTED] = useState<boolean>(false);

	const startCalibration = (evt: React.MouseEvent<Element>, url: string) => {
		if (url) {
			setCalibrationGuiUrl(url);
			if (url !== 'mfc') {
				setCalibrationOpen(true);
			}
			const r = document.querySelector(':root');
			if (r) {
				// @ts-ignore
				r.style.setProperty('--zIndex-AquisitionOverlay', 5);
				// @ts-ignore
				r.style.setProperty('--rc-dialog-wrap-Pointer-events', 'none');
			}
			dispatch(apiSlice.endpoints.postWebThingAction.initiate({ url: startCalibrationUrl, body: { startCalibration: { input: {} } } }));
		}
	}

	const cancelCalibration = () => {
		dispatch(apiSlice.endpoints.postWebThingAction.initiate({ url: cancelCalibrationUrl, body: { cancelCalibration: { input: {} } } }));
		setCalibrationOpen(false);
		const r = document.querySelector(':root');
		if (r) {
			// @ts-ignore
			r.style.setProperty('--zIndex-AquisitionOverlay', 4);
			// @ts-ignore
			r.style.setProperty('--rc-dialog-wrap-Pointer-events', 'all');
		}
	};

	const requestManufacturerTool = (evt: React.MouseEvent<Element>, url: string) => {
		dispatch(apiSlice.endpoints.postWebThingAction.initiate({ url: requestManufacturerToolUrl, body: { requestManufacturerTool: { input: {} } } }));
	};

	function polarToCartesian(centerX: number, centerY: number, radius: number, angleInDegrees: number) {
		var angleInRadians = (angleInDegrees - 90) * Math.PI / 180.0;

		return {
			x: centerX + (radius * Math.cos(angleInRadians)),
			y: centerY + (radius * Math.sin(angleInRadians))
		};
	}

	function describeArc(x: number, y: number, radius: number, startAngle: number, endAngle: number) {
		var start = polarToCartesian(x, y, radius, endAngle);
		var end = polarToCartesian(x, y, radius, startAngle);
		var largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";
		var d = [
			"M", x, y,
			"L", start.x, start.y,
			"A", radius, radius, 0, largeArcFlag, 0, end.x, end.y,
			"L", end.x, end.y, x, y,
		].join(" ");
		return d;
	}

	function getExposeButton() {
		if (acquisitionEXECUTED) {
			return <>
				<g className="exposeHexagonInner" id={props.colorCode.toString()} transform="matrix(6.3585 0 0 6.3585 -2709.2 -1620)" fill="#b1c100" fillRule="evenodd">
					<path className="a" d="m495.95 321.95a6.9 6.9 0 1 0-6.917-6.888 6.92 6.92 0 0 0 6.917 6.888z" />
					<path className="a" d="m491.19 323.54a10.209 10.209 0 0 0 9.326-0.028l10.715 19.9c-11.622 5.159-18.964 5.018-30.558 0z" />
					<path className="a" d="m490.79 307.01a10.2 10.2 0 0 0-4.621 8.107l-22.592-0.68c1.332-12.642 5.131-18.935 15.251-26.447z" />
					<path className="a" d="m501.16 307.01a10.274 10.274 0 0 1 4.62 8.107l22.592-0.68c-1.332-12.642-5.131-18.935-15.279-26.447z" />
				</g>
				{layout?.deviceSet_panel_display_footer ?
					<foreignObject className="text" width="100%" height="100%" transform="translate(00,180)">
						<p>{t('acquisition')}</p>
					</foreignObject>
					: null}
			</>
		}
		else if (currentSoftwareTriggerState === 'IDLE' || currentSoftwareTriggerState === 'CANCELED' || currentSoftwareTriggerState === 'CANCEL_REQUESTED') {
			return <g onClick={onExposeClick} className="exposeHexagonWrapper">

				<polygon className="exposeHexagon" transform="matrix(.7442 0 0 .74301 176.61 154.37)" points="543 2.2309 723 314 543 625.77 183 625.77 3 314 183 2.2309"
					fill="#000000" stroke="#000" strokeWidth="10" />
				{props.colorCode !== 3 ?
					<g className="exposeHexagonInner" id={props.colorCode.toString()} transform="matrix(6.3585 0 0 6.3585 -2709.2 -1620)" fill="#b1c100" fillRule="evenodd">
						<path className="a" d="m495.95 321.95a6.9 6.9 0 1 0-6.917-6.888 6.92 6.92 0 0 0 6.917 6.888z" />
						<path className="a" d="m491.19 323.54a10.209 10.209 0 0 0 9.326-0.028l10.715 19.9c-11.622 5.159-18.964 5.018-30.558 0z" />
						<path className="a" d="m490.79 307.01a10.2 10.2 0 0 0-4.621 8.107l-22.592-0.68c1.332-12.642 5.131-18.935 15.251-26.447z" />
						<path className="a" d="m501.16 307.01a10.274 10.274 0 0 1 4.62 8.107l22.592-0.68c-1.332-12.642-5.131-18.935-15.279-26.447z" />
					</g> :
					<path className="c" d="m395.04 415.73v-229.55h91.515v229.52zm-9.331
						104.4a52.574 52.574 0 0 1 16.108-38.828 53.762 53.762 0 0 1
						39.323-15.95 53.122 53.122 0 0 1 38.809 15.805 52.693 52.693 0 0 1
						15.963 38.974 53.762 53.762 0 0 1-15.963 39.31 52.574 52.574 0 0 1-38.809
						16.082 53.109 53.109 0 0 1-39.152-16.431 53.531 53.531 0 0 1-16.28-38.96z" fill="#fc5555" stroke-width="6.599" />
				}
			</g>
		}
		else if (currentSoftwareTriggerState === "TRIGGER_REQUESTED") {
			return <g onClick={onExposeCancelClick} className="cancelHexagonWrapper">
				<polygon className="cancelHexagon"
					transform="matrix(0.7095773,0,0,0.7095773,188.53777,164.56046)"
					points="543,625.77 183,625.77 3,314 183,2.2309 543,2.2309 723,314 "
					id="polygon10" />
				<g transform="matrix(.35266 0 0 .35266 353.09 247.04)">
					<path d="m387.19 68.12c-4.226 0-8.328 0.524-12.249 1.511v-14.191c0-27.622-22.472-50.094-50.094-50.094-10.293
					0-19.865 3.123-27.834 8.461-8.993-8.555-21.141-13.807-34.502-13.807-22.84 0-42.156 15.365-48.16
					36.302-5.268-1.887-10.935-2.912-16.844-2.912-27.622 0-50.094 22.472-50.094
					50.094v82.984c-5.996-2.332-12.508-3.616-19.318-3.616-29.43 0-53.373 23.936-53.373
					53.366v99.695c0 63.299 38.525 185.64 184.32 195.65 4.274 0.289 8.586 0.438 12.813
					0.438 91.218 0 165.44-72.378 165.44-161.35v-232.44c-1e-3 -27.622-22.472-50.094-50.095-50.094zm-115.34 415.83c-3.585
					0-7.209-0.126-10.896-0.376-134.66-9.237-158.18-126.67-158.18-167.66v-99.695c0-13.979 11.341-25.313 25.32-25.313
					13.98 0 25.321 11.334 25.321 25.313v76.997h22.05v-209.73c0-12.172 9.87-22.042 22.041-22.042 12.172 0 22.042 9.87
					22.042 22.042v152.96h20.922v-186.35c0-12.172 9.87-22.041 22.041-22.041 12.172 0 22.042 9.87 22.042
					22.041v186.35h18.253v-181c0-12.172 9.87-22.041 22.042-22.041 12.171 0 22.041 9.87 22.041 22.041v181h18.261v-118.23c0-12.172
					9.87-22.042 22.041-22.042 12.172 0 22.042 9.87 22.042 22.042v232.44c-1e-3 69.098-55.785 133.3-137.38 133.3z"
						stroke="#000" fill="#fd9292" strokeWidth="1.0014" />
				</g>
				{/* <text x="346" y="512" fill="#000000" fontFamily="sans-serif" fontSize="64px" ><tspan x="346" y="512">{t('cancel', {ns: 'common'} )}</tspan></text> */}
				<foreignObject className="Canceltext" width="100%" height="100%" transform="translate(0,360)">
					<p>{t('stop', { ns: 'common' })}</p>
				</foreignObject>
			</g>
		}
		else {
			return <>
				<g className="exposeHexagonInner" id={props.colorCode.toString()} transform="matrix(6.3585 0 0 6.3585 -2709.2 -1620)" fill="#b1c100" fillRule="evenodd">
					<path className="a" d="m495.95 321.95a6.9 6.9 0 1 0-6.917-6.888 6.92 6.92 0 0 0 6.917 6.888z" />
					<path className="a" d="m491.19 323.54a10.209 10.209 0 0 0 9.326-0.028l10.715 19.9c-11.622 5.159-18.964 5.018-30.558 0z" />
					<path className="a" d="m490.79 307.01a10.2 10.2 0 0 0-4.621 8.107l-22.592-0.68c1.332-12.642 5.131-18.935 15.251-26.447z" />
					<path className="a" d="m501.16 307.01a10.274 10.274 0 0 1 4.62 8.107l22.592-0.68c-1.332-12.642-5.131-18.935-15.279-26.447z" />
				</g>
				{layout?.deviceSet_panel_display_footer ?
					<foreignObject className="text" width="100%" height="100%" transform="translate(00,110)">
						<p>{t('waitingForDetector')}</p>
					</foreignObject>
					: null}
			</>
		}
	}

	const onExposeClick = (evt: React.MouseEvent<Element>) => {
		if (props.colorCode === 2) {
			dispatch({ type: 'Acquisition/setIsAcquistionOngoing', payload: true });
			if (softwareTriggerUrl && softwareTriggerUrl.length > 0) {
				dispatch(apiSlice.endpoints.postWebThingAction.initiate({ url: softwareTriggerUrl, body: { softwareTrigger: { input: {} } } }));
			}
		}
	};

	const onExposeCancelClick = (evt: React.MouseEvent<Element>) => {
		dispatch({ type: 'Acquisition/setIsAcquistionOngoing', payload: false });
		if (cancelSoftwareTriggerUrl && cancelSoftwareTriggerUrl.length > 0) {
			dispatch(apiSlice.endpoints.postWebThingAction.initiate({ url: cancelSoftwareTriggerUrl, body: { cancelSoftwareTrigger: { input: {} } } }));
		}
	};

	function getExposePanel() {
		return <div className="exposepanel">
			<svg version="1.1" viewBox="0 0 893.21 773.54">
				<defs>
					<mask id="c" x="-3" y="-3" width="6" height="6" maskUnits="userSpaceOnUse">
						<circle r="1.625" fill="none" stroke="#fff" strokeWidth="1.75" />
					</mask>
				</defs>

				<clipPath id="myClip">
					{/* <path d={`${describeArc(440, 390, 550, 0, 350.9999)}`} /> */}
					<path d={`${describeArc(365, 320, 450, 30, currentSoftwareTriggerState === 'TRIGGER_REQUESTED' ? (1 - countdown / secondSoftwareTriggerDelay) * 359.9999 + 30 : 30)}`} />
				</clipPath>

				{currentSoftwareTriggerState !== 'TRIGGER_REQUESTED' ?
					<polygon onClick={(evt) => { onExposeClick(evt) }} className="innerHexagon_a" id={props.colorCode.toString()} transform="translate(83.603 72.769)"
						points="723 314 543 625.77 183 625.77 3 314 183 2.2309 543 2.2309"
						fill="none" stroke="#b2c200" strokeWidth="150" stroke-align="inner" />
					:
					<polygon className="innerHexagon_c"
						transform="matrix(1.2345969,0,0,1.2345969,-1.5557747,-0.89461886)"
						points="543,2.2309 723,314 543,625.77 183,625.77 3,314 183,2.2309 "
						stroke="none"
						id="polygon4" />
				}


				<polygon onClick={(evt) => { onExposeCancelClick(evt) }} className="innerHexagon_b" transform="translate(83.603 72.769)"
					points="723 314 543 625.77 183 625.77 3 314 183 2.2309 543 2.2309"
					fill="none" stroke="#b2c200" strokeWidth="150" clipPath="url(#myClip)" />

				{getExposeButton()}

				<foreignObject className={currentSoftwareTriggerState === 'TRIGGER_REQUESTED' ? "foreignObject" : "foreignObject_invisible"}
					width="100%" height="100%" transform="translate(350,20)">
					<p>{countdown.toFixed(0)}</p>
				</foreignObject>
			</svg>
		</div >
	}

	function getPropsItem() {
		switch (props.property[0]) {
			case 'battery':
				return <button className="StatusLabels DeviceProp" title={`${t(props.property[1]?.description)} ${t("value", { ns: "common" })}: ${getValueForProperty('battery', currentPropertyValues)?.level} ${props.property[1]?.unit ?? ''}`}>
					<BatteryLevel colorSettingNr={1}
						batteryLevel={getValueForProperty('battery', currentPropertyValues)?.level ?? 0} />
				</button>;
			case 'connection':
				return <button className="WifiLevel DeviceProp" title={`${t(props.property[1]?.description)} ${t("value", { ns: "common" })}: ${getValueForProperty('connection', currentPropertyValues)?.signal} ${props.property[1]?.unit ?? ''}`}>
					<WifiLevel colorSettingNr={1} wifiLevel={getValueForProperty('connection', currentPropertyValues)?.signal ?? 0} />
				</button>;
			case 'status':
				return <button className="StatusLabelsState DeviceProp"
					id={getColorCode(props.device.category, getValueForProperty('status', currentPropertyValues)?.status_code).toString().toUpperCase()}
					title={`${t(props.property[1]?.description)}`}>
					<label className='DevicePropLabel'>
						{getStatusText(props.device.category, currentPropertyValues)}
					</label>
				</button>
			case 'calibrationGui':
				return <div className="calibrationGuiDiv" title={`${t(props.property[1]?.description)}`}>
					<button className="venaDefaultButton calibrationGuiButton" onClick={(ev: React.MouseEvent<Element>) => startCalibration(ev, getValueForProperty(props.property[0], currentPropertyValues))}>
						{t('startCalibration')}
					</button>
				</div>;
			case 'manufacturerTool':
				return <div className="manufacturerToolDiv" title={getValueForProperty(props.property[0], currentPropertyValues)?.description}>
					<button className="venaDefaultButton calibrationGuiButton" onClick={(ev: React.MouseEvent<Element>) => requestManufacturerTool(ev, getValueForProperty(props.property[0], currentPropertyValues))}>
						{getValueForProperty(props.property[0], currentPropertyValues)?.title}
					</button>
				</div>;
			case 'softwareTriggerType':
				return null;
			case 'softwareTriggerState':
				return getExposePanel();
			default:
				if (props.property[1]?.type === 'integer') {
					return <button className="StatusLabelsState DeviceProp">
						<label className='DevicePropLabel' title={`${t(props.property[1]?.description)}`}>
							{`${t(props.property[1]?.title)}: ${getValueForProperty(props.property[0], currentPropertyValues) ?? t('unknown')}`}
						</label>
					</button>
				}
				if (props.property[1]?.type === 'string' || props.property[1]?.type === 'number') {
					return (
						<div className='DevicePropDefault'>
							<div className='DevicePropDefaultWrapper' title={`${t(props.property[1]?.description)}`}>
								<label className='DevicePropDefaultLabel'>
									{`${t(props.property[1]?.title)}`}
								</label>
								<label className='DevicePropDefaultValue'>
									{`${getValueForProperty(props.property[0], currentPropertyValues) ?? t('unknown')}`}
								</label>
								{props.property[1]?.unit !== undefined ?
									<label className="DevicePropDefaultUnit">{props.property[1]?.unit?.replace('milliseconds', 'ms')}</label>
									: null}
							</div>
						</div>
					)
				} else if (props.property[1]?.type === 'boolean' && props?.device?.category !== 'SET') {
					return <div className="SettingsRow" title={`${t(props.property[1]?.description)}`}>
						<div className="SettingsRowInputWrapper">
							<label className="SettingsRowLabel">{t(props.property[1]?.title) ?? ''}</label>
							{getValueForProperty(props.property[0], currentPropertyValues) ?
								<label className="SettingsRow-Boolean_on" data-value={t("on", { ns: "common" })}>
									{t("on", { ns: "common" })}
								</label>
								:
								<label className="SettingsRow-Boolean_off" data-value={t("on", { ns: "common" })}>
									{t("off", { ns: "common" })}
								</label>
							}
						</div>
					</div>
				}
		}
	}

	useEffect(() => {
		if (props.property[0] === "softwareTriggerState") {
			const softwareTriggerState: string | undefined | null = getValueForProperty(props.property[0], currentPropertyValues)?.state;

			if (softwareTriggerState !== undefined && softwareTriggerState !== null) {
				if (softwareTriggerState === 'CANCEL_REQUESTED' || softwareTriggerState === 'CANCELED') {
					dispatch({ type: 'Acquisition/setIsAcquistionOngoing', payload: false });
				}
			}

			setCountdown(getValueForProperty(props.property[0], currentPropertyValues)?.countdown ?? 0);
			setSecondsSoftwareTriggerDelay((getValueForProperty("softwareTriggerDelay", currentPropertyValues) ?? 5) / 1000);
			//setSecondsSoftwareTriggerTimeout((getValueForProperty("softwareTriggerTimeout", currentPropertyValues) ?? 5) / 1000);
		}
	}, [getValueForProperty(props.property[0], currentPropertyValues)]);

	useEffect(() => {
		if (currentSoftwareTriggerState === 'EXECUTED') {
			setAcquisitionEXECUTED(true)
		}
	}, [currentSoftwareTriggerState]);


	return (
		<>
			{props.isfirstDefaultWidget ? <hr className="hrule3" /> : null}

			{getPropsItem()}
			<CommonDialog onClose={cancelCalibration} title={'Calibration'} visible={isCalibrationOpen} okText="" cancelText={t('cancel', { ns: 'common' })}
				default={{ x: 0, y: 0, width: window.innerWidth - 500, height: window.innerHeight - 150 }} childProps={{}}>
				<>
					<iframe className='calibrationIFrame' src={calibrationGuiUrl}
						title="Calibration"></iframe>
				</>
			</CommonDialog>
		</>
	);
}

export default DeviceReadonlyProp;
