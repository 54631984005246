import csTools from 'cornerstone-tools';
import cornerstone from "cornerstone-core";

const BaseTool = csTools.importInternal('base/BaseTool');

/**
 * @public
 * @class InvertTool
 * @memberof Tools
 *
 * @classdesc Tool for setting wwwc based on a rectangular region.
 * @extends Tools.Base.BaseTool
 */
export default class InvertTool extends BaseTool {
    /** @inheritdoc */
    constructor(props = {}) {
        const defaultProps = {
            name: 'Invert',
        };
        super(props, defaultProps);
    }

    activeCallback(element : HTMLElement) {
        let viewport = cornerstone.getViewport(element);
        if (viewport) {
            viewport.invert = !viewport.invert;
            cornerstone.setViewport(element, viewport);
        }
    }
}
