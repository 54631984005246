import csTools from 'cornerstone-tools';

const BaseAnnotationTool = csTools.importInternal('base/BaseAnnotationTool');
const external = csTools.external;
const toolColors = csTools.toolColors;
const getToolState = csTools.getToolState;
const getNewContext = csTools.import("drawing/getNewContext");
const drawJoinedLines = csTools.import("drawing/drawJoinedLines");
const draw = csTools.import("drawing/draw");
const drawLinkedTextBox = csTools.import("drawing/drawLinkedTextBox");
const setShadow = csTools.importInternal("drawing/setShadow");
const getROITextBoxCoords = csTools.import("util/getROITextBoxCoords");
const getLogger = csTools.importInternal('util/getLogger');
const Cursors = csTools.importInternal('tools/cursors');
const rectangleRoiCursor = Cursors.rectangleRoiCursor;
const getModule = csTools.getModule;
const logger = getLogger('tools:annotation:ProcessingROITool');

/**
 * @public
 * @class ProcessingROITool
 * @memberof Tools.Annotation
 * @classdesc Tool for displaying the current ROI used for processing this image
 * @extends Tools.Base.BaseAnnotationTool
 */
export default class ProcessingROITool extends BaseAnnotationTool {
    constructor(props = {}) {
        const defaultProps = {
            name: 'ProcessingROI',
            supportedInteractionTypes: ['Mouse', 'Touch'],
            configuration: {
                drawHandles: false,
                drawHandlesOnHover: false,
                hideHandlesIfMoving: false,
                renderDashed: false,
                shadow: true,
            },
            svgCursor: rectangleRoiCursor,
        };

        super(props, defaultProps);
    } // constructor

    passiveCallback(element) {
        //console.log("pr passive");      
        const toolData = getToolState(element, this.name);
        if (!toolData) {
            return;
        }
        for (let i = 0; i < toolData.data.length; i++) {
            let data = toolData.data[i];
            if (data && data.matrixIndex === this.initialConfiguration.matrixIndex) {
                data.visible = false;
            }
        }
    }

    activeCallback(element) {
        //console.log("pr active");
        const toolData = getToolState(element, this.name);
        if (!toolData) {
            return;
        }
        for (let i = 0; i < toolData.data.length; i++) {
            let data = toolData.data[i];
            if (data && data.matrixIndex === this.initialConfiguration.matrixIndex) {
                data.visible = true;
            }
        }
    }


    delete(element) {
        const toolData = getToolState(element, this.name);
        if (!toolData) {
            return;
        }
        for (let i = 0; i < toolData.data.length; i++) {
            const data = toolData.data[i];
            if (data)
                csTools.removeToolState(element, this.name, data);
        }

    }

    createNewMeasurement(eventData) {
        const goodEventData = eventData && eventData.currentPoints && eventData.currentPoints.image;
        if (!goodEventData) {
            logger.error(
                `required eventData not supplied to tool ${this.name}'s createNewMeasurement`
            );
            return;
        }

        //const element = eventData.element;

        if (eventData.event) {
            // TODO check if we really want this (remove all other ROIs drawing a new one)

            //this.delete(eventData.event.currentTarget);
            //clearToolState(eventData.event.currentTarget, this.name);
        }
        let x = eventData.currentPoints.image.x;
        let y = eventData.currentPoints.image.y;
        var toolData = {
            visible: false,
            active: true,
            color: undefined,
            invalidated: true,
            handles: {
                end: {
                    x: x,
                    y: y,
                    highlight: false,
                    active: false,
                },
                textBox: {
                    active: false,
                    hasMoved: false,
                    movesIndependently: false,
                    drawnIndependently: true,
                    allowedOutsideImage: true,
                    hasBoundingBox: true
                }
                //initialRotation: eventData.viewport.rotation,
            },
            fixedhandles: {
				end: {
                    x: x,
                    y: y,
                    highlight: false,
                    active: false,
                },
			}
        };

        return toolData;
    } // createNewMeasurement

    // TODO overwrite addNewMeasurement()?    

    // TODO clarify what we would need this for. Maybe show additional textual info (ROI type) on hover?
    pointNearTool(element, data, coords, interactionType) {

		if (true)
			return false;

        const hasHandles = data && data.handles;
        const validParameters = hasHandles;

        if (!validParameters) {
            logger.warn(
                `invalid parameters supplied to tool ${this.name}'s pointNearTool`
            );
        }

        if (!validParameters || data.visible === false) {
            return false;
        }

        const distance = interactionType === 'mouse' ? 15 : 25;

        const h = data.handles;
        let points = [];
        for (var j = 1; j <= 4; j++) {
            points.push({ x: h[j].x, y: h[j].y })
        }

        for (let i = 0; i < points.length; i++) {
            let j = i < points.length - 1 ? i + 1 : 0;
            const distanceToPoint = external.cornerstoneMath.lineSegment.distanceToPoint(
                {
                    start: external.cornerstone.pixelToCanvas(element, points[i]),
                    end: external.cornerstone.pixelToCanvas(element, points[j])
                },
                coords
            );
            if (distanceToPoint < distance)
                return true;
        } // for

        return false;
    } // pointNearTool

    renderToolData(evt) {

        var eventData = evt.detail;

        const toolData = getToolState(evt.currentTarget, this.name);

        if (!toolData) {
            return;
        }

        const { image, element, viewport, canvasContext } = eventData;

        if (element === undefined) {
            return;
        }

        //console.log(image.imageId);

        //const lineDash = getModule('globalConfiguration').configuration.lineDash;

        const lineDash = [5, 5];
        const {
            handleRadius,
            drawHandlesOnHover,
            hideHandlesIfMoving,
            //renderDashed,
        } = this.configuration;
        let renderDashed = true;
        const context = getNewContext(eventData.canvasContext.canvas);

        draw(context, context => {
            // If we have tool data for this element - iterate over each set and draw it
            for (let i = 0; i < toolData.data.length; i++) {
                const data = toolData.data[i];

                if (data) {

                    if (data.visible === false) {
                        continue;
                    }
                    
                    // Configure
                    let color = toolColors.getColorIfActive(data);

                    const handleOptions = {
                        color,
                        handleRadius,
                        drawHandlesIfActive: drawHandlesOnHover,
                        hideHandlesIfMoving,
                    };

                    setShadow(context, this.configuration);

                    const lineOptions = { color };
                    const lineWidth = csTools.toolStyle.getToolWidth();

                    if (renderDashed) {
                        lineOptions.lineDash = lineDash;
                    }

                    // Draw
                    const h = data.fixedhandles;
                    let points = [];
                    let maxx = 0, miny = h[1].y;
                    for (var j = 1; j <= 4; j++) {
                        points.push({ x: h[j].x, y: h[j].y })
                        if (h[j].x > maxx)
                            maxx = h[j].x;
                        if (h[j].y < miny)
                            miny = h[j].y;
                    }

                    if (points.length > 1) {
                        let dpoints = points;
                        dpoints.push(points[0]);
                        drawJoinedLines(context, element, dpoints[0], dpoints, lineOptions);
                        dpoints.pop();
                    }
                    data.handles.textBox.hasMoved = false;
                    if (!data.handles.textBox.hasMoved) {
                        // Coords for text
                        const coords = {
                            // Translate the x/y away from the cursor
                            x: maxx + 3,
                            y: miny,
                        };
                        Object.assign(data.handles.textBox, coords);
                    }
                    /*
                    const textBoxAnchorPoints = handles =>
          _findTextBoxAnchorPoints(handles.start, handles.end);
          */
                    if (data.roitype) {
                        drawLinkedTextBox(
                            context,
                            element,
                            data.handles.textBox,
                            data.roitype,
                            data.fixedhandles,
                            [data.fixedhandles.end],
                            color,
                            lineWidth,
                            10,
                            true
                        );
                    }
                }
            }
        }); // draw
    } // renderToolData

    restore(element, data) {
        var xs = data.points[0].x;
        var ys = data.points[0].y;

        let measurement = this.createNewMeasurement(
            {
                currentPoints: { image: { x: xs, y: ys } },
                viewport: { rotation: undefined },
                element: element,
            });

        let points = JSON.parse(JSON.stringify(data.points));

		for (var i = 1; i <= points.length; i++) {
			measurement.handles[i] = { x: points[i - 1].x, y: points[i - 1].y, active: false, highlight: false };
			measurement.fixedhandles[i] = { x: points[i - 1].x, y: points[i - 1].y, active: false, highlight: false }
		}
        measurement.handles.end = measurement.handles[1];
        measurement.fixedhandles.end = measurement.fixedhandles[1];
        measurement.roitype = data.roitype;
        measurement.svalue = data.svalue;
		measurement.exposureindex = data.exposureindex;
		if (data.initialbmpnts !== undefined) {
			measurement.initialbmpnts = data.initialbmpnts;
		}
        return measurement;
    }

    // absence of store() leads to this annotation never be stored/edited by the UI
    /*
    store(toolData) {
       
    }
    */

    updateCachedStats(image, element, data) {
        return;
    }
} // ProcessingROITool
