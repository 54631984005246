import React, { useState } from "react";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { Direction, Range } from 'react-range';
import { AppDispatch, RootState } from "../../../store";

import "./FalseColor.scss";
import cornerstone, { EnabledElement } from "cornerstone-core";
import { Constants } from "../../../Constants";
import { getColorFracAtMatrixIndex } from "../ImageDisplaySlice";

type FalseColorProps = {
    orderId: string;
    matrixIndex: number;
};

const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
const useAppDispatch = () => useDispatch<AppDispatch>();

const FalseColor1 = (props: FalseColorProps) => {
    const dispatch = useAppDispatch();

    const [values_grayscale, setValues_grayscale] = useState<number[]>([]);

    const colorfrac = useAppSelector((state) => getColorFracAtMatrixIndex(state, props.matrixIndex));


    const STEP = 0.01;
    const MIN = 0;
    const MAX = 1;

    const onThumbMouseDown = (evt: React.MouseEvent<Element>) => {
        dispatch({ type: "ImageDisplay/setSelectedIndex", payload: props.matrixIndex });
    }

    const getTrackBackground = (): string => {
        let ret: string = 'linear-gradient(to top,';
        for (let i = 0; i < Constants.DEFAULT_FALSE_COLOR_FRAC.length + 1; i++) {
            let frac_low: number = 0.0;

            if (i > 0) {
                frac_low = (colorfrac ?? Constants.DEFAULT_FALSE_COLOR_FRAC)[i - 1] * 100;
            } else {
                frac_low = 0.0;
            }
            let frac_high: number = 0.0;
            if (i < Constants.DEFAULT_FALSE_COLOR_FRAC.length) {
                frac_high = (colorfrac ?? Constants.DEFAULT_FALSE_COLOR_FRAC)[i] * 100;
            } else {
                frac_high = 100;
            }
            ret += `rgb(${Constants.DEFAULT_FALSE_COLORS[i * 6] * 255}, ${Constants.DEFAULT_FALSE_COLORS[i * 6 + 1] * 255}, ${Constants.DEFAULT_FALSE_COLORS[i * 6 + 2] * 255}) ${frac_low}%, `;
            ret += `rgb(${Constants.DEFAULT_FALSE_COLORS[i * 6 + 3] * 255}, ${Constants.DEFAULT_FALSE_COLORS[i * 6 + 4] * 255}, ${Constants.DEFAULT_FALSE_COLORS[i * 6 + 5] * 255}) ${frac_high}% `;
            if (i < Constants.DEFAULT_FALSE_COLOR_FRAC.length) {
                ret += ', ';
            } else {
                ret += ')';
            };
        }
        return ret;
    }

    const onValuesChange = (values: number[]) => {
        dispatch({ type: "ImageDisplay/setFalseColorsFrac", payload: { matrixIndex: props.matrixIndex, colorFrac: values } });

        const cornerstoneElements: EnabledElement[] = cornerstone.getEnabledElements();
        const displayElement: EnabledElement | undefined = cornerstoneElements.find(
            (element: EnabledElement) => element.element.id === `${Constants.IMAGE_DISPLAY_GENERIC_ELEMENT_NAME}_${props.matrixIndex}`
        );

        if (displayElement && displayElement.image) {
            displayElement.needsRedraw = true;
            displayElement.invalid = true;
        }
    }

    return (
        <div className="falseColorWrapper" onContextMenu={(e) => e.preventDefault()}>
            <div className="falseColor">
                <Range
                    direction={Direction.Up}
                    values={values_grayscale}
                    step={STEP}
                    min={MIN}
                    max={MAX}
                    rtl={false}
                    onChange={(values) => setValues_grayscale(values ?? [])}
                    renderMark={({ props, index }) => (
                        < div className='ticks'
                            {...props}
                            style={{
                                ...props.style,
                                height: '1px',
                                backgroundColor: index % 10 === 0 && index !== 0 && index !== 100 ? '#ffff' : ''
                            }}
                        />
                    )}
                    renderTrack={({ props, children }) => (
                        <div className="grayscaleBar"
                            {...props}
                            style={{
                                ...props.style,
                                backgroundImage: 'linear-gradient(to top, rgb(0, 0, 0) 0%,  rgb(255, 255, 255) 100%',
                            }}
                        >
                            {children}
                        </div>
                    )}
                    renderThumb={({ props }) => (
                        <div className="thumb_grayscale"
                            {...props}
                            style={{
                                ...props.style,
                            }}
                        />
                    )}
                />

                <Range
                    direction={Direction.Up}
                    values={colorfrac ?? Constants.DEFAULT_FALSE_COLOR_FRAC}
                    step={STEP}
                    min={MIN}
                    max={MAX}
                    rtl={false}
                    onChange={(values) => onValuesChange(values ?? [])}
                    renderMark={({ props, index }) => (
                        <div className='ticks'
                            {...props}
                            style={{
                                ...props.style,
                                height: '1px',
                                backgroundColor: index % 10 === 0 && index !== 0 && index !== 100 ? '#ffff' : ''
                            }}
                        />
                    )}

                    renderTrack={({ props, children }) => (
                        <div className="colorsBar"
                            {...props}
                            style={{
                                ...props.style,
                                backgroundImage: `${getTrackBackground()}`,
                            }}
                        >
                            {children}
                        </div>
                    )}
                    renderThumb={({ props }) => (
                        <div className="thumb"
                            onMouseDown={onThumbMouseDown}
                            {...props}
                            style={{
                                ...props.style,
                            }}
                        />
                    )}
                />
            </div>
        </div>
    );
}

export default FalseColor1;
