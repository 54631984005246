import React, { useEffect } from "react";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import SVG from 'react-inlinesvg';
import { AppDispatch, RootState } from "../../store";
import { CatalogueEntry } from "../Akquisition/CatalogueSlice";
import { getCatalogEntryForSelectedWorkitem, getLayout } from "./OrdersSlice";
import { setAcquisitionActive } from "../Akquisition/AcquisitionSlice";
import { getActivatePlanningMode, selectedWorkitem, selectImageDescVisible } from "../ImageDisplay/ImageDisplaySlice";
import ImageDesc from "../Preview/ImageDesc";
import { useGetXrayHelperByProcedureCodeQuery, workitem } from "../../apis/apiSlice";
import { serviceEndpoints } from "../..";
import { selectProcedureSelectionVisibilityState } from "../Procedures/ProcedureSlice";
import { selectTheme } from "./MainOrderListSlice";

interface PlannedItemProps {
    orderId: string;
    workitemId: string;
    seriesId: string | undefined;
    catalogueId: string;
    className: string;
    workitemData: workitem;
    includeImageDesc: boolean;
    currentProtocolStep?: any;
}

const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
const useAppDispatch = () => useDispatch<AppDispatch>();

const PlannedItem = (props: PlannedItemProps) => {
    const dispatch = useAppDispatch();

    const workitemCatalogueEntry: CatalogueEntry = useAppSelector((state) => getCatalogEntryForSelectedWorkitem(state, props.workitemData));
    const isImageDescVisible: boolean = useAppSelector((state) => selectImageDescVisible(state));
    const currentWorkitemId: string = useAppSelector((state) => selectedWorkitem(state));
    const layout: any = useAppSelector((state) => getLayout(state));
    const procedureSelectionVisibilityState: number = useAppSelector((state) => selectProcedureSelectionVisibilityState(state));
    const activatePlanningMode = useAppSelector((state) => getActivatePlanningMode(state));
    const currentTheme: string = useAppSelector((state) => selectTheme(state));

    const {
        data: data_xrayHelper,
        isSuccess: isSuccess_xrayHelper,
    }: {
        data: any, isLoading: boolean, isSuccess: boolean, isError: boolean, error: any
    } = useGetXrayHelperByProcedureCodeQuery({ procedureCode: props.workitemData?.details?.procedureCode }, {
        refetchOnMountOrArgChange: true,
        skip: props.workitemData?.details?.procedureCode === undefined,
    })


    const onImageSelect = async () => {
        dispatch({ type: "ImageDisplay/setSelectedWorkitem", payload: props.workitemId });
        dispatch({ type: "ImageDisplay/setSelectedIndex", payload: -1 });
        dispatch(setAcquisitionActive({ setAcquistionButtonActive: true, studyId: props.orderId, workitemId: props.workitemId }));
        if (procedureSelectionVisibilityState < 1 || (procedureSelectionVisibilityState === 1 && !activatePlanningMode)) {
            dispatch({ type: "Procedure/setProcedureSelectionVisibilityState", payload: 2 });
        }
        dispatch({ type: 'Catalogue/setSelectedCatalogueEntry', payload: workitemCatalogueEntry.id });
        if (props.workitemData?.protocol) {
            //dispatch({ type: "Procedure/setSelectedBodyPart", payload: props.workitemData.details.settings.kv })
        } else {
            dispatch({ type: "Procedure/setSelectedBodyPart", payload: '' })
        }
    };



    useEffect(() => {
        if (currentWorkitemId === props.workitemId) {
            console.log(currentWorkitemId + " " + props.workitemId);
            //onImageSelect();
        }
    }, []);

    const getIcon = () => {
        if (currentTheme === "ORmed" || currentTheme === "ORhuman") {
            return <div id={props.workitemId} className={props.className} onClick={() => onImageSelect()}>
                <img className="icon-species" src="/images/icon-mensch.svg" alt="icon-mensch.svg" />
            </div>
        }

        return <div id={props.workitemId} className={props.className} onClick={() => onImageSelect()}>
            <SVG className="Planned_SVG" cacheRequests={false} src={workitemCatalogueEntry.icon} />
        </div>
    }


    return (
        <>
            {props.workitemData?.protocol && isSuccess_xrayHelper && data_xrayHelper?.content?.drawing?.path ?
                <div id={props.workitemId} className={props.className} onClick={() => onImageSelect()}>
                    {<img
                        src={`${serviceEndpoints.PROCEDURES_URL}/xrayHelperImages/${data_xrayHelper?.content?.drawing?.path}`}
                        alt={"procedure icon"}
                        className="Planned_Icon"
                    />}
                </div>
                : getIcon()

            }
            {(function () {
                if (props.includeImageDesc && (isImageDescVisible || layout?.showItemDescInPreviewPanel)) {
                    return <ImageDesc onImageSelect={() => onImageSelect()} workitem={props.workitemId} orderId={props.orderId} artifactId={undefined}
                        artifactId_Raw={undefined} data={props.workitemData} className={props.className} isPlanning={true}
                        currentProtocolStep={props.currentProtocolStep} />
                } else {
                    return null;
                }
            })()}
        </>
    );

}

export default PlannedItem;
