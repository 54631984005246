import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { connect, TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import "./PreviewPanel.scss";
import { addImageMode, getImagePathForCurrentTheme, getLayout, getSelectedWorkitem, loadImage, haveOneImageToComplete, haveOpenPlannings, selectArtifactForSelectedWorkitem, selectArtifactIdForSelectedWorkitem, selectArtifactIdsForOrder, selectCountImages, selectCountPlannings, selectInstanceForWorkitem, selectSeriesDataForWorkitem, selectedStudy, studyWorkitemsList, getCompletableWorkitemIds, selectFirstPlanningWorkitemIdInOrder } from "../OrderList/OrdersSlice";
import Workitem from "../OrderList/Workitem";
import { apiSlice, useGetStudyWithWorkitemsQuery, workitem, useReopenStudyMutation, useCancelStudyMutation } from "../../apis/apiSlice";
import { selectedWorkitem, selectImageDescVisible, getMatrixIndexForInsert, selectFinalizeStudyDialogVisible, getActivatePlanningMode } from "../ImageDisplay/ImageDisplaySlice";
import { selectDeviceRunnerActive, selectDeviceRunnerError, selectDeviceRunnerPath, setAcquisitionActive } from "../Akquisition/AcquisitionSlice";
import { selectAllowComplete } from "../OrderList/MainOrderListSlice";
import { serviceEndpoints } from "../..";
import { useORTranslation } from "../Localization/ORLocalization";
import cornerstone, { EnabledElement } from "cornerstone-core";
import { Constants } from "../../Constants";
import RelatedStudies from "../Subject/RelatedStudies";
import InfoPanel from "../Utils/ConfigurableInfoPanel/InfoPanel";
import { selectProcedureSelectionVisibilityState } from "../Procedures/ProcedureSlice";
import FinalizeStudyDialog from "./FinalizeStudyDialog";
import CommonDialog from "../Utils/CommonDialog";
import { submit } from "../Utils/ConfirmDialog";

interface PreviewPanelProps {
  orderId: string;
  matrixImages?: { matrixIndex: number; imageId: string, correlationId: string }[];
}

function mapStateToProps(state: RootState) {
  const matrixImages: { matrixIndex: number; imageId: string, correlationId: string }[] = state.ImageDisplay.matrixImages;
  return { matrixImages };
}

function Sleep(milliseconds: number) {
  return new Promise(resolve => setTimeout(resolve, milliseconds));
}

const useAppDispatch = () => useDispatch<AppDispatch>();
const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

const useIsMount = () => {
  const isMountRef = useRef(true);
  useEffect(() => {
    isMountRef.current = false;
  }, []);
  return isMountRef.current;
};

const PreviewPanel = (props: PreviewPanelProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { t } = useORTranslation(['PreviewPanel', 'InfoPanel']);

  const isMount = useIsMount();

  const rootDivElement = useRef<HTMLDivElement>(null);
  const previewImagesEndRef = useRef<HTMLLIElement>(null);
  const imageListRef = useRef<HTMLUListElement>(null);

  const [showCancelStudy, setShowCancelStudy] = useState<boolean>(false);
  const [fadeIn, setFadeIn] = useState(false)

  const isImageDescVisible: boolean = useAppSelector((state) => selectImageDescVisible(state));
  const isDeviceRunnerActive: boolean = useAppSelector((state) => selectDeviceRunnerActive(state));
  const currentWorkitemId: string = useAppSelector((state) => selectedWorkitem(state));
  // including artifactIdsForOrder caused updates of this component when all instances and artifacts are loaded (needed for srolling)
  const artifactIdsForOrder = useAppSelector((state) => selectArtifactIdsForOrder(state, props.orderId));
  const workitemsList: string = useAppSelector((state) => studyWorkitemsList(state, props.orderId ?? ''));
  const allowComplete: boolean = useAppSelector((state) => selectAllowComplete(state));
  const imagePathForCurrentTheme: string = useAppSelector((state) => getImagePathForCurrentTheme(state));
  const layout: any = useAppSelector((state) => getLayout(state));

  const currentStudyAsString: string | undefined = useAppSelector((state) => selectedStudy(state, props.orderId ?? ''));
  const study = currentStudyAsString ? JSON.parse(currentStudyAsString) : undefined;
  const currentWorkitemAsString: string | undefined = useAppSelector((state) => getSelectedWorkitem(state));
  const currentWorkitem = currentWorkitemAsString ? JSON.parse(currentWorkitemAsString) : undefined;
  const currentSeriesAsString: string | undefined = useAppSelector((state) => selectSeriesDataForWorkitem(state, currentWorkitemId));
  const currentSeries = currentSeriesAsString ? JSON.parse(currentSeriesAsString) : undefined;
  const currentInstanceAsString: string | undefined = useAppSelector((state) => selectInstanceForWorkitem(state, currentWorkitemId));
  const currentInstance = currentInstanceAsString ? JSON.parse(currentInstanceAsString) : undefined;
  const currentArtifactAsString: string | undefined = useAppSelector((state) => selectArtifactForSelectedWorkitem(state));
  const currentArtifact = currentArtifactAsString ? JSON.parse(currentArtifactAsString) : undefined;
  const currentArtifactId: string | undefined = useAppSelector((state) => selectArtifactIdForSelectedWorkitem(state));
  const deviceRunnerError: string = useAppSelector((state) => selectDeviceRunnerError(state));
  const procedureSelectionVisibilityState: number = useAppSelector((state) => selectProcedureSelectionVisibilityState(state));
  const deviceRunnerPath: string = useAppSelector((state) => selectDeviceRunnerPath(state));
  const countImages = useAppSelector((state) => selectCountImages(state, props.orderId));
  const countPlannings = useAppSelector((state) => selectCountPlannings(state, props.orderId));
  const matrixIndexForInsert = useAppSelector((state) => getMatrixIndexForInsert(state, -1));
  const activateCompleteStudy: boolean = useAppSelector((state) => haveOneImageToComplete(state, props.orderId)) || (study && study.state === "COMPLETED");
  const hasOpenPlannings: boolean = useAppSelector((state) => haveOpenPlannings(state, props.orderId));
  const firstPlanningWorkitemIdInOrder = useAppSelector((state) => selectFirstPlanningWorkitemIdInOrder(state, props.orderId));
  const activatePlanningMode = useAppSelector((state) => getActivatePlanningMode(state));
  const completableWorkitemsString = useAppSelector((state) => getCompletableWorkitemIds(state, props.orderId));

  const completableWorkitems = JSON.parse(completableWorkitemsString);

  const [reopenStudy] = useReopenStudyMutation();
  const [cancelStudy] = useCancelStudyMutation();

  const isFinalizeDialogVisible: number = useAppSelector((state) => selectFinalizeStudyDialogVisible(state));

  /* const {
    data,
    isSuccess,
  } = useGetStudyQuery(props.orderId, {
    refetchOnMountOrArgChange: false,
    skip: false,
  }) */

  const {
    data,
    isSuccess,
  } = useGetStudyWithWorkitemsQuery(props.orderId, {
    refetchOnMountOrArgChange: Constants.SYNCHRONOUS_BACKEND,
    skip: false,
  })

  useEffect(() => {
    // set key to identify panel for css styling (overwrite flexlayout styling)

    if (isSuccess) {
      dispatch({ type: "Orders/setCurrentOrderId", payload: props.orderId });
    }

    return () => {
      //if(!isWorkitemPlanning) dispatch({ type: 'ImageDisplay/setSelectedWorkitem', payload: "" });
      dispatch({ type: "CurrentViewports/purge", payload: {} });
      dispatch({ type: "ImageDisplay/purgeMatrixImages", payload: {} });
      dispatch({ type: "ImageDisplay/setAllWindowLevelHistActive", payload: false });
      dispatch({ type: "ImageDisplay/setViewportSynchronization", payload: false });
      dispatch({ type: "ImageDisplay/setIsOverviewAndAnnotationsVisible", payload: true });
      //dispatch({ type: "ImageDisplay/clearProtocolStepSelectionProps", payload: {} });
    };
  }, [dispatch, props.orderId, isSuccess]);

  useEffect(() => {
    if (isSuccess && data?.study?.id === props.orderId) {
      let parentElement = rootDivElement.current?.parentElement;
      if (layout?.showItemDescInPreviewPanel) {
        parentElement?.setAttribute("key", "PreviewPanel_key_no_overlay");
      } else {
        parentElement?.setAttribute("key", "PreviewPanel_key");
      }
    }
  }, [layout?.showItemDescInPreviewPanel, isSuccess, props.orderId, data?.study?.id]);

  useLayoutEffect(() => {
    if (!isMount) {
      //console.log(imageListRef?.current?.scrollHeight + " " + imageListRef?.current?.clientHeight + " " + imageListRef?.current?.scrollTop + " " + previewImagesEndRef?.current?.offsetTop);
      //console.log(JSON.stringify(artifactIdsForOrder.map((item: any) => item?.artifactId)));
      //console.log(workitemList);
      if (previewImagesEndRef?.current?.offsetTop !== undefined && imageListRef?.current?.clientHeight !== undefined && imageListRef?.current?.scrollTop !== undefined) {
        //console.log(previewImagesEndRef?.current?.offsetTop - imageListRef?.current?.scrollTop + " --- " + imageListRef?.current?.clientHeight);
        if (previewImagesEndRef?.current?.offsetTop - imageListRef?.current?.scrollTop > imageListRef?.current?.clientHeight ||
          previewImagesEndRef?.current?.offsetTop - imageListRef?.current?.scrollTop < 0) {
          imageListRef.current?.scrollTo(0, previewImagesEndRef.current.offsetTop - 80);
          //console.log("scrollTo: " + previewImagesEndRef.current.offsetTop);
        }
      }
    }
  }, [artifactIdsForOrder, JSON.stringify(workitemsList)]);

  const onImageSelect = (workitemId: string, artifactId: string | undefined, artifactId_raw: string | undefined, workitemData?: workitem | undefined, protocolCorrelationId?: string) => {
    if (isSuccess && artifactId) {

      const r = document.querySelector(':root');
      // @ts-ignore
      r?.style.setProperty('--img_desc_left_margin', `${2}px`);

      const matrixIndexForImageId: { matrixIndex: number; imageId: string } | undefined = props.matrixImages?.find(
        (mi: { matrixIndex: number; imageId: string, correlationId: string }) => (mi.imageId === workitemId && mi.correlationId === protocolCorrelationId)
      );

      let doImageLoad: boolean = matrixIndexForImageId === undefined;
      if (matrixIndexForImageId) {
        // if previous selected image was a planning reload the image (displayElement.image has been set to undefined)
        const cornerstoneElements: EnabledElement[] = cornerstone.getEnabledElements();
        const displayElement: EnabledElement | undefined = cornerstoneElements.find(
          (element: EnabledElement) => element.element.id === `${Constants.IMAGE_DISPLAY_GENERIC_ELEMENT_NAME}_${matrixIndexForImageId.matrixIndex}`
        );
        if (displayElement) {
          let canvas: any = undefined;
          if (matrixIndexForImageId?.matrixIndex !== undefined) {  //check if the is a (pdf) document display at this matrix index
            canvas = document.querySelector(`#documentCanvas_${matrixIndexForImageId?.matrixIndex.toString()}`) as HTMLCanvasElement;
          }
          if (displayElement.image === undefined && !canvas?.clientWidth) {
            doImageLoad = true;
          }
        }
      }

      if (matrixIndexForImageId) {
        dispatch({ type: "ImageDisplay/setProtocolCorrelationId", payload: { matrixIndex: matrixIndexForImageId.matrixIndex, protocolCorrelationId: protocolCorrelationId } });
      } else {
        dispatch({ type: "ImageDisplay/setProtocolCorrelationId", payload: { matrixIndex: matrixIndexForInsert, protocolCorrelationId: protocolCorrelationId } });
      }

      // image is already displayed in matrix -> just set the selected image
      if (matrixIndexForImageId && !doImageLoad) {

        dispatch({ type: "ImageDisplay/setSelectedWorkitem", payload: workitemId });
        dispatch({ type: "ImageDisplay/setSelectedIndex", payload: matrixIndexForImageId.matrixIndex });
      } else {

        dispatch({ type: "ImageDisplay/setRawImageActive", payload: { matrixIndex: -1, isActive: false } });

        dispatch(
          loadImage({
            imageName: `${serviceEndpoints.ARTIFACT_STORE_URL}/artifacts/${artifactId}/raw`,
            imageId: workitemId,
            artifactId: artifactId,
            artifactId_Raw: artifactId_raw,
            createPreviewImage: addImageMode.none,
            matrixIndex: -1,
            display_raw: false,
            enabledElementForExport: undefined,
          })
        );
      }
    }
    dispatch(setAcquisitionActive({ setAcquistionButtonActive: false, studyId: undefined, workitemId: undefined }));
    dispatch({ type: "Procedure/setProcedureSelectionVisibilityState", payload: 0 });
  };

  const previewPanelButtonListClick = () => {
    dispatch({ type: "ImageDisplay/setImageDescVisible", payload: !isImageDescVisible });
  };

  const previewPanelButtonNewClick = async () => {
    await dispatch(setAcquisitionActive({ setAcquistionButtonActive: true, studyId: props.orderId, workitemId: undefined }));
    dispatch({ type: "Procedure/setProcedureSelectionVisibilityState", payload: 1 });
    await Sleep(300);
    if (previewImagesEndRef?.current?.offsetTop) {
      imageListRef.current?.scrollTo(0, previewImagesEndRef.current.offsetTop);
    }
  };

  const setSubject = () => {
    dispatch({ type: "Subject/setSelectedSubject", payload: structuredClone(data?.study?.details?.subject) });
    dispatch({ type: "ImageDisplay/setSubjectDialogVisible", payload: 4 });
  };

  const setProcedureSelectionVisibilityState = (state: number) => {
    dispatch({ type: "Procedure/setProcedureSelectionVisibilityState", payload: state });
    if (state <= 1) {
      try {
        dispatch(apiSlice.endpoints.putChangeOperationMode.initiate(
          {
            deviceRunnerPath: deviceRunnerPath, qualifier: 'default',
            active: false, studyId: undefined, workitemId: undefined
          })).unwrap();
      } catch (error) {
        dispatch({ type: "Acquisition/setDeviceRunnerError", payload: 'device runner connection error' });;
      }
    }
  }

  const toggleShowCancelStudy = (evt: any) => {
    evt.stopPropagation();
    evt.preventDefault();
    setShowCancelStudy(!showCancelStudy);
    setFadeIn(!fadeIn);
  }

  const completeOrReopenStudy = (evt: any) => {
    evt.stopPropagation();
    evt.preventDefault();

    if (study.state === "COMPLETED") {
      reopenStudy({ orderId: props.orderId }).unwrap();

      dispatch(
        apiSlice.util.updateQueryData('getStudyWithWorkitems', props.orderId, (data: any) => {
          if (data?.study)
            data.study.state = "OPENED";
          return data;
        })
      );

      return;
    }


    console.log("Completing study: " + props.orderId);
    if (hasOpenPlannings || completableWorkitems.length > 0) {
      dispatch({ type: 'ImageDisplay/setFinalizeStudyDialogVisible', payload: 1 });
      return;
    }
    dispatch(apiSlice.endpoints.completeStudy.initiate({
      orderId: props.orderId,
    }
    ));

    // TODO resolve copy of the following code from Navbar.tsx onClick() !
    dispatch(setAcquisitionActive({ setAcquistionButtonActive: false, studyId: undefined, workitemId: undefined }));
    dispatch({ type: 'ImageDisplay/setSelectedWorkitem', payload: "" });
    dispatch({ type: "Procedure/setProcedureSelectionVisibilityState", payload: 0 });
    dispatch({ type: "Procedure/setSelectedComponentSubtree", payload: ['human', 'Adult'] });
    dispatch({ type: "Procedure/setProcedureSelectionVisibilityState", payload: 0 });

    const r = document.querySelector(':root');
    // @ts-ignore
    r?.style.setProperty('--img_desc_left_margin', `${0}px`);

    navigate(`/${location?.search ?? ''}`);

    return;
  } // completeOrReopenStudy


  const docancelStudy = (evt: any) => {
    evt.stopPropagation();
    evt.preventDefault();
    console.log("Canceling study: " + props.orderId);

    cancelStudy({ orderId: props.orderId }).unwrap();

    dispatch(
      apiSlice.util.updateQueryData('getStudyWithWorkitems', props.orderId, (data: any) => {
        if (data?.study)
          data.study.state = "CANCELED";
        return data;
      })
    );

    // TODO resolve copy of the following code from Navbar.tsx onClick() !
    dispatch(setAcquisitionActive({ setAcquistionButtonActive: false, studyId: undefined, workitemId: undefined }));
    dispatch({ type: 'ImageDisplay/setSelectedWorkitem', payload: "" });
    dispatch({ type: "Procedure/setProcedureSelectionVisibilityState", payload: 0 });
    dispatch({ type: "Procedure/setSelectedComponentSubtree", payload: ['human', 'Adult'] });
    dispatch({ type: "Procedure/setProcedureSelectionVisibilityState", payload: 0 });

    const r = document.querySelector(':root');
    // @ts-ignore
    r?.style.setProperty('--img_desc_left_margin', `${0}px`);

    navigate(`/${location?.search ?? ''}`);

    return;
  } // docancelStudy

  const handleFinalizeStudyDialogCancel = () => {
    dispatch({ type: "ImageDisplay/setFinalizeStudyDialogVisible", payload: 0 });
  };

  const backToPlanning = () => {
    setProcedureSelectionVisibilityState(1);

    let nextSelectedWorkitemId: string = '';

    for (let nextWorkitem of data?.workitems) {
      if (nextWorkitem) {
        if (nextWorkitem.data.protocol?.steps) {
          for (let step of nextWorkitem.data.protocol?.steps) {
            if (step?.type === "de.or.protocol.steps/acquisition" && step?.rejected !== undefined && step?.performed === undefined) {
              nextSelectedWorkitemId = nextWorkitem.data.id;
              break;
            }
          }
          if (nextWorkitem !== '') {
            break;
          }
        }
      }
      if (nextWorkitem !== '') {
        break;
      }
    }

    dispatch({ type: "ImageDisplay/setSelectedWorkitem", payload: nextSelectedWorkitemId });
  };

  const doCancelCancelStudy = (evt: any) => {
    setShowCancelStudy(false);
  }

  const goToExposure = () => {
    if (currentWorkitem === undefined || currentWorkitem === '') {
      dispatch({ type: "ImageDisplay/setSelectedWorkitem", payload: firstPlanningWorkitemIdInOrder });
    }
    setProcedureSelectionVisibilityState(2)
  }

  const goToPlanning = () => {
    dispatch({ type: "ImageDisplay/setSelectedWorkitem", payload: '' });
    if (!activatePlanningMode) {
      dispatch({ type: "Procedure/setProcedureSelectionVisibilityState", payload: 1 });
    }
  }

  return (
    <>
      {isSuccess && data?.study?.id === props.orderId ?
        <div className="previewPanelDiv" ref={rootDivElement} onContextMenu={(e) => e.preventDefault()}>
          <div className="previewPanel">
            <div className="previewPanelButtonBar">
              {/* <button className="previewPanelButtonOverview">
            <img src="/images/Anzeige_Bilduebersicht_aktiv.svg" alt="Anzeige Bilduebersicht aktiv.svg" onClick={() => previewPanelButtonOverviewClick()} />
          </button> */}
              <button className={(data?.study?.state === "COMPLETED" && allowComplete) ? "previewPanelButtonNew_disabled" : "previewPanelButtonNew"}
                disabled={data?.study?.state === "COMPLETED" && allowComplete} title={t('newWorkitem') as string}>
                <img src={`${imagePathForCurrentTheme}Neuer_Roentgenauftrag.svg`}
                  onError={(event: any) => { event.target.src = "/images/Neuer_Roentgenauftrag.svg"; event.onerror = null }}
                  alt="Anzeige Bilduebersicht aktiv.svg" onClick={() => previewPanelButtonNewClick()} />
              </button>
              <div className="vl" />
              <button className="previewPanelButtonList" title={t('imageDescription') as string}>
                <img onClick={() => previewPanelButtonListClick()} src={`${imagePathForCurrentTheme}Button_Liste.svg`}
                  onError={(event: any) => { event.target.src = "/images/Button_Liste.svg"; event.onerror = null }} alt="Button_Liste.svg" />
              </button>
            </div>

            {data?.study?.details?.subject ?
              <>
                <hr className="hruleNameFieldset" />
                <div className="study-Info">
                  <div className="fieldset-Wrapper">
                    <fieldset className={"fieldset"}>
                      <legend className="patientLabel">Patient</legend>
                      <div className="subject">
                        <InfoPanel className="infoPanelWrapper_Subject" configFile={"/StudyInfo_PreviewPanel.json"}
                          workitemId={currentWorkitemId} artifactId={currentArtifactId} orderId={props.orderId ?? ''}
                          sources={{ currentStudy: study, currentWorkitem, currentSeries, currentInstance, currentArtifact, subject: study?.details?.subject }}
                          editMode={false} editEnabled={study && (study.state !== 'COMPLETED' || !allowComplete)}
                        />
                        <div className="editButtonWrapper" title={t('Edit Patient Data') as string} onClick={(evt: any) => setSubject()}>
                          <img className="editButton" onClick={() => previewPanelButtonListClick()} src={`${imagePathForCurrentTheme}Schreiben.svg`}
                            onError={(event: any) => { event.target.src = "/images/Schreiben.svg"; event.onerror = null }} alt="Schreiben.svg" />
                        </div>
                      </div>
                      <div className="RelatedStudiesWrapperTop">
                        <hr className="hrule" />
                        {data?.study?.details?.subject ?

                          <RelatedStudies study={data?.study} />
                          : null}
                        <div className="vspace"></div>
                      </div>
                    </fieldset>

                  </div>
                </div>
              </>
              : null}


            <hr className="hrule" />
            <div className="close-bar">
              <div className="close-bar-wrapper">
                <div></div>
                {isImageDescVisible ?
                  <button className="close-imgDesc-button" onClick={() => previewPanelButtonListClick()}>
                    <img className="close-image" src={`${imagePathForCurrentTheme}Schliessen_Kreuz.svg`}
                      onError={(event: any) => { event.target.src = "/images/Schliessen_Kreuz.svg"; event.onerror = null }}
                      alt="close" />
                  </button>
                  : null}
              </div>
            </div>

            <div>
              <div className="previewPanelExposureButtonWrapper">
                <div className="previewPanelExposureButton">
                  <div className="orderInfo">
                    <span className="previewdiv">
                      {countImages !== undefined && countPlannings !== undefined ? `${countImages}/${countImages + countPlannings}` : ''}
                    </span>
                  </div>

                  {activatePlanningMode ?
                    <>
                      <button type="submit" className={(procedureSelectionVisibilityState === 1 &&
                        deviceRunnerError === '' && (((currentWorkitem === undefined || currentWorkitem === '') && firstPlanningWorkitemIdInOrder !== '') || (currentWorkitem !== undefined && currentWorkitem !== ''))) && data?.study?.state !== "COMPLETED" ?
                        "exposeButton venaDefaultButton" : "exposeButton_invisisble"} onMouseDown={() => goToExposure()}>
                        Perform exposures
                      </button>

                      <button type="submit" className={(procedureSelectionVisibilityState === 2 || procedureSelectionVisibilityState === 0
                        || currentWorkitem?.state === 'COMPLETED') && data?.study?.state !== "COMPLETED" ?
                        "planButton venaDefaultButton" : "planButton_invisisble"} onMouseDown={() => { setProcedureSelectionVisibilityState(1); dispatch({ type: "ImageDisplay/setSelectedWorkitem", payload: '' }); }}>
                        Back to planning
                      </button>
                    </> : null}
                </div>
              </div>
            </div>

            <ul className={isDeviceRunnerActive ? "previewImage-list" : "previewImage-list_noDeviceRunner"} ref={imageListRef}>
              {data?.study && data?.workitems?.map((workitem: any, i: number) => (
                <li key={i.toString()} className="previewImage" ref={(currentWorkitemId !== undefined && currentWorkitemId !== '') ? (workitem?.data?.id === currentWorkitemId ? previewImagesEndRef : null) : (i === (data?.workitems?.length - 1) && procedureSelectionVisibilityState > 0 ? previewImagesEndRef : null)}
                  id={workitem?.data?.id === currentWorkitemId ? workitem?.data?.id : ''}>
                  <div className={workitem?.data?.id === currentWorkitemId ? "previewImage-list-item_selected" : "previewImage-list-item"}>
                    <Workitem workitemId={workitem?.data?.id} workitem={workitem?.data} orderId={props.orderId} onImageSelect={onImageSelect}
                      getRawSeries={true} includeImageDesc={true} showPlannings={true} showDocuments={true} className={undefined} showRejected={true} />
                  </div>
                </li>
              ))}
            </ul>

            <div className={(procedureSelectionVisibilityState === 1 || !activatePlanningMode) && data?.study?.state !== "COMPLETED" ? "SelectNewProcedureWrapper" : "SelectNewProcedureWrapper_invisible"}>
              <div className="SelectNewProcedure">
                <button type="submit" className={(currentWorkitemId === undefined || currentWorkitemId === '') && procedureSelectionVisibilityState > 0 ?
                  "SelectNewProcedureButton_selected venaDefaultButton" : "SelectNewProcedureButton venaDefaultButton"}
                  onClick={(evt: any) => goToPlanning()}>
                  <div className="SelectNewProcedureButtonInset">
                    <div className="SelectNewProcedureButtonInsetButton venaDefaultButton">
                      <img className="SelectNewProcedureButtonImg" src={`${imagePathForCurrentTheme}Neuer_Roentgenauftrag.svg`}
                        onError={(event: any) => { event.target.src = "/images/Neuer_Roentgenauftrag.svg"; event.onerror = null }}
                        alt="Anzeige Bilduebersicht aktiv.svg" />
                    </div>
                  </div>
                  <label className="SelectNewProcedureButtonLabel">{t('Add new planning')}</label>
                </button>
              </div>
            </div>

            <div className="procedureListEndSpacer"></div>

            <div className="completeStudyButtonWrapper">
              <div className="completeStudyButtonAndMenu">
                <button
                  className="completeStudyButton venaDefaultButton"
                  onClick={activateCompleteStudy ? (evt: any) => completeOrReopenStudy(evt) : (evt: any) => { }}
                  disabled={!activateCompleteStudy}
                >
                  {(study && study.state === "COMPLETED") ? t('reopenStudy') : t('completeStudy')}
                </button>
                <button
                  className="cancelStudyMenu venaDefaultButton"
                  onClick={(evt: any) => toggleShowCancelStudy(evt)}
                  disabled={study?.state === "COMPLETED"}
                >
                  <img src={showCancelStudy ? "/images/Pfeil_nach_oben.svg" : "/images/Pfeil_nach_unten.svg"} />
                </button>
              </div>
              {showCancelStudy &&
                <div className="closeStudyMenu">
                  <button
                    className={`cancelStudyButton venaDefaultButton ${fadeIn && "fade-in"}`}
                    onClick={(evt: any) => submit([t('InfoPanel:confirmationCancelStudy'), t('InfoPanel:confirmationQuestion'),], docancelStudy, doCancelCancelStudy)}
                    disabled={study?.state === "COMPLETED"}
                  >
                    {t('InfoPanel:cancelStudy')}
                  </button>
                </div>
              }
            </div>

            <CommonDialog onOk={undefined} keepOnOK={true} onClose={handleFinalizeStudyDialogCancel} title={t('FinalizeStudyDialog:finalizeStudy')} visible={isFinalizeDialogVisible > 0} okText={hasOpenPlannings ? t('OK', { ns: 'common' }) : t('FinalizeStudyDialog:finalize')} cancelText={t("cancel", { ns: 'common' })}
              default={{ x: (window.innerWidth - 450) / 20, y: (window.innerHeight - 700) / 2, width: 550, height: undefined }} childProps={{}}>
              <FinalizeStudyDialog orderId={props.orderId} showWaitPACS={false} isCalledFromOrderList={false} />
            </CommonDialog>

            <div className="RelatedStudiesWrapperBottom">
              <hr className="hrule" />
              {data?.study?.details?.subject ?

                <RelatedStudies study={data?.study} />
                : null}
              <div className="vspace"></div>
            </div>
          </div>
        </div>
        : null}
    </>
  );
};

export default connect(mapStateToProps)(PreviewPanel);
