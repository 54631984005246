import React, { useEffect, useRef } from "react";
import { TypedUseSelectorHook, useSelector } from "react-redux";
import { parseISO } from 'date-fns';
import { RootState } from "../../store";
import "./Messages.scss";
import { message, selectMessages, messageSeverity } from "./MessageSlice";

type WindowLevelHistProps = {
};

const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

const Messages = (props: WindowLevelHistProps) => {

  const wrapperDivElement = useRef<HTMLDivElement>(null);
  const messages: message[] = useAppSelector((state) => selectMessages(state));

  useEffect(() => {

    return () => {

    };
  }, []);

  return (
    <>
      <div className="messageListWrapper" ref={wrapperDivElement} onContextMenu={(e) => e.preventDefault()}>
        {messages.map((message, i) => (
          <p className={message.severity === messageSeverity.error ? "message-line-error" : message.severity === messageSeverity.warning ? "message-line-warn" : "message-line"} key={i}>
            {`${parseISO(message.timestamp).toLocaleString()}  ---   ${'    '}${message.text}`}
          </p>
        ))}
      </div>
    </>
  );
};

export default Messages;
