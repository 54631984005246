import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { throttle } from 'throttle-debounce';
import { apiSlice, device, deviceSet, property } from '../../apis/apiSlice';
import { AppDispatch, RootState } from '../../store';
import HoverRangeslider from '../Utils/HoverRangeslider';
import { selectCurrentDeviceProperties, selectSoftwareTriggerState } from './AcquisitionSlice';
import { getValueForProperty } from './Device';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useORTranslation } from '../Localization/ORLocalization';
import { getLayout, getSelectedWorkitem } from '../OrderList/OrdersSlice';
import { Constants } from '../../Constants';
import { getCurrentProtocolStepNumber } from '../ImageDisplay/ImageDisplaySlice';


interface DeviceChangeablePropProps {
	device: device | deviceSet;
	endpoint: string;
	property: [string, property];
	isfirstDefaultWidget: boolean;
	colorCode: number;
};

const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
const useAppDispatch = () => useDispatch<AppDispatch>();

const DeviceChangeableProp = (props: DeviceChangeablePropProps) => {
	const dispatch = useAppDispatch();

	const { t } = useORTranslation(['Acquisition', 'common']);
	const { language } = useORTranslation([])

	const [error, setError] = useState<string>('');
	const [value, setValue] = useState<any>(undefined);

	const valueRef = useRef<any>();
	valueRef.current = value;

	const currentPropertyValues: [string, any][] = JSON.parse(useAppSelector((state) => selectCurrentDeviceProperties(state, props.device.config.endpoint)));
	const currentSoftwareTriggerState: string | undefined = useAppSelector((state) => selectSoftwareTriggerState(state));
	const currentWorkitemAsString: string | undefined = useAppSelector((state) => getSelectedWorkitem(state));
	const currentWorkitem = currentWorkitemAsString ? JSON.parse(currentWorkitemAsString) : undefined;
	const currentProtocolSettingsStepNumber: number = useAppSelector((state) => getCurrentProtocolStepNumber(state, Constants.ACQUISITION_STEP_TYPE, 'scheduled', 'rejected'));
	const layout: any = useAppSelector((state) => getLayout(state));

	const throttleOnValueChange = useCallback(throttle(200, async (inValue: any) => {

		const url = (new URL(props.endpoint)).origin + props.property[1]?.links[0]?.href;

		let body: any = undefined;
		if (props.property[0]) {
			if (props.property[1]?.type === 'integer' || props.property[1]?.type === 'number') {
				setValue(inValue);
				body = { [props.property[0]]: Number(inValue) };
			} else if (props.property[1]?.type === 'boolean') {
				body = { [props.property[0]]: !valueRef?.current };
				setValue(valueRef?.current === undefined ? true : !valueRef?.current);
			} else {
				setValue(inValue);
				body = { [props.property[0]]: inValue };
			}
		}

		setError('');
		try {
			await dispatch(apiSlice.endpoints.putWebThingProp.initiate({ url: url, body: body })).unwrap();
		} catch (err: any) {
			//console.log(err?.error);
			setError(err?.error);
		}
	}), [props?.device?.category, props?.device?.id]);

	useEffect(() => {
		setValue(getValueForProperty(props.property[0], currentPropertyValues));
	}, [JSON.stringify(getValueForProperty(props.property[0], currentPropertyValues)), language]);

	useEffect(() => {
		if (currentWorkitem?.protocol) {
			if (props.property[1]?.title === 'Voltage') {
				setValue(getValueForProperty(props.property[0], currentWorkitem?.protocol?.steps[currentProtocolSettingsStepNumber]?.scheduled?.generator?.kv));
			}
		}
	}, []);

	function getPropsItem() {
		if ((props.property[1]?.type === 'integer' || props.property[1]?.type === 'number') && props.property[1]?.minimum !== undefined && props.property[1]?.maximum !== undefined) {
			return <div className="SettingsRow" title={`${t(props.property[1]?.description)}`}>
				<div className={error === '' ? "SettingsRowInputWrapper" : "SettingsRowInputWrapper_error"}>
					<label className="SettingsRowLabel">{t(props.property[1]?.title) ?? ''}</label>
					<span className="HoverRangeInput" title={error === '' ? undefined : error}>
						<HoverRangeslider onChange={throttleOnValueChange} steps={props.property[0] === 'softwareTriggerDelay' ? 100 : (props.property[0] === 'exposure' ? 0.1 : 1)}
							startValue={getValueForProperty(props.property[0], currentPropertyValues) ?? 0} readOnly={currentSoftwareTriggerState !== 'IDLE'}
							min={props.property[1]?.minimum} max={props.property[1]?.maximum} units={props.property[1]?.unit?.replace('milliseconds', 'ms')} />
					</span>
				</div>
			</div>
		} else if (props.property[1]?.type === 'integer' || props.property[1]?.type === 'number' || props.property[1]?.type === 'string') {
			return <div className="SettingsRow" title={`${t(props.property[1]?.description)}`}>
				<div className="SettingsRowInputWrapper">
					<label className="SettingsRowLabel">{t(props.property[1]?.title) ?? ''}</label>
					<div className={error === '' ? "SettingsRow-NumberInput" : "SettingsRow-NumberInput_error"} >
						<input type={props.property[1]?.type === 'integer' ? "number" : "text"} onChange={(evt: any) => throttleOnValueChange(evt?.target?.value)} value={value ?? ''}
							title={error === '' ? undefined : error} />
						{props.property[1]?.unit !== undefined ?
							<label className="SettingsRowUnit">{props.property[1]?.unit?.replace('milliseconds', 'ms')}</label>
							: null}
					</div>
				</div>
			</div>
		}
		else if (props.property[1]?.type === 'boolean' && props?.device?.category !== 'SET') {
			return <div className="SettingsRow" title={`${t(props.property[1]?.description)}`}>
				<div className="SettingsRowInputWrapper">
					<label className="SettingsRowLabel">{t(props.property[1]?.title) ?? ''}</label>
					<div className={error === '' ? "SettingsRow-BooleanInput" : "SettingsRow-BooleanInput_error"}
						data-value1={t("on", { ns: "common" })} data-value2={t("off", { ns: "common" })}>
						<input id={`SettingsRow-BooleanInput_${props?.device?.id}`} type="checkbox"
							onChange={(evt: any) => throttleOnValueChange(evt?.target?.value)} checked={value ?? false}
							title={error === '' ? undefined : error} />
						<label htmlFor={`SettingsRow-BooleanInput_${props?.device?.id}`}></label>
					</div>
				</div>
			</div>
		}
	}

	console.log(props.property[0]);

	return (
		<>
			{props.isfirstDefaultWidget && (props.property[0] !== "softwareTriggerDelay" || layout?.deviceSet_panel_display_footer) ? <hr className="hrule3" /> : <div className="vSpace" />}
			{getPropsItem()}
		</>
	);
}

export default DeviceChangeableProp;
