import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import './DateRangeSelector.scss';

import { useORTranslation } from '../Localization/ORLocalization';

interface DateRangeSelectorProps {
	handleDateRangeSelection: (ranges: any) => void;
	dateRange: any;
	refresh?: number;
	resetRefresh?: () => void;
	onActionDone?: () => void;
}

const DateRangeSelector = (props: DateRangeSelectorProps) => {

	const { locales } = useORTranslation([]);

	return (
		<div className="datepicker-wrapper">
			<div className="datepicker-wrapper">
				<DateRangePicker className="datepicker"
					ranges={props.dateRange}
					locale={locales}
					onChange={props.handleDateRangeSelection}
				/>
			</div>
		</div >
	);
}

export default DateRangeSelector;
