import csTools from 'cornerstone-tools';
import { getPixels, calculateEllipseStatistics } from "./utils";
import store from '../../../store';
import { selectedAnnotations } from '../../OrderList/OrdersSlice';
import { t } from "../../Localization/ORLocalization";
const getNewContext = csTools.import("drawing/getNewContext");
const getPixelSpacing = csTools.importInternal('util/getPixelSpacing');
const calculateSUV = csTools.importInternal('util/calculateSUV');
const draw = csTools.import("drawing/draw");
const setShadow = csTools.import("drawing/setShadow");
const drawEllipse = csTools.import("drawing/drawEllipse");
const drawHandles = csTools.import("drawing/drawHandles");
const drawLinkedTextBox = csTools.import("drawing/drawLinkedTextBox");
const getROITextBoxCoords = csTools.import("util/getROITextBoxCoords");
const numbersWithCommas = csTools.import("util/numbersWithCommas");
const getToolState = csTools.getToolState;

/**
 * @public
 * @class EllipticalRoiExtendedTool
 * @memberof Tools
 *
 * @classdesc Tool for setting wwwc based on a rectangular region.
 * @extends Tools.Annotation.EllipticalRoiTool
 */
export default class EllipticalRoiExtendedTool extends csTools.EllipticalRoiTool {
  /** @inheritdoc */
  constructor(props = {}) {
    const defaultProps = {
      name: 'EllipticalRoiExtended',
      supportedInteractionTypes: ['Mouse', 'Touch'],
      configuration: {
        showMinMax: false,
        // showHounsfieldUnits: true,
        drawHandlesOnHover: props.configuration.drawHandlesOnHover ?? false,
        hideHandlesIfMoving: false,
        renderDashed: false,
        shadow: true,
      },
    };
    super(defaultProps);
    this.pixelToLengthFactor = undefined;
    this.scaleFactor = undefined;
  }

  renderToolData(evt) {
    const toolData = csTools.getToolState(evt.currentTarget, this.name);

    if (!toolData) {
      return;
    }

    const eventData = evt.detail;
    const { image, element } = eventData;
    const lineWidth = csTools.toolStyle.getToolWidth();
    const lineDash = csTools.getModule('globalConfiguration').configuration.lineDash;
    const {
      handleRadius,
      drawHandlesOnHover,
      hideHandlesIfMoving,
      renderDashed,
    } = this.configuration;
    const context = getNewContext(eventData.canvasContext.canvas);
    const { rowPixelSpacing, colPixelSpacing } = getPixelSpacing(image);

    // Meta
    const seriesModule =
      csTools.external.cornerstone.metaData.get('generalSeriesModule', image.imageId) ||
      {};

    // Pixel Spacing
    const modality = seriesModule.modality;
    const hasPixelSpacing = rowPixelSpacing && colPixelSpacing;
    const pixelToLengthFactor = this.pixelToLengthFactor;
    const scaleFactor = this.scaleFactor;

    draw(context, context => {
      // If we have tool data for this element - iterate over each set and draw it
      for (let i = 0; i < toolData.data.length; i++) {
        const data = toolData.data[i];

        if (data.visible === false) {
          continue;
        }

        // Configure
        const color = csTools.toolColors.getColorIfActive(data);
        const handleOptions = {
          color,
          handleRadius,
          drawHandlesIfActive: drawHandlesOnHover,
          hideHandlesIfMoving,
        };

        setShadow(context, this.configuration);

        const ellipseOptions = { color };

        if (renderDashed) {
          ellipseOptions.lineDash = lineDash;
        }

        // Draw
        drawEllipse(
          context,
          element,
          data.handles.start,
          data.handles.end,
          ellipseOptions,
          'pixel',
          data.handles.initialRotation
        );
        drawHandles(context, eventData, data.handles, handleOptions);

        // Update textbox stats
        if (data.invalidated === true) {
          if (data.cachedStats) {
            this.throttledUpdateCachedStats(image, element, data);
          } else {
            this.updateCachedStats(image, element, data);
          }
        }

        // Default to textbox on right side of ROI
        if (!data.handles.textBox.hasMoved) {
          const defaultCoords = getROITextBoxCoords(
            eventData.viewport,
            data.handles
          );

          Object.assign(data.handles.textBox, defaultCoords);
        }

        const textBoxAnchorPoints = handles =>
          _findTextBoxAnchorPoints(handles.start, handles.end);
        if (data.cachedStats) {
          const textBoxContent = _createTextBoxContent(
            context,
            image.color,
            data.cachedStats,
            modality,
            hasPixelSpacing,
            pixelToLengthFactor,
            scaleFactor,
            this.configuration
          );

          data.unit = _getUnit(modality, this.configuration.showHounsfieldUnits);

          drawLinkedTextBox(
            context,
            element,
            data.handles.textBox,
            textBoxContent,
            data.handles,
            textBoxAnchorPoints,
            color,
            lineWidth,
            10,
            true
          );
        }
      }
    });
  }

  updateCachedStats(image, element, data) {
    if (data?.toolName !== this.name) return;
    const seriesModule =
      csTools.external.cornerstone.metaData.get('generalSeriesModule', image.imageId) ||
      {};
    const modality = seriesModule.modality;
    const pixelSpacing = getPixelSpacing(image);

    const stats = _calculateStats(
      image?.image_raw ? image.image_raw : image,
      element,
      data.handles,
      modality,
      pixelSpacing,
      this.pixelToLengthFactor,
      this.scaleFactor,
    );

    data.cachedStats = stats;
    data.invalidated = false;
  }


  restore(element, data) {
    var xs = data.handles.start.x;
    var ys = data.handles.start.y;

    let measurement = super.createNewMeasurement(
      {
        currentPoints: { image: { x: xs, y: ys } },
        viewport: { rotation: undefined },
        element: element
      });

    measurement.handles.end.x = data.handles.end.x;
    measurement.handles.end.y = data.handles.end.y;
    measurement.handles.initialRotation = data.handles.initialRotation;
    measurement.handles.textBox = JSON.parse(JSON.stringify(data.handles.textBox));

    return measurement;
  }

  store(toolData) {
    toolData.storeToolData = {
      "handles": toolData.handles,
    }
  }

  setLenghtCalibration(element, pixelToLengthFactor) {
    const toolData = getToolState(element?.element, this.name);
    this.pixelToLengthFactor = global.structuredClone(pixelToLengthFactor);

    if (!toolData) {
      return;
    }

    for (let i = 0; i < toolData.data.length; i++) {
      this.updateCachedStats(element?.image, element?.element, toolData.data[i]);
    }
  }

  setScaleCalibration(element, scaleFactor) {
    const toolData = getToolState(element?.element, this.name);
    this.scaleFactor = scaleFactor;

    if (!toolData) {
      return;
    }

    for (let i = 0; i < toolData.data.length; i++) {
      this.updateCachedStats(element?.image, element?.element, toolData.data[i]);
    }
  }
}

function _calculateStats(image, element, handles, modality, pixelSpacing, pixelToLengthFactor, scaleFactor) {
  // Retrieve the bounds of the ellipse in image coordinates
  const ellipseCoordinates = _getEllipseImageCoordinates(
    handles.start,
    handles.end
  );

  // Retrieve the array of pixels that the ellipse bounds cover
  const pixels = getPixels(
    element, image,
    ellipseCoordinates.left,
    ellipseCoordinates.top,
    ellipseCoordinates.width,
    ellipseCoordinates.height
  );

  // Calculate the mean & standard deviation from the pixels and the ellipse details.
  const ellipseMeanStdDev = calculateEllipseStatistics(
    pixels,
    ellipseCoordinates
  );

  const imageId = image?.targetId;
  const annotations = selectedAnnotations(store.getState(), imageId);
  const resolution = annotations?.find((annotation) => annotation?.body?.type === "ResolutionMeasurementResult")?.body?.value?.resolution;

  let meanStdDevSUV;

  if (modality === 'PT') {
    meanStdDevSUV = {
      mean: calculateSUV(image, ellipseMeanStdDev.mean, true) || 0,
      stdDev: calculateSUV(image, ellipseMeanStdDev.stdDev, true) || 0,
    };
  }


  // Calculate the image area from the ellipse dimensions and pixel spacing
  const area =
    Math.PI *
    ((ellipseCoordinates.width * (pixelToLengthFactor?.col || pixelSpacing.colPixelSpacing || 1)) * (scaleFactor ?? 1) / 2) *
    ((ellipseCoordinates.height * (pixelToLengthFactor?.row || pixelSpacing.rowPixelSpacing || 1)) * (scaleFactor ?? 1) / 2);

  const width = ellipseCoordinates.width * (pixelToLengthFactor?.col || pixelSpacing.colPixelSpacing || 1) * (scaleFactor ?? 1);
  const height = ellipseCoordinates.height * (pixelToLengthFactor?.row || pixelSpacing.rowPixelSpacing || 1) * (scaleFactor ?? 1)

  return {
    area: area || 0,
    width: width,
    height: height,
    widthPx: ellipseCoordinates.width,
    heightPx: ellipseCoordinates.height,
    count: ellipseMeanStdDev.count || 0,
    mean: ellipseMeanStdDev.mean || 0,
    variance: ellipseMeanStdDev.variance || 0,
    stdDev: ellipseMeanStdDev.stdDev || 0,
    min: ellipseMeanStdDev.min || 0,
    max: ellipseMeanStdDev.max || 0,
    meanStdDevSUV,
    resolution: parseFloat(resolution)
  };
}

function _getUnit(modality, showHounsfieldUnits) {
  return modality === 'CT' && showHounsfieldUnits !== false ? 'HU' : '';
}

function _getEllipseImageCoordinates(startHandle, endHandle) {
  return {
    left: Math.round(Math.min(startHandle.x, endHandle.x)),
    top: Math.round(Math.min(startHandle.y, endHandle.y)),
    width: Math.round(Math.abs(startHandle.x - endHandle.x)),
    height: Math.round(Math.abs(startHandle.y - endHandle.y)),
  };
}

function _findTextBoxAnchorPoints(startHandle, endHandle) {
  const { left, top, width, height } = _getEllipseImageCoordinates(
    startHandle,
    endHandle
  );

  return [
    {
      // Top middle point of ellipse
      x: left + width / 2,
      y: top,
    },
    {
      // Left middle point of ellipse
      x: left,
      y: top + height / 2,
    },
    {
      // Bottom middle point of ellipse
      x: left + width / 2,
      y: top + height,
    },
    {
      // Right middle point of ellipse
      x: left + width,
      y: top + height / 2,
    },
  ];
}

function _createTextBoxContent(
  context,
  isColorImage,
  { area, width, height, widthPx, heightPx, mean, stdDev, min, max, meanStdDevSUV, resolution } = {},
  modality,
  hasPixelSpacing,
  pixelToLengthFactor,
  scaleFactor,
  options = {}
) {
  const showMinMax = options.showMinMax || true;
  const textLines = [];

  // Don't display mean/standardDev for color images
  const otherLines = [];

  if (!isColorImage) {
    const hasStandardUptakeValues = meanStdDevSUV && meanStdDevSUV.mean !== 0;
    const unit = _getUnit(modality, options.showHounsfieldUnits);

    let meanString = `${t('mean', {ns: 'Tools'})}: ${numbersWithCommas(mean.toFixed(2))} ${unit}`;
    const stdDevString = `${t('stdDev', {ns: 'Tools'})}: ${numbersWithCommas(
      stdDev?.toFixed(2)
    )} ${unit}`;

    const SNRString = `SNR: ${numbersWithCommas(
      (mean / stdDev)?.toFixed(2)
    )}`;

    // (((mean / stdDev) * 0.886) / (resolution * 10.0))?.toFixed(2)
    const SNRnString = resolution ? `SNRn: ${numbersWithCommas(
      (((mean / stdDev) * 0.886) / (resolution * 10.0))?.toFixed(2)
    )}` : '';

    // If this image has SUV values to display, concatenate them to the text line
    if (hasStandardUptakeValues) {
      const SUVtext = ' SUV: ';

      const meanSuvString = `${SUVtext}${numbersWithCommas(
        meanStdDevSUV.mean.toFixed(2)
      )}`;
      const stdDevSuvString = `${SUVtext}${numbersWithCommas(
        meanStdDevSUV.stdDev.toFixed(2)
      )}`;

      const targetStringLength = Math.floor(
        context.measureText(`${stdDevString}     `).width
      );

      /* while (context.measureText(meanString).width < targetStringLength) {
        meanString += ' ';
      } */
      meanString += ' ';

      otherLines.push(`${meanString}${meanSuvString}`);
      otherLines.push(`${stdDevString}     ${stdDevSuvString}`);
    } else {
      const widthString = `${t('width', {ns: 'Tools'})}: ${(width).toFixed(2)} mm / ${widthPx.toFixed(0)} px`
      const heightString = `${t('height', {ns: 'Tools'})}: ${(height).toFixed(2)} mm / ${heightPx.toFixed(0)} px`
      otherLines.push(widthString);
      otherLines.push(heightString);
      otherLines.push(`${meanString}`);
      otherLines.push(`${stdDevString}`);
      otherLines.push(`${SNRString}`);
    }
    if (resolution && resolution !== '') otherLines.push(`${SNRnString}`);

    if (showMinMax) {
      let minString = `Min: ${min} ${unit}`;
      const maxString = `Max: ${max} ${unit}`;
      const targetStringLength = hasStandardUptakeValues
        ? Math.floor(context.measureText(`${stdDevString}  `).width)
        : Math.floor(context.measureText(`${meanString}  `).width);

      /* while (context.measureText(minString).width < targetStringLength) {
        minString += ' ';
      } */
      minString += ' ';

      otherLines.push(`${minString}${maxString}`);
    }
  }

  textLines.push(_formatArea(area, hasPixelSpacing, pixelToLengthFactor));
  otherLines.forEach(x => textLines.push(x));

  return textLines;
}

function _formatArea(area, hasPixelSpacing, pixelToLengthFactor) {
  // This uses Char code 178 for a superscript 2
  const suffix = hasPixelSpacing || pixelToLengthFactor !== undefined
    ? ` mm${String.fromCharCode(178)}`
    : ` px${String.fromCharCode(178)}`;

  return `${t('area', {ns: 'Tools'})}: ${numbersWithCommas(area.toFixed(2))}${suffix}`;
}
