import csTools from 'cornerstone-tools';
import cornerstone from "cornerstone-core";
import { lmin } from '../PipeWallThicknessHist';
import { DEFAULT_LINEWIDTH } from '../LineProfileHist';
import { sanitizeMeasuredValue, calcProfile, distance, getCornerPoints } from "./utils";

const Cursors = csTools.importInternal('tools/cursors');
const BaseAnnotationTool = csTools.importInternal('base/BaseAnnotationTool');
const getNewContext = csTools.import("drawing/getNewContext");
const lineSegDistance = csTools.importInternal('util/lineSegDistance');
const getPixelSpacing = csTools.importInternal('util/getPixelSpacing');
const draw = csTools.import("drawing/draw");
const drawHandles = csTools.import("drawing/drawHandles");
const drawLinkedTextBox = csTools.import("drawing/drawLinkedTextBox");
const getToolState = csTools.getToolState;
const setShadow = csTools.import("drawing/setShadow");
const drawLine = csTools.importInternal("drawing/drawLine");
const drawRect = csTools.importInternal("drawing/drawRect");
const throttle = csTools.importInternal('util/throttle');
const lengthCursor = Cursors.lengthCursor;
const getLogger = csTools.importInternal('util/getLogger');

const logger = getLogger('tools:annotation:LineProfileTool');

const defaultPixelspacing = 1.0;

/**
 * @public
 * @class LineProfileTool
 * @memberof Tools.Annotation
 * @classdesc Tool for measuring distances.
 * @extends Tools.Base.BaseAnnotationTool
 */
export default class LineProfileTool extends BaseAnnotationTool {
    constructor(props = {}) {
        const defaultProps = {
            name: 'LineProfile',
            supportedInteractionTypes: ['Mouse', 'Touch'],
            svgCursor: lengthCursor,
            configuration: {
                drawHandles: true,
                drawHandlesOnHover: props.configuration.drawHandlesOnHover ?? false,
                hideHandlesIfMoving: false,
                renderDashed: false,
                digits: 2,
                shadow: true,
            },
        };

        super(props, defaultProps);
        this.throttledUpdateCachedStats = throttle(this.updateCachedStats, 200);
        this.throttledCalcProfile = throttle(calcProfile, 200);
        this.selectedToolstateIndex = 0;
        this.pixelToLengthFactor = undefined;

        this.toolSelectedColor = 'hsla(64, 87%, 79%, 1)';

        const elem = document.getElementById('toolsPanel');
        if (elem) {
            const selectedColor = getComputedStyle(elem)?.getPropertyValue('--toolSelectedColor');
            if (selectedColor) {
                this.toolSelectedColor = selectedColor;
            }
        }
    }

    onMeasureCompleted = (ev) => {
        const toolData = getToolState(ev?.detail?.element, this.name);
        const enabledElement = cornerstone.getEnabledElement(ev?.detail?.element);
        if (toolData?.data) {
            if (toolData.data[toolData.data.length - 1]?.isNew) {
                toolData.data[toolData.data.length - 1].xlines = [lmin, toolData.data[toolData.data.length - 1].length];
                toolData.data[toolData.data.length - 1].isNew = false;
                this.updateCachedStats(enabledElement?.image, ev?.detail?.element, toolData.data[toolData.data.length - 1]);
            }
        }
    };

    createNewMeasurement(eventData) {
        const goodEventData =
            eventData && eventData.currentPoints && eventData.currentPoints.image;

        if (!goodEventData) {
            logger.error(
                `required eventData not supplied to tool ${this.name}'s createNewMeasurement`
            );

            return;
        }

        const toolData = getToolState(eventData?.element, this.name);
        let currentToolstateIndex = 0;
        if (toolData && toolData.data?.length > 0) {
            currentToolstateIndex = toolData.data?.length;
        }

        const { x, y } = eventData.currentPoints.image;

        eventData?.element.addEventListener(
            csTools.EVENTS.MOUSE_UP,
            this.onMeasureCompleted, { once: true }
        );

        return {
            visible: true,
            active: true,
            color: undefined,
            invalidated: true,
            handles: {
                start: {
                    x,
                    y,
                    highlight: true,
                    active: false,
                },
                end: {
                    x,
                    y,
                    highlight: true,
                    active: true,
                },
                textBox: {
                    active: false,
                    hasMoved: false,
                    movesIndependently: false,
                    drawnIndependently: true,
                    allowedOutsideImage: true,
                    hasBoundingBox: true,
                },
            },
            toolName: "LineProfile",
            lineWidth: DEFAULT_LINEWIDTH,
            xMarker: this.options.xMarker,
            matrixIndex: this.initialConfiguration.matrixIndex,
            xlines: [0, lmin],
            currentToolstateIndex: currentToolstateIndex,
            scale: this.initialConfiguration.scale,
            isNew: true,
        };
    }

    /**
     *
     *
     * @param {*} element
     * @param {*} data
     * @param {*} coords
     * @returns {Boolean}
     */
    pointNearTool(element, data, coords) {
        const hasStartAndEndHandles =
            data && data.handles && data.handles.start && data.handles.end;
        const validParameters = hasStartAndEndHandles;

        if (!validParameters) {
            logger.warn(
                `invalid parameters supplied to tool ${this.name}'s pointNearTool`
            );

            return false;
        }

        if (data.visible === false) {
            return false;
        }

        const isNear = lineSegDistance(element, data.handles.start, data.handles.end, coords) < 25;

        if (isNear) {
            cornerstone.triggerEvent(element, "currentToolstateIndex", { data: data, forceRedisplay: false });
            this.selectedToolstateIndex = data?.currentToolstateIndex;
        }

        return isNear;
    }

    updateCachedStats(image, element, data) {
        if (data?.toolName !== this.name) return;
        const toolData = getToolState(element, this.name);
        if (!toolData) {
            return;
        }

        const { rowPixelSpacing, colPixelSpacing } = getPixelSpacing(image);

        const dxRaw = (data.handles.end.x - data.handles.start.x);
        const dyRaw = (data.handles.end.y - data.handles.start.y);

        let dx, dy;
        if (this.pixelToLengthFactor !== undefined) {
            dx = (data.handles.end.x - data.handles.start.x) * (this.pixelToLengthFactor.col);
            dy = (data.handles.end.y - data.handles.start.y) * (this.pixelToLengthFactor.row);
        } else {
            // Set rowPixelSpacing and columnPixelSpacing to 0.1 if they are undefined (or zero)
            dx = (data.handles.end.x - data.handles.start.x) * (colPixelSpacing || defaultPixelspacing);
            dy = (data.handles.end.y - data.handles.start.y) * (rowPixelSpacing || defaultPixelspacing);
        }

        // Calculate the length, and create the text variable with the millimeters or pixels suffix
        const scaledLength = Math.sqrt(dx * dx + dy * dy);

        // Store the length inside the tool for outside access
        data.length = Math.sqrt(dxRaw * dxRaw + dyRaw * dyRaw);
        if (data.length > 1) {
            data.scale = scaledLength / data.length;
        }

        if (image) {
            const currentImage = image.image_raw ? image.image_raw : image;
            calcProfile(currentImage, data, false);
        }

        data.invalidated = false;
    }

    renderToolData(evt) {
        const eventData = evt.detail;
        const {
            handleRadius,
            drawHandlesOnHover,
            hideHandlesIfMoving,
            renderDashed,
            digits,
        } = this.configuration;
        const toolData = getToolState(evt.currentTarget, this.name);

        if (!toolData) {
            return;
        }

        // We have tool data for this element - iterate over each one and draw it
        const context = getNewContext(eventData.canvasContext.canvas);
        const { image, element } = eventData;
        const { rowPixelSpacing, colPixelSpacing } = getPixelSpacing(image);
        const pixelToLengthFactor = this.pixelToLengthFactor;


        const lineWidth = csTools.toolStyle.getToolWidth();
        const lineDash = csTools.getModule('globalConfiguration').configuration.lineDash;

        for (let i = 0; i < toolData.data.length; i++) {
            const data = toolData.data[i];

            if (data.visible === false) {
                continue;
            }

            draw(context, context => {
                // Configurable shadow
                setShadow(context, this.configuration);

                const color = data.active ? csTools.toolColors.getActiveColor(data) : (this.selectedToolstateIndex === i ? this.toolSelectedColor : csTools.toolColors.getToolColor(data));

                const lineOptions = { color };

                if (renderDashed) {
                    lineOptions.lineDash = lineDash;
                }

                // Draw the measurement line
                drawLine(
                    context,
                    element,
                    data.handles.start,
                    data.handles.end,
                    lineOptions
                );


                const ls = data.handles.start, le = data.handles.end;
                const lh = data.lineWidth;
                const dx = (le.x - ls.x);
                const dy = (le.y - ls.y);
                const l = Math.sqrt(dx * dx + dy * dy);

                if (l > lmin) {
                    if (data.lineWidth > 1) {
                        var angle = dx / l;
                        if (angle <= -1.0)
                            angle = -Math.PI;
                        else
                            if (angle > 1.0)
                                angle = Math.PI;
                            else
                                angle = Math.acos(angle);
                        angle *= 180.0 / Math.PI;
                        if (dy > 0)
                            angle = -angle;
                        lineOptions.lineDash = lineDash;
                        const rect = getCornerPoints(ls, le, lh);
                        drawRect(context, element, rect[3], rect[1], lineOptions, 'pixel', angle);
                    }

                    const lineheight = data.lineWidth;
                    const lineOptions1 = { color: 'rgba(252, 84, 84, 1)' };
                    lineOptions1.lineDash = lineDash;

                    const lineOptions2 = { color: 'rgba(252, 84, 84, 1)' };

                    if (data?.xlines && data?.xlines.length > 0) {
                        if (data?.xlines[0] > data?.length) {
                            data.xlines = [data?.length, data.xlines[1]];
                        }
                    }
                    if (data?.xlines && data?.xlines.length > 1) {
                        if (data?.xlines[1] > data?.length) {
                            data.xlines = [data.xlines[0], data?.length];
                        }
                    }


                    const dxh = (data?.handles.end.x - data?.handles.start.x);
                    const dyh = (data?.handles.end.y - data?.handles.start.y);
                    const lha = Math.sqrt(dxh * dxh + dyh * dyh);

                    if (data?.isNew && data?.xlines && data?.xlines?.length > 1) {
                        data.xlines[0] = lmin;
                        data.xlines[1] = lha;
                    }

                    drawMarkerLine(data.xMarker, ls, l, dx, dy, context, element, lineheight, lineOptions2);
                    for (let i = 0; i < data?.xlines?.length; i++) {
                        drawMarkerLine(data?.xlines[i], ls, l, dx, dy, context, element, lineheight, lineOptions1);
                    }
                }

                // Draw the handles
                const handleOptions = {
                    color,
                    handleRadius,
                    drawHandlesIfActive: drawHandlesOnHover,
                    hideHandlesIfMoving,
                };

                if (this.configuration.drawHandles) {
                    drawHandles(context, eventData, data.handles, handleOptions);
                }

                if (!data.handles.textBox.hasMoved) {
                    const coords = {
                        x: Math.max(data.handles.start.x, data.handles.end.x),
                    };

                    // Depending on which handle has the largest x-value,
                    // Set the y-value for the text box
                    if (coords.x === data.handles.start.x) {
                        coords.y = data.handles.start.y;
                    } else {
                        coords.y = data.handles.end.y;
                    }

                    data.handles.textBox.x = coords.x;
                    data.handles.textBox.y = coords.y;
                }

                // Move the textbox slightly to the right and upwards
                // So that it sits beside the length tool handle
                const xOffset = 10;

                // Update textbox stats
                if (data.invalidated === true) {
                    if (data.length) {
                        this.throttledUpdateCachedStats(image, element, data);
                    } else {
                        this.updateCachedStats(image, element, data);
                    }
                }

                const text = textBoxText(data);

                drawLinkedTextBox(
                    context,
                    element,
                    data.handles.textBox,
                    text,
                    data.handles,
                    textBoxAnchorPoints,
                    color,
                    lineWidth,
                    xOffset,
                    true
                );
            });
        }

        // - SideEffect: Updates annotation 'suffix'
        function textBoxText(annotation) {
            let measuredValue = undefined;
            let measuredValuePixel = undefined;
            if (annotation?.xlines && annotation?.xlines.length >= 2) {
                measuredValue = Math.abs(annotation?.xlines[1] - annotation?.xlines[0]) * annotation.scale;
                measuredValue = sanitizeMeasuredValue(measuredValue);
                measuredValuePixel = Math.abs(annotation?.xlines[1] - annotation?.xlines[0]);
                measuredValuePixel = sanitizeMeasuredValue(measuredValuePixel);
            }

            // Measured value is not defined, return empty string
            if (!measuredValue) {
                return '';
            }

            let suffix = 'pixels';

            if ((rowPixelSpacing !== undefined && colPixelSpacing !== undefined) || pixelToLengthFactor !== undefined) {
                suffix = 'mm';
            }

            annotation.unit = suffix;

            if (suffix === 'mm') {
                //return `${measuredValue.toFixed(digits)} ${suffix} / ${measuredValuePixel.toFixed(0)} pixels`;
                return `${measuredValue.toFixed(digits)} ${suffix}`;
            } else {
                return `${measuredValue.toFixed(digits)} ${suffix}`;
            }

        }

        function textBoxAnchorPoints(handles) {
            const midpoint = {
                x: (handles.start.x + handles.end.x) / 2,
                y: (handles.start.y + handles.end.y) / 2,
            };

            return [handles.start, midpoint, handles.end];
        }

        function drawMarkerLine(position, ls, l, dx, dy, context, element, lineheight, lineOptions) {
            if (position > l) position = l;
            const x1 = ls.x + dx * position / l;
            const y1 = ls.y + dy * position / l;
            const w = distance(ls, { x: x1, y: y1 });
            const linevec = { x: (x1 - ls.x) / w, y: (y1 - ls.y) / w };
            const ortho = { x: -linevec.y, y: linevec.x }
            const pa = { x: x1 + ((lineheight / 2.0) * ortho.x), y: y1 + ((lineheight / 2.0) * ortho.y) };
            const pd = { x: x1 - ((lineheight / 2.0) * ortho.x), y: y1 - ((lineheight / 2.0) * ortho.y) };
            drawLine(
                context,
                element,
                pa,
                pd,
                lineOptions
            );
        }

    }

    restore(element, data) {
        var xs = data.handles.start.x;
        var ys = data.handles.start.y;

        let measurement = this.createNewMeasurement(
            {
                currentPoints: { image: { x: xs, y: ys } },
                viewport: { rotation: undefined },
                element: element,
            });

        measurement.handles.end.x = data.handles.end.x;
        measurement.handles.end.y = data.handles.end.y;
        measurement.lineWidth = data?.lineWidth;
        measurement.xlines = data?.xlines;
        measurement.handles.textBox = JSON.parse(JSON.stringify(data.handles.textBox));


        return measurement;
    }

    store(toolData) {
        toolData.storeToolData = {
            "handles": toolData?.handles,
            "lineWidth": toolData?.lineWidth,
            "xlines": toolData?.xlines ? toolData?.xlines : [0.01, toolData?.length],
        }
    }

    measurementRemoved(element, removedata) {
        const toolData = getToolState(element, this.name);

        if (!toolData) {
            return;
        }

        for (let i = 0; i < toolData.data.length; i++) {
            const data = toolData.data[i];
            data.currentToolstateIndex = i;
        }

        if (toolData?.data?.length > 0) {
            cornerstone.triggerEvent(element, "currentToolstateIndex", { data: toolData.data[0], forceRedisplay: true });
        } else {
            cornerstone.triggerEvent(element, "currentToolstateIndex", { data: { toolName: this.name, currentToolstateIndex: -1 }, forceRedisplay: true });
        }

    }

    setLenghtCalibration(element, pixelToLengthFactor) {
        const toolData = getToolState(element?.element, this.name);
        this.pixelToLengthFactor = global.structuredClone(pixelToLengthFactor);

        if (!toolData) {
            return;
        }

        for (let i = 0; i < toolData.data.length; i++) {
            this.updateCachedStats(element?.image, element?.element, toolData.data[i]);
        }
    }
}
