import cornerstone, { EnabledElement } from "cornerstone-core";
import * as cornerstoneTools from "cornerstone-tools";
import { throttle } from 'throttle-debounce';
import React, { useCallback, useEffect, useRef, useState } from "react";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, ResponsiveContainer, ReferenceLine, Tooltip } from "recharts";
import { Constants } from "../../Constants";
import { AppDispatch, RootState } from "../../store";
import { getPipeWallThicknessHistActive, getPWTContextViewExtended, getPWT_geometry_visible, selectToolInsetVisible, getMaxLineWidth } from "./ImageDisplaySlice";
import "./PipeWallThicknessHist.scss";
import HoverRangeslider from "../Utils/HoverRangeslider";
import { setGetToolStateItems, updateAnnnotationOnInputChange } from "./Tools/utils";
import { useORTranslation } from "../Localization/ORLocalization";
import { getImagePathForCurrentTheme } from "../OrderList/OrdersSlice";

type PipeWallThicknessHistProps = {
  orderId: string;
  matrixIndex: number;
};

export const lmin: number = 0.01;
const xlineCaptureRegion: number = 2;

const toolname: string = 'PipeWallThickness';

const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
const useAppDispatch = () => useDispatch<AppDispatch>();

const PipeWallThicknessHist = (props: PipeWallThicknessHistProps) => {
  const dispatch = useAppDispatch();

  const { t } = useORTranslation(['Tools', 'common']);

  const [chartDragActive, setChartDragActive] = useState<number>(-1);
  const [autoCalc, setAutoCalc] = useState<boolean>(true);
  const [isXlinesHifhlight, setIsXlinesHifhlight] = useState<number>(-1);
  const [tooltipActive, setTooltipActive] = useState<boolean>(false);
  const [useReferenceObject, setUseReferenceObject] = useState<boolean>(true);
  const [currentHist, setCurrentHist] = useState<number>(1);
  const [spd, setSpd] = useState<string>('');
  const [sdd, setSdd] = useState<string>('');
  const [radius, setRadius] = useState<string>('');
  const [thickness, setThickness] = useState<number>(0);

  const [yMin, setYMin] = useState<number>(0);
  const [yMax, setYMax] = useState<number>(10000);
  const [yMin1, setYMin1] = useState<number>(0);
  const [yMax1, setYMax1] = useState<number>(10000);
  const [yMin2, setYMin2] = useState<number>(0);
  const [yMax2, setYMax2] = useState<number>(10000);
  const [xline, setXline] = useState<number>(10);
  const [xlines, setXlines] = useState<number[]>([]);
  const [histData, setHistData] = useState<{ x: number; y: number }[]>([]);
  const [histData1, setHistData1] = useState<{ x: number; y: number }[]>([]);
  const [windowMax, setwindowMax] = useState<number>(4096);
  const [lineWidth, setLineWidth] = useState<number>(22);
  const [windowSize, setwindowSize] = useState<{ width: number, height: number }>({ width: 440, height: 440 });
  const [selectedToolstateIndex, setSelectedToolstateIndex] = useState<number>(0);

  const spdRef = useRef<string>();
  spdRef.current = spd;
  const sddRef = useRef<string>();
  sddRef.current = sdd;
  const radiusRef = useRef<string>();
  radiusRef.current = radius;
  const lineWidthRef = useRef<number>();
  lineWidthRef.current = lineWidth;
  const selectedToolstateIndexRef = useRef<number>();
  selectedToolstateIndexRef.current = selectedToolstateIndex;

  const xlinesRef = useRef<number[]>();
  xlinesRef.current = xlines;

  const useReferenceObjectRef = useRef<boolean>();
  useReferenceObjectRef.current = useReferenceObject;
  const chartDragActiveRef = useRef<number>();
  chartDragActiveRef.current = chartDragActive;

  const cornerstoneElements: EnabledElement[] = cornerstone.getEnabledElements();
  const isPipeWallThicknessHistActive: boolean | undefined = useAppSelector((state) => getPipeWallThicknessHistActive(state, props.matrixIndex ?? 0));
  const isToolInsetVisible: boolean | undefined = useAppSelector((state) => selectToolInsetVisible(state));
  const isPWTContextViewExtended: boolean | undefined = useAppSelector((state) => getPWTContextViewExtended(state, props.matrixIndex ?? 0));
  const PWT_geometry_visible: boolean | undefined = useAppSelector((state) => getPWT_geometry_visible(state, props.matrixIndex ?? 0));
  const imagePathForCurrentTheme: string = useAppSelector((state) => getImagePathForCurrentTheme(state));
  const maxLineWidth: number = useAppSelector((state) => getMaxLineWidth(state));

  const displayElement: EnabledElement | undefined = cornerstoneElements?.find(
    (element: EnabledElement) => element.element.id === `${Constants.IMAGE_DISPLAY_GENERIC_ELEMENT_NAME}_${props.matrixIndex}`
  );

  const getWmax = (thickness: number, r: number) => {
    let ret = ''
    const lmax = 2.0 * Math.sqrt((thickness / 10.0) * (r - thickness / 10.0));
    if (!isNaN(lmax)) {
      ret = lmax.toFixed(2).toString();
    }
    return ret;
  }

  const autoCalcClick = (evt: React.MouseEvent<Element>) => {
    const ret = setGetToolStateItems(selectedToolstateIndexRef.current ?? 0, [{ name: "autoCalc", value: true }, { name: "useReferenceObject", value: true }], ["xlines", "wallThickness", "scale"], props.matrixIndex, toolname);
    setUseReferenceObject(true);
    if (ret && ret.length > 1) {
      setXlines(ret[0]?.map((value: number) => { return value * ret[2] }));
      setThickness(ret[1]);
    }
    setAutoCalc(true);
    throttleUpdateAnnnotationOnInputChange(selectedToolstateIndexRef.current, props.matrixIndex, toolname);
  };

  const spdSddRecalc = (evt: React.MouseEvent<Element>) => {
    const ret = setGetToolStateItems(selectedToolstateIndexRef.current ?? 0, [{ name: "useReferenceObject", value: false }], ["xlines", "wallThickness", "scale"], props.matrixIndex, toolname);
    setUseReferenceObject(false);
    if (ret && ret.length > 1) {
      setXlines(ret[0]?.map((value: number) => { return value * ret[2] }));
      setThickness(ret[1]);
    }
    //setGetToolStateItems(selectedToolstateIndexRef.current ?? 0, [{ name: "useReferenceObject", value: true }], [], props.matrixIndex, toolname);
    setAutoCalc(false);
  }

  const onReducedClicked = (evt: React.MouseEvent<HTMLButtonElement>) => {
    dispatch({ type: "ImageDisplay/setPWTContextViewExtended", payload: { matrixIndex: props.matrixIndex, isPWTContextViewExtended: !isPWTContextViewExtended } });
  };

  const onPWT_geometry_visible = (evt: React.MouseEvent<HTMLButtonElement>) => {
    dispatch({ type: "ImageDisplay/setPWT_geometry_visible", payload: { matrixIndex: props.matrixIndex, PWT_geometry_visible: !PWT_geometry_visible } });
  };

  const handleMouseDown = (evt: any) => {
    if (evt?.activePayload && evt?.activePayload.length > 0 && evt?.activePayload[0].payload) {
      let isChartDragActive: boolean = false;
      for (let i = 0; i < xlines?.length; i++) {
        if (Math.abs(evt?.activePayload[0].payload.x - xlines[i]) < xlineCaptureRegion) {
          setChartDragActive(i);
          isChartDragActive = true;
          setXline(xlines[i]);
          //setGetToolStateItems([{ name: "xMarker", value: xlines[i] }], [], props.matrixIndex, toolname);
          break;
        }
      }
      if (!isChartDragActive) {
        setTooltipActive(true);
      }
    }
  };

  const handleMouseUp = (evt: React.MouseEvent<Element>) => {
    if ((chartDragActiveRef.current ?? -1) >= 0) {
      updateAnnnotationOnInputChange(selectedToolstateIndexRef.current, props.matrixIndex, toolname);
    }
    setChartDragActive(-1);
    setTooltipActive(false);
  };

  const onHistClick = (evt: any) => {
    if (xlines?.length < 2) {
      const newXlines: number[] = structuredClone(xlines);
      newXlines.push(xline);
      setXlines(newXlines);
      const retScale = setGetToolStateItems(selectedToolstateIndexRef.current ?? 0, [], ["scale"], props.matrixIndex, toolname);
      setGetToolStateItems(selectedToolstateIndexRef.current ?? 0, [{ name: "xlines", value: newXlines?.map((value: number) => { return value / (retScale[0] ?? 1) }) }], [], props.matrixIndex, toolname);
    }
  };

  const handleDoubleClick = (evt: any) => {
    setXlines([]);
    setAutoCalc(false);
    setGetToolStateItems(selectedToolstateIndexRef.current ?? 0, [{ name: "autoCalc", value: false }, { name: "xlines", value: [] }], [], props.matrixIndex, toolname);
    setThickness(0);
  };

  const handleSpdInput = (evt: any) => {
    evt.stopPropagation();
    evt.preventDefault();
    setSpd(evt?.target?.value);
    const ret = setGetToolStateItems(selectedToolstateIndexRef.current ?? 0, [{ name: "spd", value: evt?.target?.value }], ["wallThickness"], props.matrixIndex, toolname);
    if (ret && ret.length > 0) {
      setThickness(ret[0]);
    }
    throttleUpdateAnnnotationOnInputChange(selectedToolstateIndexRef.current, props.matrixIndex, toolname);
  };

  const handleSddInput = (evt: any) => {
    evt.stopPropagation();
    evt.preventDefault();
    setSdd(evt?.target?.value);
    const ret = setGetToolStateItems(selectedToolstateIndexRef.current ?? 0, [{ name: "sdd", value: evt?.target?.value }], ["wallThickness"], props.matrixIndex, toolname);
    if (ret && ret.length > 0) {
      setThickness(ret[0]);
    }
    throttleUpdateAnnnotationOnInputChange(selectedToolstateIndexRef.current, props.matrixIndex, toolname);
  };

  const handleRInput = (evt: any) => {
    evt.stopPropagation();
    evt.preventDefault();
    setRadius(evt?.target?.value);
    const ret = setGetToolStateItems(selectedToolstateIndexRef.current ?? 0, [{ name: "radius", value: evt?.target?.value }], ["wallThickness"], props.matrixIndex, toolname);
    if (ret && ret.length > 0) {
      setThickness(ret[0]);
    }
    throttleUpdateAnnnotationOnInputChange(selectedToolstateIndexRef.current, props.matrixIndex, toolname);
  };

  const xMarkerChange = (chartProps: any) => {
    if (chartProps?.activePayload && chartProps?.activePayload.length > 0 && chartProps?.activePayload[0].payload) {
      setIsXlinesHifhlight(-1);
      for (let i = 0; i < xlines?.length; i++) {
        if (Math.abs(chartProps?.activePayload[0].payload.x - xlines[i]) < xlineCaptureRegion) {
          setIsXlinesHifhlight(i);
          break;
        }
      }
    }
    const retScale = setGetToolStateItems(selectedToolstateIndexRef.current ?? 0, [], ["scale"], props.matrixIndex, toolname);
    if (chartProps?.isTooltipActive) {
      setCurrentHist(1);
      if (chartProps?.activePayload && chartProps?.activePayload.length > 0 && chartProps?.activePayload[0].payload) {
        setXline(chartProps?.activePayload[0].payload.x);
        const ret = setGetToolStateItems(selectedToolstateIndexRef.current ?? 0, [{ name: "xMarker", value: chartProps?.activePayload[0].payload.x / (retScale[0] ?? 1) }], ["wallThickness"], props.matrixIndex, toolname);
        if (ret && ret.length > 0) {
          setThickness(ret[0]);
        }
      }
    }
    if ((chartDragActiveRef?.current ?? -1) > -1 && chartProps?.activePayload && chartProps?.activePayload.length > 0 && chartProps?.activePayload[0].payload) {
      const newLines = structuredClone(xlines);
      newLines[(chartDragActiveRef?.current ?? 0)] = Math.max(lmin, chartProps?.activePayload[0].payload.x);
      setXlines(newLines);
      if (xlines.length > 1) {
        setAutoCalc(false);

        const ret = setGetToolStateItems(selectedToolstateIndexRef.current ?? 0,
          [{ name: "autoCalc", value: false }, { name: "xlines", value: newLines?.map((value: number) => { return value / (retScale[0] ?? 1) }) }], ["wallThickness"], props.matrixIndex, toolname);
        if (ret && ret.length > 0) {
          setThickness(ret[0]);
        }
        //throttleUpdateAnnnotationOnInputChange(selectedToolstateIndexRef.current, props.matrixIndex, toolname);
      }
    }
  };

  const scaleYMax = (event: any) => {
    //event.preventDefault();
    event.stopPropagation();
    // Apply scale transform
    if (currentHist > 0) {
      switch (currentHist) {
        case 1:
          setYMax(Math.floor(yMax * (1 + event.deltaY * -0.001)) + 1);
          break;
        case 2:
          setYMin1(Math.floor(yMin1 * (1 + event.deltaY * -0.001)) + 1);
          setYMax1(Math.floor(yMax1 * (1 + event.deltaY * -0.001)) - 1);
          break;
        case 3:
          setYMin2(Math.floor(yMin2 * (1 + event.deltaY * -0.001)) + 1);
          setYMax2(Math.floor(yMax2 * (1 + event.deltaY * -0.001)) - 1);
          break;
      }
    }
  }

  const throttleUpdateAnnnotationOnInputChange = useCallback(throttle(200, (index: number | undefined, matrixIndex: number, toolname: string) => {
    updateAnnnotationOnInputChange(index, matrixIndex, toolname);
  }), [spd, sdd, radius, thickness, useReferenceObject, lineWidth]);


  const throttleOnValueChange = useCallback(throttle(200, (measurementData) => {
    if (measurementData?.linedata) {
      setwindowMax(Math.floor(measurementData?.length + 1));
      setYMax(Math.floor((measurementData?.max) + 1));
      if (measurementData?.xMarker > measurementData?.length) {
        setXline(measurementData?.length * measurementData?.scale);
      }
      setHistData(measurementData?.linedata?.map((value: number, i: number) => { return { x: (i + 0.5) / measurementData?.linedata.length * measurementData?.length * measurementData?.scale, y: value } }));
      setHistData1(measurementData?.linedatad);
      setYMax1(Math.floor(measurementData?.max1 + 1));
      setYMin1(Math.floor(measurementData?.min1 - 1));
      setXlines(measurementData?.xlines?.map((value: number) => { return value * measurementData?.scale }));
      setThickness(measurementData?.wallThickness);
    }
  }), [isPipeWallThicknessHistActive]);

  const throttleOnLineWidthChange = useCallback(throttle(200, (value) => {
    setLineWidth(value);
    const cornerstoneElements: EnabledElement[] = cornerstone.getEnabledElements();
    const displayElement: EnabledElement | undefined = cornerstoneElements?.find(
      (element: EnabledElement) => element.element.id === `${Constants.IMAGE_DISPLAY_GENERIC_ELEMENT_NAME}_${props.matrixIndex}`
    );
    if (displayElement?.element) {
      const ret = setGetToolStateItems(selectedToolstateIndexRef.current ?? 0, [{ name: "lineWidth", value }],
        ["linedata", "linedatad", "max", "max1", "min1", "xlines", "wallThickness", "scale", "length"], props.matrixIndex, toolname);
      setXlines(ret[5]?.map((value: number) => { return value * ret[7] }));

      setYMax(Math.floor((ret[2]) + 1));
      setHistData(ret[0]?.map((value: number, i: number) => { return { x: ((i + 0.5) / ret[0].length) * ret[8] * ret[7], y: value } }));
      if (ret && ret.length > 6) {
        setHistData1(ret[1]);
        setYMax1(Math.floor(ret[3] + 1));
        setYMin1(Math.floor(ret[4] - 1));
        setThickness(ret[6]);
      }
    }
    throttleUpdateAnnnotationOnInputChange(selectedToolstateIndexRef.current, props.matrixIndex, toolname);
  }), [isPipeWallThicknessHistActive]);


  useEffect(() => {
    const onMeasureModified = (ev: any) => {
      if (ev?.detail?.toolName === toolname) {
        throttleOnValueChange(ev?.detail?.measurementData);
      }
    };

    const onReferenceObjectCalibration = (ev: any) => {
      const ret = setGetToolStateItems(selectedToolstateIndexRef?.current, [], ["linedata", "linedatad", "max", "max1", "min1", "xlines", "wallThickness", "length", "scale"], props.matrixIndex, toolname);
      throttleOnValueChange({ linedata: ret[0], linedatad: ret[1], max: ret[2], max1: ret[3], min1: ret[4], xlines: ret[5], wallThickness: ret[6], length: ret[7], scale: ret[8] });
    }

    const onMeasureAdded = (ev: any) => {
      if (ev?.detail?.toolName === toolname) {
        setLineWidth(22);
        const cornerstoneElements: EnabledElement[] = cornerstone.getEnabledElements();
        const displayElement: EnabledElement | undefined = cornerstoneElements?.find(
          (element: EnabledElement) => element.element.id === `${Constants.IMAGE_DISPLAY_GENERIC_ELEMENT_NAME}_${props.matrixIndex}`
        );
        const stateManager = cornerstoneTools.getElementToolStateManager(displayElement?.element);
        const toolstate = stateManager.get(displayElement?.element, toolname);
        let currentSelectedToolstateIndex: number = 0;
        if (toolstate?.data) {
          currentSelectedToolstateIndex = toolstate?.data.length - 1;
          setSelectedToolstateIndex(currentSelectedToolstateIndex);
          const tool = cornerstoneTools.getToolForElement(displayElement?.element, toolname);
          if (tool) {
            tool.selectedToolstateIndex = currentSelectedToolstateIndex;
          }
        }

        const ret = setGetToolStateItems(currentSelectedToolstateIndex, [{ name: "spd", value: spdRef.current }, { name: "sdd", value: sddRef.current }, { name: "radius", value: radiusRef.current }, { name: "lineWidth", value: 22 },
        { name: "useReferenceObject", value: useReferenceObjectRef.current },
        { name: "currentToolstateIndex", value: currentSelectedToolstateIndex }], ["wallThickness"], props.matrixIndex, toolname);
        setThickness(ret[0]);
        setAutoCalc(true);
      }
    };

    const onCurrentToolstateIndex = (ev: any) => {
      if (ev?.detail?.data?.toolName === toolname && (ev?.detail?.data?.currentToolstateIndex !== selectedToolstateIndexRef.current || ev?.detail?.forceRedisplay)) {
        if (ev?.detail?.data?.currentToolstateIndex === -1) {
          setSelectedToolstateIndex(0);
          setXlines([]);
          setHistData([]);
          setHistData1([]);
        } else {
          setSelectedToolstateIndex(ev?.detail?.data?.currentToolstateIndex);
          setXlines(structuredClone(ev?.detail?.data?.xlines.map((value: number) => { return value * ev?.detail?.data?.scale })));
          setLineWidth(ev?.detail?.data?.lineWidth);
          setAutoCalc(ev?.detail?.data?.autoCalc);
          setSdd(ev?.detail?.data?.sdd);
          setSpd(ev?.detail?.data?.spd);
          setRadius(ev?.detail?.data?.radius);
        }
      }
    };


    setwindowSize({ width: windowSize.width, height: 440 });

    //setLineWidth(22)

    if (displayElement) {
      if (displayElement.image) {
        displayElement.element?.addEventListener(
          cornerstoneTools.EVENTS.MEASUREMENT_MODIFIED,
          onMeasureModified
        );
        displayElement.element?.addEventListener(
          cornerstoneTools.EVENTS.MEASUREMENT_ADDED,
          onMeasureAdded
        );
        displayElement.element?.addEventListener(
          "currentToolstateIndex",
          onCurrentToolstateIndex
        );
        displayElement.element?.addEventListener(
          "ReferenceObjectCalibration",
          onReferenceObjectCalibration
        );
      }
    }
    return () => {
      if (displayElement) {
        displayElement.element?.removeEventListener(
          cornerstoneTools.EVENTS.MEASUREMENT_MODIFIED,
          onMeasureModified);
        displayElement.element?.removeEventListener(
          cornerstoneTools.EVENTS.MEASUREMENT_ADDED,
          onMeasureAdded
        );
        displayElement.element?.removeEventListener(
          "currentToolstateIndex",
          onCurrentToolstateIndex
        );
        displayElement.element?.removeEventListener(
          "ReferenceObjectCalibration",
          onReferenceObjectCalibration
        );
      }
    };
  }, [displayElement?.image?.imageId, isPipeWallThicknessHistActive]);


  useEffect(() => {
    if (selectedToolstateIndex !== undefined) {
      const ret = setGetToolStateItems(selectedToolstateIndex, [], ["linedata", "linedatad", "max", "max1", "min1", "xlines", "wallThickness", "length", "scale"], props.matrixIndex, toolname);
      throttleOnValueChange({ linedata: ret[0], linedatad: ret[1], max: ret[2], max1: ret[3], min1: ret[4], xlines: ret[5], wallThickness: ret[6], length: ret[7], scale: ret[8] });
    }
  }, [selectedToolstateIndex]);

  useEffect(() => {
    if (displayElement?.image) {
      const ret = setGetToolStateItems(0, [], ["linedata", "linedatad", "max", "max1", "min1", "xlines", "wallThickness", "length", "scale"], props.matrixIndex, toolname);
      if (ret[0]) {
        const tool = cornerstoneTools.getToolForElement(displayElement?.element, toolname);
        if (tool) {
          tool.selectedToolstateIndex = 0;
        }
        setSelectedToolstateIndex(0);
        throttleOnValueChange({ linedata: ret[0], linedatad: ret[1], max: ret[2], max1: ret[3], min1: ret[4], xlines: ret[5], wallThickness: ret[6], length: ret[7], scale: ret[8] });
      } else {
        setHistData([]);
        setHistData1([]);
      }
    }
    return () => {
      setSelectedToolstateIndex(-1);
    };
  }, [displayElement, displayElement?.image?.imageId]);

  useEffect(() => {
    let toolSelectedToolstateIndex: number = 0;
    const cornerstoneElements: EnabledElement[] = cornerstone.getEnabledElements();
    const displayElement: EnabledElement | undefined = cornerstoneElements?.find(
      (element: EnabledElement) => element.element.id === `${Constants.IMAGE_DISPLAY_GENERIC_ELEMENT_NAME}_${props.matrixIndex}`
    );
    if (displayElement) {
      const tool = cornerstoneTools.getToolForElement(displayElement.element, toolname);
      if (tool?.selectedToolstateIndex !== undefined) {
        toolSelectedToolstateIndex = tool?.selectedToolstateIndex;
      }
    }
    const ret = setGetToolStateItems(toolSelectedToolstateIndex, [], ["linedata", "linedatad", "max", "max1", "min1", "xlines", "wallThickness",
      "length", "spd", "sdd", "radius", "lineWidth", "autoCalc", "scale"], props.matrixIndex, toolname);
    throttleOnValueChange({
      linedata: ret[0], linedatad: ret[1], max: ret[2], max1: ret[3],
      min1: ret[4], xlines: ret[5], wallThickness: ret[6], length: ret[7], scale: ret[13]
    });
    if (ret[5]) {
      setXlines(ret[5]?.map((value: number) => { return value * ret[13] }));
    }
    if (ret[8]) {
      setSpd(ret[8]);
    }
    if (ret[9]) {
      setSdd(ret[9]);
    }
    if (ret[10]) {
      setRadius(ret[10]);
    }
    if (ret[11]) {
      setLineWidth(ret[11]);
    }
    if (ret[12]) {
      setAutoCalc(ret[12]);
    }
  }, [isToolInsetVisible, props.matrixIndex]);


  return (
    <div className="PipeWallThicknessHist">
      <div className={isPWTContextViewExtended ? "ToolContextWrapper" : "ToolContextWrapper-reduced"}>
        <div className="toolContextHeader">
          {t('PipeWallThickness')}
        </div>
        <div className="chartWrapper" onContextMenu={(e) => e.preventDefault()} onWheel={(e) => scaleYMax(e)} onDoubleClick={(evt: any) => handleDoubleClick(evt)}>
          <ResponsiveContainer width="100%" height="50%" minWidth="40" minHeight="3">
            <AreaChart
              width={500}
              height={450}
              data={histData}
              margin={{
                top: 5,
                right: 18,
                left: 41,
                bottom: 0,
              }}
              onClick={onHistClick}
              onMouseMove={(props: any) => xMarkerChange(props)}
              onMouseDown={(evt: any) => handleMouseDown(evt)}
              onMouseUp={(evt: any) => handleMouseUp(evt)}
              onMouseEnter={(evt: any) => setCurrentHist(1)}
            >
              <XAxis dataKey="x" type="number" domain={[0, 'auto']} style={{ fontSize: "0.8em" }} height={31} />
              <YAxis width={5} type="number" ticks={[yMin, 0, yMax]} domain={[yMin, yMax]} style={{ fontSize: "0.8em" }} allowDataOverflow={true} />
              <CartesianGrid />
              {/* <ReferenceLine className="xline_active" x={xline} /> */}
              <Area className="chartArea" type="monotone" dataKey="y" isAnimationActive={false} />
              {xlines && xlines.length < 2 ?
                <>
                  <Tooltip cursor={{ stroke: 'hsla(0, 97%, 66%, 1.0)', strokeWidth: 1 }}
                    /* formatter={(value: number, name: string, props: any) => { throttleOnTooltipChanged(props); return value?.toFixed(2) }} */
                    // @ts-ignore
                    formatter={(value: number, name: string, props: any) => { return value?.toFixed(2) }}
                    labelFormatter={(value: any, props: any) => { return value?.toFixed(2) }} />
                </>
                : null}
              {xlines?.map((val, i) => <ReferenceLine className={isXlinesHifhlight === i ? "xline_active" : "xline"} x={val} strokeWidth={chartDragActive === i ? 2 : 1} key={i} />)}
            </AreaChart>
          </ResponsiveContainer>

          <ResponsiveContainer width="100%" height="50%" minWidth="40" minHeight="3">
            <AreaChart
              width={300}
              height={240}
              data={histData1}
              margin={{
                top: 5,
                right: 18,
                left: 41,
                bottom: 0,
              }}
              onMouseMove={(props: any) => xMarkerChange(props)}
              onMouseDown={(evt: any) => handleMouseDown(evt)}
              onMouseUp={(evt: any) => handleMouseUp(evt)}
              onMouseEnter={(evt: any) => setCurrentHist(2)}
            >
              <XAxis dataKey="x" type="number" domain={[0, 'auto']} style={{ fontSize: "0.8em" }} height={31} />
              <YAxis width={5} type="number" ticks={[yMin1, 0, yMax1]} domain={[yMin1, yMax1]} style={{ fontSize: "0.8em" }} allowDataOverflow={true} />
              <CartesianGrid />
              {/*  {xlines && xlines?.length < 2 ?
                <>
                  <ReferenceLine className="xline_active" x={xline} />
                </>
                : null} */}
              <Area className="chartArea" type="monotone" dataKey="y" isAnimationActive={false} />
              {xlines?.map((val, i) => <ReferenceLine className={isXlinesHifhlight === i ? "xline_active" : "xline"} x={val} strokeWidth={chartDragActive === i ? 2 : 1} key={i} />)}
            </AreaChart>
          </ResponsiveContainer>
        </div>


        <div className={isPWTContextViewExtended ? "input_wrapper-all-visible" : "input_wrapper-reduced"}>

          <div className="thicknessResult-autoCalc">
            <div className="thicknessResult">
              <span className="thicknessText" title={t('measuredThickness') as string}>{`${t('thickness') as string}:`}</span>
              <label className="thicknessLabel">{isNaN(thickness) ? '--' : thickness?.toFixed(2)}</label>
              <span className="thicknessUnit">{'mm'}</span>
              {useReferenceObject ? null :
                <img
                  src={`${imagePathForCurrentTheme}projection.svg`}
                  onError={(event: any) => { event.target.src = "/images/projection.svg"; event.onerror = null }}
                  alt="Button autocalc" title='projection geometry used for calculation' />
              }
            </div>
            <button className={autoCalc ? "toolContextButton autoCalc_visible" : "toolContextButton autoCalc_visible"}
              onClick={autoCalcClick} title='reset to automatic thickness measurement'>
              <img className="autoCalcImg"
                src={`${imagePathForCurrentTheme}reset-svgrepo2-com.svg`}
                onError={(event: any) => { event.target.src = "/images/reset-svgrepo2-com.svg"; event.onerror = null }}
                alt="Button autocalc" />
              {'auto calc'}
            </button>
          </div>

          <div className="hruleWithButton">
            <hr className="hrule" />
            <button className="reduced_button" onClick={(evt) => onReducedClicked(evt)}>
              <img
                src={isPWTContextViewExtended ? "/images/Pfeil_nach_oben2.svg" : "/images/Pfeil_nach_unten2.svg"}
                alt={"Pfeil nach oben.svg"}
                className="reduced_button_img"
              />
            </button>
            <hr className="hrule2" />
          </div>

          <div className="lineWidth">
            <span className="lineWidthText">{`${t('lineWidth')}:`}</span>
            <span className="HoverRangeInput">
              <HoverRangeslider onChange={throttleOnLineWidthChange} startValue={lineWidth} min={1} max={maxLineWidth} units={'px'} />
            </span>
          </div>

          <div className="distance">
            <span className="distanceText" title={t('distanceTitle') as string}>{`${t('distance') as string}:`}</span>
            <span className="distanceLabel">{xlines && xlines.length > 1 ? Math.abs(xlines[1] - xlines[0]).toFixed(2) : null}</span>
            <span className="distanceUnit">{xlines && xlines.length > 1 ? "mm" : null}</span>
          </div>

          <div className="lmax-R-Wrapper">
            <span className="inputRText" title={t('outerPipeRadiusTitle') as string}>{`${t('outerPipeRadius') as string}:`}</span>
            <input className="inputR" type="number" value={radius !== undefined ? radius : 0} step='0.5' onChange={(evt) => handleRInput(evt)} />
            <span className="inputUnit">{'cm'}</span>
            <div className="wmax">
              <span className="wmaxText" title={t('lmaxTitle') as string}>{'Lmax:'}</span>
              <label className="wmaxLabel">{getWmax(Number(thickness), Number(radius))}</label>
              <span className="wmaxUnit">{'cm'}</span>
            </div>
          </div>

          <hr className="hrule3"></hr>

          <div className="sdd-spd-input_wrapper">
            <div className="sdd-spd-input">
              <span>
                <span className="inputSpdText" title={t('spdTitle') as string}>{'SPD:'}</span>
                <input className="inputSpd" type="number" value={spd} step='0.5' onChange={(evt) => handleSpdInput(evt)} />
                <span className="inputUnit">{'cm'}</span>
              </span>
              <span>
                <span className="inputSddText" title={t('sddTitle') as string}>{'SDD:'}</span>
                <input className="inputSdd" type="number" value={sdd} step='0.5' onChange={(evt) => handleSddInput(evt)} />
                <span className="inputUnit">{'cm'}</span>
              </span>
              <span>
                <button className="toolContextButton recalc" title={t('recalcTitle') as string} onClick={spdSddRecalc}>
                  <img className="recalcImg"
                    src={`${imagePathForCurrentTheme}projection.svg`}
                    onError={(event: any) => { event.target.src = "/images/projection.svg"; event.onerror = null }}
                    alt="Button autocalc" />
                  {t('recalc')}
                </button>
              </span>
            </div>

            {/* <div className="hruleWithButton_PWT_geometry">
            <hr className="hrule1" />
            <button className="reduced_button" onClick={(evt) => onPWT_geometry_visible(evt)}>
              <img
                src={PWT_geometry_visible ? "/images/Pfeil_nach_oben2.svg" : "/images/Pfeil_nach_unten2.svg"}
                alt={"Pfeil nach oben.svg"}
                className="reduced_button_img"
              />
            </button>
            <hr className="hrule2" />
          </div> */}
            <div className="PWT_geometry">
              <img className="PWT_geometryImg"
                src={`${imagePathForCurrentTheme}PWT_geometry.svg`}
                onError={(event: any) => { event.target.src = "/images/PWT_geometry.svg"; event.onerror = null }}
                alt="PWT_geometry" />
            </div>
          </div>
        </div>
      </div>
    </div >
  );
};

export default PipeWallThicknessHist;
