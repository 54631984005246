
import csTools from 'cornerstone-tools';
import cornerstone from "cornerstone-core";
import { debounce } from 'throttle-debounce';

const external = csTools.external;
const BaseTool = csTools.importInternal('base/BaseTool');
//const zoomUtils = csTools.importInternal('util/zoomUtils');

/**
 * @public
 * @class NewZoomMouseWheelTool
 * @memberof Tools
 *
 * @classdesc Tool for changing magnification with the mouse wheel.
 * @extends Tools.Base.BaseTool
 */
export default class NewZoomMouseWheelTool extends BaseTool {
    constructor(props = {}) {
        const defaultProps = {
            name: 'NewZoomMouseWheel',
            supportedInteractionTypes: ['MouseWheel'],
            configuration: {
                minScale: 0.25,
                maxScale: 20.0,
                invert: false,
            },
        };
        super(props, defaultProps);
		this.db = debounce(1000, () => {
			//console.log("Save VP after Wheel.");
			cornerstone.triggerEvent(this.element, csTools.EVENTS.MEASUREMENT_COMPLETED, {
				toolName: "StoreViewport",
				toolType: "StoreViewport",
				element: this.element,
				measurementData: undefined,
			});
		});
    }

    mouseWheelCallback(evt) {
		
        const { element, viewport, spinY } = evt.detail;
        const { invert, maxScale, minScale } = this.configuration;
        const ticks = invert ? spinY / 4 : -spinY / 4;

        //const zoomUtils = csTools.importInternal('util/zoomUtils');
        const updatedViewport = this.changeViewportScale(viewport, ticks, {
            maxScale,
            minScale,
        });

        const [posX, posY] = [
            evt?.detail?.detail?.offsetX,
            evt?.detail?.detail?.offsetY,
        ];

        let oldPos = undefined;
        if (posX && posY) {
            oldPos = cornerstone.canvasToPixel(element, { x: posX, y: posY });
        }

        external.cornerstone.setViewport(element, updatedViewport);

        const image = cornerstone.getImage(element);
        if (image.cutwidth) {
            if (csTools.isToolActiveForElement(element, "Rotate")) {                
                return;
            }
        }
        
		this.element = element;
		this.db();
		
		
        let newPos = undefined;
        if (posX && posY) {
            newPos = cornerstone.canvasToPixel(element, { x: posX, y: posY });
        }

		if (oldPos && newPos) {
			let transX = oldPos.x - newPos.x;
			let transY = oldPos.y - newPos.y;

			transX = updatedViewport?.hflip ? -transX : transX
			transY = updatedViewport?.vflip ? -transY : transY
			const rotation = updatedViewport.rotation * Math.PI / 180.0;

			const transXP = transX * Math.cos(rotation) - transY * Math.sin(rotation);
			const transYP = transX * Math.sin(rotation) + transY * Math.cos(rotation);
			updatedViewport.translation.x = updatedViewport.translation.x - transXP;
			updatedViewport.translation.y = updatedViewport.translation.y - transYP;
			external.cornerstone.setViewport(element, updatedViewport);
		}

		
    }

    changeViewportScale(viewport, ticks, scaleLimits) {
        const { maxScale, minScale } = scaleLimits;
        const pow = 1.7;
        const oldFactor = Math.log(viewport.scale) / Math.log(pow);
        const factor = oldFactor + ticks;
        const scale = Math.pow(pow, factor);

        if (maxScale && scale > maxScale) {
            viewport.scale = maxScale;
        } else if (minScale && scale < minScale) {
            viewport.scale = minScale;
        } else {
            viewport.scale = scale;
        }

        return viewport;
    }
}
