import { useEffect, useState } from 'react';
import React from 'react';
import * as cornerstoneTools from "cornerstone-tools";
import cornerstone, { EnabledElement } from 'cornerstone-core';
import { JSONPath } from "jsonpath-plus";
import { Constants } from '../../Constants';
import { formatDA, formatNumberPrecision, formatPN, formatTM, getCompression, isValidNumber } from '../Utils/DicomTagFormatter';
import { TypedUseSelectorHook, useSelector } from 'react-redux';
import { RootState } from '../../store';
import { getImageIdAtMatrixIndex, selectMatrixIndex, getDeviationIndexThresholds } from './ImageDisplaySlice';
import { selectedAnnotations, selectProcessingTime, getTargetExposureIndexForSelectedWorkitem } from '../OrderList/OrdersSlice';
import { useORTranslation } from '../Localization/ORLocalization';
import "./ViewportOverlay.scss";

const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

interface ViewportOverlayProps {
    imageLoadStatus: string | undefined;
    matrixIndex: number;
    orderId: string;
}

function formatBytes(bytes: number, decimals = 2) {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export const ViewportOverlay = (props: ViewportOverlayProps) => {

    const { t } = useORTranslation(['ViewportOverlay']);

    const [scale, setScale] = useState<number>(0);
    const [windowWidth, setWindowWidth] = useState<number>(0);
    const [windowCenter, setWindowCenter] = useState<number>(0);
    const [imageId, setImageId] = useState<string>('');
    const [imageIndex, setImageIndex] = useState<number>(0);
    const [stackSize, setStackSize] = useState<number>(0);
    const [sizeInBytes, setSizeInBytes] = useState<number | undefined>(0);
    const [totalTimeInMS, setTotalTimeInMS] = useState<number | undefined>(0);


	const targetExposureIndex: number | undefined = useAppSelector((state) => getTargetExposureIndexForSelectedWorkitem(state));
 	const [exposureIndex, setExposureIndex] = useState<number | undefined>(0);
    const deviationIndexWidgetThresholds: [number, number] = useAppSelector((state) => getDeviationIndexThresholds(state));


    const cornerstoneElements: EnabledElement[] = cornerstone.getEnabledElements();
    const displayElement: EnabledElement | undefined = cornerstoneElements?.find((element: EnabledElement) => element.element.id === `${Constants.IMAGE_DISPLAY_GENERIC_ELEMENT_NAME}_${props.matrixIndex}`);

    const currentImageId: string | undefined = useAppSelector((state) => getImageIdAtMatrixIndex(state, props.matrixIndex));

    const selectedMatrixIndex: number | undefined = useAppSelector((state) => selectMatrixIndex(state));
    const processingTime: number | undefined = useAppSelector((state) => selectProcessingTime(state, props.orderId, currentImageId));
    // @ts-ignore
    const annotations: any = useAppSelector((state) => selectedAnnotations(state, displayElement?.image?.targetId));

    const onImageRendered = (evt: any) => {
        if (evt && evt.detail && evt.detail.viewport) {
            setScale(evt.detail.viewport.scale ?? 0);
            setWindowWidth(evt.detail.viewport?.voi?.windowWidth ?? 0);
            setWindowCenter(evt.detail.viewport?.voi?.windowCenter ?? 0);
        }
    }

	function calcDeviationIndex(ei: number, tei: number | undefined) {
        if (ei && tei)
            return Math.round(1000.0 * Math.log10(ei / tei)) / 100;
        else
            return 0;
    }


    function charFromDI():string {

		let di = 0;
		if (exposureIndex && targetExposureIndex)
			di = calcDeviationIndex(exposureIndex, targetExposureIndex);
		else
			return "";
        let tn = deviationIndexWidgetThresholds[0];
        let tr = deviationIndexWidgetThresholds[1];

        if (di > tr)
            return '\u21C8';
        if (di < -tr)
            return '\u21CA';

        if (di > tn)
            return '\u2191';
        if (di < -tn)
            return '\u2193';

        return '\u2713';
	}

    function classFromDI():string {

		let di = 0;
		if (exposureIndex && targetExposureIndex)
			di = calcDeviationIndex(exposureIndex, targetExposureIndex);
		else
			return "";
        let tn = deviationIndexWidgetThresholds[0];
        let tr = deviationIndexWidgetThresholds[1];

        if (di > tr)
            return "di-dev2";
        if (di < -tr)
            return "di-dev2";

        if (di > tn)
            return "di-dev1";
        if (di < -tn)
            return "di-dev1";

        return "di-normal";
	}

     function sclassFromDI():string {

		let di = 0;
		if (exposureIndex && targetExposureIndex)
			di = calcDeviationIndex(exposureIndex, targetExposureIndex);
		else
			return "";
        let tn = deviationIndexWidgetThresholds[0];
        let tr = deviationIndexWidgetThresholds[1];

        if (di > tr)
            return "di-dev2-symbol";
        if (di < -tr)
            return "di-dev2-symbol";

        if (di > tn)
            return "di-dev1-symbol";
        if (di < -tn)
            return "di-dev1-symbol";

        return "di-normal-symbol";
	}
    useEffect(() => {

        if (displayElement) {
            /* setScale(displayElement.viewport?.scale ?? 0);
            setWindowWidth(displayElement.viewport?.voi?.windowWidth ?? 0);
            setWindowCenter(displayElement.viewport?.voi?.windowCenter ?? 0); */
            setImageId(displayElement.image?.imageId ?? '');
            setImageIndex(0);
            setStackSize(0);
            setSizeInBytes(displayElement.image?.sizeInBytes);

            // @ts-ignore
            if (displayElement?.image?.totalTimeInMS) {
                // @ts-ignore
                setTotalTimeInMS(displayElement.image?.totalTimeInMS + displayElement.image?.image_raw?.totalTimeInMS ?? 0);
            }
            displayElement.element.addEventListener("cornerstoneimagerendered", onImageRendered);
        }
        return () => {
            if (displayElement) {
                displayElement.element.removeEventListener("cornerstoneimagerendered", onImageRendered);
            }
        }
        // @ts-ignore
    }, [displayElement, displayElement?.image, props.orderId, currentImageId]);


	const onMeasureAdded = (ev: any) => {
        if (ev?.detail?.toolName === "ProcessingROI") {
            //setSValue(ev?.detail?.measurementData.svalue);
            setExposureIndex(ev?.detail?.measurementData.exposureindex);
        }
    };

	useEffect(() => {
        const cornerstoneElements: EnabledElement[] = cornerstone.getEnabledElements();
        const displayElement: EnabledElement | undefined = cornerstoneElements?.find((element: EnabledElement) => element.element.id === `${Constants.IMAGE_DISPLAY_GENERIC_ELEMENT_NAME}_${props.matrixIndex}`);
        if (displayElement?.image /*&& currentWorkitemId !== undefined && currentWorkitemId !== ''*/) {

            displayElement.element?.addEventListener(
                cornerstoneTools.EVENTS.MEASUREMENT_ADDED,
                onMeasureAdded
            );
            // we need the following mainly to set the s-value to undefined for images where there is no
            // ProcessingROI at all and we would then never see a MEASUREMENT_ADDED event anyway
            const stateManager = cornerstoneTools.getElementToolStateManager(displayElement?.element);
            const toolstate = stateManager.get(displayElement?.element, "ProcessingROI");
            if (toolstate?.data) {
                const data = toolstate?.data[0];
                //setSValue(data.svalue);
                setExposureIndex(data.exposureindex);
            } else {
                //setSValue(undefined);
                setExposureIndex(undefined);
            }
        } else {
            setImageId("");
        }
        return () => {
            if (displayElement?.element) {

                displayElement.element?.removeEventListener(
                    cornerstoneTools.EVENTS.MEASUREMENT_ADDED,
                    onMeasureAdded
                );
            }
        }
        // @ts-ignore
    }, [props.orderId, currentImageId]);


    if (!imageId) {
        return null;
    }

    const zoomPercentage = formatNumberPrecision(((scale ?? 0) * 100).toString(), 0);
    const seriesMetadata =
        cornerstone.metaData.get('generalSeriesModule', imageId) || {};
    const imagePlaneModule =
        cornerstone.metaData.get('imagePlaneModule', imageId) || {};
    const { rows, columns, sliceThickness, sliceLocation, pixelSpacing } = imagePlaneModule;
    const { seriesNumber, seriesDescription } = seriesMetadata;

    const generalStudyModule =
        cornerstone.metaData.get('generalStudyModule', imageId) || {};
    const { studyDate, studyTime, studyDescription } = generalStudyModule;

    const patientModule =
        cornerstone.metaData.get('patientModule', imageId) || {};
    const { patientId, patientName } = patientModule;

    const generalImageModule =
        cornerstone.metaData.get('generalImageModule', imageId) || {};
    const { instanceNumber } = generalImageModule;

    const cineModule = cornerstone.metaData.get('cineModule', imageId) || {};
    const { frameTime } = cineModule;

    const frameRate = formatNumberPrecision((1000 / frameTime).toString(), 1);
    const compression = getCompression(imageId);
    const wwwc = `W: ${(windowWidth as number).toFixed ? (windowWidth as number).toFixed(0) : windowWidth
        } L: ${(windowWidth as number).toFixed ? (windowCenter as number).toFixed(0) : windowCenter}`;
    const imageDimensions = `${columns} x ${rows}`;

	const ei = "EI: " + (exposureIndex ? Math.round(exposureIndex) + " / DI: "+ calcDeviationIndex(exposureIndex, targetExposureIndex): "");

    const formatResolution = () => {
        // return (<div>Spatial Resolution: {annotations.find((annotation: any) => annotation?.body?.type === "ResolutionMeasurementResult")?.body?.value?.resolution}</div>);
        const resolution: string = JSONPath({ path: "$[?(@.body.type === 'ResolutionMeasurementResult')]", json: annotations ?? [] }).pop()?.body?.value?.resolution;
        return (resolution?.length > 0 ? <div>{`${t('spatialResolution')}: ${resolution}`}</div> : null);
    }

    const formatPixelSpacing = () => {
        const pixelToLengthFactor: { row: number, col: number } | undefined = JSONPath({ path: "$[?(@.body.type === 'LenghtCalibrationResult')]", json: annotations ?? [] }).pop()?.body?.value?.pixelToLengthFactor;
        return ((pixelToLengthFactor !== undefined)
            ? <div>Pixel spacing: {`${pixelToLengthFactor?.row}\\${pixelToLengthFactor?.col} (mod)`}</div> :
            (pixelSpacing ? <div>{`${t('pixelSpacing')}: ${pixelSpacing}`}</div> : null)
        );
    }

    const formatLengthCalibration = () => {
        const scaleFactor: number | undefined = JSONPath({ path: "$[?(@.body.type === 'ScaleCalibrationResult')]", json: annotations ?? [] }).pop()?.body?.value?.scaleFactor;
        return (scaleFactor !== undefined ? <div>{`${t('lengthCalibrationFactor')}: ${scaleFactor?.toFixed(3)?.toString()}`}</div> : null);
    }

    const normal = (
        <React.Fragment>
            <div className="top-left overlay-element">
                <div>{formatPN(patientName)}</div>
                <div>{patientId}</div>
                <div>{studyDescription}</div>
                <div>
                    {formatDA(studyDate)} {formatTM(studyTime)}
                </div>
                <div>
                    {formatResolution()}
                </div>
                <div>
                    {formatPixelSpacing()}
                </div>
                <div>
                    {formatLengthCalibration()}
                </div>
            </div>
            {/* <div className="top-right overlay-element">
                    <div>{studyDescription}</div>
                    <div>
                        {formatDA(studyDate)} {formatTM(studyTime)}
                    </div>
                </div> */}
            {/* <div className="bottom-right overlay-element">
                    <div>Zoom: {zoomPercentage}%</div>
                    <div>{wwwc}</div>
                    <div className="compressionIndicator">{compression}</div>
                </div> */}
            <div className="bottom-left overlay-element">
                <div>{seriesNumber >= 0 ? `Ser: ${seriesNumber}` : ''}</div>
                <div>
                    {stackSize > 1
                        ? `Img: ${instanceNumber} ${imageIndex}/${stackSize}`
                        : ''}
                </div>
                <div>
                    {parseInt(frameRate!) >= 0 ? `${formatNumberPrecision(frameRate!, 2)} FPS` : ''}
                    <div>{imageDimensions}</div>
                    <div>{Constants.DEV_MODE && sizeInBytes ? formatBytes(sizeInBytes) : null}</div>
                    <div>{Constants.DEV_MODE && totalTimeInMS ? `${t('loadTime')} ${totalTimeInMS} ms` : null}</div>
                    <div>{Constants.DEV_MODE && processingTime ? `${t('processingTime')} ${processingTime} ms` : null}</div>
                    <div>
                        {isValidNumber(sliceLocation)
                            ? `Loc: ${formatNumberPrecision(sliceLocation, 2)} mm `
                            : ''}
                        {sliceThickness
                            ? `Thick: ${formatNumberPrecision(sliceThickness, 2)} mm`
                            : ''}
                    </div>
                    <div>{seriesDescription}</div>
                </div>
                <div>Zoom: {zoomPercentage}%</div>
                <div className={classFromDI()}>{ei}&nbsp;<span className={sclassFromDI()}>{charFromDI()}</span></div>
                <div>{wwwc}</div>
                <div className="compressionIndicator">{compression}</div>
            </div>
        </React.Fragment>
    );

    const noDcm = (
        <React.Fragment>
            <div className="top-left overlay-element">
                <div>
                    {formatResolution()}
                </div>
                <div>
                    {formatPixelSpacing()}
                </div>
                <div>
                    {formatLengthCalibration()}
                </div>
            </div>
            <div className="bottom-left overlay-element">
                <div>
                    <div>{`${displayElement?.image?.columns} x ${displayElement?.image?.rows}`}</div>
                </div>
                <div>{Constants.DEV_MODE && sizeInBytes ? formatBytes(sizeInBytes) : null}</div>
                <div>{Constants.DEV_MODE && totalTimeInMS ? `${t('loadTime')}  ${totalTimeInMS} ms` : null}</div>
                <div>{Constants.DEV_MODE && processingTime ? `${t('processingTime')} ${processingTime} ms` : null}</div>
                <div>Zoom: {zoomPercentage}%</div>
                <div>{wwwc}</div>
            </div>
        </React.Fragment>
    );

    return <div className={props.imageLoadStatus === "loading" && selectedMatrixIndex === props.matrixIndex ? "ViewportOverlay_loading" : "ViewportOverlay"}>{(imageId.startsWith('wadouri:') || imageId.startsWith('wadors:') || imageId.startsWith('dicomfile:')) ? normal : noDcm}</div>;
}

export default ViewportOverlay;
