import React from "react";

import { TypedUseSelectorHook, useDispatch, useSelector, } from "react-redux";
import { AppDispatch, RootState } from "../../store";


import "./GenderSelection.scss";
import { getSelectedSubject } from "./SubjectSlice";

type GenderSelectionProps = {
};

const useAppDispatch = () => useDispatch<AppDispatch>();
const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

const GenderSelection = (props: GenderSelectionProps) => {

    const dispatch = useAppDispatch();

    const subject: any = useAppSelector((state) => getSelectedSubject(state));

    const onSexClicked = (value: string) => {
        const newSubject: any = structuredClone(subject);
        let { details } = newSubject;

        if (value === 'male') {
            details = { ...details, pregnancyStatus: 'not_pregnant' };
        }

        newSubject.details = { ...details, sex: value };
        dispatch({ type: "Subject/setSelectedSubject", payload: newSubject });
    }

    return (
        <div className="GenderSelection">
            <label>{`Sex:`}</label>
            <div className="GenderSelectionButtons">
                <button className={`${subject?.details?.sex === 'male' ? 'GenderSelectionButton_selected' : 'GenderSelectionButton'}  venaDefaultButton`} onClick={() => onSexClicked('male')} >{'M'}</button>
                <button className={`${subject?.details?.sex === 'female' ? 'GenderSelectionButton_selected' : 'GenderSelectionButton'}  venaDefaultButton`} onClick={() => onSexClicked('female')} >{'F'}</button>
                <button className={`${subject?.details?.sex === 'other' ? 'GenderSelectionButton_selected' : 'GenderSelectionButton'}  venaDefaultButton`} onClick={() => onSexClicked('other')} >{'O'}</button>
            </div>
        </div>
    );
};

export default GenderSelection;
